import { Injectable } from '@angular/core';
import { ArticleEntityModel, ArticleDetails } from 'src/app/shared/ecpl-article-viewer/interfaces';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { environment } from 'src/environments/environment';
import { ElectroArticleData, ElectroFactsAboutCompany } from '../models/electroarticle';
import { ElectroChecklistModel } from 'src/app/kuba/feature-documents/feature-document.models';
import { Observable } from 'rxjs';
import { ElectroChecklistCompletion } from '../models/electrochecklistcompletion';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ElectroDocumentService {

    featureId: number;
    workingModule: any;
    CultureInfo: string;
    constructor(private http: HttpClient) {
        this.setWorkingModule();
        this.CultureInfo = BaseServices.userCultureInfo();
    }
    setWorkingModule() {
        this.workingModule = JSON.parse(
            sessionStorage.getItem('workingModule')
        );
        this.featureId = this.workingModule ? this.workingModule.id : 0;
    }

    // Add Article
    newArticle(model, fId) {
        this.setWorkingModule();
        let sData: ArticleEntityModel = {
            Title: model.item.articleContent.article.articleName,
            DocumentNo: model.item.articleContent.article.documentNumber,
            ReferenceNumber: model.item.articleContent.article.documentNumber,
            Chapter: model.item.articleContent.article.chapter,
            Version: model.item.articleContent.article.version,
            FeatureFolderId: model.activeNodeId,
            FeatureId: fId,
            Status: 1,
            BusinessId: +BaseServices.BusinessId ? +BaseServices.BusinessId : 0,
            ApplicationId: BaseServices.ApplicationId,
            CreatedBy: +BaseServices.UserId,
            ModifiedBy: +BaseServices.UserId,
            ArticleDate: model.item.articleContent.article.articleDate.jsdate,
            RoleStatus: '',
            SignaturePath: model.item.articleContent.article.signaturePath,
        };
        return this.http
            .post(
                `${environment.BASE_URL}/electro/article/new`,
                sData,

            )
            .map((result: any) => result)
            .catch(error => BaseServices.handleError(error));
    }

    // update Article
    saveArticle(model, fId) {
        this.setWorkingModule();
        let sData: ArticleEntityModel = {
            Id: model.item.articleId,
            ApplicationId: +BaseServices.ApplicationId
                ? +BaseServices.ApplicationId
                : 0,
            BusinessId: +BaseServices.BusinessId ? +BaseServices.BusinessId : 0,
            FeatureId: fId,
            Title: model.item.articleContent.article.articleName,
            DocumentNo: model.item.articleContent.article.documentNumber,
            Chapter: model.item.articleContent.article.chapter,
            Version: model.item.articleContent.article.version,
            RoleStatus: '',
            Status: 1,
            ModifiedBy: BaseServices.UserId,
        };
        return this.http
            .put(
                `${environment.BASE_URL}/electro/article/update/${sData.Id}`,
                sData,

            )
            .map((result: any) => result)
            .catch(error => BaseServices.handleError(error));
    }

    // update Article own texts
    newArticleText(aid, model, isNew, version, fId, fKey) {
        let sData: ElectroArticleData[] = [];
        model.item.articleContent.subArticles.forEach(element => {
            sData.push({
                Id: element.id ? element.id : 0,
                ApplicationId: +BaseServices.ApplicationId
                    ? +BaseServices.ApplicationId
                    : 0,
                PortalId: +BaseServices.PortalId ? +BaseServices.PortalId : 0,
                BusinessId: +BaseServices.BusinessId
                    ? +BaseServices.BusinessId
                    : 0,
                ElectroArticleId: aid,
                TextType: element.name,
                TextContent: element.text,
                Version: version,
                Status: element.status === 'Active' ? 1 : 0,
                CreatedBy: BaseServices.UserId,
                ModifiedBy: BaseServices.UserId,
                ArticleStatus: JSON.stringify(model.item.articleContent.article.roleStatus),
                ArticleFormattedDate: element.name === 'Own Text' ?
                    model.item.articleContent.article.formattedDate : null,
                SignaturePath: element.name === 'Own Text' ?
                    model.item.articleContent.article.signaturePath : null,
                FeatureId: fId,
                FeatureKey: fKey,
            });
        });

        if (isNew) {
            return this.http
                .post(
                    `${environment.BASE_URL}/electro/article/ArticleAllText`,
                    sData,

                )
                .map((result: any) => result)
                .catch(error => BaseServices.handleError(error));
        } else {
            return this.http
                .put(
                    `${environment.BASE_URL}/electro/article/ArticleAllText/${aid}/${version}/${+BaseServices.roleId}`,
                    sData,

                )
                .map((result: any) => result)
                .catch(error => BaseServices.handleError(error));
        }
    }

    // get articletext status
    getArticleTextStatus(aData: ArticleDetails, mid: number) {
        return this.http
            .put(environment.BASE_URL + '/electro/article/articleTextStatus/'+ mid,
                aData,
            )
            .map((result: any) => result)
            .catch(error => BaseServices.handleError(error));
    }

    createChecklist(featureId, featureKey, model) {
        this.setWorkingModule();
        // Add Checklist
        let sData = new ElectroChecklistModel();
        sData.ApplicationId = +BaseServices.ApplicationId
            ? +BaseServices.ApplicationId
            : 0;
        sData.PortalId = +BaseServices.PortalId ? +BaseServices.PortalId : 0;
        sData.BusinessId = +BaseServices.BusinessId
            ? +BaseServices.BusinessId
            : 0;
        sData.FeatureId = featureId;
        sData.FeatureKey = featureKey;
        sData.Title = model.item.checkList.title;
        sData.Status = model.item.checkList.status === 1 ? 1 : 0;
        sData.CreatedBy = BaseServices.UserId;
        sData.ModifiedBy = BaseServices.UserId;

        return this.http
            .post(
                `${environment.BASE_URL}/electro/newChecklist/`,
                sData,

            )
            .map((result: any) => result)
            .catch(error => BaseServices.handleError(error));
    }

    saveChecklist(electrocheckListId, model, modifiedBy) {
        let sData = `[${JSON.stringify(model)}]`;
        return this.http
            .put(
                `${
                environment.BASE_URL
                }/electro/updateAllElectroChecklistItems/${electrocheckListId}/${modifiedBy}`,
                JSON.stringify(sData),

            )
            .map((result: any) => result)
            .catch(error => BaseServices.handleError(error));
    }

    deleteChecklist(checklistId) {
        return this.http.delete(environment.BASE_URL + '/electro/deletechecklistbyid/' + checklistId, )
            .map((result: any) => result);
    }

    getCheklistTemplate() {
        return this.http.get(environment.BASE_URL + '/electro/checkListForTemplate/' + BaseServices.BusinessId)
            .map((result: any) => result);
    }

    getContractEnabledChecklists(contractId: number) {
        return this.http.get(environment.BASE_URL + '/electro/getContractEnabledChecklists/' + contractId, )
            .map((result: any) => result);
    }

    generateElectroInstallationForms(businessId: number, cultureInfo: string, contractId: number, formKey: string) {
        let dynamicType = 'application/pdf';
        let CultureInfo = sessionStorage.getItem('languageMode');
        let url = `${environment.BASE_URL}/electro-report/installationforms/${formKey}/${businessId}/${contractId}/${CultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.withCredentials = true;
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send();
        });
    }

    getChecklistTemplateById(checklistId: number) {
        return this.http.get(environment.BASE_URL + '/electro/get/checklistcompletiontemplate/' + checklistId, )
            .map((result: any) => result);
    }

    getCompletionChecklistdata(completionCheckListId: number) {
        return this.http.get(environment.BASE_URL + '/electro/getcompletionchecklistbyid/' + completionCheckListId, )
            .map((result: any) => result);
    }

    saveCompletionChecklistData(checklist: ElectroChecklistCompletion) {
        return this.http.post(environment.BASE_URL + '/electro/save/completionchecklist', checklist, )
            .map((result: any) => result);
    }

    updateCompletionChecklistData(checklist: ElectroChecklistCompletion) {
        return this.http.put(environment.BASE_URL + '/electro/update/completionchecklist', checklist, )
            .map((result: any) => result);
    }

    getInspectionChecklistBuildingtree(contractId: number, inspectionId: number) {
        return this.http.get(environment.BASE_URL + '/electro/getinspectionchecklistbuildingtree'
            + '/' + BaseServices.BusinessId + '/' + contractId + '/' + inspectionId, )
            .map((result: any) => result);
    }

    saveFactsAboutCompany(facts: ElectroFactsAboutCompany) {
        return this.http.post(environment.BASE_URL + '/electro/article/savefactsaboutthecompany', facts, )
            .map((result: any) => result);
    }
    updateFactsAboutCompany(facts: ElectroFactsAboutCompany) {
        return this.http.put(environment.BASE_URL + '/electro/article/updatefactsaboutthecompany', facts, )
            .map((result: any) => result);
    }

    //#region Reports
    generateFactsAboutCompanyPdf(contractId: number, cultureInfo: string) {
        let dynamicType = 'application/pdf';
        let CultureInfo = sessionStorage.getItem('languageMode');
        let url = `${environment.BASE_URL}/electro-report/factsaboutcompany/${contractId}/${CultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.withCredentials = true;
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send();
        });
    }

    generateOrgChartPdf(contractId: number, cultureInfo: string) {
        let dynamicType = 'application/pdf';
        let CultureInfo = sessionStorage.getItem('languageMode');
        let url = `${environment.BASE_URL}/electro-report/orgchart/${contractId}/${CultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.withCredentials = true;
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send();
        });
    }

    generateInspectionReport(inspectionId, cultureInfo) {
        let dynamicType = 'application/pdf';
        let CultureInfo = sessionStorage.getItem('languageMode');
        let url = `${environment.BASE_URL}/electro-report/inspectionreport/${inspectionId}/${BaseServices.UserId}/${CultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.withCredentials = true;
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send();
        });
    }

    generateDeviationReport(deviationId, userId, cultureInfo) {
        let dynamicType = 'application/pdf';
        let CultureInfo = sessionStorage.getItem('languageMode');
        let url = `${environment.BASE_URL}/electro-report/deviationreport/${deviationId}/${userId}/${CultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.withCredentials = true;
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send();
        });
    }

    generateChecklistReport(checklistId, contractId, userId, cultureInfo) {
        let dynamicType = 'application/pdf';
        let CultureInfo = sessionStorage.getItem('languageMode');
        let url = `${environment.BASE_URL}/electro-report/checklistreport/${checklistId}/${contractId}/${userId}/${CultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.withCredentials = true;
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send();
        });
    }

    generateContractControlReport(contractId, cultureInfo) {
        let dynamicType = 'application/pdf';
        let CultureInfo = sessionStorage.getItem('languageMode');
        let url = `${environment.BASE_URL}/electro-report/contractcontrolreport/${contractId}/${CultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.withCredentials = true
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send();
        });
    }

    generateManualReport(manuals, contractId, cultureInfo) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: manuals });
        let CultureInfo = sessionStorage.getItem('languageMode');
        let url = `${environment.BASE_URL}/electro-report/manualcompletereport/${contractId}/${CultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.withCredentials = true;
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }

    inspectionCklOrFormReport(cklOrFormType, cklOrFormId, contractId) {
        let dynamicType = 'application/pdf';
        let CultureInfo = sessionStorage.getItem('languageMode');
        let url = `${environment.BASE_URL}/electro-report/inspectioncklorformreport/${cklOrFormType}/${cklOrFormId}/${BaseServices.UserId}/${contractId}/${CultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.withCredentials = true;
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send();
        });
    }

    deleteCompletionChecklist(cklOrFormId, cklOrFormType) {
        return this.http.delete(environment.BASE_URL + '/electro/deletecompletionchecklistbyid/' + cklOrFormId + '/' + cklOrFormType, )
            .map((result: any) => result);
    }


  /**
  * generate file
  * @param reportData1 {any}
  * @param reportData2 {any}
  * @param columnNames {any}
  * @param type {any}
  * @param printMode {number} // 0-Portrait, 1-Landscape
  */
    generateExportFile(reportData1: any, reportData2: any, lawColumns: any,regulationColumns: any,type: string, headerName: any,headerName1:any, printMode: number, businessId: number, cultureInfo: string) {
        if (printMode == undefined) {
            printMode = 0;
        }
        let dynamicType;
        if (type === 'PDF') {
            dynamicType = 'application/pdf';
        }
        let CultureInfo = sessionStorage.getItem('languageMode');
        let myData = JSON.stringify({ Laws: reportData1, Regulations: reportData2, lawColumns: lawColumns, regulationColumns: regulationColumns});
        let url = `${environment.BASE_URL}/electro-report/exportpdf/${type}/${headerName}/${headerName1}/${printMode}/${businessId}/${BaseServices.UserId}/${CultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.withCredentials = true;
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }

    inspectionBuildingCklOrFormReport(buildingId, inspectionId) {
        let dynamicType = 'application/pdf';
        let CultureInfo = sessionStorage.getItem('languageMode');
        let url = `${environment.BASE_URL}/electro-report/inspectionbuildingreport/${buildingId}/${inspectionId}/${BaseServices.UserId}/${CultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.withCredentials = true;
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send();
        });
    }
    //#endregion
}

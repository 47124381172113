import { LanguagesComponent } from './../../_directives/languages.component';
import { DropdownSelectTranslate, UserDropdownSelectTranslate } from './../../_helpers/pipes';
import { BusinessServices } from './../businesses/services/business.services';
import { FeaturesComponent } from './../features/components/features.component';
import { ValidationMessagesComponent } from './components/validationMessages.component';
import { staticdataComponent } from './../follow-ups/components/staticdata.component';
import { ToasterComponent } from './../../_directives/toaster.component';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { TranslateModule } from '@ngx-translate/core';
import { ValidationService } from './services/validation.service';

import { NgxUploaderModule } from 'ngx-uploader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import {
    StatusPipe,
    MonthPipe,
    LicenseCategoryPipe,
    AssignValuePipe,
    RowStatusPipe,
    categoryNamePipe,
    CapitalizePipe,
    FormatHoursPipe,
    RolesPipe,
    ModulesPipe,
    FormatTimePipe,
    SearchPipe,
    SicknessReasonTranslate,
    moduleNamePipe,
    SpecifiCharPipe,
} from '../../_helpers/pipes';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TabMenuModule } from 'primeng/tabmenu';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputMaskModule} from 'primeng/inputmask';
import {ListboxModule} from 'primeng/listbox';
import {FileUploadModule} from 'primeng/fileupload';
import {RadioButtonModule} from 'primeng/radiobutton';
import {MultiSelectModule} from 'primeng/multiselect';
import {TreeTableModule} from 'primeng/treetable';
import {ChartModule} from 'primeng/chart';
import {ToolbarModule} from 'primeng/toolbar';
import {AccordionModule} from 'primeng/accordion';
import { ProgressBarModule } from 'primeng/progressbar';
import {SpinnerModule} from 'primeng/spinner';
import { TreeModule } from '@circlon/angular-tree-component';
import { MyDatePickerModule } from 'mydatepicker';
import {ToastModule} from 'primeng/toast';


@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
        EditorModule,
        AutoCompleteModule,
        InputTextModule,
        InputTextareaModule,
        InputMaskModule,
        DropdownModule,
        TableModule,
        ButtonModule,
        SharedModule,
        CalendarModule,
        FormsModule,
        ListboxModule,
        FileUploadModule,
        ReactiveFormsModule,
        CheckboxModule,
        RadioButtonModule,
        DialogModule,
        ToastModule,
        MultiSelectModule,
        TreeTableModule,
        ToasterModule,
        ChartModule,
        TriStateCheckboxModule,
        TooltipModule,
        ToolbarModule,
        AccordionModule,
        NgxUploaderModule,
        MyDatePickerModule,
        ProgressBarModule,
        TreeModule,
    ],
    exports: [
        TranslateModule,
        CommonModule,
        EditorModule,
        AutoCompleteModule,
        InputTextModule,
        InputTextareaModule,
        InputMaskModule,
        DropdownModule,
        TabMenuModule,
        TableModule,
        MultiSelectModule,
        CheckboxModule,
        RadioButtonModule,
        ButtonModule,
        SharedModule,
        ListboxModule,
        CalendarModule,
        FormsModule,
        FileUploadModule,
        ReactiveFormsModule,
        ValidationMessagesComponent,
        staticdataComponent,
        // GrowlModule,
        FeaturesComponent,
        ConfirmDialogModule,
        CheckboxModule,
        DialogModule,
        TreeTableModule,
        StatusPipe,
        MonthPipe,
        LicenseCategoryPipe,
        AssignValuePipe,
        RowStatusPipe,
        categoryNamePipe,
        CapitalizePipe,
        FormatHoursPipe,
        RolesPipe,
        ModulesPipe,
        FormatTimePipe,
        ToasterModule,
        ChartModule,
        ToasterComponent,
        TriStateCheckboxModule,
        TooltipModule,
        ToolbarModule,
        AccordionModule,
        NgxUploaderModule,
        MyDatePickerModule,
        ProgressBarModule,
        SearchPipe,
        SpinnerModule,
        DropdownSelectTranslate,
        SicknessReasonTranslate,
        UserDropdownSelectTranslate,
        LanguagesComponent,
        moduleNamePipe,
        SpecifiCharPipe
    ],

    declarations: [
        ValidationMessagesComponent,
        FeaturesComponent,
        staticdataComponent,
        StatusPipe,
        MonthPipe,
        LicenseCategoryPipe,
        AssignValuePipe,
        RowStatusPipe,
        categoryNamePipe,
        CapitalizePipe,
        RolesPipe,
        ModulesPipe,
        FormatTimePipe,
        SearchPipe,
        LanguagesComponent,
        ToasterComponent,
        FormatHoursPipe,
        DropdownSelectTranslate,
        SicknessReasonTranslate,
        UserDropdownSelectTranslate,
        moduleNamePipe,
        SpecifiCharPipe
    ],
    providers: [
        BusinessServices
    ]
})
export class AppSharedModule { }

<div class="main-content">

  <div>
    <div class="table-view">
      <!-- Table starts -->
      <p-table
        #dt
        [value]="Vehicles"
        [rows]="10"
        [paginator]="true"
        [pageLinks]="3"
        [rowsPerPageOptions]="[5, 10, 20]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
      >
        <!-- Caption -->
        <ng-template pTemplate="caption">
          <div class="ui-helper-clearfix">
            <div class="table-options">
              <div class="pull-right">
                <kuba-export
                  [reportData]="dt.filteredValue || dt.value"
                  [additionalData]="additionalData"
                ></kuba-export>
              </div>
            </div>
          </div>
        </ng-template>
        <!-- Header -->
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="VehicleName">
              {{ "VEHICLE_NAME" | translate }}
              <p-sortIcon field="VehicleName"></p-sortIcon>
            </th>
            <th pSortableColumn="BrandName">
              {{ "BRAND" | translate }}
              <p-sortIcon field="BrandName"></p-sortIcon>
            </th>
            <th pSortableColumn="ModelName">
              {{ "MODEL" | translate }}
              <p-sortIcon field="ModelName"></p-sortIcon>
            </th>
            <th pSortableColumn="RegistrationNo">
              {{ "REGISTRATION_NUMBER" | translate }}
              <p-sortIcon field="RegistrationNo"></p-sortIcon>
            </th>
            <th pSortableColumn="RegistrationYear">
              {{ "REGISTRATION_YEAR" | translate }}
              <p-sortIcon field="RegistrationYear"></p-sortIcon>
            </th>
            <th pSortableColumn="VehicleTypeName">
              {{ "TYPE_OF_VEHICLE" | translate }}
              <p-sortIcon field="VehicleTypeName"></p-sortIcon>
            </th>
            <th pSortableColumn="DepartmentName">
              {{ "DEPARTMENT" | translate }}
              <p-sortIcon field="DepartmentName"></p-sortIcon>
            </th>
            <th>{{ "OPTIONS" | translate }}</th>
          </tr>
          <tr>
            <th>
              <input
                pInputText
                type="text"
                (input)="
                  dt.filter(
                    $any($event.target)?.value,
                    'VehicleName',
                    'contains'
                  )
                "
                placeholder=""
                class="p-column-filter"
              />
            </th>
            <th>
              <input
                pInputText
                type="text"
                (input)="
                  dt.filter($any($event.target)?.value, 'BrandName', 'contains')
                "
                placeholder=""
                class="p-column-filter"
              />
            </th>
            <th>
              <input
                pInputText
                type="text"
                (input)="
                  dt.filter($any($event.target)?.value, 'ModelName', 'contains')
                "
                placeholder=""
                class="p-column-filter"
              />
            </th>
            <th>
              <input
                pInputText
                type="text"
                (input)="
                  dt.filter(
                    $any($event.target)?.value,
                    'RegistrationNo',
                    'contains'
                  )
                "
                placeholder=""
                class="p-column-filter"
              />
            </th>
            <th>
              <input
                pInputText
                type="text"
                (input)="
                  dt.filter(
                    $any($event.target)?.value,
                    'RegistrationYear',
                    'contains'
                  )
                "
                placeholder=""
                class="p-column-filter"
              />
            </th>
            <th>
              <input
                pInputText
                type="text"
                (input)="
                  dt.filter(
                    $any($event.target)?.value,
                    'VehicleTypeName',
                    'contains'
                  )
                "
                placeholder=""
                class="p-column-filter"
              />
            </th>
            <th>
              <input
                pInputText
                type="text"
                (input)="
                  dt.filter(
                    $any($event.target)?.value,
                    'DepartmentName',
                    'contains'
                  )
                "
                placeholder=""
                class="p-column-filter"
              />
            </th>
            <th></th>
          </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-vehiclelist>
          <tr>
            <td>
              <span>{{ vehiclelist.VehicleName }}</span>
            </td>
            <td>
              <span>{{ vehiclelist.BrandName }}</span>
            </td>
            <td>
              <a [routerLink]="['./../../details', vehiclelist.Id]">{{
                vehiclelist.ModelName
              }}</a>
            </td>
            <td>
              <a [routerLink]="['./../../details', vehiclelist.Id]">{{
                vehiclelist.RegistrationNo
              }}</a>
            </td>
            <td>
              <span>{{ vehiclelist.RegistrationYear }}</span>
            </td>
            <td>
              <span>{{ vehiclelist.VehicleTypeName }}</span>
            </td>
            <td>
              <span>{{ vehiclelist.DepartmentName }}</span>
            </td>
            <td class="col-button">
              <a
                [routerLink]="['./../../edit', vehiclelist.Id]"
                class="btn btn-icon"
                title="{{ 'EDIT' | translate }}"
              >
                <i class="icon ic-sm icon-pencil-tip"></i>
              </a>
              <button
                type="button"
                class="btn btn-icon"
                title="{{ 'DELETE' | translate }}"
                (click)="deleteVehicle(vehiclelist.Id)"
                *ngIf="isHideDeleteIcon == true"
              >
                <i class="icon ic-sm icon-trash"></i>
              </button>
            </td>
          </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td colspan="8">
              {{ "NO_RECORDS_FOUND" | translate }}
            </td>
          </tr>
        </ng-template>
      </p-table>
      <!-- Table ends -->
    </div>
  </div>
</div>
<iframe
  id="fleetControl"
  height="100%"
  width="100%"
  style="width: 950px; height: 713px; display: none"
></iframe>

<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<toaster-component></toaster-component>

<router-outlet></router-outlet>

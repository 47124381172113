import { AllDepartmentResolver } from './../follow-ups/services/deviation.resolver.service';
import {
  BusinessUserEditResolver,
  BusinessCacheResolver,
} from './../businesses/services/business.resolver.service';
import { BusinessUsersEditComponent } from './../businesses/components/business-user-edit.component';
import { UserRoleResolver } from './../users/services/user.resolver.service';
import {
  EmployeeListResolver,
  EmployeeWithLoginListResolver,
} from './services/employee-admin.resolver.service';
import { EmployeeListComponent } from './components/employee-list.component';
import { CustomerEmployeeLoginComponent } from './components/customer-employee-login.component';
import { CustomerEmployeeComponent } from './components/customer-employees.component';
import { EmployeeAdminMainComponent } from './employee-admin.component';
import { Route } from '@angular/router';

export const EmployeeAdminRoutes: Route[] = [
  {
    path: 'employee-admin',
    component: EmployeeAdminMainComponent,
    children: [
      {
        path: 'list',
        component: EmployeeListComponent,
        children: [
          {
            path: 'customer-employee',
            component: CustomerEmployeeComponent,
            resolve: {
              list: EmployeeListResolver,
              business: BusinessCacheResolver,
              category: AllDepartmentResolver,
            },
          },
          {
            path: 'customer-employee-login',
            component: CustomerEmployeeLoginComponent,
            resolve: {
              employee: EmployeeWithLoginListResolver,
              business: BusinessCacheResolver,
              category: AllDepartmentResolver,
            },
          },
          {
            path: 'user/edit/:uid',
            component: BusinessUsersEditComponent,
            resolve: {
              userData: BusinessUserEditResolver,
              userrole: UserRoleResolver,
            },
          },
        ],
      },
    ],
  },
];

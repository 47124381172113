<div class="main-content">
  <div class="main-heading">
    <label translate>FOOD_SAFETY</label>
    <label> - {{ pageTitle | capitalize }}</label>
    <span class="page-actions text-right">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="BackToZone()"
      >
        <span translate>BACK_TO_ZONE</span>
      </button>
    </span>
  </div>
  <ecpl-tab-navigation
    [linkItems]="enabledAdditionalFeatures"
    [parentId]="fsId"
  ></ecpl-tab-navigation>
  <div class="card">
    <div class="card-body">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

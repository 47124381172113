import { BaseServices } from './../../kuba.services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from 'src/environments/environment';
import 'rxjs/add/operator/map';
import {
  Activity,
  ActivityHeader,
  FollowUpActivity,
} from '../models/activities';
import { PaginatedResult, Pagination } from 'src/app/_models/options';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class ActivityServices {
  id: number;
  constructor(private http: HttpClient) {}

  // bind business dropdown
  getBusiness() {
    return this.http
      .get(environment.BASE_URL + '/businesses', BaseServices.headerOption)
      .map((result) => result);
  }
  // List all Activity
  list(businessId: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/businessActivities/${businessId}`,
        BaseServices.headerOption
      )
      .map((res: any) => res);
  }

  getActivityResponsible(businessId: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/activityResponsible/${businessId}`,
        BaseServices.headerOption
      )
      .map((res) => res);
  }
  // List all Activity
  getPortalActiveBusiness() {
    return this.http
      .get(
        `${environment.BASE_URL}/portalactivebusinesses`,
        BaseServices.headerOption
      )
      .map((res) => res);
  }
  // List all business
  activeBusinesslist() {
    return this.http
      .get(
        environment.BASE_URL + '/activebusinesses',
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  // List all business
  responsiblePersons() {
    return this.http
      .get(
        environment.BASE_URL + '/responsiblepersons',
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }
  // List all Activity
  repetitionByAdmin(appId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/adminactivityschedule/' + appId,
        BaseServices.headerOption
      )
      .map((res) => res);
  }
  // List all Activity
  repetitionByPortal(userId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/portalactivityschedule/' + userId,
        BaseServices.headerOption
      )
      .map((res) => res);
  }
  // List all Activity
  repetitionByBusiness(businessId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/activityschedulesbybusiness/' + businessId,
        BaseServices.headerOption
      )
      .map((res) => res);
  }
  deleteactivity(activity: any) {
    return this.http
      .post(
        environment.BASE_URL + '/activityChildRecorddelete',
        activity,
        BaseServices.headerOption
      )
      .map((response: any) => {
        return response;
      });
  }

  /**
   * stop future recurring
   * @param activityId
   */
  stopFutureRecurring(activityId: any) {
    return this.http
      .get(
        environment.BASE_URL + '/stopFutureRecurringActivity/' + activityId,
        BaseServices.headerOption
      )
      .map((response: any) => {
        return response;
      });
  }

  add(activity: ActivityHeader, applicationId: number) {
    return this.http
      .post(
        environment.BASE_URL + '/followup/postactivity/' + applicationId,
        activity,
        BaseServices.headerOption
      )
      .map((response: any) => {
        return response;
      });
  }

  addActivityForPortal(activity: any) {
    return this.http
      .post(
        environment.BASE_URL + '/activityaddportal',
        activity,
        BaseServices.headerOption
      )
      .map((response: any) => {
        return response;
      });
  }
  update(activity: FollowUpActivity) {
    return this.http
      .put(
        environment.BASE_URL + '/followup/putactivity',
        activity,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  get(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/followup/getactivity/' + id,
        BaseServices.headerOption
      )
      .map((res: any) => res);
  }

  getById(id: number) {
    return this.http
      .get(`${environment.BASE_URL}/Lookup/${id}`, BaseServices.headerOption)
      .map((result) => result);
  }

  delete(id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/activitydelete/' + id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  deleteParentwithRecurring(id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/followup' + '/deleteParentwithRecurring/' + id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  getallRepetition() {
    return this.http
      .get(environment.BASE_URL + '/repetition', BaseServices.headerOption)
      .map((result) => {
        if (result) {
          return result;
        } else {
          return null;
        }
      });
  }

  // bind Execution and followup
  getAll(id: number) {
    return this.http
      .get(environment.BASE_URL + '/Lookup/' + id, BaseServices.headerOption)
      .map((result) => result);
  }

  getAllFollowUp(id: number) {
    return this.http
      .get(environment.BASE_URL + '/employees/' + id, BaseServices.headerOption)
      .map((result) => result);
  }

  getAllExecution(id: number) {
    return this.http
      .get(environment.BASE_URL + '/employees/' + id, BaseServices.headerOption)
      .map((result) => result);
  }

  getAllRepetition() {
    return this.http
      .get(environment.BASE_URL + '/repetition', BaseServices.headerOption)
      .map((result: any) => result);
  }

  getActivityLog(businessId: number, id: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/followup/activitylog/${businessId}/${id}`,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  // bind dropdown Course Name
  getAllByCourseName(id: number) {
    return this.http
      .get(
        environment.BASE_URL +
          '/business/businesscourse/businesscourselist/' +
          id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  getActivitiesByUser(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/activitybyuser/' + id,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }
  getChecklistByBusiness(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/manual/0 /checklist/checklistbybusiness/' + id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  getAdminActivity(applicationId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/adminactivities/' + applicationId,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }
  getPortalActivity(userId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/portalactivities/' + userId,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }
  getUserForAdminActivity(activityId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/usersbybusiness/' + activityId,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  getBusinessPortalContacts(appId: number, userId: number) {
    return this.http
      .get(
        environment.BASE_URL +
          '/businessportalcontacts/' +
          appId +
          '/' +
          userId,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }

  getActivityCompleteCheckList(activityId: number) {
    return this.http
      .get(
        environment.BASE_URL +
          '/manual/checklist/complete/get/checklistByActivity/' +
          activityId,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  /**
   *
   * @param activityId
   */
  checkParentActivity(activityId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/followup' + '/parentActivity/' + activityId,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  activityReportGenerate(
    businessId: number,
    activityId: number,
    cultureInfo: string,
    userId: number
  ) {
    let dynamicType = 'application/pdf';
    let myData = JSON.stringify({ data: activityId });
    let url = `${environment.BASE_URL}/export-ActivityPrintDetails/${businessId}/${activityId}/${cultureInfo}/${userId}`;
    return Observable.create((observer) => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      let session = JSON.parse(sessionStorage.getItem('session'));
      let type = 'Bearer';
      let token = session.AccessToken;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', type + ' ' + token);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }

  /**
   * error handler
   * @param error {any}
   */
  private handleError(error: any) {
    let applicationError = error.headers.get('Application-Error');
    let serverError = error;
    let modelStateErrors = '';

    if (!serverError.type) {
      for (let key in serverError) {
        if (serverError[key]) {
          modelStateErrors += serverError[key] + '\n';
        }
      }
    }

    modelStateErrors = modelStateErrors = '' ? null! : modelStateErrors;

    return Observable.throw(
      applicationError || modelStateErrors || 'Server error'
    );
  }
}

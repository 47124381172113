<div class="main-content">
  <div class="main-heading">
    <span translate>BUSINESS_REGISTRY</span>
    <div *ngIf="hideNewButton" class="page-actions text-right">
      <a routerLink="./new/details" class="btn btn-success">
        <span class="icon ic-sm icon-add"></span>
        <span translate>NEW_BUSINESS</span>
      </a>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="table-view" [formGroup]="slider">
        <!-- Table starts -->
        <p-table
          #dt
          [value]="businesses"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [totalRecords]="totalrecords"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
          [globalFilterFields]="[
            'CompanyName',
            'Connectedto',
            'Telephone',
            'CategoryName',
            'EmployeeCount'
          ]"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
        >
          <!-- Caption -->
          <ng-template pTemplate="caption">
            <div class="ui-helper-clearfix">
              <div class="table-options">
                <div class="pull-left">
                  <div class="input-filters">
                    <input
                      pInputText
                      size="50"
                      type="text"
                      (input)="applyFilterGlobal($event, 'contains')"
                      placeholder="{{ 'SEARCH' | translate }}"
                    />
                  </div>
                </div>
                <div class="pull-right">
                  <div class="action-btns-group">
                    <kuba-export
                      [reportData]="dt.filteredValue || dt.value"
                      [additionalData]="additionalData"
                    ></kuba-export>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th
                [attr.width]="
                  !isPortal &&
                  (applicationId == 1 ||
                    applicationId == 2 ||
                    applicationId == 4)
                    ? '120px'
                    : '60px'
                "
                text-align="center"
              >
                <div
                  *ngIf="
                    !isPortal &&
                    (applicationId == 1 ||
                      applicationId == 2 ||
                      applicationId == 4)
                  "
                >
                  {{ "NAVIGATION" | translate }}
                </div>
              </th>

              <th pSortableColumn="CompanyName" width="417px">
                {{ "NAME" | translate }}
              </th>
              <th pSortableColumn="Connectedto" width="200px">
                {{ "CONNECTED_TO" | translate }}
              </th>
              <th pSortableColumn="CategoryName " width="200px">
                {{ "CATEGORY" | translate }}
              </th>
              <th pSortableColumn="LicenseMonth" width="120px">
                {{ "LICENSE_MONTH" | translate }}
              </th>
              <th pSortableColumn="EmployeeCount" width="80px">
                {{ "EMP" | translate }}
              </th>
              <th pSortableColumn="Status" width="110px">
                {{ "STATUS" | translate }}
              </th>

              <th pSortableColumn="CreatedOn" width="95px">
                {{ "CREATED_ON" | translate }}
              </th>
              <th pSortableColumn="ModifiedOn" width="95px">
                {{ "MODIFIED_ON" | translate }}
              </th>
              <th pSortableColumn="LoginDate" width="95px">
                {{ "LOGIN_DATE" | translate }}
              </th>
              <th pSortableColumn="LoginCount" width="95px">
                {{ "LOGIN_COUNT" | translate }}
              </th>
              <th width="75px">{{ "OPTIONS" | translate }}</th>
            </tr>
            <tr>
              <th></th>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    dt.filter(
                      $any($event.target)?.value,
                      'CompanyName',
                      'contains'
                    )
                  "
                  class="p-column-filter"
                />
              </th>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    dt.filter(
                      $any($event.target)?.value,
                      'Connectedto',
                      'contains'
                    )
                  "
                  class="p-column-filter"
                />
              </th>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    dt.filter(
                      $any($event.target)?.value,
                      'CategoryName',
                      'contains'
                    )
                  "
                  class="p-column-filter"
                />
              </th>
              <th>
                <p-dropdown
                  [options]="months"
                  appendTo="body"
                  (onChange)="dt.filter($event.value, 'LicenseMonth', 'equals')"
                  styleClass="p-column-filter"
                  placeholder=""
                  [showClear]="true"
                >
                  <ng-template let-month pTemplate="item">
                    {{ month.label | translate }}
                  </ng-template>
                </p-dropdown>
              </th>
              <th>
              </th>
              <th>
                <p-dropdown
                  width="100%"
                  [options]="statuses"
                  appendTo="body"
                  (onChange)="dt.filter($event.value, 'Status', 'equals')"
                  styleClass="p-column-filter"
                  placeholder=""
                  [showClear]="true"
                >
                  <ng-template let-status pTemplate="item">
                    {{ status.label | translate }}
                  </ng-template>
                </p-dropdown>
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="13">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-business>
            <tr>
              <td>
                <!-- Checks application id, should only appear in IK, BA and Ahlsell, and never in portals -->
                <a
                  *ngIf="
                    !isPortal &&
                    (applicationId == 1 ||
                      applicationId == 2 ||
                      applicationId == 4)
                  "
                  href="javascript:void(0)"
                  class="btn btn-outline-primary"
                  (click)="cMode(business)"
                >
                  <span translate>CRM</span>
                </a>
                <a
                  href="javascript:void(0)"
                  class="btn btn-outline-primary"
                  (click)="rModeCheckFeature(business)"
                >
                  <span translate>R</span>
                </a>
              </td>

              <td>
                <span
                  translate
                  pTooltip="{{ business.CompanyName }}"
                  tooltipPosition="top"
                  >{{ business.CompanyName }}</span
                >
              </td>
              <td>
                <span
                  translate
                  pTooltip="{{ business.Connectedto }}"
                  tooltipPosition="top"
                  >{{ business.Connectedto }}</span
                >
              </td>
              <td>
                <span
                  translate
                  pTooltip="{{ business.CategoryName }}"
                  tooltipPosition="top"
                  >{{ business.CategoryName }}</span
                >
              </td>
              <td>
                <span translate>{{
                  business.LicenseMonth | month | translate
                }}</span>
              </td>
              <td style="text-align: center">
                <span style="display: inline-block">
                  {{
                    business.EmployeeCount === 0 ? "-" : business.EmployeeCount
                  }}
                </span>
              </td>

              <td>
                <a
                  href="javascript:void(0)"
                  (click)="editStatus(business['Id'], business['Status'])"
                  routerLinkActive="active"
                >
                  <span translate>
                    {{ business.Status | rowStatus | translate }}</span
                  >
                </a>
              </td>
              <td>
                <span>{{ business.CreatedOn | date : "dd/MM/yyyy" }}</span>
              </td>
              <td>
                <span>{{ business.ModifiedOn | date : "dd/MM/yyyy" }}</span>
              </td>
              <td>
                <span *ngIf="business.LoginCount != 0">{{
                  business.LoginDate | date : "dd/MM/yyyy"
                }}</span>
              </td>
              <td style="text-align: center">
                <span style="display: inline-block">
                  {{ business.EmployeeCount === 0 ? "-" : business.LoginCount }}
                </span>
              </td>

              <td class="col-button">
                <button
                  type="button"
                  [routerLink]="['./edit', business.Id, 'details']"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  *ngIf="!isPortal"
                  type="button"
                  (click)="confirm(business.Id, business)"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table ends -->
      </div>
    </div>
  </div>
</div>
<div class="panel-loader" *ngIf="loading">
  <span>
    <span class="loader-icon"></span>
    <span translate>PROCESSING</span>
  </span>
</div>
<toaster-component></toaster-component>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>

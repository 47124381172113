import 'rxjs/Rx';

import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/api';

import { BusinessDepartmentService } from './../../business-department.services';
import { Department } from '../models/vehicle';
import { VehicleServices } from '../services/vehicle.service';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { VehicleList } from './../models/vehicle';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'vehicle-edit.component.html'
})
export class VehicleEditComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    public Id;
    Year: any;
    DeptForm: FormGroup;
    Vehicles: VehicleList[];
    Vehicleformdata: any;
    editDepartments = [];
    vehiclesize: SelectItem[];
    vehicle: any;
    vehicleEditForm: FormGroup;
    departments: SelectItem[];
    selectedDepartment = new Department();
    showDept = false;
    addDept = false;
    vehicleBrand: string;
    vehicleModel: string;
    vehicleUsers: any;
    selectedUsers: any = [];
    userId: any;
    vehicleName:any;
    bindAllUsers = false;
    bindSelectedUsers = true;
    userSubscribers: SelectItem[];
    emailUsers: any[];
    emailSubscriber = [];
    selectedSubscribers: SelectItem[];
    selectedEmailSubscriber = [];
    vehicleType: SelectItem[];
    useriddefault: number;
    checkedAll:boolean;
    private subscriptions: Subscription[] = [];
    constructor(
        private vehicleService: VehicleServices,
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute,
        protected location: Location,
        private confirmationService: ConfirmationService,
        private businessDepartmentService: BusinessDepartmentService,
        private translate: TranslateService
    ) { 
        this.userId = +BaseServices.UserId;
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.vehicleEditForm = this.fb.group({
            BusinessDepartmentId: [],
            VehicleSize: [],
            VehicleName:[''],
            BrandName:[''],
            ModelName:[''],
            RegistrationNo:[],
            RegistrationYear:[''],
            VehicleTypeId:[],
            DriverName:[''],
            IsShowDeviationChecked: [false],
            selectedEmailSubscriber: [''],
            selectAllEmailSubscriber: ['']
        });

        this.DeptForm = this.fb.group({
            Name: ['', Validators.required]
        });
        let date = new Date();
        let currentDate = date.getFullYear();
        this.Year = [];
        for (let n = 1905; n <= currentDate; n++) {
            this.Year.push({ label: n, value: n });
        }
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.departments = [];
            this.departments.push(
                { label: val.SELECT, value: null }
            );
            let departments = this.activatedRoute.snapshot.data['department'];
            if (departments) {
                departments.forEach(element => {
                    this.departments.push({ label: element.Name, value: element.Id });
                    this.editDepartments.push({ Name: element.Name, Id: element.Id });
                });
            }
        }));

        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.vehiclesize = [];
            this.vehiclesize.push(
                { label: val.SELECT, value: null }
            );
        }));
        let vehiclesize = this.activatedRoute.snapshot.data['vehiclesize'];
        if (vehiclesize) {
            vehiclesize.forEach(element => {
                this.vehiclesize.push({ label: element.Name, value: element.Id });
            });
        }
        this.selectedSubscribers = [];
        this.userSubscribers = [];
        let userSubscription = this.activatedRoute.snapshot.data[
            'users_subscription'
        ];
        this.emailUsers = userSubscription;
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.userSubscribers = [];
            if (userSubscription) {
                userSubscription.forEach((element: any) => {
                    this.selectedSubscribers.push({
                        label: element.Name,
                        value: { Id: element.Id }
                    });
                    if (element.Id === BaseServices.UserId) {
                        this.userSubscribers.push({
                            label: element.Name,
                            value: { Id: element.Id }
                        });
                    }
                });
            }
        }));

        let users = this.activatedRoute.snapshot.data['contact'];
        if (users) {
            this.vehicleUsers = users;
        }  
        this.useriddefault = BaseServices.UserId

        this.vehicleType = [];
       
        this.bindDropdowns();

        let tid = this.activatedRoute.snapshot.params['id'];
        this.Id = +tid;
        let result = this.activatedRoute.snapshot.data['details'];
        if (result) {
            this.Vehicleformdata = result;
            this.vehicleBrand = result.BrandName;
            this.vehicleModel = result.ModelName;
            let vehicleDetails = {
                BusinessDepartmentId: result.BusinessDepartmentId,
                VehicleSize: result.VehicleSize,
                VehicleName:result.VehicleName,
                BrandName:result.BrandName,
                ModelName:result.ModelName,
            RegistrationNo:result.RegistrationNo,
            RegistrationYear:result.RegistrationYear,
            VehicleTypeId:result.VehicleTypeId,
            DriverName:result.DriverName,
                IsShowDeviationChecked: !result.ShowDeviation,
                selectedEmailSubscriber: result.VehicleUsersDto,
                selectAllEmailSubscriber: result.VehicleUsersDto
            };
            (<FormGroup>this.vehicleEditForm).setValue(vehicleDetails, {
                onlySelf: true
            });
            if (result.VehicleUsersDto) {
                this.selectedEmailSubscriber = [];
                result.VehicleUsersDto.forEach((element: any) => {
                    let selectedUsers = this.vehicleUsers.find(x => x.Id === element.UserId);
                    if (selectedUsers) {
                        selectedUsers.status = true;
                        selectedUsers.isEmail = false;
                        this.checked(selectedUsers);
                        if (element.IsEmail) {
                            this.selectedEmailSubscriber.push({ Id: element.UserId });
                        }
                    }
                });
            }
            if (result.VehicleUsersDto) {
                this.vehicleEditForm.patchValue({
                    selectedEmailSubscriber: this.selectedEmailSubscriber
                });
            }
        }
    }
    
    bindDropdowns() {
        this.subscriptions.push(this.translate.stream('SELECT_TYPE_OF_VEHICLE').subscribe(val => {
            this.vehicleType = [];
            this.vehicleType.push(
                { label: val.SELECT, value: null },
                { label: val.PETROL,value: 1 },
                { label: val.DIESEL, value: 2 },
                { label: val.HYBRID, value: 3 },
                { label: val.ELECTRIC,value: 4 }
            );
        }));
    }

    saveVehicle() {
        let vehicle = new VehicleList();
        vehicle.Id = this.Id ? this.Id : 0;
        vehicle.ShowDeviation = !this.vehicleEditForm.value.IsShowDeviationChecked;
        vehicle.BusinessId = BaseServices.BusinessId;
        vehicle.BusinessDepartmentId = this.vehicleEditForm.value.BusinessDepartmentId;
        vehicle.Status = '1';
        vehicle.RegistrationNo = this.vehicleEditForm.value.RegistrationNo;
        vehicle.RegistrationYear = this.vehicleEditForm.value.RegistrationYear.toString();
        vehicle.DriverName = this.vehicleEditForm.value.DriverName;
        vehicle.Name = this.vehicleEditForm.value.VehicleName;
        vehicle.VehicleTypeId = this.vehicleEditForm.value.VehicleTypeId;
        vehicle.VehicleBrand = this.vehicleEditForm.value.BrandName;
        vehicle.VehicleModel = this.vehicleEditForm.value.ModelName;
        vehicle.VehicleGeoDeviceId = this.Vehicleformdata.VehicleGeoDeviceId;
        let userId = BaseServices.UserId;
        let name = BaseServices.userName;
        let checkUserId = this.selectedUsers.filter(x => x.Id === userId);
        if (checkUserId.length === 0) {
            this.selectedUsers.push({ Id: userId, Name: name });
        }
        if ((this.bindSelectedUsers) && this.vehicleEditForm.value.selectedEmailSubscriber) {
            vehicle.VehicleEmailSubscribers = [];
            this.emailSubscriber = this.vehicleEditForm.value
                .selectedEmailSubscriber;
        }
        if ((this.bindAllUsers) && this.vehicleEditForm.value.selectAllEmailSubscriber) {
            vehicle.VehicleEmailSubscribers = [];
            this.emailSubscriber = this.vehicleEditForm.value.selectAllEmailSubscriber;
        }

        let Ids: any = ([] = []);

        this.selectedUsers.forEach((element: any) => {
            Ids.push({
                UserId: element.Id,
                VehicleId: vehicle.Id,
                Name: element.Name,
                IsEmail: this.emailSubscriber.find(x => x.Id === element.Id) ? true : false
            });
        });
        vehicle.VehicleUsersDto = Ids;

        if (this.Id) {
            this.subscriptions.push(this.vehicleService.updateVehicle(vehicle).subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                    this.gotoList();
                }
            }));
        }
    }
    gotoList() {
        this.location.back();
    }
    departmentDropdown() {
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.departments = [];
            this.departments.push(
                { label: val.SELECT, value: null }
            );
        }));
        this.subscriptions.push(this.businessDepartmentService.getCachedBusinessDepartment().subscribe(result => {
            if (result) {
                result.forEach(element => {
                    this.departments.push({
                        label: element.Name,
                        value: element.Id
                    });
                    this.editDepartments.push({
                        Name: element.Name,
                        Id: element.Id
                    });
                });
            }
        }));
    }
    viewDept() {
        this.subscriptions.push(this.businessDepartmentService.getCachedBusinessDepartment().subscribe(result => {
            if (result) {
                this.editDepartments = result;
            }
        }));
    }
    saveDept() {
        let dept = new Department();
        dept.Id = this.selectedDepartment.Id ? this.selectedDepartment.Id : 0;
        dept.Name = this.selectedDepartment.Name;
        dept.Status = '1';
        dept.BusinessId = BaseServices.BusinessId;
        dept.ApplicationId = BaseServices.ApplicationId;
        if (dept.Id > 0) {
            this.subscriptions.push(this.vehicleService.updateDept(dept).subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                    this.viewDept();
                    this.departmentDropdown();
                    this.selectedDepartment = new Department();
                }
            }));
        } else {
            this.subscriptions.push(this.vehicleService.createDept(dept).subscribe((result:any) => {
                if (result) {
                    this.toasterComponent.callToast();
                    this.viewDept();
                    this.departmentDropdown();
                    this.DeptForm.reset();
                    this.vehicleEditForm.get('BusinessDepartmentId').patchValue(result.Id);
                    this.addDept=false;
                }
            }));
        }
    }

    editDept(DeptType: any) {
        this.selectedDepartment = DeptType;
    }

    deleteDepartment(department) {
        let deleteDeptId = department.Id;
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.vehicleService
                    .deleteDepartment(deleteDeptId)
                    .subscribe(result => {
                        this.departmentDropdown();
                        this.toasterComponent.callToastDlt();
                        this.viewDept();
                    }));
            }
        });
    }

    checkAll(event) {
        _.forEach(this.vehicleUsers, (item: any) => {
            if (event) {
                item.status = true;
            } else {
                item.status =
                    // to remain checked state for current user
                    (this.userId === +item.Id) ? true : false;
            }
        });

        this.selectedUsers = this.vehicleUsers;
        if (!event) {
            let userId = BaseServices.UserId;
            let name = BaseServices.userName;
            let checkUserId = this.selectedUsers.filter(x => x.Id === userId);
            if (checkUserId.length === 0) {
                this.userSubscribers = [];
                this.userSubscribers.push({ label: name, value: { Id: userId } });
            }
            this.selectedUsers = [];
            this.bindAllUsers = false;
            this.bindSelectedUsers = true;
        } else {
            this.bindAllUsers = true;
            this.bindSelectedUsers = false;
        }
    }
 
    checked(value) {
        if (value.status) {
            this.selectedUsers.push(value);
            if (this.emailUsers) {
                let selectedUsers = this.emailUsers.find(x => x.Id === value.Id);
                this.emailUsers.forEach((element: any) => {
                    if (element.Id === value.Id && value.Id !== BaseServices.UserId && (!value.isEmail)) {
                        this.userSubscribers.push({
                            label: element.Name,
                            value: { Id: element.Id }
                        });
                    }
                });
            }
        } else {
            _.remove(this.selectedUsers, (val) => {
                if (this.userSubscribers) {
                    _.forEach(this.userSubscribers, (item: any) => {
                        if (item.value.Id === value.Id) {
                            this.userSubscribers.pop();
                        }
                    });
                }
                return val === value;
            })

        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}

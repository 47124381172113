import { FeaturesBaseServices } from './../features/services/features-base.services';
import { EcplTabNavigationModule } from './../../shared/ecpl-tab-navigation/ecpl-tab-navigation.module';
import { FunctionListResolver } from './../contact/services/contact.resolver.service';
import {
    TimeTableEditResolver,
    EmployeeContactUserResolver
} from './../time-table/services/timetable.resolver.service';

import { BaseServices } from 'src/app/kuba/kuba.services';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FirstOfKinListResolver } from './services/employeefirstofkin.resolver.service';
import { BusinessCreatorResolver } from './../businesses/services/business.resolver.service';
import {
    EmpLeaveListResolver,
    EmpLeaveEditResolver,
    ApprovalPersonResolver,
    EmpLeaveByLeaveResolver,
    SicknessAbsenceCodeResolver,
    ResposibleExecutionResolver,
    BusinessHolidaysResolver
} from './services/employeeleave.resolver.service';
import { EmployeeFirstOfKinServices } from './services/employeefirstofkin.service';
import { ApprovalDocumentResolver, DocumentedTrainingResolver, EmployeePrivateDocResolver, MultiDocumentedTrainingResolver } from './services/employeeprivatedoc.resolver.service';
import { EmployeePrivateDocumentServices } from './services/employeeprivatedocument.service';
import {
    EmployeeWorkExperienceResolver,
    CountryCodeResolver
} from './services/employeeWorkExperience.resolver.service';
import { EmployeeWorkExperienceServices } from './services/employeeworkexperience.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FormsModule } from '@angular/forms';
import { AppSharedModule } from './../shared/appShared.module';
import { EmployeesComponent } from './employees.component';
import {
    EmployeeListResolver,
    EmployeeEditResolver,
    EmployeeSicknessListResolver,
    CourseEditResolver,
    CourseListResolver,
    ResponsiblePersonResolver,
    BusinessCourseResolver,
    BusinessContractResolver,
    EmployeeTimeTableResolver,
    DepartmentDropDownResolver,
    PositionDropDownResolver,
    EmployeeDropDownResolver,
    EmployeeLeaveResolver,
    EmployeeActiveStatusResolver,
    EmployeeInActiveStatusResolver,
    EmployeeDocResolver,
    EmployeeSicknessByIdResolver,
    PortalEmployeeListResolver,
    PortalCourseListResolver,
    PortalCourseResolver,
    BusinessAbsenceCodesBusinessResolver,
    UserDetailsResolver,
    EmployeeSubDepartmentResolver,
    EmployeeBusinessDataResolver,
    CourseListContractResolver,
    PortalContractListResolver
} from './services/employee.resolver.service';
import { EmployeeServices } from './services/employee.services';
import { EmployeeCourseServices } from './services/employeecourse.service';
import { EmployeeSicknessServices } from './services/employeesickness.service';
import { EmployeeLeaveService } from './services/employeeleave.service';
import { TimetableModule } from '../time-table/timetable.module';
import {
    EmployeeTabComponent,
    EmployeeListComponent,
    LeaveAllocateComponent,
    CourseComponent,
    CourseListComponent,
    TimetableComponent,
    PrivateDocumentComponent,
    DocumentedTrainingComponent,
    LeaveListComponent,
    CourseDetailComponent,
    LeaveEditComponent,
    EmployeeTimetableComponent,
    SicknessListComponent,
    WorkExpEditComponent,
    SicknessEditComponent
} from './components/index';
import { TimeTableListResolver } from 'src/app/kuba/employees/services/timetable.resolver.service';
import { EmployeeFormComponent } from './components/employee-form.component';
import { InfoComponent } from './components/info.component';
import { EcplDocumentExplorerModule } from 'src/app/shared/ecpl-document-explorer';
import { EcplDocumentTreeModule } from 'src/app/shared/ecpl-document-tree/ecpl-document-tree.module';
import { EcplPdfViewerModule } from 'src/app/shared/ecpl-pdf-viewer';
import { EcplImageViewerModule } from 'src/app/shared/ecpl-image-viewer';
import { EcplOnlyofficeViewerModule } from 'src/app/shared/ecpl-onlyoffice-viewer';
import { EcplChecklistViewerModule } from 'src/app/shared/ecpl-checklist-viewer';
import { EcplArticleViewerModule } from 'src/app/shared/ecpl-article-viewer/ecpl-article-viewer.module';
import { HttpClientModule } from '@angular/common/http';
import { MyDatePickerModule } from 'mydatepicker';
import { DateRangeWithModule } from '../shared/directives/date-range/date-range-with.module';
@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        CommonModule,
        HttpClientModule,
        AppSharedModule,
        EcplDocumentExplorerModule,
        EcplDocumentTreeModule,
        EcplTabNavigationModule,
        MyDatePickerModule,
        TimetableModule,
        EcplOnlyofficeViewerModule,
        EcplImageViewerModule,
        EcplArticleViewerModule,
        EcplChecklistViewerModule,
        EcplPdfViewerModule,
        OverlayPanelModule,
        DateRangeWithModule
    ],
    exports: [
        EmployeesComponent,
        EmployeeTabComponent,
        EmployeeListComponent,
        TimetableComponent,
        CourseComponent,
        CourseListComponent,
        PrivateDocumentComponent,
        DocumentedTrainingComponent,
        EmployeeFormComponent,
        InfoComponent,
        LeaveListComponent,
        RouterModule,
        CourseDetailComponent,
        LeaveEditComponent,
        EmployeeTimetableComponent,
        SicknessListComponent,
        WorkExpEditComponent,
        SicknessEditComponent
    ],
    declarations: [
        EmployeesComponent,
        EmployeeTabComponent,
        EmployeeListComponent,
        LeaveAllocateComponent,
        CourseComponent,
        CourseListComponent,
        TimetableComponent,
        PrivateDocumentComponent,
        DocumentedTrainingComponent,
        EmployeeFormComponent,
        InfoComponent,
        LeaveListComponent,
        CourseDetailComponent,
        LeaveEditComponent,
        EmployeeTimetableComponent,
        SicknessListComponent,
        WorkExpEditComponent,
        SicknessEditComponent
    ],
    providers: [
        EmployeeServices,
        EmployeeCourseServices,
        EmployeeSicknessServices,
        EmployeeLeaveService,
        EmployeeFirstOfKinServices,
        EmployeeListResolver,
        EmployeeEditResolver,
        EmployeeWorkExperienceServices,
        EmployeeWorkExperienceResolver,
        EmployeePrivateDocumentServices,
        EmployeePrivateDocResolver,
        ApprovalDocumentResolver,
        EmpLeaveListResolver,
        EmpLeaveEditResolver,
        TimeTableListResolver,
        TimeTableEditResolver,
        CountryCodeResolver,
        EmployeeSicknessListResolver,
        CourseEditResolver,
        CourseListResolver,
        ResponsiblePersonResolver,
        BusinessCourseResolver,
        BusinessContractResolver,
        ApprovalPersonResolver,
        EmployeeTimeTableResolver,
        DepartmentDropDownResolver,
        PositionDropDownResolver,
        EmployeeDropDownResolver,
        EmployeeLeaveResolver,
        EmployeeActiveStatusResolver,
        EmployeeInActiveStatusResolver,
        FirstOfKinListResolver,
        EmpLeaveByLeaveResolver,
        BaseServices,
        SicknessAbsenceCodeResolver,
        ResposibleExecutionResolver,
        EmployeeContactUserResolver,
        FunctionListResolver,
        EmployeeDocResolver,
        EmployeeSicknessByIdResolver,
        PortalEmployeeListResolver,
        PortalCourseListResolver,
        CourseListContractResolver,
        PortalContractListResolver,
        BusinessCreatorResolver,
        PortalCourseResolver,
        BusinessAbsenceCodesBusinessResolver,
        UserDetailsResolver,
        FeaturesBaseServices,
        BusinessHolidaysResolver,
        EmployeeSubDepartmentResolver,
        EmployeeBusinessDataResolver,
        DocumentedTrainingResolver,
        MultiDocumentedTrainingResolver
    ]
})
export class EmployeesModule { }

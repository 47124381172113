import { RiskAnalysisTopicCachedResolver } from './../follow-ups/services/risk-analysis.resolver.service';
import {
    DeviationUserListResolver,
} from './../users/services/user.resolver.service';
import {
    DeviationEditResolver,
    DeviationListResolver,
    DepartmentForAdminResolver,
    DeviationManualResolver,
    DeviationResponsibleAssignedUserResolver
} from './services/deviation.resolver';
import { ListDeviationComponent } from './components/deviation-list/deviation-list.component';
import { DeviationFormComponent } from './components/deviation.component';
import { EditDeviationComponent } from './components/deviation-edit/deviation-edit.component';
import { Route } from '@angular/router';
import { AddDeviationComponent } from './components/deviation-new/deviation-new.component';
import {
    FollowupCaseTypeResolver,
    AllDepartmentCachedResolver,
} from 'src/app/kuba/follow-ups/services/deviation.resolver.service';
export const deviationRoutes: Route[] = [
    {
        path: 'deviation',
        children: [
            {
                path: 'list',
                component: ListDeviationComponent,
                resolve: {
                    list: DeviationListResolver,
                    typeCase: FollowupCaseTypeResolver,
                    department: AllDepartmentCachedResolver,
                    createdBy: DeviationUserListResolver,
                    user: DeviationResponsibleAssignedUserResolver
                }
            },
            {
                path: 'edit/:deviationId',
                component: EditDeviationComponent,
                resolve: {
                    edit: DeviationEditResolver,
                    topic: RiskAnalysisTopicCachedResolver,
                    caseType: FollowupCaseTypeResolver,
                    department: AllDepartmentCachedResolver,
                    user: DeviationResponsibleAssignedUserResolver,
                    adminDepartment: DepartmentForAdminResolver,
                    manualList: DeviationManualResolver
                }
            },
            {
                path: 'add-deviation',
                component: AddDeviationComponent,
                resolve: {
                    topic: RiskAnalysisTopicCachedResolver,
                    caseType: FollowupCaseTypeResolver,
                    department: AllDepartmentCachedResolver,
                    user: DeviationResponsibleAssignedUserResolver
                }
            },
            {
                path: 'dev',
                component: DeviationFormComponent
            }
        ]
    }
];

<p-accordion>
    <p-accordionTab *ngFor="let feature of features; let i = index">
        <p-header>
            {{feature.Title}}
        </p-header>
        <p-checkbox [(ngModel)]="feature.IsChecked" binary="true" label="Select All"
            (click)="onTabOpen(i, feature.IsChecked)"></p-checkbox>
        <div *ngIf="feature.Widgets || feature.AdditionFeatures">
            <ul *ngIf="feature.Widgets">
                <li *ngFor="let widget of feature.Widgets">
                    <input type="checkbox" [(ngModel)]="widget.IsChecked"> {{widget.Title}}
                </li>
            </ul>
            <ul *ngIf="feature.AdditionFeatures">
                <li *ngFor="let afeature of feature.AdditionFeatures">
                    <input type="checkbox" [(ngModel)]="afeature.IsChecked"> {{afeature.Title}}
                </li>
            </ul>
        </div>
    </p-accordionTab>
    <button (click)="saveFeatures(features)">Save</button>
</p-accordion>
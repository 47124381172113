import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';
import { HelperService } from 'src/app/_services/helper.service';

@Injectable()
export class KubaExportService {

    constructor() { }
    /**
    * generate file
    * @param reportData {any}
    * @param columnNames {any}
    * @param type {any}
    * @param printMode {number} // 0-Portrait, 1-Landscape
    */
    generateExportFile(reportData: any, columnNames: any, type: string, headerName: any, printMode: number, businessId: number, cultureInfo: string) {
        if (printMode == undefined) {
            printMode = 0;
        }
        let dynamicType;
        if (type === 'PDF') {
            dynamicType = 'application/pdf';
        } else if (type === 'XLSX') {
            dynamicType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        } else if (type === 'DOCX') {
            dynamicType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        }

        if (reportData && Array.isArray(reportData)) {
            reportData.forEach(item => {
                if (item && item.Dob) {
                    item.Dob = HelperService.formatDate1(item.Dob);
                    }   
                
               if (item && (item.StartDateForFilter || item.EndDateForFilter)) {
                if (item.StartDateForFilter != null) {
                 item.StartDateForFilter = HelperService.formatDate2(item.StartDateForFilter);
                }
                if (item.EndDateForFilter != null) {
                  item.EndDateForFilter = HelperService.formatDate2(item.EndDateForFilter);
                }
                if (item.AprDate != null) {
                  item.AprDate = HelperService.formatDate2(item.AprDate);  
                }              
                   }

                if (item && (item.StartDate || item.EndDate || item.Renewal_Date || item.ExpectedEndDate || item.StartDate || item.DeadlineForPdf || item.CompletedDateForPdf || item.Deadline)){
                    if (item.StartDate != null) {
                           item.StartDate = HelperService.formatDate3(item.StartDate); 
                        }
                    if(item.EndDate != null){
                            item.EndDate = HelperService.formatDate3(item.EndDate);
                        } 
                    if(item.Renewal_Date != null){
                            item.Renewal_Date = HelperService.formatDate2(item.Renewal_Date);
                        } 
                    if(item.ExpectedEndDate != null){
                            item.ExpectedEndDate = HelperService.formatDate4(item.ExpectedEndDate);
                        } 
                    if(item.StartDate != null){
                            item.StartDate = HelperService.formatDate4(item.StartDate);
                        } 
                    if(item.DeadlineForPdf != null){
                            item.DeadlineForPdf = HelperService.formatDate2(item.DeadlineForPdf);
                        } 
                    if(item.CompletedDateForPdf != null){
                            item.CompletedDateForPdf = HelperService.formatDate2(item.CompletedDateForPdf);
                        }
                    if(item.Deadline != null){
                            item.Deadline = HelperService.formatDate3(item.Deadline);
                        }

                      }

                if (item && (item.EndDateForPdf || item.CreatedOnForPdf || item.CompletedDate || item.CreatedOn || item.DateForPdf || item.DeliveryOn || item.ModifiedOn)){
                    if (item.EndDateForPdf != null) {
                    item.EndDateForPdf = HelperService.formatDate2(item.EndDateForPdf); 
                    }
                    if (item.CreatedOnForPdf != null) {
                        item.CreatedOnForPdf = HelperService.formatDate2(item.CreatedOnForPdf); 
                    }
                    if (item.CompletedDate != null) {
                        item.CompletedDate = HelperService.formatDate3(item.CompletedDate); 
                    }
                    if (item.CreatedOn != null) {
                        item.CreatedOn = HelperService.formatDate3(item.CreatedOn); 
                    }
                    if (item.DateForPdf != null) {
                        item.DateForPdf = HelperService.formatDate2(item.DateForPdf); 
                    }
                    if (item.DeliveryOn != null) {
                        item.DeliveryOn = HelperService.formatDate5(item.DeliveryOn); 
                    }
                    if (item.ModifiedOn != null) {
                        item.ModifiedOn = HelperService.formatDate3(item.ModifiedOn); 
                    }
                      }
            })
        }
      
        let myData = JSON.stringify({ data: reportData, columnNames: columnNames });
        let url = `${environment.BASE_URL}/export/${type}/${headerName}/${printMode}/${businessId}/${cultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }


    generateRegistrationExportFile(reportData: any, columnNames: any, type: string, headerName: any, printMode: number, businessId: number, cultureInfo: string
        , featureId: string, featureKey: string) {
        if (printMode == undefined) {
            printMode = 0;
        }
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: reportData, columnNames: columnNames });
        let url = `${environment.BASE_URL}/export/registration`;
        let reportDetails = {
            Body: { data: reportData, columnNames: columnNames },
            Type: type,
            HeaderName: headerName,
            Mode: printMode,
            BusinessId: businessId,
            CultureInfo: cultureInfo,
            FeatureId: featureId,
            FeatureKey: featureKey
        }
        let data = JSON.stringify(reportDetails);
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(data);
        });
    }

    generateRegistrationAttachmentExportFile(reportData: any, columnNames: any, type: string, headerName: any, printMode: number, businessId: number, cultureInfo: string
        , featureId: string, featureKey: string, subFeatureId: string) {
        if (printMode == undefined) {
            printMode = 0;
        }
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: reportData, columnNames: columnNames });
        let url = `${environment.BASE_URL}/export/registration/attachment`;
        let reportDetails = {
            Body: { data: reportData, columnNames: columnNames },
            Type: type,
            HeaderName: headerName,
            Mode: printMode,
            BusinessId: businessId,
            CultureInfo: cultureInfo,
            FeatureId: featureId,
            FeatureKey: featureKey,
            SubFeatureId: subFeatureId
        }
        let data = JSON.stringify(reportDetails);
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(data);
        });
    }
}

import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ElectroEmergencyLightSystem } from '../../models/electroinstallation';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ElectroInstallationService } from '../../services/electro-installation.services';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
    selector: 'emergency-lighting',
    templateUrl: 'emergency-lighting.component.html'
})

export class EmergencyLightingComponent implements OnInit {

    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @Input()lighting: ElectroEmergencyLightSystem;
    contractId: number;
    @Output()saveForm: EventEmitter<any> = new EventEmitter<any>();
    @Output()deleteEquip: EventEmitter<any> = new EventEmitter<any>();
    editMode = false;
    editorName: string;
    page: string;
    createdOn: string;
    modifiedOn: string;
    otherEquipments: any;
    isGuestHideButton = true
    inspectionId: number;
    formData: any;
    private subscriptions: Subscription[] = [];
    constructor(
        private route: ActivatedRoute,
        private translate: TranslateService,
        private location: Location,
        private electroInstallationService: ElectroInstallationService) {
        this.formData = JSON.parse(sessionStorage.getItem('formData'));
        sessionStorage.removeItem('formData');
        if (+BaseServices.roleId === 4) {
            this.isGuestHideButton = false;
        }
    }

    ngOnInit() {
        this.inspectionId = +this.route.snapshot.parent.params['Iid'] ?
            +this.route.snapshot.parent.params['Iid'] : +this.route.snapshot.params['Iid'];
        this.contractId = this.route.snapshot.parent.params['Id'];
        this.otherEquipments = [
            {
                'id': 1,
                'itemRef':1,
                'no': "",
                'floor': "",
                'location': "",
                'type': "",
                'distributionCourse': "",
                'source': ""
            }];//Default other equipments object structure.
        this.initForm(this.lighting);
    }

    //toggle between edit mode.
    toggleEditMode() {
        this.editMode = !this.editMode;
    }

    /**
     * Emergency lighting form initialization.
     * @param lighting 
     */
    initForm(lighting?: ElectroEmergencyLightSystem) {
        if (lighting) {
            this.lighting = lighting;
            this.lighting.Version = lighting.Version ? lighting.Version : 1;
            this.editorName = lighting.EditorName ? lighting.EditorName : '';
            this.otherEquipments = <any>JSON.parse(lighting.OtherEquipment);
            //Converting a CreatedOn time to a string by using specified locale.
            if (this.lighting.CreatedOn) {
                this.createdOn = this.lighting.CreatedOn;
                let cDateObj = new Date(this.lighting.CreatedOn);
                this.lighting.CreatedOn = cDateObj.toLocaleDateString('en-GB');
            }
            //Converting a ModifiedOn time to a string by using specified locale.
            if (this.lighting.ModifiedOn) {
                this.modifiedOn = this.lighting.ModifiedOn;
                let mDateObj = new Date(this.lighting.ModifiedOn);
                this.lighting.ModifiedOn = mDateObj.toLocaleDateString('en-GB');
            }
        }
        else {
            this.lighting = new ElectroEmergencyLightSystem();
            if (this.inspectionId && this.formData) {
                this.lighting.Version = this.formData.Version;
            }
            else {
                this.lighting.Version = 1;
            }
        }
        this.lighting.DocumentNumber = "IKK-040506";
        this.page = "page 6 of 7";
    }

    /** add new equipment item to the equipment table.
     * @param item 
     */
    addItem(item) {
        let refHighVal = this.otherEquipments[0].itemRef;
        this.otherEquipments.forEach(e => {
            if(e.itemRef){
                refHighVal = refHighVal < e.itemRef ? e.itemRef : refHighVal;
            }else{
                e.itemRef = e.id;
            }
        });
        let itemsToShift = this.otherEquipments.splice(this.otherEquipments.indexOf(item) + 1, this.otherEquipments.length);
        let newItem = {
            'id': item.id + 1,
            'itemRef':refHighVal ?  refHighVal + 1 : item.id + 1,
            'no': "",
            'floor': "",
            'location': "",
            'type': "",
            'distributionCourse': "",
            'source': ""
        };
        //push new item to equipment table.
        this.otherEquipments.push(newItem);
        //push the spliced items after new item.
        itemsToShift.forEach(i => {
            ++i.id;
            this.otherEquipments.push(i);
        });
    }

    /** delete the item in equipment table.
     * @param item 
     */
    deleteItem(item) {
        this.otherEquipments.splice(this.otherEquipments.indexOf(item), 1);
        this.deleteEquip.emit(item.itemRef);
    }

    disableItemDelete(item){//return true when deviations are active under checkpoint
        if(this.lighting ? this.lighting.OtherEquipmentStatus : false){
           var itemStatus = JSON.parse(this.lighting.OtherEquipmentStatus).filter(x=>x.itemRef == item.itemRef);
           if(itemStatus ? itemStatus.length : false){
            return itemStatus[0].DeviationList ? (itemStatus[0].DeviationList.length) : false;
           }
           return false;
        }
        return false;
    }
    /* save emergency lighting details.
     */
    saveEmergencyLighting() {
        this.lighting.OtherEquipment = JSON.stringify(this.otherEquipments);
        this.lighting.BusinessId = BaseServices.BusinessId;
        if (this.lighting.Id > 0) {
            this.lighting.CreatedOn = this.createdOn;
            this.lighting.ModifiedOn = this.modifiedOn;
            this.lighting.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.electroInstallationService.updateEmergencyLighting(this.lighting).subscribe(result => {
                if (result) {
                    this.saveForm.emit(result.OtherEquipmentStatus);
                    this.lighting.OtherEquipmentStatus = result.OtherEquipmentStatus;
                    this.toasterComponent.callToast();
                }
            }));
        }
        else {
            this.lighting.InspectionId = this.inspectionId;
            this.lighting.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.electroInstallationService.saveEmergencyLighting(this.lighting).subscribe(result => {
                if (result) {
                    this.lighting.Id = result.Id;
                    this.createdOn = result.CreatedOn;
                    this.saveForm.emit(result.OtherEquipmentStatus);
                    this.lighting.OtherEquipmentStatus = result.OtherEquipmentStatus;
                    this.toasterComponent.callToast();
                }
            }));
        }
    }

    /**
     * save lighting and go back to list page.
     */
    saveBackToList() {
        this.saveEmergencyLighting()
        setTimeout(() => {
            this.location.back();
        }, 2000);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }

}
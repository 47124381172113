<div>
  <form [formGroup]="adminUserForm">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group" *ngIf="adminUserForm.controls['Name']">
          <label for="Name">
            <span translate>NAME</span>
            <span class="required">*</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              required
              formControlName="Name"
              id="Name"
              maxlength="100"
            />
          </div>
          <control-messages
            [control]="adminUserForm.controls['Name']"
          ></control-messages>
        </div>
        <div class="form-group" *ngIf="adminUserForm.controls['Username']">
          <label for="Username">
            <span translate>USERNAME</span>
            <span class="required">*</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              required
              formControlName="Username"
              id="Username"
              maxlength="100"
            />
          </div>
          <control-messages
            [control]="adminUserForm.controls['Username']"
          ></control-messages>
        </div>
        <div class="form-group" *ngIf="adminUserForm.controls['Email']">
          <label for="Email">
            <span translate>EMAIL</span>
            <span class="required">*</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              formControlName="Email"
              id="Email"
              required
              maxlength="100"
            />
          </div>
          <control-messages
            [control]="adminUserForm.controls['Email']"
          ></control-messages>
        </div>
        <div formGroupName="newPasswordValidation" *ngIf="isNewUser">
          <div class="form-group">
            <label for="Password">
              <span translate>PASSWORD</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="password"
                pInputText
                formControlName="Password"
                id="Password"
              />
            </div>
            <control-messages
              [control]="adminUserForm.controls['Password']"
            ></control-messages>
          </div>
          <div class="form-group">
            <label for="ConfirmPassword">
              <span translate>CONFIRM_PASSWORD</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="password"
                pInputText
                formControlName="ConfirmPassword"
                id="ConfirmPassword"
              />
            </div>
            <control-messages
              [control]="adminUserForm.controls['ConfirmPassword']"
            ></control-messages>
          </div>
        </div>
        <div class="form-group">
          <label for="Mobile">
            <span translate>MOBILE</span>
            <span class="required">*</span>
          </label>
          <div class="phone-field">
            <p-dropdown
              [filter]="true"
              [style]="{ width: '110px' }"
              formControlName="PhoneCode"
              [options]="PhoneCode"
            ></p-dropdown>
            <input
              style="width: 56%"
              type="number"
              pInputText
              required
              formControlName="Mobile"
              (keypress)="onKeyChange($event, 11)"
            />
            <button
              class="btn btn-outline-primary"
              type="button"
              (click)="addType = !addType"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
          <control-messages
            [control]="adminUserForm.controls['Mobile']"
          ></control-messages>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label for="Address">
            <span translate>ADDRESS</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              formControlName="Address"
              maxlength="250"
            />
          </div>
        </div>
        <div class="form-group">
          <label for="ZipCode">
            <span translate>ZIP_CODE</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              formControlName="ZipCode"
              (keypress)="onKeyChange($event, 20)"
              onpaste="return false;"
            />
          </div>
        </div>
        <div class="form-group">
          <label for="postalAddress">
            <span translate>POSTAL_ADDRESS</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              formControlName="PostalAddress"
              maxlength="250"
            />
          </div>
        </div>
        <div class="upload-file-container">
          <label for="Avatar">
            <span translate>USER_IMAGE</span>
          </label>
          <div class="form-group">
            <div class="ui-g-6">
              <div class="upload-file-container">
                <div class="upload-actions">
                  <input
                    type="file"
                    id="files"
                    class="hidden"
                    (change)="filesSelectForLogo($event)"
                  />
                  <label for="files" class="btn btn-outline-primary" translate
                    >UPLOAD_IMAGE</label
                  >
                </div>
                <div *ngIf="uploadStartForLogo">
                  <span translate>IMAGE_IS_UPLOADING</span>
                </div>
                <div>
                  <img src="{{ userImage }}" alt="" width="200px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<p-dialog
  header="{{ 'ADD_COUNTRY_CODE' | translate }}"
  [(visible)]="addType"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
>
  <form [formGroup]="countryCodeForm">
    <div class="col-sm-6">
      <div class="form-group">
        <label>
          <span translate>COUNTRY_CODE</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="text"
            pInputText
            formControlName="CountryCode"
            (keypress)="onKeyChange($event, 8)"
            onpaste="return false;"
          />
        </div>
        <control-messages
          [control]="countryCodeForm.controls['CountryCode']"
        ></control-messages>
        <div>
          <span style="color: red" *ngIf="isNameExists">{{
            existsAlerttext
          }}</span>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="form-group">
        <label>
          <span translate>COUNTRY_NAME</span>
          <span class="required">*</span>
        </label>
        <div>
          <input type="text" pInputText formControlName="CountryName" />
        </div>
        <control-messages
          [control]="countryCodeForm.controls['CountryName']"
        ></control-messages>
      </div>
    </div>
    <div>
      <span style="color: red" *ngIf="isCountryExists">{{
        countryExisting
      }}</span>
      <span style="color: red" *ngIf="isNameExists">{{ existsAlerttext }}</span>
    </div>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="viewCountryCode = !viewCountryCode"
        >
          <span translate>VIEW</span>
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="saveCountryCode()"
          [disabled]="!countryCodeForm.valid"
        >
          <span translate>SAVE</span>
        </button>
      </div>
      <div *ngIf="viewCountryCode">
        <div class="table-view">
          <!-- Table starts -->
          <p-table
            [value]="countryCodeList"
            [rows]="10"
            [paginator]="true"
            [pageLinks]="3"
            [rowsPerPageOptions]="[5, 10, 20]"
            [responsive]="true"
          >
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th>{{ "CODE_PHONE" | translate }}</th>
                <th>{{ "NAME" | translate }}</th>
                <th></th>
              </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-adminuser>
              <tr>
                <td>
                  <span>{{ adminuser.Phonecode }}</span>
                </td>
                <td>
                  <span>{{ adminuser.Name }}</span>
                </td>
                <td class="col-button">
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'EDIT' | translate }}"
                    (click)="editCountryCode(adminuser)"
                  >
                    <i class="icon ic-sm icon-pencil-tip"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'DELETE' | translate }}"
                    (click)="deleteCode(adminuser)"
                  >
                    <i class="icon ic-sm icon-trash"></i>
                  </button>
                </td>
              </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td colspan="3">
                  {{ "NO_RECORDS_FOUND" | translate }}
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- Table ends -->
        </div>
      </div>
    </p-footer>
  </form>
</p-dialog>
<toaster-component></toaster-component>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>

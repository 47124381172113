import { APP_BASE_HREF } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { InitialServices } from '../_services/initial.services';
import { AccessLog } from '../kuba/accesslog/models/accesslog';
import { BaseServices, KubaServices } from '../kuba/kuba.services';

import { AlertService } from '../_services/alert.service';
import { AuthenticationService } from '../_services/authentication.service';
import { Observable } from 'rxjs';
import { AccessLogServices } from './../kuba/accesslog/services/accesslog.services';
import { CustomizeHomePageService } from './../kuba/customize-homepage/customize-homepage.service';
import { CacheHandlerService } from './../kuba/cached.services';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
  selector: 'login-original',
  templateUrl: 'login-original.component.html',
})
export class LoginOriginalComponent implements OnInit {
  accesslog: any;
  model: any = {};
  loading = false;
  returnUrl: string;
  baseUrl: string;
  userSettings: any;
  deviceInfo = null;
  acessLog: AccessLog[];
  customId: number;
  businessId: number;
  Content: string;
  header: boolean;
  private subscriptions: Subscription[] = [];
  ipAddress: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private accessLogServices: AccessLogServices,
    private alertService: AlertService,
    private initialServices: InitialServices,
    private kubaServices: KubaServices,
    private cacheHandlerService: CacheHandlerService,
    @Inject(APP_BASE_HREF) private baseHref: string,
    @Inject(DOCUMENT) private document: any,
    private customizeHomePageService: CustomizeHomePageService,
    private http: HttpClient
  ) {
    this.getIPAddress();
  }

  ngOnInit() {
    this.header = true;
    this.businessId = +this.route.snapshot.params['businessId'];
    this.subscriptions.push(
      this.customizeHomePageService
        .getData(this.businessId, 45, BaseServices.UserId)
        .subscribe((homePageData: any) => {
          if (homePageData) {
            this.Content = homePageData.ContentData.replace('<p>', '').replace(
              '</p>',
              ''
            );
          }
        })
    );
    this.initialServices.appInitializing();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/app';
  }

  changeNavigation(cmsId: number) {
    this.header = true;
    this.customId = cmsId;
    this.subscriptions.push(
      this.customizeHomePageService
        .getData(this.businessId, this.customId, BaseServices.UserId)
        .subscribe((homePageData: any) => {
          if (homePageData) {
            this.Content = homePageData.ContentData.replace('<p>', '').replace(
              '</p>',
              ''
            );
          }
        })
    );
  }
  getIPAddress() {
    this.http.get('http://api.ipify.org/?format=json').subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }
  login() {
    this.loading = true;
    this.subscriptions.push(
      this.authenticationService
        .login(this.model.username, this.model.password, this.ipAddress)
        .subscribe(
          (res) => {
          },
          (error) => {
            if (error.status === 0) {
              this.alertService.error('User may not available.');
            }
            if (error.status !== 0) {
              this.alertService.error(error._body);
            }
          }
        )
    );
  }
  /**
   * access log for hit counts
   */
  addAccessLog() {
    let user = new AccessLog();
    user.UserId = BaseServices.UserId;
    user.BusinessId = BaseServices.BusinessId;
    user.IP = '';
    user.Browser = '';
    user.OS = '';
    user.ApplicationId = BaseServices.ApplicationId;
    user.Type = 'Web';
    user.LogIn = new Date();
    this.subscriptions.push(
      this.accessLogServices.addAccessLog(user).subscribe((accsess: any) => {})
    );
  }
  goToForgetPassword() {
    this.router.navigate(['./../forget-password'], { relativeTo: this.route });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}

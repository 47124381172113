import { Route } from '@angular/router';
import { AuthGuard } from 'src/app/_guards';
import { ClientListResolver } from '../client/services/client.resolver.service';
import { elChecklistRoutes } from '../el-checklist/elChecklist.routing';
import { FeatureDocumentRoutes } from '../feature-documents/feature-document.routing';
import { deviationRoutes } from './../deviation/deviation.routing';
import { measureprotocolRoutes } from './../measure-protocol/measure-protocol.routing';
import { registrationRoutes } from './../Registration/registration.routing';
import { safetyjobRoutes } from './../safety-job-analysis/jobanalysis.routing';
import { taskmanagerRoutes } from './../task-manager/task-manager.routing';
import { timetableRoutes } from './../time-table/timetable.routing';
import {
  EmployeeContactUserResolver,
  EmployeeContactUserSubscriptionResolver,
} from './../users/services/user.resolver.service';
import { ELFormComponent } from './components/el-form.component';
import { ELListHeaderComponent } from './components/el-list-header.component';
import { ELListComponent } from './components/el-list.component';
import { ELViewComponent } from './components/el-view.component';
import { ELComponent } from './el.component';
import {
  ELEditResolver,
  ELFeaturesResolver,
  ELListByUserResolver,
  ELListResolver,
} from './services/el.resolver';

export const elRoutes: Route[] = [
  {
    path: 'kunde_EL',
    component: ELComponent,
    children: [
      {
        path: 'list',
        component: ELListHeaderComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'own',
            component: ELListComponent,
            canActivate: [AuthGuard],
            resolve: {
              list: ELListByUserResolver,
            },
          },
          {
            path: 'all',
            component: ELListComponent,
            canActivate: [AuthGuard],
            resolve: {
              list: ELListResolver,
            },
          },
        ],
      },
      {
        path: 'edit/:id',
        component: ELFormComponent,
        canActivate: [AuthGuard],
        resolve: {
          edit: ELEditResolver,
          clients: ClientListResolver,
          users: EmployeeContactUserResolver,
          elList: ELListResolver,
          users_subscription: EmployeeContactUserSubscriptionResolver,
        },
      },
      {
        path: 'new',
        component: ELFormComponent,
        resolve: {
          clients: ClientListResolver,
          users: EmployeeContactUserResolver,
          elList: ELListResolver,
          users_subscription: EmployeeContactUserSubscriptionResolver,
        },
      },
      {
        path: 'details/:id',
        component: ELViewComponent,
        data: { parent: 'KUNDE_EL' },
        resolve: {
          additionalFeatures: ELFeaturesResolver,
          el: ELEditResolver,
        },
        canActivate: [AuthGuard],
        children: [
          ...FeatureDocumentRoutes,
          ...deviationRoutes,
          ...taskmanagerRoutes,
          ...timetableRoutes,
          ...safetyjobRoutes,
          ...registrationRoutes,
          ...measureprotocolRoutes,
          ...elChecklistRoutes,
        ],
      },
    ],
  },
];

import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';
import { BusinessAgreement, BusinessEdit, BusinessList } from '../models';

import { shareReplay, tap } from 'rxjs/operators';

@Injectable()
export class BusinessServices {
  _business: Observable<any> = null!;

  constructor(private http: HttpClient) {}

  clearCachedBusinessService() {
    this._business = null!;
  }
  // List all business

  list(): Observable<BusinessList[]> {
    this.clearCachedBusinessService();

    if (!this._business) {
      this._business = this.http
        .get<BusinessList[]>(
          environment.BASE_URL + '/businesses',
          BaseServices.headerOption
        )
        .pipe(
          tap((result) => {}),
          shareReplay(1)
        );
    }
    return this._business;
  }

  // List all business
  activeBusinesslist() {
    this.clearCachedBusinessService();
    if (!this._business) {
      return this.http
        .get(
          environment.BASE_URL + '/activebusinesses',
          BaseServices.headerOption
        )
        .map((result) => result)
        .pipe(shareReplay(1));
    }
    return this._business;
  }
  // retrive a business for editing and View
  getById(id: number) {
    return this.http
      .get<any>(
        environment.BASE_URL + '/business/' + id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  getBusinessForFeature(id: number) {
    return this.http
      .get(environment.BASE_URL + '/business/' + id, BaseServices.headerOption)
      .map((result: any) => {
        if (result) {
          let data = result;
          let featureBase = BaseServices.getUserFeatures();
          featureBase.Features = data.Features;
          featureBase.Type = 'business';
          featureBase.Details = result;
          let val: any = null;
          return val;
        }
      });
  }
  create(newBusiness: BusinessEdit) {
    return this.http
      .post(
        environment.BASE_URL + '/business',
        newBusiness,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  update(id: number, ExistingBusiness: BusinessEdit) {
    return this.http
      .put(
        environment.BASE_URL + '/business/' + id,
        ExistingBusiness,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  deleteById(id: number, newBusiness: BusinessEdit) {
    return this.http
      .put(
        `${environment.BASE_URL}/businessDelete/${id}`,
        newBusiness,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  // retrive a agreement for a business
  getAgreementByBusinessId(businessId: number) {
    return this.http
      .get<any>(
        environment.BASE_URL + '/licences/' + businessId,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  /**
   * Gets a business' parent portal id
   * @param businessId Id of business to fetch from
   * @returns Portal Id
   */
  getBusinessPortalId(businessId: number) {
    return this.http
      .get<number>(
        `${environment.BASE_URL}/business/${businessId}/portal`,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  getUsersByBusiness(businessId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/userbybusiness/' + businessId,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }

  add(newBusinessAgreement: BusinessAgreement) {
    return this.http
      .post(
        environment.BASE_URL + '/licenceadd',
        newBusinessAgreement,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  updateAgreement(newBusinessAgreement: BusinessAgreement) {
    return this.http
      .put(
        environment.BASE_URL + '/licenceupdate',
        newBusinessAgreement,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  getHseManager(businessId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/hsemanager/' + businessId,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }

  getPortalContact(businessId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/portalcontact/' + businessId,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  getHpdOptions(businessId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/hpdOptions/' + businessId,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }

  getAllUsers() {
    return this.http
      .get(environment.BASE_URL + '/users', BaseServices.headerOption)
      .map((result) => result);
  }
  deleteUser(id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/adminuser/' + id,
        BaseServices.headerOption
      )
      .map((response: any) => {
        return response;
      });
  }
  // Active and Inactive the Business
  statusUpdate(id: number, status: string) {
    return this.http
      .put(
        environment.BASE_URL + '/businessUpdate/' + id + '/' + status,
        status,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  getOrganizationNumber(number: number) {
    return this.http
      .get(
        environment.BASE_URL + '/checkOrgNumber/' + number,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  getBusinessByOrgNo(orgNumber: number) {
    return this.http
      .get(
        environment.BASE_URL + '/checkOrgNumber/' + orgNumber,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  getByUserId(id: number) {
    return this.http
      .get(environment.BASE_URL + '/user/' + id, BaseServices.headerOption)
      .map((result) => result);
  }
  updatePassword(userCredential: any) {
    return this.http
      .put(
        environment.BASE_URL + '/update-password',
        userCredential,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  updatePasswordAndSendSMS(userCredential: any, languageId: Number) {
    return this.http
      .put(
        environment.BASE_URL + '/update-password-sendsms/' + languageId,
        userCredential,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  addUsersAndSendSMS(userDetails: any) {
    return this.http
      .post(
        environment.BASE_URL +
          '/save-user-sendsms/' +
          BaseServices.userLanguageId,
        userDetails,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  addProjectUsers(projectUserListDto: any) {
    return this.http
      .post(
        environment.BASE_URL + '/project/project-user',
        projectUserListDto,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  addFDVUsers(fdvUserListDto: any) {
    return this.http
      .post(
        environment.BASE_URL + '/project/fdv-user',
        fdvUserListDto,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  /**
   * handling multiple service
   * @param fdvUserListDto
   * @param projectUserListDto
   */
  saveProjectFDVforGuest(
    fdvUserListDto: any,
    projectUserListDto: any,
    uid: number
  ): Observable<any[]> {
    let fdvuserRes = this.http.post(
      environment.BASE_URL + '/project/fdv-user/' + uid,
      fdvUserListDto,
      BaseServices.headerOption
    );
    let projectuserRes = this.http.post(
      environment.BASE_URL + '/project/project-user/' + uid,
      projectUserListDto,
      BaseServices.headerOption
    );
    return forkJoin([fdvuserRes, projectuserRes]);
  }

  assignKundeELUsers(kundeELUserListDto: any) {
    return this.http
      .post(
        environment.BASE_URL + '/kundeEL/kundeel-user',
        kundeELUserListDto,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  reportGenerate(
    businessId: number,
    type: number,
    cultureInfo: string,
    ReportJson: any,
    applicationId: number
  ) {
    let dynamicType = 'application/pdf';
    let myData = JSON.stringify({ data: ReportJson });
    let url = `${environment.BASE_URL}/export-ourcompanydetailslist/${businessId}/${type}/${cultureInfo}/${applicationId}`;
    return Observable.create((observer: any) => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      let session = JSON.parse(sessionStorage.getItem('session'));
      let type = 'Bearer';
      let token = session.AccessToken;
      const tokenParts = token.split('.');
      tokenParts[1] =
        tokenParts[1].substring(0, 5) + 'W' + tokenParts[1].substring(5);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', type + ' ' + token);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }

  getBusinessCreator(businessId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/businesscreator/' + businessId,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }

  businessDepartments(BusinessId: number) {
    return this.http
      .get<HttpHeaderResponse>(
        environment.BASE_URL +
          '/businessdepartments/' +
          BusinessId +
          '/' +
          BaseServices.ApplicationId,
        BaseServices.headerOption
      )
      .map((response: HttpHeaderResponse) => {
        return response;
      });
  }

  getAllLicenseCategory() {
    return this.http
      .get<HttpHeaderResponse>(
        environment.BASE_URL + '/licensecategory/getall',
        BaseServices.headerOption
      )
      .map((response: HttpHeaderResponse) => {
        return response;
      });
  }
  checkclientNum(clientNum: string) {
    return this.http
      .get(
        environment.BASE_URL +
          '/electro/check-clientnum/' +
          BaseServices.BusinessId +
          '/' +
          clientNum,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  getAllAdminBusinesses() {
    return this.http
      .get<HttpHeaderResponse>(
        environment.BASE_URL + '/businesses',
        BaseServices.headerOption
      )
      .map((response: HttpHeaderResponse) => {
        return response;
      });
  }
  getAllPortalBusiness(portalId: number, userId: number) {
    return this.http
      .get<HttpHeaderResponse>(
        environment.BASE_URL + '/assignedbusiness/' + portalId + '/' + userId,
        BaseServices.headerOption
      )
      .map((response: HttpHeaderResponse) => {
        return response;
      });
  }

  getPortalRiskAnalysis(userId: number) {
    return this.http
      .get<any>(
        environment.BASE_URL + '/portalriskanalysis/' + userId,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  getAllUsersForResponsible(
    BusinessId: number,
    parentKey: any,
    featureId: any
  ) {
    return this.http
      .get<HttpHeaderResponse>(
        environment.BASE_URL +
          '/usersforresponsible/' +
          BaseServices.BusinessId +
          '/' +
          parentKey +
          '/' +
          featureId,
        BaseServices.headerOption
      )
      .map((response: HttpHeaderResponse) => {
        return response;
      });
  }

  getUsersCount(businessId: number) {
    return this.http
      .get<HttpHeaderResponse>(
        environment.BASE_URL + '/countOfUsers' + '/' + businessId,
        BaseServices.headerOption
      )
      .map((response: HttpHeaderResponse) => {
        return response;
      });
  }

  getEmployeesCount(businessId: number) {
    return this.http
      .get<HttpHeaderResponse>(
        environment.BASE_URL + '/activeemployeesCount' + '/' + businessId,
        BaseServices.headerOption
      )
      .map((response: HttpHeaderResponse) => {
        return response;
      });
  }

  LeftNavigationAssignAccess(
    businessId: number,
    appId: any,
    currentUserRole: any
  ) {
    return this.http
      .get(
        environment.BASE_URL +
          '/leftNavigationAssignAccess/' +
          businessId +
          '/' +
          appId +
          '/' +
          currentUserRole +
          '/' +
          BaseServices.UserId,
        BaseServices.headerOption
      )
      .map((res) => res);
  }
  getIsPortalBusiness(bid: number) {
    return this.http
      .get(
        environment.BASE_URL + '/Features-IsPortalBusiness/' + bid,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
}

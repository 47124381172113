import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ElectroService } from '../../services/electro.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { HelperService } from 'src/app/_services/helper.service';
import { ConfirmationService } from 'primeng/api';
import { Status, Rights } from 'src/app/_models';
import { ElectroDocumentService } from '../../services/electro-document.services';
import { KubaExportService } from 'src/app/kuba/shared/kuba-exports/kuba-export.service';
import { Module } from 'src/app/kuba/follow-ups/models/deviation';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
    selector: 'deviation-overview-list',
    templateUrl: 'deviation-overview-list.component.html'
})

export class DeviationOverviewListComponent implements OnInit {
    isGuestHideButton = true;
    deviationList: any;
    statuses: any;
    importance: any;
    execution: any;
    inspectionId: number;
    private subscriptions: Subscription[] = [];
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @ViewChild('deviationTable',{static: false}) deviationTable: Table;
    showLoader: boolean;
    additionalData = {
        fileName: this.translate.instant('DEVIATION_LIST'),
        header: this.translate.instant('DEVIATION_LIST'),
        businessId: BaseServices.BusinessId,
        cultureInfo: BaseServices.userCultureInfo(),
        columnNames: [
            { title: 'Inspection no ', dataKey: 'InspectionNumber' },
            { title: 'Deviation no ', dataKey: 'DeviationNumber' },
            { title: 'Title', dataKey: 'Title' },
            { title: 'Building', dataKey: 'BuildingNum' },
            { title: 'Location', datakey: 'Location' },
            { title: 'Description', datakey: 'Description' },
            { title: 'Status', dataKey: 'StatusText' },
            { title: 'Importance', dataKey: 'ImportanceText' },
            { title: 'Deadline', dataKey: 'Deadline' },
            { title: 'Responsible for Execution', dataKey: 'ResponsiblePersonName' }
        ]
    };
    constructor(public router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private confirmationService: ConfirmationService,
        private electroService: ElectroService,
        private electroDocumentServices: ElectroDocumentService,
        private kubaExportService: KubaExportService ) {
        if (+BaseServices.roleId === 4) {
            this.isGuestHideButton = false;
        }
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.SERVICE_LEADER_ELECTRO;
        if (+BaseServices.roleId === 5) {
            this.isGuestHideButton = BaseServices.checkUserRights(userRightsId,currentUserRole);
        }
    }

    ngOnInit() {
        this.deviationList = this.route.snapshot.data['deviations'];
        if(+BaseServices.roleId === 4 && BaseServices.FeatureId === Module.CLIENT_CONTACT){
            if(BaseServices.checkUserRights(Rights.HIDE_DEVIATIONS,BaseServices.UserRole)){
                this.deviationList = [];
            }
        }
        this.inspectionId = +this.route.snapshot.parent.parent.params['Iid'];
        this.bindDropdowns();
        this.bindStatusIcon(this.deviationList);
    }

    bindDropdowns() {
        this.subscriptions.push(this.translate.stream('SELECT_FOR_STATUS').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
                { label: val.SELECT, value: '' },
                { label: val.OPEN, value: 8 },
                { label: val.CLOSED, value: 9 },
            );
        }));

        this.subscriptions.push(this.translate.stream('RISKFIGURE_DROPDOWN').subscribe(val => {
            this.importance = [];
            this.importance.push(
                { label: val.SELECT, value: '' },
                { label: val.LOW, value: 1 },
                { label: val.MEDIUM, value: 2 },
                { label: val.HIGH, value: 3 },
            );
        }));
        let execution = this.route.snapshot.data['executionpersons'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.execution = [];
            this.execution.push(
                { label: val.SELECT, value: '' });
        }));
        if (execution) {
            execution.forEach(person => {
                this.execution.push({ label: person.Name, value: person.Id });
            });
        }
    }
    /**
     *
     * bind status icon by status
     * @param deviation {any}
     */
    bindStatusIcon(deviation: any) {
        this.deviationList = [];
        if (deviation) {
            deviation.forEach((deviationInfo: any) => {
                let daysDiff = 0;
                let statusIcon = '';
                let currentdate = new Date();
                let deadLine = new Date(deviationInfo.Deadline);
                if (deviationInfo.Deadline != null) {
                    daysDiff = HelperService.getDaysDiff(currentdate, deadLine);
                }
                if (deviationInfo.Status === Status.Done) {
                    statusIcon = 'icon ic-sm icon-clear-deadline';
                } else if (deviationInfo.Deadline) {
                    if (daysDiff > 3) {
                        statusIcon = 'icon ic-sm icon-far-deadline';
                    } else if (daysDiff > 0 && daysDiff <= 3) {
                        statusIcon = 'icon ic-sm icon-near-deadline';
                    } else {
                        statusIcon = 'icon ic-sm icon-over-deadline';
                    }
                } else {
                    statusIcon = 'icon ic-sm icon-over-deadline';
                }
                deviationInfo.StatusIcon = statusIcon;
                this.deviationList.push(deviationInfo);
            });
        }
    }

    /**
     * custom status filter
     * @param e 
     * @param statusDropdown 
     */
    onStatusChange(e: any, statusDropdown: any) {
        if (e.value) {
            this.deviationTable.filter(
                +statusDropdown.value,
                'Status',
                'equals'
            );
        }
        else {
            this.deviationTable.filter(null, 'Status', 'equals');
        }
    }

    /**
     * Importance filter
     * @param e 
     * @param impDropdown 
     */
    onImportanceChange(e: any, impDropdown: any) {
        if (e.value) {
            this.deviationTable.filter(
                +impDropdown.value,
                'Importance',
                'equals'
            );
        }
        else {
            this.deviationTable.filter(null, 'Importance', 'equals');
        }

    }

    /**
     * custom responsible person filter
     * @param e 
     * @param resp 
     */
    onResponsibleChange(e: any, resp: any) {
        if (e.value) {
            this.deviationTable.filter(
                +resp.value,
                'ExecutionUserId',
                'equals'
            );
        } else {
            this.deviationTable.filter(null, 'ExecutionUserId', 'equals');
        }
    }

    /**
     * Delete deviation by id.
     * @param id 
     */
    deleteDeviation(id: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.electroService.deleteDeviation(id).subscribe(result => {
                    //bind list after deletion of deviation.
                    this.subscriptions.push(this.electroService.getDeviationByBusiness().subscribe(result => {
                        this.deviationList = result;
                        this.bindStatusIcon(this.deviationList);
                    }));
                    this.toasterComponent.callToastDlt();
                }));
            }
        });
    }

    DeviationReport(id){
        this.showLoader = true;
        let type = 'pdf';
        let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
        let languageId = appSettings.Language.LanguageId;
        let CultureInfo = BaseServices.userCultureInfo();
        this.subscriptions.push(this.electroDocumentServices
            .generateDeviationReport(id,BaseServices.UserId, CultureInfo)
            .subscribe(
                blob => {
                    this.showLoader = false;
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${this.translate.instant('DEVIATION_REPORT')}.${type.toLowerCase()}`;
                    link.click();
                },
                error => {
                    this.showLoader = false;
                    alert('Export not downloaded');
                }));
    }
    DownloadListPDF(){
        this.showLoader = true;
        let CultureInfo = sessionStorage.getItem('languageMode')
        let reportData = this.deviationTable.filteredValue ? this.deviationTable.filteredValue : this.deviationTable.value;
        this.subscriptions.push(this.kubaExportService
            .generateExportFile(reportData, this.additionalData.columnNames,
                 'PDF', this.additionalData.header, 1, BaseServices.BusinessId, CultureInfo)
            .subscribe(
                blob => {
                    this.showLoader = false;
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${this.translate.instant(this.additionalData.fileName)}.${('PDF').toLowerCase()}`;
                    link.click();
                },
                error => {
                    this.showLoader = false;
                    alert(this.translate.instant('EXPORT_NOT_DOWNLOAD'));
                }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}
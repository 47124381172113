import { NgModule } from '@angular/core';
import { AppSharedModule } from './../shared/appShared.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserComponent } from './user.component';
import { UserEditComponent } from './components/userEdit.component';
import { UserFormComponent } from './components/userform.component';
import { UserListComponent } from './components/userList.component';
import { BusinessFeatureService } from './../businesses/services/businessFeatures.service';
import { UserRightsComponent } from './components/userRights.component';
import { UserService } from './services/user.service';
import {
    UserListResolver,
    UserEditResolver,
    UserRoleResolver,
    DeviationUserListResolver
} from './services/user.resolver.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
    imports: [
        HttpClientModule,
        RouterModule,
        CommonModule,
        AppSharedModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        DialogModule,
        TableModule,
        DropdownModule,
        ConfirmDialogModule,
        CheckboxModule
    ],
    exports: [
        UserComponent,
        UserListComponent,
        UserRightsComponent,
        UserEditComponent,
        UserFormComponent,
    ],
    declarations: [
        UserComponent,
        UserListComponent,
        UserRightsComponent,
        UserEditComponent,
        UserFormComponent,
    ],
    providers: [
        UserService,
        BusinessFeatureService,
        UserListResolver,
        UserEditResolver,
        UserRoleResolver,
        DeviationUserListResolver
    ]
})
export class UserModule { }

import { filter } from 'rxjs/operator/filter';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  FormArray,
} from '@angular/forms';
import { SelectItem, ConfirmationService } from 'primeng/api';
import * as _ from 'lodash';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { AdminUserService } from './../../adminuser/services/adminuser.service';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { environment } from 'src/environments/environment';
import { UserRole } from './../../follow-ups/models/deviation';
import { FeatureKey } from './../../../_models/feature';
import { FeaturesBaseServices } from './../../features/services/features-base.services';
import { EmployeeDocumentUploadable } from './../models/employeeDocument';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { BusinessDepartment, EmployeeRights } from './../models/employee';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { UserService } from './../../users/services/user.service';
import { SaveUsers } from './../../users/models/user';
import { BaseServices } from './../../kuba.services';
import { HelperService } from './../../../_services/helper.service';
import { ValidationService } from '../../shared/services/validation.service';
import {
  Employee,
  EmployeeType,
  EmployeePosition,
  EmployeeDocument,
} from './../models/index';
import { EmployeeServices } from '../services/employee.services';
import { UploadFile, UploadInput, UploaderOptions } from 'ngx-uploader';
import { DateSetting } from './../../../_helpers/date-setting';
import { Rights } from 'src/app/_models';
import { BusinessDepartmentService } from './../../business-department.services';
import { Module } from '../../follow-ups/models/deviation';
import { TranslateService } from '@ngx-translate/core';
import { UserCredential } from './../../users/models/user';
import { FeatureBase } from './../../features/models/features-base.model';
import { HttpClient } from '@angular/common/http';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { DateService } from 'src/app/_services/date.service';
enum Year {
  min = 1000,
  max = 2000,
}
@Component({
  selector: 'employee-form',
  templateUrl: 'employee-form.component.html',
  styleUrls: ['employee-form.component.scss'],
})
export class EmployeeFormComponent extends DateSetting implements OnInit {
  //#region variables
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  @ViewChild('employeeTypeTable', { static: false }) employeeTypeTable: Table;
  @ViewChild('positionTable', { static: false }) positionTable: Table;
  @ViewChild('departmenttable', { static: false }) departmenttable: Table;
  @ViewChild('subDeptTable', { static: false }) subDeptTable: Table;
  @ViewChild('fileInput') fileInput: ElementRef;
  @Input() employeeForm: FormGroup;
  featureBase: FeatureBase;
  userForm: FormGroup;
  Formloading = false;
  AddTypeloading = false;
  AddPositionloading = false;
  AddDepartmentloading = false;
  AddSubDepartmentloading = false;
  Passwordloading = false;
  loading = false;
  employeeTypePopup: FormGroup;
  employeeTypeId: number;
  positionId: number;
  departmentId: number;
  subDeptId: number;
  positionPopup: FormGroup;
  departmentPopup: FormGroup;
  subDepartmentPopup: FormGroup;
  IsHide = true;
  documents: EmployeeDocument[];
  public userId: any;
  pdf: any;
  IsHidden = true;
  IsHidding = false;
  departmentValue: number[];
  avatarName: string;
  role: SelectItem[];
  percentage: any;
  addCategory = false;
  employeeId: number;
  employeePdf: Employee[];
  addType = false;
  addPosition: boolean;
  addDept: boolean;
  addSubDept: boolean;
  departments: any[];
  selectedDepartments: any[] = [];
  subDepartments: SelectItem[];
  positions: any[];
  CountryCode: SelectItem[];
  functions: SelectItem[];
  types: any[];
  employeeType: any = [];
  businessDepartment: any = [];
  SubDepartment: any = [];
  positionDropDown: any = [];
  editTypes: any;
  viewType = false;
  editPositions: any;
  editDepartments: any;
  editSubDepartments: any;
  viewPosition = false;
  viewDept = false;
  viewSubDept = false;
  public notes: any;
  test: Date;
  sign: any;
  employeeName: any;
  description: any;
  userRightsData: any;
  guestRightsData: any;
  editorRightsData: any;
  editedUser = false;
  editedGuest = false;
  editedEditor = false;
  approvedDate: any;
  rightsData: any;
  roles: any;
  isShowOnDashBoard = false;
  isNewUser: boolean;
  cachedUserName: string;
  roleFilter = [3, 4, 5]; // editor, guest and user
  upConfig: UploaderConfig;
  uploadedFiles: any = [];
  UploadableFile: EmployeeDocumentUploadable;
  additionalData: any;
  newDocumentAdded: any = [];
  tid: number;
  isFileChoosed = false;
  files: UploadFile[] = [];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  options: UploaderOptions;
  employeeImage: string;
  defaultImage: string = '/assets/icons/default_profile.jpg';
  businessId: number;
  employeeDetail: Employee;
  canViewEmployee = true;
  uploadStart: boolean;
  existsAlerttext: string;
  isNameExists: boolean;
  showMyPageSaveButton = true;
  hideSaveUserButton: boolean;
  hideSaveUser = false;
  selectedFiles: any;
  showPassword: any;
  hidePassword: boolean;
  loadingReport = false;
  otherCode: string;
  countryCode: any;
  departmentCreator: number;
  updatePasswordForm: FormGroup;
  showPasswordResetDialog = false;
  userCredentials = new UserCredential();
  passwordValidation: boolean;
  displayDialog = false;
  TocStatus = false;
  featureName: any;
  checked: boolean;
  isAddNewEmployee = true;
  isDeleteDocument: boolean;
  isElectroRights = false;
  isShowHSE = true;
  isPortal = false;
  canViewUser: boolean;
  isLoginUser: boolean;
  isMobile: any;
  dialogDisplay = false;
  invalidUserNameWithId: boolean = false;
  private subscriptions: Subscription[] = [];
  public override defaultDateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    inline: false,
    height: '26px',
    selectionTxtFontSize: '14px',
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
  };
  isAddEmployeeDetails = true;
  selectedSubDept: any[] = [];
  assignedDepartments: any[] = [];
  subDepartmentsList: any[] = [];
  isSubDepartmentInUse = false;
  /**
   * Date picker configuration option
   */
  public dobCalendarDateOptions: IMyDpOptions = this.defaultDateOptions;
  public startDateOptions: IMyDpOptions = this.defaultDateOptions;
  public endDateOptions: IMyDpOptions = this.startDateOptions;
  updatedStatus: any;

  // #endregion

  //#region constructor

  /**
   * Constructor
   * @param _fb {FormBuilder}
   * @param fbEmployeeType {FormBuilder}
   * @param fbposition {FormBuilder}
   * @param fbdepartment {FormBuilder}
   * @param employeeServices {EmployeeServices}
   * @param route {ActivatedRoute}
   * @param router {Router}
   * @param location {Location}
   * @param confirmationService {ConfirmationService}
   * @param userService {UserService}
   */
  constructor(
    private _fb: FormBuilder,
    private fbEmployeeType: FormBuilder,
    private fbposition: FormBuilder,
    private fbdepartment: FormBuilder,
    public employeeServices: EmployeeServices,
    public businessServices: BusinessServices,
    private route: ActivatedRoute,
    protected location: Location,
    private confirmationService: ConfirmationService,
    private userService: UserService,
    public validationService: ValidationService,
    private http: HttpClient,
    private featuresBaseServices: FeaturesBaseServices,
    private businessDepartmentService: BusinessDepartmentService,
    private adminUserService: AdminUserService,
    private translate: TranslateService,
    private router: Router,
    private dateService: DateService
  ) {
    super();
    this.featuresBaseServices.setEmployeeEditModeNavigation(true);
    if (BaseServices.roleId === '1' || BaseServices.roleId === '2') {
      this.businessId = this.route.snapshot.parent!.params['bid'];
    } else {
      this.businessId = BaseServices.BusinessId;
    }
    this.getCrewlistFeatureDetails();
    this.initForm();
    this.employeeForm = this._fb.group({
      TypeOfEmployee: ['', Validators.required],
      HSE_Cardnumber: [],
      Number: [],
      Name: ['', Validators.required],
      Dob: [],
      EmployeePosition: ['', Validators.required],
      Percentage: [],
      BusinessDepartmentId: ['', Validators.required],
      DepartmentLead: [],
      Address: [],
      ZipOrCity: [],
      Location: [],
      CountryCode: [2],
      OtherCode: [],
      Mobile: [],
      Telephone: [],
      Fax: [],
      Email: ['', [ValidationService.emailValidator]],
      StartDate: [],
      EndDate: [],
      IsEmailNewDeviation: [],
      NotifyMyDeviationsChange: [],
      SubDepartmentId: [],
      ShowIndashboard: [],
      NotificationBySms: [],
      NotificationByEmail: [],
      IsShowToGuest: [],
      NotifySuggestion: [],
      MainContact: [],
      IsApprovalNotice: [],
      Note: [],
      ContactFunctionalAreaId: [],
    });
    this.uploadInput = new EventEmitter<UploadInput>();
    this.employeeTypePopup = this.fbEmployeeType.group({
      employeeType: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.positionPopup = this.fbposition.group({
      position: [''],
    });
    this.departmentPopup = this.fbdepartment.group({
      department: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.subDepartmentPopup = this._fb.group({
      SubDepartment: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
      BusinessDepartment: [0, Validators.required],
    });
    this.employeeId = +this.route.snapshot.params['eid'];
    this.userExitence();
    this.updatePasswordForm = this._fb.group({
      passwordValidation: this._fb.group(
        {
          Password: ['', [Validators.required]],
          ConfirmPassword: ['', [Validators.required]],
        },
        { validator: ValidationService.matchPassword }
      ),
    });
  }

  // #endregion

  //#region page-events

  /**
   * Binding the dropdowns and getting employee data for edit
   */
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    let businessFeatures = BaseServices.userFeatures;
    if (BaseServices.roleId === '2') {
      this.isPortal = true;
    }
    this.bindDetails();
    this.onEditMyPage();
    if (+BaseServices.roleId === UserRole.USER) {
      this.onAddNewEmployee();
    } else if (+BaseServices.roleId !== UserRole.EDITOR) {
      this.isAddNewEmployee = false;
    }
    let businessUserRole = this.route.snapshot.data['userrole'];
    let employeeId = this.route.snapshot.params['eid'];

    if (businessUserRole) {
      let roleFiltered = businessUserRole.filter(
        (x: any) => this.roleFilter.indexOf(x.Id) > -1
      );
      this.subscriptions.push(
        this.translate.stream('SELECT_ROLE_DROP').subscribe((val) => {
          this.roles = [];
          this.roles.push(
            { label: val.EDITOR, value: 3 },
            { label: val.GUEST, value: 4 },
            { label: val.USER, value: 5 }
          );
        })
      );
    }
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.percentage = [];
        this.percentage.push({ label: val.SELECT, value: null });
        for (let n = 1; n <= 100; n++) {
          this.percentage.push({ label: n + '%', value: n });
        }
      })
    );

    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.tid = params['eid'];
        if (this.tid) {
          // TODO: need to send dynamic values
          this.additionalData = {
            ApplicationId: BaseServices.ApplicationId,
            BusinessId: BaseServices.BusinessId,
            EmployeeId: +this.tid ? +this.tid : 0,
            IsPrivateDocument: false,
            status: 1,
            CreatedOn: new Date(),
            ModifiedBy: BaseServices.UserId,
            ModifiedOn: new Date(),
            IsCommonDocument: true,
          };
          if (this.tid > 0) {
            this.employeeServices.listDoc(this.tid).subscribe((res: any) => {
              if (res.length > 0) {
                this.uploadedFiles = res;
              }
            });
          }
          this.upConfig = {
            title: 'EMPLOYEE_DOCUMENT',
            titleAsLabel: true,
            editMode: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showSearchFilter: false,
            showUploadButton: true,
            showDeleteButton: BaseServices.roleId === '5' ? false : true,
            uploaderUri:
              environment.BASE_URL +
              '/file/upload/business/employee/private-docs',
            addtionalData: this.additionalData,
          };
          this.employeeDetail = this.route.snapshot.data['edit'];

          if (this.employeeDetail == null || this.employeeDetail == undefined) {
            this.updatedStatus = 1;
          } else {
            this.updatedStatus = this.employeeDetail.Status;
          }

          if (this.employeeDetail) {
            console.log('EMPLOYEE DETAIO', this.employeeDetail);
            this.employeeDetail.CountryCode = Number(
              this.employeeDetail.CountryCode
            );
            this.otherCode = this.employeeDetail.OtherCode;
            this.IsHidding = this.otherCode !== null;
            this.countryCode = this.employeeDetail.CountryCode;
            this.IsHide = this.countryCode !== null;
            if (this.employeeDetail.ShowInDashboard === true) {
              this.isShowOnDashBoard = true;
            }
            this.employeeId = +this.tid;
            // Start Date Input and Date Range set
            let startDate = this.employeeDetail.EmploymentFromDate
              ? HelperService.formatInputDate(
                  this.employeeDetail.EmploymentFromDate
                )
              : null;

            let startdate: Date = HelperService.formatDate(
              this.employeeDetail.EmploymentFromDate
            )
              ? new Date(
                  HelperService.formatDate(
                    this.employeeDetail.EmploymentFromDate
                  )
                )
              : null!;
            this.endDateOptions = HelperService.setDateRange(
              this.endDateOptions,
              startdate,
              'startDate'
            );

            // End Date Input and Date Range set

            let endDate = this.employeeDetail.EmploymentEndDate
              ? HelperService.formatInputDate(
                  this.employeeDetail.EmploymentEndDate
                )
              : '';

            let enddate: Date = HelperService.formatDate(
              this.employeeDetail.EmploymentEndDate
            )
              ? new Date(
                  HelperService.formatDate(
                    this.employeeDetail.EmploymentEndDate
                  )
                )
              : null!;
            this.startDateOptions = HelperService.setDateRange(
              this.startDateOptions,
              enddate,
              'endDate'
            );
            if (this.tid) {
              this.description = this.employeeDetail.Note
                ? this.employeeDetail.Note
                : '';
              this.employeeName = this.employeeDetail.Name;
              this.IsHidden = false;
              this.departmentValue = this.employeeDetail.BusinessDepartmentIds;
            } else {
              this.employeeName = 'New Employee';
            }
            this.employeeImage = this.avatarName = this.employeeDetail.Avatar
              ? this.employeeDetail.Avatar
              : null;
            let employeeForm = {
              TypeOfEmployee: this.employeeDetail.TypeOfEmployeeId,
              Name: this.employeeDetail.Name,
              HSE_Cardnumber:
                this.employeeDetail.HSE_Cardnumber !== null
                  ? this.employeeDetail.HSE_Cardnumber
                  : '',
              Number: this.employeeDetail.Number,
              Dob: this.employeeDetail.Dob
                ? new Date(this.employeeDetail.Dob)
                : null,
              BusinessDepartmentId: this.employeeDetail.BusinessDepartmentIds,
              CountryCode: this.employeeDetail.CountryCode,
              OtherCode: this.employeeDetail.OtherCode,
              Mobile: this.employeeDetail.Mobile,
              Email: this.employeeDetail.Email,
              EmployeePosition: this.employeeDetail.EmployeePositionId,
              Percentage: this.employeeDetail.Percentage,
              DepartmentLead: this.employeeDetail.DepartmentLead,
              Address: this.employeeDetail.Address,
              ZipOrCity: this.employeeDetail.ZipOrCity,
              Location: this.employeeDetail.Location,
              Telephone: this.employeeDetail.Telephone,
              Fax: this.employeeDetail.Fax,
              EndDate: endDate,
              StartDate: startDate,
              IsEmailNewDeviation: this.employeeDetail.IsEmailNewDeviation,
              NotifyMyDeviationsChange:
                this.employeeDetail.NotifyMyDeviationsChange,
              ShowIndashboard: this.employeeDetail.ShowInDashboard,
              NotificationBySms: this.employeeDetail.IsSms,
              NotificationByEmail: this.employeeDetail.IsEmail,
              IsShowToGuest: this.employeeDetail.IsShowToGuest,
              NotifySuggestion: this.employeeDetail.NotifySuggestion,
              MainContact: this.employeeDetail.IsMainContact,
              IsApprovalNotice: this.employeeDetail.IsApprovalNotice,
              Note: this.employeeDetail.Note ? this.employeeDetail.Note : '',
              SubDepartmentId: this.employeeDetail.SubDepartmentId,
              ContactFunctionalAreaId:
                this.employeeDetail.ContactFunctionalAreaId,
            };
            if (this.employeeDetail.IsApprovalNotice) {
              this.checked = true;
              this.approvedDate = this.employeeDetail.ApprovalDateTime;
            }
            (<FormGroup>this.employeeForm).patchValue(employeeForm, {
              onlySelf: true,
            });
          } else {
            this.startDateOptions = this.defaultDateOptions;
            this.endDateOptions = this.defaultDateOptions;
          }
        }
      })
    );
    let source = this.route.snapshot.parent.parent.data['parent'];
    // Routing data: Employee - undefined , MyPage - MYPAGE
    if (source !== 'MYPAGE') {
      this.onEditDepartmentEmployee();
    }

    if (BaseServices.UserId === this.userId) {
      this.isLoginUser = true;
    } else {
      this.isLoginUser = false;
    }
    this.isUserNameUnique(this.cachedUserName);
    this.departmentValue;
    this.bindSubDeptDropdown(this.employeeForm.value.BusinessDepartmentId);
  }
  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }
  /**
   * upload for employee image
   * @param selectedFiles
   */
  filesSelect(event: Event): void {
    this.uploadStart = true;
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('file', file);

      this.subscriptions.push(
        this.http
          .post(
            `${environment.BASE_URL}/file/upload/employee-image/${BaseServices.ApplicationId}/${this.businessId}`,
            formData
          )
          .subscribe(
            (response: any) => {
              this.uploadStart = false;
              this.employeeImage = response.path;
            },
            (error) => {
              console.error('Upload error:', error);
              this.uploadStart = false;
            }
          )
      );
    }
  }
  /**
   * file upload events
   * @param event {any}
   */
  saveUploaded(event: any) {
    if (event) {
      let eventType = event.eventName;
      switch (eventType) {
        case 'DeleteDefaultFile':
          if (event.item.data.id === 0) {
            // remove unsaved attachment
            this.newDocumentAdded = this.newDocumentAdded.filter(
              (x: any) => x.Path !== event.item.data.path
            );
            this.uploadedFiles = this.uploadedFiles.filter(
              (x: any) => x.path !== event.item.data.path
            );
            this.toasterComponent.callToastDlt();
          } else {
            // remove existing attachement
            this.subscriptions.push(
              this.employeeServices
                .deleteEmployeeDocument(event.item.data.id)
                .subscribe((result: any) => {
                  if (result) {
                    // remove from display list
                    this.uploadedFiles = this.uploadedFiles.filter(
                      (x: any) => x.id !== event.item.data.id
                    );
                    this.toasterComponent.callToastDlt();
                  }
                })
            );
          }
          break;
        case 'remove':
          this.uploadedFiles = this.uploadedFiles.filter(
            (x: any) => x.id !== +event.id
          );
          break;
        default:
          let keyBindings = {
            Originalname: 'OriginalFileName',
            FileTypeId: 'FileType',
          };
          let deviationDoc = Object.assign({}, event, this.additionalData);
          deviationDoc.id = 0;
          deviationDoc.createdBy = BaseServices.UserId;
          this.newDocumentAdded.push(
            HelperService.switchKeysCase(deviationDoc, keyBindings)
          );
          break;
      }
    }
  }
  /**
   * Initialize the user form
   * @param data {any}
   */
  initForm(data?: any) {
    let Id: number;
    let Name = '';
    let Email = '';
    let Username = '';
    let Password = '';
    let RoleId = 5; // user
    let Status = '';
    let CountryCode = 2;
    let Mobile = '';
    if (data) {
      Id = data.Id ? +data.Id : 0;
      Name = data.Name ? data.Name : '';
      Email = data.Email ? data.Email : '';
      Username = data.Username ? data.Username : '';
      RoleId = data.RoleId ? +data.RoleId : RoleId;
      Status = data.Status ? data.Status : '1';
      CountryCode = data.CountryCode ? data.CountryCode : 2;
      Mobile = data.Mobile ? data.Mobile : '';
      if (RoleId === 3) {
        // editor
        this.rightsData = data.Rights
          ? JSON.parse(data.Rights)
          : this.editorRightsData;
      } else if (RoleId === 4) {
        // guest
        this.rightsData = data.Rights
          ? JSON.parse(data.Rights)
          : this.guestRightsData;
      } else if (RoleId === 5) {
        // user
        this.rightsData = data.Rights
          ? JSON.parse(data.Rights)
          : this.userRightsData;
      }
      data.Rights = JSON.stringify(this.rightsData);
    }

    let allCategories: FormArray | any = new FormArray([]);
    let gCategories: FormArray | any = new FormArray([]);
    let eCategories: FormArray | any = new FormArray([]);
    this.userForm = new FormGroup({
      Id: new FormControl(Id!),
      Name: new FormControl(Name, [
        Validators.required,
        ValidationService.noWhitespaceValidator,
      ]),
      Email: new FormControl(Email, [
        Validators.required,
        ValidationService.emailValidator,
      ]),
      Username: new FormControl(
        Username,
        [Validators.required, ValidationService.noWhitespaceValidator],
        this.isUserNameUnique.bind(this)
      ),
      passwordValidation: this.canViewUser
        ? this._fb.group({
            Password: [Password],
            ConfirmPassword: [Password],
          })
        : this._fb.group(
            {
              Password: [Password, [Validators.required]],
              ConfirmPassword: [Password, [Validators.required]],
            },
            { validator: ValidationService.matchPassword }
          ),
      RoleId: new FormControl(RoleId),
      Status: new FormControl(Status),
      CountryCode: new FormControl(CountryCode),
      Mobile: new FormControl(Mobile),
      userCategories: allCategories,
      guestCategories: gCategories,
      editorCategories: eCategories,
    });
    if (RoleId > 0) {
      this.setRoleVisiblity(RoleId);
    }
    let businessFeatures = BaseServices.userFeatures;
    if (window.name === 'Remote') {
      this.subscriptions.push(
        this.businessServices
          .getById(BaseServices.BusinessId)
          .subscribe((result) => {
            if (result) {
              businessFeatures = <any>JSON.parse(result.Features);
              let electroFeature = businessFeatures.filter(
                (x: any) => x.id === FeatureKey.ELECTRO
              );
              if (electroFeature.length) {
                this.isElectroRights = electroFeature[0].checked;
              }
            }
          })
      );
    } else {
      let electroFeature = businessFeatures.filter(
        (x: any) => x.id === FeatureKey.ELECTRO
      );
      if (electroFeature.length) {
        this.isElectroRights = electroFeature[0].checked;
      }
    }
    this.bindRightsCheckbox(data);
  }

  // #endregion

  get editCategoriesFormGroups() {
    return this.userForm.get('editorCategories') as FormArray;
  }
  get guestFormGroups() {
    return this.userForm.get('guestCategories') as FormArray;
  }
  get userFormGroups() {
    return this.userForm.get('userCategories') as FormArray;
  }
  //#region Control-events
  /**
   * Event handler for End date
   * @param event {IMyDateModel}
   */
  onEndDateChanged(event: IMyDateModel) {
    let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
    this.startDateOptions = this.setDateInput(
      this.startDateOptions,
      event,
      'endDate'
    );
    if (!date) {
      let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(
        this.startDateOptions
      );
      copyConfig.disableSince = this.getCurrentDate();
      this.startDateOptions = copyConfig;
    }
  }
  /**
   * Event handler for Start date
   * @param event {IMyDateModel}
   */
  onStartDateChanged(event: IMyDateModel) {
    let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
    this.endDateOptions = this.setDateInput(
      this.endDateOptions,
      event,
      'startDate'
    );
    if (!date) {
      let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(
        this.endDateOptions
      );
      copyConfig.disableSince = this.getCurrentDate();
      this.startDateOptions = copyConfig;
    }
  }
  /**
   * On Department DropDown Changed
   * @param e {any}
   * @param departmentDropdown {any}
   */
  onDepartmentDropDownChanged(departmentDropdown: any) {
    this.IsHidden = false;
    this.selectedSubDept = departmentDropdown.value;
    this.departmentValue = departmentDropdown.value;
    this.assignSubDepartmentDropdown(this.departmentValue);
    this.bindSubDeptDropdown(this.departmentValue);
  }

  /**
   * ExportPdf
   */
  exportPdf() {
    this.loadingReport = true;
    let fileName = 'commondetails';
    let filetype = 'pdf';
    let type = 2;
    let position = 0;
    let cultureInfo = BaseServices.userCultureInfo();
    let ReportJson = this.route.snapshot.data['edit'];
    this.subscriptions.push(
      this.employeeServices
        .reportGenerate(BaseServices.BusinessId, cultureInfo, ReportJson)
        .subscribe(
          (blob: any) => {
            this.loadingReport = false;
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName}.${filetype.toLowerCase()}`;
            link.click();
          },
          (error: any) => {
            this.loadingReport = false;
            alert('Export not downloaded');
          }
        )
    );
  }

  /**
   * on Role DropDown Changed
   * @param e {any}
   */
  onRoleDropDownChanged(e: any) {
    this.setRoleVisiblity(e.value);
  }

  /**
   * on Rights Checkbox Change
   * @param e {any}
   * @param rightsId {any}
   */
  onRightsCheckboxChange(e: any, rightsId: any, rightCategory: any) {
    if (this.rightsData) {
      _.each(this.rightsData, (x: EmployeeRights) => {
        if (x.id === rightsId) {
          x.isChecked = e.target.checked;
        }
      });
    }
  }

  /**
   * checkbox change event
   * @param e
   */
  onShowDashBoard(e: any) {
    if (e.checked === true) {
      this.isShowOnDashBoard = true;
      this.employeeForm
        .get('ContactFunctionalAreaId')!
        .setValidators(Validators.required);
      this.employeeForm
        .get('ContactFunctionalAreaId')!
        .updateValueAndValidity();
    } else {
      this.employeeForm.get('ContactFunctionalAreaId')!.setValidators(null);
      this.employeeForm
        .get('ContactFunctionalAreaId')!
        .updateValueAndValidity();
      this.isShowOnDashBoard = false;
    }
  }
  /**
   * Creating and Updating Form Values
   */
  saveChanges() {
    this.Formloading = true;
    let employee = this.employeeDetail ? this.employeeDetail : new Employee();
    employee = this.employeeForm.value;
    if (BaseServices.roleId === '2') {
      employee.BusinessId = null;
    } else {
      employee.BusinessId = BaseServices.BusinessId;
    }
    employee.HSE_Cardnumber = this.employeeForm.value.HSE_Cardnumber;
    employee.ApplicationId = BaseServices.ApplicationId;
    employee.Avatar = this.avatarName;
    employee.TypeOfEmployeeId = this.employeeForm.value.TypeOfEmployee;
    employee.BusinessDepartmentIds = this.employeeForm.value
      .BusinessDepartmentId
      ? this.employeeForm.value.BusinessDepartmentId
      : this.employeeDetail.BusinessDepartmentIds;
    employee.BusinessDepartmentId = 0;
    employee.SubDepartmentId = this.employeeForm.value.SubDepartmentId;
    employee.EmployeePositionId = this.employeeForm.value.EmployeePosition;
    employee.IsEmail = this.employeeForm.value.NotificationByEmail;
    employee.IsSms = this.employeeForm.value.NotificationBySms;
    employee.IsApprovalNotice = this.employeeForm.value.IsApprovalNotice;
    employee.NotifyMyDeviationsChange = this.employeeForm.value
      .NotifyMyDeviationsChange
      ? this.employeeForm.value.NotifyMyDeviationsChange
      : false;
    employee.IsMainContact = this.employeeForm.value.MainContact
      ? this.employeeForm.value.MainContact
      : false;
    if (this.updatedStatus != null) {
      employee.Status = this.updatedStatus;
    } else {
      employee.Status = '1';
    }
    employee.EmploymentFromDate = this.employeeForm.value.StartDate
      ? HelperService.formatDateFilter(
          this.employeeForm.value.StartDate.formatted
        )
      : null;
    employee.EmploymentEndDate = this.employeeForm.value.EndDate
      ? HelperService.formatDateFilter(
          this.employeeForm.value.EndDate.formatted
        )
      : null;
    employee.Avatar = this.employeeImage;
    if (this.employeeImage) {
      this.adminUserService.getUserProfileImagePath(this.employeeImage);
    }
    employee.EmployeeDocument = this.newDocumentAdded;

    employee.Mobile = this.employeeForm.value.Mobile;
    employee.Note = this.employeeForm.value.Note
      ? this.employeeForm.value.Note
      : '';
    employee.ContactFunctionalAreaId =
      this.employeeForm.value.ContactFunctionalAreaId;

    employee.Dob = this.employeeForm.value.Dob
      ? this.dateService.formatDateToString(this.employeeForm.value.Dob)
      : null;

    employee.CreatedBy = BaseServices.UserId;
    employee.ApprovalDateTime = this.approvedDate;
    if (this.employeeId) {
      if (!this.IsHide) {
        employee.OtherCode = this.employeeForm.value.OtherCode;
        employee.CountryCode = null;
      } else if (!this.IsHidding) {
        employee.OtherCode = null;
        employee.CountryCode = this.employeeForm.value.CountryCode;
      }
      employee.Id = this.employeeId;
      employee.ModifiedBy = BaseServices.UserId;
      this.employeeServices
        .update(this.employeeId, employee)
        .subscribe((updateEmployee: any) => {
          if (updateEmployee) {
            this.employeeId = updateEmployee.Id;
            this.employeeServices
              .getById(this.employeeId)
              .subscribe((result) => {
                if (result) {
                  this.employeeDetail = result;
                  this.toasterComponent.callToast();
                  this.Formloading = false;
                  this.employeeServices
                    .listDoc(this.employeeId)
                    .subscribe((result) => {
                      if (result) {
                        if (result.length > 0) {
                          this.uploadedFiles = result;
                          this.newDocumentAdded = [];
                        }
                      }
                    });
                }
              });
          }
        });
    } else {
      employee.Id = 0;
      employee.BusinessId = BaseServices.BusinessId;
      if (!this.IsHidding) {
        employee.CountryCode = this.employeeForm.value.CountryCode;
        employee.OtherCode = null;
      } else if (!this.IsHide) {
        employee.CountryCode = null;
        employee.OtherCode = this.employeeForm.value.OtherCode;
      }
      this.subscriptions.push(
        this.employeeServices.add(employee).subscribe((addEmployee: any) => {
          if (addEmployee) {
            this.employeeId = addEmployee.Id;
            this.subscriptions.push(
              this.employeeServices
                .getById(this.employeeId)
                .subscribe((result) => {
                  if (result) {
                    this.employeeDetail = result;
                    this.toasterComponent.callToast();
                    this.Formloading = false;
                    this.subscriptions.push(
                      this.employeeServices
                        .listDoc(this.employeeId)
                        .subscribe((result) => {
                          if (result) {
                            if (result.length > 0) {
                              this.uploadedFiles = result;
                              this.newDocumentAdded = [];
                            }
                          }
                        })
                    );
                  }
                })
            );
          }
        })
      );
    }
  }
  clearUserName() {
    this.userForm.get('Username').patchValue('');
  }
  /**
   * To edit Type
   * @param employeeType {any}
   */
  editType(employeeType: any) {
    if (employeeType.busId > 0) {
      this.employeeTypeId = employeeType.Id;
      this.employeeTypePopup = this._fb.group({
        employeeType: employeeType.Type,
      });
    }
  }
  /**
   * To edit Position
   * @param employeePosition {any}
   */
  editPosition(employeePosition: any) {
    if (employeePosition.busId > 0) {
      this.positionId = employeePosition.Id;
      this.positionPopup = this._fb.group({
        position: employeePosition.Name,
      });
    }
  }
  /**
   * To edit Department
   * @param employeeDept {any}
   */
  editDept(employeeDept: any) {
    if (employeeDept.busId > 0) {
      this.departmentId = employeeDept.Id;
      this.departmentCreator = employeeDept.CreatedBy;
      this.departmentPopup = this._fb.group({
        department: employeeDept.Name,
      });
    }
  }
  /**
   * To edit sub Department
   * @param empSubDept {any}
   */
  editSubDept(empSubDept: any) {
    this.subDeptId = empSubDept.Id;
    this.departmentCreator = empSubDept.CreatedBy;
    this.subDepartmentPopup = this._fb.group({
      SubDepartment: empSubDept.Name,
    });
  }
  /**
   * To delete Document
   * @param id {any}
   */
  deleteDocument(id: any) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.employeeServices
            .deleteDocument(id)
            .subscribe((isDocumentDeleted) => {
              if (isDocumentDeleted) {
                this.bindDocuments(this.employeeId);
              }
            })
        );
      },
    });
  }
  /**
   * changing country code dropdown to textbox
   */
  changeCountryCode() {
    this.IsHide = false;
    this.IsHidding = true;
  }
  /**
   * changing country code textbox to dropdown
   */
  changeToCountryCodeDropDown() {
    this.IsHide = true;
    this.IsHidding = false;
  }
  /**
   * add and update type of employee
   */
  saveType() {
    this.AddTypeloading = true;
    let employeeType = new EmployeeType();
    employeeType.Id = this.employeeTypeId ? this.employeeTypeId : 0;
    employeeType.Type = this.employeeTypePopup.value.employeeType;
    employeeType.Status = '1';
    employeeType.BusinessId = BaseServices.BusinessId;
    if (employeeType.Id > 0) {
      this.subscriptions.push(
        this.employeeServices
          .updateType(employeeType)
          .subscribe((updtaedType) => {
            this.bindEmployeeTypeDropdown(updtaedType);
            this.AddTypeloading = false;
          })
      );
    } else {
      this.subscriptions.push(
        this.employeeServices
          .createType(employeeType)
          .subscribe((addedType) => {
            this.bindEmployeeTypeDropdown(addedType);
            this.AddTypeloading = false;
          })
      );
    }
  }
  /**
   * employee type after event trigger
   * @param employeeType {any}
   */
  bindEmployeeTypeDropdown(employeeType: any) {
    if (employeeType) {
      this.toasterComponent.callToast();
      this.bindEmployeeType(BaseServices.BusinessId);
      this.employeeForm.get('TypeOfEmployee')!.patchValue(employeeType.Id);
      this.addType = false;
    } else {
      this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
      this.isNameExists = true;
      setTimeout(() => {
        this.isNameExists = false;
      }, 3000);
    }
  }
  /**
   * add and update employee position
   */
  savePosition() {
    this.AddPositionloading = true;
    let employeePosition = new EmployeePosition();
    employeePosition.Id = this.positionId ? this.positionId : 0;
    employeePosition.Name = this.positionPopup.value.position;
    employeePosition.Status = '1';
    employeePosition.BusinessId = BaseServices.BusinessId;
    if (BaseServices.roleId === '2') {
      employeePosition.PortalId = BaseServices.UserId;
    }
    if (employeePosition.Id > 0) {
      employeePosition.ModifiedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.employeeServices
          .updatePosition(employeePosition)
          .subscribe((updatePosition) => {
            this.bindPositionDropdown(updatePosition);
            this.AddPositionloading = false;
          })
      );
    } else {
      employeePosition.CreatedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.employeeServices
          .createPosition(employeePosition)
          .subscribe((newPosition) => {
            this.bindPositionDropdown(newPosition);
            this.AddPositionloading = false;
          })
      );
    }
  }
  /**
   * position bind after event trigger
   * @param position {any}
   */
  bindPositionDropdown(position: any) {
    if (position) {
      this.addPosition = false;
      this.toasterComponent.callToast();
      this.bindPosition(BaseServices.BusinessId);
      this.employeeForm.get('EmployeePosition')!.patchValue(position.Id);
    } else {
      this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
      this.isNameExists = true;
      setTimeout(() => {
        this.isNameExists = false;
      }, 3000);
    }
  }
  /**
   * add and update department
   */
  saveDept() {
    this.AddDepartmentloading = true;
    let employeeDept = new Employee();
    employeeDept.Id = this.departmentId ? this.departmentId : 0;
    employeeDept.Name = this.departmentPopup.value.department;
    employeeDept.Status = '1';
    employeeDept.BusinessId = BaseServices.BusinessId;
    employeeDept.ApplicationId = BaseServices.ApplicationId;
    employeeDept.CreatedBy = BaseServices.UserId;
    if (employeeDept.Id > 0) {
      employeeDept.ModifiedBy = BaseServices.UserId;
      employeeDept.CreatedBy = this.departmentCreator;
      this.subscriptions.push(
        this.employeeServices
          .updateDept(employeeDept)
          .subscribe((updateDepartment) => {
            this.bindDeptDropdown(updateDepartment);
            this.AddDepartmentloading = false;
          })
      );
    } else {
      this.subscriptions.push(
        this.employeeServices
          .createDept(employeeDept)
          .subscribe((newDepartment) => {
            this.onSaveNewDepartment(newDepartment);
            this.AddDepartmentloading = false;
          })
      );
    }
  }
  viewDepartmentDialog() {
    this.addDept = true;
    this.businessDepartmentService.clearCachedAllDepartment();
  }
  /**
   * department after event trigger
   * @param department {any}
   */
  bindDeptDropdown(department: any) {
    if (department) {
      this.departmentValue = department.Id;
      this.toasterComponent.callToast();
      this.bindDepartmentDropdown();
      this.addDept = false;
      this.bindSubDepartmentDropdown(department.Id);
      this.selectedDepartments = department.name;
    } else {
      this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
      this.isNameExists = true;
      setTimeout(() => {
        this.isNameExists = false;
      }, 3000);
    }
  }

  /**
   * Inserts newly created department in department dropdowns
   * @param department Department to add to dropdown
   */
  onSaveNewDepartment(department: BusinessDepartment) {
    this.toasterComponent.callToast();
    this.addDepartmentToDropdown(department);
    this.departmentValue.push(department.Id);
    this.addDept = false;
    this.selectedDepartments.push(department.Name);
    const employeeDepartments: number[] =
      this.employeeForm.value.BusinessDepartmentId;
    this.employeeForm.controls['BusinessDepartmentId'].patchValue(
      employeeDepartments
    );
  }

  /**
   * add and update sub department
   */
  saveSubDept() {
    this.AddSubDepartmentloading = true;
    let employeeSubDept = new Employee();
    let addSubDept = this.subDepartmentPopup.value;
    employeeSubDept.Id = this.subDeptId ? this.subDeptId : 0;
    employeeSubDept.Name = addSubDept.SubDepartment;
    employeeSubDept.Status = '1';
    employeeSubDept.BusinessId = BaseServices.BusinessId;
    employeeSubDept.ApplicationId = BaseServices.ApplicationId;
    employeeSubDept.BusinessDeptId =
      addSubDept.BusinessDepartment != null
        ? addSubDept.BusinessDepartment
        : this.employeeForm.value.BusinessDepartmentId;
    if (employeeSubDept.Id > 0) {
      employeeSubDept.ModifiedBy = BaseServices.UserId;
      employeeSubDept.CreatedBy = this.departmentCreator;
      this.subscriptions.push(
        this.employeeServices
          .updateDept(employeeSubDept)
          .subscribe((updatedSubDept) => {
            if (updatedSubDept) {
              this.bindSubDeptDropdown(
                employeeSubDept.BusinessDeptId > 0
                  ? employeeSubDept.BusinessDeptId
                  : this.employeeForm.value.BusinessDepartmentId
              );
            }
            this.AddSubDepartmentloading = false;
            this.addSubDept = false;
          })
      );
    } else {
      employeeSubDept.CreatedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.employeeServices
          .createSubDept(employeeSubDept)
          .subscribe((addedSubdept) => {
            if (addedSubdept) {
              this.bindSubDeptDropdown(
                employeeSubDept.BusinessDeptId > 0
                  ? employeeSubDept.BusinessDeptId
                  : this.employeeForm.value.BusinessDepartmentId
              );
            }
            this.AddSubDepartmentloading = false;
            this.AddSubDepartmentloading = false;
            this.addSubDept = false;
            this.subDepartmentPopup.reset();
          })
      );
    }
  }

  /**
   * Deleting subdepartment
   * @param empSubdept {any}
   */
  deleteSubDept(empSubdept: any) {
    if (empSubdept.BusinessId > 0) {
      this.confirmationService.confirm({
        message: this.translate.instant('DELETE_THIS_RECORD'),
        accept: () => {
          this.subscriptions.push(
            this.employeeServices
              .checkSubDeptById(empSubdept.Id, empSubdept.BusinessId)
              .subscribe((data) => {
                if (!data) {
                  this.subscriptions.push(
                    this.employeeServices
                      .deleteSubDepartment(empSubdept.Id)
                      .subscribe((isDocumentDeleted) => {
                        if (isDocumentDeleted) {
                          this.bindSubDepartmentDropdown(this.departmentValue);
                        }
                      })
                  );
                } else {
                  this.isSubDepartmentInUse = true;
                  setTimeout(
                    function () {
                      this.isSubDepartmentInUse = false;
                    }.bind(this),
                    3000
                  );
                }
              })
          );
        },
      });
    }
  }
  /**
   * Deleting department
   * @param empDept {any}
   */
  deleteDept(empDept: any) {
    if (empDept.busId > 0) {
      this.confirmationService.confirm({
        message: this.translate.instant('DELETE_THIS_RECORD'),
        accept: () => {
          this.subscriptions.push(
            this.employeeServices
              .deleteSubDepartment(empDept.Id)
              .subscribe((isDocumentDeleted) => {
                if (isDocumentDeleted) {
                  this.departmentValue =
                    this.employeeForm.value.BusinessDepartmentId > 0
                      ? this.employeeForm.value.BusinessDepartmentId
                      : 0;
                  this.bindSubDepartment(this.departmentValue);
                }
              })
          );
        },
      });
    }
  }
  /**
   * Delete type of employee
   * @param employeeType {any}
   */
  deleteType(employeeType: any) {
    if (employeeType.busId) {
      this.confirmationService.confirm({
        message: this.translate.instant('DELETE_THIS_RECORD'),
        accept: () => {
          this.subscriptions.push(
            this.employeeServices
              .deleteType(employeeType.Id)
              .subscribe((isDocumentDeleted) => {
                if (isDocumentDeleted) {
                  this.bindEmployeeType(BaseServices.BusinessId);
                }
              })
          );
        },
      });
    }
  }
  /**
   * Delete employee position
   * @param empPosition {any}
   */
  deletePosition(empPosition: any) {
    if (empPosition.busId > 0) {
      this.confirmationService.confirm({
        message: this.translate.instant('DELETE_THIS_RECORD'),
        accept: () => {
          this.subscriptions.push(
            this.employeeServices
              .deletePosition(empPosition.Id)
              .subscribe((isPositionDeleted) => {
                if (isPositionDeleted) {
                  this.bindPosition(BaseServices.BusinessId);
                }
              })
          );
        },
      });
    }
  }
  /**
   * get back the location
   */
  gotoList() {
    this.location.back();
  }
  /**
   * generating random password
   */
  generateRandomPassword() {
    let randomstring = Math.random().toString(36).slice(-8);
    this.userForm.patchValue({
      passwordValidation: {
        Password: randomstring,
        ConfirmPassword: randomstring,
      },
    });
    this.showPassword = randomstring;
    this.hidePassword = true;
  }
  /**
   * Save and create user
   */
  saveUserAndProceed() {
    if (this.employeeId == 0) {
      this.saveChanges();
    }
    let userData = {
      Id: this.userId ? this.userId : 0,
      Name: this.employeeForm.value.Name,
      Email: this.employeeForm.value.Email,
      Username: this.employeeForm.value.Email,
      CountryCode: this.employeeForm.value.CountryCode,
      Mobile: this.employeeForm.value.Mobile,
      RoleId: this.employeeForm.value.RoleId,
    };
    this.addCategory = true;
    if (this.employeeId > 0 && this.employeeId != null) {
      this.subscriptions.push(
        this.employeeServices
          .getEmployeeUser(this.employeeId)
          .subscribe((user: any) => {
            if (user) {
              this.userId = user ? user.Id : 0;
              if (this.userId > 0) {
                this.isNewUser = false;
                this.subscriptions.push(
                  this.userService
                    .getUsersById(this.userId)
                    .subscribe((data: any) => {
                      this.cachedUserName = data.Username;
                      this.initForm(data);
                      this.addCategory = true;
                    })
                );
              }
            } else {
              this.isNewUser = true;
              this.initForm(userData);
              this.addCategory = true;
            }
          })
      );
    } else {
      this.isNewUser = true;
      this.initForm(userData);
      this.addCategory = true;
    }
  }
  /**
   * closing the popup
   */
  saveAndClose() {
    this.saveUser();
    this.addCategory = false;
  }
  /**
   * create user
   */
  saveUser() {
    this.loading = true;
    let Users = new SaveUsers();
    Users.Id = this.userId ? this.userId : 0;
    Users.Name = this.userForm.value.Name;
    Users.Email = this.userForm.value.Email;
    Users.Username = this.userForm.value.Username;
    Users.RoleId = this.userForm.value.RoleId;
    Users.Status = '1';
    Users.FeatureId = Module.EMPLOYEES;
    Users.FeatureKey = this.employeeId ? this.employeeId : 0;
    Users.ApplicationId = BaseServices.ApplicationId;
    Users.Password = this.userForm.value.passwordValidation.Password;
    Users.CountryCode = this.userForm.value.CountryCode;
    Users.Mobile = this.userForm.value.Mobile;
    Users.BusinessId = BaseServices.BusinessId;
    Users.CreatedBy = BaseServices.UserId;
    if (window.name === 'Remote' && this.userId === 0) {
      Users.LanguageId = 2; // default language Norsk
    } else {
      Users.LanguageId = BaseServices.userLanguageId;
    }
    Users.Avatar = this.avatarName;
    if (this.rightsData && this.rightsData.length > 0) {
      Users.Rights = JSON.stringify(this.rightsData);
    } else {
      Users.Rights = null!;
    }
    if (Users.Id > 0) {
      this.subscriptions.push(
        this.userService.updateUser(Users.Id, Users).subscribe((isUpdated) => {
          if (isUpdated) {
            this.toasterComponent.callToast();
            this.loading = false;
          }
        })
      );
    } else {
      this.subscriptions.push(
        this.userService.addusers(Users).subscribe((isAdded: any) => {
          if (isAdded) {
            this.userService.clearCachedUserList();
            this.toasterComponent.callToast();
            this.loading = false;
          }
        })
      );
    }
    this.canViewEmployee = false;
    this.canViewUser = true;
  }
  /**
   * File Upload
   * @param event {any}
   */
  onUpload(event: any) {
    let responce = JSON.parse(event.xhr.response);
    this.avatarName = responce.Result.Uri;
  }
  /**
   * mobile number validation
   * @param e {any}
   * @param limitNumber {any}
   */
  onNumberChange(e: any, limitNumber: any) {
    HelperService.numberFieldValidation(e, limitNumber);
  }

  /**
   * get selected value in multiple checkbox
   * @param array
   * @param columnName
   */
  getSelectedData(array: any, columnName: string) {
    let selectedList: any = [];
    if (array && array.length > 0) {
      array.forEach((element: any) => {
        let data: any = {};
        data[columnName] = element;
        selectedList.push(data);
      });
    }
    return selectedList;
  }
  // #endregion

  //#region methods

  /**
   * To get sign
   */
  addSignature() {
    this.notes = BaseServices.userName;
    let date =
      `${new Date().getDate()}` +
      '/' +
      `${new Date().getMonth() + 1}` +
      '/' +
      `${new Date().getFullYear()}`;
    let time = HelperService.formatTime(new Date());
    this.sign = `${this.notes}  ${date} ${time}`;
    if (this.description) {
      this.notes = BaseServices.userName;
      this.sign = `${this.description}   ${this.notes}  ${date} ${time}`;
    }
    this.employeeForm.patchValue({ Note: this.sign });
  }
  /**
   * get current date
   */
  getCurrentDate() {
    let dateFormat = new Date();
    return {
      year: dateFormat.getFullYear(),
      month: dateFormat.getMonth() + 1,
      day: dateFormat.getDate() + 1,
    };
  }

  /**
   * user name validation
   */
  isUserNameUnique(event) {
    const q = new Promise((resolve) => {
      setTimeout(() => {
        if (event != null && event.value === this.cachedUserName) {
          this.invalidUserNameWithId = false;
          resolve(null);
        } else {
          let AppId = BaseServices.ApplicationId;
          if (event != null) {
            this.subscriptions.push(
              this.employeeServices.getUserName(event.value).subscribe(
                (x) => {
                  if (!x) {
                    this.invalidUserNameWithId = false;
                    resolve(null);
                  } else {
                    this.invalidUserNameWithId = true;
                    resolve({ invalidUserNameWithId: true });
                  }
                },
                () => {
                  this.invalidUserNameWithId = true;
                  resolve({ invalidUserNameWithId: true });
                }
              )
            );
          }
        }
      }, 1000);
    });

    return q;
  }

  /**
   * user mail validation
   */
  isEmailUnique(control: FormControl) {
    const q = new Promise((resolve) => {
      setTimeout(() => {
        if (control.value === this.cachedUserName) {
          resolve(null);
        } else {
          let AppId = BaseServices.ApplicationId;
          this.subscriptions.push(
            this.employeeServices.getEmail(AppId, control.value).subscribe(
              (x) => {
                if (!x) {
                  resolve(null);
                } else {
                  resolve({ emailExist: true });
                }
              },
              () => {
                resolve({ emailExist: true });
              }
            )
          );
        }
      }, 1000);
    });
    return q;
  }
  /**
    /**
     * To bind Rights Checkbox
     * @param data {any}
     */
  bindRightsCheckbox(data?: any) {
    this.userRightsData = [
      {
        id: '1',
        name: 'ACTIVE_ACCOUNT',
        isChecked: true,
        rightsType: 1,
      },
      {
        id: '2',
        name: 'EDIT_MY_PAGE',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '3',
        name: 'EDIT_MANUALS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '4',
        name: 'ADMINISTRATE_ACTIVITIES',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '5',
        name: 'ADMINISTRATE_RISK_ANALYSIS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '6',
        name: 'ADMINISTRATE_PROJECTS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '7',
        name: 'ADMINISTRATE_FDV',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '8',
        name: 'ADMINISTRATE_INTERNAL_CONTROL',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '9',
        name: 'ADMINISTRATE_INTERNAL_NEWS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '13',
        name: 'ADD_EMPLOYEE',
        isChecked: false,
        rightsType: 2,
      },
      {
        id: '14',
        name: 'DEPARTMENT_EMPLOYEE_RIGHTS',
        isChecked: false,
        rightsType: 2,
      },
      {
        id: '15',
        name: 'HIDE_EMPLOYEE_LIST',
        isChecked: false,
        rightsType: 2,
      },
      {
        id: '16',
        name: 'ADMINISTRATE_DEVIATION',
        isChecked: false,
        rightsType: 3,
      },
      {
        id: '17',
        name: 'VIEW_DEVIATION',
        isChecked: false,
        rightsType: 3,
      },
      {
        id: '19',
        name: 'DEVIATIONS_OWN_DEPARTMENT',
        isChecked: false,
        rightsType: 3,
      },
      {
        id: '18',
        name: 'DISPLAY_OWN_DEVIATIONS',
        isChecked: false,
        rightsType: 3,
      },
      {
        id: '48',
        name: 'ADMINISTRATE_OTHER_SYSTEMS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '49',
        name: 'ADMINISTRATE_QUALITY_SYSTEM',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '51',
        name: 'ADMINISTRATE_KUBA_CONTROL',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '38',
        name: 'ADMINISTRATE_FOOD_SAFETY',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '52',
        name: 'ADMINISTRATE_FLEXI',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '45',
        name: 'DOCUMENTED_TRAINING_RIGHTS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '10',
        name: 'APPROVE_ABSENCE',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '11',
        name: 'APPROVE_LEAVE',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '12',
        name: 'APPROVE_TIMETABLE',
        isChecked: false,
        rightsType: 1,
      },
    ];

    if (this.isElectroRights) {
      let electroUserRights = [
        {
          id: '37',
          name: 'SERVICE_LEADER_ELECTRO',
          isChecked: false,
          rightsType: 1,
        },
        {
          id: '38',
          name: 'SERVICE_INSTALLER_ELECTRO',
          isChecked: false,
          rightsType: 1,
        },
      ];
      this.userRightsData.push(...electroUserRights);
    }
    const userControl = <FormArray>this.userForm.controls['userCategories'];
    for (let i = 0; i < this.userRightsData.length; i++) {
      let fg = new FormGroup({});
      fg.addControl(
        `${this.userRightsData[i].id}`,
        new FormControl(
          data
            ? this.arrayCompare(data.Rights, this.userRightsData[i].id)
            : this.userRightsData[i].isChecked
        )
      );
      userControl.push(fg);
    }

    this.guestRightsData = [
      {
        id: '1',
        name: 'ACTIVE_ACCOUNT',
        isChecked: true,
        rightsType: 1,
      },
      {
        id: '20',
        name: 'DEVIATION_RIGHTS',
        isChecked: false,
        rightsType: 3,
      },
      {
        id: '21',
        name: 'VIEW_MANUALS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '15',
        name: 'HIDE_EMPLOYEE_LIST',
        isChecked: false,
        rightsType: 2,
      },
      {
        id: '17',
        name: 'VIEW_DEVIATION',
        isChecked: false,
        rightsType: 3,
      },

      {
        id: '19',
        name: 'DEVIATIONS_OWN_DEPARTMENT',
        isChecked: false,
        rightsType: 3,
      },
      {
        id: '18',
        name: 'DISPLAY_OWN_DEVIATIONS',
        isChecked: false,
        rightsType: 3,
      },
    ];
    if (this.isElectroRights) {
      let electroGuestRights = [
        {
          id: '40',
          name: 'GUEST_CLIENT_ELECTRO',
          isChecked: false,
          rightsType: 1,
        },
      ];
      this.guestRightsData.push(...electroGuestRights);
    }
    const guestcontrol = <FormArray>this.userForm.controls['guestCategories'];
    for (let j = 0; j < this.guestRightsData.length; j++) {
      let ft = new FormGroup({});
      ft.addControl(
        `${this.guestRightsData[j].id}`,
        new FormControl(
          data
            ? this.arrayCompare(data.Rights, this.guestRightsData[j].id)
            : this.guestRightsData[j].isChecked
        )
      );
      guestcontrol.push(ft);
    }
    this.editorRightsData = [
      {
        id: '1',
        name: 'ACTIVE_ACCOUNT',
        isChecked: true,
        rightsType: 1,
      },
      {
        id: '10',
        name: 'APPROVE_ABSENCE',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '11',
        name: 'APPROVE_LEAVE',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '12',
        name: 'APPROVE_TIMETABLE',
        isChecked: false,
        rightsType: 1,
      },
    ];
    const editorControl = <FormArray>this.userForm.get('editorCategories');
    for (let k = 0; k < this.editorRightsData.length; k++) {
      let fk = new FormGroup({});
      fk.addControl(
        `${this.editorRightsData[k].id}`,
        new FormControl(
          data
            ? this.arrayCompare(data.Rights, this.editorRightsData[k].id)
            : this.editorRightsData[k].isChecked
        )
      );
      editorControl.push(fk);
    }
  }
  /**
   * To array compare
   * @param list {any}
   * @param key {number}
   */
  arrayCompare(list: any, key: number) {
    let isMatched = false;
    let dataList = JSON.parse(list || null);
    if (dataList && dataList.length > 0) {
      for (let index = 0; index < dataList.length; index++) {
        let element = dataList[index];
        if (element.id === key) {
          return element.isChecked;
        }
      }
    }
    return isMatched;
  }
  /**
   * binding department dropdown
   */
  bindDepartmentDropdown() {
    this.departments = [];
    this.editDepartments = [];
    let department = this.route.snapshot.data['businessdropdown'];

    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.departments.push({ label: val.SELECT, value: 0 });

        if (department) {
          const uniqueTypeSet = new Set<string>(); // Use a Set to store unique types
          department.forEach((typeOfEmp: any) => {
            const typeLabel = typeOfEmp.Name;
            const typeId = typeOfEmp.Id;
            const typebusId = typeOfEmp.BusinessId;

            // Check if the typeLabel is unique before pushing
            if (!uniqueTypeSet.has(typeLabel)) {
              uniqueTypeSet.add(typeLabel);

              const businessDepartment: BusinessDepartment = {
                Id: typeId,
                Name: typeLabel,
                BusinessId: typebusId,
                Status: '',
              };

              this.addDepartmentToDropdown(businessDepartment);
            }
          });
        }
      })
    );
  }

  /**
   * Adds a department to department dropdowns
   * @param businessDepartment Department to add to dropdowns
   */
  addDepartmentToDropdown(businessDepartment: BusinessDepartment) {
    // Push into departments
    this.departments.push({
      label: businessDepartment.Name,
      value: businessDepartment.Id,
      busId: businessDepartment.BusinessId,
    });

    // Push into editDepartments
    this.editDepartments.push({
      Name: businessDepartment.Name,
      Id: businessDepartment.Id,
      busId: businessDepartment.BusinessId,
    });
  }

  /**
   * binding sub department dropdown
   * @param value : any
   */
  bindSubDepartmentDropdown(value: any) {
    this.subDepartments = [];
    this.editSubDepartments = [];
    this.subDepartments.push({
      label: this.translate.instant('SELECT'),
      value: null,
    });

    this.subscriptions.push(
      this.employeeServices
        .getAllSubDepartments([value])
        .subscribe((subDepartment) => {
          this.SubDepartment = subDepartment;
          if (subDepartment) {
            this.SubDepartment.forEach((element: any) => {
              if (this.subDepartments) {
                this.subDepartments.push({
                  label: element.Name,
                  value: element.Id,
                });
                this.editSubDepartments.push({
                  Name: element.Name,
                  Id: element.Id,
                });
              }
            });
          }
        })
    );
  }
  assignSubDepartmentDropdown(value: number[]) {
    this.assignedDepartments = [];
    this.assignedDepartments.push({
      label: this.translate.instant('SELECT'),
      value: null,
    });
    this.subscriptions.push(
      this.employeeServices
        .getAssignedDepartments(value)
        .subscribe((subDepartment) => {
          this.SubDepartment = subDepartment;
          if (subDepartment) {
            this.SubDepartment.forEach((element: any) => {
              if (this.assignedDepartments) {
                this.assignedDepartments.push({
                  label: element.Name,
                  value: element.Id,
                });
              }
            });
          }
        })
    );
  }
  /**
   * Refresh the sub department after update
   * @param value {any}
   */
  bindSubDepartment(value: any) {
    this.subscriptions.push(
      this.employeeServices
        .getAllSubDepartments(value)
        .subscribe((subDepartment: any) => {
          this.subDepartments = [];
          subDepartment.forEach((element: any) => {
            if (subDepartment) {
              this.subDepartments.push({
                label: element.Name,
                value: element.Id,
              });
            }
            this.editSubDepartments = subDepartment;
          });
        })
    );
  }
  bindSubDeptDropdown(value: number[]) {
    this.subscriptions.push(
      this.employeeServices
        .getSubDepartments(value, this.businessId)
        .subscribe((subDepartment: any) => {
          this.subDepartmentsList = [];
          subDepartment.forEach((element: any) => {
            if (subDepartment) {
              this.subDepartmentsList.push({
                label: element.Name,
                value: element.Id,
              });
            }
            this.editSubDepartments = subDepartment;
          });
        })
    );
  }
  /**
   * Binding position dropdown
   */
  bindPositiondropdown() {
    this.positions = [];
    this.editPositions = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.positions = [];
        this.positions.push({ label: val.SELECT, value: null });
        this.positionDropDown = this.route.snapshot.data['positiondropdown'];
        if (this.positionDropDown) {
          const uniqueTypeSet = new Set<string>(); // Use a Set to store unique types

          this.positionDropDown.forEach((typeOfEmp: any) => {
            const typeLabel = typeOfEmp.Name;
            const typeId = typeOfEmp.Id;
            const typebusId = typeOfEmp.BusinessId;

            // Check if the typeLabel is unique before pushing
            if (!uniqueTypeSet.has(typeLabel)) {
              uniqueTypeSet.add(typeLabel);

              // Push into positions
              this.positions.push({
                label: typeLabel,
                value: typeId,
                busId: typebusId,
              });

              // Push into editPositions
              this.editPositions.push({
                Name: typeLabel,
                Id: typeId,
                busId: typebusId,
              });
            }
          });
        }
      })
    );
  }
  /**
   * Refreshing Position Dropdown after update
   * @param busId {any}
   */
  bindPosition(busId: any) {
    this.subscriptions.push(
      this.employeeServices.getAllByPosition(busId).subscribe((res) => {
        this.positions = [];
        res.forEach((position: any) => {
          this.positions.push({
            label: position.Name,
            value: position.Id,
          });
        });
        if (res) {
          const uniqueTypeSet = new Set<string>(); // Use a Set to store unique types

          res.forEach((typeOfEmp: any) => {
            const typeLabel = typeOfEmp.Name;
            const typeId = typeOfEmp.Id;
            const typebusId = typeOfEmp.BusinessId;
            // Check if the typeLabel is unique before pushing
            if (!uniqueTypeSet.has(typeLabel)) {
              uniqueTypeSet.add(typeLabel);

              // Push into editPositions
              this.editPositions.push({
                Name: typeLabel,
                Id: typeId,
                busId: typebusId,
              });
            }
          });
        }
      })
    );
  }
  /**
   * Binding employee dropdown
   */
  bindTypeofEmployeeDropdown() {
    this.types = [];
    this.editTypes = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.types = [];
        this.types.push({ label: val.SELECT, value: null });
        let employeeType = this.route.snapshot.data['employee'];

        if (employeeType) {
          const uniqueTypeSet = new Set<string>(); // Use a Set to store unique types

          employeeType.forEach((typeOfEmp: any) => {
            const typeLabel = typeOfEmp.Type;
            const typeId = typeOfEmp.Id;
            const typebusId = typeOfEmp.BusinessId;

            // Check if the typeLabel is unique before pushing
            if (!uniqueTypeSet.has(typeLabel)) {
              uniqueTypeSet.add(typeLabel);

              // Push into types
              this.types.push({
                label: typeLabel,
                value: typeId,
                busId: typebusId,
              });

              // Push into editTypes
              this.editTypes.push({
                Type: typeLabel,
                Id: typeId,
                busId: typebusId,
              });
            }
          });
        }
      })
    );
  }
  /**
   * Binding employee type
   * @param busId {any}
   */
  bindEmployeeType(busId: any) {
    this.subscriptions.push(
      this.employeeServices.getAllByType(busId).subscribe((res) => {
        this.types = [];
        res.forEach((empType) => {
          this.types.push({ label: empType.Type, value: empType.Id });
        });
        this.editTypes = res;
      })
    );
  }
  /**
   * Binding country code dropdown
   */
  bindCountryCode() {
    this.CountryCode = [];
    this.employeeType = this.route.snapshot.data['countrycodedropdown'];
    if (this.employeeType) {
      this.employeeType.forEach((countryCode: any) => {
        this.CountryCode.push({
          label: '+' + countryCode.Phonecode,
          value: countryCode.Id,
        });
      });
    }
  }
  /**
   * Binding function dropdown
   */
  bindFunctionDropDown() {
    this.functions = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.functions = [];
        this.functions.push({ label: val.SELECT, value: null });
        let functions = this.route.snapshot.data['function'];
        if (functions) {
          functions.forEach((func: any) => {
            this.functions.push({ label: func.Name, value: func.Id });
          });
        }
      })
    );
  }

  /**
   * name keypress event restrict number greater than 30 and restrict number
   */
  onKeyChange(e: any) {
    let charCode = e.which ? e.which : e.keyCode;
    if (!(charCode > 31 && (charCode < 48 || charCode > 57))) {
      e.preventDefault();
    }
  }

  /**
   * Employee Type popup control clear
   */
  onBeforeEmployeeTypeDialogHide() {
    this.employeeTypePopup.reset();
    this.employeeTypeTable.reset();
    this.employeeTypeId = 0;
  }
  /**
   * Employee Type popup control clear
   */
  onBeforePositionDialogHide() {
    this.positionPopup.reset();
    this.positionTable.reset();
    this.positionId = 0;
  }
  /**
   * Employee Type popup control clear
   */
  onBeforeDepartmentDialogHide() {
    this.departmentPopup.reset();
    this.departmenttable?.reset();
    this.departmentId = 0;
  }
  /**
   * Employee Type popup control clear
   */
  onBeforeSubDepartmentDialogHide() {
    this.subDepartmentPopup.reset();
    this.subDeptTable.reset();
    this.subDeptId = 0;
  }
  /**
   * Method for setting date range
   * @param dateConfig {IMyDpOptions}
   * @param event {IMyDateModel}
   * @param type {string}
   */
  setDateInput(
    dateConfig: IMyDpOptions,
    event: IMyDateModel,
    type: string
  ): IMyDpOptions {
    let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
    let dateOptions = HelperService.setDateRange(dateConfig, date, type);
    return dateOptions;
  }
  /**
   * To set Role Visiblity
   * @param val {number}
   */
  setRoleVisiblity(val: number) {
    if (val === 3) {
      // editor
      this.rightsData = this.editorRightsData;
      this.editedEditor = true;
      this.editedGuest = false;
      this.editedUser = false;
    }
    if (val === 4) {
      // guest
      this.editedGuest = true;
      this.editedEditor = false;
      this.editedUser = false;
      this.rightsData = this.guestRightsData;
    }
    if (val === 5) {
      // user
      this.editedUser = true;
      this.editedEditor = false;
      this.editedGuest = false;
      this.rightsData = this.userRightsData;
    }
  }
  /**
   * To Bind Documents
   * @param id {any}
   */
  bindDocuments(id: any) {
    this.subscriptions.push(
      this.employeeServices.getallDocuments(id).subscribe((res: any) => {
        this.documents = res;
      })
    );
  }
  /**
   * To bind Details
   * @param busId {any}
   */
  bindDetails() {
    this.bindDepartmentDropdown();
    this.bindTypeofEmployeeDropdown();
    this.bindPositiondropdown();
    this.bindCountryCode();
    this.bindFunctionDropDown();
  }
  /**
   * approved date check
   * @param e {any}
   */
  onApprovedDateChecked(e: any) {
    this.featureName = BaseServices.Name;
    if (this.featureName === this.employeeDetail.Name) {
      this.displayDialog = true;
    }
    this.checked = e ? true : false;
    this.approvedDate = e ? new Date() : '';
  }

  decline() {
    this.displayDialog = false;
    this.TocStatus = false;
    this.subscriptions.push(
      this.employeeServices
        .currentUserTOCStatus(BaseServices.UserId, this.TocStatus)
        .subscribe((result) => {})
    );
    sessionStorage.clear();
    this.router.navigate(['login']);
  }

  accept() {
    this.TocStatus = true;
    this.subscriptions.push(
      this.employeeServices
        .currentUserTOCStatus(BaseServices.UserId, this.TocStatus)
        .subscribe((result) => {})
    );
    this.onApprovedDateChecked(true);
    this.displayDialog = false;
  }

  /**
   * update only userdepartment based on edit department employee rights
   */
  onEditDepartmentEmployee() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId;
    let userRights;
    let data;
    let employeeId: any;
    if (currentUserRole === 'User') {
      let rightsCheck: boolean;
      userRightsId = Rights.EDIT_DEPARTMENT_EMPLOYEE_RIGHTS;
      userRights = BaseServices.getUserRights();
      data = JSON.parse(userRights);
      employeeId = +BaseServices.FeatureKey;
      rightsCheck = HelperService.checkRightsData(data, userRightsId);
      let departmentId;
      if (rightsCheck === true) {
        this.subscriptions.push(
          this.employeeServices
            .getById(employeeId)
            .subscribe((result: Employee) => {
              departmentId = result.BusinessDepartmentId;
              if (this.employeeDetail.BusinessDepartmentId === departmentId) {
                if (employeeId === this.employeeId) {
                  this.onEditMyPage();
                  this.canViewEmployee = false;
                } else {
                  this.showMyPageSaveButton = true;
                  this.canViewEmployee = false;
                }
              } else {
                this.showMyPageSaveButton = false;
                this.canViewEmployee = false;
              }
            })
        );
      } else {
        this.canViewEmployee = true;
      }
    } else {
      this.canViewEmployee = true;
    }
  }
  /**
   * addNewEmployee rights for user
   */
  onAddNewEmployee() {
    let userRightsId = Rights.ADD_NEW_EMPLOYEE;
    this.isAddNewEmployee = BaseServices.checkUserRights(
      userRightsId,
      BaseServices.UserRole
    );
    if (this.isAddNewEmployee === true) {
      this.hideSaveUserButton = false;
      this.canViewEmployee = false;
      this.showMyPageSaveButton = true;
    }
  }

  /**
   * hide save button based on edit mypage rights
   */
  onEditMyPage() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.EDIT_MY_PAGE;
    if (currentUserRole === 'User') {
      this.hideSaveUserButton = true;
      if (this.employeeId === +BaseServices.FeatureKey) {
        this.showMyPageSaveButton = BaseServices.checkUserRights(
          userRightsId,
          currentUserRole
        );
      } else {
        this.showMyPageSaveButton = false;
      }
    } else {
      this.showMyPageSaveButton = true;
      this.hideSaveUserButton = false;
    }
  }

  resetPassWord() {
    this.Passwordloading = true;
    this.userCredentials.Id = this.userId;
    this.userCredentials.Password =
      this.updatePasswordForm.value.passwordValidation.Password;
    this.userCredentials.Password =
      this.updatePasswordForm.value.passwordValidation.ConfirmPassword;
    this.subscriptions.push(
      this.businessServices
        .updatePassword(this.userCredentials)
        .subscribe((result) => {
          if (result === false) {
            this.passwordValidation = true;
            this.Passwordloading = false;
            setTimeout(() => {
              this.passwordValidation = false;
            }, 3000);
          } else {
            this.toasterComponent.callToast();
            this.Passwordloading = false;
          }
        })
    );
  }
  closeResetPassword() {
    this.showPasswordResetDialog = false;
    this.updatePasswordForm.reset();
  }
  showResetPassword() {
    this.showPasswordResetDialog = true;
  }
  userExitence() {
    let featureId = FeatureKey.EMPLOYEES;
    if (this.employeeId) {
      this.subscriptions.push(
        this.userService
          .getUserExits(featureId, this.employeeId)
          .subscribe((res: any) => {
            if (res) {
              this.canViewEmployee = false;
              this.canViewUser = true;
            }
          })
      );
    } else {
      this.canViewEmployee = true;
    }
  }
  saveAndSendSMS() {
    this.addCategory = false;
    let Users = new SaveUsers();
    Users.Id = this.userId ? this.userId : 0;
    Users.Name = this.userForm.value.Name;
    Users.Email = this.userForm.value.Email;
    Users.Username = this.userForm.value.Username;
    Users.RoleId = this.userForm.value.RoleId;
    Users.Status = '1';
    Users.FeatureId = Module.EMPLOYEES;
    Users.FeatureKey = this.employeeId ? this.employeeId : 0;
    Users.ApplicationId = BaseServices.ApplicationId;
    Users.Password = this.userForm.value.passwordValidation.Password;
    Users.CountryCode = this.userForm.value.CountryCode;
    Users.Mobile = this.userForm.value.Mobile;
    Users.BusinessId = BaseServices.BusinessId;
    if (window.name === 'Remote' && this.userId === 0) {
      Users.LanguageId = 2; // default language Norsk
    } else {
      Users.LanguageId = BaseServices.userLanguageId;
    }
    Users.Avatar = this.avatarName;
    if (this.rightsData && this.rightsData.length > 0) {
      Users.Rights = JSON.stringify(this.rightsData);
    } else {
      Users.Rights = null!;
    }
    if (Users.Id > 0) {
    } else {
      this.subscriptions.push(
        this.businessServices
          .addUsersAndSendSMS(Users)
          .subscribe((isAdded: any) => {
            if (isAdded) {
              this.userService.clearCachedUserList();
              this.toasterComponent.callToast();
              this.addCategory = false;
            }
          })
      );
    }
  }
  // #endregion

  /**
   * Delete type of employee
   * @param employeeDetail {any}
   */
  deleteEmpImage(employeeDetail: any): void {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.employeeServices
            .deleteEmployeImage(employeeDetail.Id, +BaseServices.UserId)
            .subscribe((isEmpImageDeleted) => {
              if (isEmpImageDeleted) {
                this.employeeImage = null;
                this.employeeDetail.Avatar = null;
              }
            })
        );
      },
    });
  }

  getCrewlistFeatureDetails() {
    this.featureBase = BaseServices.getUserFeatures();
    let objCreawlist = JSON.parse(this.featureBase.FeaturesTemplate)
      .filter((e: any) => (e.id = FeatureKey.PROJECT))[0]
      .children.filter((x: any) => (x.id = FeatureKey.CREW_LIST));
    if (objCreawlist !== null) {
      this.isShowHSE = objCreawlist.checked === false ? false : true;
    }
  }
  EditUserPage() {
    this.subscriptions.push(
      this.employeeServices
        .getEmployeeUser(this.employeeId)
        .subscribe((user: any) => {
          if (user) {
            this.router.navigate(
              [
                `../../../business/edit/` +
                  BaseServices.BusinessId +
                  `/user/edit/` +
                  user.Id,
              ],
              { relativeTo: this.route }
            );
          }
        })
    );
  }
  refreshUsername() {
    if (this.cachedUserName) {
      this.userForm.get('Username').patchValue(this.cachedUserName);
    } else {
      this.userForm.get('Username').patchValue(this.employeeForm.value.Email);
    }
  }

  MobileEvent(event) {
    var mobileNum = document.getElementById('mobilenum') as HTMLButtonElement;
    if (mobileNum.value.length >= 7) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }
  showDialog() {
    this.dialogDisplay = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

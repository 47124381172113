import { EmployeeContactUserResolver } from './../users/services/user.resolver.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { FdvMyListComponent } from './component/fdvMyList.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FdvComponent } from './fdv.component';
import { FdvListComponent } from './component/fdvList.component';
import { FdvNewComponent } from './component/fdvNew.component';
import { AppSharedModule } from './../shared/appShared.module';
import { FDVServices } from './services/fdv.service';
import { FdvViewComponent } from './component/fdvView.component';
import {
  FdvListResolver,
  FdvMyListResolver,
  FdvEditResolver,
  TypeOfObjectDropDownResolver,
  ContactDropDownResolver,
  ChooseObjectDropDownResolver,
  FdvFeaturesResolver,
  UserListResolver,
} from './services/fdv.resolver.service';
import { EcplTabNavigationModule } from 'src/app/shared/ecpl-tab-navigation/ecpl-tab-navigation.module';
import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { FdvListAllComponent } from './component/fdvListAll.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    AppSharedModule,
    RouterModule,
    TranslateModule,
    EcplTabNavigationModule,
    KubaExportModule,
  ],
  exports: [
    FdvListComponent,
    FdvMyListComponent,
    FdvComponent,
    FdvNewComponent,
    FdvViewComponent,
    FdvListAllComponent,
  ],
  declarations: [
    FdvListComponent,
    FdvMyListComponent,
    FdvComponent,
    FdvNewComponent,
    FdvViewComponent,
    FdvListAllComponent,
  ],
  providers: [
    FDVServices,
    FdvListResolver,
    FdvFeaturesResolver,
    FdvMyListResolver,
    FdvEditResolver,
    TypeOfObjectDropDownResolver,
    ContactDropDownResolver,
    ChooseObjectDropDownResolver,
    EmployeeContactUserResolver,
    UserListResolver,
  ],
})
export class FdvModule {}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BaseServices } from './../../kuba.services';
import { AboutKuba, CMSPage } from './../models/dashboard';
import { DashboardService } from './../Services/dashboard.services';
import { CustomizeHomePageService } from '../../customize-homepage/customize-homepage.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'dashboard-ownpage',
    templateUrl: 'dashboard-ownpage.component.html'
})

export class DashBoardOwnPageComponent implements OnInit {

    //#region variable

    newsTitle: any;
    newsId: number;
    content: string;
    option: string;
    translatekey: any;
    private subscriptions: Subscription[] = [];
    portalNews: any;

    //#endregion

    //#region constructor

    constructor(
        private dashBoardService: DashboardService,
        private route: ActivatedRoute,
        private customizeHomePageService: CustomizeHomePageService
    ) {
    }

    //#endregion

    //#region page-event

    ngOnInit() {
        this.option = this.route.snapshot.params['data'];
        if (this.option === 'Admin') {
            this.bindAboutKubaIk();
        } else {
            this.bindNewsFromPortal();
        }
    }

    //#endregion

    //#region method
    /**
     * bind kuba ik for dashboard
     */
    bindAboutKubaIk() {
        this.newsTitle = 'About Kuba IK';
        let applicationId = BaseServices.ApplicationId;
        this.translatekey = applicationId == 5 ? 'ABOUT_MEDI3' : 'ABOUT_KUBA';
        let about = <AboutKuba>this.route.snapshot.data['aboutKubaIk'];
        if (about != null) {
            this.content = about.ContentData;
        }
    }
    /**
     * bind news from portal
     */
    bindNewsFromPortal() {
        this.newsTitle = 'News From Portal';
        this.translatekey = 'NEWS_PORTAL';
        this.subscriptions.push(this.customizeHomePageService.getData(BaseServices.ApplicationId, 57, BaseServices.UserId).subscribe((portalNews) => {
            if (portalNews) {
                this.content = portalNews.ContentData;
            }
        }));
        let tvInfo = <AboutKuba[]>this.route.snapshot.data['aboutUs'];
        let news = tvInfo.filter(x => x.CmsId === CMSPage.PortalNews);
        if (news.length > 0) {
            this.portalNews = news[0].ContentData;
            this.content = this.portalNews;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}

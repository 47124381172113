import { Component, OnInit, ViewChild  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BaseServices } from './../../../kuba.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { MeasureProtocolService } from 'src/app/kuba/measure-protocol/services/measure-protocol.services';
import { DateSetting } from './../../../../_helpers/date-setting';
import { Subscription } from 'rxjs';

@Component({
  templateUrl: 'mpc-details.component.html'
})

export class MPCDetailsComponent extends DateSetting implements OnInit {

    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    cmpId: any;
    InstrumentJSONInfo: any;
    EarthLeakageJSONInfo: any;
    VisualControlJSONInfo: any;
    PowerLeakageJSONInfo: any;
    TemperatureJSONInfo: any;
    loading = false;
    private subscriptions: Subscription[] = [];

    constructor(
      private route: ActivatedRoute,
      public measureprotocolservice: MeasureProtocolService
    ) {
    super();

    }

    ngOnInit(): void {
      document.querySelector("body").classList.remove("opened");
      this.cmpId = this.route.snapshot.params['mpcid'];
    }

    generateReport(){

      this.subscriptions.push(this.measureprotocolservice.getAllCMPInstruments(this.cmpId).subscribe(InstrumentInfo => {
          if (InstrumentInfo) {
              this.InstrumentJSONInfo = {"InstrumentInfo":InstrumentInfo} ;
          }
        }));

        this.subscriptions.push(this.measureprotocolservice.getVisualControl(this.cmpId).subscribe(VisualControlInfo => {
          if (VisualControlInfo) {
            this.VisualControlJSONInfo = {"VisualControlInfo":VisualControlInfo} ;
          }
        }));

        this.subscriptions.push(this.measureprotocolservice.getAllEarthLeakage(this.cmpId).subscribe(EarthLeakageInfo => {
          if (EarthLeakageInfo) {
            this.EarthLeakageJSONInfo = {"EarthLeakageInfo":EarthLeakageInfo} ;
          }
        }));

        this.subscriptions.push(this.measureprotocolservice.getAllPowerLeakage(this.cmpId).subscribe(PowerLeakageInfo => {
          if (PowerLeakageInfo) {
            this.PowerLeakageJSONInfo = {"PowerLeakageInfo":PowerLeakageInfo} ;
          }
        }));

        this.subscriptions.push(this.measureprotocolservice.getAllCMPTemperature(this.cmpId).subscribe(TemperatureInfo => {
          if (TemperatureInfo) {
            this.TemperatureJSONInfo = {"TemperatureInfo":TemperatureInfo} ;
          }
        }));

        let mergedJSON = [this.InstrumentJSONInfo,this.VisualControlJSONInfo,
                          this.EarthLeakageJSONInfo,this.PowerLeakageJSONInfo,
                          this.TemperatureJSONInfo];
        let GenerateReportJson = mergedJSON;
        let cultureInfo = BaseServices.userCultureInfo();
        this.loading = true;
        let fileName = 'Measure Protocol';
        let type = 'pdf';
        let position = 0;
        let ReportJson = GenerateReportJson;
        
        this.subscriptions.push(this.measureprotocolservice
        .reportGenerate(BaseServices.BusinessId, cultureInfo, ReportJson)
            .subscribe(
                  blob => {
                      this.loading = false;
                      let link = document.createElement('a');
                      link.href = window.URL.createObjectURL(blob);
                      link.download = `${fileName}.${type.toLowerCase()}`;
                      link.click();
                  },
                  error => {
                      this.loading = false;
                      alert('Export not downloaded');
                  }
          ));
    }

    ngOnDestroy() {
      this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
      });
  }
}

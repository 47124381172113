import { EditorModule } from 'primeng/editor';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { LoginOriginalComponent } from './login-original.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './index';
import { NgModule } from '@angular/core';
import { ImageUploaderComponent } from './../shared/ecpl-article-viewer/image-uploader.component';
import { SearchListPipe } from '../shared/_helpers/pipes';
import { ToasterModule } from 'angular2-toaster';
import { AppSharedModule } from '../kuba/shared';
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        EditorModule,
        DropdownModule,
        CheckboxModule,
        DialogModule,
        ToasterModule,
        AppSharedModule
    ],
    exports: [
        LoginComponent,
        LoginOriginalComponent
    ],
    declarations: [
        SearchListPipe,
        LoginComponent,
        LoginOriginalComponent,
        ImageUploaderComponent
    ],
    providers: [],
})
export class LoginModule { }

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageKeys } from '../_models/storageKeys';
import { map } from 'rxjs/operators';
import { IdentityToken } from "../_models/identityToken";

/**
 * Service that handles user identity and authentication state.
 *
 */
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private identityToken = new BehaviorSubject<IdentityToken | null>(null);

  public identityToken$ = this.identityToken.asObservable();
  public isAuthenticated$ = this.identityToken$.pipe(map((token) => !!token));

  constructor() {
    this.identityToken = new BehaviorSubject<IdentityToken | null>(
      this.getStoredToken()
    );
  }

  public setIdentityToken(token: IdentityToken): void {
    localStorage.setItem(StorageKeys.IDENTITY_TOKEN, JSON.stringify(token));
    this.identityToken.next(token);
  }

  // Feature 1: Check if identity includes a given role or permission
  public hasRole(role: string): boolean {
    const token = this.identityToken.getValue();
    return token ? token.Role === role : false;
  }

  public hasPermission(permission: string): boolean {
    const token = this.identityToken.getValue();
    return token ? token.Permissions.includes(permission) : false;
  }

  // Feature 2: Observables that emit roles and permissions
  public roles$: Observable<string> = this.identityToken$.pipe(
    map((token) => token?.Role || '')
  );

  public permissions$: Observable<string[]> = this.identityToken$.pipe(
    map((token) => token?.Permissions || [])
  );

  private getStoredToken(): IdentityToken | null {
    const stored = localStorage.getItem(StorageKeys.IDENTITY_TOKEN);
    if (!stored) return null;

    try {
      return JSON.parse(stored);
    } catch (e) {
      localStorage.removeItem(StorageKeys.IDENTITY_TOKEN);
      return null;
    }
  }
}

import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MoveBusinessServices {
    constructor(private http: HttpClient) {
    }
    businessListByAdminUserOrPortal(userId: number, portalId: number) {
        return this.http.get(environment.BASE_URL + '/businessbyadminuserorpoprtal/' + userId + '/' + portalId, )
            .map(result => result);
    }

    updateBusiness(userId:number, data:any) {
        return this.http.post(environment.BASE_URL + '/movebusiness/' + userId, data, )
            .map(result => result);
    }
    getUserAndPortalTomoveBusiness(userOrPortalId: number, isPortal:any) {
        return this.http.get(environment.BASE_URL + '/usersandportaltomovebusiness/' + userOrPortalId + '/' + isPortal, )
            .map(result => result);
    }

    getAdminUsersAndPortalTitles() {
        return this.http.get(environment.BASE_URL + '/getadminusersandportaltitles/' + BaseServices.ApplicationId, )
            .map((result: any) => result);
    }
}

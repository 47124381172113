import { BaseServices } from './../../kuba.services';
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ManualService } from '../services/manual.services';
import { ManualRegistry } from './../models/index';
import { TreeView } from '../../common/models/index';

@Component({
  selector: 'manual-registry',
  templateUrl: 'manual-registry.component.html'

})
export class ManualRegistryComponent implements OnInit {
  @Input('treeData') treeData: TreeView;
  @Input() manualType: string;
  manualRegistry: ManualRegistry[];
  levelKeyName: string;
  isAdminMode = false;

  constructor(
    public router: Router) { }

  ngOnInit(): void {
    document.querySelector("body").classList.remove("opened");
     if (BaseServices.UserRole == 'Admin') {
       this.isAdminMode = true;
     }
  }

}
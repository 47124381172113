import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../kuba.services';

@Injectable()
export class CustomizeHomePageService {

    constructor(private http: HttpClient) { }

    add(data) {
        return this.http.post(environment.BASE_URL + '/homepages/homepage', data, BaseServices.headerOption)
            .map(result => result);
    }

    update(data) {
        return this.http.put(environment.BASE_URL + '/homepages/homepageupdate', data, BaseServices.headerOption)
            .map(result => result);
    }

    deleteVideo(Id) {
        return this.http.put(environment.BASE_URL + '/homepages/deletevideo/' + Id, BaseServices.headerOption)
            .map(result => result);
    }

    getData(applicationId: number, typeId: number, createdBy: number) {
        return this.http.
            get<any>(environment.BASE_URL + '/homepages/homepagelistbyapplication/' + applicationId + '/' + typeId + '/' + createdBy,
            BaseServices.headerOption)
            .map(result => result);
    }

    getDefaultVideo(applicationId: number, typeId: number, createdBy: number) {
        return this.http.
            get<any>(environment.BASE_URL + '/homepages/homepagelistbyapplication/' + applicationId + '/' + typeId + '/' + createdBy,
            BaseServices.headerOption)
            .map(result => result);
    }
}


import { Injectable } from '@angular/core';

import { Subject } from 'rxjs/Subject';

import { BusinessPortalService } from './../../portals/services/business-portal.services';
import { BaseServices } from './../../kuba.services';
import { Feature, FeatureBase } from './../models/features-base.model';
import { FeatureKey, Application, Roles } from './../../../_models/feature';
import * as _ from 'lodash';

import { BusinessServices } from '../../businesses/services/business.services';
import { Subscription } from 'rxjs';

@Injectable()
export class FeaturesBaseServices {

    //#region variable

    // Observable string sources
    private setFeaturesAccessSource = new Subject<Feature[]>();
    private setRModeAccessSource = new Subject<boolean>();
    private setMypageModuleSource = new Subject<boolean>();
    private subscriptions: Subscription[] = [];
    // Observable string streams
    getFeaturesAccess$ = this.setFeaturesAccessSource.asObservable();
    getRModeAccess$ = this.setRModeAccessSource.asObservable();
    getEmployeeNavigationAccess$ = this.setMypageModuleSource.asObservable();
    //#endregion

    //#region constructor

    //#endregion

    //#region methods

    public static getFeatureDetails(featureBase: FeatureBase): Feature[] {
        let featuresResult: Feature[];
        let featuresTemplate = <Feature[]>JSON.parse(featureBase.FeaturesTemplate);
        featuresTemplate = _.filter(featuresTemplate, 'checked'); //  Check Application level module
        if (featureBase.Type && featureBase.Type.toLocaleUpperCase() !== 'PORTAL') {
            featuresTemplate = this.setViewFeatures(featuresTemplate);
        }
        if (featuresTemplate) {
            featuresTemplate = this.setDefault(featuresTemplate, featureBase); // Default Value set
            featuresResult = featuresTemplate;
            let features = <Feature[]>JSON.parse(featureBase.Features);
            featuresResult = this.updateFeatures(featuresTemplate, features, featureBase);                                 
            if (featuresResult) {
                let isProjectTimeTable = true;
                featuresResult.forEach(element => {
                    let val = [];
                    if (element.id === FeatureKey.PROJECT) {
                        val = element.children;
                        val.forEach(e => {
                            if (e.translationKey === 'TIME_TABLE') {
                                if (e.access === true) {
                                    if (e.checked === false) {
                                        isProjectTimeTable = false;
                                    }
                                } else if (e.access === false) {
                                    isProjectTimeTable = false;
                                }
                            }
                            if (e.translationKey === 'TIMELOG') {
                                if (isProjectTimeTable === false) {
                                    e.access = false;
                                    e.checked = false;
                                }
                            }
                        })
                    }
                    // Remove checklist submodule
                    if(BaseServices.ApplicationId === Application.IK || BaseServices.ApplicationId === Application.DIN_HMS){
                        if (element.id === 20 || element.id === 43) {
                           element.children = element.children.filter(x => x.id !== FeatureKey.CLEANING);
                       } 
                    }
                    if (element.id === 19) {
                        val = element.children
                        val.forEach(e => {
                            if (e.translationKey === 'SAFETY_JOB_ANALYSIS') {
                                e.translationKey = 'IC_SJA';
                            }
                        })
                    }
                    if (element.id === 6) {
                        val = element.children
                        val.forEach(e => {

                            if (e.translationKey === 'LICENSE_AGREEMENT') {
                                let teat = ''

                                //  element.children.
                            }
                        })
                        val = val.filter(x => x.id !== 110);


                    }
                    // Disable non-working features kw-101
                    if (element.id === 15) {
                        val = element.children;
                        val.forEach(e => {
                            if (e.id === FeatureKey.MESSAGES || e.id === FeatureKey.SUGGESTION_BOX) {
                                e.checked = false;
                            }
                        })
                    }
                    // Disable non-working features kw-101
                    if (element.id === 6) {
                        // remove license agreement feature for dinhms application // #KW-176
                        if ((BaseServices.ApplicationId === Application.DIN_HMS || BaseServices.ApplicationId === Application.IK) &&
                            +BaseServices.roleId !== Roles.ADMIN && +BaseServices.roleId !== Roles.PORTAL) {
                            element.children = element.children.filter(x => x.id !== 110);
                        }
                        element.children = element.children.filter(x => x.id !== FeatureKey.DEFAULT_MENUS);
                        val = element.children;

                        val.forEach(e => {
                            if (e.translationKey === 'LICENSE_AGREEMENT') {
                            }
                            if (e.id === FeatureKey.HOME_PAGE) {
                                e.checked = false;
                            }
                        })
                    }
                    if (element.id === FeatureKey.CLIENT) {
                        element.children = element.children.filter(x => x.id !== FeatureKey.CLIENT_CONTACT);
                        val = element.children;
                    }
                    if (element.id === FeatureKey.SUB_CONTRACTOR) {
                        element.children = element.children.filter(x => x.id !== FeatureKey.CONTRACTOR_CONTACT);
                        val = element.children;
                    }
                    if (element.id === FeatureKey.SUPPLIERS) {
                        element.children = element.children.filter(x => x.id !== FeatureKey.SUPPLIER_CONTACT);
                        val = element.children;
                    }                                   
                })
            }
        }
        return featuresResult!;
    }

    /**
     * sets default state for all features
     * @param featuresTemplate {Feature[]}
     * @param featureBase {FeatureBase}
     */
    private static setDefault(featuresTemplate: Feature[], featureBase: FeatureBase): Feature[] {
        _.each(featuresTemplate, (data) => {
            data.checked = (data.id === FeatureKey.DEFAULT_MENUS);
            let ownFeature = JSON.parse(featureBase.Features);
            if (['1', '2'].indexOf(BaseServices.roleId) > -1 && ownFeature) {
                data.checked = data.IsDefault === 1 ? true : false;
            }
            if (featureBase.Type && featureBase.Details.Features && featureBase.Type.toLocaleUpperCase() !== 'PORTAL') {
                data.access = false;
            }
            if (data.children && data.children.length > 0) {
                this.setDefault(data.children, featureBase);
            }

        });
        return featuresTemplate;
    }

    private static setViewFeatures(featuresTemplate: Feature[]): Feature[] {
        featuresTemplate = _.filter(featuresTemplate, function (o) { return (o.id !== 111); });       
        return featuresTemplate;
    }

    private static updateFeatures(featuresTemplate: Feature[], features:any, featureBase: FeatureBase, parentId = 0) {
        let portalFeatures:any = null;
        
        if (featureBase.Type && featureBase.Type.toLocaleUpperCase() !== 'PORTAL') {
            if (featureBase.Details && featureBase.Details.BusinessPortalId > 0 && featureBase.Details.BusinessPortal) {
                portalFeatures = <Feature[]>JSON.parse(featureBase.Details.BusinessPortal.Features);
            }
        }
        if (features) {
            
            _.each(featuresTemplate, (data) => {
                let checkFeature = new Feature(); 
                let idval: any = { id: data.id };// current user feature
                checkFeature = <Feature>_(features
                    .filter((x:any) => x.id === parentId || parentId === 0)) // filter with parentId since all child have same id
                    .thru((parent) => {
                        return _.union(parent, _.map(parent, 'children'));
                    })
                    .flatten()
                    .find(idval);

                data.access = (portalFeatures === null);
                if (checkFeature) {
                    data.checked = checkFeature.checked;
                    if (BaseServices.roleId !== '1') {
                        data.access = checkFeature.access;
                    }
                    // disable the edit business
                    if (checkFeature.id === FeatureKey.EDIT_BUSINESS) {
                        if (BaseServices.roleId !== '1' && BaseServices.roleId !== '2') {
                            data.access = false;
                        }
                    }
                }


                if (featureBase.Type && featureBase.Type.toLocaleUpperCase() !== 'PORTAL' && portalFeatures) {
                    let checkPortalFeature = new Feature();
                    let idval: any = { id: data.id };
                    checkPortalFeature = <Feature>_(portalFeatures // current business's portal feature
                        .filter((x:any) => x.id === parentId || parentId === 0)) // filter with parentId since all child have same id
                        .thru((parent) => {
                            return _.union(parent, _.map(parent, 'children'));
                        })
                        .flatten()
                        .find(idval);
                    if (checkPortalFeature) {
                        if (!checkPortalFeature.checked) {
                            // portal level unchecked - restrict business level access
                            data.access = false;
                            data.checked = false;
                        }
                        // business feature edit - portal access is true
                        if (BaseServices.roleId === '2' && checkPortalFeature.checked) {
                            data.access = true;
                        }
                        // disable the edit business
                        if (checkPortalFeature.id === FeatureKey.EDIT_BUSINESS) {
                            if (BaseServices.roleId !== '1' && BaseServices.roleId !== '2') {
                                data.access = false;
                            }
                        }
                    }
                }

                if (data.children && data.children.length > 0) {
                    this.updateFeatures(data.children, features, featureBase, data.id);
                }

            });
        }
        return featuresTemplate;
    };
    // Service message commands
    setFeatures(feature: Feature[]) {
        this.setFeaturesAccessSource.next(feature);
    }

    // Service message commands
    setRModeFeatureEditor(isRMode: boolean) {
        this.setRModeAccessSource.next(isRMode);
    }
    // Service message commands
    setEmployeeEditModeNavigation(isEditMode: boolean) {
        this.setMypageModuleSource.next(isEditMode);
    }

    public getFeatureAccess() {
        let featuresAccess: Feature[];
        let currentUserRole = BaseServices.UserRole;
        
        let featureBase: FeatureBase = BaseServices.getUserFeatures();

        
        if (currentUserRole === 'Admin' || currentUserRole === 'Super' || currentUserRole === 'Developer') {
            featuresAccess = <Feature[]>JSON.parse(featureBase.FeaturesTemplate);
            this.setFeatures(featuresAccess);
        } else if (currentUserRole === 'Portal') {
            let portalId = BaseServices.PortalId;
            this.subscriptions.push(this.businessPortalService.getById(+portalId).subscribe(result => {
                if (result) {
                    featureBase.Features = result.Features;
                    featureBase.Type = 'portal';
                    featureBase.Details = result;
                    featuresAccess = FeaturesBaseServices.getFeatureDetails(featureBase);
                    this.setFeatures(featuresAccess);
                }
            }));
        } else {
            let businessId = BaseServices.BusinessId;
            if (businessId) {
                this.subscriptions.push(this.businessServices.getById(businessId).subscribe(result => {
                    if (result) {
                        featureBase.Features = result.Features;
                        featureBase.Type = 'business';
                        featureBase.Details = result;
                        featuresAccess = FeaturesBaseServices.getFeatureDetails(featureBase);
                        this.setFeatures(featuresAccess);
                    }
                }
                ));
            }

        }
    }

    //#endregion

    //#region constructor

    constructor(
        
        private businessPortalService: BusinessPortalService,
        public businessServices: BusinessServices 
    ) { }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}

import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { ModuleOverview } from '../models/moduleoverview'
import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ModuleOverviewServices {

    constructor(private http: HttpClient) { }


    getAllModuleOverviewDetails() {
        return this.http.get(environment.BASE_URL
            + '/moduleoverviewdetails/'
            + BaseServices.ApplicationId
            ,
            )
            .map(result => result);
    }

    adminModuleOverviewlist() {
        return this.http.get(environment.BASE_URL
            + '/moduleoverviewbyadmin/'
            + BaseServices.ApplicationId
            , )
            .map((result: any) => result);
    }
    getRModeModuleOverview() {
        return this.http.get(environment.BASE_URL + '/rmode-moduleoverview/' + BaseServices.BusinessId, )
            .map((response: any) => {
                return response
            });
    }
    getByModuleId(moduleId: number) {
        return this.http.get(environment.BASE_URL + '/moduleoverview/'+ BaseServices.ApplicationId + '/' + moduleId, )
        .map(result => result);
    }
    get(Id: number) {
        return this.http.get(environment.BASE_URL + '/moduleoverview/' + Id, )
            .map((result: any) => result);
    }
    add(data) {
        return this.http.post(environment.BASE_URL + '/moduleoverview', data, )
            .map((response: any) => { return response });
    }

    update(Id: number, data: ModuleOverview) {
        return this.http.put(environment.BASE_URL + '/moduleoverview/' + Id, data, )
            .map((response: any) => { return response });
    }

    delete(Id: number) {
        return this.http.delete(environment.BASE_URL + '/moduleoverviewdelete/' + Id, )
            .toPromise()
            .then((response: any) => { return response });
    }
}

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'deviations',
    templateUrl: 'deviation.component.html'
})
export class DeviationFormComponent implements OnInit {
    
    constructor(){
  }   ngOnInit(): void {
    document.querySelector("body").classList.remove("opened");
    }
}

import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { GoodsType, ReceptionControl } from './../models/reception-control';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class ReceptionControlService {

    constructor(private http: HttpClient) { }

    getAll(foodSafetyId: number) {
        return this.http.get(environment.BASE_URL + '/foodsafety/receptioncontrol/' + foodSafetyId, BaseServices.headerOption)
            .map((result: any) => result);
    }
    addReceptionControl(receptionControl: ReceptionControl) {
        return this.http.post(environment.BASE_URL + '/foodsafety/reception-control', receptionControl, BaseServices.headerOption)
            .map(result => result);
    }
    getById(foodSafetyId: number, receptionId: number) {
        return this.http.get(`${environment.BASE_URL}/foodsafety/receptioncontrol/${foodSafetyId}/${receptionId}`, BaseServices.headerOption)
            .map((result: any) => result);
    }
    getAllGoodsType() {
        return this.http.get(environment.BASE_URL + '/foodsafety/goodstype', BaseServices.headerOption)
            .map((result: any) => result);
    }
    getGoodsTypeByBusiness(businessId: number) {
        return this.http.get(environment.BASE_URL + '/foodsafety/goodstypes/' + businessId, BaseServices.headerOption)
            .map((result: any) => result);
    }
    deleteGoodsType(id: number) {
        return this.http.put(environment.BASE_URL + '/foodsafety/goodstype/' + id, BaseServices.headerOption)
            .map(result => result);
    }
    updateGoodsType(existingGoodsType: GoodsType) {
        return this.http.put(environment.BASE_URL + '/foodsafety/goodstype/', existingGoodsType, BaseServices.headerOption)
            .map(result => result);
    }
    createGoodsType(newGoodsType: GoodsType) {
        return this.http.post(`${environment.BASE_URL}/foodsafety/goodstype`,
            newGoodsType, BaseServices.headerOption)
            .map(result => result);
    }
    deleteReception(ids: number[]) {
        return this.http.put(environment.BASE_URL + '/foodsafety/receptioncontrol', ids, BaseServices.headerOption)
            .map(result => result)
            .catch(this.handleError);
    }
    getNextReceptionNo() {
        return this.http.get(`${environment.BASE_URL}/foodsafety/next-reception/${BaseServices.BusinessId}`, BaseServices.headerOption)
            .map(result => result);

    }
    getReceptionLog(receptionId: number) {
        return this.http.get(`${environment.BASE_URL}/foodsafety/receptionlog/${receptionId}`, BaseServices.headerOption)
            .map(result => result);

    }

    getReceptionControlDocument(featureId: number, receptionControlId: number) {
        return this.http.get(`${environment.BASE_URL}/foodsafety/getreceptiondocument/${featureId}/${receptionControlId}`, BaseServices.headerOption)
    }

    deleteReceptionControlDocument(featureId: number, receptionControlId: number, id:number) {
        return this.http.delete(`${environment.BASE_URL}/foodsafety/deletereceptiondocument/${featureId}/${receptionControlId}/${id}`, BaseServices.headerOption)
        .map((response: any) => {
            if (response.status === 200) {
                let data = response.data || [];
                data.forEach(d => {
                    d.CreatedOn = new Date(d.CreatedOn);
                    d.ModifiedOn = new Date(d.ModifiedOn);
                });
                response.data = data;
                return response;
            } else {
                return response || {};
            }
        })
    }
    
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}

import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationService } from 'primeng/api';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { v4 as uuidv4 } from 'uuid';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { BusinessCourse, EmployeeCourseDocumentNode } from './../models/employeeCourse';

import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { SelectItem } from 'primeng/api';

import {
  humanizeBytes,
  UploadFile,
  UploadInput,
  UploadOutput,
} from 'ngx-uploader';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { DateService } from 'src/app/_services/date.service';
import { HelperService } from 'src/app/_services/helper.service';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { BusinessPortalService } from 'src/app/kuba/portals/services/business-portal.services';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { environment } from 'src/environments/environment';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { UserRole } from '../../follow-ups/models/deviation';
import { BaseServices } from './../../kuba.services';
import { EmployeeCourse } from './../models/index';
import { EmployeeServices } from './../services/employee.services';
import { EmployeeCourseServices } from './../services/employeecourse.service';

require('jspdf-autotable');

@Component({
  selector: 'employee-course-list',
  templateUrl: 'employee-course-list.component.html',
})
export class CourseListComponent implements OnInit {
  //#region variables
  @ViewChild('dt', { static: false }) courseDetailsTable: Table;
  @ViewChild('courseTable', { static: false }) courseTable: Table;
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  userLevel: boolean = false;

  courseDetailForm: FormGroup;
  startDate: string;
  endDate: string;
  businesses: SelectItem[];
  courses: SelectItem[];
  courseDetails: EmployeeCourse[];
  courseDetailsValue: EmployeeCourse[];
  Employee: SelectItem[];
  Department: SelectItem[];
  files: UploadFile[];
  videoUrl: string;
  locale: string;
  safeURL: any;
  additionalData: any;
  additionalData1: any;
  additionalData3: any;
  isVideo = false;
  isPortal: boolean;
  coursePopup: FormGroup;
  coursesSearch: FormGroup;
  courseTypeId: number;
  courseNames: SelectItem[];
  courseList: any[];
  isNameExists: boolean;
  existsAlerttext: string;
  addDept: boolean;
  course: any;
  addCourse = false;
  viewCourse = false;
  isPortalBusiness = true;
  IsCommonPortalCourseNames = false;
  uploadInput: EventEmitter<UploadInput>;
  @Output() saveChanges: EventEmitter<any> = new EventEmitter<any>();
  EmployeeCourse = new EmployeeCourse();
  config: UploaderConfig;
  viewDocument = false;
  responsibleExcecution: SelectItem[];
  resposibleNames: SelectItem[];
  courseAttachment: any = [];
  /**
   * Date picker configuration option
   */

  public renewalDateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    height: '32px',
    selectionTxtFontSize: '14px',
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
  };
  public startDateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    height: '26px',
    selectionTxtFontSize: '14px',
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
  };
  public startDateOptions1: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    height: '26px',
    selectionTxtFontSize: '14px',
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
  };
  public endDateOptions: IMyDpOptions = this.startDateOptions;
  public endDateOptions1: IMyDpOptions = this.startDateOptions1;
  public startDateCourse: IMyDpOptions = this.startDateOptions;
  public endDateCourse: IMyDpOptions = this.startDateOptions;
  showemployee: boolean = false;
  showdata: boolean = false;
  hideemployee: boolean = false;
  Description: any;
  CourseName: any;
  SDCOurse: Date;
  EDCourse: Date;
  Renewaldate: Date;
  Institute: any;
  ResponsibleId: any;
  DeadlineNotification: any;
  employeecoursevalue: any = [];
  emp: any = [];
  guest: boolean = false;
  selectedEmployee: any = [];
  uuid: any;
  newDocumentAdded: any = [];
  files1: any = [];
  humanizeBytes: (bytes: number) => string;
  EmpId: any;
  SaveShow: boolean = false;
  // #endregion
  href: any;
  urlvalue: any;
  contracthref: any;
  coursehref: any;
  isEmployeecourses: boolean = true;
  firsttry: any;
  upConfig: UploaderConfig;
  uploadedFiles: any = [];
  private subscriptions: Subscription[] = [];
  isPortalCourse: boolean;
  isShowAddCourse: boolean = false;
  //#region constructor

  /**
   *  Constructor
   * @param empcrseservice {EmployeeCourseServices}
   * @param _fb {FormBuilder}
   * @param route {ActivatedRoute}
   */
  constructor(
    private router: Router,
    private _sanitizer: DomSanitizer,
    private employeeCourseServices: EmployeeCourseServices,
    private employeeServices: EmployeeServices,
    private _fb: FormBuilder,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private businessServices: BusinessServices,
    private businessPortalService: BusinessPortalService,
    private dateService: DateService
  ) {
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;

    this.courseDetailForm = this._fb.group({
      StartDate: [''],
      EndDate: [''],
    });
    this.coursePopup = this._fb.group({
      Course: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.coursesSearch = this._fb.group({
      courseName: [''],
    });
  }
  /**
   * page init
   */
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    this.isPortal = +BaseServices.roleId === UserRole.PORTAL ? true : false;
    this.coursehref = this.router.url.substring(
      this.router.url.indexOf('list') + 0,
      this.router.url.indexOf('list') + 4
    );
    this.contracthref = this.router.url.substring(
      this.router.url.indexOf('contractlist') + 0,
      this.router.url.indexOf('contractlist') + 12
    );
    this.firsttry = this.router.url.substring(
      this.router.url.indexOf('contractlist') + 0,
      this.router.url.indexOf('contractlist') + 12
    );
    if (this.isPortal == true) {
      this.isPortalCourse = true;
    }
    if (this.contracthref === 'contractlist') {
      this.isEmployeecourses = false;
    } else if (this.coursehref === 'list') {
      this.isEmployeecourses = true;
    }
    if (BaseServices.roleId === '5') {
      this.userLevel = true;
    }
    if (+BaseServices.roleId === UserRole.GUEST) {
      this.guest = true;
    }
    if (BaseServices.roleId === '2') {
      this.isPortalBusiness = true;
      this.bindCourseDetails();
    } else {
      this.isPortalBusiness = this.route.snapshot.data['businesscreator'];
      let portalBusinessId = this.isPortalBusiness;
      if (portalBusinessId) {
        this.subscriptions.push(
          this.businessPortalService
            .getById(+portalBusinessId)
            .subscribe((result) => {
              if (result) {
                this.isPortalBusiness = this.IsCommonPortalCourseNames =
                  result == null ? true : result.IsCommonPortalCourseNames;
                this.bindCourseDetails();
              }
            })
        );
      } else {
        this.bindCourseDetails();
      }
    }
    this.bindDropDown();
    if (this.contracthref === 'contractlist') {
      let listcontract =
        BaseServices.roleId === '2'
          ? <EmployeeCourse[]>this.route.snapshot.data['portalListContract']
          : <EmployeeCourse[]>this.route.snapshot.data['listcontract'];
      this.courseDetails = listcontract;
      this.courseDetailsValue = listcontract;
    } else if (this.coursehref === 'list') {
      let list =
        BaseServices.roleId === '2'
          ? <EmployeeCourse[]>this.route.snapshot.data['portalList']
          : <EmployeeCourse[]>this.route.snapshot.data['list'];
      this.courseDetails = list;
      this.courseDetailsValue = list;
    }

    if (this.isEmployeecourses == false) {
      this.subscriptions.push(
        this.translate.stream('FILENAME').subscribe((val) => {
          this.additionalData = {
            fileName: val.EMPLOYEE_CONTRACT_DETAILS,
            header: 'Employee Contract Details',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Employee Name', dataKey: 'Employee' },
              { title: 'Department', dataKey: 'Department' },
              { title: 'Contract Name', dataKey: 'Course' },
              { title: 'Institute', dataKey: 'Institute' },
              { title: 'Renewal Date', dataKey: 'Renewal_Date' },
              { title: 'Start Date', dataKey: 'StartDate' },
              { title: 'End Date', dataKey: 'EndDate' },
            ],
          };
        })
      );
    } else if (this.isEmployeecourses == true) {
      this.subscriptions.push(
        this.translate.stream('FILENAME').subscribe((val) => {
          this.additionalData = {
            fileName: val.EMPLOYEE_COURSE_DETAILS,
            header: 'Employee Course Details',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Employee Name', dataKey: 'Employee' },
              { title: 'Department', dataKey: 'Department' },
              { title: 'Course Name', dataKey: 'Course' },
              { title: 'Institute', dataKey: 'Institute' },
              { title: 'Renewal Date', dataKey: 'Renewal_Date' },
              { title: 'Start Date', dataKey: 'StartDate' },
              { title: 'End Date', dataKey: 'EndDate' },
            ],
          };
        })
      );
    }

    if (this.isPortalCourse == true) {
      this.subscriptions.push(
        this.translate.stream('FILENAME').subscribe((val) => {
          this.additionalData = {
            fileName: val.EMPLOYEE_CONTRACT_DETAILS,
            header: 'Employee Contract Details',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Business Name', datakey: 'BusinessName' },
              { title: 'Employee Name', dataKey: 'Employee' },
              { title: 'Department', dataKey: 'Department' },
              { title: 'Contract Name', dataKey: 'Course' },
              { title: 'Institute', dataKey: 'Institute' },
              { title: 'Renewal Date', dataKey: 'Renewal_Date' },
              { title: 'Start Date', dataKey: 'StartDate' },
              { title: 'End Date', dataKey: 'EndDate' },
            ],
          };
        })
      );
    }
    this.showemployee = true;

    this.getEmployee();

    this.additionalData1 = {
      BusinessId: BaseServices.BusinessId,
      Status: '1',
      ModifiedBy: BaseServices.UserId,
      ModifiedOn: new Date(),
      IsCommonDocument: '0',
    };
    if (this.isEmployeecourses == true) {
      this.config = {
        title: 'COURSE_DOCUMENT',
        titleAsLabel: true,
        windowedHeight: false,
        viewSwitch: false,
        showUserMeta: false,
        showSearchFilter: false,
        showUploadButton: true,
        showDeleteButton: true,
        uploaderUri:
          environment.BASE_URL + '/file/upload/business/employee/private-docs',
        addtionalData: this.additionalData1,
      };

      this.upConfig = {
        title: null!,
        titleAsLabel: false,
        windowedHeight: false,
        viewSwitch: false,
        showUserMeta: false,
        showSearchFilter: false,
        showUploadButton: false,
        showDeleteButton: false,
        uploaderUri: null!,
        addtionalData: this.additionalData1,
      };
    } else if (this.isEmployeecourses == false) {
      this.config = {
        title: 'CONTRACT_DOCUMENT',
        titleAsLabel: true,
        windowedHeight: false,
        viewSwitch: false,
        showUserMeta: false,
        showSearchFilter: false,
        showUploadButton: true,
        showDeleteButton: true,
        uploaderUri:
          environment.BASE_URL + '/file/upload/business/employee/private-docs',
        addtionalData: this.additionalData1,
      };
    }
    this.GetBusiness();
    if (BaseServices.roleId === '2') {
      let portalId = BaseServices.PortalId;
      this.subscriptions.push(
        this.businessPortalService.getById(portalId).subscribe((result) => {
          if (result) {
            this.IsCommonPortalCourseNames = result.IsCommonPortalCourseNames;
          }

          if (
            this.isPortal == true &&
            BaseServices.PortalManager == true &&
            this.IsCommonPortalCourseNames == true
          ) {
            this.isShowAddCourse = true;
          } else {
            this.isShowAddCourse = false;
          }
        })
      );
    } else {
      let portalId: string;
      this.subscriptions.push(
        this.businessPortalService
          .getById(
            portalId != null
              ? BaseServices.userSettings.userFeature.Details.BusinessPortalId
              : BaseServices.userSettings.userBase.PortalId
          )
          .subscribe((result) => {
            setTimeout(() => {
              if (!this.isPortal && !this.IsCommonPortalCourseNames) {
                this.isShowAddCourse = true;
              } else {
                this.isShowAddCourse = false;
              }
            }, 1000);
          })
      );
    }
  }
  getEmployee() {
    this.subscriptions.push(
      this.employeeServices
        .getActiveEmployees(BaseServices.BusinessId)
        .subscribe((result) => {
          this.emp = result;
          for (let i = 0; i < this.emp.length; i++) {
            this.employeecoursevalue.push({
              Id: this.emp[i].Id,
              Name: this.emp[i].Name,
              DepartmentName: this.emp[i].DepartmentName,
              AddFiles: '',
            });
          }
        })
    );
  }

  bindCourseFilter() {
    this.courses = [];
    this.courseList = [];
    if (this.isPortal || this.isPortalBusiness) {
      this.course = this.route.snapshot.data['portalcourse'];
    } else {
      this.course = this.route.snapshot.data['course'];
    }
    if (this.course) {
      this.courseList = this.course;
      this.course.forEach((course: any) => {
        this.courses.push({ label: course.Title, value: course.Id });
      });
    }
  }

  // #endregion

  //#region page-events
  // #endregion

  //#region Control-events

  /**
   * startdate calendar onselect event
   * @param event {IMyDateModel}
   */
  onCourseStartDateChanged(event: IMyDateModel) {
    // custom filter datatable
    this.startDate = HelperService.formatDateForFilter(event.formatted);
    if (this.startDate && this.endDate) {
      this.subscriptions.push(
        this.employeeCourseServices
          .getEmployeeCourseListWithDateFilter(
            BaseServices.BusinessId,
            this.startDate,
            this.endDate
          )
          .subscribe((x: any) => {
            this.courseDetails = x;
            this.courseDetailsValue = x;
          })
      );
    }
  }
  /**
   * startdate calendar onselect event
   * @param event{any}
   */
  onStartDateChanged(event: IMyDateModel) {
    if (event) {
      // custom filter datatable
      this.startDate = HelperService.formatDateFilter(event.formatted);
      this.filterTable();
    }
  }

  /**
   * enddate calendar onselect event
   * @param event {IMyDateModel}
   */
  onEndDateChanged(event: IMyDateModel) {
    if (event) {
      // custom filter datatable
      this.endDate = HelperService.formatDateFilter(event.formatted);
      this.filterTable();
    }
  }
  /**
   * course Type popup control clear
   */
  onBeforeCourseDialogHide() {
    this.coursePopup.reset();
    this.courseTable.reset();
    this.courseTypeId = 0;
  }
  /**
   * date filter for table
   */
  filterTable() {
    let list =
      BaseServices.roleId === '2'
        ? <EmployeeCourse[]>this.route.snapshot.data['portalList']
        : <EmployeeCourse[]>this.route.snapshot.data['list'];
    if (this.startDate || this.endDate) {
      this.courseDetails = [];
      if (this.startDate && this.endDate) {
        list = list.filter((x) => {
          return (
            new Date(x.Renewaldate) >= new Date(this.startDate) &&
            new Date(x.Renewaldate) <= new Date(this.endDate)
          );
        });
      } else if (this.endDate) {
        list = list.filter((x) => {
          return new Date(x.Renewaldate) <= new Date(this.endDate);
        });
      } else {
        list = list.filter((x) => {
          return new Date(x.Renewaldate) >= new Date(this.startDate);
        });
      }
      this.courseDetails.push(...list);
    }
  }
  /**
   * enddate calendar onselect event
   * @param event {IMyDateModel}
   */
  onCourseEndDateChanged(event: IMyDateModel) {
    // custom filter datatable
    this.endDate = HelperService.formatDateForFilter(event.formatted);
    if (this.startDate && this.endDate) {
      this.subscriptions.push(
        this.employeeCourseServices
          .getEmployeeCourseListWithDateFilter(
            BaseServices.BusinessId,
            this.startDate,
            this.endDate
          )
          .subscribe((x: any) => {
            this.courseDetails = x;
            this.courseDetailsValue = x;
          })
      );
    }
  }
  /**
   * On Department Changed
   * @param e {any}
   * @param departmentDropdown {any}
   */
  onDepartmentChanged(e: any, departmentDropdown: any) {
    if (e.value) {
      // custom filter datatable
      this.courseDetailsTable.filter(
        departmentDropdown.selectedOption.label,
        'Department',
        'equals'
      );
    } else {
      this.courseDetailsTable.reset();
    }
  }
  /**
   * On Employee Changed
   * @param e {any}
   * @param employeeDropdown {any}
   */
  onEmployeeChanged(e: any, employeeDropdown: any) {
    if (e.value) {
      // custom filter datatable
      this.courseDetailsTable.filter(
        employeeDropdown.selectedOption.label,
        'Employee',
        'equals'
      );
    } else {
      this.courseDetailsTable.reset();
    }
  }
  /**
   * On Course Changed
   * @param e {any}
   * @param courseDropdown {any}
   */
  onCourseChanged(e: any, courseDropdown: any) {
    if (e.value) {
      // custom filter datatable
      this.courseDetailsTable.filter(
        courseDropdown.selectedOption.label,
        'Course',
        'equals'
      );
    } else {
      this.courseDetailsTable.reset();
    }
  }
  onBusinessChanged(e: any, businessDropdown: any) {
    if (e.value) {
      this.courseDetails = this.courseDetailsValue.filter(
        (x) => x.BusinessId == e.value
      );
    } else {
      this.courseDetailsTable.reset();
    }
  }
  // #endregion

  //#region methods

  /**
   * To bind DropDowns
   */
  bindDropDown() {
    this.Employee = [];
    this.Department = [];
    this.Employee.push({ label: 'select', value: null });
    this.Department.push({ label: 'select', value: null });
    let department = this.route.snapshot.data['department'];
    if (department) {
      department.forEach((departments: any) => {
        this.Department.push({
          label: departments.Name,
          value: departments.Id,
        });
      });
    }
    let employee = this.route.snapshot.data['employee'];
    if (employee) {
      employee.forEach((employees: any) => {
        this.Employee.push({
          label: employees.Name,
          value: employees.Id,
        });
      });
    }

    let businessList = this.route.snapshot.data['business'];
    if (businessList) {
      this.businesses = [];
      this.businesses.push({ label: 'Select', value: null });
      let activeBusiness = businessList.filter((x: any) => x.Status === 1);
      if (activeBusiness) {
        activeBusiness.forEach((list: any) => {
          this.businesses.push({
            label: list.CompanyName,
            value: list.Id,
          });
        });
      }
    }

    this.responsibleExcecution = [];
    this.courseNames = [];
    this.resposibleNames = this.route.snapshot.data['responsibleExecution'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.responsibleExcecution = [];
        this.responsibleExcecution.push({ label: val.SELECT, value: null });
      })
    );
    if (this.resposibleNames) {
      this.resposibleNames.forEach((element: any) => {
        this.responsibleExcecution.push({
          label: element.Name,
          value: element.Id,
        });
      });
    }
  }
  /**
   * Get current date
   */
  getCurrentDate() {
    let dateFormat = new Date();
    return {
      year: dateFormat.getFullYear(),
      month: dateFormat.getMonth() + 1,
      day: dateFormat.getDate(),
    };
  }
  /**
   * Method for setting date range
   * @param dateConfig {IMyDpOptions}
   * @param event {IMyDateModel}
   * @param type {string}
   */
  setDateInput(
    dateConfig: IMyDpOptions,
    event: IMyDateModel,
    type: string
  ): IMyDpOptions {
    let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
    let dateOptions = HelperService.setDateRange(dateConfig, date, type);
    return dateOptions;
  }
  /**
   * reset filter
   */
  getCourseDetail() {
    if (this.contracthref === 'listcontract') {
      this.startDate = '';
      this.endDate = '';
      this.courseDetails = <EmployeeCourse[]>(
        this.route.snapshot.data['listcontract']
      );
      this.courseDetailsValue = this.courseDetails;
      this.courseDetailForm = this._fb.group({
        StartDate: '',
        EndDate: '',
      });
    } else if (this.coursehref === 'list') {
      this.startDate = '';
      this.endDate = '';
      this.courseDetails = <EmployeeCourse[]>this.route.snapshot.data['list'];
      this.courseDetailsValue = this.courseDetails;
      this.courseDetailForm = this._fb.group({
        StartDate: '',
        EndDate: '',
      });
    }
  }

  /**
   * add and update sub department
   */
  savecourse() {
    let businessCourse = new BusinessCourse();
    businessCourse.Id = this.courseTypeId ? this.courseTypeId : 0;
    businessCourse.Title = this.coursePopup.value.Course;
    businessCourse.Status = '1';
    businessCourse.BusinessId = BaseServices.BusinessId;
    businessCourse.AppId = BaseServices.ApplicationId;
    businessCourse.IsEmployeeCourse = this.isEmployeecourses;
    if (this.isPortal) {
      businessCourse.portalId = BaseServices.PortalId;
    }
    if (businessCourse.Id > 0) {
      businessCourse.ModifiedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.employeeCourseServices
          .updateCourseData(businessCourse)
          .subscribe((courseDetail: any) => {
            if (courseDetail) {
              this.toasterComponent.callToast();
              this.bindCourseDropdown(courseDetail);
              this.coursesSearch.get('courseName')!.patchValue(courseDetail.Id);
            } else {
              this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
              this.isNameExists = true;
              setTimeout(() => {
                this.isNameExists = false;
              }, 3000);
            }
          })
      );
    } else {
      businessCourse.CreatedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.employeeCourseServices
          .addcourseData(businessCourse)
          .subscribe((addedCourse: any) => {
            this.subscriptions.push(
              this.employeeCourseServices
                .getallPortalCourse(
                  BaseServices.PortalId,
                  BaseServices.BusinessId,
                  this.isEmployeecourses
                )
                .subscribe((result: any) => {
                  if (result) {
                    this.courses = [];
                    result.forEach((element: any) => {
                      this.courses.push({
                        label: element.Title,
                        value: element.Id,
                      });
                    });
                    this.courseList = this.courses;
                  }
                })
            );
            if (addedCourse) {
              this.toasterComponent.callToast();
              this.bindCourseDropdown(addedCourse);
              this.coursesSearch.get('courseName')!.patchValue(addedCourse.Id);
            } else {
              this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
              this.isNameExists = true;
              setTimeout(() => {
                this.isNameExists = false;
              }, 3000);
            }
          })
      );
    }
  }
  /**
   *  edit course
   * @param course {any}
   */
  editCourse(course: any) {
    this.courseTypeId = course.Id;
    this.coursePopup = this._fb.group({
      Course: course.Title,
    });
  }

  /**
   * delete course
   * @param course {any}
   */
  deleteCourse(course: any) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.employeeCourseServices
            .deletecourseData(course.Id)
            .subscribe((isCourseDeleted: any) => {
              if (isCourseDeleted.isSuccess) {
                this.bindCourse();
                this.toasterComponent.callToastDlt();
                this.bindCourseDetails();
              } else {
                this.existsAlerttext = this.translate.instant(`CANNOT_DELETE`);
                this.isNameExists = true;
                setTimeout(() => {
                  this.isNameExists = false;
                }, 3000);
              }
            })
        );
      },
    });
  }

  bindCourseDetails() {
    this.courses = [];
    this.courseList = [];
    if (!this.IsCommonPortalCourseNames) {
      this.employeeCourseServices
        .getAllByPortalCourseName(
          BaseServices.BusinessId,
          this.isEmployeecourses
        )
        .subscribe((course: any) => {
          if (course) {
            course.forEach((element: any) => {
              this.courses.push({ label: element.Title, value: element.Id });
            });
            this.courseList = course;
          }
        });
    } else if (this.IsCommonPortalCourseNames) {
      this.employeeCourseServices
        .getallPortalCourse(
          BaseServices.PortalId,
          BaseServices.BusinessId,
          this.isEmployeecourses
        )
        .subscribe((result: any) => {
          if (result) {
            result.forEach((element: any) => {
              this.courses.push({ label: element.Title, value: element.Id });
            });
            this.courseList = result;
          }
        });
    } else {
      this.subscriptions.push(
        this.employeeCourseServices
          .getAllByCourseName(BaseServices.BusinessId, this.isEmployeecourses)
          .subscribe((course) => {
            if (course) {
              course.forEach((element: any) => {
                this.courses.push({ label: element.Title, value: element.Id });
              });
              this.courseList = course;
            }
          })
      );
    }
  }

  /**
   * bind course table
   */
  bindCourse() {
    if (this.isPortal) {
      this.subscriptions.push(
        this.employeeCourseServices
          .getPortalCourse(BaseServices.UserId, this.isEmployeecourses)
          .subscribe((courselist) => {
            if (courselist) {
              this.courses = [];
              courselist.forEach((element: any) => {
                this.courses.push({ label: element.Title, value: element.Id });
              });
              this.courseList = courselist;
            }
          })
      );
    } else {
      this.subscriptions.push(
        this.employeeCourseServices
          .getAllByCourseName(BaseServices.BusinessId, this.isEmployeecourses)
          .subscribe((course) => {
            if (course) {
              this.courses = [];
              course.forEach((element: any) => {
                this.courses.push({ label: element.Title, value: element.Id });
              });
            }
          })
      );
    }
  }

  /**
   * course after event trigger
   * @param department {any}
   */
  bindCourseDropdown(CourseDetail: any) {
    if (CourseDetail) {
      this.courseTypeId = CourseDetail.Id;
      this.bindCourse();
      this.addCourse = false;
    }
  }
  // #endregion

  onShowEmployee() {
    this.showemployee = false;
    this.showdata = true;
    this.hideemployee = true;
  }
  onHideEmployee() {
    this.showemployee = true;
    this.showdata = false;
    this.hideemployee = false;
  }

  onMultipleCourseEmployeeSave() {
    if (
      this.CourseName != null &&
      this.SDCOurse != null &&
      this.EDCourse != null &&
      this.Description != null &&
      this.selectedEmployee.length > 0
    ) {
      let appSettings = JSON.parse(sessionStorage.getItem('appSettings')!);
      let languageId = parseInt(sessionStorage.getItem('languageId')!);
      this.EmployeeCourse.Id = 0;
      this.EmployeeCourse.BusinessCourseId = +this.CourseName;
      this.EmployeeCourse.Institute = this.Institute;
      this.EmployeeCourse.ResponsibleId = this.ResponsibleId;
      this.EmployeeCourse.DeadlineNotification =
        this.DeadlineNotification != null ? this.DeadlineNotification : 0;
      this.EmployeeCourse.BusinessId = BaseServices.BusinessId;

      this.EmployeeCourse.StartDate = this.SDCOurse
        ? this.dateService.formatDateToString(this.SDCOurse)
        : '';


      this.EmployeeCourse.EndDate = this.EDCourse
          ? this.dateService.formatDateToString(this.EDCourse)
          : '';

      this.EmployeeCourse.Renewaldate = this.Renewaldate
        ? this.dateService.formatDateToString(this.Renewaldate)
        : '';

      this.EmployeeCourse.Description = this.Description;
      this.EmployeeCourse.LanguageId = languageId;
      this.EmployeeCourse.isEmployeecourse = true;

      this.EmployeeCourse.Status = '1';
      this.EmployeeCourse.CreatedBy = BaseServices.UserId;
      for (let i = 0; i < this.selectedEmployee.length; i++) {
        this.EmployeeCourse.EmployeeId = this.selectedEmployee[i].Id;
        this.EmployeeCourse.EmployeeDocument = [];
        var doc = this.employeecoursevalue.filter(
          (x: any) => x.Id == this.selectedEmployee[i].Id
        );
        let keyBindings = {
          Originalname: 'OriginalFileName',
          FileTypeId: 'FileType',
        };
        if (this.selectedEmployee[0].Id == doc[0].Id) {
          if (doc[0].AddFiles == null || doc[0].AddFiles == '') {
            this.EmployeeCourse.EmployeeDocument = [];
          } else {
            doc[0].AddFiles.forEach((file: any) => {
              let employeeDoc = Object.assign(
                {},
                file.File,
                this.additionalData1
              );
              employeeDoc.Id = 0;
              employeeDoc.Status = 1;
              employeeDoc.CreatedBy = BaseServices.UserId;
              employeeDoc.FileType = doc[0].AddFiles[0].File.fileTypeId;
              employeeDoc.Filename = doc[0].AddFiles[0].File.filename;
              employeeDoc.OriginalFileName =
                doc[0].AddFiles[0].File.originalname;
              employeeDoc.EmployeeId = this.selectedEmployee[i].Id;
              this.EmployeeCourse.EmployeeDocument.push(
                HelperService.switchKeysCase(employeeDoc, keyBindings, 'L') as EmployeeCourseDocumentNode
              );
            });
          }
        }
        this.subscriptions.push(
          this.employeeCourseServices
            .add(this.EmployeeCourse)
            .subscribe((employeeCourse) => {
              if (employeeCourse) {
                if (this.selectedEmployee.length == i + 1) {
                  this.subscriptions.push(
                    this.employeeCourseServices
                      .list(BaseServices.BusinessId)
                      .subscribe((x) => {
                        this.courseDetails = x;
                      })
                  );
                  this.toasterComponent.callToast();
                  this.CourseName = '';
                  this.SDCOurse = null;
                  this.EDCourse = null;
                  this.Renewaldate = null;
                  this.Description = '';
                  this.Institute = '';
                  this.ResponsibleId = '';
                  this.DeadlineNotification = '';
                  this.selectedEmployee = [];
                  for (let i = 0; i < this.employeecoursevalue.length; i++) {
                    this.employeecoursevalue[i].AddFiles = '';
                  }
                }
              }
            })
        );
      }
      if (BaseServices.roleId == '2') {
        this.subscriptions.push(
          this.employeeCourseServices
            .portalList(BaseServices.UserId)
            .subscribe((result) => {
              this.courseDetails = result;
            })
        );
      } else {
        this.subscriptions.push(
          this.employeeCourseServices
            .list(BaseServices.BusinessId)
            .subscribe((data) => {
              this.courseDetails = data;
            })
        );
      }
    }
  }

  onCourseDelete(id: any) {
    var x = this.employeecoursevalue.filter((x: any) => x.Id == id);
    x[0].AddFiles = [];
  }
  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: this.config.uploaderUri,
      method: 'POST',
      data: this.config.addtionalData,
    };
    this.uploadInput.emit(event);
  }

  onUploadOutput(output: UploadOutput): void {
    this.uploadedFiles = [];
    if (output.type === 'allAddedToQueue') {
      // when all files added in queue
      // comment this if you want to manual upload files
      const event: UploadInput = {
        type: 'uploadAll',
        url: this.config.uploaderUri,
        method: 'POST',
        data: this.config.addtionalData,
      };
      if (event.data) {
        this.uploadInput.emit(event);
      } else {
        this.files1 = [];
        alert(this.translate.instant('SELECT_FOLDER_TO_DROP_FILE'));
      }
    } else if (
      output.type === 'addedToQueue' &&
      typeof output.file !== 'undefined'
    ) {
      if (output.file) {
        // add file to array when added
        this.files1.push(output.file);
      }
    } else if (
      output.type === 'uploading' &&
      typeof output.file !== 'undefined'
    ) {
      // update current data in files array for uploading file
      const index = this.files1.findIndex(
        (file: any) =>
          typeof output.file !== 'undefined' && file.id === output.file.id
      );
      if (output.file) {
        // add file to array when added
        this.files1[index] = output.file;
      }
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.files1 = this.files1.filter(
        (file: UploadFile) => file !== output.file
      );
    } else if (output.type === 'dragOver') {
    } else if (output.type === 'dragOut') {
    } else if (output.type === 'drop') {
    } else if (
      output.type === 'rejected' &&
      typeof output.file !== 'undefined'
    ) {
    } else if (output.type === 'done') {
      if (typeof this.uploadedFiles === 'undefined') {
        this.uploadedFiles = [];
      }
      this.removeFromFiles(this.files1, output.file);
      this.uuid = uuidv4();
      if (output.file?.response === '') {
        alert(
          output.file.name +
            ' ' +
            this.translate.instant('EXCEEDS_MAX_FILE_LIMITS')
        );
      } else {
        output.file!.response.Id = this.uuid.replace('-', '0').substring(1, 20);
        var x = this.employeecoursevalue.filter((x: any) => x.Id == this.EmpId);
        this.uploadedFiles.push({
          File: output.file!.response,
          EmployeeId: this.EmpId,
        });
        x[0].AddFiles = this.uploadedFiles;
        this.EmpId = '';
        delete output.file!.response.Id;
      }
    }
  }
  removeFromFiles(files: any, obj: any): void {
    const index = files.indexOf(obj);
    files.splice(index, 1);
  }
  onEmpId(e: any) {
    this.EmpId = e;
  }

  onSaveShow() {
    if (
      this.CourseName != null &&
      this.SDCOurse != null &&
      this.EDCourse != null &&
      this.Description != null &&
      this.selectedEmployee.length > 0
    ) {
      this.SaveShow = true;
    }
  }

  onStartDateChanged1(event: IMyDateModel) {
    let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
    this.endDateOptions1 = this.setDateInput(
      this.endDateOptions1,
      event,
      'startDate'
    );
    if (!date) {
      let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(
        this.endDateOptions1
      );
      copyConfig.disableSince = this.getCurrentDate();
      this.startDateOptions1 = copyConfig;
    }
  }

  onEndDateChanged1(event: IMyDateModel) {
    let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
    this.startDateOptions1 = this.setDateInput(
      this.startDateOptions1,
      event,
      'endDate'
    );
    if (!date) {
      let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(
        this.startDateOptions1
      );
      copyConfig.disableUntil = this.getCurrentDate();
      this.startDateOptions1 = copyConfig;
    }
  }
  GetBusiness() {
    this.subscriptions.push(
      this.businessServices.list().subscribe((businessList) => {
        if (businessList) {
          this.businesses = [];
          this.businesses.push({ label: 'Select', value: null });
          businessList.forEach((list: any) => {
            this.businesses.push({
              label: list.CompanyName,
              value: list.Id,
            });
          });
        }
      })
    );
  }

  saveUploaded(event: any) {
    if (event) {
      let eventType = event.eventName;
      let keyBindings = {
        Originalname: 'OriginalFileName',
        FileTypeId: 'FileType',
      };
      let deviationDoc = Object.assign({}, event, this.additionalData);
      switch (eventType) {
        case 'DeleteDefaultFile':
          this.uploadedFiles = this.uploadedFiles.filter(
            (x: any) => x.path !== event.item.data.path
          );
          if (event.item.data.id === 0) {
            this.newDocumentAdded = this.newDocumentAdded.filter(
              (x: any) => x.Path !== event.item.data.path
            );
          } else {
            // remove existing attachement
            this.subscriptions.push(
              this.employeeServices
                .deleteEmployeeDocument(event.item.data.id)
                .subscribe((result: any) => {
                  if (result) {
                    // remove from display list
                    this.uploadedFiles = this.uploadedFiles.filter(
                      (x: any) => x.path !== event.item.data.path
                    );
                    this.toasterComponent.callToastDlt();
                  }
                })
            );
          }
          break;
        default:
          deviationDoc.id = 0;
          deviationDoc.createdBy = BaseServices.UserId;
          deviationDoc.status = 1;
          this.newDocumentAdded.push(
            HelperService.switchKeysCase(deviationDoc, keyBindings)
          );
          break;
      }
    }
  }

  ViewDocument(documents: any) {
    this.viewDocument = true;
    this.uploadedFiles = documents;
  }
  downloadAttachment(courseid) {
    this.subscriptions.push(
      this.employeeCourseServices
        .getEmployeeCourseDocument(courseid)
        .subscribe((result: any) => {
          var attachment = result.EmployeeDocument[0].path;
          if (attachment !== null && attachment !== undefined) {
            if (!Array.isArray(attachment)) {
              attachment = [attachment]; // Wrap single object in an array
            }

            attachment.forEach((x) => {
              this.courseAttachment.push(x.path);
            });
          }

          var attLength = this.courseAttachment.length;
          for (let i = 0; i <= attLength - 1; i++) {
            window.open(this.courseAttachment[i]);
            window.close();
          }
          this.courseAttachment = [];
        })
    );
  }

  addCourseDialog() {
    this.addCourse = true;
    this.courseTypeId = 0;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UploadInput } from 'ngx-uploader';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class FileUploadService {
    private subscription: Subscription;
    public static get headerOption() {
        let headers = new HttpHeaders();
        let options = { headers: headers }
        return options;
    }

    constructor(private http: HttpClient) { }

    uploadBusinessFile(formData:any): UploadInput {
        let uploadInput: UploadInput = {
            type: 'uploadAll',
            url: environment.BASE_URL + '/file/upload/business/manual',
            method: 'POST',
            data: {
                FolderTypeName: formData.folderTypeName,
                ApplicationId: formData.applicationId,
                BusinessId: formData.businessId,
                FeatureId: formData.featureId
            }
        };

        return uploadInput;
    }

    uploadAdminFile(formData) {
        let result: any = this.http
            .post(
                environment.BASE_URL + '/file/upload/application/manual',
                formData
            )
            .map(result => result)
            .catch((error:any) => Observable.throw(error))
            .subscribe(
            );
        this.subscription = result;
        return result;
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}

import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { HelpSupport } from '../models/helpsupport'
import { BaseServices } from './../../kuba.services';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class HelpSupportServices {

    constructor(private http: HttpClient) { }

    createAuthorizationHeader(headers: Headers) {
        headers.append('content-type', 'application/json');
    }
    // List all Help and Support
    list() {
        return this.http.get<any>(environment.BASE_URL + '/helpsupportbybusiness/' + BaseServices.BusinessId, )
            .map(result => result);
    }
    getAllHelpSupportDetails() {
        return this.http.get<any>(environment.BASE_URL
            + '/helpsupportdetails/'
            + BaseServices.ApplicationId
            + '/' + 0,
            )
            .map(result => result);
    }

    adminHelpSupportlist() {
        return this.http.get<any>(environment.BASE_URL
            + '/helpsupportbyadmin/'
            + BaseServices.ApplicationId
            + '/' + 0, )
            .map(result => result);
    }
getByCategoryId(CategoryId: number) {
    return this.http.get(environment.BASE_URL + '/helpsupport/'+ BaseServices.ApplicationId + '/' + CategoryId, )
    .map(result => result);
}
    get(Id: number) {
        return this.http.get<any>(environment.BASE_URL + '/helpsupport/' + Id, )
            .map(result => result);
    }
    add(data:any) {
        return this.http.post(environment.BASE_URL + '/helpsupport', data, )
            .map((response: any) => { return response });
    }

    update(Id: number, data: HelpSupport) {
        return this.http.put<Response>(environment.BASE_URL + '/helpsupport/' + Id, data, )
            .map((response: Response) => { return response });
    }

    delete(Id: number) {
        return this.http.delete<Response>(environment.BASE_URL + '/helpsupportdelete/' + Id, )
            .toPromise()
            .then((response: Response) => { return response });
    }
}

<!-- Business Details-->
<div class="kb-progress-bar" *ngIf="loading">
    <div class="kb-progress"></div>
</div>
<div class="tab-content" id="businessDetailPrint">
    <form [formGroup]="businessDetailForm">
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="category">
                        <span translate>BUSINESS_CATEGORY</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <p-dropdown [filter]="true" formControlName="category" id="category" [options]="vcategories"
                          [style]="{'width':'100%'}" styleClass="p-column-filter"></p-dropdown>
                        <button class="btn btn-outline-primary" type="button" (click)="addCategory = !addCategory">
                            <i class="pi pi-plus" aria-hidden="true"></i>
                        </button>
                    </div>
                    <control-messages [control]="businessDetailForm.controls['category']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="orgNumber">
                        <span translate>ORGANISATION_NUMBER</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" [readonly]="isReadOnly" pInputText (keypress)="onMobileNumber($event,12)"
                          formControlName="orgNumber" id="orgNumber" (keyup)="onOrgNumberChange($event,12)"
                          class="ex-full-width" #organization>
                        <div>
                            <span style="color:green" *ngIf="isNumberExists" translate>ORGANISATION_NUM_AVAILABLE</span>
                        </div>
                        <div>
                            <control-messages [control]="businessDetailForm.controls['orgNumber']"></control-messages>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="companyName">
                        <span translate>COMPANY_NAME</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="companyName" id="companyName" maxlength="100" />
                    </div>
                    <control-messages [control]="businessDetailForm.controls['companyName']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="companyNumber">
                        <span translate>COMPANY_NUMBER</span>
                    </label>
                    <div>
                        <input type="number" pInputText formControlName="companyNumber" id="companyNumber"
                          (keypress)="onMobileNumber($event,12)" />
                    </div>
                    <control-messages [control]="businessDetailForm.controls['companyNumber']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="aboutBusiness">
                        <span translate>ABOUT_BUSINESS</span>
                    </label>
                    <div>
                        <textarea pInputTextarea formControlName="aboutBusiness" id="aboutBusiness"
                          class="ex-description"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="address">
                        <span translate>ADDRESS</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <textarea pInputTextarea formControlName="address" id="address" class="ex-address"
                          maxlength="250"></textarea>
                    </div>
                    <control-messages [control]="businessDetailForm.controls['address']"></control-messages>
                </div>

                <div class="form-group">
                    <label for="zipCode">
                        <span translate>CITY_ZIP</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="zipCode" id="zipCode" />
                    </div>
                    <control-messages [control]="businessDetailForm.controls['zipCode']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="telephone">
                        <span translate>TELEPHONE</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="telePhone" id="telePhone"
                          (keypress)="onMobileNumber($event,20)" />
                    </div>
                    <control-messages [control]="businessDetailForm.controls['telePhone']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="email">
                        <span translate>EMAIL</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="email" id="email" maxlength="100" />
                    </div>
                    <control-messages [control]="businessDetailForm.controls['email']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="status">
                        <span translate>NUM_EMPLOYEES</span>
                    </label>
                    <div>
                        <input type="number" pInputText readonly formControlName="employeeCount" />
                    </div>
                </div>
            </div>
        </div>
        <div class="action-btns-wrapper">
            <div class="row">
                <div class="col-sm-6">
                    <button *ngIf="!isDinHMS || isEditBusiness " class="btn btn-primary" type="submit"
                      [disabled]="!businessDetailForm.valid || loading" (click)="saveChanges()">
                        <span *ngIf="!loading" translate>SAVE</span>
                        <span *ngIf="loading" translate>SAVE_PROCESS</span>
                        <span *ngIf="loading" class="loader-icon"></span>
                    </button>
                    <button *ngIf="!isDinHMS" class="btn btn-primary" type="submit"
                      [disabled]="!businessDetailForm.valid || loading" (click)="saveNextChanges()">
                        <span translate>SAVE_AND_NEXT</span>
                    </button>
                    <button *ngIf="isDinHMS && !isEditBusiness" class="btn btn-primary" type="submit"
                      [disabled]="!businessDetailForm.valid || loading" (click)="saveNextChanges()">
                        <span translate>NEXT</span>
                    </button>
                </div>
                <div class="col-sm-6 text-right" *ngIf="isNewBusiness">
                    <button type="submit" class="btn btn-outline-primary" (click)="exportallPdf()">
                        <span class="icon ic-sm icon-print"></span>
                        <span translate>PRINT_ALL</span>
                    </button>
                    <button type="submit" class="btn btn-outline-primary" (click)="exportPdf()">
                        <span class="icon ic-sm icon-print"></span>
                        <span translate>PRINT_PAGE</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
    <p-dialog header="{{'ADD_CATEGORY' | translate}}" [(visible)]="addCategory" [style]="{width: '530px'}"
      [resizable]="false" [draggable]="false"
      [modal]="true" (onHide)="onBeforeCategoryDialogHide()">
        <form [formGroup]="categoryPopup">
            <div class="form-group">
                <label for="title" class="col-form-label">
                    <span translate>NAME</span>
                    <span class="required">*</span>
                </label>
                <div>
                    <input type="text" class="label-input form-control" formControlName="Name" maxlength="100">
                </div>
                <span style="color:red" *ngIf="isNameExists">{{existsAlerttext}}</span>
            </div>
        </form>
        <ng-template pTemplate="footer">
            <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
                <button type="button" class="btn btn-outline-primary" (click)="viewCategory = !viewCategory">
                    <span translate>VIEW</span>
                </button>
                <button type="button" [disabled]="orgNumberNotValid" class="btn btn-primary"
                  [disabled]="!categoryPopup.valid" (click)="saveCategory()">
                    <span translate>SAVE</span>
                </button>
            </div>
            <div class="table-view mt-3" *ngIf="viewCategory">
                <!-- Table starts -->
                <p-table #categoryTable [value]="editCategories" [rows]="5" [paginator]="true" [pageLinks]="3"
                  [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'CATEGORY_NAME' | translate}}</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text"
                                  (input)="categoryTable.filter($any($event.target)?.value, 'Name', 'contains')"
                                  class="p-column-filter">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-category>
                        <tr>
                            <td>
                                <span>{{category.Name}}</span>
                            </td>
                            <td>
                                <button type="button" class="btn btn-icon" title="{{'EDIT' | translate}}"
                                  (click)="editCategory(category)">
                                    <i class="icon ic-sm icon-pencil-tip"></i>
                                </button>
                                <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}"
                                  (click)="deleteCategory(category.Id)">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="2">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table ends -->
            </div>
        </ng-template>
    </p-dialog>
    <toaster-component></toaster-component>
    <p-dialog header="{{'ALERT'| translate}}" [(visible)]="isAleadyUse" [draggable]="false">
        {{'CATEGORY_IN_USE' | translate }}
        <p-footer>
            <button type="button" class="btn btn-link" title="{{'OK'|translate}}" (click)="informationHide();">
                Ok
            </button>
        </p-footer>
    </p-dialog>

    <p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
      acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
</div>
<div class="main-content">
  <div class="main-heading">
    <span translate>OUR_CLIENT</span>
    <span class="page-actions text-right">
      <a
        *ngIf="isEditor"
        class="btn btn-outline-secondary"
        href="../../assets/template_files/SampleFormat.xlsx"
        download
      >
        <i class="pi pi-question" aria-hidden="true"></i>
      </a>
      <a *ngIf="isEditor" [routerLink]="['./../new']" class="btn btn-success">
        <span class="icon ic-sm icon-add"></span>
        <span translate>NEW</span>
      </a>
    </span>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="row" *ngIf="isEditor">
        <div class="col-sm-3">
          <div class="form-group">
            <label for="DeviceId" class="col-form-label">
              <span translate>IMPORT_CLIENT</span>
            </label>
            <p-fileUpload
              chooseLabel="{{ 'CHOOSE' | translate }}"
              name="file"
              url="{{ documentUploadUrl }}"
              (onUpload)="uploadDocument($event)"
              [auto]="true"
              mode="advanced"
              accept=".xlsx"
              dragDropSupport="true"
              [multiple]="true"
            >
            </p-fileUpload>
          </div>
        </div>
        <div class="col-sm-3" style="margin: 1%" *ngIf="document.name">
          <div
            class="form-group"
            style="margin-top: 5%; background: whitesmoke; padding-bottom: 4%"
          >
            <label for="DeviceId" class="col-form-label"> </label>
            <div class="text-center">
              <i class="pi pi-file" aria-hidden="true"></i>
              <a href="{{ document.uri }}"
                >{{ document.name }}
                <i class="pi pi-download" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          #dt
          [value]="client"
          [rows]="10"
          exportFilename="Client list"
          [paginator]="true"
          dataKey="Id"
          [(selection)]="selectedClient"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
        >
          <!-- Caption -->
          <ng-template pTemplate="caption">
            <div class="ui-helper-clearfix">
              <div class="table-options">
                <div class="pull-right">
                  <kuba-export
                    [reportData]="dt.filteredValue || dt.value"
                    [additionalData]="additionalData"
                  ></kuba-export>
                </div>
              </div>
            </div>
          </ng-template>
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 55px">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th pSortableColumn="Number">
                {{ "NUMBER" | translate }}
                <p-sortIcon field="Number"></p-sortIcon>
              </th>
              <th pSortableColumn="Name">
                {{ "NAME" | translate }} <p-sortIcon field="Name"></p-sortIcon>
              </th>
              <th>{{ "TYPE_OF_CLIENT" | translate }}</th>
              <th pSortableColumn="Mobile">
                {{ "CELLULAR_PHONE" | translate }}
                <p-sortIcon field="Mobile"></p-sortIcon>
              </th>
              <th pSortableColumn="Telephone">
                {{ "TELEPHONE" | translate }}
                <p-sortIcon field="Telephone"></p-sortIcon>
              </th>
              <th pSortableColumn="Email">
                {{ "EMAIL" | translate }}
                <p-sortIcon field="Email"></p-sortIcon>
              </th>
              <th>{{ "STATUS" | translate }}</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    dt.filter($any($event.target)?.value, 'Name', 'contains')
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
              <th>
                <p-dropdown
                  [options]="clientTypes"
                  #clientDropdown
                  (onChange)="onClientChanged($event, clientDropdown)"
                  styleClass="p-column-filter"
                  placeholder="Select"
                  [showClear]="true"
                >
                  <ng-template let-client pTemplate="item">
                    {{ client.label | translate }}
                  </ng-template>
                </p-dropdown>
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                <p-dropdown
                  [options]="statuses"
                  #statusDropdown
                  (onChange)="onStatusChanged($event, statusDropdown)"
                  styleClass="p-column-filter"
                  placeholder="Select"
                  [showClear]="true"
                >
                  <ng-template let-status pTemplate="item">
                    {{ status.label | translate }}
                  </ng-template>
                </p-dropdown>
              </th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-client>
            <tr>
              <td style="width: 55px">
                <p-tableCheckbox id="selectedOrders" [value]="client">
                </p-tableCheckbox>
              </td>
              <td>
                <span>{{ client.Number }}</span>
              </td>
              <td>
                <span>
                  <a href="#" [routerLink]="['../edit/', client['Id']]">{{
                    client.Name
                  }}</a>
                </span>
              </td>
              <td>
                <span>{{ client.ClientType }}</span>
              </td>
              <td>
                <span>{{ client.Mobile }}</span>
              </td>
              <td>
                <span>{{ client.Telephone }}</span>
              </td>
              <td>
                <span>{{ client.Email }}</span>
              </td>
              <td>
                <span>
                  <a
                    (click)="editStatus(client['Id'], client['Status'])"
                    href="javascript:void(0);"
                    routerLinkActive="active"
                    translate
                    >{{ client.StatusText.toUpperCase() }}</a
                  >
                </span>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="8">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
          <!-- Footer -->
          <ng-template pTemplate="summary">
            <div class="ui-helper-clearfix">
              <div class="footer-data">
                <div class="text-left" *ngIf="isEditor">
                  <button class="btn btn-danger" (click)="deleteClient()">
                    <i class="icon ic-sm icon-trash"></i>
                    <span translate>DELETE_CHECKED</span>
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
        </p-table>
        <!-- Table ends -->
      </div>
    </div>
  </div>
</div>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<router-outlet></router-outlet>

import { NotificationSubscription } from 'src/app/shared/employee-contact';

export class FireDeviation {
  Id: number;
  Title: string;
  Description: string;
  ImagePath: string;
  ContactId: number;
  Status: string;
  BusinessId: number;
  CreatedBy: number;
  CreatedOn: Date;
  ModifiedBy: number;
  ModifiedOn: Date;
  FiredeviationIssueTypeId: number;
  Name: string;
  ProjectTemplateId: number;
  CopySja: boolean;
  FireDeviationUserDto: FireDeviationUser[];
  ContactDto: SaveContact;
  ShowDeviation?: boolean;
  FDVEmailSubscribers: NotificationSubscription[];
  IsEmail?: boolean;
  IsSMS?: boolean;
}
export class FireDeviationUser {
  FireDeviationId: number;
  UserId: number;
  Name: string;
}

export class BusinessProperty {
  Id: number;
  BusinessId: number;
  BusinessPropertyTypeId: number;
  Name: string;
  Number: number;
  Description: string;
  FloorCount: number;
  Address: string;
  Status: string;
  City: string;
  Zip: string;
  ContactId: number;
  logopath: string;
  Location: string;
  Latitude: string;
  Longitude: string;
  CreatedBy: number;
  ModifiedBy: number;
  CreatedOn: Date;
  ModifiedOn: Date;
  PropertyObjects: PropertyObjects[];
}
export class PropertyObjects {
  BusinessPropertyId: number;
  BusinessPropertyObjectId: number;
  Quantity: number;
}
export class TypeOfObject {
  Name: string;
  Id: number;
}
export class ChooseObject {
  Name: string;
  Id: number;
}

export class Contact {
  Name: string;
  Id: number;
}
export class SaveContact {
  Id: number;
  Mobile: string;
  Emailid: string;
  IsSMS: boolean;
  IsEmail: boolean;
}
export class PropertyObject {
  Id: number;
  Name: string;
  Quantity: number;
}

export class BusinessPropertyDetail {
  Name: string;
  FloorCount: string;
  ApartmentCount: string;
  Address: string;
  Zip: string;
  Location: string;
  PropertyObjects: PropertyObjectDetail[];
  constructor() {
    this.Name = '';
    this.FloorCount = '';
    this.ApartmentCount = '';
    this.PropertyObjects = [];
  }
}
export class ContactDetail {
  Name: string;
  Mobile: string;
  Emailid: string;
  SmsWhenNewDeviation: boolean;
  EmailWhenNewDeviation: boolean;
  constructor() {
    this.Name = '';
    this.Emailid = '';
    this.Mobile = '';
    this.SmsWhenNewDeviation = false;
    this.EmailWhenNewDeviation = false;
  }
}

export class PropertyObjectDetail {
  Name: string;
  Quantity: number;
  constructor() {
    this.Name = '';
    this.Quantity = 0;
  }
}
export class FdvDetail {
  BusinessProperty: BusinessPropertyDetail;
  ContactDto: ContactDetail;
  PropertyObjects?: PropertyObjectDetail[];
  constructor() {
    this.BusinessProperty = new BusinessPropertyDetail();
    this.ContactDto = new ContactDetail();
  }
}

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

import { BaseServices } from './../../kuba.services';
import { BusinessSetting } from './../models/business-setting-add';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class BusinessSettingService {
  constructor(private http: HttpClient) {}

  createAuthorizationHeader(headers: HttpHeaders) {
    headers.append('content-type', 'application/json');
  }
  getEmployee(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/project/ContactLookup/' + id,

      )
      .map((result) => result);
  }
  getLanguage() {
    return this.http
      .get(
        environment.BASE_URL + '/master/languages',

      )
      .map((result) => result);
  }
  getTheme() {
    return this.http
      .get(environment.BASE_URL + '/master/themes', )
      .map((result) => result);
  }
  addSetting(setting: BusinessSetting) {
    return this.http
      .post(
        environment.BASE_URL + '/business/settings',
        setting,

      )
      .map((result) => result);
  }
  getSettingByBusiness(businessId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/business/settings/' + businessId,

      )
      .map((result) => result);
  }
  getPortalBusiness(businessId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/business/portalbusiness/' + businessId,

      )
      .map((result) => result);
  }
  updateBusinessSetting(setting: BusinessSetting) {
    return this.http
      .put(
        environment.BASE_URL + '/business/settings',
        setting,

      )
      .map((result) => result);
  }
  getPortalContacts(businessId: number) {
    return this.http
      .get<any>(
        environment.BASE_URL + '/portalcontactsbybusiness/' + businessId,

      )
      .map((result) => result);
  }

  getHseAdviser() {
    return this.http
      .get<any>(
        environment.BASE_URL + '/business/HSEAdviser',

      )
      .map((result) => result);
  }

  reportGenerate(
    businessId: number,
    type: number,
    cultureInfo: string,
    ReportJson,
    applicationId: number
  ) {
    let dynamicType = 'application/pdf';
    let myData = JSON.stringify({ data: ReportJson });
    let url = `${environment.BASE_URL}/export-ourcompanydetailslist/${businessId}/${type}/${cultureInfo}/${applicationId}`;
    return Observable.create((observer: any) => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.withCredentials = true;

      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }
}


import { HelperService } from './../../../_services/helper.service';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BusinessServices } from '../../businesses/services/business.services';


@Injectable()
export class ValidationService {
    static getValidatorErrorMessage(
        validatorName: string,
        validatorValue?: any
    ) {
        let config: any = {
            required: 'REQUIRED',
            invalidCreditCard: 'Is invalid credit card number',
            invalidEmailAddress: 'INVALID_EMAIL',
            emailExist:'EMAIL_EXISTING',
            invalidPassword:
                'Invalid password. Password must be at least 6 characters long, and contain a number.',
            minlength: `Minimum length ${validatorValue.requiredLength}`,
            startDateLessThanEqualToEndDate:
                'STARTDATE_ENDDATE',
            startDateAndEndDateLessThanCurrentDate:
                'Start Date And End Date must be less than current Date',
            'Organization number must have 12 characters':
                'Organization number must be 12 characters',
            'invalid number':
                'This number is already existing.Please create other number',
            organizationMaxLength:
                'ORGANISATION_NUM_HAV_12_DIGIT',
            invalidaverage: 'INVALID_AVERAGE',
            Availed_within_a_year: 'LEAVE_WITHIN_YEAR',
            isOrgNumberUnique: 'ORGANISATION_NUM_UNAVAILABLE',
            isSupplierNameUnique: 'Name is available',
            invalidWebSite: 'VALID_WEBSITE',
            invalidNumber: 'INVALID_NUMBER',
            invalidMobileNumber: 'INVALID_MOBILE',
            deadLineDaysLessThanEqualToFollowupDays:
                'NOTIFICATION_LESS',
            multipleCheckboxRequireOne: 'SELECT_ONE_REASON',
            invalidUserName: 'INVALID_EMAIL',
            invalidUserNameWithId : "INVALID_USER_NAME",
            invalidPasWword: 'EXISTING_PASSWORD_INCORRECT',
            passwordMismatch: 'PASSWORD_MISMATCH',
            deadlineValidation:
                'DEADLINE_GREATER_CURRENT',
            notificationValidation:
                'NOTIFICATION_LESS',
            nomatch:
                'REMAINING_GREATER_SELECTED',
            minimumFiveCharacters: 'MINIMUM_FIVE_CHAR',
            isLinkTextUnique: 'UNAVAILABLE',
            isEmailUnique: 'EMAIL_EXISTING',
            isPreviousKmGreaterThanTodaysKm:
                'TODAY_GREATER_PREVIOUS',
            invalidProjectName: 'Project Name already exists',
            notificationdays: 'NOTIFICATION_DAYS',
            break_time: 'BREAK_TIME',
            contractNumberExists: 'CONTRACT_NUMBER_EXISTS',
            clientNumberExists: 'CLIENT_NUMBER_EXISTS',
            whitespace:"REQUIRED",
            clientAndcontractNumberExists:"CLIENT_AND_CONTRACT_NUMBER_EXISTS",
            inspectionNumberExists:"INSPECTION_NUMBER_EXISTS",
        };

        return config[validatorName];
    }

    static websiteValidator(control: any) {
        if (!(control.value === 'http://' || control.value === '')) {
            if (
                control.value.match(
                    /((?:https?\:\/\/|www\.)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)/i
                )
            ) {
                return null;
            } else {
                return { invalidWebSite: true };
            }
        } else {
            return null;
        }
    }
    static numberValidator(control: any) {
        if (control.value === '') {
            return null;
        }
        if (control.value.match(/^(?:[1-9]\d*|\d)$/)) {
            return null;
        } else {
            return { invalidNumber: true };
        }
    }

    static emailValidator(control: any) {
        // RFC 2822 compliant regex
        if (control.value !== null &&
            control.value.match(
               /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
            return null;
        } else {
            return { invalidEmailAddress: true };
        }
    }
    static passwordValidator(control: any) {
        if (control.value === '') {
            return null;
        }
        if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
            return null;
        } else {
            return { invalidPasssword: true };
        }
    }
    static mobileNumberValidator(control: any) {
        if (control.value === '') {
            return null;
        }
        if (control.value.match(/^\d{10}/)) {
            return null;
        } else {
            return { invalidMobileNumber: true };
        }
    }

    static noWhitespaceValidator(control: any) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { whitespace: true };
    }

    static repetetionValidation(control: any) {
        let repetetionZero = (control.value === 0)
        let isValid = !repetetionZero;
        return isValid ? null : { required: true };
    }

    static nonNegativeValidator(control: any) {
        let isNonNegative = control.value < 0;
        let isValid = !isNonNegative;
        return isValid ? null : { nonnegative: true };
    }

    static startDateLessThanEqualToEndDate(group: FormGroup) {
        let valid = true;
        if (
            !group.controls['StartDate'].value ||
            !group.controls['EndDate'].value
        ) {
            return null;
        }
        if (
            group.controls['StartDate'].value &&
            group.controls['EndDate'].value
        ) {
            let startDate: Date = new Date(group.controls['StartDate'].value);
            let endDate: Date = new Date(group.controls['EndDate'].value);
            if (startDate && endDate) {
                startDate = new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDay(),
                    0,
                    0,
                    0,
                    0
                );
                endDate = new Date(
                    endDate.getFullYear(),
                    endDate.getMonth(),
                    endDate.getDay(),
                    0,
                    0,
                    0,
                    0
                );
                valid =
                    new Date(group.controls['StartDate'].value.jsdate) <=
                    new Date(group.controls['EndDate'].value.jsdate);
            }
        }
        if (valid) {
            return null;
        }
        return {
            startDateLessThanEqualToEndDate: true
        };
    }

    static deadLineDaysLessThanEqualToFollowupDays(group: FormGroup) {
        let valid = true;

        if (
            group.controls['Notification'].value &&
            group.controls['FollowupNotification'].value
        ) {
            valid =
                group.controls['FollowupNotification'].value >=
                group.controls['Notification'].value;
        }
        if (valid) {
            return null;
        }
        return {
            deadLineDaysLessThanEqualToFollowupDays: true
        };

    }
    static startDateLessThanCurrentDate(control: any) {
        let valid = true;
        let today: Date = new Date();
        let currentdate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            0,
            0,
            0,
            0
        );
        if (control.value) {
            let controlValue = control.value;
            if (controlValue) {
                let startDate = new Date(controlValue);
                startDate = new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate(),
                    0,
                    0,
                    0,
                    0
                );
                valid = startDate < currentdate;
            }
        }
        if (valid) {
            return null;
        }
        return { startDateLessThanCurrentDate: true };
    }

    static startDateAndEndDateLessThanCurrentDate(group: FormGroup) {
        let valid = true;
        if (
            group.controls['StartDate'].value &&
            group.controls['EndDate'].value
        ) {
            let startDate: Date = new Date(group.controls['StartDate'].value);
            let endDate: Date = new Date(group.controls['EndDate'].value);
            if (startDate && endDate) {
                startDate = new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDay(),
                    0,
                    0,
                    0,
                    0
                );
                endDate = new Date(
                    endDate.getFullYear(),
                    endDate.getMonth(),
                    endDate.getDay(),
                    0,
                    0,
                    0,
                    0
                );
                valid = startDate < new Date() && endDate < new Date();
            }
        }
        if (valid) {
            return null;
        }
        return {
            startDateAndEndDateLessThanCurrentDate: true
        };
    }

    /**
     * Start Date less than or equal to End Date
     * @param group date object
     */
    static startDateLessEqualEndDate(group: FormGroup) {
        let valid = true;
        if (
            group.controls['StartDate'].value &&
            group.controls['EndDate'].value
        ) {
            let startDate = group.controls['StartDate'].value;
            let endDate = group.controls['EndDate'].value;
            if (startDate && endDate) {
                valid = startDate <= endDate;
            }
        }
        if (valid) {
            return null;
        }
        return {
            startDateLessThanEqualToEndDate: true
        };
    }

    static multipleCheckboxRequireOne(fa: FormArray) {
        let valid = false;

        for (let x = 0; x < fa.length; ++x) {
            if (fa.at(x).value) {
                valid = true;
                break;
            }
        }
        return valid
            ? null
            : {
                multipleCheckboxRequireOne: true
            };
    }

    static sicknessReasonValidation(group: FormArray) {
        if (group.controls['checkboxProperties'].value) {
        }
    }

    /**
     * Start Date less than or equal to End Date
     * @param group date object
     */
    static startDateEndDateYearCheck(group: FormGroup) {
        let valid = true;
        if (
            group.controls['StartDate'].value &&
            group.controls['EndDate'].value
        ) {
            let startDate = group.controls['StartDate'].value.date.year;
            let endDate = group.controls['EndDate'].value.date.year;
            if (startDate && endDate) {
                valid = startDate === endDate;
            }
        }
        if (valid) {
            return null;
        }
        return {
            Availed_within_a_year: true
        };
    }

    static checkOrganizationNumber(control: any) {
        if (('' + control.value).length < 9) {
            let organizationNumber : any =  { organizationMaxLength: true };
            return organizationNumber;
        }
    }

    static checkAverageTemperature(control: any) {
        if (((control.value) < -80) || ((control.value) > 300)) {
            let avgTemp: any = { invalidaverage: true };
            return avgTemp;
        }
    }
    static minimunFiveCharacters(control: any) {
        if (control.value) {
            if (('' + control.value).length < 5) {
                let fiveChar: any = { minimumFiveCharacters: true }
                return fiveChar;
            }
        }
    }
    static matchPassword(control: any) {
        let password = control.get('Password').value; // to get value in input tag
        let confirmPassword = control.get('ConfirmPassword').value; // to get value in input tag
        if (
            password !== '' &&
            confirmPassword !== '' &&
            password !== confirmPassword
        ) {
            return { passwordMismatch: true };
        } else {
            return null;
        }
    }

    static deadlineValidation(control: FormControl) {
        let valid = true;
        if (control.value) {
            let currentDate: Date = new Date();
            let deadlineDate: Date = new Date(control.value);
            if (currentDate && deadlineDate) {
                currentDate = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate(),
                    0,
                    0,
                    0,
                    0
                );
                deadlineDate = new Date(
                    deadlineDate.getFullYear(),
                    deadlineDate.getMonth(),
                    deadlineDate.getDate(),
                    0,
                    0,
                    0,
                    0
                );
                valid = deadlineDate >= currentDate;
            }
        }
        if (valid) {
            return null;
        }
        control.markAsTouched();
        return {
            deadlineValidation: true
        };
    }


    static kilometerValidator = (control:any): { [key: string]: boolean } => {
        const previousKm = control.get('PreviousKM');
        const todaysKm = control.get('TodaysKM');
        if (!previousKm || !todaysKm) {
            let pkm :any = null;
            return pkm;
        }
        let km : any = +todaysKm.value > +previousKm.value
        ? null
        : { isPreviousKmGreaterThanTodaysKm: true };
        return km;
    };

    static nonZero(control:any): { [key: string]: any } {
        if (Number(control.value) <= 0) {
            return { nonZero: true };
        } else {
            let nonZero : any = null;
            return nonZero;
        }
    }

    static rangeValidator = (control:any): { [key: string]: boolean } => {
        const remainingQuantity = control.get('RemainingQuantity');
        const selectedQuantity = control.get('SelectedQuantity');
        if (!remainingQuantity || !selectedQuantity) {
            let range : any =null;
            return range;
        }
        let rangeValidator : any = +selectedQuantity.value <= remainingQuantity.value &&
        +selectedQuantity.value > 0
        ? null
        : { nomatch: true };
        return rangeValidator;
    };

    getOrganizationNumber(number: any) {
        let data: any;
        this.http
            .get(
                environment.BASE_URL + '/checkOrgNumber/' + number,
                BaseServices.headerOption
            ).pipe(map(result => data));
        if (data === false) {
            return null;
        } else {
            return { 'invalid number': true };
        }
    }


    constructor(public http: HttpClient, 
        public businessService: BusinessServices
        ) { }
}

import {
  Component,
  OnInit,
  Input,
  Output,
  Renderer2,
  OnDestroy,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ToastContainerModule } from 'ngx-toastr';
import { KubaArticleViewerService } from './ecpl-article-viewer.service';
import { environment } from 'src/environments/environment';
import { SearchListPipe } from './../_helpers/pipes';
import { BaseServices } from '../../kuba/kuba.services';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { ThemeService } from '../../theme.service';

@Component({
  selector: 'image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.css'],
})
export class ImageUploaderComponent implements OnInit {
  folderList: SelectItem[];
  uploadStart: boolean;
  folderId: number;
  display = false;
  newFolderName = '';
  applicationId: number;
  portalId: number;
  businessId: number;
  userId: number;
  imageList: any[];
  SelectImage: any;
  selectedImageName = '';
  displayImage = false;
  uploadStartForCoverImage = false;
  filterText = '';
  assetloc: string = environment.BASE_STYLES_PATH;
  public selectedFiles;
  private subscriptions: Subscription[] = [];
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private http: HttpClient,
    private themeService: ThemeService,
    private KubaArticleViewerService: KubaArticleViewerService
  ) {}
  ngOnInit() {
    this.applicationId = +this.route.snapshot.queryParams['applicationId'];
    this.portalId = +this.route.snapshot.queryParams['portalId'];
    this.businessId = +this.route.snapshot.queryParams['businessId'];
    this.userId = +this.route.snapshot.queryParams['userId'];
    if (this.portalId !== 0) {
      this.businessId = 0;
    }
    this.bindFolder();
    this.resetTheme();
  }

  resetTheme(): void {
    if (BaseServices.userTheme && BaseServices.userTheme.Stylepath) {
      const themePath = this.assetloc + BaseServices.userTheme.Stylepath;
      const themeId = BaseServices.userTheme.Id;
      this.themeService.setTheme(themeId);
      this.themeService.applyTheme();
    }
  }

  bindFormDropdowns() {}

  upload() {
    window
      .top!.opener.CKEDITOR.dialog.getCurrent()
      .setValueOf('info', 'txtUrl', encodeURI(this.SelectImage.Path));
    window.top!.close();
    window.top!.opener.focus();
  }
  /**
   * Need to be implement
   */
  saveChanges() {}
  saveAndCreateNew() {
    this.newFolderName = '';
    this.display = true;
  }

  bindFolder() {
    this.folderList = [];
    this.folderList.push({ label: 'Select', value: 0 });
    if (this.applicationId) {
      this.subscriptions.push(
        this.KubaArticleViewerService.getCKEditorImageFolderList(
          this.applicationId,
          this.portalId,
          this.businessId
        ).subscribe((result: any) => {
          if (result) {
            result.forEach((element: any) => {
              if (element) {
                this.folderList.push({
                  label: element.FileName,
                  value: element.Id,
                });
              }
            });
          }
        })
      );
    }
  }

  NewFolder() {
    let FolderDetails: any = {
      Id: null,
      ApplicationId: this.applicationId,
      PortalId: this.portalId,
      BusinessId: this.businessId,
      IsDefault: false,
      ParentFolderId: 0,
      FileName: this.newFolderName,
      Size: null,
      Path: null,
      Status: 1,
      CreatedBy: +this.userId,
      CreatedOn: new Date(),
      MofidiedOn: null,
    };

    this.subscriptions.push(
      this.KubaArticleViewerService.SaveCKEditorImageFolder(
        FolderDetails
      ).subscribe((result) => {
        if (result) {
          alert('Successfully saved');
          this.bindFolder();
          this.display = false;
        }
      })
    );
  }

  BindImageList() {
    this.selectedImageName = '';
    this.SelectImage = {};
    this.imageList = [];
    this.subscriptions.push(
      this.KubaArticleViewerService.getCKEditorImageList(
        this.applicationId,
        this.businessId,
        this.portalId,
        this.folderId
      ).subscribe((result: any) => {
        if (result) {
          this.imageList = result;
        }
      })
    );
  }

  PreviewImage(event: any) {
    this.SelectImage = event;
    this.selectedImageName = event.FileName;
  }

  ShowRename(event: any) {
    this.displayImage = true;
    this.SelectImage = event;
    this.selectedImageName = event.FileName;
  }
  RenameImage() {
    this.SelectImage.FileName = this.selectedImageName;
    this.SelectImage.CreatedBy = this.userId;
    this.subscriptions.push(
      this.KubaArticleViewerService.RenameCKEditorImage(
        this.SelectImage
      ).subscribe((result) => {
        if (result) {
          alert('Successfully saved');
          this.BindImageList();
          this.displayImage = false;
        }
      })
    );
  }

  Test() {}

  DeleteImageFile() {
    if (confirm('Do you want to delete the selected file ?')) {
      this.SelectImage.CreatedBy = this.userId;
      this.subscriptions.push(
        this.KubaArticleViewerService.DeleteCKEditorImage(
          this.SelectImage
        ).subscribe((result) => {
          if (result) {
            alert('Successfully deleted');
            this.BindImageList();
          }
        })
      );
    }
  }

  DeleteImageFolder() {
    if (confirm('Do you want to delete the selected folder ?')) {
      this.subscriptions.push(
        this.KubaArticleViewerService.DeleteCKEditorImageFolder(
          this.folderId,
          this.userId
        ).subscribe((result) => {
          if (result) {
            alert('Successfully deleted');
            this.bindFolder();
            this.display = false;
          }
        })
      );
    }
  }

  filesSelectForCoverImage(selectedFiles: any) {
    this.uploadStartForCoverImage = true;
    this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(
      (file) => file
    );
    let fileName = this.selectedFiles[0].name;
    let fileSize = this.selectedFiles[0].size;
    let formData = new FormData();
    formData.append('file', this.selectedFiles[0]);

    this.subscriptions.push(
      this.http
        .post(
          environment.BASE_URL +
            '/file/upload/manual/coverImage/' +
            this.applicationId +
            '/' +
            this.businessId,
          formData
        )
        .pipe(
          map((response: any) => {
            this.uploadStartForCoverImage = false;
            let res = response;

            let imageFile = {
              Id: null,
              ApplicationId: this.applicationId,
              PortalId: this.portalId,
              BusinessId: this.businessId,
              IsDefault: false,
              ParentFolderId: this.folderId,
              FileName: fileName,
              Size: fileSize,
              Path: res.path,
              Status: 1,
              CreatedBy: this.userId,
              CreatedOn: new Date(),
              ModifiedBy: null,
              ModifiedOn: null,
            };

            this.subscriptions.push(
              this.KubaArticleViewerService.SaveCKEditorImage(
                imageFile
              ).subscribe((result) => {
                if (result) {
                  alert('Image successfully uploaded');
                  this.BindImageList();
                  this.displayImage = false;
                }
              })
            );
          })
        )
        .subscribe(
          (result) => {
          },
          (error) => console.log('Upload manual cover image Error : ', error),
          () => console.log('Upload manual cover image Complete')
        )
    );
  }

  DeleteFolderValidation() {
    if (this.folderId && this.folderId !== undefined) {
      return false;
    } else {
      return true;
    }
  }

  SelectedImageValidation() {
    if (this.selectedImageName !== '') {
      return false;
    } else {
      return true;
    }
  }

  Cancle() {
    window
      .top!.opener.CKEDITOR.dialog.getCurrent()
      .setValueOf('info', 'txtUrl', encodeURI(''));
    window.top!.close();
    window.top!.opener.focus();
  }
  CloseFolderDialogBox() {
    this.display = false;
  }
  CloseRenameDialogBox() {
    this.displayImage = false;
  }
  ValidateRenameText() {
    if (this.selectedImageName !== '') {
      return false;
    } else {
      return true;
    }
  }
  ValidateAddFolderText() {
    if (this.newFolderName !== '') {
      return false;
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}

<div class="app-sidemenu">
  <div class="shimmer-overlay" *ngIf="placeholder">
    <div class="smr-container">
      <div class="smr-content-sidebar">
        <span class="smr-menu-item-top smr-mask"></span>
        <span class="smr-menu-item">
          <span class="smr-b-mask"
            ><svg viewBox="0 0 10 10" width="24" height="24" class="smr-mask">
              <path
                d="M0,0 L10,0 L10,10 L0,10 L0,0 Z M0,5 C0,7.76142375 2.23857625,10 5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,2.22044605e-16 5,0 C2.23857625,-2.22044605e-16 0,2.23857625 0,5 L0,5 Z"
              ></path></svg
          ></span>
          <span class="smr-b-mask"
            ><svg width="3" height="3" class="smr-mask smr-mask-tl">
              <path d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-tr">
              <path d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-br">
              <path d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-bl">
              <path d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"></path></svg
          ></span>
          <span class="smr-mask"></span>
        </span>
        <span class="smr-menu-item">
          <span class="smr-b-mask"
            ><svg viewBox="0 0 10 10" width="24" height="24" class="smr-mask">
              <path
                d="M0,0 L10,0 L10,10 L0,10 L0,0 Z M0,5 C0,7.76142375 2.23857625,10 5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,2.22044605e-16 5,0 C2.23857625,-2.22044605e-16 0,2.23857625 0,5 L0,5 Z"
              ></path></svg
          ></span>
          <span class="smr-b-mask"
            ><svg width="3" height="3" class="smr-mask smr-mask-tl">
              <path d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-tr">
              <path d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-br">
              <path d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-bl">
              <path d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"></path></svg
          ></span>
          <span class="smr-mask"></span>
        </span>
        <span class="smr-menu-item">
          <span class="smr-b-mask"
            ><svg viewBox="0 0 10 10" width="24" height="24" class="smr-mask">
              <path
                d="M0,0 L10,0 L10,10 L0,10 L0,0 Z M0,5 C0,7.76142375 2.23857625,10 5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,2.22044605e-16 5,0 C2.23857625,-2.22044605e-16 0,2.23857625 0,5 L0,5 Z"
              ></path></svg
          ></span>
          <span class="smr-b-mask"
            ><svg width="3" height="3" class="smr-mask smr-mask-tl">
              <path d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-tr">
              <path d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-br">
              <path d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-bl">
              <path d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"></path></svg
          ></span>
          <span class="smr-mask"></span>
        </span>
        <span class="smr-menu-item">
          <span class="smr-b-mask"
            ><svg viewBox="0 0 10 10" width="24" height="24" class="smr-mask">
              <path
                d="M0,0 L10,0 L10,10 L0,10 L0,0 Z M0,5 C0,7.76142375 2.23857625,10 5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,2.22044605e-16 5,0 C2.23857625,-2.22044605e-16 0,2.23857625 0,5 L0,5 Z"
              ></path></svg
          ></span>
          <span class="smr-b-mask"
            ><svg width="3" height="3" class="smr-mask smr-mask-tl">
              <path d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-tr">
              <path d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-br">
              <path d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-bl">
              <path d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"></path></svg
          ></span>
          <span class="smr-mask"></span>
        </span>
        <span class="smr-menu-item">
          <span class="smr-b-mask"
            ><svg viewBox="0 0 10 10" width="24" height="24" class="smr-mask">
              <path
                d="M0,0 L10,0 L10,10 L0,10 L0,0 Z M0,5 C0,7.76142375 2.23857625,10 5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,2.22044605e-16 5,0 C2.23857625,-2.22044605e-16 0,2.23857625 0,5 L0,5 Z"
              ></path></svg
          ></span>
          <span class="smr-b-mask"
            ><svg width="3" height="3" class="smr-mask smr-mask-tl">
              <path d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-tr">
              <path d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-br">
              <path d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-bl">
              <path d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"></path></svg
          ></span>
          <span class="smr-mask"></span>
        </span>
        <span class="smr-menu-item">
          <span class="smr-b-mask"
            ><svg viewBox="0 0 10 10" width="24" height="24" class="smr-mask">
              <path
                d="M0,0 L10,0 L10,10 L0,10 L0,0 Z M0,5 C0,7.76142375 2.23857625,10 5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,2.22044605e-16 5,0 C2.23857625,-2.22044605e-16 0,2.23857625 0,5 L0,5 Z"
              ></path></svg
          ></span>
          <span class="smr-b-mask"
            ><svg width="3" height="3" class="smr-mask smr-mask-tl">
              <path d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-tr">
              <path d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-br">
              <path d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-bl">
              <path d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"></path></svg
          ></span>
          <span class="smr-mask"></span>
        </span>
        <span class="smr-menu-item">
          <span class="smr-b-mask"
            ><svg viewBox="0 0 10 10" width="24" height="24" class="smr-mask">
              <path
                d="M0,0 L10,0 L10,10 L0,10 L0,0 Z M0,5 C0,7.76142375 2.23857625,10 5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,2.22044605e-16 5,0 C2.23857625,-2.22044605e-16 0,2.23857625 0,5 L0,5 Z"
              ></path></svg
          ></span>
          <span class="smr-b-mask"
            ><svg width="3" height="3" class="smr-mask smr-mask-tl">
              <path d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-tr">
              <path d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-br">
              <path d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-bl">
              <path d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"></path></svg
          ></span>
          <span class="smr-mask"></span>
        </span>
        <span class="smr-menu-item">
          <span class="smr-b-mask"
            ><svg viewBox="0 0 10 10" width="24" height="24" class="smr-mask">
              <path
                d="M0,0 L10,0 L10,10 L0,10 L0,0 Z M0,5 C0,7.76142375 2.23857625,10 5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,2.22044605e-16 5,0 C2.23857625,-2.22044605e-16 0,2.23857625 0,5 L0,5 Z"
              ></path></svg
          ></span>
          <span class="smr-b-mask"
            ><svg width="3" height="3" class="smr-mask smr-mask-tl">
              <path d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-tr">
              <path d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-br">
              <path d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-bl">
              <path d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"></path></svg
          ></span>
          <span class="smr-mask"></span>
        </span>
        <span class="smr-menu-item">
          <span class="smr-b-mask"
            ><svg viewBox="0 0 10 10" width="24" height="24" class="smr-mask">
              <path
                d="M0,0 L10,0 L10,10 L0,10 L0,0 Z M0,5 C0,7.76142375 2.23857625,10 5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,2.22044605e-16 5,0 C2.23857625,-2.22044605e-16 0,2.23857625 0,5 L0,5 Z"
              ></path></svg
          ></span>
          <span class="smr-b-mask"
            ><svg width="3" height="3" class="smr-mask smr-mask-tl">
              <path d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-tr">
              <path d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-br">
              <path d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-bl">
              <path d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"></path></svg
          ></span>
          <span class="smr-mask"></span>
        </span>
        <span class="smr-menu-item">
          <span class="smr-b-mask"
            ><svg viewBox="0 0 10 10" width="24" height="24" class="smr-mask">
              <path
                d="M0,0 L10,0 L10,10 L0,10 L0,0 Z M0,5 C0,7.76142375 2.23857625,10 5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,2.22044605e-16 5,0 C2.23857625,-2.22044605e-16 0,2.23857625 0,5 L0,5 Z"
              ></path></svg
          ></span>
          <span class="smr-b-mask"
            ><svg width="3" height="3" class="smr-mask smr-mask-tl">
              <path d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-tr">
              <path d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-br">
              <path d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-bl">
              <path d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"></path></svg
          ></span>
          <span class="smr-mask"></span>
        </span>
        <span class="smr-menu-item">
          <span class="smr-b-mask"
            ><svg viewBox="0 0 10 10" width="24" height="24" class="smr-mask">
              <path
                d="M0,0 L10,0 L10,10 L0,10 L0,0 Z M0,5 C0,7.76142375 2.23857625,10 5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,2.22044605e-16 5,0 C2.23857625,-2.22044605e-16 0,2.23857625 0,5 L0,5 Z"
              ></path></svg
          ></span>
          <span class="smr-b-mask"
            ><svg width="3" height="3" class="smr-mask smr-mask-tl">
              <path d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-tr">
              <path d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-br">
              <path d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-bl">
              <path d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"></path></svg
          ></span>
          <span class="smr-mask"></span>
        </span>
        <span class="smr-menu-item">
          <span class="smr-b-mask"
            ><svg viewBox="0 0 10 10" width="24" height="24" class="smr-mask">
              <path
                d="M0,0 L10,0 L10,10 L0,10 L0,0 Z M0,5 C0,7.76142375 2.23857625,10 5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,2.22044605e-16 5,0 C2.23857625,-2.22044605e-16 0,2.23857625 0,5 L0,5 Z"
              ></path></svg
          ></span>
          <span class="smr-b-mask"
            ><svg width="3" height="3" class="smr-mask smr-mask-tl">
              <path d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-tr">
              <path d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-br">
              <path d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-bl">
              <path d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"></path></svg
          ></span>
          <span class="smr-mask"></span>
        </span>
        <span class="smr-menu-item">
          <span class="smr-b-mask"
            ><svg viewBox="0 0 10 10" width="24" height="24" class="smr-mask">
              <path
                d="M0,0 L10,0 L10,10 L0,10 L0,0 Z M0,5 C0,7.76142375 2.23857625,10 5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,2.22044605e-16 5,0 C2.23857625,-2.22044605e-16 0,2.23857625 0,5 L0,5 Z"
              ></path></svg
          ></span>
          <span class="smr-b-mask"
            ><svg width="3" height="3" class="smr-mask smr-mask-tl">
              <path d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-tr">
              <path d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-br">
              <path d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-bl">
              <path d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"></path></svg
          ></span>
          <span class="smr-mask"></span>
        </span>
        <span class="smr-menu-item">
          <span class="smr-b-mask"
            ><svg viewBox="0 0 10 10" width="24" height="24" class="smr-mask">
              <path
                d="M0,0 L10,0 L10,10 L0,10 L0,0 Z M0,5 C0,7.76142375 2.23857625,10 5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,2.22044605e-16 5,0 C2.23857625,-2.22044605e-16 0,2.23857625 0,5 L0,5 Z"
              ></path></svg
          ></span>
          <span class="smr-b-mask"
            ><svg width="3" height="3" class="smr-mask smr-mask-tl">
              <path d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-tr">
              <path d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-br">
              <path d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-bl">
              <path d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"></path></svg
          ></span>
          <span class="smr-mask"></span>
        </span>
        <span class="smr-menu-item">
          <span class="smr-b-mask"
            ><svg viewBox="0 0 10 10" width="24" height="24" class="smr-mask">
              <path
                d="M0,0 L10,0 L10,10 L0,10 L0,0 Z M0,5 C0,7.76142375 2.23857625,10 5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,2.22044605e-16 5,0 C2.23857625,-2.22044605e-16 0,2.23857625 0,5 L0,5 Z"
              ></path></svg
          ></span>
          <span class="smr-b-mask"
            ><svg width="3" height="3" class="smr-mask smr-mask-tl">
              <path d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-tr">
              <path d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-br">
              <path d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-bl">
              <path d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"></path></svg
          ></span>
          <span class="smr-mask"></span>
        </span>
        <span class="smr-menu-item">
          <span class="smr-b-mask"
            ><svg viewBox="0 0 10 10" width="24" height="24" class="smr-mask">
              <path
                d="M0,0 L10,0 L10,10 L0,10 L0,0 Z M0,5 C0,7.76142375 2.23857625,10 5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,2.22044605e-16 5,0 C2.23857625,-2.22044605e-16 0,2.23857625 0,5 L0,5 Z"
              ></path></svg
          ></span>
          <span class="smr-b-mask"
            ><svg width="3" height="3" class="smr-mask smr-mask-tl">
              <path d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-tr">
              <path d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-br">
              <path d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-bl">
              <path d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"></path></svg
          ></span>
          <span class="smr-mask"></span>
        </span>
        <span class="smr-menu-item">
          <span class="smr-b-mask"
            ><svg viewBox="0 0 10 10" width="24" height="24" class="smr-mask">
              <path
                d="M0,0 L10,0 L10,10 L0,10 L0,0 Z M0,5 C0,7.76142375 2.23857625,10 5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,2.22044605e-16 5,0 C2.23857625,-2.22044605e-16 0,2.23857625 0,5 L0,5 Z"
              ></path></svg
          ></span>
          <span class="smr-b-mask"
            ><svg width="3" height="3" class="smr-mask smr-mask-tl">
              <path d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-tr">
              <path d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-br">
              <path d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-bl">
              <path d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"></path></svg
          ></span>
          <span class="smr-mask"></span>
        </span>
        <span class="smr-menu-item">
          <span class="smr-b-mask"
            ><svg viewBox="0 0 10 10" width="24" height="24" class="smr-mask">
              <path
                d="M0,0 L10,0 L10,10 L0,10 L0,0 Z M0,5 C0,7.76142375 2.23857625,10 5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,2.22044605e-16 5,0 C2.23857625,-2.22044605e-16 0,2.23857625 0,5 L0,5 Z"
              ></path></svg
          ></span>
          <span class="smr-b-mask"
            ><svg width="3" height="3" class="smr-mask smr-mask-tl">
              <path d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-tr">
              <path d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-br">
              <path d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"></path></svg
            ><svg width="3" height="3" class="smr-mask smr-mask-bl">
              <path d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"></path></svg
          ></span>
          <span class="smr-mask"></span>
        </span>
        <span class="smr-menu-item-bottom smr-mask"></span>
      </div>
    </div>
  </div>
  <!--Coloun left (Navigation, links widgets)-->
  <div class="sidemenu-wrapper" *ngIf="features">
    <div class="sidemenu-links">
      <span *ngFor="let feature of features">
        <div
          id="list-container"
          class="link-item"
          [routerLinkActive]="['active']"
        >
          <a
            id="anchor-container"
            routerLink="{{ urlBase }}/{{ feature.linkText }}"
            (click)="setCurrentModule(feature)"
            pTooltip="{{ feature.translationKey | translate }}"
            tooltipPosition="right"
          >
            <span
              class="group link-item-icon icon ic-md icon-{{
                feature.key | lowercase
              }}"
            ></span>
            <span id="anchor-item-text" class="group link-item-txt">{{
              feature.translationKey | translate
            }}</span>
          </a>
        </div>
      </span>
    </div>
  </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  InspectionTaskStatus,
  InspectionStatus,
  ElectroInstallatioForms,
} from '../../models/electrofeatures';
import {
  ElectroInspection,
  InspectionExecution,
  InspectionFollowup,
  ElectroInspectionTasks,
} from '../../models/electroinspection';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ElectroService } from '../../services/electro.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { HelperService } from 'src/app/_services/helper.service';
import { IMyDpOptions } from 'mydatepicker';
import { DatePipe, Location } from '@angular/common';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { environment } from 'src/environments/environment';
import { Rights, FeatureKey, Roles } from 'src/app/_models';
import { CustomValidator } from '../shared/custom-validator';
import { ElectroContractContent } from '../../models/electro-contract-content.models';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ElectroDocumentService } from '../../services/electro-document.services';
import { ElectroInstallationService } from '../../services/electro-installation.services';
import { CourseOverviewComponent } from '../installation/course-overview.component';
import { DistributionOverviewComponent } from '../installation/distribution-overview.component';
import { DocumentAssessmentComponent } from '../installation/document-assessment.component';
import { EmergencyLightingComponent } from '../installation/emergency-lighting.component';
import { FilreAlarmComponent } from '../installation/fire-alarm-system.component';
import { RiskAssessmentComponent } from '../installation/risk-assessment.component';
import { ElectroCompleteChecklistEdit } from '../../models/electrochecklistcompletion';
import { ElectroCourseOverview } from '../../models/electroinstallation';
import { Table } from 'primeng/table';
import {
  CompleteChecklistStatus,
  CompleteChecklistItemSection,
} from 'src/app/kuba/shared/models/complete-checklist';
import { CompleteCheckListServices } from '../../../shared/services/complete-checklist.service';
import { ElectroDeviation } from '../../models/electrodeviation';
@Component({
  selector: 'inspection-form',
  templateUrl: 'inspection-form.component.html',
})
export class InspectionFormComponent implements OnInit {
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  @ViewChild('dt1', { static: false }) dt1: Table;

  @ViewChild('course', { static: false }) course: CourseOverviewComponent;
  @ViewChild('distribution', { static: false })
  distribution: DistributionOverviewComponent;
  @ViewChild('documentAssessment', { static: false })
  documentAssessment: DocumentAssessmentComponent;
  @ViewChild('emergencyLighting', { static: false })
  emergencyLighting: EmergencyLightingComponent;
  @ViewChild('filreAlarm', { static: false }) filreAlarm: FilreAlarmComponent;
  @ViewChild('riskAssessment', { static: false })
  riskAssessment: RiskAssessmentComponent;

  isEditMode: boolean;
  contractId: number;
  inspectionId: number;
  inspection: ElectroInspection;
  inspectionForm: FormGroup;
  status: any;
  taskStatus: any;
  followupPersonsForSelInspection: any;
  followupPersons: any;
  followup: any;
  execution: any;
  enabledTasks: any;
  tasks: any;
  contractTasks: any[];
  inspectionTasks: any[];
  followUpDays: any;
  repetition: any;
  isTasksCompleted: boolean;
  enabledChecklists: any;
  selectedChecklist: any;
  additionalData: any;
  newDocumentAdded = [];
  uploadedFiles = [];
  upConfig: UploaderConfig;
  CompleteChecklistEdit: ElectroCompleteChecklistEdit;
  isEditorOrLeader: boolean;
  parentId: number;
  date: any;
  deviation = new ElectroDeviation();
  addFileCheckpoint: number;
  addFileHeadpoint: number;
  editMode = false;
  treeNode: any;
  newInspection = false;
  deadLinePastStartMsg = false;
  public dateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    height: '32px',
    selectionTxtFontSize: '14px',
    disableUntil: this.getCurrentDate(),
    inline: false,
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
  };
  public dateOptionsforDeadLine: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    height: '32px',
    selectionTxtFontSize: '14px',
    disableUntil: this.getCurrentDate(),
    inline: false,
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
  };
  treeData: any;
  currentDate: Date;
  currentDeadLine: Date;
  dateDiff: number;
  showAddTaskDialog: boolean;
  showAddSingleTaskDialog: boolean;
  selectedContractTasks: any = [];
  selectedInspectionTasks: any = [];
  allSelectedTasks: any[] = [];
  addServiceForm: FormGroup;
  singleTask = new ElectroContractContent();
  inspectionCreatedOn: string;
  inspectionCreatedBy: number;
  isSelfInspection: boolean;
  showLoader: boolean;
  intervalKey = [
    { Key: 'NONE', Value: 1 },
    { Key: 'EVERY_MONTH', Value: 2 },
    { Key: 'EVERY_3_MONTH', Value: 3 },
    { Key: 'EVERY_6_MONTH', Value: 4 },
    { Key: 'ANNUALY', Value: 5 },
    { Key: 'EVERY_OTHER_YEAR', Value: 6 },
    { Key: 'EVERY_THIRD_YEAR', Value: 7 },
    { Key: 'EVERY_FIFTH_YEAR', Value: 7 },
  ];
  isContractFollowupTab = false;
  isShowNotifyPopup = false;
  IsChangeNotify = false;
  IsExecutionChangeNotify = false;
  IsFollowupChangeNotify = false;
  navOnSaveClick: string;
  enableForm: string;
  previewChecklist: boolean;
  previewHeader = 'PREVIEW';
  clientContactInfo: any;
  inspectionNoPrefix: string;
  contrcatNo: string;
  dispInspectionNo: string;
  isEndControl = true;
  ShowEndControlConfirm: boolean;
  formData: any;
  isDisableAllFields: boolean;
  clientNo: string;
  cachedInspNum: any;
  hideStartDate: boolean;
  isClientUserRestrict: boolean;
  statusDisabled: boolean = true;
  isDisableStatusField: boolean = false;
  private subscriptions: Subscription[] = [];
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private confirmationService: ConfirmationService,
    private CompleteCheckListServices: CompleteCheckListServices,
    private electroServices: ElectroService,
    private electroDocumentServices: ElectroDocumentService,
    private electroInstallationService: ElectroInstallationService,
    public router: Router
  ) {
    this.isContractFollowupTab = this.route.snapshot.url[0].path === 'detail';
    this.CompleteChecklistEdit = new ElectroCompleteChecklistEdit();
    this.isSelfInspection = this.router.url.indexOf('self-insp') > -1;
    this.initForm();
    let rights = JSON.parse(BaseServices.getUserRights());
    this.isEditorOrLeader =
      BaseServices.roleId == '3' ||
      HelperService.ApprovalPersonRightsCheck(
        rights,
        Rights.SERVICE_LEADER_ELECTRO
      );
    this.isClientUserRestrict =
      BaseServices.FeatureId == FeatureKey.CLIENT ||
      BaseServices.FeatureId == FeatureKey.CLIENT_CONTACT;
    this.isDisableAllFields = this.isClientUserRestrict;
  }

  ngOnInit() {
    this.tasks = this.route.snapshot.data['contracttasks'];
    this.contractTasks = this.route.snapshot.data['contracttasks'];
    this.inspectionTasks = this.route.snapshot.data['contracttasks'];
    this.enabledTasks = this.route.snapshot.data['enabledTasks'];
    this.clientContactInfo = this.route.snapshot.data['clientContactInfo'];
    this.treeNode = this.route.snapshot.data['treeData'];
    this.enabledTasks = this.enabledTasks ? this.enabledTasks : [];
    if (this.isSelfInspection) {
      this.isClientUserRestrict = false;
      this.isDisableAllFields = false;
    }
    this.statusDisabled = true;
    this.bindDropdown();
    this.bindTasks();
    this.contractId = this.route.snapshot.parent.params['cid'];
    this.inspectionId = +this.route.snapshot.parent.params['Iid'];
    if (this.inspectionId > 0) {
      this.treeData = this.route.snapshot.data['treeData'];
      let inspection = this.route.snapshot.data['inspection'];
      this.isEditMode = true;
      if (this.clientContactInfo) {
        this.contrcatNo = this.clientContactInfo.ContractNo;
        this.clientNo = this.clientContactInfo.ClientNumber;
      }
      this.initForm(inspection);
    } else {
      this.inspectionId = 0;
      this.newInspection = true;
      this.isEditMode = false;
      if (this.clientContactInfo) {
        this.contrcatNo = this.clientContactInfo.ContractNo;
        this.clientNo = this.clientContactInfo.ClientNumber;
        this.inspectionNoPrefix = (
          +this.clientContactInfo.ContractInspectionCount + 1
        ).toString();
        this.dispInspectionNo =
          this.clientContactInfo.ClientNumber +
          '-' +
          this.clientContactInfo.ContractNo +
          '-' +
          this.inspectionNoPrefix;
      }
      this.initForm();
    }
    this.additionalData = {
      ApplicationId: BaseServices.ApplicationId,
      BusinessId: BaseServices.BusinessId,
      ActivityId: 0,
      Status: '1',
      CreatedOn: new Date(),
      ModifiedBy: BaseServices.UserId,
      ModifiedOn: new Date(),
    };
    this.upConfig = {
      title: 'ATTACHMENTS',
      titleAsLabel: true,
      editMode: this.editMode,
      windowedHeight: false,
      viewSwitch: false,
      showUserMeta: false,
      showUploadButton: !this.isClientUserRestrict,
      showDeleteButton: !this.isClientUserRestrict,
      uploaderUri: environment.BASE_URL + '/electro/file/upload/inspection',
      addtionalData: this.additionalData,
    };
  }

  getCurrentDate() {
    let dateFormat = new Date();
    return {
      year: dateFormat.getFullYear(),
      month: dateFormat.getMonth() + 1,
      day: dateFormat.getDate() - 1,
    };
  }
  /**
   * bind status dropdown
   */
  bindDropdown() {
    // Bind inspection status dropdown values.
    this.subscriptions.push(
      this.translate
        .stream('SELECT_INSPECTION_STATUS_DROP')
        .subscribe((val) => {
          this.status = [];
          if (this.router.url.indexOf('self-insp') > -1) {
            this.status.push(
              { label: val.SELECT, value: null },
              { label: val.INSPECTION_PLANNED, value: 14 },
              { label: val.INSPECTION_STARTED, value: 15 },
              { label: val.COMPLETED, value: 16 }
            );
          } else {
            this.status.push(
              { label: val.SELECT, value: null },
              { label: val.BOOK_INSPECTION, value: 13 },
              { label: val.INSPECTION_PLANNED, value: 14 },
              { label: val.INSPECTION_STARTED, value: 15 },
              { label: val.COMPLETED, value: 16 }
            );
          }
        })
    );

    // Bind Task status dropdown values.
    this.subscriptions.push(
      this.translate.stream('SELECT_TASK_STATUS_DROP').subscribe((val) => {
        this.taskStatus = [];
        this.taskStatus.push(
          { label: val.SELECT, value: null },
          { label: val.NOT_STARTED, value: 1 },
          { label: val.STARTED, value: 2 },
          { label: val.COMPLETED, value: 3 }
        );
      })
    );
    this.followUpDays = [];
    for (let n = 0; n <= 14; n++) {
      if (n === 0) {
        this.followUpDays.push({
          label: this.translate.instant('ON_DEADLINE_DATE'),
          value: n,
        });
      } else {
        this.followUpDays.push({ label: n.toString(), value: n });
      }
    }
    this.followUpDays.push(
      { label: this.translate.instant('TREE_WEEKS'), value: 21 },
      { label: this.translate.instant('ONE_MONTH'), value: 30 }
    );
    this.subscriptions.push(
      this.translate.stream('SELECT_INTERVAL').subscribe((val) => {
        this.repetition = [];
        this.repetition.push(
          { label: val.SELECT, value: 0 },
          { label: val.NONE, value: 1 },
          { label: val.EVERY_MONTH, value: 2 },
          { label: val.EVERY_3_MONTH, value: 3 },
          { label: val.EVERY_6_MONTH, value: 4 },
          { label: val.ANNUALY, value: 5 },
          { label: val.EVERY_OTHER_YEAR, value: 6 },
          { label: val.EVERY_THIRD_YEAR, value: 7 },
          { label: val.EVERY_FIFTH_YEAR, value: 8 }
        );
      })
    );
    this.followup = [];
    this.execution = [];

    if (this.isSelfInspection == true) {
      this.followupPersonsForSelInspection =
        this.route.snapshot.data['followuppersonsforselfInspection'];
      if (this.followupPersonsForSelInspection) {
        this.followupPersonsForSelInspection.forEach((user: any) => {
          this.followup.push({ label: user.Name, value: user.Id });
        });
      }
    } else {
      this.followupPersons = this.route.snapshot.data['followuppersons'];
      if (this.followupPersons) {
        this.followupPersons.forEach((user: any) => {
          if (user.RoleId == 3) {
            this.followup.push({ label: user.Name, value: user.Id });
          } else {
            let rights = user.Rights ? JSON.parse(user.Rights) : null;
            let hasAccess = HelperService.ApprovalPersonRightsCheck(
              rights,
              Rights.SERVICE_LEADER_ELECTRO
            );
            if (hasAccess) {
              this.followup.push({ label: user.Name, value: user.Id });
            }
          }
        });
      }
    }

    if (this.isSelfInspection == true) {
      let executionForSelInspection =
        this.route.snapshot.data['executionpersonsforselfInspection'];
      if (executionForSelInspection) {
        executionForSelInspection.forEach((person) => {
          this.execution.push({ label: person.Name, value: person.Id });
        });
      }
    } else {
      let execution = this.route.snapshot.data['executionpersons'];
      if (execution) {
        execution.forEach((person) => {
          this.execution.push({ label: person.Name, value: person.Id });
        });
      }
    }

    let enabledChecklists = this.route.snapshot.data['enabledChecklists'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.enabledChecklists = [];
        this.enabledChecklists.push({ label: val.SELECT, value: null });
      })
    );
    if (enabledChecklists) {
      enabledChecklists.forEach((cl) => {
        this.enabledChecklists.push({ label: cl.name, value: cl.id });
      });
    }
  }

  /**
   * bind contract, inspection and enabled tasks to appropriate datatables.
   */
  bindTasks() {
    this.selectedContractTasks = [];
    this.selectedInspectionTasks = [];
    this.allSelectedTasks = [];
    if (this.tasks) {
      this.contractTasks = this.contractTasks.filter((x) => !x.InspectionId);
      this.inspectionTasks = this.inspectionTasks.filter((x) => x.InspectionId);
      if (this.enabledTasks) {
        this.enabledTasks.forEach((et) => {
          if (et.IsInspectionTask) {
            let a = this.inspectionTasks.find((x) => x.Id == et.ContentId);
            if (a) {
              a.Status = et.Status;
              a.ChecklistCompletionId = et.ChecklistCompletionId;
              let intervalKey = this.intervalKey.find(
                (x) => x.Value === a.Interval
              );
              a.IntervalText = intervalKey ? intervalKey.Key : '';
              this.selectedInspectionTasks.push(a); //collects enabled inspection specific tasks
              this.allSelectedTasks.push(a);
            }
          } else {
            let a = this.contractTasks.find((x) => x.Id == et.ContentId);
            if (a) {
              a.Status = et.Status;
              a.ChecklistCompletionId = et.ChecklistCompletionId;
              let intervalKey = this.intervalKey.find(
                (x) => x.Value === a.Interval
              );
              a.IntervalText = intervalKey ? intervalKey.Key : '';
              this.selectedContractTasks.push(a); //collects enabled contract tasks
              this.allSelectedTasks.push(a);
            }
          }
        });
      }
    }
  }

  /**
   * initialize form group and supply values to control on edit.
   * @param data
   */
  initForm(data?: ElectroInspection) {
    this.inspection = new ElectroInspection();
    let inspectionNo = '';
    let inspectionTitle = '';
    if (this.clientContactInfo) {
      inspectionNo = (
        +this.clientContactInfo.ContractInspectionCount + 1
      ).toString();
      inspectionTitle = this.clientContactInfo.ContractName;
    }
    let inspectionStatus = 0;
    let deadline = '';
    let startDate = '';
    let repetition = 0;
    let executionId = [];
    let followupId = [];
    let inspectionTasks = [];
    let notifyBeforeDeadline = 0;
    let notifyBeforeFollowup = 1;
    let isSelfInspection = false;
    let ischangeNotify = false;
    let isexcecutionChangeNotify = false;
    let isfollowupChangeNotify = false;
    let comment = '';
    if (data) {
      this.inspectionId = data.Id;
      this.parentId = data.ParentId;
      this.isEndControl =
        data.Status === InspectionStatus.Completed ? false : true;
      this.inspectionCreatedBy = data.CreatedBy;
      this.inspectionCreatedOn = data.CreatedOn;
      this.isSelfInspection = data.IsSelfInspection;
      this.inspectionNoPrefix = data.InspectionNoSuffix
        ? data.InspectionNoSuffix
        : '';
      this.dispInspectionNo =
        this.clientNo + '-' + this.contrcatNo + '-' + this.inspectionNoPrefix;
      inspectionNo = data.InspectionNoSuffix ? data.InspectionNoSuffix : '';
      this.cachedInspNum = inspectionNo;
      inspectionTitle = data.Title;
      inspectionStatus = data.Status;
      startDate = data.StartDate;
      this.date = startDate;
      deadline = data.Deadline;
      this.onDatedisable();
      this.currentDeadLine = data.Deadline ? new Date(data.Deadline) : null;
      repetition = data.RepetitionId;
      executionId = [];
      data.InspectionExecution.forEach((exe) => {
        executionId.push(exe.ExecutionId);
      });
      followupId = [];
      data.InspectionFollowup.forEach((fup) => {
        followupId.push(fup.FollowupId);
      });
      comment = data.Comments;
      ischangeNotify =
        data.IsChangeNotifyExecution || data.IsChangeNotifyFollowup
          ? true
          : false;
      isexcecutionChangeNotify = data.IsChangeNotifyExecution;
      isfollowupChangeNotify = data.IsChangeNotifyFollowup;
      this.uploadedFiles = [];
      if (data.ElectroInspectionAttachment) {
        data.ElectroInspectionAttachment.forEach((element) => {
          if (element) {
            this.uploadedFiles.push(
              HelperService.switchKeysCase(element, null, 'L')
            );
          }
        });
      }
    }
    this.inspectionForm = this.fb.group({
      InspectionNo: [
        inspectionNo,
        Validators.required,
        this.isInspectionNumUnique.bind(this),
      ],
      InspectionTitle: [
        inspectionTitle,
        [Validators.required, CustomValidator.noWhitespaceValidator],
      ],
      InspectionStatus: [inspectionStatus, Validators.required],
      Deadline: [
        deadline ? HelperService.formatInputDate(deadline) : '',
        Validators.required,
      ],
      StartDate: [
        startDate ? HelperService.formatInputDate(startDate) : '',
        Validators.required,
      ],
      Repetition: [repetition],
      Execution: [executionId, Validators.required],
      Followup: [followupId, Validators.required],
      Comment: [comment],
    });
    this.statusChange();
    this.isTasksCompleted = true;
  }

  isInspectionNumUnique(control: FormControl) {
    const q = new Promise((resolve) => {
      setTimeout(() => {
        if (this.clientContactInfo) {
          this.dispInspectionNo =
            this.clientContactInfo.ClientNumber +
            '-' +
            this.clientContactInfo.ContractNo +
            '-' +
            control.value;
        }
        if (control.value === this.cachedInspNum) {
          resolve(null);
        }
        if ((control.value || '').trim().length === 0) {
          resolve({ whitespace: true });
        } else {
          this.subscriptions.push(
            this.electroServices
              .checkInspectionNum(this.contractId, control.value)
              .subscribe(
                (x) => {
                  if (!x) {
                    resolve(null);
                  } else {
                    resolve({ inspectionNumberExists: true });
                  }
                },
                () => {
                  resolve({ inspectionNumberExists: true });
                }
              )
          );
        }
      }, 1000);
    });
    return q;
  }

  checkNumber(event: any): boolean {
    let k = event.keyCode;
    if (k < 48 || k > 57) {
      return false;
    }
    return true;
  }

  /**
   * Notification before deadline click event.
   * @param e
   * @param limitNumber
   */
  onNumber(e: any, limitNumber: any) {
    const limit = limitNumber;
    let charCode = e.which ? e.which : e.keyCode;
    if (
      e.target.value.length === limit ||
      (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46)
    ) {
      e.preventDefault();
    }
  }

  /**
   * save uploaded file
   */
  saveUploaded(event: any) {
    if (event) {
      let eventType = event.eventName;
      switch (eventType) {
        case 'DeleteDefaultFile':
          if (event.item.data.id === 0) {
            // remove unsaved attachment
            this.newDocumentAdded = this.newDocumentAdded.filter(
              (x) => x.Path !== event.item.data.path
            );
            this.uploadedFiles = this.uploadedFiles.filter(
              (x) => x.path !== event.item.data.path
            );
            this.toasterComponent.callToastDlt();
          } else {
            // remove existing attachement
            this.subscriptions.push(
              this.electroServices
                .deleteInspectionAttachment(event.item.data.id)
                .subscribe((result: any) => {
                  if (result) {
                    // remove from display list
                    this.uploadedFiles = this.uploadedFiles.filter(
                      (x) => x.path !== event.item.data.path
                    );
                    this.toasterComponent.callToastDlt();
                  }
                })
            );
          }
          break;
        default:
          let keyBindings = {
            Originalname: 'OriginalFileName',
            FileTypeId: 'FileType',
          };
          let suggestionDoc = Object.assign({}, event, this.additionalData);
          suggestionDoc.id = 0;
          suggestionDoc.createdBy = BaseServices.UserId;
          suggestionDoc.status = 1;
          this.newDocumentAdded.push(
            HelperService.switchKeysCase(suggestionDoc, keyBindings)
          );
          break;
      }
    }
  }
  /**
   * Complete the inspetion and save.
   */
  EndControl() {
    //set inspection status to completed
    this.inspectionForm
      .get('InspectionStatus')
      .patchValue(InspectionStatus.Completed);
    //clear inspection form for new inspection
    if (this.isSelfInspection) {
      this.inspection.NeedToRecurInspection = true;
      this.SaveInspection('stay');
    } else {
      this.ShowEndControlConfirm = true;
    }
  }

  endControlCofirmation(recur: boolean) {
    this.ShowEndControlConfirm = false;
    this.inspection.NeedToRecurInspection = recur;
    if (recur) {
      this.SaveInspection('stay');
    } else {
      this.SaveInspection('back');
    }
  }
  /**
   * save inspection and go back to inspection list.
   */
  SaveInspectionClick(nav?: string) {
    this.navOnSaveClick = nav;
    this.isShowNotifyPopup = true;
  }

  onDateChanged(event: any) {
    event.date = {
      year: event.date.year,
      month: event.date.month,
      day: event.date.day - 1,
    };
    this.dateOptionsforDeadLine = {
      dateFormat: 'dd/mm/yyyy',
      editableDateField: false,
      openSelectorOnInputClick: true,
      firstDayOfWeek: 'su',
      satHighlight: true,
      height: '32px',
      selectionTxtFontSize: '14px',
      disableUntil: event.date,
      inline: false,
      todayBtnTxt: this.translate.instant('TODAY'),
      dayLabels: {
        su: this.translate.instant('SUN'),
        mo: this.translate.instant('MON'),
        tu: this.translate.instant('TUE'),
        we: this.translate.instant('WED'),
        th: this.translate.instant('THU'),
        fr: this.translate.instant('FRI'),
        sa: this.translate.instant('SAT'),
      },
      monthLabels: {
        1: this.translate.instant('JANUARY'),
        2: this.translate.instant('FEBRUARY'),
        3: this.translate.instant('MARCH'),
        4: this.translate.instant('APRIL'),
        5: this.translate.instant('MAY'),
        6: this.translate.instant('JUNE'),
        7: this.translate.instant('JULY'),
        8: this.translate.instant('AUGUST'),
        9: this.translate.instant('SEPTEMBER'),
        10: this.translate.instant('OCTOBER'),
        11: this.translate.instant('NOVEMBER'),
        12: this.translate.instant('DECEMBER'),
      },
    };
    this.inspectionForm.value.Deadline;
    let deadline = new Date(this.inspectionForm.value.Deadline.formatted);
    let startDate = new Date(this.inspectionForm.value.StartDate.jsdate);
    if (this.inspectionForm.value.Deadline.jsdate < event.jsdate) {
      this.deadLinePastStartMsg = true;
      this.inspectionForm.patchValue({ Deadline: null });
    }
  }
  /**
   * save inspection.
   * @param nav
   */
  SaveInspection(nav?: string) {
    this.showLoader = true;
    this.isShowNotifyPopup = false;
    let CultureInfo = sessionStorage.getItem('languageMode');
    let IsChangeNotify = this.inspectionForm.value.IsChangeNotify;
    this.inspection.InspectionNoPrefix = this.inspectionNoPrefix;
    this.inspection.InspectionNoSuffix = this.inspectionForm.value.InspectionNo;
    this.inspection.Title = this.inspectionForm.value.InspectionTitle;
    this.inspection.Status = this.inspectionForm.value.InspectionStatus;
    this.inspection.Deadline = HelperService.formatDateFilter(
      this.inspectionForm.value.Deadline.formatted
    );
    this.inspection.StartDate = this.inspectionForm.value.StartDate
      ? HelperService.formatDateFilter(
          this.inspectionForm.value.StartDate.formatted
        )
      : null;
    this.inspection.RepetitionId = this.inspectionForm.value.Repetition;
    this.inspection.IsSelfInspection = this.isSelfInspection
      ? this.isSelfInspection
      : false;
    this.inspection.InspectionExecution = [];
    this.inspectionForm.value.Execution.forEach((exe) => {
      let execution = new InspectionExecution();
      execution.ExecutionId = exe;
      this.inspection.InspectionExecution.push(execution);
    });
    this.inspection.InspectionFollowup = [];
    this.inspectionForm.value.Followup.forEach((exe) => {
      let followup = new InspectionFollowup();
      followup.FollowupId = exe;
      this.inspection.InspectionFollowup.push(followup);
    });
    this.inspection.ElectroInspectionTasks = [];
    if (this.enabledTasks ? this.enabledTasks.length > 0 : false) {
      this.selectedContractTasks.forEach((task) => {
        let a = this.enabledTasks.find(
          (x) => x.ContentId == task.Id && !x.IsInspectionTask
        );
        let inspectionTask = new ElectroInspectionTasks();
        inspectionTask.ElectroContractId = this.contractId;
        inspectionTask.InspectionId = this.inspectionId;
        inspectionTask.ContentId = a.ContentId;
        inspectionTask.Status = task.Status;
        inspectionTask.Id = a.Id;
        inspectionTask.ChecklistCompletionId = a.ChecklistCompletionId;
        this.inspection.ElectroInspectionTasks.push(inspectionTask);
      });
      this.selectedInspectionTasks.forEach((task) => {
        let a = this.enabledTasks.find(
          (x) => x.ContentId == task.Id && x.IsInspectionTask
        );

        let inspectionTask = new ElectroInspectionTasks();
        inspectionTask.ElectroContractId = this.contractId;
        inspectionTask.InspectionId = this.inspectionId;
        inspectionTask.ContentId = a.ContentId;
        inspectionTask.Status = task.Status;
        inspectionTask.Id = a.Id;
        inspectionTask.IsInspectionTask = true;
        inspectionTask.ChecklistCompletionId = a.ChecklistCompletionId;
        this.inspection.ElectroInspectionTasks.push(inspectionTask);
      });
    }

    this.inspection.Comments = this.inspectionForm.value.Comment;
    this.inspection.BusinessId = BaseServices.BusinessId;
    this.inspection.ContractId = this.contractId;
    this.inspection.ElectroInspectionAttachment = this.newDocumentAdded;
    this.inspection.IsChangeNotifyExecution = this.IsChangeNotify
      ? this.IsExecutionChangeNotify
      : false;
    this.inspection.IsChangeNotifyFollowup = this.IsChangeNotify
      ? this.IsFollowupChangeNotify
      : false;
    if (this.inspectionId) {
      this.inspection.Id = this.inspectionId;
      this.inspection.ParentId = this.parentId;
      this.inspection.CreatedOn = this.inspectionCreatedOn;
      this.inspection.CreatedBy = this.inspectionCreatedBy;
      this.inspection.ModifiedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.electroServices
          .updateInspection(this.inspection, CultureInfo)
          .subscribe((result) => {
            if (result) {
              this.showLoader = false;
              this.toasterComponent.callToast();
              this.newDocumentAdded = [];
              this.isShowNotifyPopup = false;
              if (nav === 'back') {
                if (this.route.snapshot.url[0].path === 'edit') {
                  //common inspection overview tab
                  this.router.navigate([`./../../../list`], {
                    relativeTo: this.route,
                  });
                } else {
                  //installer and self inspection tabs
                  this.router.navigate([`./../../../../list`], {
                    relativeTo: this.route,
                  });
                }
              } else if (this.route.snapshot.url[0].path === 'detail') {
                if (nav === 'next') {
                  //save and edit click
                  this.router.navigate(
                    [
                      `./../../../../../../details/${this.contractId}/manual`,
                      { Id: this.contractId },
                    ],
                    { relativeTo: this.route }
                  );
                }
              } else if (
                this.inspection.NeedToRecurInspection &&
                result.ParentId === this.inspection.Id
              ) {
                this.isEndControl = false;
              }
            }
          })
      );
    } else {
      this.inspection.IsSelfInspection =
        this.router.url.indexOf('self-insp') > -1;
      this.inspection.CreatedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.electroServices
          .saveInspection(this.inspection, CultureInfo)
          .subscribe((result) => {
            if (result) {
              this.showLoader = false;
              this.inspectionId = result.Id;
              this.newDocumentAdded = [];
              this.isShowNotifyPopup = false;
              this.toasterComponent.callToast();
              this.inspectionCreatedBy = result.CreatedBy;
              this.inspectionCreatedOn = result.CreatedOn;
              this.isSelfInspection = result.IsSelfInspection;
              if (nav === 'back') {
                if (this.route.snapshot.url[0].path === 'edit') {
                  //common inspection overview tab
                  this.router.navigate([`./../../../list`], {
                    relativeTo: this.route,
                  });
                } else {
                  //installer and self inspection tabs
                  this.router.navigate([`./../../../../list`], {
                    relativeTo: this.route,
                  });
                }
              } else if (nav === 'next') {
                if (this.route.snapshot.url[0].path === 'detail') {
                  //save and edit click
                  this.router.navigate(
                    [
                      `./../../../../../../details/${this.contractId}/manual`,
                      { Id: this.contractId },
                    ],
                    { relativeTo: this.route }
                  );
                }
              } else {
                if (this.route.snapshot.url[0].path === 'detail') {
                  //contract-followup tab
                  this.router.navigate(
                    [`./../../${this.inspectionId}/detail`],
                    { relativeTo: this.route }
                  );
                } else {
                  //installer and self inspection tabs
                  this.router.navigate([`./../../${this.inspectionId}/info`], {
                    relativeTo: this.route,
                  });
                }
              }
            }
          })
      );
    }
  }

  /**
   * fill out a new report.
   */
  fillOutReport(taskId) {
    let url = JSON.parse(sessionStorage.getItem('appSettings')).BaseUrl;
    let u = BaseServices.userBase.BaseUrl;
    if (this.route.snapshot.url[0].path === 'info') {
      this.router.navigate(
        [
          `./../../../../../ckl/${this.contractId}/${this.inspectionId}/new-complete-cklist/${taskId}`,
        ],
        { relativeTo: this.route }
      );
    } //ckl/:cId/:Iid/new-complete-cklist/:tId
    else if (this.route.snapshot.url[0].path === 'edit') {
      this.router.navigate(
        [
          `./../../../../../electro/${this.contractId}/${this.inspectionId}/new-complete-cklist/${taskId}`,
        ],
        { relativeTo: this.route }
      );
    } else if (this.route.snapshot.url[0].path === 'detail') {
      this.router.navigate(
        [
          `./../../../../../../../electro/${this.contractId}/${this.inspectionId}/new-complete-cklist/${taskId}`,
        ],
        { relativeTo: this.route }
      );
    }
  }

  /**
   * add task to the inspection
   */
  addTask() {
    this.showAddTaskDialog = true;
  }

  /**
   * save task enabled for an inspection.
   */
  saveEnabledTasks() {
    let enabledTasks: ElectroInspectionTasks[];
    enabledTasks = [];
    let disabledTasks = [];
    this.selectedContractTasks.forEach((e) => {
      let t = new ElectroInspectionTasks();
      if (e.InspectionId == this.inspectionId && this.inspectionId != 0) {
        t.IsInspectionTask = true;
      }
      t.ContentId = e.Id;
      //collect saved task by matching content and inspection level
      if (this.enabledTasks) {
        let savedTask = this.enabledTasks.find(
          (x) => x.ContentId == t.ContentId && !x.IsInspectionTask
        );
        t.Id = savedTask ? savedTask.Id : 0;
      }
      t.ElectroContractId = this.contractId;
      t.InspectionId = this.inspectionId;
      t.Status = e.Status ? e.Status : InspectionTaskStatus.NOTSTARTED;
      t.ChecklistCompletionId = e.ChecklistCompletionId;
      enabledTasks.push(t);
    });
    this.selectedInspectionTasks.forEach((e) => {
      let t = new ElectroInspectionTasks();
      if (e.InspectionId == this.inspectionId && this.inspectionId != 0) {
        t.IsInspectionTask = true;
      }
      t.ContentId = e.Id;
      //collect saved task by matching content and inspection level
      if (this.enabledTasks) {
        let savedTask = this.enabledTasks.find(
          (x) =>
            x.ContentId == t.ContentId &&
            x.IsInspectionTask == t.IsInspectionTask
        );
        t.Id = savedTask ? savedTask.Id : 0;
      }
      t.ElectroContractId = this.contractId;
      t.InspectionId = this.inspectionId;
      t.Status = e.Status ? e.Status : InspectionTaskStatus.NOTSTARTED;
      enabledTasks.push(t);
    });
    this.subscriptions.push(
      this.electroServices
        .saveInspectionTasks(enabledTasks, this.inspectionId)
        .subscribe((result) => {
          if (result) {
            this.toasterComponent.callToast();
            this.enabledTasks = result;
            this.bindTasks();
            this.showAddTaskDialog = false;
          }
        })
    );
  }
  /**
   * add single task popup trigger event.
   */
  addSingleTask() {
    this.showAddSingleTaskDialog = true;
    this.singleTask.InspectionId = this.inspectionId;
    this.addServiceForm = this.fb.group({
      description: ['', Validators.required],
    });
  }

  /**
   * edit single task popup trigger event.
   * @param task
   */
  editSingleTask(task) {
    this.showAddSingleTaskDialog = true;
    this.singleTask.Id = task.Id;
    this.singleTask.CreatedOn = task.CreatedOn;
    this.singleTask.CreatedBy = task.CreatedBy;
    this.addServiceForm = this.fb.group({
      description: [task.Name, Validators.required],
    });
  }

  /**
   * delete single task event.
   * @param taskId
   */
  deleteSingleTask(taskId) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.electroServices
            .deleteContractContent(taskId)
            .subscribe((result) => {
              if (result) {
                this.subscriptions.push(
                  this.electroServices
                    .getAssignedTasks(this.contractId, this.inspectionId)
                    .subscribe((result) => {
                      if (result) {
                        this.tasks = result;
                        this.contractTasks = result;
                        this.inspectionTasks = result;
                        this.bindTasks();
                      }
                    })
                );
              }
              this.toasterComponent.callToastDlt();
            })
        );
      },
    });
  }

  /**
   * save single task service for the inspection.
   */
  saveService(addServiceForm: any) {
    let contractContent = new ElectroContractContent();
    contractContent.Id = this.singleTask.Id;
    contractContent.Name = this.addServiceForm.value.description;
    contractContent.BusinessId = BaseServices.BusinessId;
    contractContent.InspectionId = this.inspectionId;
    if (contractContent.Id > 0) {
      contractContent.CreatedBy = this.singleTask.CreatedBy;
      contractContent.CreatedOn = this.singleTask.CreatedOn;
      contractContent.ModifiedBy = BaseServices.UserId;
    } else {
      contractContent.CreatedBy = BaseServices.UserId;
    }
    this.subscriptions.push(
      this.electroServices
        .saveContractContent(contractContent)
        .subscribe((result) => {
          if (result) {
            this.showAddSingleTaskDialog = false;
            this.subscriptions.push(
              this.electroServices
                .getAssignedTasks(this.contractId, this.inspectionId)
                .subscribe((result) => {
                  if (result) {
                    this.tasks = result;
                    this.contractTasks = result;
                    this.inspectionTasks = result;
                    this.bindTasks();
                  }
                  this.addServiceForm.reset();
                })
            );
          }
          this.toasterComponent.callToast();
        })
    );
  }

  /**
   * service description form control clear event.
   */
  clearDescription() {
    this.addServiceForm.reset();
  }

  /**
   * task complete button for filling out checklist.
   * @param task
   */
  complete(task: any) {
    let taskId = 0;
    this.inspectionForm.patchValue({
      InspectionStatus: InspectionStatus.InspectionStarted,
    });
    this.SaveInspection('stay');
    if (task) {
      let savedTask = this.enabledTasks.find(
        (x) =>
          x.ContentId == task.Id &&
          ((x.IsInspectionTask
            ? task.IsInspectionTask
            : !task.IsInspectionTask) ||
            x.InspectionId == task.InspectionId)
      );
      taskId = savedTask.Id;
    }
    this.fillOutReport(taskId);
  }

  // Events form Tree
  catchTreeEvent(event): void {
    if (
      event.eventName !== 'toggleExpanded' &&
      event.eventName === 'activate' &&
      event.node
    ) {
      switch (event.node.data.documentType) {
        case 'checklist':
          this.enableForm = 'checklist';
          this.subscriptions.push(
            this.electroDocumentServices
              .getCompletionChecklistdata(event.node.data.nodeId)
              .subscribe((res) => {
                if (res) {
                  this.previewHeader = res.Title;
                  this.previewChecklist = true;
                  this.CompleteChecklistEdit = JSON.parse(res.CheckListData);
                }
              })
          );
          break;
        case 'archiveundercompletion':
          this.enableForm = 'checklist';
          this.subscriptions.push(
            this.electroDocumentServices
              .getCompletionChecklistdata(event.node.data.nodeId)
              .subscribe((res) => {
                if (res) {
                  this.previewHeader = res.Title;
                  this.previewChecklist = true;
                  this.CompleteChecklistEdit = JSON.parse(res.CheckListData);
                }
              })
          );
          break;
      }
      switch (event.node.data.installationFormType) {
        case ElectroInstallatioForms.ASSESSMENT_OF_AVAILABLE_DOCUMENTATION:
          this.enableForm = 'document-assessment';
          this.subscriptions.push(
            this.electroInstallationService
              .getDocumentAssessmsentById(event.node.data.nodeId)
              .subscribe((res) => {
                if (res) {
                  this.formData = res;
                  this.previewHeader = 'PREVIEW';
                  this.previewChecklist = true;
                  this.documentAssessment.initForm(res);
                  this.documentAssessment.editMode = false;
                }
              })
          );
          break;
        case ElectroInstallatioForms.RISK_ASSESSMENT_OF_THE_INSTALLATION:
          this.enableForm = 'risk-assessment';
          this.subscriptions.push(
            this.electroInstallationService
              .getRiskAssessmentById(event.node.data.nodeId)
              .subscribe((res) => {
                if (res) {
                  this.formData = res;
                  this.previewHeader = 'PREVIEW';
                  this.previewChecklist = true;
                  this.riskAssessment.initForm(res);
                  this.riskAssessment.editMode = false;
                }
              })
          );
          break;
        case ElectroInstallatioForms.ELECTRICAL_DISTRIBUTION_OVERVIEW:
          this.enableForm = 'distribution-overview';
          this.subscriptions.push(
            this.electroInstallationService
              .getDistributionsById(event.node.data.nodeId)
              .subscribe((res) => {
                if (res) {
                  this.formData = res;
                  this.previewHeader = 'PREVIEW';
                  this.previewChecklist = true;
                  this.distribution.initForm(res);
                  this.distribution.editMode = false;
                }
              })
          );
          break;
        case ElectroInstallatioForms.ELECTRICAL_COURSE_OVERVIEW:
          this.enableForm = 'course-overview';
          this.subscriptions.push(
            this.electroInstallationService
              .getCourseById(event.node.data.nodeId)
              .subscribe((res) => {
                if (res) {
                  this.formData = res;
                  this.previewHeader = 'PREVIEW';
                  this.previewChecklist = true;
                  this.course.initForm(res);
                  this.course.editMode = false;
                  this.course.isTemplate = true;
                }
              })
          );
          break;
        case ElectroInstallatioForms.FIRE_ALARM_SYSTEM:
          this.enableForm = 'fire-alarm';
          this.subscriptions.push(
            this.electroInstallationService
              .getFirealarmSystemById(event.node.data.nodeId)
              .subscribe((res) => {
                if (res) {
                  this.formData = res;
                  this.previewHeader = 'PREVIEW';
                  this.previewChecklist = true;
                  this.filreAlarm.initForm(res);
                  this.filreAlarm.editMode = false;
                }
              })
          );
          break;
        case ElectroInstallatioForms.EMERGENCY_LIGHTING_SYSTEM:
          this.enableForm = 'emergency-lighting';
          this.subscriptions.push(
            this.electroInstallationService
              .getEmergencyLightingById(event.node.data.nodeId)
              .subscribe((res) => {
                if (res) {
                  this.formData = res;
                  this.previewHeader = 'PREVIEW';
                  this.previewChecklist = true;
                  this.emergencyLighting.initForm(res);
                  this.emergencyLighting.editMode = false;
                }
              })
          );
          break;
        case ElectroInstallatioForms.MEASURE_PROTOCOL:
          this.enableForm = 'measure-protocol';
          this.previewChecklist = true;
          this.previewHeader = 'PREVIEW';
          this.formData = { Id: event.node.data.nodeId };
          break;
      }
    } else if (event.eventName === 'deactivate') {
      this.previewChecklist = false;
    }
  }

  treeOptionsEvent(event) {
    if (event) {
      let cklOrFormType = 0;
      if (event.data.installationFormType) {
        cklOrFormType = event.data.installationFormType;
      }
      switch (event.data.documentType) {
        case 'checklist':
          cklOrFormType = 0;
          break;
        case 'archiveundercompletion':
          cklOrFormType = 0;
          break;
        case 'installationform':
          cklOrFormType = event.data.installationFormType;
          break;
        case 'undercompletioninstallationform':
          cklOrFormType = event.data.installationFormType;
          break;
      }
      if (event.action == 'edit') {
        let clId = event.data.nodeId;
        let routeNav;
        if (this.route.snapshot.url[0].path === 'info') {
          routeNav = `./../../../../../ckl/${this.contractId}/${this.inspectionId}/complete-cklist/`;
        } else if (this.route.snapshot.url[0].path === 'edit') {
          routeNav = `./../../../../../electro/${this.contractId}/${this.inspectionId}/complete-cklist/`;
        } else if (this.route.snapshot.url[0].path === 'detail') {
          routeNav = `./../../../../../../../electro/${this.contractId}/${this.inspectionId}/complete-cklist/`;
        }
        this.router.navigate(
          [routeNav + `${cklOrFormType}/${event.data.nodeId}`],
          { relativeTo: this.route }
        );
      } else if (event.action == 'print') {
        let type = 'pdf';
        if (event.data.type == 'folder') {
          alert('Folder specific report');
          this.subscriptions.push(
            this.electroDocumentServices
              .inspectionBuildingCklOrFormReport(
                event.data.nodeId,
                this.inspectionId
              )
              .subscribe(
                (blob) => {
                  this.showLoader = false;
                  let link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);
                  link.download = event.data.filename;
                  link.click();
                },
                (error) => {
                  this.showLoader = false;
                  alert('Export not downloaded');
                }
              )
          );
        } else {
          this.subscriptions.push(
            this.electroDocumentServices
              .inspectionCklOrFormReport(
                cklOrFormType,
                event.data.nodeId,
                this.contractId
              )
              .subscribe(
                (blob) => {
                  this.showLoader = false;
                  let link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);
                  link.download = event.data.filename + '.pdf';
                  link.click();
                },
                (error) => {
                  this.showLoader = false;
                  alert('Export not downloaded');
                }
              )
          );
        }
      } else if (event.action == 'delete') {
        this.showLoader = true;
        this.subscriptions.push(
          this.electroDocumentServices
            .deleteCompletionChecklist(event.data.nodeId, cklOrFormType)
            .subscribe((res) => {
              if (res) {
                this.subscriptions.push(
                  this.electroDocumentServices
                    .getInspectionChecklistBuildingtree(
                      this.contractId,
                      this.inspectionId
                    )
                    .subscribe((result) => {
                      this.showLoader = false;
                      this.treeData = result;
                    })
                );
              }
            })
        );
      }
    }
  }
  /**
   * check status of tasks selected for completed to enable end control
   */
  checkCompletion() {
    let allCompleted = true;
    this.isDisableAllFields = false;
    this.statusDisabled = false;

    this.allSelectedTasks.forEach((t) => {
      if (t.Status === InspectionTaskStatus.COMPLETED) {
        this.isDisableAllFields = false;
      } else {
        this.isDisableAllFields = true;
      }
    });
    this.allSelectedTasks.forEach((t) => {
      if (t.Status !== InspectionTaskStatus.COMPLETED) {
        let allCompleted: any = false;
        return allCompleted;
      }
    });
    return allCompleted;
  }

  /**
   * task selection event
   * @param rowData
   * @param taskStatus
   */
  onTaskSelected(rowData, event) {
    if (this.selectedContractTasks) {
      if (event) {
        this.selectedContractTasks.push(rowData);
      } else {
        this.selectedContractTasks.splice(
          this.selectedContractTasks.indexOf(rowData),
          1
        );
      }
    }
  }

  /**
   * Inspection generate report event.
   */
  InspectionReport() {
    this.showLoader = true;
    let type = 'pdf';
    let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
    let languageId = appSettings.Language.LanguageId;
    let CultureInfo = BaseServices.userCultureInfo();
    this.subscriptions.push(
      this.electroDocumentServices
        .generateInspectionReport(this.inspectionId, CultureInfo)
        .subscribe(
          (blob) => {
            this.showLoader = false;
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${this.translate.instant(
              'INSPECTION_REPORT'
            )}.${type.toLowerCase()}`;
            link.click();
          },
          (error) => {
            this.showLoader = false;
            alert('Export not downloaded');
          }
        )
    );
  }

  setStatus(
    event: CompleteChecklistStatus,
    status: CompleteChecklistStatus[],
    CompleteChecklistItemSection: CompleteChecklistItemSection
  ) {
    for (let statusitem of status) {
      if (statusitem.Label === event.Label) {
        statusitem.value = true;
      } else {
        statusitem.value = false;
      }
    }

    this.CompleteCheckListServices.getCurrentDate().subscribe((result: any) => {
      if (result) {
        CompleteChecklistItemSection.Signature =
          this.translate.instant('SIGNED') +
          BaseServices.userName +
          ' ' +
          this.datePipe.transform(result, 'dd/MM/yyyy HH:mm');
      }
    });
  }

  addEditDeviation(devId, headPoint, subSection) {
    this.deviation = new ElectroDeviation();
    this.addFileHeadpoint = headPoint;
    this.addFileCheckpoint = subSection;
    if (devId) {
      this.electroServices.getDeviationById(devId).subscribe((result) => {
        if (result) {
          this.deviation = result;
        }
      });
    } else {
    }
  }

  onDatedisable() {
    this.dateOptionsforDeadLine = {
      dateFormat: 'dd/mm/yyyy',
      editableDateField: false,
      openSelectorOnInputClick: true,
      firstDayOfWeek: 'su',
      satHighlight: true,
      height: '32px',
      selectionTxtFontSize: '14px',
      disableUntil: this.getDate(),
      inline: false,
      todayBtnTxt: this.translate.instant('TODAY'),
      dayLabels: {
        su: this.translate.instant('SUN'),
        mo: this.translate.instant('MON'),
        tu: this.translate.instant('TUE'),
        we: this.translate.instant('WED'),
        th: this.translate.instant('THU'),
        fr: this.translate.instant('FRI'),
        sa: this.translate.instant('SAT'),
      },
      monthLabels: {
        1: this.translate.instant('JANUARY'),
        2: this.translate.instant('FEBRUARY'),
        3: this.translate.instant('MARCH'),
        4: this.translate.instant('APRIL'),
        5: this.translate.instant('MAY'),
        6: this.translate.instant('JUNE'),
        7: this.translate.instant('JULY'),
        8: this.translate.instant('AUGUST'),
        9: this.translate.instant('SEPTEMBER'),
        10: this.translate.instant('OCTOBER'),
        11: this.translate.instant('NOVEMBER'),
        12: this.translate.instant('DECEMBER'),
      },
    };
  }

  getDate() {
    let dateFormat = new Date(this.date);
    return {
      year: dateFormat.getFullYear(),
      month: dateFormat.getMonth() + 1,
      day: dateFormat.getDate() - 1,
    };
  }

  InspectionReportClick() {
    let incompleteExists = false;
    if (this.treeData) {
      this.treeData.forEach((b) => {
        if (b.nodes ? b.nodes.length : false) {
          let ucCkl = b.nodes.filter((ckl) => ckl.mimetype == 'checklist-uc');
          if (ucCkl ? ucCkl.length : false) {
            incompleteExists = true;
            return;
          }
        }
      });
    }
    if (incompleteExists) {
      this.confirmationService.confirm({
        message: this.translate.instant('REPORT_WITH_INCOMPLETE_ITEMS'),
        accept: () => {
          this.InspectionReport();
        },
      });
    } else {
      this.InspectionReport();
    }
  }

  endControlClick() {
    let incompleteExists = false;
    if (this.treeData) {
      this.treeData.forEach((b) => {
        if (b.nodes ? b.nodes.length : false) {
          let ucCkl = b.nodes.filter((ckl) => ckl.mimetype == 'checklist-uc');
          if (ucCkl ? ucCkl.length : false) {
            incompleteExists = true;
            return;
          }
        }
      });
    }
    if (incompleteExists) {
      this.confirmationService.confirm({
        message: this.translate.instant('COMPLETE_WITH_INCOMPLETE_ITEMS'),
        accept: () => {
          this.EndControl();
        },
      });
    } else {
      this.EndControl();
    }
  }

  back() {
    if (this.route.snapshot.url[0].path === 'edit') {
      //common inspection overview tab
      this.router.navigate([`./../../../list`], { relativeTo: this.route });
    } else {
      //installer and self inspection tabs
      this.router.navigate([`./../../../../list`], { relativeTo: this.route });
    }
  }

  onBuidingSelect(distId) {
    if (distId) {
      this.subscriptions.push(
        this.electroInstallationService
          .getCourseByInspectionDistribution(
            this.course.courseOverview.BuildingId,
            this.inspectionId,
            distId
          )
          .subscribe((course) => {
            if (course) {
              this.formData = course;
            } else {
              this.formData = new ElectroCourseOverview();
            }
            this.formData.DistributionId = distId;
            this.formData.BuildingId = this.course.courseOverview.BuildingId;
            this.course.showLoader = false;
            this.course.initForm(this.formData);
          })
      );
    }
  }

  statusChange() {
    if (
      this.inspectionForm.value.InspectionStatus ===
      InspectionStatus.BookInspection
    ) {
      this.hideStartDate = true;
      this.inspectionForm.patchValue({ StartDate: null });
      this.inspectionForm.controls['StartDate'].clearValidators();
      this.inspectionForm.controls['StartDate'].updateValueAndValidity();
    } else {
      this.hideStartDate = false;
      this.inspectionForm.controls['StartDate'].setValidators(
        Validators.required
      );
      this.inspectionForm.controls['StartDate'].updateValueAndValidity();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}

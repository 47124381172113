<!-- Include Font Awesome for icons -->
<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
/>
<div class="tab-content">
  <div class="page-title">
    <h4 translate>DEVIATIONS</h4>
  </div>
  <!-- Charts section -->
  <div class="chart">
    <div class="button-container d-flex justify-content-end">
      <button
        class="btn nav-button"
        [ngClass]="{
          'btn-outline-red': !isPaused,
          'btn-outline-green': isPaused
        }"
        (click)="togglePause()"
      >
        <i
          class="fas"
          [ngClass]="{
            'fa-pause': !isPaused,
            'fa-play': isPaused,
            'pause-icon': !isPaused,
            'play-icon': isPaused
          }"
        ></i>
      </button>
      <button
        class="btn btn-outline-primary nav-button"
        (click)="changeChart('previous')"
      >
        <i class="fas fa-chevron-left"></i>
      </button>
      <button
        class="btn btn-outline-primary nav-button"
        (click)="changeChart('next')"
      >
        <i class="fas fa-chevron-right"></i>
      </button>
    </div>

    <app-deviation-list-charts
      [chartView]="chartView"
      [deviationList]="deviationList"
      [isPortal]="isPortal"
      (buttonClicked)="onChildButtonClicked()"
    ></app-deviation-list-charts>
  </div>
  <!-- Charts section ends -->
  <span class="page-actions d-flex justify-content-end" *ngIf="isShowNewButton">
    <a
      routerLink="new"
      [routerLink]="['./new']"
      class="btn btn-success"
      routerLinkActive="active"
    >
      <span class="icon ic-sm icon-add"></span>
      <span translate>NEW</span>
    </a>
  </span>
  <div class="table-view">
    <!-- Table starts -->
    <p-table
      #deviationTable
      [value]="deviationList"
      [rows]="10"
      [paginator]="true"
      [pageLinks]="5"
      [rowsPerPageOptions]="rowsPerPageOptions"
      [showCurrentPageReport]="true"
      (onFilter)="deviationFilterChange($event)"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} recods"
      (onPage)="deviationPageChange($event)"
    >
      <!-- Caption -->
      <ng-template pTemplate="caption">
        <div class="ui-helper-clearfix">
          <div class="filter-controls-wrapper" style="display: none">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="col-form-label">
                    <span translate>SHOW_DEVIATION</span>
                  </label>
                  <div class="input-group">
                    <p-dropdown
                      [options]="ShowDeviation"
                      [style]="{ width: '100%' }"
                      (onChange)="onShowDeviationChanged($event)"
                      #showDeviation
                      [disabled]="showDeviationDisabled"
                    ></p-dropdown>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="col-form-label">
                    <span translate>RESPONSIBLE_FOR_FOLLOWUP</span>
                  </label>
                  <div>
                    <p-dropdown
                      type="text"
                      [options]="followup"
                      [(ngModel)]="responsibleFollowUpFilter"
                      (onChange)="onFollowupChanged($event)"
                      [filter]="true"
                      [filterBy]="'label'"
                      [style]="{ width: '100%' }"
                    ></p-dropdown>
                  </div>
                </div>
              </div>
              <div class="col-md-4" [formGroup]="deviationSearch">
                <div class="form-group">
                  <label class="col-form-label">
                    <span translate>TYPE_OF_CASE</span>
                  </label>
                  <div class="input-btn-group">
                    <p-dropdown
                      [options]="Case"
                      [style]="{ width: '150px' }"
                      formControlName="typeOfCase"
                      [filter]="true"
                      [filterBy]="'label'"
                      (onChange)="onCaseChanged(caseDropdown)"
                      #caseDropdown
                    ></p-dropdown>
                    <button
                      type="button"
                      class="btn btn-outline-primary"
                      *ngIf="isShowPlusbutton"
                      (click)="addtypeOfCase = !addtypeOfCase"
                    >
                      <i class="pi pi-plus" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="startDate" class="col-form-label">
                    <span translate>CREATED_FROM</span>
                  </label>
                  <div>
                    <my-date-picker
                      name="startdate"
                      [options]="startDateOptions"
                      [(ngModel)]="craetedFromFilter"
                      placeholder="{{ 'SELECT_START_DATE' | translate }}"
                      (dateChanged)="onStartDateChanged($event)"
                      required
                    ></my-date-picker>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="endDate" class="col-form-label">
                    <span translate>CREATED_TO</span>
                  </label>
                  <div>
                    <my-date-picker
                      name="enddate"
                      [options]="endDateOptions"
                      [(ngModel)]="createdToFilter"
                      placeholder="{{ 'SELECT_END_DATE' | translate }}"
                      (dateChanged)="onEndDateChanged($event)"
                      required
                    ></my-date-picker>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="endDate" class="col-form-label">
                    <span translate>DESCRIPTION</span>
                  </label>
                  <div>
                    <input
                      type="text"
                      class="label-input form-control"
                      [(ngModel)]="descFilter"
                      (input)="onSearchChange($any($event.target)?.value)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <form [formGroup]="deviationSearch">
                  <div class="form-group">
                    <label class="col-form-label">
                      <span translate>TOPICS</span>
                    </label>
                    <div>
                      <p-dropdown
                        [options]="Topics"
                        [style]="{ width: '150px' }"
                        formControlName="topic"
                        (onChange)="onTopicChanged(topicDropdown)"
                        #topicDropdown
                        filter="filter"
                      ></p-dropdown>
                      <button
                        type="button"
                        class="btn btn-outline-primary"
                        *ngIf="isShowPlusbutton"
                        (click)="addTopic = !addTopic"
                      >
                        <i class="pi pi-plus" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-4" *ngIf="!isAdmin">
                <div class="form-group">
                  <label class="col-form-label">
                    <span translate>CLIENT</span>
                  </label>
                  <div>
                    <p-dropdown
                      [options]="Client"
                      [(ngModel)]="clientFilter"
                      (onChange)="onClientChanged(clientDropdown)"
                      #clientDropdown
                      [filter]="true"
                      [style]="{ width: '100%' }"
                    ></p-dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="col-form-label">
                    <span translate>RESPONSIBLE_FOR_EXECUTION</span>
                  </label>
                  <div>
                    <p-dropdown
                      type="text"
                      [(ngModel)]="responsibleExecutionFilter"
                      [options]="vresperson"
                      (onChange)="onExecutionChanged(executionDropdown)"
                      #executionDropdown
                      [filter]="true"
                      [filterBy]="'label'"
                      [style]="{ width: '100%' }"
                    ></p-dropdown>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="col-form-label">
                    <span translate>DEPARTMENT</span>
                  </label>
                  <div class="input-group">
                    <p-dropdown
                      [options]="departments"
                      [(ngModel)]="deptFilter"
                      [style]="{ width: '100%' }"
                      [filter]="true"
                      (onChange)="onDepartmentChanged(depControl)"
                      #depControl
                    ></p-dropdown>
                  </div>
                </div>
              </div>
              <div class="col-md-4" *ngIf="isBusinessId">
                <div class="form-group">
                  <label for="endDate" class="col-form-label">
                    <span translate>CREATED_BY</span>
                  </label>
                  <div>
                    <p-dropdown
                      [options]="createdBy"
                      [(ngModel)]="createdByFilter"
                      [style]="{ width: '100%' }"
                      (onChange)="onCreatedByChange($event, createdByDropdown)"
                      #createdByDropdown
                      styleClass="ui-column-filter"
                    ></p-dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <button
                  class="btn btn-outline-primary"
                  type="submit"
                  (click)="clearFilters()"
                >
                  <span class="icon ic-sm icon-reset"></span>
                  <span translate>REFRESH</span>
                </button>
              </div>
            </div>
          </div>
          <div class="table-options">
            <div class="pull-right">
              <button class="btn p-3 filter-toggle">
                <span class="icon ic-sm icon-filter"></span>
                <span translate>FILTER</span>
              </button>
              <div class="action-btns-group">
                <kuba-export
                  [printMode]="1"
                  [reportData]="
                    deviationTable.filteredValue || deviationTable.value
                  "
                  (loading)="loader($event)"
                  [additionalData]="additionalData"
                ></kuba-export>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th width="35"></th>
          <th pSortableColumn="No" width="95px">
            {{ "NUMBER_NO" | translate }}
          </th>
          <th pSortableColumn="BusinessName" *ngIf="isAdmin || isPortal">
            {{ "BUSINESS" | translate }}
          </th>
          <th pSortableColumn="Title">
            {{ "TITLE" | translate }}
          </th>
          <th pSortableColumn="ChecklistName" *ngIf="userRole == 'Editor'">
            {{ "CHECKLIST_NAME" | translate }}
          </th>
          <th pSortableColumn="ActivityName" *ngIf="!isAdmin">
            {{ "ACITVITY" | translate }}
          </th>
          <th width="75px">{{ "STATUS" | translate }}</th>
          <th width="80px">{{ "IMPORTANCE" | translate }}</th>
          <th>{{ "DEPARTMENT" | translate }}</th>
          <th pSortableColumn="DeadlineForPdf" width="105">
            {{ "DEADLINE" | translate }}
          </th>
          <th pSortableColumn="Modules">
            {{ "MODULES" | translate }}
          </th>
          <th pSortableColumn="Cost" width="90">
            {{ "COST" | translate }}
          </th>

          <th pSortableColumn="CompletedDate" width="105">
            {{ "DONE_ON" | translate }}
          </th>
          <th pSortableColumn="CreatedOn">
            {{ "CREATED_ON" | translate }}
          </th>
          <th width="45" *ngIf="hasDeviationDeleteAccess"></th>
        </tr>
        <tr>
          <th></th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                deviationTable.filter(
                  $any($event.target)?.value,
                  'No',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th *ngIf="isAdmin || isPortal">
            <input
              pInputText
              type="text"
              (input)="
                deviationTable.filter(
                  $event.target.value,
                  'BusinessName',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                deviationTable.filter(
                  $any($event.target)?.value,
                  'Title',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th *ngIf="userRole == 'Editor'">
            <input
              pInputText
              type="text"
              (input)="
                deviationTable.filter(
                  $any($event.target)?.value,
                  'ChecklistName',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th *ngIf="!isAdmin">
            <input
              pInputText
              type="text"              
              (input)="
                deviationTable.filter(
                  $any($event.target)?.value,
                  'ActivityName',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th>
            <p-dropdown
              width="65px"
              [options]="statuses"
              #ed
              (onChange)="onStatusChanged($event, ed)"
              styleClass="p-column-filter"
              placeholder=""
              [filterBy]="null"
              [showClear]="true"
              appendTo="body"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th>
            <p-dropdown
              width="118px"
              [options]="importances"
              #impControl
              (onChange)="onImportanceChange($event, impControl)"
              styleClass="p-column-filter"
              placeholder=""
              [filterBy]="null"
              [showClear]="true"
              appendTo="body"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th>
            <p-dropdown
              width="118px"
              [options]="vDepartment"
              #department
              (onChange)="onDepartmentChange($event, department)"
              styleClass="p-column-filter"
              placeholder=""
              [filter]="true"
              [filterBy]="'label'"
              [showClear]="true"
              appendTo="body"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th></th>
          <th>
            <p-dropdown
              width="118px"
              [options]="vModules"
              #modules
              (onChange)="onModuleChange($event)"
              styleClass="p-column-filter"
              placeholder=""
              [filter]="true"
              [filterBy]="'label'"
              [showClear]="true"
              appendTo="body"
            >
              <ng-template let-module pTemplate="item">
                {{ module.label}}
              </ng-template>
            </p-dropdown>
          </th>
          <th></th>

          <th></th>
          <th></th>
          <th *ngIf="hasDeviationDeleteAccess"></th>
        </tr>
      </ng-template>

      <!-- Body Content -->
      <ng-template pTemplate="body" let-deviation let-i="rowIndex">
        <tr>
          <td>
            <i
              *ngIf="deviation.StatusId == 10"
              class="{{ deviation.StatusIcon }}"
              style="color: red"
            ></i>
            <i
              *ngIf="deviation.StatusId != 10"
              class="{{ deviation.StatusIcon }}"
            ></i>
          </td>

          <td>
            <span
              pTooltip="{{ deviation.No | translate }}"
              tooltipPosition="top"
              translate
              >{{ deviation.No }}</span
            >
          </td>
          <td *ngIf="isAdmin || isPortal">
            <span
              pTooltip="{{ deviation.BusinessName | translate }}"
              tooltipPosition="top"
              translate
              >{{ deviation.BusinessName }}</span
            >
          </td>
          <td *ngIf="!isPortal && !isAdmin">
            <a
              class="truncate-data"
              pTooltip="{{ deviation.Title | translate }}"
              tooltipPosition="top"
              [routerLink]="['./edit', deviation['Id']]"
              >{{ deviation.Title }}</a
            >
          </td>
          <td *ngIf="isPortal || isAdmin">
            <span>{{ deviation.Title }}</span>
          </td>
          <td *ngIf="userRole == 'Editor'">
            <span
              class="truncate-data"
              pTooltip="{{ deviation.ChecklistName | translate }}"
              tooltipPosition="top"
            >
              {{ deviation.ChecklistName }}</span
            >
          </td>
          <td *ngIf="!isAdmin">
            <span
              class="truncate-data"
              pTooltip="{{ deviation.ActivityName | translate }}"
              tooltipPosition="top"
            >
              {{ deviation.ActivityName }}
            </span>
          </td>
          <td>
            <span translate>{{ deviation.Status }}</span>
          </td>
          <td>
            <div *ngIf="deviation.Importance.backColor !== ''">
              <span
                class="badge"
                [style.background-color]="deviation.Importance.backColor"
                >{{ deviation.Importance.value | translate }}
              </span>
            </div>
          </td>
          <td>
            <span
              [pTooltip]="deviation.Department"
              tooltipPosition="top"
              [innerHTML]="deviation.Department"
            ></span>
          </td>
          <td>
            <span translate>{{ deviation.DeadlineForPdf }}</span>
          </td>
          <td>
            <span
              pTooltip="{{ deviation.Modules | translate }}"
              tooltipPosition="top"
              translate
              >{{ deviation.Modules }}</span
            >
          </td>
          <td>
            <span>{{ deviation.Cost }}</span>
          </td>

          <td>
            <span translate>{{
              deviation.CompletedDate | date : "dd/MM/yyyy"
            }}</span>
          </td>
          <td>
            <span translate>{{
              deviation.CreatedOn | date : "dd/MM/yyyy"
            }}</span>
          </td>
          <td class="col-button" *ngIf="hasDeviationDeleteAccess">
            <button
              type="button"
              class="btn btn-icon"
              (click)="confirm(deviation.Id)"
              title="{{ 'DELETE' | translate }}"
              *ngIf="deviation.StatusId !== 9"
            >
              <i class="icon ic-sm icon-trash"></i>
            </button>
          </td>
        </tr>
      </ng-template>
      <!-- Footer -->
      <ng-template pTemplate="summary">
        <div class="row">
          <div class="col-md-2" *ngIf="!isPortal">
            <div *ngIf="isInternalControl" class="font-weight-bold" translate>
              IK - {{ "INTERNAL_CONTROL" | translate }}
            </div>
            <div *ngIf="isproject" class="font-weight-bold" translate>
              PR - {{ "PROJECT" | translate }}
            </div>
          </div>
          <div class="col-md-2" *ngIf="!isPortal">
            <div *ngIf="isfdv" class="font-weight-bold" translate>
              FDV - {{ "FDV" | translate }}
            </div>
            <div *ngIf="isVehicles" class="font-weight-bold" translate>
              KJ - {{ "Vehicle" | translate }}
            </div>
          </div>
          <div class="col-md-2" *ngIf="!isPortal && isIKdeviation">
            <div *ngIf="isFoodSafety" class="font-weight-bold" translate>
              SF - {{ "FOOD_SAFETY" | translate }}
            </div>
            <div *ngIf="isKubaControl" class="font-weight-bold" translate>
              KK - {{ "KUBA_Control" | translate }}
            </div>
          </div>
          <div class="col-md-3" *ngIf="!isPortal && isIKdeviation">
            <div *ngIf="isothersystem" class="font-weight-bold" translate>
              AS - {{ "OTHER_SYSTEMS" | translate }}
            </div>
            <div *ngIf="isqualitysystem" class="font-weight-bold" translate>
              KS - {{ "QUALITY_SYSTEMS" | translate }}
            </div>
          </div>
          <div class="col-md-3" *ngIf="!isPortal && isAHdeviation">
            <div *ngIf="isFramework" class="font-weight-bold" translate>
              RA - {{ "FRAMEWORK_AGREEMENT" | translate }}
            </div>
            <div *ngIf="isService" class="font-weight-bold" translate>
              SA - {{ "SERVICE_AGREEMENT" | translate }}
            </div>
          </div>
          <div class="col-md-3" *ngIf="!isPortal && isAHdeviation">
            <div *ngIf="isKunde" class="font-weight-bold" translate>
              EL - {{ "KUNDE_EL" | translate }}
            </div>
          </div>
        </div>
        <div class="footer-data">
          <div class="symbol-descriptions text-left">
            <div class="symbol-desc-title">
              <span translate>DESCRIPTION_SYMBOLS</span>
            </div>
            <div class="symbol-desc-items">
              <span class="new-deadline">
                <i class="icon ic-md icon-new-deadline"></i>
                <span translate>NEW_ISSUE</span>
              </span>
              <span class="far-deadline">
                <i class="icon ic-md icon-far-deadline"></i>
                <span translate>MORE_DEADLINE</span>
              </span>
              <span class="over-deadline">
                <i class="icon ic-md icon-over-deadline"></i>
                <span translate>OVERDEADLINE</span>
              </span>
              <span class="near-deadline">
                <i class="icon ic-md icon-near-deadline"></i>
                <span translate>LESS_DEADLINE</span>
              </span>
              <span class="clear-deadline">
                <i class="icon ic-md icon-clear-deadline"></i>
                <span translate>DONE_STATUS</span>
              </span>
              <span class="clear-deadline" style="color: red">
                <i class="icon ic-md icon-cancel"></i>
                <span translate>REJECTED</span>
              </span>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="15">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template
        pTemplate="emptymessage"
        let-columns
        *ngIf="isAdmin || isPortal"
      >
        <tr>
          <td colspan="13">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
      <!-- footerColumnGroup -->
      <ng-template pTemplate="groupheader" let-customer>
        <tr pRowGroupHeader>
          <td
            *ngIf="isAdmin"
            colspan="8"
            [style]="{ 'text-align': 'right', 'font-weight': 'bold' }"
          >
            {{ "TOTAL_COST" | translate }} :
          </td>
          <td ngIf="isAdmin" [style]="{ 'text-align': 'left' }">
            {{ pageSum }}
          </td>
          <td
            *ngIf="isEditor"
            colspan="10"
            [style]="{ 'text-align': 'right', 'font-weight': 'bold' }"
          >
            {{ "TOTAL_COST" | translate }}:
          </td>
          <td *ngIf="isEditor" [style]="{ 'text-align': 'left' }">
            {{ pageSum }}
          </td>
          <td
            *ngIf="!isAdmin && !isPortal && !isEditor"
            colspan="9"
            [style]="{ 'text-align': 'right', 'font-weight': 'bold' }"
          >
            {{ "TOTAL_COST" | translate }} :
          </td>
          <td
            *ngIf="!isAdmin && !isPortal && !isEditor"
            [style]="{ 'text-align': 'left' }"
          >
            {{ pageSum }}
          </td>
          <td
            *ngIf="isPortal"
            colspan="9"
            [style]="{ 'text-align': 'right', 'font-weight': 'bold' }"
          >
            {{ "TOTAL_COST" | translate }} :
          </td>
          <td *ngIf="isPortal" [style]="{ 'text-align': 'left' }">
            {{ pageSum }}
          </td>
          <td *ngIf="isAdmin || isPortal" colspan="4"></td>
          <td *ngIf="isEditor" colspan="3"></td>
          <td *ngIf="!isAdmin && !isPortal && !isEditor" colspan="2"></td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <p-dialog
    header="{{ 'ADD_CASE' | translate }}"
    [(visible)]="addtypeOfCase"
    [style]="{ width: '300px' }"
    [resizable]="false"
    [draggable]="false"
    (onHide)="onBeforeDeviationTypeofCaseDialogHide()"
  >
    <form [formGroup]="caseForm">
      <div class="form-group">
        <label for="title" class="col-form-label">
          <span translate>NAME</span>
        </label>
        <div>
          <input
            type="text"
            #Title
            formControlName="Case"
            class="label-input form-control"
          />
          <span style="color: red" *ngIf="isTypeCaseNameExists">{{
            existsTypeCaseAlerttext
          }}</span>
        </div>
      </div>
    </form>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="viewTypeOfCase = !viewTypeOfCase"
        >
          <span translate>VIEW</span>
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="!caseForm.valid || loading"
          [ngClass]="{ loading: loading }"
          (click)="saveTypeOfCase()"
        >
          <span *ngIf="!loading" translate>SAVE</span>
          <span *ngIf="loading" translate>SAVE_PROCESS</span>
          <span *ngIf="loading" class="loader-icon"></span>
        </button>
      </div>
      <div *ngIf="viewTypeOfCase">
        <div class="table-view">
          <!-- Table starts -->
          <p-table
            #Type
            [value]="editTypeOfCases"
            [rows]="10"
            [paginator]="true"
            [pageLinks]="3"
            [rowsPerPageOptions]="[5, 10, 20]"
            [responsive]="true"
          >
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th>{{ "TYPE_NAME" | translate }}</th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <input
                    pInputText
                    type="text"
                    (input)="
                      Type.filter(
                        $any($event.target)?.value,
                        'Name',
                        'contains'
                      )
                    "
                    placeholder=""
                    class="p-column-filter"
                  />
                </th>
                <th></th>
                <th></th>
              </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-Type>
              <tr>
                <td>
                  <span>{{ Type.Name }}</span>
                </td>
                <td class="col-button">
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'EDIT' | translate }}"
                    [disabled]="Type.IsDefault"
                    (click)="editTypeOfCase(Type)"
                  >
                    <i class="icon ic-sm icon-pencil-tip"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'DELETE' | translate }}"
                    [disabled]="Type.IsDefault"
                    (click)="deleteTypeOfCase(Type.Id)"
                  >
                    <i class="icon ic-sm icon-trash"></i>
                  </button>
                </td>
              </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td colspan="2">
                  {{ "NO_RECORDS_FOUND" | translate }}
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- Table Ends -->
        </div>
      </div>
    </p-footer>
  </p-dialog>
  <p-dialog
    header="{{ 'ADD_TOPIC' | translate }}"
    [(visible)]="addTopic"
    [style]="{ width: '300px' }"
    [resizable]="false"
    [draggable]="false"
    (onHide)="onBeforeDeviationTopicDialogHide()"
  >
    <form [formGroup]="topicForm">
      <div class="form-group">
        <label for="topic" class="col-form-label">
          <span translate>NAME</span>
        </label>
        <div>
          <input
            type="text"
            #topic
            class="label-input form-control"
            formControlName="Topic"
          />
          <span style="color: red" *ngIf="isTopicNameExists">{{
            existsTopicAlerttext
          }}</span>
        </div>
      </div>
    </form>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="viewTopic = !viewTopic"
        >
          <span translate>VIEW</span>
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [ngClass]="{ loading: loading }"
          [disabled]="!topicForm.valid || loading"
          (click)="saveTopic()"
        >
          <span *ngIf="!loading" translate>SAVE</span>
          <span *ngIf="loading" translate>SAVE_PROCESS</span>
          <span *ngIf="loading" class="loader-icon"></span>
        </button>
      </div>
      <div *ngIf="viewTopic">
        <div class="table-view">
          <!-- Table starts -->
          <p-table
            #Types
            [value]="editTopics"
            [rows]="10"
            [paginator]="true"
            [pageLinks]="3"
            [rowsPerPageOptions]="[5, 10, 20]"
            [responsive]="true"
          >
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th>{{ "TYPE_NAME" | translate }}</th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <input
                    pInputText
                    type="text"
                    (input)="
                      Types.filter(
                        $any($event.target)?.value,
                        'Name',
                        'contains'
                      )
                    "
                    placeholder=""
                    class="p-column-filter"
                  />
                </th>
                <th></th>
              </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-Type>
              <tr>
                <td>
                  <span>{{ Type.Name }}</span>
                </td>
                <td class="col-button">
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'EDIT' | translate }}"
                    (click)="editTopicType(Type)"
                  >
                    <i class="icon ic-sm icon-pencil-tip"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'DELETE' | translate }}"
                    (click)="deleteTopic(Type.Id)"
                  >
                    <i class="icon ic-sm icon-trash"></i>
                  </button>
                </td>
              </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td colspan="2">
                  {{ "NO_RECORDS_FOUND" | translate }}
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- Table Ends -->
        </div>
      </div>
    </p-footer>
  </p-dialog>
  <p-confirmDialog
    header="{{ 'CONFIRMATION' | translate }}"
    icon="pi pi-question-circle"
    width="425"
    acceptLabel="{{ 'SWITCH_YES' | translate }}"
    rejectLabel="{{ 'SWITCH_NO' | translate }}"
  ></p-confirmDialog>
  <toaster-component></toaster-component>
</div>
<!--Panel Loader-->
<div class="panel-loader" *ngIf="Showloader">
  <span>
    <span class="loader-icon"></span>
    <span translate>PROCESSING</span>
  </span>
</div>
<!--Panel Loader-->

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { InternalControl } from '../models/internal-control';
import { BaseServices } from './../../kuba.services';
import { InternalControlServices } from './internal-control.service';

@Injectable()
export class InternalControlResolver implements Resolve<InternalControl[]> {
    constructor(private backend: InternalControlServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<InternalControl[]> {
        let businessId = BaseServices.BusinessId;
        return this.backend.getAll(BaseServices.BusinessId);
    }
}

@Injectable()
export class InternalControlFeaturesResolver implements Resolve<any[]> {
    constructor(private backend: InternalControlServices) { }
    resolve(route: ActivatedRouteSnapshot): any {
        if (BaseServices.roleId === '3') {
            return this.backend.getInternalControlFeatures(BaseServices.BusinessId);
        }
    }
}

@Injectable()
export class InternalControlEditResolver implements Resolve<InternalControl[]> {
    constructor(private backend: InternalControlServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<InternalControl[]> {
        let businessId = BaseServices.BusinessId;
        return this.backend.getInternalControlById(+route.params['id']);
    }
}

@Injectable()
export class InternalControlByUserResolver implements Resolve<InternalControl[]> {
    constructor(private backend: InternalControlServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<InternalControl[]> {
        let businessId = BaseServices.BusinessId;
        return this.backend.getInternalByUser(BaseServices.UserId);
    }
}

@Injectable()
export class InternalControlNameResolver implements Resolve<InternalControl[]> {
    constructor(private backend: InternalControlServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<InternalControl[]> {
        let businessId = BaseServices.BusinessId;
        return this.backend.getInternalName(businessId);
    }
}


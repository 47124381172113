<div class="tab-content">
  <form
    [formGroup]="riskForm"
    (ngSubmit)="SaveRiskanalysis(riskForm.value)"
    #f="ngForm"
    novalidate
    name="form"
  >
    <div class="row">
      <div class="col-sm-4 section">
        <div class="page-title">
          <h4 translate>{{ title }}</h4>
        </div>
        <div class="form-group" *ngIf="riskForm.controls['Title']">
          <label for="lblIncidentStress" class="col-form-label">
            <span translate>INCIDENT_STRESS</span>
            <span class="required">*</span>
          </label>
          <div>
            <input
              type="text"
              [readonly]="isReadOnly"
              id="txtIncidentStress"
              formControlName="Title"
              pInputText
            />
          </div>
          <control-messages
            [control]="riskForm.controls['Title']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label for="lblCategory" class="col-form-label">
            <span translate>CATEGORY</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown
              [options]="Category"
              [disabled]="isReadOnly"
              type="text"
              [style]="{ width: '100%' }"
              formControlName="CategoryId"
            >
            </p-dropdown>
            <button
              class="btn btn-outline-primary"
              type="button"
              (click)="addCategory = !addCategory"
              *ngIf="isAdministrateRiskAnalysis"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="form-group" [hidden]="isSorting">
          <label class="col-form-label">
            <span translate>SORTING</span>
          </label>
          <div>
            <p-dropdown
              [options]="sorting"
              [disabled]="isReadOnly"
              type="text"
              [style]="{ width: '100%' }"
              formControlName="Sorting"
            >
            </p-dropdown>
          </div>
        </div>
        <div class="form-group">
          <label for="lblTopics" class="col-form-label">
            <span translate>TOPICS</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown
              [options]="Topics"
              [disabled]="isReadOnly"
              type="text"
              [style]="{ width: '100%' }"
              formControlName="TopicId"
            >
            </p-dropdown>
            <button
              class="btn btn-outline-primary"
              *ngIf="isAdministrateRiskAnalysis"
              type="button"
              (click)="addTopicType = !addTopicType"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="form-group">
          <label for="lblDepartment" class="col-form-label">
            <span translate>DEPARTMENT</span>
          </label>
          <div class="input-btn-group">
            <p-multiSelect
              [disabled]="isReadOnly"
              [options]="departments"
              formControlName="DepartmentId"
              [style]="{ width: '100%' }"
              defaultLabel="{{ 'CHOOSE' | translate }}"
            ></p-multiSelect>
            <control-messages
              [control]="riskForm.controls['DepartmentId']"
            ></control-messages>
            <button
              type="button"
              class="btn btn-outline-primary"
              *ngIf="isAdministrateRiskAnalysis"
              (click)="addDept = !addDept"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
        </div>

        <div class="form-group">
          <label for="lblLocation" class="col-form-label">
            <span translate>LOCATION</span>
          </label>
          <div>
            <input
              type="text"
              [readonly]="isReadOnly"
              id="txtLocation"
              formControlName="Location"
              pInputText
              name="Location"
            />
          </div>
        </div>

        <div class="form-group">
          <label for="lblParticipants" class="col-form-label">
            <span translate>PARTICIPANTS</span>
          </label>
          <div>
            <input
              type="text"
              [readonly]="isReadOnly"
              id="txtParticipants"
              formControlName="Participants"
              pInputText
            />
          </div>
        </div>
        <div class="form-group">
          <div *ngIf="IsEditRisk">
            <button
              id="btnViewlog"
              type="button"
              class="btn btn-outline-primary"
              (click)="showLog()"
            >
              <span translate>VIEW_LOG</span>
            </button>
          </div>
        </div>
        <div class="form-group">
          <div class="page-title">
            <span translate>RISK_ATTACHMENT</span>
          </div>
          <label for="File" class="col-form-label">
            <span translate>UPLOAD_FROM_ARCHIVE</span>
          </label>
          <div class="mbtm-10 flex-container" *ngIf="isArchive">
            <button
              type="button"
              class="btn btn-outline-secondary"
              (click)="showDocumentDailog()"
            >
              <span> {{ "BROWSE_ARCHIVE" | translate }} </span>
            </button>
          </div>
          <label for="File" class="col-form-label">
            <span translate>UPLOAD_FROM_PC</span>
          </label>
          <div>
            <ecpl-document-explorer
              [uploaderConfig]="upRiskConfig"
              [uploadedFiles]="uploadedRiskFiles"
              (saveChanges)="saveUploaded($event)"
            >
            </ecpl-document-explorer>
          </div>
        </div>
      </div>
      <div class="col-sm-4 section">
        <div class="page-title">
          <h4 translate>ASSESSMENT</h4>
        </div>
        <div class="form-group">
          <label for="lblWhathappend" class="col-form-label">
            <span translate>WHAT_CAN_HAPPEN</span>
          </label>
          <div>
            <textarea
              [readonly]="isReadOnly"
              type="text"
              id="txtariaWhatHappend"
              pInputTextarea
              formControlName="ExpectedBehaviour"
            >
            </textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="lblRelatedIncident" class="col-form-label">
            <span translate>RELATED_INCIDENT</span>
          </label>
          <div>
            <input
              type="text"
              [readonly]="isReadOnly"
              id="txtRelatedIncident"
              formControlName="RelatedIncident"
              pInputText
            />
          </div>
        </div>
        <div class="form-group">
          <label for="lblActionTaken" class="col-form-label">
            <span translate>ACTION_ALREADY_TAKEN</span>
          </label>
          <div>
            <textarea
              type="text"
              [readonly]="isReadOnly"
              id="txtariaActionTaken"
              formControlName="Alreadyactiontaken"
              pInputTextarea
            >
            </textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="lblProbability" class="col-form-label">
            <span translate>PROBABILITY_FIGURE</span>
          </label>
          <div>
            <p-dropdown
              [style]="{ width: '100%' }"
              [disabled]="isReadOnly"
              type="text"
              formControlName="Probability"
              (onChange)="probabilityChanged($event)"
              [options]="figureValue"
            ></p-dropdown>
          </div>
        </div>
        <div class="form-group">
          <label for="lblConsequence" class="col-form-label">
            <span translate>CONSEQUENCE_FIGURE</span>
          </label>
          <div>
            <p-dropdown
              [style]="{ width: '100%' }"
              [disabled]="isReadOnly"
              type="text"
              formControlName="Consequence"
              [options]="figureValue"
              (onChange)="consequenceChanged($event)"
            ></p-dropdown>
          </div>
        </div>
        <div class="form-group" *ngIf="riskData">
          <label for="lblRisk" class="col-form-label">
            <span translate>RISK_FIGURE</span>
          </label>
          <div>
            <input
              [readonly]="isReadOnly"
              type="text"
              [style.background-color]="riskData.backColor"
              pInputText
              class="ex-full-width"
              [value]="riskData.value | translate"
            />
          </div>
        </div>
      </div>
      <!--Action plan-New Actions-->
      <div class="col-sm-4 section">
        <div class="page-title">
          <h4 translate>ACTION_PLAN</h4>
        </div>
        <form
          [formGroup]="actionplanForm"
          (ngSubmit)="SaveRiskanalysis(riskForm.value)"
          #f="ngForm"
          novalidate
          name="form"
        >
          <div class="form-group" *ngIf="isActionExist">
            <label translate>STATUS</label>
            <div>
              <div class="radio-inline" *ngFor="let option of options">
                <label>
                  <input
                    type="radio"
                    [readonly]="isReadOnly"
                    formControlName="Status"
                    [value]="option.id"
                    [checked]="isChecked(option.id)"
                    (click)="onStatusChanged(option.name)"
                  />{{ option.name | translate }}
                </label>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="actionplanForm.controls['Title']">
            <label for="lblActionName" class="col-form-label">
              <span translate>ACTION_NAME</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                [readonly]="isReadOnly"
                id="txtActionName"
                type="text"
                formControlName="Title"
                pInputText
              />
            </div>
            <control-messages
              [control]="actionplanForm.controls['Title']"
            ></control-messages>
          </div>
          <div class="form-group">
            <label for="lblDescription" class="col-form-label">
              <span translate>DESCRIPTION</span>
            </label>
            <div>
              <textarea
                [readonly]="isReadOnly"
                id="txtDescription"
                formControlName="Description"
                pInputTextarea
              >
              </textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="lblGoal" class="col-form-label" translate>GOAL</label>
            <div>
              <input
                [readonly]="isReadOnly"
                id="txtGoal"
                formControlName="Goal"
                pInputText
              />
            </div>
          </div>
          <div class="form-group">
            <label for="lblEstimatedCost" class="col-form-label" translate
              >ESTIMATED_COST</label
            >
            <div>
              <input
                [readonly]="isReadOnly"
                id="txtEstimatedCost"
                formControlName="EstimatedCost"
                pInputText
                name="EstimatedCost"
                (keypress)="onCostChange($event)"
                type="number"
              />
            </div>
          </div>
          <div class="form-group" formGroupName="days">
            <label for="lblDeadLine" class="col-form-label">
              <span translate>DEADLINE</span>
              <span class="required">*</span>
            </label>
            <div>
              <my-date-picker
                [disabled]="isReadOnly"
                name="deadline"
                [options]="dateOptions"
                formControlName="Deadline"
                placeholder="{{ 'SELECT_DEADLINE_DATE' | translate }}"
                required
                (dateChanged)="onDeadLineChanged($event)"
              >
              </my-date-picker>
            </div>
          </div>
          <div class="form-group" *ngIf="actionplanForm.controls['Execution']">
            <label for="execution" class="col-form-label">
              <span translate>RESPONSIBLE_PERSON</span>
              <span class="required">*</span>
            </label>
            <div>
              <p-multiSelect
                [disabled]="isReadOnly"
                [options]="executions"
                formControlName="Execution"
                [style]="{ width: '100%' }"
                defaultLabel="{{ 'CHOOSE' | translate }}"
              >
              </p-multiSelect>
            </div>
            <control-messages
              [control]="actionplanForm.controls['Execution']"
            ></control-messages>
          </div>
          <div formGroupName="days" class="form-group">
            <label for="lblNotification" class="col-form-label">
              <span translate>NOTIFICATION_BEFORE_DEADLINE </span>
            </label>
            <div>
              <p-dropdown
                [options]="notifyDaysBeforeDeadlineOptions"
                [formControl]="
                  actionplanForm.get('days')?.get('NotifyBeforeDeadLine')
                "
                [style]="{ width: '100%' }"
                [ngClass]="{
                  'ng-invalid':
                    actionplanForm.get('days.NotifyBeforeDeadLine').touched &&
                    actionplanForm
                      .get('days')
                      .hasError('notificationDaysInvalid')
                }"
              ></p-dropdown>
              <div
                *ngIf="
                  actionplanForm.get('days.NotifyBeforeDeadLine').touched &&
                  actionplanForm.get('days').hasError('notificationDaysInvalid')
                "
              >
                <span class="text-danger">{{
                  "NOTIFICATION_DAYS_INVALID" | translate
                }}</span>
              </div>
            </div>
          </div>
          <control-messages
            [control]="actionplanForm.controls['days']['NotifyBeforeDeadLine']"
          >
          </control-messages>
          <div class="form-group">
            <label for="follow-up" class="col-form-label">
              <span translate>FOLLOWUP_PERSON</span>
              <span class="required">*</span>
            </label>
            <div>
              <p-multiSelect
                [disabled]="isReadOnly"
                [options]="followUps"
                formControlName="Followup"
                [style]="{ width: '100%' }"
                defaultLabel="{{ 'CHOOSE' | translate }}"
              >
              </p-multiSelect>
            </div>
            <control-messages
              [control]="actionplanForm.controls['Followup']"
            ></control-messages>
          </div>

          <div formGroupName="days" class="form-group">
            <label for="lblNotification" class="col-form-label">
              <span translate>NOTIFICATION_BELOW_FOLLOWUP</span>
            </label>
            <div>
              <p-dropdown
                [disabled]="isReadOnly"
                [options]="FollowUpDays"
                [style]="{ width: '100%' }"
                [formControl]="
                  actionplanForm.get('days')?.get('NotifyBeforeFollowUp')
                "
              ></p-dropdown>
            </div>
          </div>
          <div class="form-group text-right">
            <button
              type="button"
              *ngIf="isAdministrateRiskAnalysis"
              class="btn btn-primary"
              (click)="addAndNewAction()"
              [disabled]="!actionplanForm.valid"
              translate
            >
              SAVE_ACTION
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              (click)="clearTask()"
            >
              <span translate>REFRESH</span>
            </button>
          </div>
        </form>
        <div class="form-group">
          <div class="page-title">
            <span translate>PLANNED_ACTIONS</span>
          </div>
        </div>
        <div class="table-view">
          <!-- Table starts -->
          <p-table
            [value]="actionPlanList"
            [rows]="10"
            [paginator]="true"
            [pageLinks]="3"
            [rowsPerPageOptions]="[5, 10, 20]"
            [responsive]="true"
          >
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th>{{ "ACTION_NAME" | translate }}</th>
                <th>{{ "DEADLINE" | translate }}</th>
                <th>{{ "STATUS" | translate }}</th>
                <th>{{ "RESPONSIBLE" | translate }}</th>
                <th>{{ "ATTACHMENT" | translate }}</th>
                <th *ngIf="isAdministrateRiskAnalysis"></th>
              </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-risk let-i="rowIndex">
              <tr>
                <td>
                  <a
                    (click)="editActivity(risk, i)"
                    routerLinkActive="active"
                    href="javascript:void(0);"
                    >{{ risk.Title }}</a
                  >
                </td>
                <td>
                  <span>{{ risk.Deadline | date : "dd/MM/yyyy" }}</span>
                </td>
                <td>
                  <span>{{ risk.StatusText | translate }}</span>
                </td>
                <td>
                  <span>{{ risk.ExecutionUserName }}</span>
                </td>
                <td>
                  <a href="{{ risk.DocumentType }}">
                    <span
                      *ngIf="risk.DocumentType"
                      class="icon ic-xs icon-xls"
                    ></span>
                  </a>
                </td>
                <td class="col-button" *ngIf="isAdministrateRiskAnalysis">
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'DELETE' | translate }}"
                    (click)="deleteActivity(risk, i)"
                  >
                    <i class="icon ic-sm icon-trash"></i>
                  </button>
                </td>
              </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template
              pTemplate="emptymessage"
              *ngIf="isAdministrateRiskAnalysis"
              let-columns
            >
              <tr>
                <td colspan="5">
                  {{ "NO_RECORDS_FOUND" | translate }}
                </td>
              </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td colspan="6">
                  {{ "NO_RECORDS_FOUND" | translate }}
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- Table Ends -->
        </div>
      </div>
    </div>
    <div class="action-btns-wrapper">
      <div class="row">
        <div class="col-sm-6">
          <button
            type="button"
            class="btn btn-outline-secondary"
            (click)="gotoList()"
            *ngIf="isVisibleSave"
          >
            <span class="icon ic-xs icon-back"></span>
            <span translate>BACK_LIST</span>
          </button>
          <button
            type="button"
            class="btn btn-primary"
            [ngClass]="{ loading: loading }"
            (click)="saveRisk('NOTNEW')"
            *ngIf="isAdministrateRiskAnalysis"
            [disabled]="!riskForm.valid || loading"
            style="font-size: smaller"
          >
            <span *ngIf="!loading" translate>SAVE</span>
            <span *ngIf="loading" translate>SAVE_PROCESS</span>
            <span *ngIf="loading" class="loader-icon"></span>
          </button>
          <button
            class="btn btn-primary"
            type="button"
            [ngClass]="{ loading: loadingForNewRisk }"
            (click)="saveRisk('NEWRISK')"
            *ngIf="isAdministrateRiskAnalysis"
            style="font-size: smaller"
            [disabled]="!riskForm.valid || loadingForNewRisk"
            pTooltip="{{ 'SAVE_AND_ADD_NEW_RISK' | translate }}"
            tooltipPosition="top"
          >
            <span *ngIf="!loadingForNewRisk" translate>SAVE_NEW_RISK</span>
            <span *ngIf="loadingForNewRisk" translate>SAVE_PROCESS</span>
            <span *ngIf="loadingForNewRisk" class="loader-icon"></span>
          </button>
          <button
            class="btn btn-primary"
            *ngIf="buttonVisible && isAdministrateRiskAnalysis"
            style="font-size: smaller"
            [ngClass]="{ loading: loadingForSaveAndActivity }"
            type="button"
            (click)="saveAndAddTask()"
            [disabled]="!riskForm.valid || loadingForSaveAndActivity"
            pTooltip="{{ 'SAVE_AND_ADD_NEW_ACTIVITY' | translate }}"
            tooltipPosition="top"
          >
            <span *ngIf="!loadingForSaveAndActivity" translate
              >SAVE_ACTIVITY</span
            >
            <span *ngIf="loadingForSaveAndActivity" translate
              >SAVE_PROCESS</span
            >
            <span *ngIf="loadingForSaveAndActivity" class="loader-icon"></span>
          </button>
        </div>
        <div class="col-sm-6 text-right">
          <button
            type="submit"
            class="btn btn-outline-primary"
            (click)="exportPdf()"
            [ngClass]="{ loadingReport: loadingReport }"
          >
            <span class="icon ic-sm icon-print"></span>
            <span *ngIf="!loadingReport" translate>PRINT_PAGE</span>
            <span *ngIf="loadingReport" translate>PROCESSING</span>
            <span *ngIf="loadingReport" class="loader-icon"></span>
          </button>
        </div>
      </div>
    </div>
    <div class="row mtop-30">
      <div class="col-md-12">
        <staticdata id="staticData"></staticdata>
      </div>
    </div>
  </form>
</div>
<p-dialog
  header="{{ 'ADD_DEPARTMENT' | translate }}"
  [(visible)]="addDept"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
  (onHide)="onBeforeRiskEditDeptDialogHide()"
>
  <form [formGroup]="RiskEditDeptPopUp">
    <div class="form-group">
      <label for="Department" class="col-form-label">
        <span translate>DEPARTMENT</span>
      </label>
      <div>
        <input
          type="text"
          formControlName="department"
          #Name
          class="label-input form-control"
        />
        <span style="color: red" *ngIf="isdepartmentExists">{{
          departmentExisting
        }}</span>
        <span style="color: red" *ngIf="isdepartmentAlert">{{
          departmentAlerttext
        }}</span>
      </div>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="viewType = !viewType"
      >
        <span translate>VIEW</span>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!RiskEditDeptPopUp.valid"
        (click)="saveDept()"
      >
        <span translate>SAVE</span>
      </button>
    </div>
    <div *ngIf="viewType">
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          [value]="editDepartments"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "TYPE_NAME" | translate }}</th>
              <th>
                <span translate>ACTIONS</span>
              </th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-risk let-i="rowIndex">
            <tr>
              <td>
                <span>{{ risk.Name }}</span>
              </td>
              <td class="col-button">
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="editDeptType(risk)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deleteDept(risk)"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends -->
      </div>
    </div>
  </p-footer>
</p-dialog>
<p-dialog
  header="{{ 'ADD_TOPIC' | translate }}"
  [(visible)]="addTopicType"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
  (onHide)="onBeforeRiskEditTopicDialogHide()"
>
  <form [formGroup]="RiskEditTopicPopUp">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>TOPICS</span>
      </label>
      <div>
        <input
          type="text"
          class="label-input form-control"
          formControlName="topic"
        />
        <span style="color: red" *ngIf="istopicNameExists">{{
          topicExisting
        }}</span>
        <span style="color: red" *ngIf="istopicExists">{{
          topicAlerttext
        }}</span>
      </div>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="viewTopicType = !viewTopicType"
      >
        <span translate>VIEW</span>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!RiskEditTopicPopUp.valid"
        (click)="saveTopic()"
      >
        <span translate>SAVE</span>
      </button>
    </div>
    <div *ngIf="viewTopicType">
      <div class="table-view">
        <!--  Table Starts-->
        <p-table
          [value]="editTopics"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "TYPE_NAME" | translate }}</th>
              <th>
                <span translate>ACTIONS</span>
              </th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-risk>
            <tr>
              <td>
                <span>{{ risk.Name }}</span>
              </td>
              <td class="col-button">
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="editTopicType(risk)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deleteTopic(risk)"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends -->
      </div>
    </div>
  </p-footer>
</p-dialog>
<p-dialog
  header="{{ 'RA_LOG_SUMMARY' | translate }}"
  [(visible)]="showViewLog"
  [style]="{ width: '500px', height: '400px' }"
  [resizable]="false"
  [draggable]="false"
>
  <div class="wb-content" style="height: auto">
    <div class="default-list">
      <div class="list-item">
        <span translate>CREATED_BY</span> {{ createdby }}
      </div>
      <div class="list-item">
        <span translate>CREATED_ON</span> {{ createdon }}
      </div>
      <div class="list-item"><span translate>TIME</span> {{ time }}</div>
    </div>
  </div>
  <div class="table-view">
    <!-- Table Starts-->
    <p-table
      [value]="activityLogData"
      [rows]="5"
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
      [responsive]="true"
    >
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th>{{ "MODIFIED_FIELD" | translate }}</th>
          <th>{{ "PREVIOUS_VALUE" | translate }}</th>
          <th>{{ "MODIFIED_BY" | translate }}</th>
          <th>{{ "MODIFIED_ON" | translate }}</th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-risk>
        <tr>
          <td>
            <span>{{ risk.FieldName }}</span>
          </td>
          <td>
            <span>{{ risk.OldValue }}</span>
          </td>
          <td>
            <span>{{ risk.Modifier }}</span>
          </td>
          <td>
            <span>{{ risk.CreatedOn | date : "dd/MM/yyyy" }}</span>
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="4">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table Ends -->
  </div>
</p-dialog>
<p-dialog
  header="{{ 'UPLOAD' | translate }}"
  [(visible)]="showArchiveDocumentDailog"
  [style]="{ width: '500px', height: '400px' }"
  [resizable]="false"
  [draggable]="false"
>
  <div style="max-height: 300px" class="mbtm-10">
    <app-ecpl-document-tree
      *ngIf="treeNode"
      treeId="ArchiveDocumentsTree"
      [(ecplTreeData)]="treeNode"
      [(ecplTreeCheckbox)]="editMode"
      (ecplNodeEvent)="catchEvent($event)"
      (ecplNodeEvent)="catchTreeEvent($event)"
    >
    </app-ecpl-document-tree>
  </div>
</p-dialog>
<br />
<toaster-component></toaster-component>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>

<!--Panel Loader-->
<div
  class="panel-loader"
  *ngIf="loading || loadingForNewRisk || loadingForSaveAndActivity"
></div>
<!--Panel Loader-->
<div class="panel-loader" *ngIf="loadingForAttachment">
  <span>
    <span class="loader-icon"></span>
    <span>Processing...</span>
  </span>
</div>
<p-dialog
  header="{{ 'NOTIFICATION' | translate }}"
  [(visible)]="showNotificationForTask"
  width="600"
  [responsive]="true"
>
  <span translate>ACTION_TO_SAVE</span>
</p-dialog>
<p-dialog
  header="{{ 'ADD_CATEGORY' | translate }}"
  [(visible)]="addCategory"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
>
  <form [formGroup]="RiskCategoryPopUp">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>CATEGORY</span>
      </label>
      <div>
        <input
          type="text"
          class="label-input form-control"
          formControlName="category"
        />
        <span style="color: red" *ngIf="iscategoryExists">{{
          categoryExisting | translate
        }}</span>
        <span style="color: red" *ngIf="isCategoryExistsInAnotherRA">{{
          categoryAlert | translate
        }}</span>
      </div>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="viewCategory = !viewCategory"
      >
        <span translate>VIEW</span>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!RiskCategoryPopUp.valid"
        (click)="saveCategory()"
      >
        <span translate>SAVE</span>
      </button>
    </div>
    <div *ngIf="viewCategory">
      <div class="table-view">
        <!-- Table Starts-->
        <p-table
          [value]="editCategory"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "TYPE_NAME" | translate }}</th>
              <th>
                <span translate>ACTIONS</span>
              </th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-risk>
            <tr>
              <td>
                <span>{{ risk.Name | translate }}</span>
              </td>
              <td class="col-button">
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="editCategoryName(risk)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deleteCategory(risk)"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends-->
      </div>
    </div>
  </p-footer>
</p-dialog>
<p-dialog
  header="{{ 'NOTIFICATION' | translate }}"
  [style]="{ width: '450px', height: '100px' }"
  [resizable]="false"
  [draggable]="false"
  [(visible)]="ActionPlanInfo"
  [responsive]="true"
>
  <span translate>ACTION_PLAN_INFO</span>
</p-dialog>

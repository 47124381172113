import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FollowUpMessageService {

    constructor(private http: HttpClient) { }

    /**
     * get all roles except role 6&7
     */
    list() {
        return this.http
            .get(`${environment.BASE_URL}/getallusers`, )
            .map(res => res);
    }

    sendMail(mailData:any) {
        return this.http
            .post(
                environment.BASE_URL + '/followupmessage/sendmessage',
                mailData,

            )
            .map((response: any) => {
                return response;
            });
    }

    getMessages(userId: number) {
        return this.http
            .get(
                environment.BASE_URL +
                '/followupmessage/getinboxmessage/' +
                userId,
            )
            .map((res: any) => res);
    }

    getOutBoxMessages(userId: number) {
        return this.http
            .get(
                environment.BASE_URL +
                '/followupmessage/getoutboxmessage/' +
                userId,
            )
            .map((res: any) => res);
    }

    deleteMessages(ids:any) {
        return this.http
            .put(
                environment.BASE_URL + '/followupmessage/deletemessage',
                ids,

            )
            .map(result => result);
    }

    getMessageById(id:any) {
        return this.http
            .get(environment.BASE_URL + '/followupmessage/getMessage/' + id, )
            .map((res: any) => res);
    }

    getToListForBusinessUnderAdmin() {
        let result: any = this.http
        .get(`${environment.BASE_URL}/to-users-businessunderadmin/` + BaseServices.BusinessId + '/' + BaseServices.roleId,
            )
        .map((res: any) => res);
        return result;
    }

    getToListForBusinessUnderPortal() {
        return this.http
            .get(`${environment.BASE_URL}/to-users-businessunderportal`,
                )
            .map((res: any) => res);
    }

    getToListForPortal() {
        return this.http
            .get(`${environment.BASE_URL}/to-users-portal`, )
            .map((res: any) => res);
    }

    updateStatus(id:any) {
        return this.http
            .put(
                environment.BASE_URL + '/followupmessage/updatestatus',
                id,

            )
            .map(result => result);
    }

    getAdminAndPortal() {
        return this.http
            .get(`${environment.BASE_URL}/getadminandportal`, )
            .map((res: any) => res);
    }
}

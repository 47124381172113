
import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { BusinessUserEditResolver, BusinessListResolver } from './../businesses/services/business.resolver.service';
import {
    UserEditResolver,
    UserRoleResolver
} from './../users/services/user.resolver.service';
import { UserService } from './../users/services/user.service';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from './../shared/appShared.module';
import { ContactComponent } from './contact.component';
import { ContactServices } from './services/contact.service';
import { TranslateModule } from '@ngx-translate/core';
import {
    ContactEditResolver,
    ContactListResolver,
    ContactTypeResolver,
    FunctionListResolver,
    CountryCodeListResolver,
    CustomerContactsWithLoginResolver,
    CustomerContactsWithResolver,
    AdminContactsResolver
} from './services/contact.resolver.service';

import {
    CustomerContactLoginComponent,
    CustomerContactComponent,
    ContactListComponent,
    OurContactComponent,
    ContactEditComponent,
    ContactNewComponent,
    ContactFormComponent
} from './components/index';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

@NgModule({
    imports: [
        AppSharedModule,
        RouterModule,
        TranslateModule,
        ConfirmDialogModule,
        KubaExportModule
    ],
    exports: [
        ContactComponent,
        CustomerContactLoginComponent,
        CustomerContactComponent,
        ContactListComponent,
        OurContactComponent,
        ContactEditComponent,
        ContactFormComponent,
        ContactNewComponent
    ],
    declarations: [
        ContactComponent,
        CustomerContactLoginComponent,
        CustomerContactComponent,
        ContactListComponent,
        OurContactComponent,
        ContactEditComponent,
        ContactNewComponent,
        ContactFormComponent
    ],
    providers: [
        ContactServices,
        ContactListResolver,
        ContactEditResolver,
        ValidationService,
        ConfirmationService,
        UserService,
        UserEditResolver,
        ContactTypeResolver,
        FunctionListResolver,
        CountryCodeListResolver,
        CustomerContactsWithLoginResolver,
        CustomerContactsWithResolver,
        BusinessUserEditResolver,
        UserRoleResolver,
        AdminContactsResolver,
        BusinessListResolver
    ]
})
export class ContactModule { }

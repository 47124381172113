import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FoodSafetyList } from 'src/app/kuba/foodsafety/models';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { FoodSafetyManualCompleteChecklist } from 'src/app/kuba/shared/models/complete-checklist';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { Rights } from 'src/app/_models/feature';
import { ConfirmationService } from 'primeng/api';
import { KubaControlService } from '../../services/kuba-control.service';

@Component({
  selector: 'app-kuba-control-header',
  templateUrl: './kuba-control-header.component.html'
})
export class KubaControlHeaderComponent implements OnInit {
  isKcRights = true;
  hideKcRights = true;
  @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
  FoodSafety: FoodSafetyList[];
  FoodSafetyManualCompleteChecklist: FoodSafetyManualCompleteChecklist;
  additionalData: any;
  isShowUser = true;
  constructor(
    public kubaControlService: KubaControlService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.router.navigate(['all'], { relativeTo: this.route })
    if (+BaseServices.roleId === 4) {
      this.isKcRights = false;
      this.router.navigate(['own'], { relativeTo: this.route })
    }
    else if (+BaseServices.roleId === 5) {
      this.onKubaControlRights();
    }
    else {
      this.OnAdmin()
    }

  }


  onKubaControlRights() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.ADMINISTRATE_KUBA_CONTROL;
    this.isKcRights = BaseServices.checkUserRights(userRightsId, currentUserRole);
    if (this.isKcRights) {
      this.router.navigate(['all'], { relativeTo: this.route })
    } else {
      this.router.navigate(['own'], { relativeTo: this.route })
    }
  }
  OnAdmin() {
    let curSession = JSON.parse(sessionStorage.getItem('RModeBusinessId'));
    if (curSession > 0) {
      this.isKcRights = true;
      this.hideKcRights = false;
      this.router.navigate(['all'], { relativeTo: this.route });
    }
    else {
      this.isKcRights = true;
      this.router.navigate(['all'], { relativeTo: this.route });
    }
  }

}

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { BaseServices } from './../../kuba.services';
import { KubaControlService } from './kuba-control.service';
import { BusinessDepartmentService } from './../../business-department.services';
import { FoodSafetyList } from 'src/app/kuba/foodsafety/models';

@Injectable()
export class KubaControlListResolver implements Resolve<FoodSafetyList[]> {
    constructor(private backend: KubaControlService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodSafetyList[]> {
        return this.backend.getAllByBusinessId(BaseServices.BusinessId);
    }
}
    
@Injectable()
export class FoodSafetyListResolver implements Resolve<FoodSafetyList[]> {
    constructor(private backend: KubaControlService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodSafetyList[]> {
        return this.backend.getAllByBusinessId(BaseServices.BusinessId);
    }
}
@Injectable()
export class FoodSafetyEditResolver implements Resolve<FoodSafetyList[]> {
    constructor(private backend: KubaControlService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodSafetyList[]> {
        if (+route.params['id'] > 0) {
            return this.backend.getFoodsafetyById(+route.params['id']);
        } else { return null; }
    }

}
@Injectable()
export class ZoneListResolver implements Resolve<FoodSafetyList[]> {
    constructor(private backend: KubaControlService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodSafetyList[]> {
        return this.backend.getZoneName(BaseServices.BusinessId);
    }

}

@Injectable()
export class ObjectTypeListResolver implements Resolve<FoodSafetyList[]> {
    constructor(private backend: KubaControlService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodSafetyList[]> {
        return this.backend.getTypeOfObject(BaseServices.BusinessId);
    }
}
@Injectable()
export class DepartmentResolver implements Resolve<FoodSafetyList[]> {
    constructor(private backend: BusinessDepartmentService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodSafetyList[]> {
        return this.backend.getCachedBusinessDepartment();
    }

}

@Injectable()
export class FoodsafetyFeaturesResolver implements Resolve<any[]> {
    constructor(private backend: KubaControlService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.backend.getFoodsafetyFeatures(BaseServices.BusinessId);
    }
}

@Injectable()
export class FoodSafetyListByUserResolver implements Resolve<FoodSafetyList[]> {
    constructor(private backend: KubaControlService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodSafetyList[]> {
        return this.backend.getFoodByUser(BaseServices.UserId);
    }
}
@Injectable()
export class FoodSafetyZonesResolver implements Resolve<FoodSafetyList[]> {
    constructor(private backend: KubaControlService) { }
    resolve(): Observable<FoodSafetyList[]> {
        return this.backend.getZonesbyBusiness();
    }
}
@Injectable()
export class FSDailyTaskListResolver implements Resolve<FoodSafetyList[]> {
    constructor(private backend: KubaControlService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodSafetyList[]> {
        return this.backend.getFSDailyTaskList(+route.params['Id']);
    }
}
@Injectable()
export class FSAuditListResolver implements Resolve<any[]> {
    constructor(private backend: KubaControlService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodSafetyList[]> {
        let MinDate = null;
        let MaxDate = null;
        return this.backend.foodSafetyAuditList(+route.params['Id'], MinDate, MaxDate, false);
    }
}

@Injectable()
export class HazardAnalysisListResolver implements Resolve<any[]> {
    constructor(private backend: KubaControlService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getHazardAnalysis(+route.params['Id']);
    }
}

@Injectable()
export class FSAuditDateListResolver implements Resolve<any[]> {
    constructor(private backend: KubaControlService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodSafetyList[]> {
        return this.backend.foodSafetyAuditDateList(+route.params['Id']);
    }
}
@Injectable()
export class ToDoListResolver implements Resolve<any[]> {
    constructor(private backend: KubaControlService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getAllApprovedForKubaControlToDoList(+route.params['Id']);
    }
}

@Injectable()
export class ToDoDocumentResolver implements Resolve<any[]> {
    constructor(private backend: KubaControlService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getTodoDocumnet(+route.params['Id'],BaseServices.UserId);
    }
}
<div class="row" *ngIf="treeData.isEditMode">
    <div class="col-sm-12">
        <div class="upload-file-container">
            <label>
                <span translate>Upload Manual Image</span>
            </label>
            <div class="upload-actions">
                <label class="btn btn-outline-secondary upload-button">
                    <input type="file" ngFileSelect> Browse
                </label>
            </div>
            <div class="upload-items">
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="company-image">
            <img class="img-fluid" src="assets/images/KubaManual.JPG" alt="Manual Image">
        </div>
    </div>
</div>
<div class="table-view">
    <!-- Table starts -->
    <p-table #dt [value]="loglist" dataKey="LogOn" [rows]="10" [paginator]="true" [pageLinks]="3"
      [rowsPerPageOptions]="[5,10,20]" expandableRows="true"
      [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th></th>
                <th>{{'LOGIN_DATE' | translate}}</th>
                <th>{{'LOGIN_COUNT'|translate}}</th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-log let-expanded="expanded" let-i="rowIndex">
            <tr>
                <td width='60px' *ngIf="roleId">
                    <button type="button" class="btn" (click)="dt.toggleRow(log)">
                        <span class="icon ic-sm icon-expand-arrow" aria-hidden="true" *ngIf="!setCollapse(log)"
                          (click)="toggleClick(log)"></span>
                        <span class="icon ic-sm icon-collapse-arrow" aria-hidden="true" *ngIf="setCollapse(log)"
                          (click)="toggleClickHide(i)"></span>
                    </button>
                </td>
                <td><span>{{log.LogOn | date:'dd/MM/yyyy'}}</span></td>
                <td><span>{{log.LoginCount}}</span></td>
            </tr>
        </ng-template>
        <!-- rowexpansion  -->
        <ng-template pTemplate="rowexpansion" let-row>
            <tr>
                <td colspan="3">
                    <!-- Table starts -->
                    <p-table [value]="loginUsername" dataKey="UserName">
                        <!-- Header -->
                        <ng-template pTemplate="header">
            <tr>
                <th>{{'USERNAME' | translate}}</th>
                <th>{{'LOGIN_COUNT' | translate}}</th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-userlog>
            <tr>
                <td>
                    <span translate>{{userlog.UserName}}</span>
                </td>
                <td>
                    <span translate>{{userlog.LoginCount}}</span>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table ends -->
    </td>
    </tr>
    </ng-template>
    </p-table>
    <!-- Table ends -->
</div>

<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { KubaArticleViewerService } from 'src/app/shared/ecpl-article-viewer/ecpl-article-viewer.service';
import { ElectroDocumentService } from '../services/electro-document.services';
import { ElectroService } from '../services/electro.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { Subject } from 'rxjs/Subject';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ArticleModel } from 'src/app/shared/ecpl-article-viewer/interfaces';
import { Rights, Roles } from 'src/app/_models';
import { ElectroFeature } from '../models/electrofeatures';
import { ElectroOrgChartData } from '../models/electroinstallation';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { IMyDpOptions } from 'mydatepicker';
import { HelperService } from 'src/app/_services/helper.service';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
//#region org chart
const iframeCode = `
<!doctype html>
<html lang="en">
<head>
  <meta charset="utf-8">
  <title>UmlApp</title>
  <base href="/">

  <meta name="viewport" content="width=device-width, initial-scale=1">
	<link rel="icon" type="image/x-icon" href="favicon.ico">
	<link rel="stylesheet" type="text/css" id="appStyle" #appStyle href="./assets/styles/themes/default/style.min.css">

  <link rel="stylesheet" type="text/css" href="./assets/mxgraph/styles/grapheditor.css">
  <style>*, *:after, *:before {-webkit-box-sizing: content-box;-moz-box-sizing: content-box;box-sizing: content-box;}</style>
	<script type="text/javascript">
		// Parses URL parameters. Supported parameters are:
		// - lang=xy: Specifies the language of the user interface.
		// - touch=1: Enables a touch-style user interface.
		// - storage=local: Enables HTML5 local storage.
        // - chrome=0: Chromeless mode.
        var universalGraph;
		var urlParams = (function(url)
		{
			var result = new Object();
			var idx = url.lastIndexOf('?');

			if (idx > 0)
			{
				var params = url.substring(idx + 1).split('&');

				for (var i = 0; i < params.length; i++)
				{
					idx = params[i].indexOf('=');

					if (idx > 0)
					{
						result[params[i].substring(0, idx)] = params[i].substring(idx + 1);
					}
				}
			}

			return result;
		})(window.location.href);

		// Default resources are included in grapheditor resources
		mxLoadResources = false;
	</script>
	<script type="text/javascript" src="./assets/mxgraph/js/Init.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/jscolor/jscolor.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/sanitizer/sanitizer.min.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/src/js/mxClient.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/EditorUi.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/Editor.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/Sidebar.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/Graph.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/Shapes.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/Actions.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/Menus.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/Format.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/Toolbar.js"></script>
	<script type="text/javascript" src="./assets/mxgraph/js/Dialogs.js"></script>
</head>
<body>
text
  <script type="text/javascript">
		// Extends EditorUi to update I/O action states based on availability of backend
		(function()
		{
			var bundle = mxResources.getDefaultBundle(RESOURCE_BASE, mxLanguage) ||
				mxResources.getSpecialBundle(RESOURCE_BASE, mxLanguage);

			// Fixes possible asynchronous requests
			mxUtils.getAll([bundle, STYLE_PATH + '/default.xml'], function(xhr)
			{
				// Adds bundle text to resources
				mxResources.parse(xhr[0].getText());

				// Configures the default graph theme
				var themes = new Object();
				themes[Graph.prototype.defaultThemeName] = xhr[1].getDocumentElement();

				// Main
                new EditorUi(new Editor(urlParams['chrome'] == '0', themes));
                var chartXmlData = JSON.parse(sessionStorage.getItem('orgchartxml'));
                if(chartXmlData != null && chartXmlData.length < MAX_REQUEST_SIZE)
                {
                    uploadXML(chartXmlData);
                }
			}, function()
			{
				document.body.innerHTML = '<center style="margin-top:10%;">Error loading resource files. Please check browser console.</center>';
			});
        })();
        function uploadXML(xml){
		let doc = mxUtils.parseXml(xml);
		let codec = new mxCodec(doc);
		codec.decode(doc.documentElement, universalGraph.getModel());
		let elt = doc.documentElement.firstChild;
		let cells = [];
		while (elt != null)
		{
			let cell = codec.decode(elt)
			if(cell != undefined){
				if(cell.id != undefined && cell.parent != undefined && (cell.id == cell.parent)){
					elt = elt.nextSibling;
					continue;
				}
				cells.push(cell);
			}
			elt = elt.nextSibling;
		}
		universalGraph.addCells(cells);
        }
	</script>
</body>
</html>`
//#endregion

@Component({
    selector: 'electro-organization',
    templateUrl: 'electro-organization.component.html',
    styleUrls: ['electro-organization.component.css']
})

export class ElectroOrganizationComponent implements OnInit {

    data$: Subject<string> = new Subject();
    @ViewChild('iframe',{static: false}) iframe: ElementRef;
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @ViewChild('electroTable',{static: false}) orgTable: Table;
    @ViewChild('hiddenAction',{static: false}) hiddenAction: ElementRef;
    chartxml: any;
    svgchart: any;
    orgList: any;
    type: string;
    showLoader = false;
    editMode = false;
    featureId: number;
    featureKey: number;
    workingModule: any;
    file: any;
    isiFrameEnabled: boolean;
    isSvgchart: boolean;
    busMode: string;
    isGuestHideButton = true;
    contractId: number;
    orgchartData: ElectroOrgChartData;
    EditedOn: Date;
    CreatedOn: Date;
    private subscriptions: Subscription[] = [];
    public dateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px',
        inline: false
    };
    constructor(public router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef,
        private exportService: KubaArticleViewerService,
        private electroDocumentServices: ElectroDocumentService,
        private electroServices: ElectroService,
        private renderer: Renderer2,
        private domSanitizer: DomSanitizer) {
        if (+BaseServices.roleId === 4) {
            this.isGuestHideButton = false;
        }
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.SERVICE_LEADER_ELECTRO;
        if (+BaseServices.roleId === 5) {
            this.isGuestHideButton = BaseServices.checkUserRights(
                userRightsId,
                currentUserRole
            );
        }
        this.contractId = this.route.snapshot.params['Id'];
    }

    ngOnInit() {
        localStorage.setItem('apibaseUrl', environment.BASE_URL);
        this.orgList = this.route.snapshot.data['list'];
        this.featureId = this.route.snapshot.url[0].path === "org_resp" ?
            ElectroFeature.ORGANIZATION_AND_RESPONSIBILTIES : ElectroFeature.INTERNAL_CONTROL_SYSTEM
        this.featureKey = BaseServices.UserRole !== 'Admin' ? +this.route.snapshot.params['Id'] : 0;
        this.busMode = this.route.snapshot.data["businessMode"];
    }
    toggleEditMode() {
        this.editMode = !this.editMode;
    }

    //#region graph
    sendData() {
        this.data$.next('From parent to iframe');
    }

    /**
     * initiates iframe
     * @param iframe
     */
    private setIframeReady(iframe: ElementRef): void {
        const win: Window = iframe.nativeElement.contentWindow;

        // Pass the variable from parent to iframe
        win['dataFromParent'] = this.data$;

        const doc: Document = win.document;
        doc.open();
        doc.write(iframeCode);
        doc.close()
    }

    /**
     * trigger iframe
     * @param orgchartDetails
     * @param EditorName
     */
    openiframe(orgchartDetails: any, EditorName: string) {
        this.orgchartData = new ElectroOrgChartData();
        this.orgchartData.DocumentNumber = orgchartDetails.documentNumber;
        this.orgchartData.Version = orgchartDetails.version;
        this.orgchartData.EditorName = EditorName;
        this.orgchartData.Chapter = 1;
        this.orgchartData.CreatedOn = orgchartDetails.createdOn;
        this.EditedOn = orgchartDetails.modifiedOn;
        this.CreatedOn = orgchartDetails.createdOn;
        if (this.editMode) {
            let BusinessId = BaseServices.BusinessId;
            let ApplicationId = BaseServices.ApplicationId;
            let UserId = BaseServices.UserId;
            sessionStorage.setItem('chartsavetrigger', 'false');
            if (orgchartDetails.id === 0) {
                this.orgchartData.BusinessId = BusinessId;
                this.orgchartData.ApplicationId = ApplicationId;
                this.orgchartData.ElectroContractId = this.featureKey;
                this.orgchartData.Status = 1;
                this.orgchartData.CreatedBy = UserId;
            }
            else {
                this.orgchartData.ModifiedBy = UserId;
                if (orgchartDetails.modifiedOn) {
                    let date = HelperService.formatInputDate(orgchartDetails.modifiedOn).date.day;
                    let mnth = HelperService.formatInputDate(orgchartDetails.modifiedOn).date.month;
                    let year = HelperService.formatInputDate(orgchartDetails.modifiedOn).date.year;
                    this.orgchartData.ArticleEditedDate = year + '-' + (mnth < 10 ? '0' + mnth : mnth) + '-' + (date < 10 ? '0' + date : date);
                }
            }
            this.orgchartData.Id = orgchartDetails.id;
            sessionStorage.setItem('orgchartData', JSON.stringify(this.orgchartData));
            this.chartxml = orgchartDetails.chartXmlData;
            sessionStorage.setItem('orgchartxml', JSON.stringify(this.chartxml));
            this.isiFrameEnabled = true;
            this.renderer.addClass(document.body, 'modal-open');
            this.setIframeReady(this.iframe);
        }
        else {
            this.isiFrameEnabled = true;
            this.isSvgchart = orgchartDetails.chartSvgData ? true : false;
            let svgchartURL = orgchartDetails.chartSvgData;
            this.svgchart = this.domSanitizer.bypassSecurityTrustResourceUrl(svgchartURL);
        }

    }

    //#endregion

    // New Article
    createArticle() {
        // open empty article viewer
        this.file = new ArticleModel();
        this.file.id = 0;
        this.file.nodeId = 0;
        this.file.filename = '';
        this.file.originalFileName = '';
        this.file.path = '';
        this.file.mimetype = '';
        this.file.size = 0;
        this.file.isFolder = false;
        this.file.type = 'file';
        this.file.isDefault = false;
        this.file.documentType = 'article';
        this.file.status = 1;
        this.file.createdBy = BaseServices.UserId;
        this.file.modifiedBy = BaseServices.UserId;
        if (BaseServices.BusinessId === 0) {
            this.file.content = {
                article: {
                    id: 0,
                    articleName: '',
                    documentNumber: null,
                    chapter: '',
                    version: 1,
                    roleStatus: {
                        admin: [
                            { name: 'Own Text', status: 'Active', disable: 'false' },
                            { name: 'Standard Text', status: 'Active', disable: 'false' },
                        ],
                        portal: [
                            { name: 'Own Text', status: 'Active', disable: 'false' },
                            { name: 'Portal Text', status: 'Active', disable: 'false' },
                            { name: 'Standard Text', status: 'Active', disable: 'false' },
                        ],
                        editor: [
                            { name: 'Own Text', status: 'Active', disable: 'false' },
                            { name: 'Portal Text', status: 'IActive', disable: 'false' },
                            { name: 'Standard Text', status: 'Active', disable: 'false' },
                        ]
                    }
                },
                subArticles: [
                    {
                        name: 'Own Text',
                        text: '',
                        version: 1,
                        status: 'Active'
                    },
                    {
                        name: 'Standard Text',
                        text: '',
                        version: 1,
                        status: 'Active'
                    }
                ],
                revisionComments: null
            };
        }
        else if (BaseServices.BusinessId !== 0) {
            this.file.content = {
                article: {
                    id: 0,
                    articleName: '',
                    documentNumber: null,
                    chapter: '',
                    version: 1
                },
                subArticles: [
                    {
                        name: 'Own Text',
                        text: '',
                        version: 1,
                        status: 'Active'
                    },
                    {
                        name: 'Standard Text',
                        text: '',
                        version: 1,
                        status: 'Active'
                    }
                ],
                revisionComments: null
            };

        }
        else { }
        this.cdr.detectChanges();
        this.triggerFalseClick();
    }

    /**
     * false trigger for article model
     */
    triggerFalseClick() {
        let el: HTMLElement = this.hiddenAction.nativeElement.querySelector(
            'a'
        );
        el.click();
    }
    // emitter for save from Document Explorer
    saveUploaded(event: any) {
        switch (event.eventName) {
            case 'SaveArticle':
                this.catchEvent(event);
                break;
            case 'NewArticle':
                this.catchEvent(event);
                break;
            default:
                break;
        }
    }

    /**
     * catches article model events.
     * @param event
     */
    catchEvent(event): void {
        switch (event.eventName) {
            case 'NewArticle':
                this.subscriptions.push(this.electroDocumentServices
                    .newArticle(event, this.featureId)
                    .subscribe(
                        response => {
                            this.subscriptions.push(this.electroDocumentServices
                                .newArticleText(
                                    response.Id,
                                    event,
                                    true,
                                    0,
                                    this.featureId, this.featureKey)
                                .subscribe(result => {
                                    this.type = 'common';
                                    this.BindDocument(this.type);
                                    this.toasterComponent.callToast();
                                }));
                        },
                        error => console.log('New article error : ', error),
                        () => console.log('new article Complete')
                    ));
                break;
            case 'SaveArticle':
                let version = event.item.articleContent.article.version;
                if (+BaseServices.roleId == Roles.ADMIN) {
                    this.subscriptions.push(this.electroDocumentServices
                        .saveArticle(event, this.featureId)
                        .subscribe(
                            response => {
                                this.subscriptions.push(this.electroDocumentServices
                                    .newArticleText(
                                        response.Id,
                                        event,
                                        false,
                                        version,
                                        this.featureId, this.featureKey
                                    )
                                    .subscribe(result => {
                                        this.BindDocument(this.type);
                                        this.toasterComponent.callToast();
                                    }));
                            },
                            error => console.log('Save article error : ', error),
                            () => console.log('save article Complete')
                        ));
                }
                else {
                    this.subscriptions.push(this.electroDocumentServices
                        .newArticleText(
                            event.item.articleId,
                            event,
                            false,
                            version,
                            this.featureId, this.featureKey
                        )
                        .subscribe(result => {
                            this.BindDocument(this.type);
                            this.toasterComponent.callToast();
                        }));
                }
                break;
            default:
                break;
        }
    }

    /**
     * bind documents to grid.
     * @param type
     */
    BindDocument(type) {
        this.showLoader = true;
        this.orgList = [];
        this.subscriptions.push(this.electroServices
            .getArticlesByBusinessFeature(this.featureId, this.featureKey)
            .subscribe(response => {
                if (response) {
                    this.orgList = response;
                    this.showLoader = false;
                }
            }));
    }

    /**
     * hide model
     */
    hideModal() {
        this.renderer.removeClass(document.body, 'modal-open');
        this.isiFrameEnabled = false;
        if (this.editMode) {
            this.type = 'common';
            this.BindDocument(this.type);
        }
    }

    /**
     * print chart event
     */
    printChartPdf() {
        let type = 'pdf';
        let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
        let languageId = appSettings.Language.LanguageId;
        let CultureInfo = BaseServices.userCultureInfo();
        this.subscriptions.push(this.electroDocumentServices
            .generateOrgChartPdf(this.contractId, CultureInfo)
            .subscribe(
                blob => {
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${this.translate.instant('ORGANIZATION_AND_RESPONSIBILTIES')}.${type.toLowerCase()}`;
                    link.click();
                },
                error => {
                    alert('Export not downloaded');
                }));
    }

    saveFactsReload() {
        this.BindDocument(this.type);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}
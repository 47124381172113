//#region import
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { BaseServices } from './../../../kuba.services';
import { MeasureProtocolService } from '../../services/measure-protocol.services';
import { EarthLeakage } from 'src/app/kuba/measure-protocol/models/mpc-details';
import { TranslateService } from '@ngx-translate/core';
import { FeatureKey } from 'src/app/_models';
import { Subscription } from 'rxjs';
//#endregion

@Component({
    selector: 'measure-protocol-earth-leakage',
    templateUrl: 'measure-protocol-earth-leakage.component.html'
})

export class MeasureProtocolEarthLeakageComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    measureProtocolEarthLeakage: FormGroup;
    earthLeakage: EarthLeakage[];
    cmpId: number;
    earthLeak: any;
    earthLeakId: any;
    createdOn: any;
    createdBy: any;
    featureId: number;
    isElectro: boolean;
    saveprogress: boolean = false;
    ShowConfirm = false;
    deleteEarth = 0;
    courses: SelectItem[];
    private subscriptions: Subscription[] = [];
    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private location: Location,
        private confirmationService: ConfirmationService,
        public measureprotocolservice: MeasureProtocolService,
        private translate: TranslateService
    ) {
        this.measureProtocolEarthLeakage = this.fb.group({
            'CircuitNumber': [''],
            'ELBreaker': [''],
            'IsTested': [false],
            'ReleasePower': [''],
            'DisconnectTime': [''],
            'Comments': ['']
        });
    }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.earthLeakage = [];
        this.earthLeakage = this.route.snapshot.data['earthLeakage'];
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        this.featureId = workingModule ? workingModule.id : 0;
        if (this.featureId == FeatureKey.ELECTRO) {
            this.isElectro = true;
            if(+this.route.snapshot.params['cClId']){
                this.cmpId = +this.route.snapshot.params['cClId'];
            }
            if (this.cmpId) {
                this.subscriptions.push(this.measureprotocolservice.getAllEarthLeakage(this.cmpId).subscribe((earthData: any) => {
                    if (earthData) {
                        this.earthLeakage = earthData;
                    }
                }))
            }
        }else{
            this.cmpId = this.route.snapshot.parent.params['mpcid'];
        }
    }

    /**
     * get all earth leakage list
    */
    getAllEarthLeakage() {
        this.subscriptions.push(this.measureprotocolservice
            .getAllEarthLeakage(this.cmpId)
            .subscribe((result: any) => this.earthLeakage = result));
    }

    /**
     * clearing the data in the form
     */
    onClear() {
        this.saveprogress = false;
        this.earthLeakId = 0;
        this.measureProtocolEarthLeakage = this.fb.group({
            CircuitNumber: [''],
            ELBreaker: [''],
            IsTested: false,
            ReleasePower: [''],
            DisconnectTime: [''],
            Comments: ['']
        });
    }

    saveChanges() {
        this.saveprogress = true;
        let earthLeakage = new EarthLeakage();
        earthLeakage.Id = (this.earthLeakId) ? this.earthLeakId : 0;
        earthLeakage.CircuitNumber = this.measureProtocolEarthLeakage.value.CircuitNumber;
        earthLeakage.ELBreaker = this.measureProtocolEarthLeakage.value.ELBreaker;
        earthLeakage.IsTested = this.measureProtocolEarthLeakage.value.IsTested;
        earthLeakage.ReleasePower = this.measureProtocolEarthLeakage.value.ReleasePower;
        earthLeakage.DisconnectTime = this.measureProtocolEarthLeakage.value.DisconnectTime;
        earthLeakage.Comments = this.measureProtocolEarthLeakage.value.Comments;
        earthLeakage.MeasureProtocolCompleteId = this.cmpId;
        earthLeakage.Status = 1;

        if (earthLeakage.Id > 0) {
            earthLeakage.CreatedBy = this.createdBy;
            earthLeakage.CreatedOn = this.createdOn;
            earthLeakage.ModifiedOn = new Date();
            earthLeakage.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.measureprotocolservice
                .updateEarthLeakage(this.cmpId, earthLeakage)
                .subscribe(result => {
                    this.toasterComponent.callToast();
                    this.getAllEarthLeakage();
                    this.onClear();
                }));
        } else {
            earthLeakage.CreatedBy = BaseServices.UserId;
            earthLeakage.CreatedOn = new Date();
            this.subscriptions.push(this.measureprotocolservice
                .createEarthLeakage(this.cmpId, earthLeakage)
                .subscribe((result) => {
                    if (result) {
                        this.toasterComponent.callToast();
                    }
                    this.getAllEarthLeakage();
                    this.onClear();
                }));
        }
    }

    /**
    * editing earth leakage
     * @param Id {number}
    */

    editEarthLeakage(id: number) {
        this.subscriptions.push(this.measureprotocolservice.getEarthLeakageById(this.cmpId, id).subscribe((earthLeak: any) => {
            if (earthLeak) {
                this.createdOn = earthLeak.CreatedOn;
                this.createdBy = earthLeak.CreatedBy;
                this.earthLeakId = id;
                this.earthLeak = {
                    CircuitNumber: earthLeak.CircuitNumber,
                    ELBreaker: earthLeak.ELBreaker,
                    IsTested: earthLeak.IsTested,
                    ReleasePower: earthLeak.ReleasePower,
                    DisconnectTime: earthLeak.DisconnectTime,
                    Comments: earthLeak.Comments,
                };
                (<FormGroup>this.measureProtocolEarthLeakage).setValue(this.earthLeak, { onlySelf: true });
            }
        }));

    }

    /**
     * delete earth leakage
     * @param el
     */

    deleteEarthLeakage(el) {
        this.ShowConfirm = true;
        this.deleteEarth = el;
    }

    Confirmation(accept){
        if(accept){
            this.ShowConfirm = false;
            this.subscriptions.push(this.measureprotocolservice.deleteEarthLeakage(this.deleteEarth, this.cmpId).subscribe(result => {
                this.toasterComponent.callToastDlt();
                this.getAllEarthLeakage();
            }));
        }else{
            this.ShowConfirm = false;
            this.deleteEarth = 0;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}


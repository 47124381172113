<div class="dashboard">
  <div class="masonry-view clearfix">
    <div class="masonry-grid-item">
      <div class="company-details">
        <div class="inner-wrapper">
          <div class="company-image">
            <img
              *ngIf="BusinessImage; else businessLogo"
              class="img-fluid image"
              src="{{ BusinessImage }}"
              alt=""
            />
            <ng-template #businessLogo>
              <img
                src="{{ assetPath }}/images/KubaImage.jpg"
                class="img-fluid"
                alt=""
              />
            </ng-template>
          </div>
          <div class="company-content">
            <div *ngIf="BusinessName">
              <strong>{{ BusinessName }}</strong>
            </div>
            <div *ngIf="adminAddress">
              <strong>{{ adminAddress }}</strong>
            </div>
            <div *ngIf="zipCode">
              <p>{{ zipCode }}</p>
            </div>
            <div *ngIf="adminPostalAddress">
              <strong>{{ adminPostalAddress }}</strong>
            </div>
            <div *ngIf="Telephone">
              <p>{{ Telephone }}</p>
            </div>
            <div *ngIf="AboutBusiness">
              <p>
                <strong style="white-space: break-spaces">{{
                  AboutBusiness
                }}</strong>
              </p>
            </div>
            <div *ngIf="isPortal">
              <p>
                <strong style="white-space: break-spaces">{{
                  description
                }}</strong>
              </p>
            </div>
            <div *ngIf="HseGoals">
              <p>
                <strong>{{ HseGoals }} </strong>
              </p>
            </div>
            <div *ngIf="dashboardAdmin">
              <strong [innerHTML]="dashboardAdmin"></strong>
            </div>
          </div>
          <div class="default-list-nb">
            <div class="list-item" *ngIf="hseManager">
              <span style="padding-left: 0px">
                <strong translate>HSE_RESPONSIBLE</strong>
              </span>
              <span> {{ hseManager }} </span>
            </div>
            <div class="list-item" *ngIf="Envlight">
              <span style="padding-left: 0px">
                <strong translate>ENVIRONMENTAL_LIGHTHOUSE_RESPONSIBLE</strong>
              </span>
              <span> {{ Envlight }} </span>
            </div>
            <div class="list-item" *ngFor="let function of businessFunction">
              <span
                style="padding-left: 0px"
                *ngIf="function.IsTranslate; else functionName"
              >
                <strong> {{ function.Function | translate }} </strong>
              </span>
              <ng-template #functionName>
                <strong> {{ function.Function }} </strong>
              </ng-template>
              <span> {{ function.Employee }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="masonry-grid-item"
      *ngIf="hasFollowupRights && hasActivitiesRights"
    >
      <div class="widget-box">
        <div class="wb-title">
          <h4 translate *ngIf="isPortal || !isAdmin">OPEN_ACTIVITIES</h4>
          <h4 translate *ngIf="isAdmin">ACTIVITIES</h4>
          <div class="widget-actions">
            <span *ngIf="isActivity">
              <a
                *ngIf="!isPortal && !isAdmin"
                href="javascript:void(0);"
                [routerLink]="['../follow-up/activities']"
                [queryParams]="{ prop: 'new', openDialog: true }"
                class="wa-btn text-success btn-link"
                pTooltip="{{ 'NEW_ACTIVITY' | translate }}"
                tooltipPosition="top"
              >
                <i class="icon ic-md icon-plus-2"></i>
              </a>
            </span>
            <a
              href="javascript:void(0);"
              [routerLink]="['../follow-up/activities']"
              class="wa-btn"
            >
              <i class="icon ic-md icon-forward-button"></i>
            </a>
          </div>
        </div>

        <!-- BUSINESS LEVEL ACTIVITIES -->

        <div
          *ngIf="!isPortal && !isAdmin && activities.length !== 0"
          class="wb-content"
        >
          <app-activities-business-widget [activities]="activities">
          </app-activities-business-widget>
        </div>
        <!-- PORTAL LEVEL ACTIVITIES -->
        <div *ngIf="isPortal && activities.length !== 0" class="wb-content">
          <app-activities-portal-widget> </app-activities-portal-widget>
        </div>

        <div *ngIf="activities.length <= 0" class="wb-content">
          <div class="no-data">
            <span>
              <span class="icon ic-lg icon-041"></span>
              <span translate>NO_RECORDS_FOUND</span>
            </span>
          </div>
        </div>

        <!-- Admin LEVEL ACTIVITIES -->
        <!-- DISABLED UNTIL FURTHER DEVELOPMENT -->
        <div *ngIf="isAdmin" class="wb-content">
          <div class="no-data">
            <span>
              <span class="icon ic-lg icon-041"></span>
              <span translate>FEATURE_DISABLED</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="masonry-grid-item" *ngIf="hasCalendarRights">
      <div class="widget-box">
        <full-calendar
          [options]="calendarOptions"
          (eventClick)="eventClick($event.detail)"
          class="custom-full-calendar"
        ></full-calendar>
      </div>
    </div>

    <div
      class="masonry-grid-item"
      *ngIf="hasFollowupRights && hasDeviationRights"
    >
      <div class="widget-box">
        <div class="wb-title">
          <h4 translate>DEVIATIONS</h4>
          <div class="widget-actions">
            <span *ngIf="isDeviation">
              <a
                *ngIf="!isAdmin && !isPortal"
                href="javascript:void(0);"
                [routerLink]="['../follow-up/deviations/new']"
                class="wa-btn text-success btn-link"
                pTooltip="{{ 'NEW_DEVIATION' | translate }}"
                tooltipPosition="top"
              >
                <i class="icon ic-md icon-plus-2"></i>
              </a>
            </span>
            <a
              href="javascript:void(0);"
              [routerLink]="['../follow-up/deviations']"
              class="wa-btn"
            >
              <i class="icon ic-md icon-forward-button"></i>
            </a>
          </div>
        </div>
        <div class="wb-content">
          <app-deviation-widget
            *ngIf="deviationCount.length > 0"
            [deviationCount]="deviationCount"
          ></app-deviation-widget>
          <div *ngIf="deviationCount.length <= 0 && !isAdmin" class="no-data">
            <span>
              <span class="icon ic-lg icon-041"></span>
              <span translate>NO_RECORDS_FOUND</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="masonry-grid-item" *ngIf="hasFollowupRights && hasRiskRights">
      <div class="widget-box">
        <div class="wb-title">
          <h4 translate>RISK_ANALYSES</h4>
          <div class="widget-actions">
            <span *ngIf="isRiskAnalysis">
              <a
                *ngIf="!isPortal && !isAdmin"
                href="javascript:void(0);"
                [routerLink]="['../follow-up/risk-analysis/newrisk']"
                class="wa-btn text-success btn-link"
                pTooltip="{{ 'NEW_RISK_ADD' | translate }}"
                tooltipPosition="top"
              >
                <i class="icon ic-md icon-plus-2"></i>
              </a>
            </span>
            <a
              href="javascript:void(0);"
              [routerLink]="['../follow-up/risk-analysis']"
              class="wa-btn"
            >
              <i class="icon ic-md icon-forward-button"></i>
            </a>
          </div>
        </div>
        <div class="wb-content">
          <!-- Widget for business level -->
          <div *ngIf="!isAdmin && !isPortal">
            <app-risk-analysis-business-widget
              *ngIf="riskAnalysis.length > 0"
              [riskAnalysis]="riskAnalysis"
            ></app-risk-analysis-business-widget>
          </div>
          <!-- Widget for Portal level -->
          <div *ngIf="isPortal">
            <app-risk-analysis-portal-widget
              *ngIf="riskAnalysis.length > 0"
              [riskAnalysis]="riskAnalysis"
            >
            </app-risk-analysis-portal-widget>
          </div>
          <!-- Admin widget not yet implemented -->
          <div *ngIf="isAdmin" class="no-data">
            <span>
              <span class="icon ic-lg icon-041"></span>
              <span translate>FEATURE_DISABLED</span>
            </span>
          </div>
          <div *ngIf="riskAnalysis.length <= 0 && !isAdmin" class="no-data">
            <span>
              <span class="icon ic-lg icon-041"></span>
              <span translate>NO_RECORDS_FOUND</span>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="masonry-grid-item"
      *ngIf="hasFollowupRights && hasMeasureRights"
    >
      <div class="widget-box">
        <div class="wb-title">
          <h4 translate>MEASURES</h4>
          <div class="widget-actions">
            <a
              *ngIf="isEditor"
              href="javascript:void(0);"
              [routerLink]="['../follow-up/consumptions/new/', 0]"
              class="wa-btn text-success btn-link"
              pTooltip="{{ 'NEW_MEASUREMENT' | translate }}"
              tooltipPosition="top"
            >
              <i class="icon ic-md icon-plus-2"></i>
            </a>
            <a
              href="javascript:void(0);"
              [routerLink]="['../follow-up/consumptions']"
              class="wa-btn"
            >
              <i class="icon ic-md icon-forward-button"></i>
            </a>
          </div>
        </div>
        <div class="wb-content">
          <div class="default-list-nb" *ngIf="!(measures.length <= 0)">
            <div class="list-item">
              <span>
                <strong translate>NAME</strong>
              </span>
              <span>
                <strong translate>MEASURES</strong>
              </span>
            </div>
            <div class="list-item" *ngFor="let measure of measures">
              <span>{{ measure.Name }}:</span>
              <span>{{ measure.Measures }}</span>
            </div>
          </div>
          <div *ngIf="measures.length <= 0" class="no-data">
            <span>
              <span class="icon ic-lg icon-055"></span>
              <span translate>NO_RECORDS_FOUND</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="masonry-grid-item"
      *ngIf="!isAdmin && !isPortal && hasInternalNewsRights"
    >
      <div class="widget-box">
        <div class="wb-title">
          <h4 translate>INTERNAL_NEWS</h4>
          <div class="widget-actions">
            <a
              *ngIf="!isUser && !isGuest"
              href="javascript:void(0);"
              [routerLink]="['../news/create/', 0, 0]"
              class="wa-btn text-success btn-link"
              pTooltip="{{ 'ADD_NEWS' | translate }}"
              tooltipPosition="top"
            >
              <i class="icon ic-md icon-plus-2"></i>
            </a>
            <a
              href="javascript:void(0);"
              [routerLink]="['../news/list']"
              class="wa-btn"
            >
              <i class="icon ic-md icon-forward-button"></i>
            </a>
          </div>
        </div>
        <div class="wb-content">
          <div class="default-list-nb">
            <div class="list-item" *ngFor="let news of internalNews">
              <span>
                <a
                  href="javascript:void(0);"
                  [routerLink]="['../news/create/', news.Id, 1]"
                >
                  <span translate>{{ news.Title }}</span>
                </a>
              </span>
            </div>
          </div>
          <div *ngIf="internalNews == null" class="no-data">
            <span>
              <span class="icon ic-lg icon-030"></span>
              <span translate>NO_RECORDS_FOUND</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="masonry-grid-item"
      *ngIf="(isUser || isEditor || isAdmin || isPortal) && hasNewsRights"
    >
      <div class="widget-box">
        <div class="wb-title">
          <h4 translate *ngIf="!isMedi3">ADMIN_NEWS</h4>
          <h4 translate *ngIf="isMedi3">ADMIN_NEWS_MEDI3</h4>
          <div class="widget-actions">
            <a
              *ngIf="isAdmin && !isPortal"
              href="javascript:void(0);"
              [routerLink]="['../news/create/', 0, 0]"
              class="wa-btn btn-add btn-link"
              title="{{ 'ADD_NEWS' | translate }}"
            >
              <i class="icon ic-md icon-plus-2"></i>
            </a>
            <a
              *ngIf="isAdmin"
              href="javascript:void(0);"
              [routerLink]="['../news/list']"
              class="wa-btn"
            >
              <i class="icon ic-md icon-forward-button"></i>
            </a>
            <a
              *ngIf="!isAdmin"
              href="javascript:void(0);"
              [routerLink]="['/app/admin/1/news/Adminlist']"
              class="wa-btn"
              pTooltip="{{ 'VIEW_ALL' | translate }}"
              tooltipPosition="top"
            >
              <i class="icon ic-md icon-forward-button"></i>
            </a>
          </div>
        </div>
        <div class="wb-content">
          <div class="default-list-nb">
            <div class="list-item" *ngFor="let news of adminNews">
              <span>
                <a
                  href="javascript:void(0);"
                  [routerLink]="['../news/create/', news.Id, 1]"
                >
                  <span>{{ news.Title }}</span>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="masonry-grid-item" *ngIf="!isAdmin && hasPortalNewsRights">
      <div class="widget-box">
        <div class="wb-title">
          <h4 translate>NEWS_PORTAL</h4>
          <div class="widget-actions">
            <a
              href="javascript:void(0);"
              [routerLink]="['../dashboard-ownpage/', 'Portal']"
              class="wa-btn"
            >
              <i class="icon ic-md icon-forward-button"></i>
            </a>
          </div>
        </div>
        <div class="wb-content">
          <div class="wb-content">
            <div style="padding: 10px" [innerHTML]="portalNews"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="masonry-grid-item" *ngIf="hasAboutKubaRights">
      <div class="widget-box">
        <div class="wb-title">
          <h4 *ngIf="applicationId != 5">
            {{ "ABOUT_KUBA" | translate }} {{ applicationName }}
          </h4>
          <h4 *ngIf="applicationId == 5">
            {{ "ABOUT" | translate }} {{ applicationName }}
          </h4>
          <div class="widget-actions">
            <a
              href="javascript:void(0);"
              [routerLink]="['../dashboard-ownpage/', 'Admin']"
              class="wa-btn"
            >
              <i class="icon ic-md icon-forward-button"></i>
            </a>
          </div>
        </div>
        <div class="wb-content">
          <div
            style="padding: 10px; word-break: break-all"
            [innerHTML]="aboutKuba"
          ></div>
        </div>
      </div>
    </div>
    <div class="masonry-grid-item" *ngIf="isAdmin">
      <div class="widget-box">
        <div class="wb-title">
          <h4 translate>PORTAL_HITS</h4>
        </div>
        <div class="wb-content">
          <div class="no-data">
            <span>
              <span class="icon ic-lg icon-in"></span>
              <span translate>NO_RECORDS_FOUND</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="masonry-grid-item" *ngIf="hasLoggedHistory">
      <div class="widget-box">
        <div class="wb-title">
          <h4 translate>LOGGED_HISTORY</h4>
          <div class="widget-actions">
            <a
              (click)="viewLogged = !viewLogged"
              class="wa-btn"
              href="javascript:void(0);"
            >
              <i
                [ngClass]="
                  viewLogged == false
                    ? 'icon ic-sm icon-bar-chart'
                    : 'icon ic-md icon-forward-button'
                "
              ></i>
            </a>
          </div>
        </div>
        <div class="wb-content">
          <div class="default-list-nb" *ngIf="loggedUser.length > 0">
            <div class="list-item">
              <span>
                <strong *ngIf="isAdmin || isPortal" translate
                  >BUSINESS_NAME</strong
                >
                <strong *ngIf="isEditor || isUser" translate>USER_NAME</strong>
              </span>
              <span class="count-column">
                <strong> {{ dayBeforeYesterday | date : "dd-MMM" }}</strong>
              </span>
              <span class="count-column">
                <strong> {{ yesterday | date : "dd-MMM" }}</strong>
              </span>
              <span class="count-column">
                <strong>{{ today | date : "dd-MMM" }}</strong>
              </span>
            </div>
            <div class="list-item" *ngFor="let user of loggedUser">
              <span>{{ user.Business }}</span>
              <span class="count-column">{{
                user.DayBeforeYesterdayCount
              }}</span>
              <span class="count-column">{{ user.YesterdayCount }}</span>
              <span class="count-column">{{ user.TodayCount }}</span>
            </div>
          </div>
          <div *ngIf="loggedUser.length <= 0" class="no-data">
            <span>
              <span class="icon ic-lg icon-052"></span>
              <span translate>NO_RECORDS_FOUND</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="masonry-grid-item" *ngIf="hasAppHistory">
      <div class="widget-box">
        <div class="wb-title">
          <h4 translate>APP_HISTORY</h4>
        </div>
        <div class="wb-content">
          <div class="no-data" *ngIf="!isAppLog">
            <span>
              <span class="icon ic-lg icon-052"></span>
              <span translate>NO_RECORDS_FOUND</span>
            </span>
          </div>
          <div class="default-list-nb" *ngIf="isAppLog">
            <div class="list-item">
              <span>
                <strong translate>NAME</strong>
              </span>
              <span class="count-column">
                <strong> {{ dayBeforeYesterday | date : "dd-MMM" }}</strong>
              </span>
              <span class="count-column">
                <strong> {{ yesterday | date : "dd-MMM" }}</strong>
              </span>
              <span class="count-column">
                <strong>{{ today | date : "dd-MMM" }}</strong>
              </span>
            </div>
            <div class="list-item" *ngFor="let user of appLoggedUser">
              <span>{{ user.Business }}</span>
              <span class="count-column">{{
                user.DayBeforeYesterdayCount
              }}</span>
              <span class="count-column">{{ user.YesterdayCount }}</span>
              <span class="count-column">{{ user.TodayCount }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="masonry-grid-item"
      *ngIf="!isAdmin && hasLinksRights && hasDLinksRights"
    >
      <div class="widget-box">
        <div class="wb-title">
          <h4 translate>LINKS</h4>
          <div class="widget-actions">
            <a
              *ngIf="!isPortal"
              href="javascript:void(0);"
              [routerLink]="['../business/edit/' + businessId + '/links']"
              class="wa-btn"
            >
              <i class="icon ic-md icon-forward-button"></i>
            </a>
            <a
              *ngIf="isPortal"
              href="javascript:void(0);"
              [routerLink]="['../portal-link']"
              class="wa-btn"
            >
              <i class="icon ic-md icon-forward-button"></i>
            </a>
          </div>
        </div>
        <div class="wb-content">
          <div class="default-list-nb" *ngIf="links">
            <div class="list-item" *ngFor="let link of links">
              <span>
                <a
                  href="javascript:void(0);"
                  target="_blank"
                  href="{{ link.Url }}"
                >
                  <span translate>{{ link.Title }}</span>
                </a>
              </span>
            </div>
          </div>
          <div *ngIf="!links" class="no-data">
            <span>
              <span class="icon ic-lg icon-030"></span>
              <span translate>NO_RECORDS_FOUND</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="masonry-grid-item"
      *ngIf="isPortalBusiness && hasPortalLinksRights"
    >
      <div class="widget-box">
        <div class="wb-title">
          <h4 translate>PORTALLINKS</h4>
        </div>
        <div class="wb-content">
          <div class="default-list-nb" *ngIf="Portallinks">
            <div class="list-item" *ngFor="let link of Portallinks">
              <span>
                <a
                  href="javascript:void(0);"
                  target="_blank"
                  href="{{ link.Url }}"
                >
                  <span translate>{{ link.Title }}</span>
                </a>
              </span>
            </div>
          </div>
          <div *ngIf="!Portallinks" class="no-data">
            <span>
              <span class="icon ic-lg icon-030"></span>
              <span translate>NO_RECORDS_FOUND</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { BaseServices } from 'src/app/kuba/kuba.services';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VehiclesCompleteChecklist } from '../../shared/models/complete-checklist';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class VehicleControlService {

    constructor(private http: HttpClient) {
    }
    getAllVehicleControl() {
        return this.http
            .get(`${environment.BASE_URL}/vehicle/vehiclecontrol`, )
            .map((result:any) => result);
    }
    getVehicleControlById(Id: number) {
        return this.http
            .get(`${environment.BASE_URL}/vehicle/vehiclecontrol/${Id}`, )
            .map((result: any) => result);
    }
    addVehicleControl(vehicleCtrl) {
        return this.http
            .post(`${environment.BASE_URL}/vehicle/vehiclecontrol`, vehicleCtrl, )
            .map(result => result);
    }
    updateVehicleControl(vehicleCtrl, Id) {
        return this.http
            .put(
                `${environment.BASE_URL}/vehicle/vehiclecontrol/${Id}`,
                vehicleCtrl,
            )
            .map(result => result);
    }
    deleteVehicleControl(Id: number) {
        return this.http
            .delete(`${environment.BASE_URL}/vehicle/controldelete/${Id}`, )
            .map(result => result);
    }
    getVehicleControlByVehicleId(Id: number) {
        return this.http
            .get(`${environment.BASE_URL}/vehicle/vehiclecontrols/${Id}`, )
            .map((result: any) => result);
    }
    createCompleteChecklist(VehiclesCompleteChecklist: VehiclesCompleteChecklist) {
        return this.http.post(`${environment.BASE_URL}/vehicle/CompleteChecklistByVehicleId`, VehiclesCompleteChecklist, )
            .map((response: any) => { return response });
    }
}

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BusinessEquipmentType } from '../models/';
import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BusinessEquipmentService {
    item: Object;
    constructor(private http: HttpClient) {

    }
    // list all equipments
    list(Id: number) {
        return this.http.get<any>(environment.BASE_URL + '/business/equipment/equipments/' + Id, BaseServices.headerOption)
            .map(result => result);
    }

    // add employee to list
    add(data: any) {
        let body = JSON.stringify(data);
        return this.http.post(environment.BASE_URL + '/business/equipment/equipment', body, BaseServices.headerOption)
            .map(result => result);
    }

    // delete equipment from list
    deleteEquipment(id: number) {
        return this.http.delete(environment.BASE_URL + '/business/equipment/equipmentDelete/' + id, BaseServices.headerOption)
            .map(result => result);
    }

    // bind equipment type
    getAllEquipType(id: number) {
        return this.http.get(environment.BASE_URL + '/business/equipmentType/parent/' + id, BaseServices.headerOption)
            .map(result =>
                result
            );
    }

    // update employee type
    updateEquipType(existingCategory: BusinessEquipmentType) {
        return this.http.put(environment.BASE_URL + '/business/equipmentType/updateequipment', existingCategory, BaseServices.headerOption)
            .map(result => result);
    }

    // adding employee Type
    createEquipType(newCategory: BusinessEquipmentType) {
        return this.http.post(environment.BASE_URL + '/business/equipmentType/newequipment',
            JSON.stringify(newCategory), BaseServices.headerOption)
            .map(result => result);
    }

    // bind equipment
    getAllByEquip(Id: number) {
        return this.http.get(environment.BASE_URL + '/business/equipmentType/child/' + Id, BaseServices.headerOption)
            .map(result => result);
    }

    // delete popup values
    deleteType(Id: number, businessId: number) {
        return this.http.delete(environment.BASE_URL + '/business/equipmentType/equipmentTypeDelete/' + Id + '/' + businessId, BaseServices.headerOption)
            .map(result => result);
    }


}

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';


import { HelperService } from './../../../_services/helper.service';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ActivityPortalDto } from './../models/activities';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { ActivitySchedule, ActivityAttachment } from './../models/activities';
import { TaskService } from './../../task-manager/services/task.services';
import { IMyDpOptions } from 'mydatepicker';
import { SelectItem } from 'primeng/api';
import { ActivityServices } from '../services/activities.services';
import { BaseServices } from './../../kuba.services';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';


@Component({
    selector: 'activity-portal',
    templateUrl: 'activity-portal.component.html',
})
export class PortalActivitiesComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    vRepetition: SelectItem[];
    activityForm: FormGroup;
    businessList: SelectItem[];
    CheckListData: SelectItem[];
    FollowUpDays: any[];
    additionalData: any;
    isBusiness = true;
    upConfig: UploaderConfig;
    uploadedFiles = [];
    ActivityScheduleId: number;
    private subscriptions: Subscription[] = [];
    newUploadedFiles: ActivityAttachment[] = [];
    public dateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px',
        disableUntil: this.getCurrentDate()
    };
    constructor(
        private activityServices: ActivityServices,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private taskService: TaskService,
        protected location: Location,
        private translate: TranslateService
    ) {

    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.disableActivityByFeature();
        this.activityForm = this.formBuilder.group({
            Title: ['', Validators.required],
            Description: [''],
            RepetitionId: [1, Validators.required],
            Deadline: ['', Validators.required],
            NotificationBeforeDeadline: [0],
            NotificationBeforeFollowUp: [7],
            LinkUrl: [''],
            RepeatCheckList: [],
            CheckList: [''],
            Business: [[], Validators.required]
        });
        this.additionalData = {
            ApplicationId: BaseServices.ApplicationId,
            BusinessId: BaseServices.BusinessId,
            ParentId: 0,
            Status: '1',
            CreatedOn: new Date(),
            ModifiedBy: BaseServices.UserId,
            ModifiedOn: new Date()
        };

        this.upConfig = {
            title: 'Activity Document',
            titleAsLabel: true,
            editMode: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showSearchFilter: false,
            showUploadButton: true,
            uploaderUri:
            environment.BASE_URL +
            '/file/upload/business/followup/activity-docs',
            addtionalData: this.additionalData
        };
        this.bindDetails();
    }

    /**
    * go back to Activity List
    */
    gotoList() {
        this.location.back();
    }
    /**
     * get current date
     */
    getCurrentDate() {
        let dateFormat = new Date();
        return {
            year: dateFormat.getFullYear(),
            month: dateFormat.getMonth() + 1,
            day: dateFormat.getDate() - 1,
        };
    }
    /**
     * file upload events
     * @param event {any}
     */
    saveUploaded(event: any) {
        if (event) {
            let eventType = event.eventName;
            switch (eventType) {
                case 'DeleteDefaultFile':
                    if (event.item.data.id === 0) {
                        // remove unsaved attachment
                        this.newUploadedFiles = this.newUploadedFiles.filter(
                            (x:any) => x.Path !== event.item.data.path
                        );
                        this.uploadedFiles = this.uploadedFiles.filter(
                            (x:any) => x.path !== event.item.data.path
                        );
                        this.toasterComponent.callToastDlt();
                    } else {
                        // remove existing attachement
                        this.subscriptions.push(this.taskService
                            .deleteActivityAttachment(event.item.data.id)
                            .subscribe((result: any) => {
                                if (result) {
                                    // remove from display list
                                    this.uploadedFiles = this.uploadedFiles.filter(
                                        (x:any) => x.path !== event.item.data.path
                                    );
                                    this.toasterComponent.callToastDlt();
                                }
                            }));
                    }
                    break;
                default:
                    let keyBindings = {
                        Originalname: 'OriginalFileName',
                        FileTypeId: 'FileType'
                    };
                    let deviationDoc = Object.assign(
                        {},
                        event,
                        this.additionalData
                    );
                    deviationDoc.id = 0;
                    deviationDoc.createdBy = BaseServices.UserId;
                    deviationDoc.status = 1;
                    this.newUploadedFiles.push(
                        HelperService.switchKeysCase(deviationDoc, keyBindings)
                    );
                    break;
            }
        }
    }
    bindDetails() {
        this.businessList = [];
        let portalBusinessList = this.route.snapshot.data['business'];
        if (portalBusinessList) {
            portalBusinessList.forEach((repitition: any) => {
                this.businessList.push({
                    label: repitition.Name,
                    value: repitition.Id
                });
            });
        }
        this.vRepetition = [];
        this.subscriptions.push(this.translate.stream('REPETITION_LIST').subscribe(val => {
            this.vRepetition.push(
                { label: val.REPETITION_LIST_SELECT, value: null },
                { label: val.REPETITION_LIST_NO_REPETITION, value: 1 },
                { label: val.REPETITION_LIST_DAILY, value: 2 },
                { label: val.REPETITION_LIST_WEEKDAYS, value: 3 },
                { label: val.REPETITION_LIST_EVERY_WEEK, value: 4 },
                { label: val.REPETITION_LIST_EVERY_2_WEEKS, value: 5 },
                { label: val.REPETITION_LIST_EVERY_3_WEEKS, value: 6 },
                { label: val.REPETITION_LIST_EVERY_MONTH, value: 7 },
                { label: val.REPETITION_LIST_EVERY_2_MONTH, value: 8 },
                { label: val.REPETITION_LIST_EVERY_3_MONTH, value: 9 },
                { label: val.REPETITION_LIST_EVERY_4_MONTH, value: 10 },
                { label: val.REPETITION_LIST_EVERY_6_MONTH, value: 11 },
                { label: val.REPETITION_LIST_EVERY_YEAR, value: 12 },
                { label: val.REPETITION_LIST_EVERY_2_YEARS, value: 13 },
                { label: val.REPETITION_LIST_EVERY_3_YEARS, value: 14 },
                { label: val.REPETITION_LIST_EVERY_4_YEARS, value: 15 },
                { label: val.REPETITION_LIST_EVERY_5_YEARS, value: 16 },
                { label: val.REPETITION_LIST_EVERY_6_YEARS, value: 17 },
                { label: val.REPETITION_LIST_EVERY_7_YEARS, value: 18 },
                { label: val.REPETITION_LIST_EVERY_8_YEARS, value: 19 },
                { label: val.REPETITION_LIST_EVERY_9_YEARS, value: 20 },
                { label: val.REPETITION_LIST_EVERY_10_YEARS, value: 21 }
            );
        }))
        this.CheckListData = [];
        let checklist = this.route.snapshot.data['Checklist'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.CheckListData = [];
            this.CheckListData.push(
                { label: val.SELECT, value: null }
            );
        }));
        if (checklist) {
            checklist.forEach((checklist: any) => {
                this.CheckListData.push({
                    label: checklist.Title,
                    value: checklist.Id
                });
            });
        }

        this.FollowUpDays = [];
        for (let n = 0; n <= 14; n++) {
            if (n === 0) {
              this.FollowUpDays.push({
                label: this.translate.instant('ON_DEADLINE_DATE'),
                value: n,
              });
            } else {
              this.FollowUpDays.push({ label: n.toString(), value: n });
            }
          }
        this.FollowUpDays.push({ label: this.translate.instant('TREE_WEEKS'), value: 21 }, { label: this.translate.instant('ONE_MONTH'), value: 30 });
    }
    savePortalActivity() {
        let addActivity:any = new ActivityPortalDto();
        addActivity.Id = 0;
        addActivity.BusinessIds = [];
        this.activityForm.value.Business.forEach((selectedBusiness: any) => {
            addActivity.BusinessIds.push(selectedBusiness)
        });
        addActivity.ChecklistId = this.activityForm.value.CheckList;
        addActivity.ApplicationId = BaseServices.ApplicationId;
        addActivity.IsRepeatChklistRecurr = this.activityForm.value.RepeatCheckList;
        addActivity.LinkUrl = this.activityForm.value.LinkUrl;
        addActivity.Description = this.activityForm.value.Description;
        addActivity.CreatedOn = new Date();
        addActivity.CreatedBy = BaseServices.UserId;
        addActivity.Title = this.activityForm.value.Title;
        let activitySchedule = new ActivitySchedule();
        activitySchedule.Id = 0;
        activitySchedule.EndDate =
            this.activityForm.value.Deadline
                ? HelperService.formatDateFilter(this.activityForm.value.Deadline.formatted)
                : null!;
        activitySchedule.NotifyBeforeDeadLine = this.activityForm.value.NotificationBeforeDeadline;
        activitySchedule.Status = '1';
        activitySchedule.NotifyBeforeFollowup = this.activityForm.value.NotificationBeforeFollowUp;
        addActivity.ActivityScheduleDto = activitySchedule;
        addActivity.RepetitionId = this.activityForm.value.RepetitionId;
        addActivity.Status = '8';
        addActivity.ActivityAttachment = this.newUploadedFiles;
        this.subscriptions.push(this.activityServices
            .addActivityForPortal(addActivity)
            .subscribe(isActivityUpdated => {
                if (isActivityUpdated) {
                    this.toasterComponent.callToast();
                }
            }));
    }
    /**
    * cost key press event
    * @param e {e}
    * @param limitNumber {limitNumber}
    */
    onNumber(e: any, limitNumber: any) {
        const limit = limitNumber;
        let charCode = e.which ? e.which : e.keyCode;
        if (
            e.target.value.length === limit ||
            (charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46)
        ) {
            e.preventDefault();
        }
    }
    disableActivityByFeature() {
        let features = JSON.parse(BaseServices.getUserFeatures().Features);
        let followUpFeatures = features.filter((x:any) => x.id === 1);
        let activity = followUpFeatures[0].children;
        let activityChecked = activity.filter((x:any) => x.id === 3);
        if (activity[0].checked === false && (BaseServices.roleId === '2' || BaseServices.roleId === '1')) {
            this.isBusiness = false;
        } else {
            this.isBusiness = true;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}

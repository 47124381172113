import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  template: `<router-outlet></router-outlet>`,
})
export class KubaSubComponent implements OnInit {
  /**
   *
   */
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {}
}

import { UserRole } from './../../follow-ups/models/deviation';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';

import { Rights } from './../../../_models/feature';
import { HelperService, PdfSetting } from './../../../_services/helper.service';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { Timetable } from '../models/index';
import { EmployeeServices } from '../services/employee.services';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
  selector: 'timetablelist',
  templateUrl: 'employee-timetable-list.component.html',
})
export class EmployeeTimetableComponent implements OnInit {
  //#region variables
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  @ViewChildren('tableCheckbox') tableCheckboxes: QueryList<any>;
  headerCheckboxChecked: boolean = false;

  @ViewChild('timetableTable', { static: false }) timetableTable: Table;
  months: any[];
  years: any[];
  timetables: Timetable[];
  TimetableForm: FormGroup;
  employeeId: number;
  startDate: Date;
  endDate: Date;
  videoUrl: string;
  safeURL: any;
  statusList: SelectItem[];
  additionalData: any;
  isVideo = false;
  project: any = [];
  totalHoursSpent: any;
  rowsPerPageOptions: any = [];
  isProject = false;
  isApproveTimetable: boolean;
  userId: any;
  selectedTimetable: any[];
  isTimeTableSelected: boolean;
  filteredValue: any;
  checkedAll: boolean;
  isApproveTimeTableRight: boolean;
  isUser: boolean;
  private subscriptions: Subscription[] = [];

  /**
   * Date picker configuration option
   */
  public startDateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    height: '26px',
    selectionTxtFontSize: '14px',
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
  };
  public endDateOptions: IMyDpOptions = this.startDateOptions;
  selectedYear: any;
  selectAllChecked: any;
  selectedTimetable1: number;
  checkedCount: number;
  // #endregion

  //#region constructor

  /**
   * constructor
   * @param employeeServices {EmployeeServices}
   * @param _fb {FormBuilder}
   * @param route {ActivatedRoute}
   * @param confirmationService {ConfirmationService}
   */
  constructor(
    private employeeServices: EmployeeServices,
    private _fb: FormBuilder,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private translate: TranslateService
  ) {
    
    this.subscriptions.push(
      this.translate.stream('FILENAME').subscribe((val) => {
        this.additionalData = {
          fileName: val.EMPLOYEE_TIMETABLE_LIST,
          header: 'Employee Timetable List',
          businessId: BaseServices.BusinessId,
          cultureInfo: BaseServices.userCultureInfo(),
          columnNames: [
            { title: 'Employee Name', dataKey: 'EmployeeName' },
            { title: 'Project Name', dataKey: 'ProjectName' },
            { title: 'Type of Work', dataKey: 'ProjectWorkTypeName' },
            { title: 'Start Date', dataKey: 'StartDate' },
            { title: 'End Date', dataKey: 'EndDate' },
            { title: 'Start Time', dataKey: 'StartTime' },
            { title: 'End Time', dataKey: 'EndTime' },
            { title: 'Break', dataKey: 'BreakTime' },
            { title: 'Hours Spent', dataKey: 'HoursSpent' },
            { title: 'Comments', datakey: 'Comments' },
            { title: 'Approval Person', dataKey: 'ApprovalUser' },
            { title: 'Approval Date', dataKey: 'ApprovalDate' },
            { title: 'Status', dataKey: 'StatusText' },
          ],
        };
      })
    );
  }

  // #endregion

  //#region page-events

  /**
   * getting employee id,binding the datatable based on id,binding yesr and month function call
   */
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    this.isUser = +BaseServices.roleId === UserRole.USER ? false : true;
    this.checkApprovelTimeTableRights();
    this.checkAll(false);
    this.selectedTimetable = [];
    this.userId = BaseServices.UserId;
    this.bindDropdowns();
    this.TimetableForm = this._fb.group({
      FromDate: [''],
      EndDate: [''],
      Month: [],
      Year: [],
    });

    let eid = this.route.snapshot.params['tid'];
    this.employeeId = +eid;
    if (this.employeeId) {
      this.timetables = <Timetable[]>this.route.snapshot.data['timetable'];
    } else {
      this.timetables = <Timetable[]>this.route.snapshot.data['list'];
    }
    this.gridData();
    this.bindProject();
    this.bindYears();
    this.bindMonths();
    
    // Project Name hide/show based on Project Module existence
    let leftNav = JSON.parse(sessionStorage.getItem('leftNav')!);
    let filteredTemplate = HelperService.filterTree(
      'Project',
      leftNav.filter((x: any) => {
        if (x.name === 'Project') {
          let childLength = x.children.length;
          if (x.children[3].checked === true) {
            this.isProject = true;
          }
        }
      }),
      'IsProject'
    );
  }

  gridData() {
    var resArr = [];
    this.timetables.forEach(function (item) {
      var i = resArr.findIndex((x) => x.Id == item.Id);
      if (i <= -1) {
        resArr.push(item);
      }
    });
    this.timetables = resArr;
    if (this.timetables !== null) {
      this.timetables = this.timetables.filter((x) => x.EndDate !== null);
    }
    let currentYear = new Date().getFullYear();
    this.bindTimetableGrid(this.timetables);
    this.timetables.forEach((element) => {
      this.timetables = this.timetables.filter(
        (item: any) => item.Year == String(currentYear)
      );
    });
  }
  checkApprovelTimeTableRights() {
    let userData = this.route.snapshot.data['userData'];
    let rightsId = Rights.APPROVE_TIMETABLE;
    if (userData) {
      let userRights = JSON.parse(userData.Rights);
      this.isApproveTimetable = BaseServices.checkRightsForAccess(
        userRights,
        rightsId
      );
    }
  }

  // #endregion

  //#region Control-events
  /**
   * get current date
   */
  getCurrentDate() {
    let dateFormat = new Date();
    return {
      year: dateFormat.getFullYear(),
      month: dateFormat.getMonth() + 1,
      day: dateFormat.getDate() - 1,
    };
  }
  /**
   * Delete employee timtable
   * @param timetables {any}
   */
  deleteTimetable(timetables: any) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.employeeServices
            .deleteTimetable(timetables)
            .subscribe((isTimeTableDeleted) => {
              if (isTimeTableDeleted) {
                if (this.employeeId) {
                  this.timetableByEmployee();
                } else {
                  this.timetableByBusiness();
                }
              }
            })
        );
      },
    });
  }
  timetableByBusiness() {
    this.subscriptions.push(
      this.employeeServices
        .getAllTimetableByBusinessId(BaseServices.BusinessId)
        .subscribe((timetableBusinessList: any) => {
          this.timetables = timetableBusinessList;
          this.gridData();
        })
    );
  }
  timetableByEmployee() {
    this.subscriptions.push(
      this.employeeServices
        .getTimeTableByEmployeeId(this.employeeId)
        .subscribe((timetableEmployeeList) => {
          this.timetables = timetableEmployeeList;
        })
    );
  }
  /**
   * start date change event
   * @param event {IMyDateModel}
   */
  onTimeTableStartDateChanged(event: IMyDateModel) {
    this.startDate = event.jsdate;
    let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
    if (event.jsdate) {
      this.TimeTableDateRangeFilter(this.startDate, this.endDate);
    } else {
      this.bindTimetable();
    }
  }

  onHeaderCheckboxClick() {
    // Toggle the header checkbox state
    this.headerCheckboxChecked = !this.headerCheckboxChecked;

    // You can get the number of checkboxes checked in the table
    this.checkedCount = this.tableCheckboxes.filter((cb) => cb.checked).length;
  }

  /**
   * End date change event
   * @param event {IMyDateModel}
   */
  onTimeTableEndDateChanged(event: IMyDateModel) {
    this.endDate = event.jsdate;
    if (event.jsdate) {
      this.TimeTableDateRangeFilter(this.startDate, this.endDate);
    } else {
      this.bindTimetable();
    }
  }
  /**
   * Month change dropdown event for filter
   * @param e {any}
   * @param monthDropdown {any}
   */
  onMonthChanged(e: any, monthDropdown: any) {
    if (this.timetableTable) {
      if (e.value) {
        let selectedMonth = monthDropdown.selectedOption.value;
        selectedMonth = String(selectedMonth).replace(/^0+/, '');
        this.timetableTable.filter(
          selectedMonth,   
          'Month',         
          'equals'         
        );
      }else{        
        this.timetableTable.reset();
      }
    }
  }
  
  /**
   * year change dropdown event for filter
   * @param e {any}
   * @param yearDropdown {any}
   */
  onYearChanged(e: any, yearDropdown: any) {
    this.timetables = <Timetable[]>this.route.snapshot.data['list'];
    var resArr = [];
    this.timetables.forEach(function (item) {
      var i = resArr.findIndex((x) => x.Id == item.Id);
      if (i <= -1) {
        resArr.push(item);
      }
    });
    this.timetables = resArr;
    let currentYear = new Date().getFullYear();
    this.bindTimetableGrid(this.timetables);
    if (e.value) {
      // custom filter datatable
      this.timetableTable.filter(
        yearDropdown.selectedOption.label,
        'Year',
        'equals'
      );
    } else {
      this.timetableTable.reset();
    }
  }

  /**
   * refresh datatable
   */
  refreshTable() {
    this.TimetableForm = this._fb.group({
      FromDate: [''],
      EndDate: [''],
      Month: [],
      Year: [],
    });
    this.timetableTable.reset();
  }
  bindTimetable() {
    this.timetables = <Timetable[]>this.route.snapshot.data['list'];
  }
  /**
   * Export PDF
   */
  exportPdf() {
    let pdfSetting = new PdfSetting();
    pdfSetting.date = HelperService.formatDate(new Date());
    pdfSetting.businessName = BaseServices.BusinessName;
    pdfSetting.pageHeader = 'Employee Timetable List';
    let columns: any = [
      { title: 'Employee Name', dataKey: 'EmployeeName' },
      { title: 'Project Name', dataKey: 'ProjectName' },
      { title: 'Type of Work', dataKey: 'ProjectWorkTypeName' },
      { title: 'Work Date', dataKey: 'StartDate' },
      { title: 'End Date', dataKey: 'EndDate' },
      { title: 'Start Time', dataKey: 'StartTime' },
      { title: 'End Time', dataKey: 'EndTime' },
      { title: 'Hours Spent', dataKey: 'HoursSpent' },
      { title: 'Comments', datakey: 'Comments' },
      { title: 'Approver Name', dataKey: 'ApproverName' },
      { title: 'Approval Date', dataKey: 'ApprovalDate' },
      { title: 'Status', dataKey: 'Status' },
    ];
    HelperService.generatePdf(
      this.timetables,
      columns,
      'Employee-Timetable',
      pdfSetting,
      'l'
    );
  }

  // #endregion

  //#region methods

  /**
   * Binding year dropdown
   */
  bindYears() {
    let currentYear = new Date().getFullYear();
    this.bindTimetableGrid(this.timetables);
    this.timetables.forEach((element) => {
      this.timetables = this.timetables.filter(
        (item: any) => item.Year == String(currentYear)
      );
    });
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.years = [];
        this.years.push({ label: val.SELECT, value: null });
        for (let n = currentYear - 9; n <= currentYear + 1; n++) {
          this.years.push({ label: n, value: n });
        }
      })
    );
  }

  /**
   * TimeTable DateRange Filter
   * @param StartDate {any}
   * @param EndDate {any}
   */
  TimeTableDateRangeFilter(StartDate: any, EndDate: any) {
    let startDate = StartDate ? StartDate : new Date('1800-01-01');
    let endDate = EndDate ? EndDate : new Date();
    this.timetables = [];
    let list = <Timetable[]>this.route.snapshot.data['list'];
    if (list) {
      list = list.filter((x) => {
        return (
          new Date(x.StartDate) >= startDate && new Date(x.EndDate) <= endDate
        );
      });
      this.timetables.push(...list);
    }
  }
  /**
   * to bing time table list
   * @param timeTableList {any}
   */
  bindTimetableGrid(timeTableList: any) {
    this.timetables = [];
    let listForSpentHours;
    if (timeTableList) {
      if (timeTableList.length > 20) {
        listForSpentHours = timeTableList.slice(0, 20);
        this.totalHoursSpent = HelperService.addTimes(
          listForSpentHours,
          'HoursSpent'
        );
      } else {
        this.totalHoursSpent = HelperService.addTimes(
          timeTableList,
          'HoursSpent'
        );
      }

      timeTableList.forEach((timeTable: any) => {
        timeTable.Month = `${new Date(timeTable.StartDate).getMonth() + 1}`;
        timeTable.Year = `${new Date(timeTable.StartDate).getFullYear()}`;
        this.timetables.push(timeTable);
        this.rowsPerPageOptions = [10, 20, 50, 100];
        if (this.timetables.length > 100) {
          this.rowsPerPageOptions.push(this.timetables.length);
        }
      });
    }
  }
  /**
   * filter change event
   * @param event {any}
   */
  employeeFilterChange(event: any) {
    this.filteredValue = event.filteredValue;
    if (this.filteredValue.length > 0) {
      this.totalHoursSpent = HelperService.addTimes(
        this.filteredValue,
        'HoursSpent'
      );
    } else {
      this.totalHoursSpent = 0;
    }
  }
  /**
   * pagination change
   * @param first {number}
   * @param end {number}
   */
  employeePageChange(event: any) {
    let list: any = this.timetables.slice(
      event.first,
      event.first + event.rows
    );
    if (list.length > 0) {
      this.totalHoursSpent = HelperService.addTimes(list, 'HoursSpent');
    } else {
      this.totalHoursSpent = 0;
    }
  }

  /**
   * bind dropdown
   */
  bindDropdowns() {
    this.subscriptions.push(
      this.translate.stream('SELECT_STATUSES').subscribe((val) => {
        this.statusList = [];
        this.statusList.push(
          { label: val.SELECT, value: null },
          { label: val.OPEN, value: '8' },
          { label: val.APPROVED, value: '6' },
          { label: val.REJECTED, value: '10' }
        );
      })
    );
  }
  /**
   * to filter status in list
   * @param e {event}
   * @param statusDropdown {any}
   */
  onStatusChanged(e: any, statusDropdown: any) {
    if (e.value) {
      // custom filter datatable
      this.timetableTable.filter(
        statusDropdown.selectedOption.value,
        'Status',
        'equals'
      );
    } else {
      this.timetableTable.reset();
    }
  }

  /**
   * binding project dropdown
   */
  bindProject() {
    this.project = [];
    let projects = this.route.snapshot.data['project'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.project.push({ label: val.SELECT, value: null });
      })
    );
    if(projects != null){
      projects.forEach((project: any) => {
        this.project.push({ label: project.label, value: project.value });
      });
    }
  }
  /**
   * to change record based on project
   * @param e {ant}
   * @param projects {any}
   */
  onProjectChange(e: any, projects: any) {
    if (e.value) {
      // custom filter datatable
      this.timetableTable.filter(
        projects.selectedOption.value,
        'Projectvalue',
        'equals'
      );
    } else {
      this.timetableTable.reset();
    }
  }

  checked(value: any) {
    if (this.selectedTimetable.length > 0) {
      if (value.status === true) {
        this.selectedTimetable.push(value.Id);
      } else if (value.status === false) {
        this.checkedAll = false;
        this.selectedTimetable = this.selectedTimetable.filter(
          (x: any) => x !== value.Id
        );
      }
    } else {
      if (value.status === true) {
        this.selectedTimetable.push(value.Id);
      } else {
        this.selectedTimetable = [];
      }
    }
  }

  checkAll(event: any) {
    let list: any = [];
    if (event) {
      this.checkedAll = true;
      if (this.filteredValue && this.filteredValue.length > 0) {
        this.filteredValue.forEach((element: any) => {
          list.push(element.Id);
        });
        _.forEach(this.filteredValue, (item: any) => {
          if (event) {
            item.status = true;
          } else {
            item.status = false;
            this.selectedTimetable = [];
          }
        });
        if (list.length > 0) {
          list.forEach((element: any) => {
            this.selectedTimetable.push(element);
          });
        }
      } else {
        list = this.timetables.filter(
          (x) => x.ApprovalUserId === this.userId && x.Status === 8
        );
        _.forEach(list, (item: any) => {
          if (event) {
            item.status = true;
          } else {
            item.status = false;
            this.selectedTimetable = [];
          }
        });
        if (list.length > 0) {
          list.forEach((element: any) => {
            this.selectedTimetable.push(element.Id);
          });
        }
      }
      this.selectAllChecked = event.checked;
    } else {
      _.forEach(this.timetables, (item: any) => {
        item.status = false;
        this.selectedTimetable = [];
      });
    }
  }

  /**
   * employee multi delete
   * @param selectedTimetable
   */
  approveMultiple(selectedTimetable: any) {
    let selectedIds: any = [];
    this.selectedTimetable.forEach((element: any) => {
      selectedIds.push(element.Id);
    });
    if (selectedIds.length > 0) {
      this.confirmationService.confirm({
        message: this.translate.instant('CONFIRMATION_APPROVAL'),
        accept: () => {
          this.subscriptions.push(
            this.employeeServices
              .approveTimeTable(selectedIds)
              .subscribe((result) => {
                if (result) {
                  this.toasterComponent.callToast();
                  this.checkedAll = false;
                  this.timetableByBusiness();
                  this.checkAll(false);
                  this.selectedTimetable = [];
                }
              })
          );
        },
      });
    } else {
      this.isTimeTableSelected = true;
    }
  }

  //Binding months dropdown
  bindMonths(){
    let currentYear = new Date().getFullYear();
    this.selectedYear = { label: currentYear, value: currentYear };
    this.subscriptions.push(
      this.translate.stream('SELECT_MONTH').subscribe((val) => {
        this.months = [];
        this.months.push(
          { label: val.SELECT, value: null },
          { label: val.JANUARY, value: '01' },
          { label: val.FEBRUARY, value: '02' },
          { label: val.MARCH, value: '03' },
          { label: val.APRIL, value: '04' },
          { label: val.MAY, value: '05' },
          { label: val.JUNE, value: '06' },
          { label: val.JULY, value: '07' },
          { label: val.AUGUST, value: '08' },
          { label: val.SEPTEMBER, value: '09' },
          { label: val.OCTOBER, value: '10' },
          { label: val.NOVEMBER, value: '11' },
          { label: val.DECEMBER, value: '12' }
        );
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
  // #endregion
}

import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { DeclarationOfConformity } from '../models/declaration-conformity';
import { BaseServices } from './../../kuba.services';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class DeclarationConformityServices {
  item: Object;
  constructor(private http: HttpClient,
              public translate: TranslateService) {
  }

  /**
   * add declaration of conformity to list
   * @param data {any}
   */
  add(data): Promise<any> {

    const headers = new HttpHeaders();

    let body = JSON.stringify(data);
    let lang = BaseServices.userCultureInfo(this.translate.currentLang);
    headers.append('CultureInfo', lang);
    return this.http
      .post(
        environment.BASE_URL + '/project/projectdeclaration',
        body,
        {headers}
      )
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  // edit declaration
  getDeclaration(id: number) {
    return this.http
      .get(environment.BASE_URL + '/project/projectdeclaration/' + id)
      .map(result => result);
  }

  private extractData(res) {
    let body = res; // return body || {};

    return <DeclarationOfConformity[]>res;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  reportGenerate(businessId: number, cultureInfo: string, ReportJson: any): Observable<Blob> {
    const myData = JSON.stringify({ data: ReportJson });
    const url = `${environment.BASE_URL}/export-DOC/${businessId}/${cultureInfo}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(url, myData, { headers, responseType: 'blob' });
  }
}

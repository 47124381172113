import { ValidationService } from './../../shared/services/validation.service';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
    FormBuilder,
    FormGroup,
    FormControl,
    Validators
} from '@angular/forms';

import { CacheHandlerService } from './../../cached.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { CustomizeHomePage } from './../../customize-homepage/models/customize-homepage';
import { CustomizeHomePageService } from './../../customize-homepage/customize-homepage.service';
import { BaseServices } from '../../kuba.services';
import { BusinessHomepageService } from '../services/businessHomepage.service';
import { Options } from './../../../_models/options';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
@Component({
    templateUrl: 'business-homepage.component.html'
})
export class BusinessHomepageComponent implements OnInit {
    //#region variable

    @ViewChild(ToasterComponent, {static: false}) toasterComponent: ToasterComponent;
    text: any;
    homepageForm: FormGroup;
    items: MenuItem[];
    activeItem: MenuItem;
    customizeHomepage = new CustomizeHomePage();
    loading = false;
    customId: number;
    console: Options;
    businessId: number;
    group: any;
    id: number;
    linkText: string;
    valid: boolean;
    options = [
        new Options(1, 'SHOW_KUBA_CONT'),
        new Options(2, 'SHOW_OWN_CONT')
    ];
    businessInfo: any;
    remoteWindow: Window;
    roleId: number;
    private subscriptions: Subscription[] = [];

    //#endregion

    //#region constructor

    /**
     * constructor
     * @param HomepageSvc {BusinessHomepageService}
     * @param _fb {FormBuilder}
     * @param activatedRoute {ActivatedRoute}
     * @param location {Location}
     * @param customizeHomePageService {CustomizeHomePageService}
     * @param router {Router}
     */
    constructor(
        private HomepageSvc: BusinessHomepageService,
        private _fb: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private customizeHomePageService: CustomizeHomePageService,
        private cacheHandlerService: CacheHandlerService
    ) {

    }

    //#endregion

    //#region page-event

    /**
     * init
    */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.homepageForm = this._fb.group({
            Description: '',
            Link: [
                '',
                [Validators.required, ValidationService.minimunFiveCharacters],
                this.isLinkTextUnique.bind(this)
            ],
            TypeOfContent: ''
        });
        this.console = this.options.filter(item => item.id === 2)[0];
        this.businessId = +this.activatedRoute.parent?.snapshot.params['bid'];
        this.businessInfo = this.activatedRoute.snapshot.data['list'];
        this.items = [
            { label: 'About us' },
            { label: 'Contact us' },
            { label: 'Services' },
            { label: 'Products' }
        ];
        this.activeItem = this.items[0];
        this.changeNavigation(45);
        this.roleId = +BaseServices.roleId;
    }

    //#endregion

    //#region control-event

    // TODO: to be implemented
    // TODO: businessid and application id pointing to same column, need to change it
    saveLinks() {
        this.loading = true;
        this.customizeHomepage.Id = this.id ? this.id : 0;
        this.customizeHomepage.BusinessId = this.businessId;
        this.customizeHomepage.AppId = BaseServices.ApplicationId;
        this.customizeHomepage.BannerImg = this.homepageForm.value.Link;
        this.customizeHomepage.ContentData = this.homepageForm.value.Description;
        this.customizeHomepage.CmsId = this.customId;
        this.customizeHomepage.Type = this.homepageForm.value.TypeOfContent;
        this.customizeHomepage.Status = '1';
        this.customizeHomepage.CreatedBy = BaseServices.UserId;
        if (this.customizeHomepage.Id > 0) {
            this.subscriptions.push(this.customizeHomePageService
                .update(this.customizeHomepage)
                .subscribe((homepageData: any) => {
                    this.toasterComponent.callToast();
                    this.loading = false;
                }));
        } else {
            this.subscriptions.push(this.customizeHomePageService
                .add(this.customizeHomepage)
                .subscribe((homepageData: any) => {
                    this.toasterComponent.callToast();
                }));
        }
    }
    /**
     * homepage tab navigation
     * @param cmsId {number}
     */
    changeNavigation(cmsId: number) {
        this.customId = cmsId;
        let link = '';
        let description = '';
        let type = '';
        let applicationId = BaseServices.ApplicationId;
        this.subscriptions.push(this.customizeHomePageService
            .getData(applicationId, this.customId, BaseServices.UserId)
            .subscribe((homePageData: any) => {
                this.id = homePageData.Id;
                if (homePageData) {
                    link = homePageData.BannerImg
                        ? homePageData.BannerImg
                        : '',
                        description = homePageData.ContentData
                            ? homePageData.ContentData
                            : '',
                        type = homePageData.Type ? homePageData.Type : 2;
                    let data = {
                        Link: link,
                        Description: description,
                        TypeOfContent: type
                    };
                    (<FormGroup>this.homepageForm).setValue(data, {
                        onlySelf: true
                    });
                } else {
                    this.homepageForm = this._fb.group({
                        Description: '',
                        Link: [
                            '',
                            [Validators.required, ValidationService.minimunFiveCharacters],
                            this.isLinkTextUnique.bind(this)
                        ],
                        TypeOfContent: ''
                    });
                }
            }));


    }

    back() {
        this.location.back();
    }

    isLinkTextUnique(control: FormControl) {
        const q = new Promise(resolve => {
            setTimeout(() => {
                if (control.value === '' || control.value === this.linkText) {
                    resolve(null);
                } else {
                    this.HomepageSvc.checkExistingLink(control.value).subscribe(
                        x => {
                            if (!x) {
                                this.valid = true;
                                this.linkText = this.homepageForm.value.Link;
                                setTimeout(() => {
                                    this.valid = false;
                                }, 1000);
                                resolve(null);
                            } else {
                                resolve({ isLinkTextUnique: true });
                            }
                        },
                        () => {
                            resolve({ isLinkTextUnique: true });
                        }
                    );
                }
            }, 1000);
        });
        return q;
    }

    /**
   * remote mode event
   * @param event {any}
   * @param business {any}
   */
    rModeHomePage() {
        let business = this.businessInfo.filter((x:any) => x.Id === +this.businessId);
        if (this.remoteWindow) {
            this.remoteWindow.close();
        }
        this.cacheHandlerService.clearCachedData();
        let businessSetting = { 'BusinessName': business[0].CompanyName, 'BusinessLogo': business[0].BusinessLogo };
        sessionStorage.setItem('RModeBusiness', JSON.stringify(businessSetting));
        sessionStorage.setItem('RModeBusinessId', business[0].Id);
        let windowOrigin = window.location.origin;
        let windowControl = 'location=yes,width=' + screen.availWidth + ', height = ' + screen.availHeight
            + ', scrollbars = yes, status = yes';
        this.remoteWindow = window.open(windowOrigin + '/ik/app/business/' + business[0].Id + '/dashboard', 'Remote', windowControl)!;
    }
    
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
    //#endregion
}

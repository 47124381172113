import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

import { BaseServices } from './../../kuba.services';
import { deviationCaseType } from './../models/deviationCaseType';
import { Department } from './../models/deviationDepartment';
import { DeviationShared } from './../models/deviationShared';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class DeviationServices {
  constructor(private http: HttpClient) {}
  getAll(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/project/deviation/list/' + id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  getAllUsers() {
    return this.http
      .get(environment.BASE_URL + '/allusers', BaseServices.headerOption)
      .map((result: any) => result);
  }

  // list deviation based on parent
  getAllByEach(id: number, key: string) {
    return this.http
      .get(
        `${environment.BASE_URL}/${BaseServices.apiRoute(
          key
        )}/deviation/list/${id}`,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }

  // Add Deviation
  addDeviation(deviation: DeviationShared, key: string) {
    return this.http
      .post(
        `${environment.BASE_URL}/${BaseServices.apiRoute(key)}/deviation`,
        deviation,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  // Update Deviation
  updateDeviation(deviation: DeviationShared, key: string, mID: number) {
    let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
    let languageId = parseInt(sessionStorage.getItem('languageId'));
    let CultureInfo = '';
    switch (languageId) {
      case 1:
        CultureInfo = 'en-GB';
        break;
      case 2:
        CultureInfo = 'no-NO';
        break;
      case 3:
        CultureInfo = 'sv-SE';
        break;
      case 4:
        CultureInfo = 'pl-PL';
        break;
    }
    deviation.LanguageId = languageId;
    if (key === undefined) {
      return this.http
        .put(
          `${environment.BASE_URL}/${BaseServices.apiRoute(
            key
          )}deviation/${CultureInfo}/${mID}`,
          deviation,
          BaseServices.headerOption
        )
        .map((result) => result);
    } else {
      return this.http
        .put(
          `${environment.BASE_URL}/${BaseServices.apiRoute(
            key
          )}/deviation/${CultureInfo}`,
          deviation,
          BaseServices.headerOption
        )
        .map((result) => result);
    }
  }
  // delete the topics
  deleteTopics(Id: number) {
    return this.http
      .delete(environment.BASE_URL + '/Delete/' + Id, BaseServices.headerOption)
      .map((response: HttpHeaderResponse) => {
        return response;
      });
  }
  // add the topics
  addTopics(data: any) {
    return this.http
      .post(environment.BASE_URL + '/Save', data, BaseServices.headerOption)
      .map((response: HttpHeaderResponse) => {
        return response;
      });
  }
  // update the topics
  updateTopics(data: any) {
    return this.http
      .put(environment.BASE_URL + '/Update', data, BaseServices.headerOption)
      .map((response: HttpHeaderResponse) => {
        return response;
      });
  }

  getDeviationLog(deviationId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/project/deviation-log/' + deviationId,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  getUser(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/project/ContactLookup/' + id,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }

  getById(id: number) {
    return this.http
      .get(environment.BASE_URL + '/deviation/' + id, BaseServices.headerOption)
      .map((result: any) => result);
  }
  getAllTopics(Id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/DeviationTopic/' + Id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  getAllCaseType() {
    return this.http
      .get(
        environment.BASE_URL +
          '/casetypes/' +
          BaseServices.BusinessId +
          '/' +
          BaseServices.roleId,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }
  // delete deviation list
  deleteDev(Id: any) {
    return this.http
      .delete(
        environment.BASE_URL + '/deviation/' + Id,
        BaseServices.headerOption
      )
      .map((result) => result)
      .catch(this.handleError);
  }

  delete(id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/Deviation/' + id,
        BaseServices.headerOption
      )
      .map((result) => result)
      .catch(this.handleError);
  }

  getCaseTypeById(id: number) {
    return this.http
      .get(environment.BASE_URL + '/CaseType/' + id, BaseServices.headerOption)
      .map((result) => result);
  }

  // add Case type dropdown
  createCaseType(caseType: deviationCaseType) {
    return this.http
      .post(
        environment.BASE_URL + '/CaseType',
        JSON.stringify(caseType),
        BaseServices.headerOption
      )
      .map((response: HttpHeaderResponse) => {
        return response;
      });
  }

  // edit case type dropdowm
  updateCaseType(caseType: deviationCaseType) {
    return this.http
      .put(
        environment.BASE_URL + '/CaseType/' + caseType.Id,
        caseType,
        BaseServices.headerOption
      )
      .map((response: HttpHeaderResponse) => {
        return response;
      });
  }

  // delete case type dropdown
  deleteType(id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/CaseType/' + id,
        BaseServices.headerOption
      )
      .map((response: HttpHeaderResponse) => {
        return response;
      });
  }

  // add  the department
  addDepartment(data: any) {
    return this.http
      .post(
        environment.BASE_URL + '/department',
        data,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  // update  the department
  updateDepartment(department: Department) {
    return this.http
      .put(
        environment.BASE_URL + '/department/' + department.Id,
        department,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  //  delete  the department
  deleteDepartment(id: number) {
    return this.http.delete(
      environment.BASE_URL + '/departmentdelete/' + id,
      BaseServices.headerOption
    );
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  // check if type case is exist or not
  checkTypecaseExist(caseName: string) {
    return this.http
      .get(
        environment.BASE_URL + '/checkcasename/' + caseName,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  // check if topic is exist or not
  checkTopicExist(topicName: string) {
    return this.http
      .get(
        environment.BASE_URL + '/checktopicname/' + topicName,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  // check if deparment is exists or not
  checkDepartmentExist(departmentName: string) {
    return this.http
      .get(
        environment.BASE_URL + '/checkdepartmentname/' + departmentName,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  getAllDeviationByDepartment(userId) {
    return this.http
      .get(
        `${environment.BASE_URL}/deviations/${userId}`,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  checkExistingTypeForDelete(caseId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/checkcase/' + caseId,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  getDepartmentForAdmin(deviationId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/getdepartmentbybusinessid/' + deviationId,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }
  checkTopicForDeviation(topicId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/checktopicfordeviation/' + topicId,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  reportGenerate(
    businessId: number,
    parentId: number,
    cultureInfo: string,
    ReportJson: any,
    Logging: string
  ) {
    let loginName = BaseServices.userProfile['Name'];
    let dynamicType = 'application/pdf';
    let myData = JSON.stringify({ data: ReportJson });
    let url = `${environment.BASE_URL}/export-DeviationBulkPrint/${businessId}
        /${parentId}/${cultureInfo}/${loginName}/${Logging}`;
    return Observable.create((observer) => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      let session = JSON.parse(sessionStorage.getItem('session'));
      let type = 'Bearer';
      let token = session.AccessToken;
      const tokenParts = token.split('.');
      tokenParts[1] =
        tokenParts[1].substring(0, 5) + 'W' + tokenParts[1].substring(5);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', type + ' ' + token);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }

  deviationReportGenerate(
    projectId: number,
    businessId: number,
    deviationId: number,
    cultureInfo: string,
    deviation: any,
    FeatureId: number,
    userId: number
  ) {
    const url = `${environment.BASE_URL}/export-DeviationPrintDetails/${projectId}/${businessId}/${deviationId}/${cultureInfo}/${FeatureId}/${userId}`;

    return this.http.post(url, deviation, { responseType: 'blob' });
  }

  deleteDeviationAttachment(Id) {
    return this.http
      .delete(
        environment.BASE_URL + '/deletedeviationattachment/' + Id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  getAssignedBusinessManual(businessId: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/deviations/manualList/${businessId}`,
        BaseServices.headerOption
      )
      .map((result: any) => result)
      .catch((error) => BaseServices.handleError(error));
  }
  getResponsibleAndAssignedUsers(
    businessId: number,
    featureId: number,
    featureKey: number
  ) {
    return this.http
      .get(
        `${environment.BASE_URL}/assignedresponsibleusers/${businessId}/${featureId}/${featureKey}`,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }
  getResponsibleAndAssignedUsersForFollowup(
    businessId: number,
    featureId: number,
    featureKey: number
  ) {
    return this.http
      .get(
        `${environment.BASE_URL}/assignedresponsibleusersfollowup/${businessId}/${featureId}/${featureKey}`,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  getAssignedUsers(
    businessId: number,
    featureId: number,
    featurekey: number,
    applicationId: number
  ) {
    return this.http
      .get<any>(
        `${environment.BASE_URL}/AssignedPersons/${businessId}/${featurekey}/${featureId}/${applicationId}`,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
}

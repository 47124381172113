import { TreeView } from './../models/index';
import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from './../services/common.services';

@Component({
    moduleId: module.id,
    selector: 'common-image',
    templateUrl: 'common-image.component.html'
})

export class CommmonImageComponent implements OnInit {
    @Input('nodeData') nodeData: any;
    @Input('treeData') treeData: TreeView;
    uploadedFiles: any[] = [];
    constructor(
    ) { }

    ngOnInit() { }

    onUpload(event) {
        let file = event.files;

        this.uploadedFiles.push(file);
    }

    onBeforeSend(ev) {
    }
}

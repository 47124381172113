import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { EL } from 'src/app/kuba/kunde-EL/models/el-edit';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ELService {

    featureId: number;
    workingModule: any;

    constructor(private http: HttpClient) {
        this.setWorkingModule();
    }

    getClientContactUser(clientId: number) {
        return this.http.get(environment.BASE_URL + '/clientcontact/clientcontactsuser/' + clientId, BaseServices.headerOption)
            .map(result => result);
    }

    getELList() {
        return this.http.get(environment.BASE_URL + '/kundeEL/getall/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getELByUser(Id: number) {
        return this.http.get(environment.BASE_URL + '/kundeEL/getuserEL/' + Id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    create(newEL: EL) {
        return this.http.post(environment.BASE_URL + '/kundeEL/save', newEL, BaseServices.headerOption)
            .map(result => result);
    }

    getELById(id: number) {
        let result: any;
        return this.http.get(environment.BASE_URL + '/kundeEL/' + id, BaseServices.headerOption)
            .map((res: any) => res);
    }

    update(ExistingEL: EL) {
        return this.http.put(environment.BASE_URL + '/kundeEL/update', ExistingEL, BaseServices.headerOption)
            .map(result => result);
    }

    deleteEL(id: number) {
        return this.http.delete(environment.BASE_URL + '/kundeEL/delete/' + id, BaseServices.headerOption)
            .map(result => result);
    }


    getELFeatures(businessId: number) {
        this.setWorkingModule();
        return this.http.get(environment.BASE_URL + '/business/' + businessId, BaseServices.headerOption)
            .map((result: any) => {
                let feature = result.Features;
                let elAdditionalFeature = _.find(JSON.parse(feature), { 'id': this.featureId, 'access': true });
                return elAdditionalFeature;
            });
    }
    getELByUserId(userId: number) {
        return this.http.get(`${environment.BASE_URL}/kundeEL/getallbyuser/${userId}`, BaseServices.headerOption)
            .map(result => result);

    }
    deleteElAttachment(Id) {
        return this.http
            .delete(environment.BASE_URL + '/kundeEL/deleteELattachment/' + Id, BaseServices.headerOption)
            .map(result => result);
    }

    setWorkingModule() {
        this.workingModule = JSON.parse(
            sessionStorage.getItem('workingModule')
        );
        if (this.workingModule !== null) {
            this.featureId = this.workingModule.id;
        }
    }

    getActiveClientContactUser(clientId: number) {
        return this.http.get(environment.BASE_URL + '/clientcontact/activeclientcontactsuser/' + clientId, BaseServices.headerOption)
            .map(result => result);
    }
}

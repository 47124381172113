import { BaseServices } from 'src/app/kuba/kuba.services';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Rights } from 'src/app/_models';

@Component({
    selector: 'fdv-list-all',
    templateUrl: 'fdvListAll.component.html'
})
export class FdvListAllComponent implements OnInit {

    isUser: boolean;
    isHideMyFDV = true;
    isAdministrateFDV = true;
    /**
     * constructor
     * @param route {{Router}}
     * @param activatedRoute {{ActivatedRoute}}
     */
    constructor(
        private route: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        if (BaseServices.UserRole === 'Guest') {
            this.isAdministrateFDV = false;
            this.route.navigate(['myList'], { relativeTo: this.activatedRoute });            
        } else if (BaseServices.UserRole === 'User') {
            this.onAdministrateProject();
        } else {
            this.OnAdmin();
        }
    }

    onAdministrateProject() {
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.ADMINISTRATE_FDV;
        if (currentUserRole === 'User') {
            this.isAdministrateFDV = BaseServices.checkUserRights(userRightsId, currentUserRole);
            this.route.navigate(['myList'], { relativeTo: this.activatedRoute });
        } else {
            this.isAdministrateFDV = true;
            this.route.navigate(['list'], { relativeTo: this.activatedRoute });
        }
    }

    OnAdmin(){
        let curSession = JSON.parse(sessionStorage.getItem('RModeBusinessId'));
        if(curSession > 0)
        {
            this.isAdministrateFDV=true;
            this.isHideMyFDV = false;
            this.route.navigate(['list'], { relativeTo: this.activatedRoute });
        }
        else{
            this.isAdministrateFDV = true;
            this.route.navigate(['list'], { relativeTo: this.activatedRoute });
        }
    }
}
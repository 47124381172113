import { BaseServices } from '../../../kuba/kuba.services';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ContactServices } from './../services/contact.service';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
    selector: 'customer-contact',
    templateUrl: 'customerContact.component.html'
})
export class CustomerContactComponent implements OnInit {
    //#region variables
    @ViewChild('contactsTable',{static:false}) contactsTable: Table;
    selectedContacts: any = [];
    typeOfcontacts: SelectItem[];
    cTypeOfWork: any;
    contacts: any;
    viewExport = false;
    viewFilter = false;
    isSelectedValue = false;
    additionalData: any;
    functions: SelectItem[];
    functionList: any[];
    business: SelectItem[];
    private subscriptions: Subscription[] = [];
    isPortal = false;
    //#endregion
    //#region constructor
    /**
     * constructor
     * @param contactSvc {contactSvc}
     * @param confirmationService {confirmationService}
     * @param activatedRoute {activatedRoute}
     */
    constructor(
        private contactSvc: ContactServices,
        private confirmationService: ConfirmationService,
        private route: ActivatedRoute,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService
    ) {
        this.subscriptions.push(this.translate.stream('FILENAME').subscribe(val => { 
        this.additionalData = {
            fileName: val.CUSTOMER_CONTACT_LIST,
            header: 'Customer Contact List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Name', dataKey: 'Name' },
                { title: 'Cellular Phone', dataKey: 'Telephone' },
                { title: 'Email Id ', dataKey: 'Emailid' },
                { title: 'Function ', dataKey: 'ContactFunctionalAreaName' },
                { title: 'Company Name ', dataKey: 'BusinessName' }
            ]
        };
    }));
    }
    //#endregion
    //#region page-event
    /**
     * page events
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.isPortal = (BaseServices.roleId === '2');
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.functions = [];
            this.functions.push(
                { label: val.SELECT, value: null }
            );
        }));
        this.functionList = this.activatedRoute.snapshot.data['function'];
        if (this.functionList) {
            this.functionList.forEach((element: any) => {
                this.functions.push({ label: element.Name, value: element.Id });
            });
        }
        this.typeOfcontacts = [];
        this.typeOfcontacts.push({ label: 'All', value: '' });
        this.functionList = this.activatedRoute.snapshot.data['function'];
        if (this.functionList) {
            this.functionList.forEach((e: any) => {
                this.typeOfcontacts.push({ label: e.Name, value: e.Id });
            });
        }
        let businessList = this.route.snapshot.data['business'];
        if (businessList) {
            this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                this.business = [];
                this.business.push(
                    { label: val.SELECT, value: null }
                );
            }));
            businessList.forEach((list: any) => {
                this.business.push({
                    label: list.CompanyName,
                    value: list.Id
                });
            });
        }
        this.contacts = this.activatedRoute.snapshot.data['list'];
    }
    //#endregion
    //#region control-event
    /**
     * type dropdown change event
     */
    onTypeOfContactChanged(typeOfContactsDropdown: any) {
        if (typeOfContactsDropdown.selectedOption.value) {
            this.contactsTable.filter(
                typeOfContactsDropdown.selectedOption.value,
                'ContactTypeId',
                'equals'
            );
        } else {
            this.contactsTable.reset();
        }
    }
    onFunctionChanged(e: any, functionDropdown: any) {
        this.contactsTable.filter(
            functionDropdown.selectedOption.value,
            'ContactFunctionalAreaId',
            'equals'
        );
        if (e.value == null) {
            this.contactsTable.reset();
        }
    }
    onBusinessChanged(e: any, businessDropdown: any) {
        this.contactsTable.filter(
            businessDropdown.selectedOption.label,
            'BusinessName',
            'contains'
        );
        if (e.value == null) {
            this.contactsTable.reset();
        }
    }
    /**
     * row select event
     * @param selectedValue
     */
    onRowSelect(selectedValue:any) {
        if (this.selectedContacts) {
            this.isSelectedValue = true;
        } else {
            this.isSelectedValue = false;
        }
    }
    /**
     * row unselect event
     */
    onRowUnselect(selectedValue:any) {
        if (this.selectedContacts.length > 0) {
            this.isSelectedValue = true;
        } else {
            this.isSelectedValue = false;
        }
    }
    //#endregion
    //#region method
    /**
     * delete selected contacts
     */
    deleteContacts() {
        let contactIds: any = [];
        if (this.selectedContacts.length > 0) {
            this.selectedContacts.forEach((e: any) => {
                contactIds.push(e.Id);
            });
            this.confirmationService.confirm({
                message: this.translate.instant('DELETE_THIS_RECORD'),
                accept: () => {
                    this.subscriptions.push(this.contactSvc
                        .deleteCustConatcts(contactIds)
                        .subscribe(() => {
                            this.subscriptions.push(this.contactSvc
                                .getAllCustContacts()
                                .subscribe(res => (this.contacts = res)));
                        }));
                }
            });
            this.selectedContacts = [];
        } else {
            alert('Please select at least one Contact to Delete');
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
    //#endregion
}

import { FeatureKey } from './../../../_models/feature';
import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  ActivatedRoute,
  Router,
  Params,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Location } from '@angular/common';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import {
  UploadFile,
  UploaderOptions,
  UploadInput,
  UploadStatus,
  UploadOutput,
} from 'ngx-uploader';
import { IMyDpOptions } from 'mydatepicker';

import { Execution } from './../../follow-ups/models/activities';
import { CompleteCheckList } from '../../shared/models/complete-checklist';
import { DeviationServices } from './../../deviation/services/deviation.service';
import { RiskanalysisService } from 'src/app/kuba/follow-ups/services/risk-analysis.service';
import {
  TaskPlanList,
  ActivitySchedule,
} from './../../follow-ups/models/riskanalysis';
import { environment } from './../../../../environments/environment';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';

import { ToasterComponent } from './../../../_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { Options } from './../../../_models/options';
import { Auditlogdata } from './../models/safetyLog';
import { HelperService } from './../../../_services/helper.service';
import {
  TaskPlan,
  ActivityAttachment,
  FeatureMapping,
  ActivityScheduleDto,
} from './../models/taskplan';
import { Topic } from './../models/topic';
import { JobAnalysisService } from './../services/jobanalysis.services';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { Safetyjob, Issue, Activity, SafetyJobListedit } from '../models/index';
import { SafetyBaseComponent } from './safety-base.component';
import { Rights } from 'src/app/_models';
import { FeaturesBaseServices } from 'src/app/kuba/features/services/features-base.services';

import * as _ from 'lodash';
import {
  FeatureBase,
  Feature,
} from 'src/app/kuba/features/models/features-base.model';
import { DashboardService } from 'src/app/kuba/dashboard/Services/dashboard.services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'safetyjob-edit',
  templateUrl: './safety-Job-analysis-edit.component.html',
  styleUrls: ['./safety-Job-analysis-edit.component.css'],
  providers: [HelperService, JobAnalysisService],
})
export class EditComponent extends SafetyBaseComponent implements OnInit {
  //#region variables
  safetyData = new TaskPlan();
  parentKey: any;
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  safetyLogData: Auditlogdata[];
  editSafetyJobAnalysis: FormGroup;
  isDescription = true;
  isWhatCanHappen = false;
  isCheckList = false;
  addTaskPlan: FormGroup;
  isTaskAdded = false;
  sjaList: any;
  topicForm: FormGroup;
  typeForm: FormGroup;
  showNotification = false;
  safetyjob: any;
  safetyjoblist: Safetyjob[];
  activityId: any;
  safetyJobId: number;
  Topic: SelectItem[];
  Issue: SelectItem[];
  taskPlanList: any = [];
  taskList = [];
  probability: SelectItem[];
  consequence: SelectItem[];
  Execution: SelectItem[];
  Followup: SelectItem[];
  ChecklistData: SelectItem[];
  execution: any;
  editTypes: any = [];
  editTopics: any = [];
  issuetype: any;
  topic: any;
  selectedType = new Issue();
  selectedTopic = new Topic();
  parentId: number;
  time: any;
  createdby: any;
  createdById: any;
  createdon: any;
  task: any;
  taskIndex: number;
  options = [
    new Options(7, 'NEW'),
    new Options(8, 'OPEN'),
    new Options(9, 'DONE'),
    new Options(10, 'REJECTED'),
  ];
  upConfig: UploaderConfig;
  uploadedFiles: any = [];
  uploadedAcitivityFiles: ActivityAttachment[] = [];
  additionalData: any;
  newDocumentAdded: any = [];
  newTaskDocumentAdded: any = [];
  hidingElement = false;
  addType = false;
  addTopic = false;
  locale: any;
  topicId: number;
  issueId: number;
  isFileChoosed = false;
  files: UploadFile[] = [];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  option: UploaderOptions;
  activityDocument: any;
  editActivityDocument: any;
  upConfigActivity: UploaderConfig;
  checklistId?: number;
  CompleteCheckList: CompleteCheckList[];
  jobId: number;
  istopicfunctionExists = false;
  topicFunctionExisting: string;
  isfunctionExists = false;
  functionExisting: string;
  loading = false;
  isTopicExists = false;
  isCaseExists = false;
  sjaFeatureId: number;
  projectKey: number;
  copyDocForm: FormGroup;
  showCopyLinkDailog = false;
  isHideGuestButton = true;
  FeatureId: number;
  hideSJAChecklist = false;
  isICSJA = false;
  isFS = false;

  private subscriptions: Subscription[] = [];
  /**
   * Date picker configuration option
   */
  public startDateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    height: '32px',
    selectionTxtFontSize: '14px',
    disableUntil: this.getCurrentDate(),
  };

  documentName: any;
  //#endregion variables

  //#region constructor
  /**
   * constructor
   * @param formBuilder {{FormBuilder}}
   * @param route {{ActivatedRoute}}
   * @param router {{Router}}
   * @param safetyjobservice {{JobAnalysisService}}
   * @param confirmationService {{ConfirmationService}}
   * @param _location {{Location}}
   */
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private safetyjobservice: JobAnalysisService,
    private riskanalysisService: RiskanalysisService,
    private confirmationService: ConfirmationService,
    private _location: Location,
    private router: Router,
    private deviationService: DeviationServices,
    private translate: TranslateService,
    private _fb: FormBuilder,
    public featuresBaseServices: FeaturesBaseServices,
    private dashboardService: DashboardService
  ) {
    super();
    this.parentId = route.snapshot.parent!.parent!.params['id']; // feature key
    this.parentKey = route.snapshot.parent!.parent!.data['parent']; // feature id name
    this.jobId = route.snapshot.params['jobId'];
    this.copyDocForm = this._fb.group({
      filePath: [''],
    });
    if (
      this.parentKey === 'IC' ||
      this.parentKey === 'OS' ||
      this.parentKey === 'QS'
    ) {
      this.isICSJA = true;
    } else if (this.parentKey === 'FS') {
      this.isFS = true;
    }
  }

  checkSJAChecklistFeatureChecklist(feature: any) {
    if (feature) {
      if (feature.length > 0) {
        this.hideSJAChecklist = !this.flattenFind(
          feature,
          FeatureKey.SJA_CHECKLIST
        );
      }
    }
  }

  //#endregion

  //#region page-events
  /**
   * edit safety job analysis bind dropdowns
   */
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    this.FeatureId =
      this.parentKey === 'PROJECT'
        ? FeatureKey.PROJECT
        : this.parentKey === 'FDV'
        ? FeatureKey.FDV
        : this.parentKey === 'IC'
        ? FeatureKey.INTERNAL_CONTROL
        : this.parentKey === 'FS'
        ? FeatureKey.FOOD_SAFETY
        : this.parentKey === 'VEHICLE'
        ? FeatureKey.VEHICLES
        : this.parentKey === 'FRAMEWORK'
        ? FeatureKey.FRAMEWORK_AGREEMENT
        : this.parentKey === 'SERIVCE'
        ? FeatureKey.SERVICE_AGREEMENT
        : this.parentKey == 'KUNDE_EL'
        ? FeatureKey.KUNDE_EL
        : this.parentKey == 'OS'
        ? FeatureKey.OTHER_SYSTEMS
        : this.parentKey == 'QS'
        ? FeatureKey.QUALITY_SYSTEMS
        : 0;
    if (+BaseServices.roleId === 5) {
      this.isHideGuestButton = this.hideTypeOfcase(this.parentKey);
    } else if (+BaseServices.roleId !== 3) {
      this.isHideGuestButton = false;
    }
    if (window.name === 'Remote') {
      this.subscriptions.push(
        this.dashboardService.getRModeFeature().subscribe((feature: any) => {
          if (feature) {
            let RModefeaturesDetails =
              FeaturesBaseServices.getFeatureDetails(feature);
            RModefeaturesDetails = RModefeaturesDetails.filter(
              (x: any) => x.id === this.FeatureId
            );
            this.checkSJAChecklistFeatureChecklist(RModefeaturesDetails);
          }
        })
      );
    } else {
      let featuresBaseDetails = FeaturesBaseServices.getFeatureDetails(
        BaseServices.getUserFeatures()
      );
      featuresBaseDetails = featuresBaseDetails.filter(
        (x: any) => x.id === this.FeatureId
      );
      this.checkSJAChecklistFeatureChecklist(featuresBaseDetails);
    }

    this.projectKey = FeatureKey.PROJECT;
    this.sjaFeatureId = FeatureKey.SAFETY_JOB_ANALYSIS;
    if (this.parentKey === 'PROJECT') {
      this.isDescription = false;
      this.isWhatCanHappen = true;
    } else {
      this.isDescription = true;
      this.isWhatCanHappen = false;
    }
    if (
      this.parentKey === 'IC' ||
      this.parentKey === 'OS' ||
      this.parentKey === 'QS'
    ) {
      this.isCheckList = true;
    }
    this.uploadInput = new EventEmitter<UploadInput>();
    this.topicForm = this.formBuilder.group({
      Name: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.typeForm = this.formBuilder.group({
      Name: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.editSafetyJobAnalysis = this.formBuilder.group({
      Status: [''],
      TopicId: ['', Validators.required],
      IssueTypeId: ['', Validators.required],
      Probability: [1, Validators.required],
      Location: [''],
      RiskFigure: [''],
      Consequence: [1, Validators.required],
      checklistId: [null],
      WhatCanHappen: ['', [Validators.required, Validators.nullValidator]],
    });
    this.addTaskPlan = this.formBuilder.group({
      Id: [''],
      Title: ['', Validators.required],
      EndDate: ['', Validators.required],
      Description: [''],
      NotifyBeforeDeadLine: [0],
      FollowupUser: ['', Validators.required],
      ExecutionUser: ['', Validators.required],
    });
    this.setRiskFigure(1, 1); // setting default value
    // get routing params
    this.subscriptions.push(
      this.route.params.subscribe((params: Params) => {
        const sid = Number(params['jobId']);
        this.safetyJobId = sid;
        if (!sid) {
          console.error('Invalid Safety Job ID');
          return;
        }
  
        this.fetchActivities();
        const result = this.route.snapshot.data['edit'];
        if (!result) {
          console.error('Edit data is unavailable');
          return;
        }
  
        this.handleStatusOptions(result);
        this.createdById = result.CreatedBy;
        this.createdby = result.CreatorName;
        this.createdon = new Date(result.CreatedOn).toLocaleDateString();
        this.time = new Date(result.CreatedOn).toLocaleTimeString();
  
        this.populateSafetyJob(result);
        this.setRiskFigure(result.Probability, result.Consequence);
      })
    );
    this.additionalData = {
      ApplicationId: BaseServices.ApplicationId,
      BusinessId: BaseServices.BusinessId,
      ParentId: this.safetyJobId,
      Status: '1',
      CreatedOn: new Date(),
      ModifiedBy: BaseServices.UserId,
      ModifiedOn: new Date(),
    };
    this.upConfig = {
      title: 'MORE_ATTACHMENTS',
      titleAsLabel: true,
      editMode: true,
      windowedHeight: false,
      viewSwitch: false,
      showUserMeta: false,
      showSearchFilter: false,
      showUploadButton: true,
      showDeleteButton: +BaseServices.roleId === 4 ? false : true,
      uploaderUri:
        environment.BASE_URL +
        '/file/upload/business/project/risk-analysis-docs',
      addtionalData: this.additionalData,
    };
    this.Execution = [];
    this.Followup = [];
    this.probability = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
    ];
    this.consequence = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
    ];

    let userList = this.route.snapshot.data['user'];
    if (userList) {
      userList.forEach((user: any) => {
        this.Execution.push({
          label: user.Name,
          value: user.Id,
        });
        this.Followup.push({
          label: user.Name,
          value: user.Id,
        });
      });
    }
    this.bindTopicDropdown();
    this.bindIssueDropdown();
    this.safetyLog();
    this.bindChecklistDropDown();
    this.bindCompleteChecklist();
    this.bindSJADetails();
  }
  //#endregion

  //#region methods
  /**
   * get current date
   */
  getCurrentDate() {
    let dateFormat = new Date();
    return {
      year: dateFormat.getFullYear(),
      month: dateFormat.getMonth() + 1,
      day: dateFormat.getDate() - 1,
    };
  }

  /**
   * bind issue dropdown
   */
  bindIssueDropdown() {
    this.Issue = [];
    this.editTypes = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.Issue = [];
        this.Issue.push({ label: val.SELECT, value: null });
        let result = this.route.snapshot.data['issue'];
        if (result) {
          result.forEach((issue: any) => {
            this.Issue.push({ label: issue.Name, value: issue.Id });
            this.editTypes.push({ Name: issue.Name, Id: issue.Id });
          });
        }
      })
    );
    // });
  }
  /**
   * bind topic dropdown
   */
  bindTopicDropdown() {
    this.Topic = [];
    this.editTopics = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.Topic = [];
        this.Topic.push({ label: val.SELECT, value: null });
        this.topic = this.route.snapshot.data['topic'];
        if (this.topic) {
          this.topic.forEach((topic: any) => {
            this.Topic.push({ label: topic.Name, value: topic.Id });
            this.editTopics.push({ Name: topic.Name, Id: topic.Id });
          });
        }
      })
    );
  }

  bindChecklistDropDown() {
    this.ChecklistData = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.ChecklistData = [];
        this.ChecklistData.push({ label: val.SELECT, value: null });
      })
    );

    this.subscriptions.push(
      this.safetyjobservice
        .getCheckListData(
          BaseServices.BusinessId,
          this.FeatureId,
          this.parentId
        )
        .subscribe((result: any) => {
          result.forEach((checklist: any) => {
            this.ChecklistData.push({
              label: checklist.Title,
              value: checklist.Id,
            });
          });
        })
    );
  }

  bindCompleteChecklist() {
    this.CompleteCheckList = [];
    this.subscriptions.push(
      this.safetyjobservice
        .getCompleteCheckListData(
          BaseServices.BusinessId,
          FeatureKey.SAFETY_JOB_ANALYSIS,
          this.jobId
        )
        .subscribe((result: any) => {
          if (result) {
            this.CompleteCheckList = result;
          }
        })
    );
  }

  confirmCompleteChecklistDelete(id: any, path: any) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        if (path) {
          this.subscriptions.push(
            this.safetyjobservice.deleteDocument(id).subscribe((result) => {
              if (result) {
                this.toasterComponent.callToastDlt();
                this.bindCompleteChecklist();
              }
            })
          );
        } else {
          this.subscriptions.push(
            this.safetyjobservice
              .deleteCompleteCheckListData(id)
              .subscribe((result) => {
                if (result) {
                  this.toasterComponent.callToastDlt();
                  this.bindCompleteChecklist();
                }
              })
          );
        }
      },
    });
  }

  completeCheckList() {
    let id = this.editSafetyJobAnalysis.value.checklistId;
    this.router.navigate(
      [
        '../../../../../../safety-job/new/' + id,
        'project',
        this.sjaFeatureId,
        this.jobId,
        this.FeatureId,
        this.parentId,
      ],
      { relativeTo: this.route }
    );
  }

  /**
   * get safety log list
   */
  safetyLog() {
    this.subscriptions.push(
      this.safetyjobservice
        .getSafetyLog(this.safetyJobId)
        .subscribe((result: any) => {
          this.safetyLogData = result;
        })
    );
  }
  /**
   * get safety job analysis list
   */
  getData() {
    this.subscriptions.push(
      this.safetyjobservice.getAll(this.parentId).subscribe((result: any) => {
        this.safetyjoblist = result;
      })
    );
  }
  /**
   * save safety job analysis
   */
  saveChanges(mode?: string) {
    let safetyjob = new SafetyJobListedit();
    safetyjob.Id = this.safetyJobId ? this.safetyJobId : 0;
    safetyjob.BusinessId = BaseServices.BusinessId;
    safetyjob.ApplicationId = BaseServices.ApplicationId;
    safetyjob.CreatedOn = new Date();
    safetyjob.CreatedBy = this.createdById;
    safetyjob.CreatorName = this.createdby;
    safetyjob.IssueTypeId = this.editSafetyJobAnalysis.value.IssueTypeId;
    safetyjob.TopicId = this.editSafetyJobAnalysis.value.TopicId;
    safetyjob.ExpectedBehaviour =
      this.editSafetyJobAnalysis.value.WhatCanHappen;
    safetyjob.Location = this.editSafetyJobAnalysis.value.Location;
    safetyjob.Probability = this.editSafetyJobAnalysis.value.Probability
      ? this.editSafetyJobAnalysis.value.Probability
      : 1;
    safetyjob.Consequence = this.editSafetyJobAnalysis.value.Consequence
      ? this.editSafetyJobAnalysis.value.Consequence
      : 1;
    safetyjob.RiskFigure = this.riskData.Id;
    safetyjob.RelatedIncident = this.editSafetyJobAnalysis.value.WhatCanHappen;
    safetyjob.RiskAnalysisAttachment = [];
    if (this.newDocumentAdded) {
      safetyjob.RiskAnalysisAttachment = this.newDocumentAdded;
    }
    safetyjob.Activity = [];
    safetyjob.Activity = [...this.taskPlanList];
    safetyjob.FeatureId = this.parentId;
    safetyjob.ModuleFeatureId = this.FeatureId;
    safetyjob.AdditionalFeatureId = FeatureKey.SAFETY_JOB_ANALYSIS;
    safetyjob.ObjectName = sessionStorage.getItem('FdvName');
    if (this.safetyJobId > 0) {
      if (this.addTaskPlan.valid) {
        this.showNotification = true;
      } else {
        this.loading = true;
        safetyjob.Status =
          this.editSafetyJobAnalysis.value.Status === 7
            ? 8
            : this.editSafetyJobAnalysis.value.Status;
        safetyjob.ModifiedBy = BaseServices.UserId;
        this.subscriptions.push(
          this.safetyjobservice
            .updateJobAnalysis(safetyjob, this.parentKey)
            .subscribe((result) => {
              if (result) {
                this.loading = false;
                this.toasterComponent.callToast();
                this.deletApprovalNotice();
                if (mode === '') {
                  this.safetyLog();
                  this.goback();
                } else {
                  this.router.navigate([`./../../new`], {
                    relativeTo: this.route,
                  });
                }
              }
            })
        );
      }
    }
  }

  saveAndClear() {
    this.saveChanges();

    this.clear();
  }
  clear() {
    this.taskPlanList = [];
    this.safetyLogData = [];
    this.uploadedFiles = [];
    this.safetyJobId = 0;
    this.documentName = '';
    this.editSafetyJobAnalysis = this.formBuilder.group({
      Status: '',
      TopicId: '',
      IssueTypeId: '',
      Location: '',
      WhatCanHappen: '',
      Probability: 1,
      Consequence: 1,
      checklistId: '',
    });
    this.setRiskFigure(1, 1);

    this.activityId = 0;
    this.addTaskPlan.reset();
    this.createdby = '';
    this.createdon = '';
    this.time = '';
  }

  clearTask() {
    this.taskIndex = null!;
    this.activityId = 0;
    this.addTaskPlan.reset();
    this.documentName = '';
    this.activityDocument = '';
  }
  /**
   * save issue type
   */
  saveIssue() {
    this.loading = true;
    let issuetype = new Issue();
    issuetype.Id = this.issueId ? this.issueId : 0;
    issuetype.Name = this.typeForm.value.Name;
    issuetype.Status = '1';
    issuetype.BusinessId = BaseServices.BusinessId;
    issuetype.ApplicationId = BaseServices.ApplicationId;
    if (issuetype.Id > 0) {
      this.subscriptions.push(
        this.safetyjobservice
          .updateIssue(issuetype)
          .subscribe((topicEdited: any) => {
            this.loading = false;
            if (topicEdited) {
              this.viewIssue();
              this.bindIssueDropdown();
              this.toasterComponent.callToast();
              this.editSafetyJobAnalysis
                .get('IssueTypeId')!
                .patchValue(topicEdited.Id);
              this.addType = false;
              this.issueId = 0;
            } else {
              this.isCaseExists = true;
              setTimeout(() => {
                this.isCaseExists = false;
              }, 3000);
            }
          })
      );
    } else {
      this.subscriptions.push(
        this.safetyjobservice
          .createIssue(issuetype)
          .subscribe((caseType: any) => {
            this.loading = false;
            if (caseType) {
              this.viewIssue();
              this.bindIssueDropdown();
              this.toasterComponent.callToast();
              this.editSafetyJobAnalysis
                .get('IssueTypeId')!
                .patchValue(caseType.Id);
              this.addType = false;
            } else {
              this.isCaseExists = true;
              setTimeout(() => {
                this.isCaseExists = false;
              }, 3000);
            }
          })
      );
    }
  }

  clearIssue() {
    this.selectedType.Name = '';
    this.selectedType.Id = 0;
  }
  /**
   * save uploaded file
   */
  saveUploaded(event: any) {
    if (event) {
      let eventType = event.eventName;
      let keyBindings = {
        Originalname: 'OriginalFileName',
        FileTypeId: 'FileType',
      };
      let deviationDoc = Object.assign({}, event, this.additionalData);
      switch (eventType) {
        case 'DeleteDefaultFile':
          if (event.item.data.id === 0) {
            // remove unsaved attachment
            this.newDocumentAdded = this.newDocumentAdded.filter(
              (x: any) => x.Path !== event.item.data.path
            );
            this.uploadedFiles = this.uploadedFiles.filter(
              (x: any) => x.path !== event.item.data.path
            );
            this.toasterComponent.callToastDlt();
          } else {
            // remove existing attachement
            this.subscriptions.push(
              this.riskanalysisService
                .deleteRiskAttachment(event.item.data.id)
                .subscribe((result: any) => {
                  if (result) {
                    // remove from display list
                    this.uploadedFiles = this.uploadedFiles.filter(
                      (x: any) => x.path !== event.item.data.path
                    );
                    this.toasterComponent.callToastDlt();
                  }
                })
            );
          }
          break;
        default:
          deviationDoc.id = 0;
          deviationDoc.createdBy = BaseServices.UserId;
          deviationDoc.status = 1;
          this.newDocumentAdded.push(
            HelperService.switchKeysCase(deviationDoc, keyBindings)
          );
          break;
      }
    }
  }

  /**
   * get list of issue types
   */
  viewIssue() {
    this.subscriptions.push(
      this.safetyjobservice.getAllIssues().subscribe((issue: any) => {
        this.editTypes = issue;
        if (issue) {
          this.Issue = [];
          this.subscriptions.push(
            this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
              this.Issue = [];
              this.Issue.push({ label: val.SELECT, value: null });
            })
          );
          issue.forEach((element) => {
            this.Issue.push({ label: element.Name, value: element.Id });
          });
        }
      })
    );
  }
  /**
   * edit issue type
   * @param issuetype {{issuetype}}
   */
  editIssue(issuetype: Issue) {
    this.issueId = issuetype.Id;
    let result = {
      Name: issuetype.Name,
    };
    (<FormGroup>this.typeForm).setValue(result, { onlySelf: true });
  }
  /**
   * delete type issue
   * @param selectedType {{any}}
   */
  deleteIssue(selectedType: any) {
    let data = this.sjaList.filter(
      (element: any) => element.TypeofIssueId === selectedType
    );
    if (data.length === 0) {
      this.subscriptions.push(
        this.safetyjobservice
          .checkExistingFunction(selectedType)
          .subscribe((result: any) => {
            if (result) {
              this.isfunctionExists = true;
              this.functionExisting =
                'You cannot delete this Function / Role as it is being used by another Contact';
              setTimeout(() => {
                this.isfunctionExists = false;
              }, 3000);
            } else {
              this.confirmationService.confirm({
                message: this.translate.instant('DELETE_THIS_RECORD'),
                accept: () => {
                  this.subscriptions.push(
                    this.safetyjobservice
                      .deleteIssue(selectedType)
                      .subscribe(() => {
                        this.viewIssue();
                        this.bindIssueDropdown();
                        this.toasterComponent.callToastDlt();
                      })
                  );
                },
              });
            }
          })
      );
    } else {
      alert('You cannot delete this Type of Issue as it is being used');
    }
  }
  /**
   * save topic
   */
  saveTopic() {
    this.loading = true;
    let topic = new Topic();
    topic.Id = this.topicId ? this.topicId : 0;
    topic.Name = this.topicForm.value.Name;
    topic.Status = '1';
    topic.BusinessId = BaseServices.BusinessId;
    topic.ApplicationId = BaseServices.BusinessId;
    if (topic.Id > 0) {
      this.subscriptions.push(
        this.safetyjobservice
          .updateTopic(topic)
          .subscribe((topicEdited: any) => {
            this.loading = false;
            if (topicEdited) {
              this.viewTopic();
              this.toasterComponent.callToast();
              this.editSafetyJobAnalysis
                .get('TopicId')!
                .patchValue(topicEdited.Id);
              this.addTopic = false;
              this.topicId = 0;
            } else {
              this.isTopicExists = true;
              setTimeout(() => {
                this.isTopicExists = false;
              }, 3000);
            }
          })
      );
    } else {
      this.subscriptions.push(
        this.safetyjobservice.createTopic(topic).subscribe((result: any) => {
          this.loading = false;
          if (result) {
            this.isTopicExists = true;
            this.viewTopic();
            this.toasterComponent.callToast();
            this.editSafetyJobAnalysis.get('TopicId')!.patchValue(result.Id);
            this.addTopic = false;
          } else {
            this.isTopicExists = true;
            setTimeout(() => {
              this.isTopicExists = false;
            }, 3000);
          }
        })
      );
    }
    this.clearTopic();
  }

  clearTopic() {
    this.selectedTopic.Id = 0;
    this.selectedTopic.Name = '';
  }
  /**
   * get topic list
   */
  viewTopic() {
    this.subscriptions.push(
      this.safetyjobservice
        .getTopicList(BaseServices.BusinessId)
        .subscribe((topic: any) => {
          this.editTopics = topic;
          if (topic) {
            this.Topic = [];
            this.subscriptions.push(
              this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
                this.Topic.push({ label: val.SELECT, value: null });
              })
            );
            topic.forEach((element) => {
              this.Topic.push({
                label: element.Name,
                value: element.Id,
              });
            });
          }
        })
    );
  }
  /**
   * edit topic
   * @param topic {{any}}
   */
  editTopic(topic: Topic) {
    this.topicId = topic.Id;
    let result = {
      Name: topic.Name,
    };
    (<FormGroup>this.topicForm).setValue(result, { onlySelf: true });
  }
  /**
   * delete topic
   * @param selectedTopic {{any}}
   */
  deleteTopic(selectedTopic: any) {
    let data = this.sjaList.filter(
      (element: any) => element.TopicId === selectedTopic
    );

    if (data && data.length === 0) {
      this.subscriptions.push(
        this.safetyjobservice
          .checkforTopicexistingFunction(selectedTopic)
          .subscribe((result: any) => {
            if (result) {
              this.istopicfunctionExists = true;
              this.topicFunctionExisting =
                'You cannot delete this Function / Role as it is being used by another Contact';
              setTimeout(() => {
                this.istopicfunctionExists = false;
              }, 3000);
            } else {
              this.confirmationService.confirm({
                message: this.translate.instant('DELETE_THIS_RECORD'),
                accept: () => {
                  this.subscriptions.push(
                    this.safetyjobservice
                      .deleteTopic(selectedTopic)
                      .subscribe((result) => {
                        this.viewTopic();
                        this.toasterComponent.callToastDlt();
                      })
                  );
                },
              });
            }
          })
      );
    } else {
      alert('You cannot delete this Topic as it is being used');
    }
  }

  saveTaskDetails() {
    let task: any = new TaskPlanList();
    let executionUsers = this.Execution;
    let followupUsers = this.Followup;
    let followUpUser = this.addTaskPlan.value.FollowupUserId
      ? followupUsers.filter(
          (x: any) => x.value === this.addTaskPlan.value.FollowupUserId
        )
      : null;

    let executionUser = this.addTaskPlan.value.ExecutionUserId
      ? executionUsers.filter(
          (x: any) => x.value === this.addTaskPlan.value.ExecutionUserId
        )
      : null;

    let executionList: any = [];
    let count = 0;
    let followupList: any = [];
    let executionNameList = '';
    let followupNameList = '';
    task.ExecutionUsersList = [];

    this.addTaskPlan.value.ExecutionUser.forEach((element: any) => {
      executionList.push({ Id: element });
      let checkname = executionUsers.filter((x: any) => x.value === element);
      let name;
      if (checkname.length > 0) {
        name = checkname[0].label;
      }
      executionNameList += count === 0 ? name : ',' + name;
      count++;
    });
    count = 0;
    this.addTaskPlan.value.FollowupUser.forEach((element: any) => {
      followupList.push({ Id: element });
      let checkname = followupUsers.filter((x: any) => x.value === element);
      let name;
      if (checkname.length > 0) {
        name = checkname[0].label;
      }
      followupNameList += count === 0 ? name : ',' + name;
      count++;
    });

    if (
      (this.taskIndex &&
        this.taskIndex !== null &&
        this.taskPlanList &&
        this.taskPlanList.length > 0 &&
        this.taskPlanList[this.taskIndex].Id > 0) ||
      (this.taskIndex > -1 && this.taskIndex !== null)
    ) {
      this.taskPlanList[this.taskIndex].ExecutionUsersList = executionList;
      this.taskPlanList[this.taskIndex].FollowupUsersList = followupList;
      this.taskPlanList[this.taskIndex].Description =
        this.addTaskPlan.value.Description;

      let executionProperty = new Execution();
      executionProperty.Id = this.addTaskPlan.value.ExecutionUserId;
      this.taskPlanList[this.taskIndex].ExecutionUsers = executionList; // [executionProperty];
      let followupProperty = new Execution();
      followupProperty.Id = this.addTaskPlan.value.FollowupUserId;
      this.taskPlanList[this.taskIndex].FollowupUsers = followupList;

      this.taskPlanList[this.taskIndex].FollowupPerson = followupNameList; // followUpUser ? followUpUser[0].label : '';
      this.taskPlanList[this.taskIndex].ExecutionUserName = executionNameList; // executionUser ? executionUser[0].label : '';
      this.taskPlanList[this.taskIndex].Title = this.addTaskPlan.value.Title;
      this.taskPlanList[this.taskIndex].FollowupUserId = followUpUser
        ? followUpUser[0].value
        : '';
      this.taskPlanList[this.taskIndex].ExecutionUserId = executionUser
        ? executionUser[0].value
        : '';
      this.taskPlanList[this.taskIndex].ModifiedBy = BaseServices.UserId;
      this.taskPlanList[this.taskIndex].Status = '7';
      this.isTaskAdded = true;
      if (this.activityDocument) {
        this.taskPlanList[this.taskIndex].ActivityAttachmentDto = [] = [];
        this.taskPlanList[this.taskIndex].ActivityAttachmentDto.push(
          HelperService.switchKeysCase(this.activityDocument, null, 'U')
        );
      } else {
        this.taskPlanList[this.taskIndex].ActivityAttachmentDto = [];
      }
      // schedule data
      this.taskPlanList[this.taskIndex].Deadline = this.addTaskPlan.value
        .EndDate
        ? HelperService.formatDateFilter(
            this.addTaskPlan.value.EndDate.formatted
          )
        : null;
      this.taskPlanList[this.taskIndex].NotifyBeforeDeadLine =
        this.addTaskPlan.value.NotifyBeforeDeadLine;
      this.taskPlanList[this.taskIndex].DocumentType = this.activityDocument
        ? this.activityDocument.Path
        : '';
      this.taskPlanList[this.taskIndex].ActivityScheduleDto.EndDate = this
        .addTaskPlan.value.EndDate
        ? HelperService.formatDateFilter(
            this.addTaskPlan.value.EndDate.formatted
          )
        : null;
      this.taskPlanList[
        this.taskIndex
      ].ActivityScheduleDto.NotifyBeforeDeadLine =
        this.addTaskPlan.value.NotifyBeforeDeadLine;
    } else {
      task.Id = this.activityId ? this.activityId : 0;
      task.Title = this.addTaskPlan.value.Title;
      task.Deadline = this.addTaskPlan.value
        ? HelperService.formatDateFilter(
            this.addTaskPlan.value.EndDate.formatted
          )
        : null;
      task.Description = this.addTaskPlan.value.Description;
      task.Status = '7';
      task.CreatedBy = BaseServices.UserId;
      task.ExecutionUserName = executionNameList; // executionUser ? executionUser[0].label : '';
      task.ExecutionUsers = executionList;
      task.FollowupUsers = followupList;
      task.FollowupUserId = followUpUser ? followUpUser[0].value : '';
      task.ExecutionUserId = executionUser ? executionUser[0].value : '';
      task.FollowupPerson = followupNameList; // followUpUser ? followUpUser[0].label : '';
      let executionProperty = new Execution();
      executionProperty.Id = this.addTaskPlan.value.ExecutionUserId;
      let followupProperty = new Execution();
      followupProperty.Id = this.addTaskPlan.value.FollowupUserId;
      if (this.activityDocument) {
        task.ActivityAttachmentDto = [] = [];
        task.ActivityAttachmentDto.push(
          HelperService.switchKeysCase(this.activityDocument, null, 'U')
        );
        task.DocumentType = this.activityDocument.Path;
      } else {
        task.ActivityAttachmentDto = [];
      }
      let activitySchedule = new ActivitySchedule();
      activitySchedule.EndDate = HelperService.formatDateFilter(
        this.addTaskPlan.value.EndDate.formatted
      );
      activitySchedule.NotifyBeforeDeadLine =
        this.addTaskPlan.value.NotifyBeforeDeadLine;
      task.ActivityScheduleDto = activitySchedule;
      this.taskPlanList = [...this.taskPlanList, task];
      this.isTaskAdded = true;
    }
    this.clearTask();
  }
  /**
   * save and update task
   */
  saveAddTasks() {
    let taskplan = new SafetyJobListedit();
    if (this.addTaskPlan.valid) {
      this.showNotification = true;
    } else {
      this.loading = true;
      if (this.jobId > 0) {
        taskplan.Id = this.safetyJobId;
        taskplan.BusinessId = BaseServices.BusinessId;
        taskplan.FeatureKey = this.parentId;
        taskplan.FeatureId = BaseServices.getFeatureId(this.parentKey);
        taskplan.CreatedOn = new Date();
        taskplan.ModifiedBy = BaseServices.UserId;
        taskplan.Status = this.editSafetyJobAnalysis.value.Status;
        taskplan.IssueTypeId = this.editSafetyJobAnalysis.value.IssueTypeId;
        taskplan.TopicId = this.editSafetyJobAnalysis.value.TopicId;
        taskplan.ExpectedBehaviour =
          this.editSafetyJobAnalysis.value.WhatCanHappen;
        taskplan.Location = this.editSafetyJobAnalysis.value.Location;
        taskplan.Probability = this.editSafetyJobAnalysis.value.Probability;
        taskplan.Consequence = this.editSafetyJobAnalysis.value.Consequence;
        taskplan.RiskFigure = this.riskData.Id;
        taskplan.RiskAnalysisAttachment = [];
        if (this.newDocumentAdded) {
          taskplan.RiskAnalysisAttachment = this.newDocumentAdded;
        }
        taskplan.Activity = [];
        taskplan.SaveType = 'ADDTASK';
        taskplan.Activity = [...this.taskPlanList];
        this.subscriptions.push(
          this.safetyjobservice
            .updateJobAnalysis(taskplan, this.parentKey)
            .subscribe((result) => {
              if (result) {
                this.fetchActivities();
                this.toasterComponent.callToast();
                this.deletApprovalNotice();
                this.isTaskAdded = false;
                this.loading = false;
              }
            })
        );
      }
    }
  }
  /**
   * get activity list
   */
  fetchActivities() {
    this.subscriptions.push(
      this.safetyjobservice
        .getByAnalysis(this.safetyJobId)
        .subscribe((result: any) => {
          this.taskPlanList = [];
          let list = result;
          if (list) {
            list.forEach((element: any) => {
              let task = new TaskPlanList();
              task.Id = element.Id;
              task.Title = element.Title;
              task.Status = element.StatusId;
              task.Deadline = element.Deadline;
              task.Description = element.Description;
              task.ExecutionUserId = element.ExecutionUserId;
              task.FollowupUserId = element.FollowupUserId;
              task.FollowupPerson = element.FollowupPerson;
              task.ExecutionUsers = element.ExecutionUsers;
              task.FollowupUsers = element.FollowupUsers;
              task.ExecutionUserName = element.ExecutionUserName;
              task.FollowupUsersList = element.FollowupUsers;
              task.ExecutionUsersList = element.ExecutionUsers;
              task.ActivityNumber = element.ActivityNumber;
              task.Activity_Number_Prefix = element.Activity_Number_Prefix;
              task.ActivityAttachmentDto = [] = [];
              if (element.ActivityAttachmentDto) {
                task.ActivityAttachmentDto.push(element.ActivityAttachmentDto);
                this.editActivityDocument = element.ActivityAttachmentDto;
              }
              task.DocumentType = element.DocumentType;
              let activitySchedule = new ActivitySchedule();
              activitySchedule.EndDate = element.ActivityScheduleDto.EndDate;
              activitySchedule.NotifyBeforeDeadLine =
                element.NotifyBeforeDeadLine;
              task.ActivityScheduleDto = activitySchedule;
              task.ModuleFeatureId = this.FeatureId;
              task.AdditionalFeautreId = this.sjaFeatureId;
              task.CultureInfo = BaseServices.userCultureInfo();
              this.taskPlanList.push(task);
            });
          } else {
            list.forEach((element: any) => {
              if (element.ActivityAttachmentDto !== null) {
                let ActivityAttachmentDto = element.ActivityAttachmentDto.Path;
                return (element.ActivityAttachmentDto = ActivityAttachmentDto);
              }
            });
            this.taskPlanList.push(...list);
          }
        })
    );
  }
  /**
   * edit task
   * @param activityId {{number}}
   */
  editTask(activity: any, index: any) {
    this.taskIndex = index;
    this.safetyData = activity;
    let taskplan;
    if (activity) {
      let activityId = activity.Id;
      let followupList: any = [];
      let executionList: any = [];
      let checkExecutionList = [];
      let checkFollowupList = [];

      activity.ExecutionUsers.forEach((element: any) => {
        executionList.push(element.Id);
      });
      activity.FollowupUsers.forEach((element: any) => {
        followupList.push(element.Id);
      });

      executionList.forEach((element: any) => {
        let checkValueExec = this.Execution.filter(
          (x: any) => x.value == element
        );
        if (checkValueExec.length > 0) {
          checkExecutionList.push(checkValueExec);
        }
      });
      followupList.forEach((element: any) => {
        let checkValFollow = this.Execution.filter(
          (x: any) => x.value == element
        );
        if (checkValFollow.length > 0) {
          checkFollowupList.push(checkValFollow);
        }
      });
      if (checkExecutionList.length == 0) {
        executionList = [];
      }
      if (checkFollowupList.length == 0) {
        followupList = [];
      }

      taskplan = {
        // activity data
        Id: this.activityId ? this.activityId : 0,
        Title: activity.Title ? activity.Title : '',
        Description: activity.Description ? activity.Description : '',
        NotifyBeforeDeadLine: activity.ActivityScheduleDto
          ? activity.ActivityScheduleDto.NotifyBeforeDeadLine
          : 0,
        EndDate: HelperService.formatInputDate(
          activity.Deadline ? activity.Deadline : null
        ),
        FollowupUser: followupList,
        ExecutionUser: executionList,
      };
      (<FormGroup>this.addTaskPlan).setValue(taskplan, {
        onlySelf: true,
      });
      if (activity.ActivityAttachmentDto.length > 0) {
        activity.ActivityAttachmentDto.forEach((x: any) => {
          if (x) {
            this.activityDocument = x;
            this.documentName = x.Filename;
          }
        });
      } else {
        this.documentName = '';
      }
    }
  }
  /**
   * delete task
   * @param control {{any}}
   */
  confirm(activity: any, index: any) {
    let data = new FeatureMapping();
    data.RiskAnalysisId = this.jobId;
    data.ActivityId = activity.Id;
    data.IsMappedActivity = true;
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        // const data = activity;
        if (data) {
          this.subscriptions.push(
            this.safetyjobservice
              .deleteTaskPlanInRiskActvity(data)
              .subscribe((result) => {
                if (result) {
                  this.toasterComponent.callToastDlt();
                  this.fetchActivities();
                }
              })
          );
        } else {
          this.taskPlanList = this.taskPlanList.filter(
            (val: any, i: any) => i !== index
          );
          this.toasterComponent.callToastDlt();
        }
      },
    });
  }
  /**
   * upload event
   */
  startUpload(): void {
    let businessId = BaseServices.BusinessId;
    const event: UploadInput = {
      type: 'uploadAll',
      url: environment.BASE_URL + '/file/upload/business/project/activity-docs',
      method: 'POST',
      data: {
        ApplicationId: `${BaseServices.ApplicationId}`,
        BusinessId: `${BaseServices.BusinessId}`,
        ParentId: `${this.safetyJobId}`,
        Status: '1',
        CreatedOn: `${new Date()}`,
        ModifiedBy: `${BaseServices.UserId}`,
        ModifiedOn: `${new Date()}`,
      },
    };
    this.uploadInput.emit(event);
  }

  /**
   * got to list
   */
  goback() {
    this._location.back();
  }
  // private funs
  getFileExtension(filename: any) {
    let fileExtension = null;
    if (filename != null) {
      fileExtension = filename.substr(filename.lastIndexOf('.') + 1);
    }
    return fileExtension;
  }
  deletApprovalNotice() {
    if (this.safetyJobId) {
      this.subscriptions.push(
        this.riskanalysisService
          .getApprovalNoticeByRisk(this.safetyJobId)
          .subscribe((result: any) => {
            if (result) {
              this.subscriptions.push(
                this.riskanalysisService
                  .deleteApprovalNotice(result[0].Id)
                  .subscribe((result) => {})
              );
            }
          })
      );
    }
  }
  //#endregion

  //#region cotrol-events
  onTopicFormHide() {
    this.topicForm.reset();
  }
  onTypeFormHide() {
    this.typeForm.reset();
    this.issueId = 0;
  }
  // TODO: to be implemented
  onRowSelect(ev: any) {}
  /**
   * on upload event
   * @param output
   */
  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') {
      // uncomment this if you want to auto upload files when added
      const event: UploadInput = {
        type: 'uploadAll',
        url: `${environment.BASE_URL}/${BaseServices.apiRoute(
          this.parentKey
        )}/file-upload`,
        method: 'POST',
        data: {
          ApplicationId: `${BaseServices.ApplicationId}`,
          BusinessId: `${BaseServices.BusinessId}`,
          ParentId: `${this.parentId ? this.parentId : 0}`,
          Status: '1',
          CreatedOn: `${new Date()}`,
          ModifiedBy: `${BaseServices.UserId}`,
          ModifiedOn: `${new Date()}`,
        },
      };
      this.uploadInput.emit(event);
    } else if (
      output.type === 'addedToQueue' &&
      typeof output.file !== 'undefined'
    ) {
      this.isFileChoosed = true;
      this.files.push(output.file);
    } else if (
      output.type === 'uploading' &&
      typeof output.file !== 'undefined'
    ) {
      const index = this.files.findIndex(
        (file) =>
          typeof output.file !== 'undefined' && file.id === output.file.id
      );
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      this.files = this.files.filter(
        (file: UploadFile) => file !== output.file
      );
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'done') {
      this.activityDocument = output.file!.response;
      this.documentName = output.file!.response.filename;
    } else if (
      output.type === 'rejected' &&
      typeof output.file !== 'undefined'
    ) {
    }

    this.files = this.files.filter(
      (file) => file.progress.status !== UploadStatus.Done
    );
  }
  bindSJADetails() {
    this.subscriptions.push(
      this.safetyjobservice
        .getAllByEach(this.parentId, this.parentKey)
        .subscribe((result) => {
          if (result) {
            this.sjaList = result;
          }
        })
    );
  }

  copyDocLink(node: any) {
    this.copyDocForm = this._fb.group({
      filePath: node.Path,
    });
    this.showCopyLinkDailog = true;
  }

  copyDocumnetUrl() {
    this.showCopyLinkDailog = false;
  }

  onDownloadSJApdf(url: any) {
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(url);
    link.download = 'test.pdf';
    link.click();
  }
  /**
   *
   * @param featureName
   */
  hideTypeOfcase(featureName: string): boolean {
    if (featureName === 'PROJECT') {
      return this.isUserRights(Rights.ADMINISTRATE_PROJECTS);
    } else if (featureName === 'FDV') {
      return this.isUserRights(Rights.ADMINISTRATE_FDV);
    } else if (featureName === 'IC') {
      return this.isUserRights(Rights.ADMINISTRATE_INTERNAL_CONTROL);
    } else if (featureName === 'OS') {
      return this.isUserRights(Rights.ADMINISTRATE_OTHER_SYSTEMS);
    } else if (featureName === 'QS') {
      return this.isUserRights(Rights.ADMINISTRATE_QUALITY_SYSTEM);
    } else if (featureName === 'VEHICLE') {
      return false;
    } else if (featureName === 'FS') {
      return false;
    } else if (featureName === 'KUNDE_EL') {
      return this.isUserRights(Rights.IK_KUNDE_EL_RIGHTS);
    } else if (featureName === 'FRAMEWORK') {
      return this.isUserRights(Rights.FRAMEWORK_AGREEMENT_RIGHTS);
    } else if (featureName === 'SA') {
      return this.isUserRights(Rights.SERVICE_AGREEMENT_RIGHTS);
    }
    return true;
  }

  isUserRights(rightsId: number): boolean {
    return BaseServices.checkUserRights(+rightsId, BaseServices.UserRole);
  }

  //#endregion

  /**
   * recursive search in a nested array
   * @param data {array}
   * @param featureId {number}
   */
  flattenFind(data: any, key: any) {
    let checkFeature = <Feature>_(data)
      .thru((parent) => {
        return _.union(parent, _.map(parent, 'children'));
      })
      .flatten()
      .find({ id: +key });
    if (checkFeature !== undefined) {
      return checkFeature.checked;
    } else {
      return false;
    }
  }

  private handleStatusOptions(result: any): void {
    if (result.Status === 8) {
      this.options = [
        new Options(8, 'OPEN'),
        new Options(9, 'DONE'),
        new Options(10, 'REJECTED'),
      ];
    }
  }
  
  private populateSafetyJob(result: any): void {
    if (result?.RiskAnalysisAttachment?.length) {
      this.uploadedFiles = result.RiskAnalysisAttachment.map((element: any) =>
        HelperService.switchKeysCase(element, null, 'L')
      );
    }
  
    this.safetyjob = {
      Status: result.Status === 7 ? 8 : result.Status,
      TopicId: result.TopicId,
      IssueTypeId: result.IssueTypeId,
      Location: result.Location,
      Probability: result.Probability?.toString() || '1',
      Consequence: result.Consequence?.toString() || '1',
      RiskFigure: result.RiskFigure,
      checklistId: null,
      WhatCanHappen: result.ExpectedBehaviour || result.RelatedIncident,
    };
  
    this.editSafetyJobAnalysis.setValue(this.safetyjob, { onlySelf: true });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}

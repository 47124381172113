import { environment } from 'src/environments/environment';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { FollowUpMessageService } from './../services/message.services';
import { ToUserData, FollowUpMessage } from './../models/message';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseServices } from './../../kuba.services';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';

import { Location } from '@angular/common';

import { User } from './../../client/models/client';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
@Component({
    selector: 'message-new',
    templateUrl: 'message-new.component.html'
})
export class MessagenewComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    selectedUser: any[];
    toList: User[];
    roleId: string;
    selectedDate: Date;
    messageForm: FormGroup;
    invalidDates: Array<Date>;
    mailData = new FollowUpMessage();
    uploadStart: boolean;
    attachement: string;
    attachmentName: string;
    showNotification = false;
    minDate: Date;
    yearRange: string;
    userName: string;
    private subscriptions: Subscription[] = [];
    public selectedFiles;
    constructor(
        private route: ActivatedRoute,
        public router: Router,
        private _location: Location,
        private formBuilder: FormBuilder,
        private followUpMessageService: FollowUpMessageService,
        private http: HttpClient
    ) {
        let today = new Date();
        let invalidDate = new Date();
        let month = today.getMonth();
        let year = today.getFullYear();
        this.minDate = new Date();
        let prevMonth = month === 0 ? 11 : month;
        let prevYear = prevMonth === 11 ? year : year;
        this.minDate.setMonth(prevMonth);
        this.minDate.setFullYear(prevYear);
        invalidDate.setDate(today.getDate() - 1);
        this.invalidDates = [invalidDate];
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.yearRange = (new Date().getFullYear()) + ':' + (new Date().getFullYear());
        this.messageForm = this.formBuilder.group({
            Subject: ['', Validators.required],
            Message: ['', Validators.required],
            selectedDate: ['', Validators.required]
        });
        this.userName = BaseServices.userName;
        this.roleId = BaseServices.roleId;
        let list = <User[]>this.route.snapshot.data['list'];
        if (list) {
            this.toList = list;
        }
    }

    sendMail() {
        this.mailData.ApplicationId = BaseServices.ApplicationId;
        this.mailData.BusinessId = BaseServices.BusinessId;
        this.mailData.CreatedBy = BaseServices.UserId;
        this.mailData.CreatedOn = new Date();
        this.mailData.FromUser = BaseServices.UserId;
        this.mailData.Message = this.messageForm.value.Message;
        this.mailData.Subject = this.messageForm.value.Subject;
        this.mailData.SendDate = this.messageForm.value.selectedDate;
        this.mailData.AttachmentUrl = this.attachement;
        this.mailData.AdminDelete = false;
        this.mailData.IsDeleted = false;
        this.mailData.IsSent = false;
        this.mailData.OutboxStatus = false;
        this.mailData.Status = true;
        this.mailData.SenderList = [];
        if (this.selectedUser) {
            this.selectedUser.forEach(element => {
                let user = new ToUserData();
                user.mail = element.Email ? element.Email : '';
                user.Name = element.Name ? element.Name : '';
                user.UserId = element.Id;
                this.mailData.SenderList!.push(user);
            });
            this.subscriptions.push(this.followUpMessageService
                .sendMail(this.mailData)
                .subscribe(result => {
                    if (result) {
                        this.toasterComponent.callToast();
                        this.clear();
                    }
                }));
        } else {
            this.showNotification = true;
        }
    }

    /**
     * get back location
     */
    gotoBack() {
        this._location.back();
    }
    /**
     * attachement upload
     * @param selectedFiles
     */
    filesSelectForLogo(selectedFiles: any) {
        this.uploadStart = true;
        this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(file => file);
        let formData = new FormData();
        formData.append('file', this.selectedFiles[0]);
        let session = JSON.parse(sessionStorage.getItem('session'));
        let token = session.AccessToken;
        var headers = {
            'Authorization': 'Bearer ' + token, // Add JWT token to headers
            // Add any other headers if needed
        };

        this.subscriptions.push(this.http
            .post(
            environment.BASE_URL +
            '/followupmessage/file/upload/followup/message/' +
            BaseServices.ApplicationId,
            formData,{headers:headers}
            )
            .map((response: any) => {
                this.uploadStart = false;
                let res = response;
                this.attachement = res.path;

                this.attachmentName = this.attachement.replace(/^.*[\\\/]/, '');
            })
            .subscribe(
            result => { },
            error => console.log('Upload article Sign Error : ', error),
            () => console.log('Upload article  Sign Complete')
            ));
    }

    clear() {
        this.selectedUser = [];
        this.attachmentName = '';
        this.messageForm.reset();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}

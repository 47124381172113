import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'followups-activities',
    templateUrl: 'activities.component.html',
})
export class FollowUpActivitiesComponent implements OnInit {

    constructor( ) {}
  
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
    }
}
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { ContactEdit } from './../models/contactEdit';
import { ContactFunctionalArea } from './../models/contactFunctionalArea';
import { ContactView } from './../models/contactView';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class ContactServices {
    item: Object;
    constructor(private http: HttpClient) { }

    // List all contacts
    getAll() {
        return this.http
            .get<any>(environment.BASE_URL + '/contacts', )
            .map(result => result);
    }
    getContactById(id: number) {
        return this.http
            .get(
                environment.BASE_URL + '/contact/' + id,

            )
            .map((result: any) => result);
    }

    add(data:any) {
        let body = JSON.stringify(data);
        return this.http
            .post(
                environment.BASE_URL + '/contact',
                body,

            )
            .map(result => result);
    }

    update(ExistingEmployee: ContactEdit) {
        return this.http
            .put(
                environment.BASE_URL + '/contactUpdate',
                ExistingEmployee,

            )
            .map(result => result);
    }

    // delete contact from list
    deleteContact(Id:any) {
        return this.http
            .delete(environment.BASE_URL + '/contact/' + Id)
            .toPromise()
            .then((response: any) => {
                return response;
            });
    }

    // delete selected contacts
    deleteAll(Ids:any) {
        return this.http
            .put(
                environment.BASE_URL + '/contacts',
                Ids,

            )
            .map(result => result);
    }

    // // get all contact type
    getAllContactType() {
        return this.http
            .get<any>(environment.BASE_URL + '/contacttypes/contactTypes', )
            .map(result => result);
    }

    // bind contact function
    getAllByFunction() {
        return this.http
            .get<any>(environment.BASE_URL + '/contactfunctions/contactFunctions',
                )
            .map(result => result);
    }

    // update contact function
    updateFunction(existingCategory: ContactFunctionalArea) {
        return this.http
            .put(
                environment.BASE_URL +
                '/contactfunctions/contactfunction/' +
                existingCategory.Id,
                existingCategory,
            )
            .map(result => result);
    }

    // adding contact function
    createFunction(newCategory: ContactFunctionalArea) {
        return this.http
            .post(
                environment.BASE_URL + '/contactfunctions/contacFunction',
                newCategory,

            )
            .map(result => result);
    }

    // delete popup values
    deleteType(Id: number) {
        return this.http
            .delete(
                environment.BASE_URL +
                '/contactfunctions/contactfunctions/' +
                Id,
            )
            .map(result => result);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
    getAllCustContacts() {
        return this.http
            .get<any>(
                environment.BASE_URL + '/cutomerContacts',

            )
            .map(result => result);
    }
    getAllCustContactsWithLogin() {
        return this.http
            .get<any>(
                environment.BASE_URL + '/cutomerContactsLogin',

            )
            .map(result => result);
    }
    deleteCustConatcts(ids:any) {
        return this.http
            .put(
                environment.BASE_URL + '/cutomerContacts',
                ids,

            )
            .map(result => result);
    }
    checkExistingFunction(functionId:any) {
        return this.http
            .get(
                environment.BASE_URL +
                '/contactfunctions/checkfunction/' +
                functionId,

            )
            .map(result => result);
    }

    getAdminContacts(createdBy: number) {
        return this.http
            .get<any>(environment.BASE_URL + '/adminorportalcontacts/' + createdBy, )
            .map(result => result);
    }

    getContactUser(featureKey: number) {
        return this.http
            .get(
            environment.BASE_URL + '/contactUser/' + featureKey,

            )
            .map(result => result);
    }

}


import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { environment } from 'src/environments/environment';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { ConfirmationService, SelectItem } from 'primeng/api';
import 'rxjs/Rx';

import { ToasterComponent } from './../../../../_directives/toaster.component';
import { Rights } from './../../../../_models/feature';
import { HelperService, PdfSetting } from './../../../../_services/helper.service';
import { EmployeeCourse } from './../../../employees/models/employeeCourse';
import { EmployeeCourseServices } from './../../../employees/services/employeecourse.service';
import { BaseServices } from './../../../kuba.services';
import { UserService } from './../../../users/services/user.service';
import { DateSetting } from './../../../../_helpers/date-setting';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    moduleId: module.id,
    selector: 'course-detail',
    templateUrl: 'mypage-coursedetails.component.html'
})
export class MyPageCourseDetailComponent extends DateSetting implements OnInit {
    @ViewChild(ToasterComponent,{static:false}) toasterComponent: ToasterComponent;
    public courseobject: EmployeeCourse[];
    employeeId: number;
    courseDetailForm: FormGroup;
    display = false;
    upConfig: UploaderConfig;
    additionalData: any;
    newDocumentAdded: any = [];
    uploadedFiles = [];
    course: any;
    courseId: any;
    isEditMyPage: boolean;
    ResponsibleId: string;
    rexcecution: SelectItem[];
    coursenames: SelectItem[];
    ctype: any = [];
    coursetype: any = [];
    ExecutionResponsible: string;
    EmployeeCourseData:any = new EmployeeCourse();
    BusinessCourseId: number;
    Name: any;
    StartDateFormat: any;

    description: any;
    hasChildren = false;
    locale = 'en';
    selDate: string;
    renewalOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '26px',
        selectionTxtFontSize: '14px'
    };

    /**
     * Date picker configuration option
     */
    public startDateOptions: IMyDpOptions = this.defaultDateOptions;
    public endDateOptions: IMyDpOptions = this.startDateOptions;
    public renewalDateOptions: IMyDpOptions = this.renewalOptions;

    hrefcourse :any;
    hrefcontract :any;
    isEmployeecourses: Boolean=true;
    private subscriptions: Subscription[] = [];

    constructor(
        private employeeCourseServices: EmployeeCourseServices,
        private router: Router,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private userService: UserService,
        private fb: FormBuilder,
        protected location: Location,
        private translate: TranslateService
    ) {

        super();
    }

    /**
     * getting employee course data by emp id,setting form value,bindind dropdown
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.employeeId = this.route.snapshot.queryParams['emp'];
        if (this.employeeId > 0) {
            this.additionalData = {
                'ApplicationId': BaseServices.ApplicationId,
                'BusinessId': BaseServices.BusinessId,
                'EmployeeId': this.employeeId,
                'IsPrivateDocument': false,
                'Status': '1',
                'CreatedBy': BaseServices.UserId,
                'CreatedOn': '',
                'ModifiedBy': BaseServices.UserId,
                'ModifiedOn': '',
            };
        }
        this.upConfig = {
            title: 'Upload Document',
            titleAsLabel: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showSearchFilter: false,
            showUploadButton: true,
            uploaderUri: environment.BASE_URL + '/file/upload/business/employee/private-docs',
            addtionalData: this.additionalData
        };
        this.subscriptions.push(this.router.routerState.root.queryParams.subscribe(params => {
            this.employeeId = +params['emp'];
        }));

        this.courseDetailForm = this.fb.group({
            BusinessCourseId: ['', Validators.required],
            Institute: [''],
            StartDate: new FormControl('', Validators.required),
            EndDate: new FormControl('', Validators.required),
            Renewaldate: [''],
            ResponsibleId: [''],
            Description: [''],
            DeadlineNotification: ['']
        })
        
        this.courseobject = [];   
        
        if(this.router.url.substring(35,39) == "list"){
            this.isEmployeecourses = true;
        }
        else if(this.router.url.substring(35,47) == "contractlist"){
            this.isEmployeecourses = false;
        }
        this.subscriptions.push(this.employeeCourseServices.getEmployeeCourseByEmployeeid(this.employeeId).subscribe((result: any) => {
            if (result) {
                this.courseobject = result;
            }

        }));

        this.bindDetails();
        this.OnShowSaveButton();
    }
    /**
         * file upload events
         * @param event {any}
         */
    saveUploaded(event: any) {
        if (event) {
            let eventType = event.type;
            switch (eventType) {
                case 'remove':
                    this.uploadedFiles = this.uploadedFiles.filter((x:any) => x.id !== +event.id);
                    break;
                default:
                    let obj = Object.assign({}, event, this.additionalData);
                    this.newDocumentAdded.push(event);
                    break;
            }
        }
    }
    initForm(data?: any) {
        let BusinessCourseId = '';
        let Institute = '';
        let StartDate = null;
        let EndDate: any;
        let Renewaldate: any;
        let ResponsibleId = '';
        let Description = '';
        let DeadlineNotification = '';
        if (data) {
            this.uploadedFiles = [];
            this.uploadedFiles = data.EmployeeDocument;
            BusinessCourseId = data.BusinessCourseId ? data.BusinessCourseId : '';
            Institute = data.Institute ? data.Institute : '';
            // Start Date Input and Date Range set
            StartDate = HelperService.formatInputDate(data.StartDate ? data.StartDate : null);
            let startdate: Date = HelperService.formatDate(data.StartDate)
                ? new Date(HelperService.formatDate(data.StartDate))
                : null!;
            this.endDateOptions = HelperService.setDateRange(this.endDateOptions, startdate, 'startDate');
            // End Date Input and Date Range set
            EndDate = HelperService.formatInputDate(data.EndDate ? data.EndDate : null);
            let enddate: Date = HelperService.formatDate(data.EndDate)
                ? new Date(HelperService.formatDate(data.EndDate))
                : null!;
            this.startDateOptions = HelperService.setDateRange(this.startDateOptions, enddate, 'endDate');
            Renewaldate = HelperService.formatInputDate(data.Renewaldate)
                ? HelperService.formatInputDate(data.Renewaldate)
                : '';
            ResponsibleId = data.ResponsibleId ? data.ResponsibleId : '';
            (Description = data.Description ? data.Description : ''),
                (DeadlineNotification = data.DeadlineNotification ? data.DeadlineNotification : '');
        } else {
            this.startDateOptions = this.defaultDateOptions;
            this.endDateOptions = this.defaultDateOptions;
        }
        this.courseDetailForm = this.fb.group({
            BusinessCourseId: new FormControl(BusinessCourseId),
            Institute: new FormControl(Institute),
            StartDate: new FormControl(StartDate),
            EndDate: new FormControl(EndDate),
            Renewaldate: new FormControl(Renewaldate),
            ResponsibleId: new FormControl(ResponsibleId),
            Description: new FormControl(Description),
            DeadlineNotification: new FormControl(DeadlineNotification)
        });
    }

    /**
     * get current date
     */
    getCurrentDate() {
        let dateFormat = new Date();
        return {
            year: dateFormat.getFullYear(),
            month: dateFormat.getMonth() + 1,
            day: dateFormat.getDate()
        };
    }

    /**
     * binding dropdown
     */
    bindDetails() {
        this.rexcecution = [];
        this.coursenames = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.coursenames = [];
            this.coursenames.push(
                { label: val.SELECT, value: null }
            );
        }));
        this.coursenames.push({ label: 'Select a Course', value: null });
        this.rexcecution.push({ label: 'Select a Person', value: null });
        this.ctype = [];
        this.ctype = this.route.snapshot.data['responsible'];
        if (this.ctype) {
            this.ctype.forEach((element:any) => {
                this.rexcecution.push({ label: element.Name, value: element.Id });
            });
        }
        this.coursetype = [];
        this.coursetype = this.route.snapshot.data['course'];
        if (this.coursetype) {
            this.coursetype.forEach((element:any) => {
                this.coursenames.push({ label: element.Title, value: element.Id });
            });

        }

    }

    /**
     * Event handler for Start date
     * @param event
     */
    onStartDateChanged(event: IMyDateModel) {
        this.StartDateFormat = event.date;
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
        this.endDateOptions = this.setDateInput(this.endDateOptions, event, 'startDate');
        this.renewalDateOptions = this.setDateInput(this.renewalDateOptions, event, 'startDate');
        if (!date) {
            let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(this.endDateOptions);
            copyConfig.disableSince = this.getCurrentDate();
            this.startDateOptions = copyConfig;
        }
    }

    /**
     * Event handler for End date
     * @param event
     */
    onEndDateChanged(event: IMyDateModel) {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
        this.startDateOptions = this.setDateInput(this.startDateOptions, event, 'endDate');
        if (!date) {
            let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(this.startDateOptions);
            copyConfig.disableSince = this.getCurrentDate();
            this.startDateOptions = copyConfig;
        }
    }

    /**
     * Method for setting date range
     * @param dateConfig
     * @param event
     * @param type
     */
    setDateInput(dateConfig: IMyDpOptions, event: IMyDateModel, type: string): IMyDpOptions {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
        let dateOptions = HelperService.setDateRange(dateConfig, date, type);
        return dateOptions;
    }

    /**
     * show popup
     */
    showDialog() {
        this.display = true;
    }

    getStartDate() {
        let employeeData = this.courseDetailForm.value;
        return {
            date: employeeData.StartDate.formatted
        };
    }
    /**
     * add and update course data
     */
    saveChanges() {
        let employeeData = this.courseDetailForm.value;
        if (this.employeeId) {
            this.EmployeeCourseData.Id = this.courseId ? this.courseId : 0;
            this.EmployeeCourseData.EmployeeId = this.employeeId;
            this.EmployeeCourseData.BusinessCourseId = employeeData.BusinessCourseId;
            this.EmployeeCourseData.Institute = employeeData.Institute;
            this.EmployeeCourseData.StartDate = employeeData.StartDate ? employeeData.StartDate.formatted : null;
            this.EmployeeCourseData.EndDate = employeeData.EndDate ? employeeData.EndDate.formatted : null;
            this.EmployeeCourseData.Renewaldate = employeeData.Renewaldate ? employeeData.Renewaldate.formatted : null;
            this.EmployeeCourseData.ResponsibleId = employeeData.ResponsibleId;
            this.EmployeeCourseData.Description = employeeData.Description;
            this.EmployeeCourseData.BusinessId = BaseServices.BusinessId;
            this.EmployeeCourseData.Status = '1';
            this.EmployeeCourseData.DeadlineNotification = employeeData.DeadlineNotification;
            this.EmployeeCourseData.EmployeeDocument = [];
            let keyBindings = { 'Originalname': 'OriginalFileName', 'FileTypeId': 'FileType' };
            this.newDocumentAdded.forEach((file: any) => {
                let employeeDoc:any = Object.assign({}, file, this.additionalData)
                employeeDoc.id = 0;
                employeeDoc.status = 1;
                employeeDoc.createdBy = BaseServices.UserId;
                this.EmployeeCourseData.EmployeeDocument.push(HelperService.switchKeysCase(employeeDoc, keyBindings, 'L'))
            })
            if (this.courseId > 0) {
                this.subscriptions.push(this.employeeCourseServices.update(this.EmployeeCourseData).subscribe(result => {
                    if (result) {
                        this.toasterComponent.callToast();
                        this.subscriptions.push(this.employeeCourseServices.getEmployeeCourseByEmployeeid(this.employeeId).subscribe((res: any) => {
                            this.courseobject = res;
                        }));
                    }
                }));
            } else {
                this.EmployeeCourseData.Id = this.courseId ? this.courseId : 0;
                this.EmployeeCourseData.EmployeeId = this.employeeId;
                this.EmployeeCourseData.Status = '1';
                this.subscriptions.push(this.employeeCourseServices.add(this.EmployeeCourseData).subscribe(result => {
                    if (result) {
                        this.toasterComponent.callToast();
                        this.subscriptions.push(this.employeeCourseServices.getEmployeeCourseByEmployeeid(this.employeeId).subscribe((res: any) => {
                            this.courseobject = res;
                        }));
                    }
                }));
            }
        }
    }

    /**
     * go back
     */
    gotoList() {
        this.location.back();
    }

    /**
     * getting single course data
     */
    edit(eid:any) {
        if (eid) {
            this.subscriptions.push(this.employeeCourseServices.getById(eid).subscribe(res => {
                this.Name = res.Name;
                this.courseId = res.Id;
            }));
        } else {
            this.Name = 'New Course';
        }
        if (eid) {
            this.subscriptions.push(this.employeeCourseServices.getById(eid).subscribe(result => {
                this.courseId = result.Id;
                if (result) {
                    this.initForm(result);
                }
            }));
        }
        if (this.courseId > 0) {
            this.subscriptions.push(this.employeeCourseServices.getEmployeeCourseDocument(this.courseId).subscribe((result: any) => {
                this.uploadedFiles = result;
            }))
        }
    }
    /**
     * delete employee course
     * @param courseobject
     */
    delete(courseobject:any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.employeeCourseServices.deleteCourse(courseobject).subscribe(result => {
                    this.subscriptions.push(this.employeeCourseServices.getEmployeeCourseByEmployeeid(this.employeeId).subscribe((res: any) => {
                        this.courseobject = res;
                        this.toasterComponent.callToastDlt();
                    }));
                }));
            }
        });
    }
    /**
     * clearing form value
     */
    Clear() {
        this.uploadedFiles = [];
        this.courseId = 0;
        this.initForm();
    }

    printList() {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to generate report?',
            accept: () => {
                let pdfSetting = new PdfSetting();
                pdfSetting.date = HelperService.formatDate(new Date());
                pdfSetting.businessName = BaseServices.BusinessName;
                pdfSetting.pageHeader = 'Course Details';
                let columns:any = [
                    { title: 'Course Name', dataKey: 'Course' },
                    { title: 'Institute', dataKey: 'Institute' },
                    { title: 'Start Date', dataKey: 'StartDate' },
                    { title: 'End Date', dataKey: 'EndDate' },
                    { title: 'Renewal Date', dataKey: 'Renewaldate' },
                    {
                        title: 'Responsible for Execution',
                        dataKey: 'ResponsiblepersonName'
                    }
                ];
                HelperService.generatePdf(this.courseobject, columns, 'Course list', pdfSetting, 'l');
            }
        });
    }
    /**
     * show and hide save button
     */
    OnShowSaveButton() {
        let currentUserRole = BaseServices.UserRole;
        let userRightsId;
        let userRights;
        let data;
        if (currentUserRole === 'User') {
            userRightsId = Rights.EDIT_MY_PAGE;
            userRights = BaseServices.getUserRights();
            data = JSON.parse(userRights);
            this.isEditMyPage = HelperService.checkRightsData(data, userRightsId);
        } else {
            this.isEditMyPage = false;
        }

    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}

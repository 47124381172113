import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UploadInput } from 'ngx-uploader';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class FileUploadService {
    private subscription: Subscription;
    public static get headerOption() {
        let headers = new HttpHeaders();
        let session = JSON.parse(sessionStorage.getItem('session')!);
        if (session) {
            let type = 'Bearer';
            let token = session.AccessToken;
            // Split the JWT token by dots
            const tokenParts = token.split('.');

            // Check if there are at least two parts (header and payload)
            if (tokenParts.length >= 2) {
                // Add the desired letter after the first dot (in the payload part)
                tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);

                headers = headers.append('content-type', 'application/json');
                headers = headers.append('Authorization', type + ' ' + token);
            } else {
                // Handle the case where the token doesn't have the expected format
                console.error('Invalid JWT token format');
            }
        }
        let options = { headers: headers }
        return options;
    }

    constructor(private http: HttpClient) { }

    uploadBusinessFile(formData:any): UploadInput {
        let uploadInput: UploadInput = {
            type: 'uploadAll',
            url: environment.BASE_URL + '/file/upload/business/manual',
            method: 'POST',
            data: {
                FolderTypeName: formData.folderTypeName,
                ApplicationId: formData.applicationId,
                BusinessId: formData.businessId,
                FeatureId: formData.featureId
            }
        };

        return uploadInput;
    }

    uploadAdminFile(formData) {
        let result: any = this.http
            .post(
                environment.BASE_URL + '/file/upload/application/manual',
                formData,
                FileUploadService.headerOption
            )
            .map(result => result)
            .catch((error:any) => Observable.throw(error))
            .subscribe(
            );
        this.subscription = result;
        return result;
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}

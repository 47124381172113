import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { element } from 'protractor';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { Status } from 'src/app/_models';
import { Batch, EditBatch, Measurement, Product } from '../../../models/Product';
import { FoodSafetyServices } from '../../../services/foodsafety.services';
import { BatchStatus, ControlType } from '../../../models/heating-cooling';
import { Table } from 'primeng/table';
import { Dropdown } from 'primeng/dropdown';
@Component({
  selector: 'app-cooling-list',
  templateUrl: './cooling-list.component.html',
  styleUrls: ['./cooling-list.component.css']
})
export class CoolingListComponent implements OnInit {
  @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;  
  @ViewChild('dt', { static: false }) dt: Table;
  coolingList: any[];
  showNewBatchForm: boolean;
  batchForm: FormGroup;
  products: SelectItem[];
  productsList: SelectItem[] = [];
  controlTypeOptions: SelectItem[] = [];
  statusOptions: SelectItem[] = [];
  controlType: SelectItem[];
  productForm: FormGroup;
  productList: any;
  loading: boolean;
  addProduct: boolean;
  productInUse: boolean;
  productId: any;
  measurementId: number;
  measurementList: any[];
  editBatch: boolean;
  addBatch: boolean;
  editMeasurement: boolean;
  currentTime: any;
  rowID: number;
  isCollapsed: boolean;
  foodSafetyId: number;
  productName: string;
  selectedBatches: any = [];
  batchId: number;
  batchStatus: number;
  showSaveButton: boolean;
  showEditButton: boolean;
  productExists:boolean;
  showProducts:boolean;
  isReadonly: boolean;
  saveDisable: boolean = false;
  isProductAllocated: boolean = false;
  isCoolorHeat: number;
  minTemperature: number = -10;
  maxTemperature: number = 100;
  ControlTypeText: string;

  constructor(private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private foodsafetyService: FoodSafetyServices,
    private datePipe: DatePipe,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.coolingList = this.route.snapshot.data['coolingList'];
    this.TemperatureData();
    this.batchForm = this.formBuilder.group({
      controlId : ['', Validators.required],
      productId: ['', Validators.required],
      timeOfMeasurement: ['', Validators.required],
      temperature: ['60', Validators.required],
      Comments: ['']
    });

    this.productForm = this.formBuilder.group({
      productName: ['', [Validators.required, ValidationService.noWhitespaceValidator]]
    });

    let productData = this.route.snapshot.data['coolingProduct'];
    this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
      this.products = [];
      this.products.push({ label: val.SELECT, value: null });
      productData.forEach(element => {
        this.products.push({ label: element.Name, value: element.Id })
      });
      this.productsList = this.products;
    });
    this.translate.stream('SELECT_CONTROL_TYPE').subscribe(val => {
      this.controlType = [];
      this.controlType.push(
        { label: val.SELECT, value: null },
        { label: val.COOLING, value: ControlType.Cooling },
        { label: val.HEATING, value: ControlType.Heating }
      );
      this.controlTypeOptions = this.controlType;      
    });
    this.translate.stream('SELECT_STATE').subscribe(val => {
      this.statusOptions = [];
      this.statusOptions.push(
        { label: val.SELECT, value: null },
        { label: val.NEW, value: BatchStatus.New },
        { label: val.COMPLETED, value: BatchStatus.Completed }
      );    
    });

     this.batchForm.get('controlId')?.valueChanges.pipe().subscribe(selectedValue => {
      if (selectedValue === ControlType.Cooling) {
        this.batchForm.patchValue({ temperature: 60 });
        this.isCoolorHeat = 1;
        this.minTemperature = -10;
      } else if (selectedValue === ControlType.Heating) {
        this.batchForm.patchValue({ temperature: 0 });
        this.isCoolorHeat = 2;
        this.minTemperature = 0; 
      }else {
        this.isCoolorHeat = null;
      }
    });
    this.measurementId = 0;
    this.batchId = 0;
    this.foodSafetyId = this.route.snapshot.params['Id'];
  }

  addBatchAndMeasurement(batch, isAddorEdit, data) {
    this.isCoolorHeat = 0;
    this.showNewBatchForm = true;
    if(data != null){
      this.productId = batch.Id;
      this.batchForm.controls['productId'].setValue(batch.ProductId);
    }
    this.batchForm.get("temperature").setValue(60);
    if (isAddorEdit == 'edit') {
      this.editBatch = true;
      this.addBatch = false;
      this.editMeasurement = false;
      this.batchId = data.CoolingMeasurements[0].BatchId;
      let lastTempValue = data.CoolingMeasurements[data.CoolingMeasurements.length - 1].TemperatureValue;
      let lastTtimeOfMeasurement = data.CoolingMeasurements[data.CoolingMeasurements.length - 1].DisplayTime;
      let lastComments = data.CoolingMeasurements[data.CoolingMeasurements.length - 1].Comments;
      this.batchForm.patchValue({ temperature: lastTempValue });
      if(data.ControlType == null){
        data.ControlType = ControlType.Cooling;
        data.ControlTypeText = "COOLING";
      }
      this.batchForm.controls['controlId'].setValue(data.ControlType);
      this.measurementId = 0;
      this.productName = data.ProductName;
      this.ControlTypeText = data.ControlTypeText;
      if (data != null) {
        let measurements = {
          controlId: data.ControlType,
          productId: batch.ProductId,
          timeOfMeasurement: lastTtimeOfMeasurement,
          temperature: lastTempValue,
          Comments: lastComments
        };
        (<FormGroup>this.batchForm).setValue(measurements, { onlySelf: true });
      }
    }
    else if (isAddorEdit == 'add') {
      this.editBatch = false;
      this.addBatch = true;
      this.editMeasurement = false;
      this.batchId = 0;
      this.measurementId = 0;
      this.batchForm.patchValue({controlId: null});
    }
    else if (isAddorEdit == 'editMeasurement') {
      let contType = batch.ControlType;
      if(contType != null){        
        this.ControlTypeText = contType === 2 ? "HEATING" : "COOLING";
        this.isCoolorHeat = contType === ControlType.Heating ? 2 : 1;
      }
      this.batchForm.controls['productId'].setValue(batch.ProductId);
      this.editMeasurement = true;
      this.editBatch = false;
      this.addBatch = false;
      if (data != null) {
        this.measurementId = data.Id;
        this.batchId = data.BatchId;
        let measurement = {
          controlId: contType,
          productId: this.productId,
          timeOfMeasurement: data.DisplayTime,
          temperature: data.TemperatureValue,
          Comments: data.Comments
        };
        (<FormGroup>this.batchForm).setValue(measurement, { onlySelf: true });
      }
    }    
    this.cdr.detectChanges();
    if (this.editBatch) {
      this.foodsafetyService.getCoolingBatch(batch.Id).subscribe((measurements: any) => {
        this.measurementList = measurements.CoolingMeasurements;
      });
    }
    if (data != null) {
      this.showSaveButton = data.Status == 3 || data.Status == 2 ? false : true;
    } else {
      this.showSaveButton = true;
    }
    if (data.Status == 2) {
      this.showSaveButton = true;
    }
  }
  onControlTypeChange(event: Dropdown) {
    const controlTypeValue = event.value; 
    if (controlTypeValue !== null) {
      this.dt.filter(controlTypeValue, 'ControlType', 'equals');
    } else {
      this.dt.filter('', 'ControlType', 'equals');
    }
  }
  onStatusFilterChange(event: Dropdown) {
    const selectedStatus = event.value;
    if (selectedStatus !== null) {
      if (selectedStatus === BatchStatus.Completed) {
        this.dt.filter([2, 3], 'Status', 'in');
      } else if (selectedStatus === BatchStatus.New) {
        this.dt.filter(1, 'Status', 'equals');
      }
    } else {
      this.dt.filter('', 'Status', 'equals');
    }
  }
  
  TemperatureData(){
    if(this.coolingList.length > 0){
      this.coolingList.forEach(cooling => {
        const lastMeasurement = cooling.CoolingMeasurements[cooling.CoolingMeasurements.length - 1];
        cooling.TemperatureFilterField = lastMeasurement ? lastMeasurement.DispalyTemperatureValue.toString() : '';
      });
    }
  }

  viewProduct() {
    this.foodsafetyService.getCoolingProducts().subscribe(products => {
      this.productList = products;
    });
  }

  saveProduct() {
    this.loading = true;
    let product = new Product();
    product.Name = this.productForm.value.productName;
    product.BusinessId = BaseServices.BusinessId;
    if (this.productId > 0) {
      if (this.productName == product.Name) {
        this.batchForm.get('productId').patchValue(this.productId);
        this.loading = false;
        this.addProduct = false;
      }
      else {
        product.Id = this.productId;
        product.ModifiedBy = BaseServices.UserId;
        product.ModifiedOn = new Date();
        this.foodsafetyService.updateCoolingProduct(product).subscribe((result: any) => {
          if (result) {
            this.loading = false;
            this.toasterComponent.callToast();
            this.batchForm.get('productId').patchValue(this.productId);
            this.getProduct();
            this.addProduct = false;
          }
          else {
            this.productInUse = true;
            setTimeout(() => {
              this.productInUse = false;
            }, 2000);
          }
        });
      }
    }
    else {
      product.Id = 0;
      product.Active = true;
      product.CreatedBy = BaseServices.UserId;
      product.CreatedOn = new Date();
      this.foodsafetyService.createCoolingProduct(product).subscribe((result: any) => {
        if (result != null) {
          this.loading = false;
          this.toasterComponent.callToast();
          this.batchForm.get('productId').patchValue(result.Id);
          this.getProduct();
          this.addProduct = false;
          this.clearProduct();
        }
        else {
          this.productInUse = true;
          setTimeout(() => {
            this.productInUse = false;
          }, 2000);
        }
      });
    }
  }
  getControlTypeIcon(controlType: number): string {
    switch (controlType) {
      case ControlType.Cooling: 
        return '/assets/icons/cooling.png';
      case ControlType.Heating: 
        return '/assets/icons/heating.png';
      default:
        return '/assets/icons/cooling.png'; 
    }
  }
  
  clearProduct() {
    this.productForm.reset({ Name: '' })
  }

  editProduct(product) {
    this.productId = product.Id;
    this.productName = product.Name;
    let result = {
      productName: product.Name
    };
    (<FormGroup>this.productForm).setValue(result, { onlySelf: true });
  }

  setCurrentTime() {
    this.batchForm.controls['timeOfMeasurement'].setValue(this.datePipe.transform(new Date(), "HH:mm"));
  }

  clear() {
    this.batchForm.controls['timeOfMeasurement'].setValue('');
  }
  onProductChange(e: any, prodDropdown: Dropdown) {
    if (e.value) {
      // custom filter datatable
      this.dt.filter(
        prodDropdown.selectedOption.label,
        'ProductName',
        'equals'
      );
    } else {
      this.dt.filter('', 'ProductName', 'equals');
      prodDropdown.resetFilter();
    }
  }
  
  onTemperatureFilter(event: any) {
    const filterValue = event.target.value.toLowerCase();
    this.dt.filter(filterValue, 'TemperatureFilterField', 'contains');
  }
  
  
  saveBatch(form: any) {
    this.saveDisable = true;
    this.loading = true;
    let measurement = new Measurement();
    measurement.Id = 0;
    let dateFormat = moment().format("HH:mm");
    let selectedTime = this.batchForm.value.timeOfMeasurement;
    if ((this.batchForm.value.timeOfMeasurement).toString().includes(")")) {
      selectedTime = this.datePipe.transform(new Date(this.batchForm.value.timeOfMeasurement), 'HH:mm')
    }
    let date = new Date(`1970-01-01 ${selectedTime}`)
    let time = selectedTime ? this.datePipe.transform(date, `${date.getHours()}:${date.getMinutes()}`, 'HH:mm') : ''
    measurement.Time = time;
    measurement.Active = true;
    measurement.Comments = this.batchForm.value.Comments;
    measurement.TemperatureValue = this.batchForm.value.temperature;
    measurement.CreatedBy = BaseServices.UserId;
    measurement.CreatedOn = new Date();
    measurement.Status = (this.batchForm.value.Comments == "" || this.batchForm.value.Comments == null) ? 1 : 2;
    if (form === 'save') {
      let batch = new Batch();
      batch.Id = 0;
      batch.ProductId = this.batchForm.value.productId;
      batch.BusinessId = BaseServices.BusinessId;
      batch.FoodsafetyId = this.foodSafetyId;
      batch.Active = true;
      batch.ControlType = this.isCoolorHeat;
      if(this.isCoolorHeat == ControlType.Cooling){
        batch.Prefix = 'CL';
      }else{
        batch.Prefix = 'HT';
      }
      batch.CreatedBy = BaseServices.UserId;
      batch.CreatedOn = new Date();
      batch.CreatedBy = BaseServices.UserId;
      batch.CreatedOn = new Date();
      batch.NewCoolingMeasurement = measurement;
      if (this.batchId == 0) {
        this.foodsafetyService.createCoolingBatch(batch).subscribe((result: any) => {
          if (result != null) {
            this.loading = false;
            this.toasterComponent.callToast();
            this.showNewBatchForm = false;
            this.refreshGrid();
            this.clearForm();
            this.saveDisable = false;
          }
        });
      }
      else if (this.batchId > 0 && this.measurementId > 0) {
        measurement.Id = this.measurementId;
        measurement.Time = this.batchForm.value.timeOfMeasurement;
        measurement.ModifiedBy = BaseServices.UserId;
        this.foodsafetyService.updateCoolingMeasurement(measurement).subscribe((data: any) => {
          if (data) {
            this.loading = false;
            this.toasterComponent.callToast();
            this.showNewBatchForm = false;
            this.refreshGrid();
            this.clearForm();
            this.saveDisable = false;
          }
        })
      }
      else if (this.batchId > 0 && this.measurementId == 0) {
        measurement.BatchId = this.productId;
        measurement.IsDefault = false;
        batch.NewCoolingMeasurement.BatchId = this.productId;
        this.foodsafetyService.createCoolingMeasurement(measurement).subscribe((data: any) => {
          if (data != null) {
            this.measurementList = data;
            this.refreshGrid();
            this.loading = false;
            this.toasterComponent.callToast();
            this.showNewBatchForm = false;
            this.clearForm();
            this.saveDisable = false;
          }
        })
      }
    }
    else if (form === 'complete') {
      this.confirmationService.confirm({
        message: this.translate.instant('SAVE_COMPLETE_BATCH'),
        accept: () => {
          let editBatch = new EditBatch();
          editBatch.BatchId = this.batchId
          editBatch.Status = (this.batchForm.value.Comments == "" || this.batchForm.value.Comments == null) ? 3 : 2;
          editBatch.ModifiedBy = BaseServices.UserId;
          measurement.IsDefault = false;
          editBatch.NewCoolingMeasurement = measurement;
          editBatch.NewCoolingMeasurement.BatchId = this.productId;
          this.foodsafetyService.updateCoolingBatch(editBatch).subscribe((data: any) => {
            if (data) {
              this.refreshGrid();
              this.loading = false;
              this.toasterComponent.callToast();
              this.showNewBatchForm = false;
              this.clearForm();
              this.saveDisable = false;
            }
          });
        }
      })
    }
  }

  refreshGrid() {
    this.foodsafetyService.getCoolingBatches(this.foodSafetyId).subscribe((data: any) => {
      this.coolingList = data;
      this.TemperatureData();
    })
  }

  clearForm() {
    this.batchForm.reset({ temperature: 60 });
  }

  deleteBatch(Id) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_BATCH'),
      accept: () => {
        this.foodsafetyService
          .deleteCoolingBatch(Id)
          .subscribe((list: any) => {
            if (list) {
              this.toasterComponent.callToastDlt();
              this.refreshGrid();
            }
          });
      }
    });
  }

  deleteMeasurement(Id, batchId) {
    if (batchId > 0) {
      this.foodsafetyService.getCoolingBatch(batchId).subscribe((measurements: any) => {
        let measurementCount = measurements.CoolingMeasurements.length;
        if (measurementCount > 1) {
          this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
              this.foodsafetyService
                .deleteCoolingMeasurement(Id)
                .subscribe((list: any) => {
                  if (list) {
                    this.toasterComponent.callToastDlt();
                    this.refreshGrid();
                  }
                });
            }
          });
        }
        else if (measurementCount == 1) {
          this.confirmationService.confirm({
            message: this.translate.instant('DELETE_MEASUREMENT'),
            accept: () => {
              this.foodsafetyService
                .deleteCoolingMeasurement(Id)
                .subscribe((list: any) => {
                  if (list) {
                    this.toasterComponent.callToastDlt();
                    this.refreshGrid();
                  }
                });
            }
          });
        }
      });
    }
  }

  deleteProduct(product) {
    let Id = product.Id
    this.foodsafetyService
      .checkProductAllocate(Id)
      .subscribe((result: any) => {
        if (result) {
          this.isProductAllocated = true;
          setTimeout(
            function () {
              this.isProductAllocated = false;
            }.bind(this),
            3000
          );
        }
        else {
          this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
              this.foodsafetyService.deleteCoolingProduct(Id).subscribe((data: any) => {
                if (data) {
                  this.toasterComponent.callToastDlt();
                  this.viewProduct();
                }
              });
            }
          })
        }
      });
  }

  getProduct() {
    this.foodsafetyService.getCoolingProducts().subscribe((result: any) => {
      if (result != null) {
        this.productList = result;
        this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
          this.products = [];
          this.products.push({
            label: val.SELECT, value:
              null
          });
          result.forEach((type: any) => {
            this.products.push({ label: type.Name, value: type.Id })
          });
        });
      }
    })
  }

  backtoList() {
    this.showNewBatchForm = false;
  }

  generateReport(batch) {
    let batchListId: Array<number> = [];
    let fileName;
    let filetype = 'pdf';
    let cultureInfo = sessionStorage.getItem('languageMode');
    if (batch.Id > 0) {
      batchListId.push(batch.Id);
      fileName = batch.ControlType == ControlType.Cooling ? this.translate.instant('COOLING') : this.translate.instant('HEATING');
    }
    else {
      this.selectedBatches.forEach(x => {
        batchListId.push(x.Id)
      });
      const cooling = this.selectedBatches.some(x => x.ControlType === ControlType.Cooling);
      const heating = this.selectedBatches.some(x => x.ControlType === ControlType.Heating);
      fileName = cooling && heating  ? this.translate.instant('HEATING_COOLING')  
                : cooling  ? this.translate.instant('COOLING')
                : this.translate.instant('HEATING');
    }
    if (batchListId.length > 0) {
      this.loading = true;
      this.foodsafetyService.reportGenerate(batchListId, this.foodSafetyId, cultureInfo).subscribe(blob => {
        this.loading = false;
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileName}.${filetype.toLowerCase()}`;
        link.click();
      });
    }
    else {
      alert(this.translate.instant('PLEASE_SELECT_A_REPORT'));
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  toggleClick(cooling) {
    this.productName = cooling.ProductName;
    this.productId = cooling.ProductId; // Assigned the productId value while edit the batch
    this.batchStatus = cooling.Status; // Assigned the batch status to play the condition with save button
    if (cooling != null) {
      this.showEditButton = cooling.Status == 3 || cooling.Status == 2 ? false : true;
    } else {
      this.showEditButton = true;
    }
  }

  numberOnly1(event) {
    var charCode = (typeof event.which == "number") ? event.which : event.keyCode;
    if (!charCode || charCode == 8 /* Backspace */) {
      return null;
    }
    var typedChar = String.fromCharCode(charCode);
    // Allow numeric characters
    if (/\d/.test(typedChar)) {
      return null;
    }
    // Allow the minus sign (-) if the user enters it first
    if (typedChar == "-" && this.batchForm.value.temperature == null) {
      return null;
    }
    if (this.batchForm.value.temperature.toString().indexOf("-") == -1) {
      return null;
    }
    return false;
  }

}

import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { HttpClient } from '@angular/common/http';


@Injectable()

export class BusinessSignedService {
    constructor(private http: HttpClient) {

    }
    createAuthorizationHeader(headers: Headers) {
        headers.append('content-type', 'application/json');
    }
    getAllUsers(bid: number) {
        return this.http.get<any>(environment.BASE_URL + '/userbybusiness/' + bid, )
            .map(result => result);
    }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'inspection-followup-view',
    templateUrl: 'inspection-followup-view.component.html'
})

export class InspectionFollowupViewComponent implements OnInit {
    enabledManuals: any;
    tabsEnabled: string[];
    firstRoute = true;
    routeSub:Subscription;
    constructor(private route: ActivatedRoute,
        public router: Router) { }

    ngOnInit() {
        this.tabsEnabled = [];
        this.enabledManuals = this.route.snapshot.parent.data['enabledFeatures'];
        if (this.enabledManuals ? this.enabledManuals.EnabledManuals : false) {
            (JSON.parse(this.enabledManuals.EnabledManuals)).forEach(m => {
                if (m.name == 'INSTALLER_INSPECTION_OVERVIEW' ) {
                    this.tabsEnabled.push('installer-insp');
                }
                if (m.name == 'SELF_INSPECTION_OVERVIEW') {
                    this.tabsEnabled.push('self-insp');
                }
                if (m.name == 'DEVIATION_OVERVIEW') {
                    this.tabsEnabled.push('dev-overview');
                }
                if (m.name == 'REPORT_TEMPLATES') {
                    this.tabsEnabled.push('report-temp');
                }
                if (m.name == 'INSTALLATION_FORM_OVERVIEW') {
                    this.tabsEnabled.push('form-overview');
                }
                if (m.name == 'INSTALLATION_FORMS') {
                    this.tabsEnabled.push('forms');
                }
            });
        }
        this.router.navigate(['./'+this.tabsEnabled[0]],{relativeTo:this.route});
        this.firstRoute  = true;
        this.routeSub = this.router.events.subscribe((val) => {
            if(val instanceof NavigationEnd){
                if(this.router.url.indexOf('inspection;Id') > -1){
                    if(this.firstRoute){
                        this.firstRoute = false;
                    }
                    else{
                        if(this.router.url.indexOf('installer-insp') > -1 ? false :
                        this.router.url.indexOf('self-insp') > -1 ? false : 
                        this.router.url.indexOf('dev-overview') > -1 ? false : 
                        this.router.url.indexOf('report-temp') > -1 ? false : 
                        this.router.url.indexOf('form-overview') > -1 ? false : 
                        this.router.url.indexOf('forms') > -1 ? false : 
                        this.router.url.indexOf('complete-cklist') > -1 ? false : true){
                            this.router.navigate(['./'+this.tabsEnabled[0]],{relativeTo:this.route});
                        }
                    }
                }
            }
        })
    }
    ngOnDestroy(){
        this.routeSub.unsubscribe();
    }
}
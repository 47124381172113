import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/Observable';

import { BaseServices } from './../../kuba.services';
import { CommonArticle } from './../models';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CommonArticleService {
    constructor(private http: HttpClient) { }

    getManualArticles(
        manualId: number,  
        levelId: number,
        levelKey: number,
        parentFolderId: number
    ): Observable<CommonArticle[]> {
        return this.http
            .get<any[]>(
                `${environment.BASE_URL
                }/manual/${manualId}/article/${levelId}/${levelKey}/${parentFolderId}`,
                BaseServices.headerOption
            )
            .map(result => {
                return result;
            });
    }

    getArticle(Id: number, type: string) {
        if (type.toLocaleUpperCase() === 'MANUAL') {
            let res: any = this.http
                .get(
                    `${environment.BASE_URL}/manual/${Id}/article/${Id}`,
                    BaseServices.headerOption
                )
                .map(result => {
                    return result;
                });
            return res;
        }
    }

    add(commonArticle: CommonArticle, type: string) {
        if (type.toLocaleUpperCase() === 'MANUAL') {
            let res: any = this.http
                .post(
                    `${environment.BASE_URL}/manual/${commonArticle.AppManualId
                    }/article/add`,
                    commonArticle,
                    BaseServices.headerOption
                )
                .map(result => {
                    return result;
                });
            return res;
        }
    }

    update(commonArticle: CommonArticle, type: string) {
        if (type.toLocaleUpperCase() === 'MANUAL') {
            let res: any = this.http
                .put(
                    `${environment.BASE_URL}/manual/${commonArticle.AppManualId
                    }/article/update`,
                    commonArticle,
                    BaseServices.headerOption
                )
                .map(result => {
                    return result;
                });
            return res;
        }
    }

    delete(Id: number, manualLAR: CommonArticle) {
        return this.http
            .delete(
                `${environment.BASE_URL}/manual/${manualLAR.AppManualId
                }/law-regulations/delete/${Id}`,
                BaseServices.headerOption
            )
            .map(result => {
                return result;
            });
    }
}


import { Execution, Recurrence } from './../../follow-ups/models/activities';
export class ActivityList {
    Id: number;
    BusinessDepartmentId: number;
    Title: string;
    Description: string;
    WeekdayRecur: Recurrence[];
    Status: string;
    ExecutionUserId: number;
    FollowupUserId: number;
    ExecutionUserName: string;
    FollowupUserName: string;
    RepetitionId: number;
    StartDate: string;
    SendUserData: boolean;
    ActivityScheduleId: number;
    ChecklistId: number;
    LinkUrl: string;
    isEmailorSms: string;
    CompletedDate: string;
    BusinessId: number;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
    ModifierName: string;
    FeatureKey: number;
    FeatureId: string;
    LanguageId: number;
    ActivityScheduleDto: ActivitySchedule;
    ActivityAttachment: ActivityAttachment[];
    ExecutionUsers: Execution[];
    FollowupUsers: Execution[];
    ApplicationId: number;
    IsFutureRecurring: boolean;
    ModuleFeatureId: number;
    CultureInfo: string;
    StopRecurr: boolean;
    WeekdayRecurParent: number;
    IsDeleted: boolean;
}
export class ActivitySchedule {
    Id: number;
    StartDate: string;
    EndDate: string;
    ScheduleRule: string;
    NotifyBeforeDeadLine: string;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number
    ModifiedOn: string;
    ExecutionUsers: Execution[];
    FollowupUsers: Execution[];
    ParentId: number;
    ActivityId: number;
    NotifyBeforeFollowup: string;
}
export class FollowUpPerson {
    Name: string;
    Id: number;
}
export class ActivityAttachment {
    Id: number;
    ActivityId: number;
    Filename: string;
    OriginalFileName: string;
    Path: string;
    MimeType: string;
    Size: number;
    FileType: string;
    Status: string;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
}

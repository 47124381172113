import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import {
  ArticleModel,
  ArticleApproveDetails,
} from 'src/app/shared/ecpl-article-viewer/interfaces';
import { ChecklistModel } from 'src/app/shared/ecpl-checklist-viewer/ecpl-checklist-viewer.model';
import { EcplTreeData } from 'src/app/shared/ecpl-document-tree/intefaces/ecpl-tree-data';
import * as _ from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {
  humanizeBytes,
  UploadFile,
  UploadInput,
  UploadOutput,
  UploaderOptions,
} from 'ngx-uploader';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { UploaderConfig } from './uploader-config';
import { KubaBulkReportService } from './file-window.service';
import { SelectItem } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { HelperService } from '../../_services/helper.service';
import { UserService } from '../../kuba/users/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ManualService } from 'src/app/kuba/manuals/services/manual.services';
const StringBuilder = require('string-builder');
import { Feature, FeatureKey, Rights } from 'src/app/_models';
import { ElectroDocumentService } from 'src/app/kuba/electro/services/electro-document.services';
import { EmployeeServices } from 'src/app/kuba/employees/services/employee.services';
import { EmployeePrivateDocumentServices } from 'src/app/kuba/employees/services/employeeprivatedocument.service';
import { DomSanitizer } from '@angular/platform-browser';
import { v4 as uuidv4 } from 'uuid';
import { Subscription } from 'rxjs';
import { AnyARecord } from 'dns';
@Component({
  selector: 'ecpl-file-window',
  templateUrl: './file-window.component.html',
  styleUrls: ['./file-window.component.css'],
})
export class FileWindowComponent implements OnInit, OnChanges {
  @Input() config: UploaderConfig;
  @Input() uploadedFiles: any;
  @Input() ecplApiResponse: string;
  @Input() triggerUploader: boolean;
  @Input() folderList: SelectItem[];
  @Input() businessMode: string;
  @Input() adminUsers: any[];
  @ViewChild('newFolderModal', { static: false })
  newFolderModal: TemplateRef<any>;
  @ViewChild('hiddenAction', { static: false }) hiddenAction: ElementRef;

  copyDocForm: FormGroup;
  unSavedFiles = [];
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: (bytes: number) => string;
  dragOver: boolean;
  listView: any = true;
  uuid: any;
  query: any;
  file: any;
  showLoader = false;
  bsModalRef: BsModalRef;
  bsConfig = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
  };
  bsModalTitle: string;
  newFolderNode: EcplTreeData;
  currentEventData: any;
  options: UploaderOptions;
  selectedAll: any;
  fileName: string;
  showCopyLinkDailog = false;
  filePath: string;
  applicationId: number;
  businessId: number;
  portalId: number;
  businessPortalId: number;
  projectId: number;
  roleId: number;
  featureId: any;
  userId: number;
  pdUserId: any;
  manualName: any;
  workingModule: any;
  projectName: string;
  isAddfiles: string;
  isMypage: boolean = true;
  adminId: string;
  ismanual: string;
  manualpage: boolean = false;
  commonpage: boolean = false;
  isemployee: string;
  isPortal: boolean;
  get UserId() {
    return BaseServices.UserId;
  }
  fileDateOptions: IMyDpOptions;
  startDate: string;
  endDate: string;
  appManualId: number;
  pgConfig: PaginationInstance = {
    itemsPerPage: 5,
    currentPage: 1,
  };
  pageSizeList: SelectItem[] = [
    { label: 'All', value: 0 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];
  createdBy: SelectItem[];
  selectedCreatedByUserId: any = null;
  selectedFolderId: any = null;
  isEditor = false;
  isUser = false;
  openVideoPopup = false;
  isVideos = false;
  videoLink: any;
  VideoTitle: string;
  deleteRightsDocumentedTraining = false;
  deleteRightsApprovalDocument = false;
  isAdmin: boolean = false;
  businessEditedDate: string; 
  textName : string;
  private subscriptions: Subscription[] = [];
  public selectedPageSize = this.pgConfig.itemsPerPage;
  @Output() saveChanges: EventEmitter<any> = new EventEmitter<any>();
  @Output() activeFolder: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileChangeEvents: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterFolderEvents: EventEmitter<any> = new EventEmitter<any>();
  @Output() toogleEditDisplayMode: EventEmitter<any> = new EventEmitter<any>();
  @Output() showDocumentDialog: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('uploadAction', { static: false }) uploadAction: ElementRef;
  @ViewChild('folderDropDown', { static: false }) folderDropDown: any;
  @Output() openChecklistOrFormEmit: EventEmitter<any> =
    new EventEmitter<any>();

  featureKey;

  constructor(
    private bsModalService: BsModalService,
    private cdr: ChangeDetectorRef,
    private bulkReportService: KubaBulkReportService,
    private userService: UserService,
    private _fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private manualServices: ManualService,
    private employeeService: EmployeeServices,
    private electroDocumentServices: ElectroDocumentService,
    private employeePrivateDocumentServices: EmployeePrivateDocumentServices,
    private _sanitizer: DomSanitizer
  ) {
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
    this.copyDocForm = this._fb.group({
      filePath: [''],
    });
    this.manualName = this.route.snapshot.params['manualname'];
    this.businessId = +this.route.snapshot.params['mBusinessId'];
    this.appManualId = +this.route.snapshot.params['mid'];
    this.applicationId = BaseServices.ApplicationId;
    this.portalId = +this.route.snapshot.params['mPortalId'];
    this.projectId = this.route.snapshot.params['Id'];
    if (this.projectId === undefined) {
      this.projectId = 0;
    }
    this.fileName = 'Article bulk print';
    this.featureId = JSON.parse(sessionStorage.getItem('workingModule')!)
      ? JSON.parse(sessionStorage.getItem('workingModule')!).id
      : 0;
    this.featureKey = JSON.parse(sessionStorage.getItem('workingModule')!);
  }

  getUserId(empId: any) {
    this.subscriptions.push(
      this.employeeService
        .getUserByEmployeeId(empId)
        .subscribe((result: any) => {
          this.userId = result;
          return result;
        })
    );
  }

  ngOnInit() {
    let empId =
      this.config.pdEmpId != undefined
        ? this.config.pdEmpId
        : this.route.snapshot.params['eid'];
    const parentFId = this.route.snapshot.params['Id'];
    if (parentFId) {
      sessionStorage.setItem('pid', parentFId);
    }

    if (+empId > 0) {
      this.getUserId(empId);
    }
    this.pdUserId = BaseServices.UserId;
    this.adminId = BaseServices.roleId;
    this.isPortal = BaseServices.roleId === '2' ? true : false;
    if (BaseServices.roleId == '3') {
      if (BaseServices.userBase.UserFeatureKey == this.config.pdEmpId)
        this.isUser = true;
      else this.isEditor = true;
    }
    if (
      (BaseServices.roleId == '4' || BaseServices.roleId == '5') &&
      BaseServices.userBase.UserFeatureKey == this.config.pdEmpId
    ) {
      this.isUser = true;
    }
    if (BaseServices.roleId == '1') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    if (BaseServices.UserRole == 'User') {
      let feature = JSON.parse(BaseServices.getUserRights());
      let checkDocumentedTraining = feature.filter(
        (x: any) =>
          x.id == Rights.DOCUMENTED_TRAINING_RIGHTS && x.isChecked == true
      );
      if (checkDocumentedTraining[0] != null) {
        this.deleteRightsDocumentedTraining = true;
      }
      let checkPrivateDocuments = feature.filter(
        (x: any) =>
          x.id == Rights.APPROVAL_DOCUMENT_RIGHTS && x.isChecked == true
      );
      if (
        checkPrivateDocuments[0] != null &&
        checkPrivateDocuments[0].isChecked
      ) {
        this.deleteRightsApprovalDocument = true;
      }
    } else {
      this.deleteRightsDocumentedTraining = true;
      this.deleteRightsApprovalDocument = true;
    }

    this.ismanual = this.router.url.substring(
      this.router.url.indexOf('manual') + 0,
      this.router.url.indexOf('manual') + 6
    );
    this.isemployee = this.router.url.substring(
      this.router.url.indexOf('employee') + 0,
      this.router.url.indexOf('employee') + 8
    );
    if (this.ismanual == 'manual') {
      this.manualpage = true;
    } else if (this.isemployee == 'employee') {
      this.manualpage = false;
    } else {
      this.commonpage = true;
    }
    this.isAddfiles = this.router.url.substring(
      this.router.url.lastIndexOf('/') + 1
    );
    if (this.isAddfiles.length <= 4) {
      this.isMypage = true;
    } else {
      this.isMypage = false;
    }
    // To hide side menu bar
    document.querySelector('body').classList.remove('opened');
    this.roleId = +BaseServices.roleId;
    if (this.config.showFDVItems) {
      this.options = {
        concurrency: 1,
        allowedContentTypes: ['application/pdf'],
      };
    } else if (this.config.showProjectPhoto) {
      this.options = {
        concurrency: 1,
        allowedContentTypes: [
          'image/jpeg',
          'image/png',
          'image/png',
          'image/bmp',
        ],
      };
    } else if (
      this.config.documentType === 'article' ||
      this.config.documentType === 'checklist'
    ) {
      this.options = {
        concurrency: 1,
        allowedContentTypes: ['text/Article'],
      };
    } else {
      this.options = {
        concurrency: 1,
        allowedContentTypes: [
          'image/jpeg',
          'image/png',
          'image/png',
          'image/bmp',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/msword',
          'text/plain',
          'application/vnd.ms-powerpoint',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          'application/pdf',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel',
          'text/csv',
        ],
      };
    }
    this.fileDateOptions = {
      dateFormat: 'dd/mm/yyyy',
      editableDateField: false,
      openSelectorOnInputClick: true,
      firstDayOfWeek: 'su',
      satHighlight: true,
      height: '32px',
      selectionTxtFontSize: '14px',
      todayBtnTxt: this.translate.instant('TODAY'),
      dayLabels: {
        su: this.translate.instant('SUN'),
        mo: this.translate.instant('MON'),
        tu: this.translate.instant('TUE'),
        we: this.translate.instant('WED'),
        th: this.translate.instant('THU'),
        fr: this.translate.instant('FRI'),
        sa: this.translate.instant('SAT'),
      },
      monthLabels: {
        1: this.translate.instant('JANUARY'),
        2: this.translate.instant('FEBRUARY'),
        3: this.translate.instant('MARCH'),
        4: this.translate.instant('APRIL'),
        5: this.translate.instant('MAY'),
        6: this.translate.instant('JUNE'),
        7: this.translate.instant('JULY'),
        8: this.translate.instant('AUGUST'),
        9: this.translate.instant('SEPTEMBER'),
        10: this.translate.instant('OCTOBER'),
        11: this.translate.instant('NOVEMBER'),
        12: this.translate.instant('DECEMBER'),
      },
    };

    if (this.config.showFoodSafetyFiler) {
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
          this.createdBy = [];
          this.createdBy.push({ label: val.SELECT, value: null });
          this.subscriptions.push(
            this.userService
              .getAssignedUsers(
                BaseServices.ApplicationId,
                BaseServices.BusinessId
              )
              .subscribe((result) => {
                if (result) {
                  result.forEach((element: any) => {
                    this.createdBy.push({
                      label: element.Name,
                      value: element.Name,
                    });
                  });
                }
              })
          );
        })
      );
    }
    this.workingModule = JSON.parse(sessionStorage.getItem('workingModule')!);
    if (this.workingModule.id == 20 || this.workingModule.id == 43) {
      sessionStorage.setItem('childrenModule', this.route.snapshot.url[0].path);
    }
    console.log(this.config);
    console.log(this.files);
  }

  onViewChange() {
    if (this.listView) {
      this.listView = false;
    } else {
      this.listView = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showLoader = true;
    let tempFolderList = [];
    tempFolderList = this.folderList;
    this.folderList = [];
    this.folderList = tempFolderList;
    this.selectedFolderId = null;
    this.query = null;
    if (
      changes['triggerUploader'] &&
      changes['triggerUploader'].currentValue === true
    ) {
      this.triggerUploader = false;
      let el: HTMLElement = this.uploadAction.nativeElement;
      el.click();
    }

    if (this.config.showFDVItems) {
      this.options = {
        concurrency: 1,
        allowedContentTypes: ['application/pdf'],
      };
    } else if (this.config.showProjectPhoto) {
      this.options = {
        concurrency: 1,
        allowedContentTypes: [
          'image/jpeg',
          'image/png',
          'image/png',
          'image/bmp',
        ],
      };
    } else if (
      this.config.documentType === 'article' ||
      this.config.documentType === 'checklist'
    ) {
      this.options = {
        concurrency: 1,
        allowedContentTypes: ['text/Article'],
      };
    } else {
      this.options = {
        concurrency: 1,
        allowedContentTypes: [
          'image/jpeg',
          'image/png',
          'image/png',
          'image/bmp',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/msword',
          'text/plain',
          'application/vnd.ms-powerpoint',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          'application/pdf',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel',
          'text/csv',
        ],
      };
    }
    this.cdr.detectChanges();
    this.selectedPageSize = 25;
    this.pgConfig.itemsPerPage = 25;
    this.showLoader = false;
  }

  // just for uploading data
  saveUpload(fileToSave: any) {
    this.saveChanges.emit(fileToSave);
  }

  downloadFile(fileToDownload: any) {
    window.open(fileToDownload);
  }

  removeExistingFile(node: any) {
    let deletemsg = '?';
    let alertMessage = this.translate.instant('ARE_SURE_WANT_TO_DELETE');
    if (node.isFolder) {
      let childCount =
        node.childCount > 0 ? ' (' + node.childCount + ')?' : '?';
      let hasNodeMsg = node.childCount > 0 ? ' It has sub nodes.' : '';
      deletemsg = childCount + hasNodeMsg;
    }
    if (!confirm(alertMessage + node.filename + deletemsg)) {
      let result: any = false;
      return result;
    }
    let eventData = {};

    if (node.isFolder) {
      eventData = {
        eventName: 'DeleteFolder',
        item: {
          data: node,
        },
      };
    } else if (!node.isFolder && node.documentType === 'article') {
      eventData = {
        eventName: 'DeleteArticle',
        item: {
          data: node,
        },
      };
    } else if (!node.isFolder && node.documentType === 'checklist') {
      eventData = {
        eventName: 'DeleteChecklist',
        item: {
          data: node,
        },
      };
    } else if (!node.isFolder && node.documentType === 'document') {
      eventData = {
        eventName: 'DeleteFile',
        item: {
          data: node,
        },
      };
    } else if (
      !node.isFolder &&
      node.documentType === 'manualfsundercompletion'
    ) {
      eventData = {
        eventName: 'DeleteFSundercompletionFile',
        item: {
          data: node,
        },
      };
    } else {
      eventData = {
        eventName: 'DeleteDefaultFile',
        item: {
          data: node,
        },
      };
    }
    this.saveChanges.emit(eventData);
  }
  /**
   * Retrieves the appropriate file icon for given document type.
   *
   * The function maintains a mapping of document types to file icons.
   * In case the provided document type is not in the icons mapping,
   * it defaults to a general file icon.
   *
   * @param {string} documentType - The type of the document for which the icon is needed.
   * @returns {string} Path to the file icon corresponding to the document type.
   */
  getFileIcon(documentType: string): string {
    const icons = {
      article: 'kb-folder-blue.png',
      laws: 'kb-folder-gray.png',
      regulation: 'kb-folder-gray.png',
      videos: 'kb-folder-green.png',
      fsundercompletion: 'kb-folder-red.png',
      undercompletion: 'kb-folder-red.png',
      checklist: 'kb-folder-red.png',
      // Assign a default icon when the document type is not recognized
      default: 'kb-folder.png',
    };

    let icon = icons[documentType];

    // If the document type doesn't have a specific icon, use the default icon
    if (!icon) {
      icon = icons.default;
    }

    return `/assets/file-icons/${icon}`;
  }

  jumpIntoFolder(id: any) {
    const parent = _.filter(this.uploadedFiles, ['id', id]);
    if (parent !== null && parent[0].nodes != null) {
      this.uploadedFiles = parent[0].nodes;
      this.config.title = parent[0].filename;

      this.activeFolder.emit(parent);
    }
    this.config.addtionalData = {
      ApplicationId: BaseServices.ApplicationId,
      FolderPath:
        'mid-' +
        this.appManualId +
        '-' +
        this.manualName.replace(' ', '-').toLowerCase(),
      FolderTypeName: parent[0].documentType,
      ManualId: +this.appManualId,
      UserId: BaseServices.UserId,
      ManualFolderId: parent[0].nodeId,
    };
  }

  // New folder and funs
  createFolder() {
    // For new folder
    this.newFolderNode = {
      filename: '',
    };

    this.bsModalTitle = 'New Folder';

    this.currentEventData = {
      eventName: 'AddNewFolder',
      item: {
        data: this.newFolderNode,
      },
    };
    this.bsModalRef = this.bsModalService.show(
      this.newFolderModal,
      this.bsConfig
    );
  }

  // New Article
  createArticle() {
    // open empty article viewer
    this.file = new ArticleModel();
    this.file.id = 0;
    this.file.nodeId = 0;
    this.file.filename = '';
    this.file.originalFileName = '';
    this.file.path = '';
    this.file.mimetype = '';
    this.file.size = 0;
    this.file.isFolder = false;
    this.file.type = 'file';
    this.file.isDefault = false;
    this.file.documentType = 'article';
    this.file.status = 1;
    this.file.createdBy = this.UserId;
    this.file.modifiedBy = this.UserId;
    if (this.businessId === 0 && this.portalId === 0) {
      this.file.content = {
        article: {
          id: 0,
          articleName: '',
          documentNumber: null,
          chapter: '',
          version: 1,
          editedBy: null,
          roleStatus: {
            admin: [
              { name: 'Own Text', status: 'Active', disable: 'false' },
              { name: 'Standard Text', status: 'Active', disable: 'false' },
              { name: 'Legislation', status: 'Active', disable: 'false' },
            ],
            portal: [
              { name: 'Own Text', status: 'Active', disable: 'false' },
              { name: 'Portal Text', status: 'Active', disable: 'false' },
              { name: 'Standard Text', status: 'Active', disable: 'false' },
              { name: 'Legislation', status: 'Active', disable: 'false' },
            ],
            editor: [
              { name: 'Own Text', status: 'Active', disable: 'false' },
              { name: 'Portal Text', status: 'Active', disable: 'false' },
              { name: 'Standard Text', status: 'Active', disable: 'false' },
              { name: 'Legislation', status: 'Active', disable: 'false' },
            ],
          },
        },
        subArticles: [
          {
            name: 'Own Text',
            text: '',
            version: 1,
            status: 'Active',
          },
          {
            name: 'Portal Text',
            text: '',
            version: 1,
            status: 'Active',
          },
          {
            name: 'Standard Text',
            text: '',
            version: 1,
            status: 'Active',
          },
          {
            name: 'Legislation',
            text: '',
            version: 1,
            status: 'Active',
          },
        ],
        revisionComments: null,
      };
    } else if (this.portalId !== 0 && this.businessId === 0) {
      this.file.content = {
        article: {
          id: 0,
          articleName: '',
          documentNumber: null,
          chapter: '',
          version: 1,
          roleStatus: {
            admin: [],
            portal: [
              { name: 'Own Text', status: 'Active', disable: 'false' },
              { name: 'Portal Text', status: 'Active', disable: 'false' },
            ],
            editor: [
              { name: 'Own Text', status: 'Active', disable: 'false' },
              { name: 'Portal Text', status: 'Active', disable: 'false' },
            ],
          },
        },
        subArticles: [
          {
            name: 'Own Text',
            text: '',
            version: 1,
            status: 'Active',
          },
          {
            name: 'Portal Text',
            text: '',
            version: 1,
            status: 'Active',
          },
        ],
        revisionComments: null,
      };
    } else if (this.businessId !== 0 && this.projectId !== 0) {
      this.file.content = {
        article: {
          id: 0,
          articleName: '',
          documentNumber: null,
          chapter: '',
          version: 1,
        },
        subArticles: [
          {
            name: 'Project Text',
            text: '',
            version: 1,
            status: 'Active',
          },
          {
            name: 'Own Text',
            text: '',
            version: 1,
            status: 'Active',
          },
          {
            name: 'Portal Text',
            text: '',
            version: 1,
            status: 'Active',
          },
        ],
        revisionComments: null,
      };
    } else if (this.portalId === 0 && this.businessId !== 0) {
      this.file.content = {
        article: {
          id: 0,
          articleName: '',
          documentNumber: null,
          chapter: '',
          version: 1,
          roleStatus: {
            admin: [],
            portal: [],
            editor: [{ name: 'Own Text', status: 'Active', disable: 'false' }],
          },
        },
        subArticles: [
          {
            name: 'Own Text',
            text: '',
            version: 1,
            status: 'Active',
          },
        ],
        revisionComments: null,
      };
    } else {
    }


    this.cdr.detectChanges();
    this.triggerFalseClick();
  }

  // New Article
  createCheckList() {
    // open empty checklist viewer
    this.file = {};
    this.file = new ChecklistModel();
    this.file.filename = '';
    this.file.originalFileName = '';
    this.file.path = '';
    this.file.mimetype = 'checklist';
    this.file.size = 0;
    this.file.isFolder = false;
    this.file.type = 'file';
    this.file.isDefault = false;
    this.file.documentType = 'checklist';
    this.file.status = 1;
    this.file.createdBy = BaseServices.UserId;
    this.file.createdOn = new Date();
    this.file.parentFolderId = 0;
    this.file.documentTypeId = 0;
    this.file.content = {
      checkList: {
        id: 0,
        title: '',
        scoreIndex: false,
        status: 1,
        isEditable: true,
        createdOn: new Date(),
        sections: [
          {
            id: 0,
            position: 1,
            title: '',
            status: 1,
            items: [
              {
                id: 0,
                position: 1,
                title: '',
                status: 1,
              },
            ],
          },
        ],
      },
    };
    this.cdr.detectChanges();
    this.triggerFalseClick();
  }

  addZero(versioNumber: any) {
    if (versioNumber >= 10) {
      return '';
    } else {
      return 0;
    }
  }

  /**
   *
   * @param businessMode
   * @param article
   */
  busines_version(
    businessMode: string,
    article: any,
    subArticles: any
  ): string {
    let languageId = parseInt(sessionStorage.getItem('languageId')!);
    let CultureInfo = 'en-GB';
    switch (languageId) {
      case 1:
        CultureInfo = 'en-GB';
        break;
      case 2:
        CultureInfo = 'no-NO';
        break;
      case 3:
        CultureInfo = 'sv-SE';
        break;
      case 4:
        CultureInfo = 'pl-PL';
        break;
    }
    let versions = new StringBuilder();
    versions.append('');
    if (businessMode === 'underPortal') {
      if (article.roleId === 1) {
        let findStandrdText = subArticles.find(function (element: any) {
          return element.name === 'Standard Text';
        });

        if (
          findStandrdText !== undefined &&
          findStandrdText.status.toLowerCase() !== 'inactive'
        ) {
          versions.append(
            'S :' + this.addZero(article.version) + article.version
          );
        }

        let findPortaltext = subArticles.find(function (element: any) {
          return element.name === 'Portal Text';
        });
        if (
          findPortaltext !== undefined &&
          findPortaltext.status.toLowerCase() !== 'inactive'
        ) {
          versions.append(
            ' ' +
            'P :' +
            this.addZero(findPortaltext.portalVersion) +
            findPortaltext.portalVersion
          );
        }
        let findOwntext = subArticles.find(function (element: any) {
          return element.name === 'Own Text';
        });
        this.businessEditedDate = findOwntext?.businessEditedDate ?? null;
        this.textName= findOwntext?.name ?? null;
        if (
          findOwntext !== undefined &&
          findOwntext.status.toLowerCase() !== 'inactive'
        ) {
          if (CultureInfo == 'en-GB') {
            versions.append(
              ' ' +
              'O :' +
              this.addZero(findOwntext.businessVersion) +
              findOwntext.businessVersion
            );
          } else {
            versions.append(
              ' ' +
              'E :' +
              this.addZero(findOwntext.businessVersion) +
              findOwntext.businessVersion
            );
          }
        }
      } else if (article.roleId === 2) {
        let findPortaltext = subArticles.find(function (element: any) {
          return element.name === 'Portal Text';
        });
        if (
          findPortaltext !== undefined &&
          findPortaltext.status.toLowerCase() !== 'inactive'
        ) {
          versions.append(
            'P :' + this.addZero(article.version) + article.version
          );
        }
        let findOwntext = subArticles.find(function (element: any) {
          return element.name === 'Own Text';
        });
        if (
          findOwntext !== undefined &&
          findOwntext.status.toLowerCase() !== 'inactive'
        ) {
          if (CultureInfo == 'en-GB') {
            versions.append(
              ' ' +
              'O :' +
              this.addZero(findOwntext.businessVersion) +
              findOwntext.businessVersion
            );
          } else {
            versions.append(
              ' ' +
              'E :' +
              this.addZero(findOwntext.businessVersion) +
              findOwntext.businessVersion
            );
          }
        }
      }
    } else {
      if (article.roleId === 1) {
        console.log('article.roleId === 1');
        versions.append(
          'S :' + this.addZero(article.version) + article.version
        );
        let findOwntext = subArticles.find(function (element: any) {
          return element.name === 'Own Text';
        });
        if (
          findOwntext !== undefined &&
          findOwntext.status.toLowerCase() !== 'inactive'
        ) {
          if (CultureInfo == 'en-GB') {
            versions.append(
              ' ' +
              'O :' +
              this.addZero(findOwntext.businessVersion) +
              findOwntext.businessVersion
            );
          } else {
            versions.append(
              ' ' +
              'E :' +
              this.addZero(findOwntext.businessVersion) +
              findOwntext.businessVersion
            );
          }
        }
      }
    }
    return versions;
  }

  /** */
  displayVersion(article: any, subArticles: any): string {
    let languageId = parseInt(sessionStorage.getItem('languageId')!);
    let CultureInfo = 'en-GB';
    switch (languageId) {
      case 1:
        CultureInfo = 'en-GB';
        break;
      case 2:
        CultureInfo = 'no-NO';
        break;
      case 3:
        CultureInfo = 'sv-SE';
        break;
      case 4:
        CultureInfo = 'pl-PL';
        break;
    }
    let versions = new StringBuilder();
    versions.append('');
    if (
      this.applicationId !== 0 &&
      this.portalId === 0 &&
      this.businessId === 0
    ) {
      versions.append('S :' + this.addZero(article.version) + article.version);
    } else if (
      this.applicationId !== 0 &&
      this.portalId !== 0 &&
      this.businessId === 0
    ) {
      if (article.roleId === 1 && article.portalId === 0) {
        let findPortalText = subArticles.find(function (element: any) {
          return element.name === 'Portal Text';
        });
        if (
          findPortalText !== undefined &&
          findPortalText.status.toLowerCase() !== 'inactive'
        ) {
          versions.append(
            'P :' +
            this.addZero(
              findPortalText.portalVersion !== null
                ? findPortalText.portalVersion
                : 1
            ) +
            (findPortalText.portalVersion !== null
              ? findPortalText.portalVersion
              : 1)
          );
        } else {
          versions.append('P :' + 0 + 1);
        }
        let findStandard = subArticles.find(function (element: any) {
          return element.name === 'Standard Text';
        });
        if (
          findStandard !== undefined &&
          findStandard.status.toLowerCase() !== 'inactive'
        ) {
          versions.append(
            ' ' +
            'S :' +
            this.addZero(findStandard.version) +
            findStandard.version
          );
        } else {
          versions.append(' ' + 'S :' + 0 + 1);
        }
      } else {
        versions.append(
          'P :' + this.addZero(article.version) + article.version
        );
      }
    } else if (
      this.applicationId !== 0 &&
      this.portalId === 0 &&
      this.businessId > 0
    ) {
      if (
        (article.roleId === 1 && article.businessId === 0) ||
        (article.roleId === 2 && article.businessId === 0)
      ) {
        versions.append(
          this.busines_version(this.businessMode, article, subArticles)
        );
      } else {
        if (CultureInfo == 'en-GB') {
          versions.append(
            'O : ' + this.addZero(article.version) + article.version
          );
        } else {
          versions.append(
            'E : ' + this.addZero(article.version) + article.version
          );
        }
      }
    } else if (this.projectId > 0) {
      versions.append(article.version);
    }
    return versions.toString();
  }
  showDocumentDailog() {
    this.saveChanges.emit('showDocumentDialog');
  }
  // This will emit all file save/update action
  saveEmitter(eventData: any) {
    switch (eventData.eventName) {
      case 'AddNewFolder':
        if (this.newFolderNode.filename !== '') {
          this.newFolderNode.type = 'folder';
          (this.newFolderNode.documentType = eventData.item.data.documentType),
            (this.newFolderNode.isDefault = false);
          this.newFolderNode.isFolder = true;
          this.newFolderNode.status = 1;

          eventData.newNode = { ...this.newFolderNode };
          this.bsModalRef.hide();
          this.saveChanges.emit(eventData);
        }
        break;
      case 'SaveArticle':
        this.saveChanges.emit(eventData);

        break;
      case 'NewArticle':
        this.saveChanges.emit(eventData);
        break;
      case 'NewChecklist':
        this.saveChanges.emit(eventData);

        break;
      case 'CopyChecklist':
        this.saveChanges.emit(eventData);

        break;
      case 'SaveChecklist':
        this.saveChanges.emit(eventData);
        break;
      case 'CopyOfficeDocument':
        this.saveChanges.emit(eventData);
        break;
      case 'ShowOfficeFileSaveloader':
        this.saveChanges.emit(eventData);
        break;
      case 'CopyEditVersionDocument':
        this.saveChanges.emit(eventData);
        break;
      default:
        break;
    }
  }
  savePrivateDocs(eventData: any, f: any) {
    this.saveChanges.emit(eventData);
    if (eventData.eventName == 'CopyEditVersionDocument') {
      this.subscriptions.push(
        this.employeePrivateDocumentServices
          .getbyId(f.id)
          .subscribe((result) => {
            if (result) {
              if (f.createdById == this.pdUserId) {
                this.employeePrivateDocumentServices
                  .updateDoc(
                    f.id,
                    this.config.pdEmpId,
                    f.IsDocumentedTraining ? 2 : 1
                  )
                  .subscribe((result) => {
                    if (result) {
                    }
                  });
              }
            }
          })
      );
    }
  }

  //#region ngx-uploader funs

  genUuid() {
    this.uuid = uuidv4();
    return this.uuid;
  }

  onUploadOutput(output: UploadOutput): void {
    if (this.config.editMode) {
      //To prevent drag and drop of file in non edit mode
      // Copy Drag and drop documents in edit mode
      let session = JSON.parse(sessionStorage.getItem('session'));
      let token = session.AccessToken;
      const headers = {
        Authorization: 'Bearer ' + token, // Add JWT token to headers
        // Add any other headers if needed
      };
      if (this.config.editMode) {
        if (output.type === 'allAddedToQueue') {
          // when all files added in queue
          // comment this if you want to manual upload files
          const event: UploadInput = {
            type: 'uploadAll',
            url: this.config.uploaderUri,
            method: 'POST',
            headers: headers,
            data: this.config.addtionalData,
          };
          if (event.data) {
            this.uploadInput.emit(event);
          } else {
            this.files = [];
            alert(this.translate.instant('SELECT_FOLDER_TO_DROP_FILE'));
          }
        } else if (
          output.type === 'addedToQueue' &&
          typeof output.file !== 'undefined'
        ) {
          if (output.file) {
            // add file to array when added
            this.files.push(output.file);
          }
        } else if (
          output.type === 'uploading' &&
          typeof output.file !== 'undefined'
        ) {
          // update current data in files array for uploading file
          const index = this.files.findIndex(
            (file) =>
              typeof output.file !== 'undefined' && file.id === output.file.id
          );

          if (output.file) {
            // add file to array when added
            this.files[index] = output.file;
          }
        } else if (output.type === 'removed') {
          // remove file from array when removed
          this.files = this.files.filter(
            (file: UploadFile) => file !== output.file
          );
        } else if (output.type === 'dragOver') {
          this.dragOver = true;
        } else if (output.type === 'dragOut') {
          this.dragOver = false;
        } else if (output.type === 'drop') {
          this.dragOver = false;
        } else if (
          output.type === 'rejected' &&
          typeof output.file !== 'undefined'
        ) {
        } else if (output.type === 'done') {
          //
          //

          if (typeof this.uploadedFiles === 'undefined') {
            this.uploadedFiles = [];
          }
          this.removeFromFiles(this.files, output.file);
          this.uuid = uuidv4();
          if (output.file!.response === '') {
            alert(
              output.file!.name +
              ' ' +
              this.translate.instant('EXCEEDS_MAX_FILE_LIMITS')
            );
          } else {
            output.file!.response.Id = this.uuid
              .replace('-', '0')
              .substring(1, 20);
            this.uploadedFiles.push(output.file.response);
            this.uploadedFiles.sort((a, b) =>
              a.filename.localeCompare(b.filename)
            );
            delete output.file.response.Id;
            this.saveUpload(output.file.response);
          }
        }
      }
      // Preventing drag and drop documents without edit mode
      else {
        this.removeAllFiles();
      }
    } else {
      //To prevent drag and drop of file in non edit mode
      this.uploadInput.emit({ type: 'removeAll' });
    }
  }

  // if you need start upload when event triiger
  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: this.config.uploaderUri,
      method: 'POST',
      data: this.config.addtionalData,
    };
    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: '{id}' });
  }

  removeFile(id: string) {
    this.saveChanges.emit({ type: 'remove', id: `${id}` });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  fileExtension(filename: string) {
    return filename.split('.').pop();
  }

  removeFromFiles(files: any, obj: any): void {
    const index = files.indexOf(obj);
    files.splice(index, 1);
  }

  humanizeType(mimetype: any) {
    let type = '';

    switch (mimetype) {
      case 'xlsx':
      case 'xls':
      case 'csv':
      case 'docx':
      case 'doc':
      case 'rtf':
      case 'ppt':
      case 'pptx':
      case 'csv':
        type = 'Document';
        break;
      case 'png':
      case 'jpg':
      case 'JPG':
      case 'jpeg':
      case 'bmp':
      case 'gif':
      case 'tiff':
        type = 'Image';
        break;
      case 'mp3':
      case 'wav':
        type = 'Audio';
        break;
      case 'avi':
      case 'mp4':
      case '3gpp':
      case 'webm':
      case 'flv':
      case 'mov':
        type = 'Video';
        break;
      case 'txt':
        type = 'Text';
        break;
      case 'PDF':
      case 'pdf':
        type = 'Pdf';
        break;
      case 'zip':
      case 'rar':
      case '7z':
        type = 'Archive file';
        break;
      default:
        type = 'Article';
        break;
    }

    return type;
  }

  //#endregion

  // private funs
  getFileExtension(filename: any) {
    let fileExtension = null;
    if (filename != null) {
      fileExtension = filename.substr(filename.lastIndexOf('.') + 1);
    }
    return fileExtension;
  }

  triggerFalseClick() {
    let el: HTMLElement = this.hiddenAction.nativeElement.querySelector('a');
    el.click();
  }

  // Checkbox selection
  SelectAllDocuments() {
    if (this.config.title == 'PRIVATE_DOCUMENTS') {
      for (let i = 0; i < this.uploadedFiles.length; i++) {
        if (
          (this.isEditor &&
            this.uploadedFiles[i].createdById == this.pdUserId) ||
          (this.isUser && this.uploadedFiles[i].createdById != this.pdUserId)
        ) {
          if (
            this.uploadedFiles[i].approvedOn != null &&
            this.userId == this.pdUserId
          )
            this.uploadedFiles[i].selected = false;
          else this.uploadedFiles[i].selected = this.selectedAll;
        }
      }
    } else {
      for (let i = 0; i < this.uploadedFiles.length; i++) {
        if (
          this.uploadedFiles[i].approvedOn != null &&
          this.userId == this.pdUserId
        )
          this.uploadedFiles[i].selected = false;
        else if (this.uploadedFiles[i].approvedOn != null)
          this.uploadedFiles[i].selected = false;
        else this.uploadedFiles[i].selected = this.selectedAll;
      }
    }
  }
  checkIfAllSelected() {
    this.selectedAll = this.uploadedFiles.every(function (item: any) {
      return item.selected === true;
    });
  }

  // Article Bulk Print
  ArticleBulkPrintPDF(selectedVal: any) {
    this.appManualId = +this.route.snapshot.params['mid'];
    let selectedArticles: any = [];
    for (let i = 0; i < selectedVal.length; i++) {
      let selectStatus = selectedVal[i].selected;

      if (
        selectStatus !== undefined &&
        selectStatus === true &&
        selectedVal[i].documentType === 'article'
      ) {
        if (selectedVal[i].content !== undefined) {
          selectedVal[i].content.article.articleDate = null;
        }
        let selectedContent: any = {};
        selectedContent = _.clone(selectedVal[i].content);
        selectedArticles.push(
          HelperService.switchKeysCase(selectedContent, null, 'U')
        );
      }
    }
    if (selectedArticles.length === 0) {
      alert(this.translate.instant('PLEASE_SELECT_ANY_ARTICLE'));
    } else {
      this.showLoader = true;
      let type = 'pdf';
      let appSettings = JSON.parse(sessionStorage.getItem('appSettings')!);
      let languageId = Number(sessionStorage.getItem('languageId'));
      let CultureInfo = 'en-GB';
      switch (languageId) {
        case 1:
          CultureInfo = 'en-GB';
          break;
        case 2:
          CultureInfo = 'no-NO';
          break;
        case 3:
          CultureInfo = 'sv-SE';
          break;
        case 4:
          CultureInfo = 'pl-PL';
          break;
      }
      this.subscriptions.push(
        this.bulkReportService
          .generateBulkArticleReport(
            selectedArticles,
            BaseServices.BusinessId,
            CultureInfo,
            this.portalId,
            this.projectId,
            this.appManualId
          )
          .subscribe(
            (blob: any) => {
              this.showLoader = false;
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = `${this.fileName}.${type.toLowerCase()}`;
              link.click();
            },
            (error: any) => {
              alert('Export not downloaded');
            }
          )
      );
    }
  }

  // Article Bulk Print
  ArticleIndexPrintPDF(selectedVal: any) {
    let selectedArticles: any = [];
    for (let i = 0; i < selectedVal.length; i++) {
      let selectStatus = selectedVal[i].selected;

      if (
        selectStatus !== undefined &&
        selectStatus === true &&
        selectedVal[i].documentType === 'article'
      ) {
        if (selectedVal[i].content !== undefined) {
          selectedVal[i].content.article.articleDate = null;
        }
        let selectedContent: any = {};
        selectedContent = _.clone(selectedVal[i].content);
        selectedArticles.push(
          HelperService.switchKeysCase(selectedContent, null, 'U')
        );
      }
    }
    if (selectedArticles.length === 0) {
      alert(this.translate.instant('PLEASE_SELECT_ANY_ARTICLE'));
    } else {
      this.showLoader = true;
      let type = 'pdf';
      let appSettings = JSON.parse(sessionStorage.getItem('appSettings')!);
      let languageId = appSettings.Language.LanguageId;
      let CultureInfo = 'en-GB';
      switch (languageId) {
        case 1:
          CultureInfo = 'en-GB';
          break;
        case 2:
          CultureInfo = 'no-NO';
          break;
        case 3:
          CultureInfo = 'sv-SE';
          break;
        case 4:
          CultureInfo = 'pl-PL';
          break;
      }
      this.subscriptions.push(
        this.bulkReportService
          .generateArticleIndex(
            selectedArticles,
            BaseServices.BusinessId,
            CultureInfo,
            this.portalId,
            this.projectId,
            this.appManualId
          )
          .subscribe(
            (blob: any) => {
              this.showLoader = false;
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = `${this.fileName}.${type.toLowerCase()}`;
              link.click();
            },
            (error: any) => {
              alert('Export not downloaded');
            }
          )
      );
    }
  }
  // Documents download as zip
  DocumentsDownloadAll(selectedList: any) {
    this.showLoader = true;
    let selectedDoc: any = [];
    let BusinessId = BaseServices.BusinessId;
    let appSettings = JSON.parse(sessionStorage.getItem('appSettings')!);
    let languageId = Number(sessionStorage.getItem('languageId'));
    let CultureInfo = 'en-GB';
    switch (languageId) {
      case 1:
        CultureInfo = 'en-GB';
        break;
      case 2:
        CultureInfo = 'no-NO';
        break;
      case 3:
        CultureInfo = 'sv-SE';
        break;
      case 4:
        CultureInfo = 'pl-PL';
        break;
    }
    for (let i = 0; i < selectedList.length; i++) {
      let selectStatus = selectedList[i].selected;
      if (selectStatus !== undefined && selectStatus === true) {
        let requestObject = {
          FilePath: selectedList[i].path,
          FileName: selectedList[i].filename,
          FileType: selectedList[i].documentType.toLowerCase(),
          FileContent:
            selectedList[i].documentType === 'checklist'
              ? selectedList[i].content.checkList
              : selectedList[i].content,
          BusinessId: BusinessId,
          CultureInfo: CultureInfo,
        };
        selectedDoc.push(
          HelperService.switchKeysCase(requestObject, null, 'U')
        );
      }
    }
    selectedDoc.forEach((element: any) => {
      if (element.FileContent != null)
        if (element.FileContent.article != null) {
          element.FileContent.article.articleDate = null;
        }
    });
    if (selectedDoc.length === 0) {
      alert(this.translate.instant('NO_FILE_SELECTED'));
      this.showLoader = false;
    } else {
      let reportname = 'Download all.zip';
      this.subscriptions.push(
        this.bulkReportService
          .downloadAllAsZip(selectedDoc, 1, this.portalId, this.projectId)
          .subscribe(
            (blob: any) => {
              this.showLoader = false;
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = `${reportname}`;
              link.click();
            },
            (error: any) => {
              this.showLoader = false;
              alert('Please try again');
            }
          )
      );
    }
  }

  // Documents download as zip
  approveDocument(selectedVal: any) {
    let artIdList = [];
    let selectedArticles: any = [];

    for (let i = 0; i < selectedVal.length; i++) {
      let selectStatus = selectedVal[i].selected;

      if (
        selectStatus !== undefined &&
        selectStatus === true &&
        selectedVal[i].documentType === 'article'
      ) {
        if (selectedVal[i].content !== undefined) {
          selectedVal[i].content.article.articleDate = null;
        }
        let selectedContent: any = {};
        selectedContent = _.clone(selectedVal[i].content);
        artIdList.push(+selectedContent.article.id);
        selectedArticles.push(
          HelperService.switchKeysCase(selectedContent, null, 'U')
        );
      }
    }

    if (selectedArticles.length === 0) {
      alert(this.translate.instant('NO_FILE_SELECTED'));
      this.showLoader = false;
    } else {
      let aData: ArticleApproveDetails = {
        ApplicationId: +BaseServices.ApplicationId,
        BusinessId: this.businessId,
        ManualId: this.appManualId,
        UserId: +BaseServices.UserId,
        ArticleIdList: artIdList,
      };
      let eventData = {
        eventName: 'approveArticle',
        item: {
          data: aData,
        },
      };
      this.saveChanges.emit(eventData);
    }
  }

  copyDocLink(node: any) {
    this.copyDocForm = this._fb.group({
      filePath: node.path,
    });
    this.showCopyLinkDailog = true;
  }

  copyDocumnetUrl() {
    this.showCopyLinkDailog = false;
  }

  printChecklist(node: any) {
    let type = 'pdf';
    let appSettings = JSON.parse(sessionStorage.getItem('appSettings')!);
    let languageId = parseInt(sessionStorage.getItem('languageId'));
    let CultureInfo = 'en-GB';
    switch (languageId) {
      case 1:
        CultureInfo = 'en-GB';
        break;
      case 2:
        CultureInfo = 'no-NO';
        break;
      case 3:
        CultureInfo = 'sv-SE';
        break;
      case 4:
        CultureInfo = 'pl-PL';
        break;
    }
    if (node.featureId == FeatureKey.ELECTRO) {
      let contractId = this.route.snapshot.parent!.params['cid'];
      let cklOrFormType =
        node.documentType == 'installationform' ||
          node.documentType == 'undercompletioninstallationform'
          ? node.installationFormType
          : 0;
      this.subscriptions.push(
        this.electroDocumentServices
          .inspectionCklOrFormReport(cklOrFormType, node.nodeId, contractId)
          .subscribe(
            (blob: any) => {
              this.showLoader = false;
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = node.filename;
              link.click();
            },
            (error: any) => {
              this.showLoader = false;
              alert('Export not downloaded');
            }
          )
      );
    } else {
      this.subscriptions.push(
        this.bulkReportService
          .generateChecklistReport(
            HelperService.switchKeysCase(node.content.checkList, null, 'U'),
            BaseServices.BusinessId,
            CultureInfo,
            this.projectId
          )
          .subscribe(
            (blob: any) => {
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = `${node.filename}.${type.toLowerCase()}`;
              link.click();
            },
            (error: any) => {
              alert('Export not downloaded');
            }
          )
      );
    }
  }
  onStartDateChanged(event: IMyDateModel) {
    this.startDate = event.formatted;
    this.DateFilterValidation();
  }
  onEndDateChanged(event: IMyDateModel) {
    this.endDate = event.formatted;
    this.DateFilterValidation();
  }
  DateFilterValidation() {
    if (this.startDate && this.endDate) {
      let startDateValueSplit = this.startDate.split('/');
      let endDateValueSplit = this.endDate.split('/');
      let startDateValue =
        startDateValueSplit[1] +
        '/' +
        startDateValueSplit[0] +
        '/' +
        startDateValueSplit[2];
      let endDateValue =
        endDateValueSplit[1] +
        '/' +
        endDateValueSplit[0] +
        '/' +
        endDateValueSplit[2];
      let startDate = new Date(startDateValue).getTime();
      let endDate = new Date(endDateValue).getTime();
      if (startDate > endDate) {
        alert('End date should be greater than start date.');
        this.endDate = null!;
        this.startDate = null!;
      }
    }
  }

  onPageChanged(e: any) {
    if (this.selectedPageSize === 0) {
      this.pgConfig.itemsPerPage = this.uploadedFiles.length;
      this.pgConfig.currentPage = 1;
    } else {
      this.pgConfig.itemsPerPage = this.selectedPageSize;
    }
  }

  validateNewFolder() {
    if (this.newFolderNode.filename.trim() === '') {
      return true;
    } else {
      return false;
    }
  }

  onFolderSelectionChanges(event: any) {
    let value = event.value;
    event.value = null;
    this.filterFolderEvents.emit(this.selectedFolderId);
  }

  onDeleteSelectedFiles() {
    if (!confirm(this.translate.instant('ARE_SURE_WANT_TO_DELETE') + '?')) {
      let result: any;
      return result;
    }
    let eventData = {};
    let selectedEventName = '';
    let selectedData = this.uploadedFiles.filter(
      (x: any) =>
        x.selected === true && x.isDefault === false && x.isEditable === true
    );
    if (selectedData.length > 0) {
      if (this.config.documentType !== 'allFiles') {
        if (selectedData[0].documentType === 'undercompletion') {
          selectedEventName = 'DeleteSelectedUnderCompletionChecklist';
        } else if (selectedData[0].documentType === 'manualfsundercompletion') {
          selectedEventName = 'DeleteSelectedFSUnderCompletionChecklist';
        } else {
          switch (this.config.documentType) {
            case 'article':
              selectedEventName = 'DeleteSelectedArticle';
              break;
            case 'checklist':
              selectedEventName = 'DeleteSelectedChecklist';
              break;
            case 'document':
            case 'fscompleted':
              selectedEventName = 'DeleteSelectedDocuments';
              break;
            case 'archiveundercompletionfolder':
              selectedEventName = 'DeleteArchiveSelectedDocuments';
              break;
          }
        }
        eventData = {
          eventName: selectedEventName,
          item: {
            data: selectedData,
          },
        };
        this.saveChanges.emit(eventData);
      } else {
        //KW-912 - Under All Files - Deletion of all files together is not working - Fix

        let documentTypeList = _.groupBy(selectedData, 'documentType');

        let eventDataList: any = [];
        _.forEach(documentTypeList, function (value, key) {
          let eventData = {};
          let selectedEventName = '';
          switch (key) {
            case 'article':
              selectedEventName = 'DeleteSelectedArticle';
              break;
            case 'checklist':
              selectedEventName = 'DeleteSelectedChecklist';
              break;
            case 'document':
            case 'fscompleted':
              selectedEventName = 'DeleteSelectedDocuments';
              break;
            case 'archiveundercompletionfolder':
              selectedEventName = 'DeleteArchiveSelectedDocuments';
              break;
            case 'undercompletion':
              selectedEventName = 'DeleteSelectedUnderCompletionChecklist';
              break;
            case 'manualfsundercompletion':
              selectedEventName = 'DeleteSelectedFSUnderCompletionChecklist';
              break;
          }

          eventData = {
            eventName: selectedEventName,
            item: {
              data: documentTypeList[key],
            },
          };

          eventDataList.push(eventData);
        });

        eventData = {
          eventName: 'DeleteAllFiles',
          item: {
            data: eventDataList,
          },
        };
        this.saveChanges.emit(eventData);
      }
    } else {
      alert(this.translate.instant('SELECT_FILE_DELETE'));
    }
  }

  toggleEditMode() {
    this.toogleEditDisplayMode.emit(!this.config.editMode);
  }

  openChecklistOrForm(file: any) {
    this.openChecklistOrFormEmit.emit(file);
  }

  openVideo(file: any) {
    this.openVideoPopup = true;
    this.isVideos = true;
    let fileExtension = null;
    if (file.path != null) {
      if (file.path.indexOf('=') + 1 > 5)
        fileExtension = file.path.substring(
          file.path.indexOf('=') + 1,
          file.path.indexOf('=') + 12
        );
    }
    if (fileExtension == null)
      fileExtension = file.path.substring(
        file.path.lastIndexOf('/') + 1,
        file.path.lastIndexOf('/') + 12
      );
    if (file.filename != null) {
      this.VideoTitle = file.filename;
    }
    this.videoLink = this._sanitizer.bypassSecurityTrustResourceUrl(
      'https://www.youtube.com/embed/' + fileExtension
    );
  }

  cancel() {
    this.isVideos = false;
    this.openVideoPopup = false;
  }

  closePopUp() {
    this.openVideoPopup = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}

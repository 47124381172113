import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ConfirmationService } from 'primeng/api';
import { BusinessPortalService } from '../services/business-portal.services';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Table } from 'primeng/table';

@Component({
    selector: 'portal-list',
    templateUrl: 'portal-list.component.html'
})

export class PortalListComponent implements OnInit {

    //#region variables
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @ViewChild('pt', { static: true }) pt: Table;
    portals: any;
    list: any = [];
    showDialog = false;
    statuses: SelectItem[];
    selectedValue: any;
    cols: any[];
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor
    /**
     * Constructor
     * @param portalservice {BusinessPortalService}
     * @param router {Router}
     * @param route {ActivatedRoute}
     * @param confirmationService {ConfirmationService}
     */
    constructor(
        private portalservice: BusinessPortalService,
        private router: Router,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private translate: TranslateService
    ) {
        this.portals = this.route.snapshot.data['list'];
    }
    //#endregion

    //#region page-events
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.subscriptions.push(this.translate.stream('SELECT_STATUS_DROP').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
                { label: val.SELECT, value: null },
                { label: val.INACTIVE, value: 2 },
                { label: val.ACTIVE, value: 1 }
            );
        }));
        this.cols = [
            { field: 'Title', header: 'Title', width: '50px',pipe:null },
            { field: 'Users', header: 'Users', width: '50px',pipe:null },
            { field: 'Businesses', header: 'Businesses', width: '150px'},
            { field: 'PortalLogo', header: 'Image', width: '100px'},
          ];
    }
    //#endregion

    //#region control-events
    /**
     * Referesh the portal list
     */
    fetchPortalData() {
        this.subscriptions.push(this.portalservice.getAll().subscribe(res => {
            this.portals = res;
        }))
    }

    onHide() {
        this.showDialog = false;
        if (this.pt ) {
            this.pt.first = 0; // Set the current page to the first page
            this.pt.reset();   // Reset filters and sorting
        }
    }

    showPortalInfo(portalInfo: any[]) {
        this.showDialog = true;
        this.list = [];
        this.list = portalInfo;
    }

    /**
    * status change dropdown event
    * @param e
    * @param statusDropDown
    */
    onStatusChanged(e: any) {
        this.portals = [];
        let copyPortalList = _.clone(this.route.snapshot.data['list']);
        if (e.value === 2) {
            this.portals = copyPortalList.filter((x:any) => x.Status === 2);
        } else if (e.value === 1) {
            this.portals = copyPortalList.filter((x:any) => x.Status === 1);
        } else if (e.value == null) {
            this.portals = this.route.snapshot.data['list'];
        }
    }

    /**
  * edit status
  * @param Id
  * @param Status
  */
    editStatus(Id: any, Status: string) {
        let currentStatus = (Status === 'ACTIVE' ? 1 : 2);
        this.confirmationService.confirm({
            message: 'Are you sure that you want to change the status?',
            accept: () => {

            }
        });
    }

    /**
     * delete the portal
     * @param portalId {number}
     */
    confirm(portalId: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.portalservice.deletePortal(portalId).subscribe(res => {
                    this.fetchPortalData();
                    this.toasterComponent.callToastDlt();
                }));
            }
        });
    }
    
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion

}

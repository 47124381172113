import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { NotificationEdit } from '../models/notificationEdit';
import { BaseServices } from './../../kuba.services';

@Injectable()
export class NotificationServices {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http
      .get<any>(
        environment.BASE_URL + '/notifications',

      )
      .map((result) => result);
  }

  getById(notificationId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/notification/' + notificationId,

      )
      .map((result) => result);
  }

  add(notification: NotificationEdit) {
    return this.http
      .post(
        environment.BASE_URL + '/notification',
        notification,

      )
      .map((result) => result);
  }

  update(notification: NotificationEdit) {
    return this.http
      .put(
        environment.BASE_URL + '/notification/' + notification.Id,
        notification,

      )
      .map((result) => result);
  }

  deleteNotification(id) {
    let result: boolean;
    return this.http
      .delete(
        environment.BASE_URL + '/notificationDelete/' + id,

      )
      .toPromise()
      .then((result) => result);
  }

  getLoginUserNotifications() {
    return this.http
      .get<any>(
        environment.BASE_URL + '/notifications/list',

      )
      .map((result) => result);
  }

  getAllUserNotifications() {
    return this.http
      .get(
        environment.BASE_URL + '/notifications/users',

      )
      .map((result) => result);
  }
}

<div class="page-title">
  <span>
    <a
      *ngIf="!isFS"
      [routerLink]="['./../../../../list/own']"
      class="btn btn-icon"
    >
      <span class="icon ic-xs icon-back"></span>
    </a>
    <span *ngIf="isIC" translate>IC_SJA</span>
    <span *ngIf="!isIC" translate>SAFETY_JOB_ANALYSIS</span>
  </span>
  <span class="page-actions">
    <a [routerLink]="['./../new']" class="btn btn-success" *ngIf="isGuest">
      <span class="icon ic-sm icon-add"></span>
      <span>
        <span translate>NEW</span>
      </span>
    </a>
  </span>
</div>
<div class="table-view mbtm-30">
  <div *ngIf="!isShowAll">
    <!-- Table starts -->
    <p-table
      #sjaTable
      [value]="safetyDetails"
      dataKey="Id"
      [(selection)]="selectedSJA"
      expandableRows="true"
      [rows]="10"
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
    >
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 55px">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th style="width: 55px"></th>
          <th>{{ "TOPICS" | translate }}</th>
          <th>{{ "TYPE_OF_ISSUE" | translate }}</th>
          <th pSortableColumn="Location" [hidden]="isLocation">
            {{ "LOCATION" | translate }}
            <p-sortIcon field="Location"></p-sortIcon>
          </th>
          <th pSortableColumn="Description" [hidden]="isDescription">
            {{ "DESCRIPTION" | translate }}
            <p-sortIcon field="Description"></p-sortIcon>
          </th>
          <th pSortableColumn="RelatedIncident" [hidden]="isWhatcanHappen">
            {{ "WHAT_CAN_HAPPEN" | translate }}
            <p-sortIcon field="RelatedIncident"></p-sortIcon>
          </th>
          <th width="120px">{{ "RISK_FIGURE" | translate }}</th>

          <th pSortableColumn="CreatedOn" width="110px">
            {{ "CREATED_ON" | translate }}
            <p-sortIcon field="CreatedOn"></p-sortIcon>
          </th>
          <th pSortableColumn="EditedBy" width="200px">
            {{ "EDITED_BY" | translate }}
            <p-sortIcon field="EditedBy"></p-sortIcon>
          </th>
          <th
            pSortableColumn="ApprovalStatus"
            [hidden]="isFoodSafety"
            style="width: 180px"
          >
            {{ "APPROVAL_NOTICE" | translate }}
            <p-sortIcon field="ApprovalStatus"></p-sortIcon>
          </th>
          <th *ngIf="hideButton" [hidden]="!isGuest" width="40px"></th>
        </tr>
        <tr>
          <th></th>
          <th style="width: 55px"></th>
          <th>
            <p-dropdown
              [options]="Topics"
              (onChange)="onTopicChanged($event)"
              styleClass="p-column-filter"
              placeholder="Select"
              [showClear]="true"
              appendTo="body"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th>
            <p-dropdown
              [options]="issues"
              (onChange)="onIssueTypeChanged($event)"
              appendTo="body"
              styleClass="p-column-filter"
              placeholder="Select"
              [showClear]="true"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th [hidden]="isLocation"></th>
          <th [hidden]="isDescription">
            <input
              pInputText
              type="text"
              (input)="
                sjaTable.filter(
                  $any($event.target)?.value,
                  'Location',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th [hidden]="isWhatcanHappen">
            <input
              pInputText
              type="text"
              (input)="
                sjaTable.filter(
                  $any($event.target)?.value,
                  'RelatedIncident',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th>
            <p-dropdown
              [options]="riskFigures"
              (onChange)="
                sjaTable.filter(
                  $event.value,
                  'RiskFigureData.ValueText',
                  'equals'
                )
              "
              appendTo="body"
              styleClass="p-column-filter"
              placeholder="Select"
              [showClear]="true"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th></th>

          <th></th>
          <th [hidden]="isFoodSafety"></th>
          <th *ngIf="hideButton" [hidden]="!isGuest"></th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template
        pTemplate="body"
        let-safetyjob
        let-expanded="expanded"
        let-i="rowIndex"
      >
        <tr>
          <td style="width: 55px">
            <p-tableCheckbox id="selectedOrders" [value]="safetyjob">
            </p-tableCheckbox>
          </td>
          <td>
            <a
              (click)="sjaTable.toggleRow(safetyjob)"
              *ngIf="safetyjob.TaskPlanListDto.length > 0"
            >
              <i
                class="pi pi-chevron-circle-right"
                aria-hidden="true"
                *ngIf="setCollapse(i)"
                (click)="toggleClick(i)"
              ></i>
              <i
                class="pi pi-chevron-circle-down"
                aria-hidden="true"
                *ngIf="!setCollapse(i)"
                (click)="toggleClickHide(i)"
              ></i>
            </a>
          </td>
          <td>
            <a [routerLink]="['./../edit', safetyjob['Id']]">{{
              safetyjob.Topics
            }}</a>
          </td>
          <td>
            <span>{{ safetyjob.TypeofIssue }}</span>
          </td>
          <td [hidden]="isLocation">
            <span>{{ safetyjob.Location }}</span>
          </td>
          <td [hidden]="isDescription">
            <span>{{ safetyjob.Description }}</span>
          </td>
          <td [hidden]="isWhatcanHappen">
            <div
              pTooltip="{{ safetyjob.RelatedIncident }}"
              tooltipPosition="bottom"
            >
              {{ safetyjob.RelatedIncident }}
            </div>
          </td>
          <td>
            <span
              class="badge"
              translate
              [style.background-color]="safetyjob.RiskFigureData.BackColor"
              >{{ safetyjob.RiskFigureData.ValueText }}
              <span>{{ safetyjob.RiskFigureData.Value }}</span>
            </span>
          </td>
          <td>
            <span>{{ safetyjob.CreatedOn | date : "dd/MM/yyyy" }}</span>
          </td>
          <td>
            <span>{{ safetyjob.EditedBy }}</span>
          </td>
          <td [hidden]="isFoodSafety">
            <span
              [style.color]="
                safetyjob.ApprovalStatusColor
                  ? safetyjob.ApprovalStatusColor
                  : 'black'
              "
              >{{ safetyjob.ApprovalStatus.toUpperCase() | translate }}</span
            >
          </td>
          <td class="col-button" *ngIf="hideButton" [hidden]="!isGuest">
            <a
              (click)="confirm(safetyjob.Id)"
              class="btn btn-icon"
              title="{{ 'DELETE' | translate }}"
              routerLinkActive="active"
            >
              <i class="icon ic-sm icon-trash"></i>
            </a>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-activityList>
        <tr>
          <td colspan="11">
            <div>
              <!-- Table starts -->
              <p-table [value]="activityList.TaskPlanListDto" [rows]="5">
                <!-- Header -->
                <ng-template pTemplate="header">
                  <tr>
                    <th width="220px">{{ "ACTION_NAME" | translate }}</th>
                    <th width="110px">{{ "DEADLINE" | translate }}</th>
                    <th>{{ "DESCRIPTION" | translate }}</th>
                    <th width="220px">
                      {{ "RESPONSIBLE_PERSON" | translate }}
                    </th>
                    <th width="220px">{{ "FOLLOWUP_PERSON" | translate }}</th>
                  </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-safetyjob>
                  <tr>
                    <td>
                      <span>{{ safetyjob.Title }}</span>
                    </td>
                    <td>
                      <span>{{
                        safetyjob.Deadline | date : "dd/MM/yyyy"
                      }}</span>
                    </td>
                    <td>
                      <span>{{ safetyjob.Description }}</span>
                    </td>
                    <td>
                      <span>{{ safetyjob.ResponsiblePerson }}</span>
                    </td>
                    <td>
                      <span>{{ safetyjob.FollowupPerson }}</span>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              <!-- Table ends -->
            </div>
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="9">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>
  <div *ngIf="isShowAll">
    <!-- Table starts -->
    <p-table
      #sjaTable
      [value]="safetyDetails"
      dataKey="Id"
      scrollable="true"
      scrollHeight="200px"
      [(selection)]="selectedSJA"
    >
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 55px">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th></th>
          <th>{{ "TOPICS" | translate }}</th>
          <th>{{ "TYPE_OF_ISSUE" | translate }}</th>
          <th>{{ "LOCATION" | translate }}</th>
          <th>{{ "DESCRIPTION" | translate }}</th>
          <th>{{ "RISK_FIGURE" | translate }}</th>
          <th>{{ "CREATED_ON" | translate }}</th>
          <th>{{ "EDITED_BY" | translate }}</th>
          <th>{{ "APPROVAL_NOTICE" | translate }}</th>
          <th *ngIf="hideButton" [hidden]="true"></th>
        </tr>
      </ng-template>

      <!-- Body Content -->
      <ng-template
        pTemplate="body"
        let-safetyjob
        let-expanded="expanded"
        let-i="rowIndex"
      >
        <tr>
          <td style="width: 55px">
            <p-tableCheckbox id="selectedOrders" [value]="safetyjob">
            </p-tableCheckbox>
          </td>
          <td>
            <a
              (click)="sjaTable.toggleRow(safetyjob)"
              *ngIf="safetyjob.TaskPlanListDto.length > 0"
            >
              <i
                class="pi pi-chevron-circle-right"
                aria-hidden="true"
                *ngIf="setCollapse(i)"
                (click)="toggleClick(i)"
              ></i>
              <i
                class="pi pi-chevron-circle-down"
                aria-hidden="true"
                *ngIf="!setCollapse(i)"
                (click)="toggleClickHide(i)"
              ></i>
            </a>
          </td>
          <td>
            <a [routerLink]="['./../edit', safetyjob['Id']]">{{
              safetyjob.Topics
            }}</a>
          </td>
          <td>
            <span>{{ safetyjob.TypeofIssue }}</span>
          </td>
          <td>
            <span>{{ safetyjob.Location }}</span>
          </td>
          <td>
            <span>{{ safetyjob.Description }}</span>
          </td>
          <td>
            <span
              class="badge"
              translate
              [style.background-color]="safetyjob.RiskFigureData.BackColor"
              >{{ safetyjob.RiskFigureData.ValueText }}
              <span>{{ safetyjob.RiskFigureData.Value }}</span>
            </span>
          </td>
          <td>
            <span>{{ safetyjob.CreatedOn | date : "dd/MM/yyyy" }}</span>
          </td>
          <td>
            <span>{{ safetyjob.EditedBy }}</span>
          </td>
          <td>
            <span
              [style.color]="
                safetyjob.ApprovalStatusColor
                  ? safetyjob.ApprovalStatusColor
                  : 'black'
              "
              >{{ safetyjob.ApprovalStatus.toUpperCase() | translate }}</span
            >
          </td>
          <td class="col-button" *ngIf="hideButton" [hidden]="true">
            <a
              (click)="confirm(safetyjob.Id)"
              class="btn btn-icon"
              title="{{ 'DELETE' | translate }}"
              routerLinkActive="active"
            >
              <i class="icon ic-sm icon-trash"></i>
            </a>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-activityList>
        <tr>
          <td colspan="11">
            <div>
              <!-- Table starts -->
              <p-table
                [value]="activityList.TaskPlanListDto"
                [rows]="5"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{{
                  'SUMMARY_TOTALRECORDS' | translate
                }}"
              >
                <!-- Header -->
                <ng-template pTemplate="header">
                  <tr>
                    <th>{{ "ACTION_NAME" | translate }}</th>
                    <th>{{ "DEADLINE" | translate }}</th>
                    <th>{{ "DESCRIPTION" | translate }}</th>
                    <th>{{ "RESPONSIBLE_PERSON" | translate }}</th>
                    <th>{{ "FOLLOWUP_PERSON" | translate }}</th>
                  </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-safetyjob>
                  <tr>
                    <td>
                      <span>{{ safetyjob.Title }}</span>
                    </td>
                    <td>
                      <span>{{
                        safetyjob.Deadline | date : "dd/MM/yyyy"
                      }}</span>
                    </td>
                    <td>
                      <span>{{ safetyjob.Description }}</span>
                    </td>
                    <td>
                      <span>{{ safetyjob.ResponsiblePerson }}</span>
                    </td>
                    <td>
                      <span>{{ safetyjob.FollowupPerson }}</span>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              <!-- Table Ends -->
            </div>
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="11">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>
</div>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<div class="action-btns-wrapper mbtm-30" *ngIf="isHideGuest">
  <button class="btn btn-primary" (click)="showAll()">
    <span translate>SHOW_ALL</span>
  </button>
  <button
    class="btn btn-outline-primary"
    (click)="saveApprovalNotice()"
    *ngIf="!isApproved"
  >
    <span translate>APPROVAL_NOTICE</span>
  </button>
  <button class="btn btn-primary" (click)="generateReport()">
    <span translate>GENERATE_REPORT</span>
  </button>
  <p-checkbox
    *ngIf="isFS"
    value="generate report"
    [(ngModel)]="approvedOn"
    label="{{ 'GENERATE_REPORT_IN_DOCUMENTS' | translate }}"
  ></p-checkbox>
  <p-checkbox
    *ngIf="!isFS"
    value="generate report"
    [(ngModel)]="approvedOn"
    label="{{ 'GENERATE_REPORT_IN_DOCUMENTS' | translate }}"
  ></p-checkbox>
</div>
<div class="mbtm-30" [hidden]="isApprovalHidden">
  <div class="sub-panel-title">
    <span translate>APPROVED_NOTICES</span>
  </div>
  <!--Approved all notices  datatable-->
  <div class="table-view mbtm-30">
    <!-- Table starts -->
    <p-table
      [value]="approvalNotices"
      #approvalTable
      dataKey="Id"
      expandableRows="true"
      [rows]="10"
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
    >
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th>{{ "EMPLOYEE_NAME" | translate }}</th>
          <th>{{ "STATUS" | translate }}</th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template
        pTemplate="body"
        let-risk
        let-expanded="expanded"
        let-i="rowIndex"
      >
        <tr>
          <td>
            <a (click)="approvalTable.toggleRow(risk)" class="btn btn-icon">
              <span
                class="icon ic-sm icon-expand-arrow"
                aria-hidden="true"
                *ngIf="setCollapse(i)"
                (click)="toggleClick(i)"
              ></span>
              <span
                class="icon ic-sm icon-collapse-arrow"
                aria-hidden="true"
                *ngIf="!setCollapse(i)"
                (click)="toggleClickHide(i)"
              ></span>
            </a>
          </td>
          <td>
            <span>{{ risk.Name }}</span>
          </td>
          <td>
            <span>{{ risk.Count }}/{{ risk.RiskAnalysis.length }}</span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-risk>
        <tr>
          <td colspan="3">
            <div>
              <!-- Table starts -->
              <p-table [value]="risk.RiskAnalysis" [rows]="5">
                <!-- Header -->
                <ng-template pTemplate="header">
                  <tr>
                    <th>{{ "TOPICS" | translate }}</th>
                    <th>{{ "LOCATION" | translate }}</th>
                    <th>{{ "APPROVED_DATE" | translate }}</th>
                    <th>{{ "EDITED_ON" | translate }}</th>
                  </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-risks>
                  <tr>
                    <td>
                      <span>{{ risks.Topic }}</span>
                    </td>
                    <td>
                      <span>{{ risks.GPSLocation }}</span>
                    </td>
                    <td>
                      <span
                        [style.color]="risks.ApprovedOn ? 'Green' : 'black'"
                        >{{
                          risks.ApprovedOn
                            ? (risks.ApprovedOn | date : "MM/dd/yyyy")
                            : ("UNAPPROVED" | translate)
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        [style.color]="
                          risks.ApprovedOn != null
                            ? risks.ModifiedOn
                              ? 'black'
                              : 'Green'
                            : 'red'
                        "
                        >{{ risks.ModifiedOn | date : "MM/dd/yyyy" }}</span
                      >
                    </td>
                  </tr>
                </ng-template>
                <!-- Empty message -->
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr>
                    <td colspan="4">
                      {{ "NO_RECORDS_FOUND" | translate }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              <!-- Table ends -->
            </div>
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="3">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>
  <!--Approved all notices  datatable ends-->
  <staticdata id="staticData"></staticdata>
</div>
<p-dialog
  header="{{ 'NOTIFICATION' | translate }}"
  [(visible)]="showNotification"
  [style]="{ width: '600px' }"
  [resizable]="false"
  [draggable]="false"
  translate
>
  SELECT_ONERECORD
</p-dialog>
<toaster-component></toaster-component>

<form #f="ngForm" novalidate>
    <div class="form-group">
        <label for="TopicName" class="col-form-label" translate>TOPIC_NAME
            <span class="required">*</span>
        </label>
        <div>
            <input type="text" class="label-input form-control" id="TopicName" type="text">
        </div>
    </div>
    <div class="action-btns-wrapper">
        <button type="submit" class="btn btn-outline-primary" translate>VIEW</button>
        <button type="submit" class="btn btn-primary" translate>SAVE</button>
    </div>
    <div class="table-view">
        <!-- Table starts -->
        <p-table [value]="data" [rows]="10" [responsive]="true">
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="TopicName">{{'Topic Name' | translate}}</th>
                    <th></th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-f>
                <tr>
                    <td>
                        <span>{{f.TopicName}}</span>
                    </td>
                    <td class="col-button">
                        <button type="button" class="btn btn-icon" title="{{'EDIT' | translate}}">
                            <i class="icon ic-sm icon-pencil-tip"></i>
                        </button>
                        <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="2">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table Ends-->
    </div>
</form>
import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ElectroLAR } from '../models/electro-laws-regulations.model';
import { ConfirmationService } from 'primeng/api';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ElectroService } from '../services/electro.services';
import { Toast } from 'angular2-toaster';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Electro } from '../models/electrocontract';
import { CustomValidator } from './shared/custom-validator';
import { ElectroDocumentService } from '../services/electro-document.services';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
    selector: 'electro-laws-regulation',
    templateUrl: 'electro-laws-regulation.component.html'
})

export class ElectroLARComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @ViewChild('LARTable',{static: false}) LARTable: Table;
    @ViewChild('electroRegTable',{static: false}) electroRegTable: Table;
    @Input() reportData: any;
    @Input() printMode?: number;
    @Output() loading = new EventEmitter();
    showLawsDialog: boolean = false;
    showMailDialog = false;
    showRegulationsDialog: boolean = false;
    electroLARS: ElectroLAR[];
    electroLAR: ElectroLAR;
    createLarForm: FormGroup;
    selectedType: string;
    electroLARList: ElectroLAR[];
    electroREGList: ElectroLAR[];
    isAdmin = false;
    isLaws: boolean = true;
    mailText: string = "";
    toast: Toast;
    additionalData: any;
    showLoader = true;
    clientContactForm: FormGroup;
    clientContacts: any;
    electro: Electro;
    email = false;
    private subscriptions: Subscription[] = [];
    constructor(
        public router: Router,
        private route: ActivatedRoute,
        private electroLARServices: ElectroService,
        private confirmationService: ConfirmationService,
        private translate: TranslateService,
        private ElectroDocumentService: ElectroDocumentService,
        private _fb: FormBuilder, ) {
        this.additionalData = {
            fileName: this.translate.instant('LAR_LIST'),
            header: this.translate.instant('LAR_LIST'),
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Laws', dataKey: 'Title' },
            ]
        };
    }

    ngOnInit(): void {
        this.electroLARS = [];
        this.electroLARList = this.route.snapshot.data['larlist'];
        this.electroREGList = this.route.snapshot.data['reglist'];
        this.electro = this.route.snapshot.data['electro'];
        this.clientContactForm = this._fb.group({
            'contactName': ['', Validators.required],
            'Email': [''],
        });
        if (BaseServices.UserRole === 'Admin') {
            this.isAdmin = true;
        }
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.clientContacts = [];
            this.clientContacts.push(
                { label: val.SELECT, value: null });
        }));
        if (!this.isAdmin) {
            this.subscriptions.push(this.electroLARServices.getClientContact(this.electro.ClientId).subscribe(result => {
                if (result) {
                    result.forEach(user => {
                        this.clientContacts.push(
                            { label: user.Name, value: user.Id });
                    });
                }
            }));
        }
    }

    loader(value: any) {
        this.showLoader = value;
    }

    mailMe() {
        this.showMailDialog = true;
    }

    /**
     * send laws and regulation attachment to contact mail.
     */
    sendReportMail(clientContactForm:any) {
        let businessId = 0;
        let CultureInfo = "en-GB";
        let Tomail = this.clientContactForm.value.Email;
        this.printMode = 1;
        this.additionalData = {
            fileName: this.translate.instant('Laws and Regulations'),
            fileName1: this.translate.instant('REGULATIONS_LIST'),
            header: this.translate.instant('Laws and Regulations'),
            header1: this.translate.instant('REGULATIONS_LIST'),
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            lawColumns: [
                { title: 'Laws', dataKey: 'Title' },
            ],
            regulationColumns:[
                { title: 'Regulations', dataKey: 'Title' },
             ]
        };
        if (this.additionalData.businessId != undefined) {
            businessId = this.additionalData.businessId;
        }
        if (this.additionalData.cultureInfo != undefined) {
            CultureInfo = this.additionalData.cultureInfo;
        }

        let languageMode = sessionStorage.getItem('languageMode')
        if (languageMode != null) {
            CultureInfo = languageMode;
        }
        this.subscriptions.push(this.electroLARServices.sendAttachmentMail(this.LARTable.value,this.electroRegTable.value, this.additionalData.lawColumns,this.additionalData.regulationColumns, this.additionalData.header, this.additionalData.header1, this.additionalData.businessId, CultureInfo, Tomail)
            .subscribe(success => {
                this.loading.emit(false);
                this.showMailDialog = false;
            },
                error => {
                    alert(this.translate.instant('MAIL_NOT_SENT'));
                }));
        this.showMailDialog = false;
    }

    /**
     * Bind emailId for contact selected to mail.
     * @param event 
     */
    onContactChange(event) {
        let contactId = event.value;
        if (contactId) {
            this.subscriptions.push(this.electroLARServices.getClientInfoById(contactId).subscribe(result => {
                if (result) {
                    this.clientContactForm.patchValue({
                        'Email': result.Email
                    });
                }
            }));
        }
    }

    /**
     * initialize form controls
     * @param LawOrReg 
     * @param electroLARS 
     */
    initLARForm(LawOrReg: string, electroLARS?: ElectroLAR): void {
        this.showLawsDialog = true;
        this.isLaws = LawOrReg === 'LAWS';
        let title = '';
        let url = '';

        if (electroLARS && electroLARS.Id > 0) {
            title = electroLARS.Title;
            url = electroLARS.Url;
        }
        this.electroLAR = new ElectroLAR();
        if (electroLARS) {
            this.electroLAR = electroLARS;
        }

        this.createLarForm = this._fb.group({
            'title': [title, [Validators.required, CustomValidator.noWhitespaceValidator]],
            'url': [url, [Validators.required, CustomValidator.noWhitespaceValidator]],
        });

    }

    /**
     * save laws or regulation added
     */
    saveLAR(createLarForm:any) {
        let electroSaveLaws = this.createLarForm.value;
        this.electroLAR.Title = electroSaveLaws.title;
        this.electroLAR.Url = electroSaveLaws.url;
        this.electroLAR.BusinessId = BaseServices.BusinessId;
        this.electroLAR.ApplicationId = BaseServices.ApplicationId;
        this.electroLAR.IsLaws = this.isLaws;
        if (this.electroLAR.Id > 0) {
            this.electroLAR.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.electroLARServices.updateLARS(this.electroLAR).subscribe(result => {
                if (result) {
                    this.closeModal();
                    this.createLarForm.reset();
                    this.subscriptions.push(this.electroLARServices.getLawsList().subscribe(result => {
                        this.electroLARList = result;
                        this.subscriptions.push(this.electroLARServices.getRegulationsList().subscribe(result => {
                            this.electroREGList = result;
                        }))
                    }));
                }
            }));
        }
        else {
            this.electroLAR.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.electroLARServices.addLARS(this.electroLAR).subscribe(result => {
                if (result) {
                    this.closeModal();
                    this.createLarForm.reset();
                    this.subscriptions.push(this.electroLARServices.getLawsList().subscribe(result => {
                        this.electroLARList = result;
                        this.subscriptions.push(this.electroLARServices.getRegulationsList().subscribe(result => {
                            this.electroREGList = result;
                        }));
                    }));
                }
            }));
        }

    }

    /**
     * delete specific law or regulation
     * @param electroLARId 
     */
    deleteLAR(electroLARId) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.electroLARServices.deleteLARS(electroLARId).subscribe(result => {
                    if (result) {
                        this.subscriptions.push(this.electroLARServices.getLawsList().subscribe(result => {
                            this.electroLARList = result;
                            this.createLarForm.reset();
                            this.subscriptions.push(this.electroLARServices.getRegulationsList().subscribe(result => {
                                this.electroREGList = result;
                                this.createLarForm.reset();
                            }));
                        }));
                    }
                    this.toasterComponent.callToastDlt();
                }));
            }
        });
    }

    /**
     * close model
     */
    closeModal() {
        this.showLawsDialog = false;
        this.ngOnInit();
        this.toasterComponent.callToast();
    }

    /**
     * clear form
     */
    clearLaw() {
        this.createLarForm.reset()
    }

    /**
     * popes for toast message
     * @param type 
     */
    popToastMessage(type: string): Toast {
        let body = `Data ${type} successfully`;
        var toast: Toast = {
            type: 'success',
            title: 'Laws and Regulations',
            body: body
        };
        return toast;
    }

    /**
     * export list as report event
     * @param type 
     * @param reportFor 
     */
    exportReport(type: string, reportFor: string) {
        this.loading.emit(true);
        let businessId = 0;
        let CultureInfo = "en-GB";
        if (this.additionalData.businessId != undefined) {
            businessId = this.additionalData.businessId;
        }
        if (this.additionalData.cultureInfo != undefined) {
            CultureInfo = this.additionalData.cultureInfo;
        }

        let languageMode = sessionStorage.getItem('languageMode')
        if (languageMode != null) {
            CultureInfo = languageMode;
        }
        if (reportFor === 'LAWS AND REGULATIONS') {
            this.additionalData = {
                fileName: this.translate.instant('Laws and Regulations'),
                fileName1: this.translate.instant('REGULATIONS_LIST'),
                header: this.translate.instant('Laws and Regulations'),
                header1: this.translate.instant('REGULATIONS_LIST'),
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                lawColumns: [
                    { title: 'Laws', dataKey: 'Title' },
                ],
                regulationColumns:[
                    { title: 'Regulations', dataKey: 'Title' },
                 ]
            };
            this.subscriptions.push(this.ElectroDocumentService
                .generateExportFile(this.LARTable.value,this.electroRegTable.value,this.additionalData.lawColumns,this.additionalData.regulationColumns, type, this.additionalData.header,this.additionalData.header1, this.printMode, businessId, CultureInfo)
                .subscribe(
                    blob => {
                        this.loading.emit(false);
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `${this.additionalData.fileName}.${this.additionalData.fileName1}.${type.toLowerCase()}`;
                        link.click();
                    },
                    error => {
                        alert(this.translate.instant('EXPORT_NOT_DOWNLOAD'));
                    }));
        }
    }

    openLink(url){
        window.open(url, "_blank");
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}
<div class="page-title">
  {{ PageTitle }}
</div>
<div class="tab-content">
  <form [formGroup]="deviationEditForm">
    <div class="row">
      <div class="col-sm-4 section">
        <h4 translate>DEVIATION_INFORMATION</h4>
        <div class="form-group">
          <label translate>STATUS</label>
          <div class="div">
            <label
              class="form-check form-check-inline"
              *ngFor="let option of options"
            >
              <input
                type="radio"
                value="{{ option.id }}"
                (change)="statusChange($event, option)"
                [checked]="
                  option.id === deviationEditForm.get('Status').value
                "
                formControlName="Status"
                class="form-check-input"
              />{{ option.name | translate }}
            </label>
          </div>
        </div>
        <div
          class="form-group"
          *ngIf="deviationEditForm.get('Department')"
        >
          <label for="department" class="col-form-label">
            <span translate>DEPARTMENT</span>
            <span class="required" *ngIf="!isFoodSafety">*</span>
          </label>
          <div class="input-btn-group">
            <p-multiSelect
              [options]="departments"
              formControlName="Department"
              [style]="{ width: '100%' }"
              #department
              (onChange)="onDepartmentChanged($event, department)"
            ></p-multiSelect>
            <button
              *ngIf="isGuestHideButton"
              class="btn btn-outline-primary"
              type="button"
              (click)="showDepartment()"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
          <control-messages
            [control]="deviationEditForm.controls['Department']"
          ></control-messages>
        </div>
        <div class="form-group" [hidden]="IsSubDepartmentHidden">
          <label for="Position">
            <span translate>SUB_DEPARTMENT_NAME</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown
              [filter]="true"
              [options]="subDepartmentsList"
              formControlName="SubDepartment"
              [style]="{ width: '100%' }"
            ></p-dropdown>
            <button
              class="btn btn-outline-primary"
              type="button"
              (click)="addSubDepartment()"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="form-group" *ngIf="deviationEditForm.controls['Topic']">
          <label for="topic" class="col-form-label">
            <span translate>TOPICS</span>
            <span class="required" *ngIf="!isFoodSafety">*</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown
              (dataKey)="('Id')"
              [options]="topics"
              formControlName="Topic"
              #topic
              (onChange)="onTopicChanged($event, topic)"
            ></p-dropdown>
            <button
              type="button"
              *ngIf="isGuestHideButton"
              class="btn btn-outline-primary"
              (click)="showTopic()"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
          <control-messages
            [control]="deviationEditForm.controls['Topic']"
          ></control-messages>
        </div>
        <div class="form-group" *ngIf="deviationEditForm.controls['Case']">
          <label class="col-form-label">
            <span translate>TYPE_OF_CASE</span>
            <span class="required" *ngIf="!isFoodSafety">*</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown
              (dataKey)="('Id')"
              [options]="cases"
              formControlName="Case"
              #casename
              (onChange)="onCaseChanged($event, casename)"
            ></p-dropdown>
            <button
              type="button"
              *ngIf="isGuestHideButton"
              class="btn btn-outline-primary"
              (click)="showCase()"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
          <control-messages
            [control]="deviationEditForm.controls['Case']"
          ></control-messages>
        </div>
        <div class="form-group" *ngIf="deviationEditForm.controls['Title']">
          <label for="title" class="col-form-label">
            <span translate>TITLE</span>
            <span class="required">*</span>
          </label>
          <div>
            <input type="text" size="27" formControlName="Title" pInputText />
          </div>
          <control-messages
            [control]="deviationEditForm.controls['Title']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label for="description" class="col-form-label">
            <span translate>DESCRIPTION</span>
          </label>
          <div>
            <textarea
              pInputTextarea
              [autoResize]="true"
              formControlName="Description"
            ></textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="title" class="col-form-label">
            <span translate>TIME_OF_EVENT</span>
          </label>
          <div style="display: flex; justify-content: space-between">
            <span style="width: 140px">
              <p-calendar
              dateFormat="dd/mm/yy"
              [showIcon]="true"
              formControlName="EventDate"
              placeholder="{{ 'SELECT_EVENT_DATE' | translate }}"
            ></p-calendar>
            </span>
            <span style="width: 100px">
              <p-calendar
                [timeOnly]="true"
                readonlyInput="true"
                [style]="{ width: '100%' }"
                (onSelect)="enableClear()"
                formControlName="EventTime"
                styleClass="dateTimeField"
              >
              </p-calendar>“
            </span>
            <span>
              <button
                type="button"
                class="btn btn-primary"
                (click)="bindEventDate()"
              >
                <span translate>NOW</span>
              </button>
              <button
                type="button"
                class="btn btn-outline-secondary"
                [disabled]="!IsClear"
                (click)="clearEventDate()"
              >
                <span translate>CLEAR</span>
              </button>
            </span>
          </div>
        </div>
        <div class="form-group">
          <label for="title" class="col-form-label">
            <span translate>EVENT_LOCATION</span>
          </label>
          <div>
            <input
              type="text"
              class="label-input form-control"
              formControlName="EventLocation"
              name="EventLocation"
            />
          </div>
        </div>

        <div
          class="form-group"
          [hidden]="isHideFS"
          *ngIf="deviationEditForm.controls['ObjectName']"
        >
          <label class="col-form-label">
            <span translate>OBJECT_NAME</span>
            <span class="required" *ngIf="!isFoodSafety">*</span>
          </label>
          <div>
            <input
              type="text"
              size="27"
              readonly="true"
              formControlName="ObjectName"
              pInputText
            />
          </div>
          <control-messages
            [control]="deviationEditForm.controls['ObjectName']"
          ></control-messages>
        </div>
        <div
          class="form-group"
          [hidden]="isHideFS"
          *ngIf="deviationEditForm.controls['Temperature']"
        >
          <label class="col-form-label">
            <span translate>TEMPERATURE_FULL</span>
            <span class="required" *ngIf="!isFoodSafety">*</span>
          </label>
          <div>
            <input
              type="text"
              size="27"
              readonly="true"
              formControlName="Temperature"
              pInputText
            />
          </div>
          <control-messages
            [control]="deviationEditForm.controls['Temperature']"
          ></control-messages>
        </div>

        <div class="form-group">
          <div class="tab-content">
            <ecpl-document-explorer
              [uploaderConfig]="upConfig"
              [uploadedFiles]="uploadedFiles"
              (saveChanges)="saveUploaded($event)"
            >
            </ecpl-document-explorer>
          </div>
        </div>
      </div>
      <!-- Mid column -->
      <div class="col-sm-4 section">
        <h4 translate>MANAGE_DEVIATION</h4>
        <div formGroupName="days" class="form-group">
          <label for="deadline" class="col-form-label">
            <span translate>DEADLINE</span>
            <span class="required" *ngIf="!isFoodSafety">*</span>
          </label>
          <div>
            <p-calendar
            dateFormat="dd/mm/yy"
            [showIcon]="true"
            formControlName="Deadline"
            placeholder="{{ 'SELECT_DEADLINE_DATE' | translate }}"
          ></p-calendar>
          </div>
          <control-messages
            [control]="deviationEditForm.get('days').get('Deadline')"
          ></control-messages>
        </div>

        <div class="form-row form-group">
          <div
            class="form-group flex-item"
            *ngIf="deviationEditForm.controls['Importance']"
          >
            <label for="importance" class="col-form-label">
              <span translate>IMPORTANCE_FULL</span>
              <span class="required" *ngIf="!isFoodSafety">*</span>
            </label>
            <div>
              <p-dropdown
                [options]="importances"
                formControlName="Importance"
                [style]="{ width: '100%' }"
                #importance
                (onChange)="onImportanceChanged($event, importance)"
              ></p-dropdown>
            </div>
            <control-messages
              [control]="deviationEditForm.controls['Importance']"
            ></control-messages>
          </div>
          <div
            class="form-group flex-item"
            *ngIf="deviationEditForm.controls['Cost']"
          >
            <label for="cost" class="col-form-label">
              <span translate>COST</span>
            </label>
            <div>
              <input
                type="number"
                formControlName="Cost"
                pInputText
                (keypress)="onNumber($event, 15)"
              />
            </div>
            <control-messages
              [control]="deviationEditForm.controls['Cost']"
            ></control-messages>
          </div>
        </div>

        <div *ngIf="Reference" class="form-group">
          <label for="description" class="col-form-label">
            <span translate>REFERENCE</span>
          </label>
          <div>
            <textarea
              pInputTextarea
              [autoResize]="true"
              formControlName="Reference"
            ></textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="immediate Action" class="col-form-label">
            <span translate>IMMEDIATE_ACTION</span>
          </label>
          <div>
            <textarea
              pInputTextarea
              [autoResize]="true"
              formControlName="ImmediateAction"
            ></textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="cause Analysis" class="col-form-label">
            <span translate>CAUSE_ANALYSIS</span>
          </label>
          <div>
            <textarea
              pInputTextarea
              [autoResize]="true"
              formControlName="Analysis"
            ></textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="corrective Action" class="col-form-label">
            <span translate>CORRECTIVE_ACTION</span>
          </label>
          <div>
            <textarea
              pInputTextarea
              [autoResize]="true"
              formControlName="CorrectiveAction"
            ></textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="effect of Action" class="col-form-label">
            <span translate>EFFECT_OF_ACTION</span>
          </label>
          <div>
            <textarea
              pInputTextarea
              [autoResize]="true"
              formControlName="EffectOfAction"
            ></textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="comment" class="col-form-label">
            <span translate>COMMENT</span>
          </label>
          <div>
            <textarea
              pInputTextarea
              [autoResize]="true"
              formControlName="Comment"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="col-sm-4 section">
        <!-- Right column -->
        <h4 translate>RESPONSIBILITY_AND_NOTIFICATIONS</h4>
        <div class="form-group">
          <label for="title" class="col-form-label">
            <span translate>CREATED_BY</span>
            <span>: {{CreatedBy}}</span>
          </label>
          <control-messages
            [control]="deviationEditForm.controls['Title']"
          ></control-messages>
        </div>
        <div class="form-group" *ngIf="deviationEditForm.controls['Execution']">
          <label for="execution" class="col-form-label">
            <span translate>RESPONSIBLE_FOR_EXECUTION</span>
            <span class="required" *ngIf="!isFoodSafety">*</span>
          </label>
          <div>
            <p-dropdown
              [options]="executions"
              formControlName="Execution"
              [style]="{ width: '100%' }"
              [filter]="true"
              #exec
              (onChange)="onExecChanged($event, exec)"
            ></p-dropdown>
          </div>
          <control-messages
            [control]="deviationEditForm.controls['Execution']"
          ></control-messages>
        </div>
        <div formGroupName="days" class="form-group">
          <label for="notification" class="col-form-label">
            <span translate>NOTIFICATION_BEFORE_DEADLINE_DEVIATION</span>
          </label>
          <div>
            <p-dropdown
              [options]="followUpNotify"
              formControlName="Notification"
              [style]="{ width: '100%' }"
            ></p-dropdown>
          </div>
          <control-messages
            [control]="deviationEditForm.get('days').get('Notification')"
          ></control-messages>
        </div>
        <div class="form-group" *ngIf="deviationEditForm.controls['Followup']">
          <label for="follow-up" class="col-form-label">
            <span translate>RESPONSIBLE_FOR_FOLLOW_UP</span>
            <span class="required" *ngIf="!isFoodSafety">*</span>
          </label>
          <div>
            <p-dropdown
              [options]="followUps"
              [filter]="true"
              formControlName="Followup"
              [style]="{ width: '100%' }"
              #follow
              (onChange)="onFollowChanged($event, follow)"
            ></p-dropdown>
          </div>
          <control-messages
            [control]="deviationEditForm.controls['Followup']"
          ></control-messages>
        </div>

        <div formGroupName="days" class="form-group">
          <label for="followupNotification" class="col-form-label">
            <span translate>NOTIFICATION_FOR_FOLLOW_UP_DAYS</span>
          </label>
          <div>
            <p-dropdown
              [options]="followUpNotify"
              formControlName="FollowupNotification"
              [style]="{ width: '100%' }"
            ></p-dropdown>
          </div>
          <control-messages
            [control]="
              deviationEditForm.get('days').get('FollowupNotification')
            "
          ></control-messages>
        </div>
      </div>
    </div>
    <!-- Deviation log - Shown / hidden based on button press -->
    <div class="wb-content" style="height: auto" *ngIf="showDeviationLog">
      <div class="default-list" *ngIf="!viewDashboard">
        <div class="col-sm-12 section deviation-log">
          <h4 translate>DEVIATION_LOG</h4>

          <div class="list-item">
            <span translate>CREATED_ON</span> {{ CreatedOn }}
          </div>
          <div class="list-item"><span translate>TIME</span> {{ time }}</div>

          <div class="table-view" *ngIf="showDeviationLog">
            <!-- Table starts -->
            <p-table
              [value]="deviationLog"
              [rows]="10"
              [paginator]="true"
              [pageLinks]="3"
              [rowsPerPageOptions]="[5, 10, 20]"
            >
              <!-- Header -->
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="FieldName">
                    {{ "MODIFIED_FIELD" | translate }}
                  </th>
                  <th pSortableColumn="OldValue">
                    {{ "OLD_VALUE" | translate }}
                  </th>
                  <th pSortableColumn="Modifier">
                    {{ "MODIFIED_BY" | translate }}
                  </th>

                  <th pSortableColumn="CreatedOn">
                    {{ "MODIFIED_ON" | translate }}
                  </th>
                  <th pSortableColumn="Time">{{ "TIME" | translate }}</th>
                </tr>
              </ng-template>
              <!-- Body Content -->
              <ng-template pTemplate="body" let-deviation>
                <tr>
                  <td>
                    <span>{{ deviation.FieldName | translate }}</span>
                  </td>
                  <td>
                    <span>{{ deviation.OldValue }}</span>
                  </td>
                  <td>
                    <span>{{ deviation.Modifier }}</span>
                  </td>
                  <td>
                    <span>{{ deviation.CreatedOn | date : "dd/MM/yyyy" }}</span>
                  </td>
                  <td>
                    <span>{{ deviation.Time }}</span>
                  </td>
                </tr>
              </ng-template>
              <!-- Empty message -->
              <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                  <td colspan="5">
                    {{ "NO_RECORDS_FOUND" | translate }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <!-- Table Ends -->
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="action-btns-wrapper">
    <button class="btn btn-outline-secondary" (click)="gotoList()">
      <span class="icon ic-xs icon-back"></span>
      <span translate>BACK_LIST</span>
    </button>
    <button class="btn btn-outline-secondary" (click)="toggleDeviationLog()">
      <span translate>DEVIATION_LOG</span>
    </button>

    <button
      class="btn btn-primary"
      [ngClass]="{ loading: loading }"
      (click)="updateChanges()"
      [disabled]="
        isFoodSafety
          ? false
          : (!deviationEditForm.valid || loading) &&
            (deviationEditForm.get('status')?.value != 10 ||
              deviationEditForm.get('Title')?.value.trim() == '')
      "
    >
      <span *ngIf="!loading" translate>SAVE</span>
      <span *ngIf="loading" translate>SAVE_PROCESS</span>
      <span *ngIf="loading" class="loader-icon"></span>
    </button>
    <div class="pull-right">
      <button
        type="submit"
        class="btn btn-outline-primary"
        (click)="exportPdf()"
      >
        <span class="icon ic-sm icon-print"></span>
        <span translate>PRINT_PAGE</span>
        <div class="panel-loader" *ngIf="loadingReport">
          <span>
            <span class="loader-icon"></span>
            <span translate>PROCESSING</span>
          </span>
        </div>
      </button>
    </div>
  </div>
</div>

<p-dialog
  header="{{ 'ADD_CASE' | translate }}"
  [(visible)]="isCaseDialog"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
  (onHide)="onBeforeCaseDialogHide()"
>
  <form [formGroup]="caseForm">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>NAME</span>
      </label>
      <div>
        <input
          type="text"
          id="title"
          class="label-input form-control"
          [(ngModel)]="selectedCaseType.Name"
          formControlName="Name"
        />
      </div>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!caseForm.valid"
        (click)="saveCase()"
      >
        <span translate>SAVE</span>
      </button>
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="hidingElement = !hidingElement"
        (click)="viewCaseType()"
      >
        <span translate>VIEW</span>
      </button>
    </div>
    <label
      color
      class="col-form-label"
      *ngIf="isLabelDisabled"
      [ngStyle]="{ color: 'red' }"
    >
      <span>{{ isExistCaseText }}</span>
    </label>
    <div *ngIf="hidingElement">
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          #ct
          [value]="editTypes"
          [rows]="5"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "CASE_NAME" | translate }}</th>
              <th></th>
            </tr>
            <tr>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    ct.filter($any($event.target)?.value, 'Name', 'contains')
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-Type>
            <tr>
              <td>
                <span>{{ Type.Name }}</span>
              </td>
              <td class="col-button">
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="editCase(Type)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deleteCase(Type['Id'])"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends -->
      </div>
    </div>
  </p-footer>
</p-dialog>

<p-dialog
  header="{{ 'ADD_DEPARTMENT' | translate }}"
  [(visible)]="department"
  [modal]="true"
  (onHide)="onBeforeCaseDialogHide()"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
>
  <form [formGroup]="departmentForm">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>NAME</span>
      </label>
      <div>
        <input
          type="text"
          [(ngModel)]="selectedDepartment.Name"
          class="label-input form-control"
          formControlName="Name"
        />
      </div>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="hidesElement = !hidesElement"
        (click)="getBusinessDepartment()"
      >
        <span translate>VIEW</span>
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        (click)="saveDepartment()"
        [disabled]="!departmentForm.valid"
      >
        <span translate>SAVE</span>
      </button>
    </div>
    <label
      for="title"
      color
      class="col-form-label"
      *ngIf="isDepartmentAllocated"
      [ngStyle]="{ color: 'red' }"
    >
      <span translate>CANNOT_DELETE</span>
    </label>
    <label
      for="title "
      color
      class="col-form-label"
      *ngIf="isDepartmentErrorDisabled"
      [ngStyle]="{ color: 'red' }"
    >
      <span translate>DEPARTMENT_EXIST</span>
    </label>

    <div *ngIf="hidesElement">
      <div class="table-view">
        <p-table
          #deviation
          [value]="departmentdetails"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "Id" | translate }}</th>
              <th>{{ "TYPE_NAME" | translate }}</th>
              <th></th>
            </tr>
            <tr>
              <th></th>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    deviation.filter(
                      $any($event.target)?.value,
                      'Name',
                      'contains'
                    )
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-Type>
            <tr>
              <td>
                <span>{{ Type.Id }}</span>
              </td>
              <td>
                <span>{{ Type.Name }}</span>
              </td>
              <td class="col-button">
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="editDepartment(Type)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deleteDepartment(Type)"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="3">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </p-footer>
</p-dialog>

<p-dialog
  header="{{ 'ADD_TOPIC' | translate }}"
  [(visible)]="isAddTopic"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
  (onHide)="onBeforeTopicDialogHide()"
>
  <form [formGroup]="TopicForm">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>NAME</span>
      </label>
      <div>
        <input
          type="text"
          formControlName="Topic"
          #Title
          class="label-input form-control"
        />
      </div>
    </div>
    <label
      for="Topicalert"
      color
      class="col-form-label"
      *ngIf="isTopicalertDisabled"
      [ngStyle]="{ color: 'red' }"
    >
      <span translate>TOPIC_EXIST</span>
    </label>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        type="button"
        class="btn btn-primary"
        [ngClass]="{ AddTopicLoading: AddTopicLoading }"
        [disabled]="!TopicForm.valid || AddTopicLoading"
        (click)="saveTopic()"
      >
        <span *ngIf="!AddTopicLoading" translate>SAVE</span>
        <span *ngIf="AddTopicLoading" translate>SAVE_PROCESS</span>
        <span *ngIf="AddTopicLoading" class="loader-icon"></span>
      </button>
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="viewTopic = !viewTopic"
      >
        <span translate>VIEW</span>
      </button>
    </div>

    <div *ngIf="viewTopic">
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          #ct
          [value]="topicList"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "CASE_NAME" | translate }}</th>
              <th></th>
            </tr>
            <tr>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    ct.filter($any($event.target)?.value, 'Name', 'contains')
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-Type>
            <tr>
              <td>
                <span>{{ Type.Name }}</span>
              </td>
              <td class="col-button">
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="editCase(Type)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deleteCase(Type['Id'])"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends -->
      </div>
    </div>
  </p-footer>
</p-dialog>

<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>

<toaster-component></toaster-component>
<div class="panel-loader" *ngIf="loading"></div>
<ng-container #addSubDeptViewRef></ng-container>

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class UserService {
  _employeeContactUser: Observable<any> = null!;
  constructor(private http: HttpClient) {}
  clearCachedUserList() {
    this._employeeContactUser = null!;
  }
  getAllUsers() {
    return this.http
      .get(environment.BASE_URL + '/users', )
      .map((result) => result);
  }
  getUser(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/project/ContactLookup/' + id,

      )
      .map((result: any) => result);
  }
  getDeviationCreatedByUsers() {
    return this.http
      .get<any>(
        environment.BASE_URL + '/createdbyusers/' + BaseServices.BusinessId,

      )
      .map((result) => result);
  }

  /**
   * get assigned user
   * @param appId {number}
   * @param businessId {number}
   */
  getAssignedUsers(appId: number, businessId: number) {
    return this.http
      .get<any[]>(
        `${environment.BASE_URL}/users/${appId}/${businessId}`,

      )
      .map((result) => result);
  }

  updateLanguage(langId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/user/modify/language/' + langId,

      )
      .map((result) => {
        return result;
      });
  }

  getUserProfile() {
    return this.http
      .get<HttpHeaderResponse>(
        environment.BASE_URL + '/user/profile',

      )
      .map((response: HttpHeaderResponse) => {
        return response;
      });
  }

  getUsersById(id: number) {
    return this.http
      .get<any[]>(
        environment.BASE_URL + '/user/' + id,

      )
      .map((response: any) => {
        return response;
      });
  }

  updateUser(id: number, data: any) {
    return this.http
      .put<HttpHeaderResponse>(
        environment.BASE_URL + '/user/' + id,
        data,

      )
      .map((response: HttpHeaderResponse) => {
        return response;
      });
  }

  /**
   * update signature image for a user
   * @param id {int}
   * @param imageUrl {string}
   */
  updateSignatureImage(data: any) {
    return this.http
      .put<HttpHeaderResponse>(
        environment.BASE_URL + '/update-signature-image/',
        data,

      )
      .map((response: HttpHeaderResponse) => {
        return response;
      });
  }

  getLanguages() {
    return this.http
      .get(
        environment.BASE_URL + '/master/languages',

      )
      .map((result) => result);
  }

  getRoles() {
    return this.http
      .get<HttpHeaderResponse>(
        environment.BASE_URL + '/master/roles',

      )
      .map((response: HttpHeaderResponse) => {
        return response;
      });
  }

  addusers(data: any) {
    return this.http
      .post<HttpHeaderResponse>(
        environment.BASE_URL + '/user',
        data,

      )
      .map((response: HttpHeaderResponse) => {
        return response;
      });
  }
  getUsersByBusiness(businessId: number) {
    return this.http
      .get<any>(
        environment.BASE_URL + '/userbybusiness/' + businessId,

      )
      .map((result) => result);
  }

  /**
   * get employees/contacts with userid
   * @param businessId {number}
   */
  getEmployeeContactUsers(businessId: number) {
    return this.http
      .get<any>(
        environment.BASE_URL + '/employeecontactusers/' + businessId,

      )
      .map((result) => result);
  }

  getEmployeeOnly(businessId: number) {
    return this.http
      .get<any>(
        environment.BASE_URL + '/employeeOnly/' + businessId,

      )
      .map((result) => result);
  }

  /**
   * get employees/contacts with userid
   * @param businessId {number}
   */
  getEmployeeContactConveners(businessId: number) {
    return this.http
      .get<any>(
        environment.BASE_URL + '/employeecontactconveners/' + businessId,

      )
      .map((result) => result);
  }

  /**
   * get employees/contacts subscription user with userid
   * @param businessId {number}
   */
  getEmployeeContactSubscriptionUsers(businessId: number) {
    return this.http
      .get<any>(
        environment.BASE_URL + '/employeecontactsubscription/' + businessId,

      )
      .map((result) => result);
  }

  getUserExits(featureId: number, featureKey: number) {
    return this.http
      .get<HttpHeaderResponse>(
        `${environment.BASE_URL}/userexits/${BaseServices.ApplicationId}/${featureId}/${featureKey}`,

      )
      .map((response: HttpHeaderResponse) => {
        return response;
      });
  }
}


import { BaseServices } from './../../kuba.services';
import { CrewListServices } from 'src/app/kuba/crew-list/services/crewlist.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { PdfSetting, HelperService } from 'src/app/_services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
@Component({
    selector: 'history',
    templateUrl: 'history.component.html'
})
export class HistoryComponent implements OnInit {
    @ViewChild('crewListTable',{static: false}) crewListTable: Table;
    crewHistory: any;
    crewListForm: FormGroup;
    projectUsers: any;
    filterData: any;
    endDate: string;
    startDate: string;
    parentKey: any;
    parentId: any;
    additionalData: any;
    projectInfo: any;
    projectName: string;
    ProjectAddress: string;
    ProjectBuilder: string;
    featureKeyName: string;
    hideFeatureInfo = true;
    buildername: string;
    foodsafety:boolean=true;
    private subscriptions: Subscription[] = [];
    /**
    * Date picker configuration option
    */
    public startDateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px',
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        }
    };
    public endDateOptions: IMyDpOptions = this.startDateOptions;
    constructor(private crewListServices: CrewListServices,
        private route: ActivatedRoute,
        private _fb: FormBuilder,
        private translate: TranslateService) {
        this.parentId = route.snapshot.parent.parent.params['id'];
        this.parentKey = route.snapshot.parent.parent.data['parent'];
        this.subscriptions.push(this.translate.stream('FILENAME').subscribe(val => {
            if(this.parentKey === 'FS'){      
        this.additionalData = {
            fileName: val.CREW_LIST_HISTORY,
            header: 'Crew List',
            businessId: BaseServices,
            cultureInfo: 'en-GB',
            columnNames: [
                { title: 'Name', dataKey: 'Name' },
                { title: 'Date', dataKey: 'Date' },
                { title: 'In', dataKey: 'In' },
                { title: 'Out', dataKey: 'Out' }
            ]
        };}
        else{
            this.additionalData = {
                fileName: val.CREW_LIST_HISTORY,
                header: 'Crew List',
                businessId: BaseServices,
                cultureInfo: 'en-GB',
                columnNames: [
                    { title: 'Organization Number', dataKey: 'OrganizationNumber' },
                    { title: 'Name', dataKey: 'Name' },
                    { title: 'Date Of Birth', dataKey: 'Dob' },
                    { title: 'HSE-Card Number', dataKey: 'HSECardNumber' },
                    { title: 'Date', dataKey: 'Date' },
                    { title: 'In', dataKey: 'In' },
                    { title: 'Out', dataKey: 'Out' }
                ]
            };
        }
    }));
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.projectInfo = this.route.snapshot.data['proInfo'];
        this.parentKey = this.route.snapshot.parent.parent.data['parent'];

        if (this.projectInfo) {
            this.projectName = this.projectInfo.ProjectName;
            this.ProjectAddress = this.projectInfo.Address;
            this.ProjectBuilder = this.projectInfo.Builder;
        }
        if (this.parentKey === 'FS') {
            this.buildername = this.translate.instant('DEPARTMENT_NAME');
            this.featureKeyName = 'ZONE';
        } else {
            this.featureKeyName = 'PROJ_NAME';
            this.buildername = this.translate.instant('BUILDER');
        }
        this.crewListForm = this._fb.group({
            'StartDate': [''],
            'EndDate': [''],
            'Name': ['']
        })
        this.crewHistory = this.route.snapshot.data['list'];
        let projectUsers = this.route.snapshot.data['projectUsers'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.projectUsers = [];
            this.projectUsers.push(
                { label: val.SELECT, value: null }
            );
        }));
        if (projectUsers) {
            projectUsers.forEach(element => {
                this.projectUsers.push({ label: element.Name, value: element.Id });
            });
        }
        this.additionalData.businessId = BaseServices.BusinessId;
        this.additionalData.cultureInfo = BaseServices.userCultureInfo();
        if(this.parentKey === 'FS'){
            this.foodsafety=false;
        }
    }
    /**
   * startdate calendar onselect event
   * @param event {IMyDateModel}
   */
    onStartDateChanged(event: IMyDateModel) {
        this.startDate = HelperService.formatDateFilter(event.formatted);
        if (this.startDate !== '') {
            this.filterTable();
        } else {
            this.crewHistory = this.route.snapshot.data['list'];
        }
    }
    /**
    * enddate calendar onselect event
    * @param event {IMyDateModel}
    */
    onEndDateChanged(event: IMyDateModel) {
        this.endDate = HelperService.formatDateFilter(event.formatted);
        if (this.endDate !== '') {
            this.filterTable();
        } else {
            this.crewHistory = this.route.snapshot.data['list'];
        }
    }/**
       * Method for setting date range
       * @param dateConfig {IMyDpOptions}
       * @param event{IMyDateModel}
       * @param type  {string}
       */
    setDateInput(dateConfig: IMyDpOptions, event: IMyDateModel, type: string): IMyDpOptions {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null;
        let dateOptions = HelperService.setDateRange(dateConfig, date, type);
        return dateOptions;
    }
    getCrewList() {
        this.subscriptions.push(this.crewListServices.getCrewListHistroy(this.parentId, this.parentKey).subscribe(result => {
            if (result) {
                this.crewHistory = result;
            }
        }))
    }

    /**
   * date filter for table
   */
    filterTable() {
        if (this.crewHistory) {
            if (this.startDate || this.endDate) {
                if (this.startDate && this.endDate) {
                    this.crewHistory = this.crewHistory.filter(
                        x => new Date(x.DateFilter) >= new Date(this.startDate) && new Date(x.DateFilter) <= new Date(this.endDate)
                    );
                } else if (this.endDate) {
                    this.crewHistory = this.crewHistory.filter(x => x.DateFilter === this.endDate);
                } else {
                    this.crewHistory = this.crewHistory.filter(x => x.DateFilter === this.startDate);
                }
            }
        }
    }
    /**
 * Export pdf
 */
    exportPdf() {
        let pdfSetting = new PdfSetting();
        pdfSetting.date = 'Date:' + HelperService.formatDate(new Date());
        pdfSetting.businessName = BaseServices.BusinessName;
        pdfSetting.pageHeader = 'Course Details';
        let columns = [
            { title: 'Employee Name', dataKey: 'Employee' },
            { title: 'Department', dataKey: 'Department' },
            { title: 'Course Name', dataKey: 'Course' },
            { title: 'Institute', dataKey: 'Institute' },
            { title: 'Renewal Date', dataKey: 'RenewalDateForFilter' },
            { title: 'Start Date', dataKey: 'StartDate' },
            { title: 'End Date', dataKey: 'EndDate' }
        ];
        HelperService.generatePdf(this.crewHistory, columns, 'Crew list', pdfSetting, 'l');
    }
    onProjectUserChanged(e: any, userDropdown: any) {
        if (e.value) {
            // custom filter datatable
            this.crewListTable.filter(userDropdown.selectedOption.value, 'UserId', 'equals');
        } else {
            this.crewListTable.reset();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }

}

export interface User {
  Id: number;
  LanguageId: number;
  RoleId: number;
  ApplicationId: number;
  BusinessId: number;
  Rights: string;
  FeatureId: number;
  FeatureKey: String;
  Username: string;
  Password: string;
  Email: string;
  Name: string;
  Avatar: string;
  Sso: string;
  SsoUsername: string;
  SsoDomain: string;
  SsoLoggedIn: string;
  NotificationUpdateOn: string;
  Status: string;
}
export class Language {
  Id: number;
  Title: string;
}
export class Role {
  Id: number;
  Name: string;
}
export class SaveUsers {
  Id: number;
  LanguageId: number;
  RoleId: number;
  ApplicationId: number;
  BusinessPortalId: number;
  BusinessId: number;
  Rights: string;
  FeatureId: number;
  FeatureKey: number;
  Username: string;
  Password: string;
  Email: string;
  Name: string;
  SignatureFile: SignatureFile;
  SignatureImg: string;
  AvatarFile: AvatarFile;
  Avatar: string;
  Sso: string;
  SsoUsername: string;
  SsoDomain: string;
  SsoLoggedIn: true;
  NotificationUpdateOn: Date;
  Status: string;
  Mobile: string;
  CountryCode: string;
  CreatedBy: number;
  SendUserData: boolean;
  MobilewithCode: string;
  IsTOCApproved: boolean;
  BusinessAccess: BusinessAccess[];
  IsPortalManager?: boolean;
  CreatedOn: string;
  ModifiedBy?: number;
}
export class SignatureFile {
  ContentType: string;
  ContentDisposition: string;
  Headers: {};
  Length: number;
  Name: string;
  FileName: string;
}
export class AvatarFile {
  ContentType: string;
  ContentDisposition: string;
  Headers: {};
  Length: number;
  Name: string;
  FileName: string;
}

export class UserCredential {
  Id: number;
  OldPassword: string;
  Password: string;
  UserName: string;
  EmailId: string;
  Url: string;
}

export class BusinessAccess {
  Id: number;
  BusinessId: number;
  PortalManagerId: number;
  UserId: number;
}

export class ResetPasswordCredentialDto {
  Id: number;
  UserName: string;
  EmailId: string;
  OldPassword: string;
  Password: string;
}

export class ResetCredential extends ResetPasswordCredentialDto {
  public get someFieldButPublic() {
    return this.Id;
  }
  public set someFieldButPublic(value) {
    this.Id = value;
  }
  UserID: string;
  Token: string;
  Status: string;
  CreatedOn: string;
  CurrentTime: Date;
}

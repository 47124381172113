<div class="tab-content" id="businessSettingPrint">
    <div class="row">
        <div class="col-sm-6">
            <div class="row mb-2 align-items-center">
                <div class="col-sm-7">
                    <input type="hidden" pInputText [(ngModel)]="settingId">
                    <p-checkbox [(ngModel)]="emp.chkEmployee" [binary]="true" label="{{'HAVE_EMPLOYEES' | translate}}"
                      (click)="emp.chkEmployee"></p-checkbox>
                </div>
            </div>
            <div class="row mb-2 align-items-center">
                <div class="col-sm-7">
                    <p-checkbox [binary]="true" label="{{'MAIN_SAFETY_DELEGATE' | translate}}"
                      [(ngModel)]="emp.chkMainSafetyDelegate" (click)="emp.chkMainSafetyDelegate"></p-checkbox>
                </div>
                <div class="col-sm-5">
                    <p-multiSelect [options]="OnlyEmployee" #mainSafetyDelegate="ngModel"
                      [(ngModel)]="selectedMainSafetyDelegate" [required]="true" [style]="{'width':'100%'}"
                      [disabled]="!emp.chkMainSafetyDelegate" [defaultLabel]="'SELECT' | translate">
                    </p-multiSelect>
                </div>
            </div>
            <div class="row mb-2 align-items-center">
                <div class="col-sm-7">
                    <p-checkbox [binary]="true" label="{{'SAFETY_DELEGATE' | translate}}"
                      [(ngModel)]="emp.chkSafetyDelegate" (click)="emp.chkSafetyDelegate"></p-checkbox>
                </div>
                <div class="col-sm-5">
                    <p-multiSelect [options]="OnlyEmployee | dropdownSelectTranslate" #safetyDelegate="ngModel"
                      [(ngModel)]="selectedSafetyDelegate" [required]="true" [style]="{'width':'100%'}"
                      [disabled]="!emp.chkSafetyDelegate" [defaultLabel]="'SELECT' | translate"></p-multiSelect>
                </div>
            </div>
            <div class="row mb-2 align-items-center">
                <div class="col-sm-7">
                    <p-checkbox [binary]="true" label="{{'EMPLOYEE_DELEGATE' | translate}}"
                      [(ngModel)]="emp.chkEmployeeDelegate" (click)="emp.chkEmployeeDelegate"></p-checkbox>
                </div>
                <div class="col-sm-5">
                    <p-multiSelect [options]="OnlyEmployee | dropdownSelectTranslate" #employeeDelegate="ngModel"
                      [(ngModel)]="selectedEmployeeDelegate" [required]="true" [style]="{'width':'100%'}"
                      [disabled]="!emp.chkEmployeeDelegate" [defaultLabel]="'SELECT' | translate"></p-multiSelect>
                </div>
            </div>
            <div class="row mb-2 align-items-center">
                <div class="col-sm-7">
                    <p-checkbox [binary]="true" label="{{'FIRE_DELEGATE' | translate}}"
                      [(ngModel)]="emp.chkFireDelegate" (click)="emp.chkFireDelegate"></p-checkbox>
                </div>
                <div class="col-sm-5">
                    <p-dropdown [options]="OnlyEmployee | dropdownSelectTranslate" #fireDelegate="ngModel"
                      [(ngModel)]="selectedFireDelegate" [required]="true" [style]="{'width':'100%'}"
                      [disabled]="!emp.chkFireDelegate" placeholder="{{'SELECT' | translate}}"></p-dropdown>
                </div>
            </div>
            <div class="row mb-2 align-items-center">
                <div class="col-sm-7">
                    <p-checkbox [binary]="true" label="{{'ACCOUNTANT' | translate}}" [(ngModel)]="emp.chkAccountant"
                      (click)="emp.chkAccountant"></p-checkbox>
                </div>
                <div class="col-sm-5">
                    <p-dropdown [options]="EmployeeContact | dropdownSelectTranslate" #accountant="ngModel"
                      [(ngModel)]="selectedAccountant" [required]="true" [style]="{'width':'100%'}"
                      [disabled]="!emp.chkAccountant" placeholder="{{'SELECT' | translate}}"></p-dropdown>
                </div>
            </div>
            <div class="row mb-2 align-items-center">
                <div class="col-sm-7">
                    <p-checkbox [binary]="true" label="{{'AUDITOR' | translate}}" [(ngModel)]="emp.chkAuditor"
                      (click)="emp.chkAuditor"></p-checkbox>
                </div>
                <div class="col-sm-5">
                    <p-dropdown [options]="EmployeeContact | dropdownSelectTranslate" #auditor="ngModel"
                      [(ngModel)]="selectedAuditor" [required]="true" [style]="{'width':'100%'}"
                      [disabled]="!emp.chkAuditor" placeholder="{{'SELECT' | translate}}"></p-dropdown>
                </div>
            </div>
            <div class="row mb-2 align-items-center">
                <div class="col-sm-7">
                    <p-checkbox [binary]="true" label="{{'WORKMEN_COMP_INSURANCE' | translate}}"
                      [(ngModel)]="emp.chkCompensationInsurance" (click)="emp.chkCompensationInsurance"></p-checkbox>
                </div>
                <div class="col-sm-5">
                    <input #employeeInsurance="ngModel" pInputText [(ngModel)]="selectedEmployeeInsurance" required
                      [disabled]="!emp.chkCompensationInsurance" />
                      <p *ngIf="!selectedEmpInsurance" style="color: red;">* {{'REQUIRED' | translate}}</p>
                </div>
            </div>
            <div class="row mb-2 align-items-center">
                <div class="col-sm-7">
                    <p-checkbox [binary]="true" label="{{'OTP_STATUTORY_INSURANCE' | translate}}"
                      [(ngModel)]="emp.chkStatutoryInsurance" (click)="emp.chkStatutoryInsurance"></p-checkbox>
                </div>
                <div class="col-sm-5">
                    <input #employee="ngModel" type="text" [(ngModel)]="selectedEmployee" required pInputText
                      [disabled]="!emp.chkStatutoryInsurance" />
                      <p *ngIf="!selectedEmpstatutory" style="color: red;">* {{'REQUIRED' | translate}}</p>
                </div>
            </div>
            <div class="row mb-2 align-items-center">
                <div class="col-sm-7">
                    <input type="hidden" pInputText [(ngModel)]="settingId">
                    <p-checkbox [(ngModel)]="emp.chkOccupational" [binary]="true" label="{{'HEALTH_CARE' | translate}}"
                      (click)="emp.chkOccupational"></p-checkbox>
                </div>
                <div class="col-sm-5">
                    <input #occupational="ngModel" pInputText [(ngModel)]="selectedOccupational" required
                      [disabled]="!emp.chkOccupational" />
                      <p *ngIf="!selectedEmpOccupational" style="color: red;">* {{'REQUIRED' | translate}}</p>
                </div>
            </div>
            <div class="row mb-2 align-items-center">
                <div class="col-sm-7">
                    <input type="hidden" pInputText [(ngModel)]="settingId">
                    <p-checkbox [(ngModel)]="emp.chkpersonal" [binary]="true"
                      label="{{'HAVE_PERSONAL_DELEGATE' | translate}}" (click)="emp.chkpersonal"></p-checkbox>
                </div>
                <div class="col-sm-5">
                    <p-dropdown [options]="HavePersonalDelegateOptions | dropdownSelectTranslate" #personal="ngModel"
                      [(ngModel)]="selectedPersonal" [required]="true" [style]="{'width':'100%'}"
                      [disabled]="!emp.chkpersonal" placeholder="{{'SELECT' | translate}}"></p-dropdown>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="row mb-2 align-items-center" *ngIf="isShowBusinessLanguage">
                <div class="col-sm-7">
                    <label for="BusinessLanguage">
                        <span translate>BUSINESS_LANGUAGE</span>
                    </label>
                </div>
                <div class="col-sm-5">
                    <p-dropdown [options]="Language | dropdownSelectTranslate" [(ngModel)]="selectedLanguage"
                      [style]="{'width':'100%'}" placeholder="{{'SELECT' | translate}}"></p-dropdown>
                </div>
            </div>
            <div class="row mb-2 align-items-center">
                <div class="col-sm-7">
                    <label for="BusinessTheme">
                        <span translate>HSE_RESPONSIBLE</span>
                    </label>
                </div>
                <div class="col-sm-5">
                    <p-dropdown [options]="HseManager | dropdownSelectTranslate" [(ngModel)]="selectedHseManager"
                      [style]="{'width':'100%'}" placeholder="{{'SELECT' | translate }}"></p-dropdown>
                </div>
            </div>
            <div class="row mb-2 align-items-center">
                <div class="col-sm-7">
                    <label>
                        <span translate>HSE-advisor</span>
                    </label>
                </div>
                <div class="col-sm-5" *ngIf="!isAdminPortal">
                    <p-dropdown [options]="HSEAdviser | dropdownSelectTranslate" [disabled]="true"
                      [(ngModel)]="selectedHseAdviser" placeholder="{{'SELECT' | translate }}" [style]="{'width':'100%'}"></p-dropdown>
                </div>
                <div class="col-sm-5" *ngIf="isAdminPortal">
                    <p-dropdown [options]="HSEAdviser | dropdownSelectTranslate" [(ngModel)]="selectedHseAdviser"
                      placeholder="{{'SELECT' | translate }}" [style]="{'width':'100%'}"></p-dropdown>
                </div>
            </div>
            <!-- Enviromental lighthouse  -->
            <div class="row mb-2 align-items-center">
                <div class="col-sm-7">
                    <label>
                        <span translate>ENVIRONMENTAL_LIGHTHOUSE_RESPONSIBLE</span>
                    </label>
                </div>
                <div class="col-sm-5">
                    <p-dropdown [options]="EnvLight | dropdownSelectTranslate" [(ngModel)]="selectedEnvLighthosue"
                      [style]="{'width':'100%'}" placeholder="{{'SELECT' | translate }}"></p-dropdown>
                </div>
            </div>
            <div class="row mb-2 align-items-center">
                <div class="col-sm-7">
                    <label for="InsuranceCompany">
                        <span translate>INSURANCE_COMPANY</span>
                    </label>
                </div>
                <div class="col-sm-5">
                    <input type="text" [(ngModel)]="InsuranceCompany" pInputText [style]="{'width':'100%'}"
                      maxlength="100">
                </div>
            </div>
            <div class="row mb-2 align-items-center">
                <div class="col-sm-7">
                    <label for="HomePageAddress">
                        <span translate>HOMEPAGE_ADDRESS</span>
                    </label>
                </div>
                <div class="col-sm-5" [formGroup]="siteAddress">
                    <input type="text" formControlName="homeSiteAddress" pInputText [style]="{'width':'100%'}">
                    <control-messages [control]="siteAddress.controls['homeSiteAddress']"></control-messages>
                </div>
            </div>
            <div class="row mb-2 align-items-center">
                <div class="col-sm-7">
                    <label for="Goals">
                        <span translate>HSE_GOALS</span>
                    </label>
                </div>
                <div class="col-sm-5">
                    <textarea pInputTextarea [(ngModel)]="HseGoals" maxlength="500"></textarea>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="row mt-3" id="ignorePDF">
        <div class="col-sm-6">
            <div class="upload-file-container">
                <h5 translate>BUSINESS_LOGO</h5>
                <div class="upload-actions">
                    <input type="file" id="files" class="hidden" (change)="filesSelectForLogo($event)" />
                    <label for="files" class="btn btn-outline-primary m-0" translate>UPLOAD_IMAGE</label>
                    <a *ngIf="businessLogo != null" type="button" class="btn btn-icon text-danger"
                        title="{{'DELETE' | translate}}" (click)="deleteImage('Business_Logo')">
                        <i class="icon ic-sm icon-trash"></i>
                    </a>
                </div>
                <div *ngIf="uploadStartForLogo">
                    <span translate>IMAGE_IS_UPLOADING</span>
                </div>
                <div *ngIf="businessLogo !=null">
                    <img src="{{businessLogo}}" alt="" width="200px" class="img-thumbnail">
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="upload-file-container">
                <h5 translate>BUSINESS_IMAGE</h5>
                <div class="upload-actions">
                    <input type="file" id="businessfiles" class="hidden" (change)="filesSelect($event)" />
                    <label for="businessfiles" class="btn btn-outline-primary m-0" translate>UPLOAD_IMAGE</label>
                    <a *ngIf="businessImage != null" type="button" class="btn btn-icon text-danger"
                        title="{{'DELETE' | translate}}" (click)="deleteImage('Business_Image')">
                        <i class="icon ic-sm icon-trash"></i>
                    </a>
                </div>
                <div *ngIf="uploadStart">
                    <span translate>IMAGE_IS_UPLOADING</span>
                </div>
                <div *ngIf="businessImage !=null">
                    <img src="{{businessImage}}" alt="" width="200px" class="img-thumbnail">
                </div>
            </div>
        </div>
    </div>
    <div class="action-btns-wrapper">
        <div class="row">
            <div class="col-sm-6">
                <button class="btn btn-primary" type="submit" (click)="saveChanges()" [ngClass]="{'loading' : loading}"
                  [disabled]="     
      (mainSafetyDelegate.invalid  || safetyDelegate.invalid || employeeDelegate.invalid || fireDelegate.invalid || accountant.invalid || auditor.invalid || accountant.invalid || loading)">
                    <span *ngIf="!loading" translate>SAVE</span>
                    <span *ngIf="loading" translate>SAVE_PROCESS</span>
                    <span *ngIf="loading" class="loader-icon"></span></button>

                <button *ngIf="!isDinHMSLogin" class="btn btn-primary" [routerLink]="['../features']"
                  [disabled]="    
      (mainSafetyDelegate.invalid  || safetyDelegate.invalid || employeeDelegate.invalid || fireDelegate.invalid || accountant.invalid || employee.invalid || employeeInsurance.invalid || auditor.invalid || accountant.invalid || loading)"
                  (click)="saveChanges()">
                    <span translate>SAVE_AND_NEXT</span>
                </button>
            </div>
            <div class="col-sm-6 text-right" *ngIf="isNewBusiness">
                <button type="submit" class="btn btn-outline-primary" (click)="exportallPdf()">
                    <span class="icon ic-sm icon-print"></span>
                    <span translate>PRINT_ALL</span>
                </button>
                <button type="submit" class="btn btn-outline-primary" (click)="exportPdf()">
                    <span class="icon ic-sm icon-print"></span>
                    <span translate>PRINT_PAGE</span>
                </button>
            </div>
        </div>
    </div>
    <toaster-component></toaster-component>
    <p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
      acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
</div>
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BusinessSocialAspect } from '../models/';
import { BaseServices } from './../../kuba.services';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class BusinessSocialAspectsService {
    constructor(private http: HttpClient) { }

    gettype(id: number) {
        return this.http.get(environment.BASE_URL + '/business/socialaspectstypelist/' + id)
            .map(result => result);
    }


    deletetype(id: number) {
        return this.http.delete(environment.BASE_URL + '/business/socialaspecttypeDelete/' + id)
            .map(result => result);
    }

    listSocialAspects(id: number) {
        return this.http.get(environment.BASE_URL + '/business/socialaspect/socialaspectlist/' + id)
            .map(result => result);
    }
    listSocialAspectsType(id: number) {
        return this.http.get(environment.BASE_URL + '/business/socialaspectstypelist/' + id)
            .map(result => result);
    }
    deleteSocialAspectsType(id: number) {
        return this.http.delete(environment.BASE_URL + '/business/socialaspecttypeDelete/' + id)
            .map(result => result);
    }
    addSocialAspectsType(data: any) {
        return this.http.post<HttpHeaderResponse>(environment.BASE_URL + '/business/socialaspecttype', data, )
            .map((response: HttpHeaderResponse) => { return response });
    }
}

import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs/Subject';
import { FDVServices } from './../../FDV/services/fdv.service';
import { FoodSafetyServices } from './../../foodsafety/services/foodsafety.services';
import { InternalControlServices } from './../../internal-control/services/internal-control.service';
import { ProjectServices } from './../../projects/services/project.service';
import { VehicleServices } from './../../vehicle/services/vehicle.service';
import { TreeData, TreeView } from './../models';
import { HttpClient } from '@angular/common/http';
import { BaseServices } from '../../kuba.services';

@Injectable()
export class CommonService {
  // Observable string sources
  private isEditModeSource = new Subject<boolean>();

  // Observable string streams
  isEditMode$ = this.isEditModeSource.asObservable();

  constructor(
    private http: HttpClient,
    private projectServices: ProjectServices,
    private internalControlServices: InternalControlServices,
    private foodSafetyServices: FoodSafetyServices,
    private vehicleServices: VehicleServices,
    private fdvServices: FDVServices
  ) {}

  // Service message commands
  setMode(modeType: boolean) {
    this.isEditModeSource.next(modeType);
  }
  // getting projectname by title
  getByProjectId(id: number) {
    return this.projectServices.getProjectById(id);
  }

  // getting internalcontrolname by title
  getByICId(id: number) {
    return this.internalControlServices.getInternalControlById(id);
  }

  // getting foodsafety name by title
  getByFSId(id: number) {
    return this.foodSafetyServices.getFoodsafetyById(id);
  }
  // getting vehicle name by title
  getByVehicleId(id: number) {
    return this.vehicleServices.getVehiclesById(id);
  }
  // getting vehicle name by title
  getByFDVId(id: number) {
    return this.fdvServices.getByFDVId(id);
  }

  updateCheckNode(treeData: TreeData, treeView: TreeView) {
    if (treeView.type.toLocaleUpperCase() === 'MANUAL') {
      let res: any = this.http
        .put(
          `${environment.BASE_URL}/manual/${treeView.id}/tree/check`,
          treeData,

        )
        .map((result) => {
          return result;
        });
      return res;
    }
  }

  renameNode(treeData: TreeData, treeView: TreeView) {
    if (treeView.type.toLocaleUpperCase() === 'MANUAL') {
      let res: any = this.http
        .put(
          `${environment.BASE_URL}/manual/${treeView.id}/tree/rename`,
          treeData,

        )
        .map((result) => {
          return result;
        });
      return res;
    }
  }

  deleteNode(treeData: TreeData, treeView: TreeView) {
    if (treeView.type.toLocaleUpperCase() === 'MANUAL') {
      let res: any = this.http
        .put(
          `${environment.BASE_URL}/manual/${treeView.id}/tree/delete`,
          treeData,

        )
        .map((result) => {
          return result;
        });
      return res;
    }
  }

  addNode(treeData: TreeData, treeView: TreeView) {
    if (treeView.type.toLocaleUpperCase() === 'MANUAL') {
      let res: any = this.http
        .post(
          `${environment.BASE_URL}/manual/${treeView.id}/tree/folder`,
          treeData,

        )
        .map((result) => {
          return result;
        });
      return res;
    }
  }

  getFrameworkById(id: number) {
    let result: any;
    return this.http
      .get(environment.BASE_URL + '/framework/' + id)
      .map((res) => res);
  }
  getBySAId(Id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/serviceAgreement/get/' + Id,

      )
      .map((result) => result);
  }
}

import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CompleteCheckListService {
    constructor(private http: HttpClient) {}
    create(checkListId: number) {
        return this.http
            .post(
                `${
                    environment.BASE_URL
                }/manual/checklist/complete/save/${checkListId}`,
              {}
            )
            .map((result: any) => result);
    }
}

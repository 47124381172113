import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { BaseServices } from 'src/app/kuba/kuba.services';
import { EmployeeServices } from './../services/employee.services';
import { EmployeeFirstOfKinServices } from '../services/employeefirstofkin.service';
import { EmployeeFirstOfKin } from '../models/index';
import { HelperService, PdfSetting } from './../../../_services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'first-kin-list',
  templateUrl: 'employee-firstofkin-list.component.html',
})
export class FirstKinListComponent implements OnInit {
  //#region variables
  employeeFirstOfKinList: EmployeeFirstOfKin[];
  videoUrl: string;
  safeURL: any;
  additionalData: any;
  isVideo = false;
  private subscriptions: Subscription[] = [];
  //#endregion

  //#region constructor
  /**
   * constructor
   * @param employeeFirstOfKinServices{EmployeeFirstOfKinServices}
   * @param activatedRoute {ActivatedRoute}
   */
  constructor(
    public employeeFirstOfKinServices: EmployeeFirstOfKinServices,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {
    
    this.subscriptions.push(
      this.translate.stream('FILENAME').subscribe((val) => {
        this.additionalData = {
          fileName: val.EMPLOYEE_CONTACT_DETAILS,
          header: 'Employee contact details',
          businessId: BaseServices.BusinessId,
          cultureInfo: BaseServices.userCultureInfo(),
          columnNames: [
            { title: 'Employee Name', dataKey: 'EmployeeName' },
            { title: 'Contact Name', dataKey: 'ContactName' },
            { title: 'Mobile', dataKey: 'Mobile' },
            { title: 'Email', dataKey: 'Email' },
            { title: 'Relation', dataKey: 'Relation' },
            { title: 'Address', dataKey: 'Address' },
            { title: 'Zip/City', dataKey: 'ZipCode' },
          ],
        };
      })
    );
  }
  //#endregion

  //#region page-events
  /**
   * page load
   */
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    this.employeeFirstOfKinList = this.activatedRoute.snapshot.data['list'];
  }
  //#endregion

  //#region methods
  /**
   * print this page event
   */
  exportPdf() {
    let data = this.employeeFirstOfKinList;
    let pdfSetting = new PdfSetting();
    pdfSetting.date = 'Date:' + HelperService.formatDate(new Date());
    pdfSetting.businessName = BaseServices.BusinessName;
    pdfSetting.pageHeader = 'Contact Details';
    let columns: any = [
      { title: 'Employee Name', dataKey: 'EmployeeName' },
      { title: 'Contact Name', dataKey: 'ContactName' },
      { title: 'Mobile', dataKey: 'Mobile' },
      { title: 'Relation', dataKey: 'Relation' },
      { title: 'Address', dataKey: 'Address' },
      { title: 'Zip/City', dataKey: 'ZipCode' },
    ];
    HelperService.generatePdf(
      data,
      columns,
      'First Of Kin Details',
      pdfSetting,
      'l'
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
  //#endregion
}

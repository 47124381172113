import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AdminUserService } from './../../adminuser/services/adminuser.service';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { BusinessPortalEdit } from './../models/business-portal-edit.model';
import { BusinessPortalService } from './../services/business-portal.services';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
    selector: 'portal-details',
    templateUrl: 'portal-details.component.html'
})

export class PortalDetailsComponent implements OnInit {

    //#region variables
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    portalForm: FormGroup;
    businessPortal: BusinessPortalEdit;
    businessLogo: string;
    businessLogoUpload: any;
    public selectedFiles: any;
    uploadStart: boolean;
    uploadStartForLogo: boolean;
    isProfileSettings = false;
    portalId: number;
    uploadStartForPortalLogo: boolean;
    PortalLogo: string;
    isLabelDisabled = false;
    portalTitle = '';
    readonly = false;

    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor
    /**
     * Constructor
     * @param activatedRoute {ActivatedRoute}
     * @param portalSvc {BusinessPortalService}
     * @param fb {FormBuilder}
     * @param router {Router}
     * @param http {Http}
     */
    constructor(private activatedRoute: ActivatedRoute,
        private portalSvc: BusinessPortalService,
        private _portalSvc: BusinessPortalService,
        private fb: FormBuilder,
        private router: Router,
        private http: HttpClient,
        private adminUserService: AdminUserService
    ) {
        this.portalForm = this.fb.group({
            Title: ['', Validators.required],
            Description: ['', Validators.required],
            IsCommonPortalCourse: [true],
            BusinessListName: [''],
            IsPortalManager: [false]
        });
    }
    //#endregion
    //#region page-events
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        if (BaseServices.PortalManager !== true && +BaseServices.roleId === 2) {
            this.readonly = true;
        }
        this.businessPortal = new BusinessPortalEdit();
        let portalDetails = this.activatedRoute.snapshot.parent!.data['portalDetails'];
        this.businessLogoUpload =
            environment.BASE_URL +
            '/file/upload/business/logo/' +
            BaseServices.ApplicationId +
            '/' +
            this.portalId;

        if (portalDetails) {
            this.portalId = portalDetails.Id;
            this.businessPortal = portalDetails;
            this.businessLogo = this.businessPortal.LogoPath;
            this.PortalLogo = this.businessPortal.PortalLogo;
            this.portalTitle = this.businessPortal.Title;
            let portal = {
                Title: this.businessPortal.Title,
                Description: this.businessPortal.Description,
                IsCommonPortalCourse: this.businessPortal.IsCommonPortalCourseNames,
                BusinessListName: this.businessPortal.BusinessListName,
                IsPortalManager: this.businessPortal.IsPortalManager
            };
            (<FormGroup>this.portalForm).setValue(portal, { onlySelf: true });
        }
        if (BaseServices.roleId === '2') { // Portal
            this.isProfileSettings = true;
        }
    }
    //#endregion

    //#region control-events
    /**
     * Back to list
     */
    gotoList() {
        if (this.portalId) {
            this.router.navigate(['../../../list'], { relativeTo: this.activatedRoute });
        } else {
            this.router.navigate(['../../list'], { relativeTo: this.activatedRoute });
        }
    }
    /**
     * Add/Update the business portal
     */
    savePortal() {
        this.isLabelDisabled = false;
        this.businessPortal.Title = this.portalForm.value.Title;
        this.businessPortal.Description = this.portalForm.value.Description;
        this.businessPortal.LogoPath = this.businessLogo;
        this.businessPortal.PortalLogo = this.PortalLogo;
        this.businessPortal.IsCommonPortalCourseNames = this.portalForm.value.IsCommonPortalCourse;
        this.businessPortal.BusinessListName = this.portalForm.value.BusinessListName;
        this.businessPortal.IsPortalManager = true;
        if (this.businessPortal.Id > 0) {
            this.businessPortal.ApplicationId = BaseServices.ApplicationId;
            this.businessPortal.ModifiedBy = BaseServices.UserId;
            if (this.portalTitle !== this.businessPortal.Title) {
                this.subscriptions.push(this._portalSvc.checkPortalTilte(this.portalForm.value.Title).subscribe(isCheck => {
                    if (isCheck) {
                        this.isLabelDisabled = true;
                    } else {
                        this.portalUpdate(this.businessPortal.Id, this.businessPortal);
                    }
                }));
            } else {
                this.portalUpdate(this.businessPortal.Id, this.businessPortal);
            }
        } else {
            this.businessPortal.ApplicationId = BaseServices.ApplicationId;
            this.businessPortal.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this._portalSvc.checkPortalTilte(this.portalForm.value.Title).subscribe(isCheck => {
                if (isCheck) {
                    this.isLabelDisabled = true;
                } else {
                    this.subscriptions.push(this.portalSvc.add(this.businessPortal).subscribe((result:any) => {
                        if (result) {
                            this.toasterComponent.callToast();
                            setTimeout(() => {
                                this.router.navigate(['./../../edit', result.Id, 'user'], { relativeTo: this.activatedRoute });
                            }, 1000);
                        }
                    }));
                }
            }));
        }
    }

    /**
     *
     */
    portalUpdate(portalId: number, portalObj: any): void {
        this.subscriptions.push(this.portalSvc.update(portalId, portalObj).subscribe(result => {
            if (result) {
                this.toasterComponent.callToast();
            }
        }));
    }

    /**
     * upload for business logo
     * @param selectedFiles
     */
    filesSelectForLogo(selectedFiles: any) {
        this.uploadStartForLogo = true;
        this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(file => file);
        let formData = new FormData();
        formData.append('file', this.selectedFiles[0]);

        this.subscriptions.push(this.http
            .post(
                environment.BASE_URL +
                '/file/upload/business/logo/' +
                BaseServices.ApplicationId +
                '/' +
                this.portalId,
                formData
            )
            .map((response: any) => {
                this.uploadStartForLogo = false;
                let res = response;
                this.businessLogo = res.path;
            })
            .subscribe(
                result => { },
                error => console.log('Upload article Sign Error : ', error),
                () => console.log('Upload article  Sign Complete')
            ));
    }
    /**
         * upload for portal logo
         * @param selectedFiles
         */
    filesSelectForPortalLogo(selectedFiles: any) {
        this.uploadStartForPortalLogo = true;
        this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(file => file);
        let formData = new FormData();
        formData.append('file', this.selectedFiles[0]);

        this.subscriptions.push(this.http
            .post(
                environment.BASE_URL +
                '/file/upload/business/logo/' +
                BaseServices.ApplicationId +
                '/' +
                this.portalId,
                formData
            )
            .map((response: any) => {
                this.uploadStartForPortalLogo = false;
                let res = response;
                this.PortalLogo = res.path;
            })
            .subscribe(
                result => { },
                error => console.log('Upload portal logo error : ', error),
                () => console.log('Upload portal logo error')
            ));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }

    //#endregion
}

<div class="tab-content" *ngIf="!isMultiDocument">
  <div>
    <ecpl-document-explorer
      [uploaderConfig]="upConfig"
      [uploadedFiles]="uploadedFiles"
      (saveChanges)="saveUploaded($event)"
      (toogleEditDisplayMode)="toggleEditMode($event)"
      (showDocumentDialog)="showDocumentDailog()"
    >
    </ecpl-document-explorer>
    <div class="action-btns-wrapper">
      <button
        class="btn btn-primary"
        [ngClass]="{ loading: loading }"
        [disabled]="
          loading ||
          (newDocumentAdded?.length <= 0 && selectedArticles.length <= 0)
        "
        type="button"
        *ngIf="hasRights"
        (click)="saveDoc()"
      >
        <span translate>SAVE</span>
      </button>
      <button
        class="btn btn-primary"
        *ngIf="isEditor && hasRights"
        type="button"
        [disabled]="
          loading || (newDocumentAdded?.length <= 0 && isFileSeclected())
        "
        (click)="Approve(1)"
      >
        <span translate>SEND_APPROVAL_NOTICE</span>
      </button>
      <button
        class="btn btn-primary"
        *ngIf="isUser"
        type="button"
        [disabled]="
          loading || newDocumentAdded?.length > 0 || isFileSeclected()
        "
        (click)="Approve(3)"
      >
        <span translate>APPROVE </span>
      </button>
    </div>
  </div>
  <div class="saving-loader overlay" *ngIf="showSavingLoader">
    <div class="saving-loader-message text-center">
      <img src="/assets/icons/blocks.gif" alt="" />
      <p translate>SAVING</p>
    </div>
  </div>
</div>

<div class="tab-content" *ngIf="isMultiDocument">
  <div class="page-title">
    <span translate>{{ title }}</span>
  </div>

  <div class="table-view mb-3">
    <label for="documents" class="control-label" translate>DOCUMENTS</label>
    <!-- Table starts -->
    <p-table
      #employee
      [value]="emp"
      dataKey="EmployeeId"
      [rows]="10"
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
      expandableRows="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
      (onHeaderCheckboxToggle)="onTableHeaderCheckboxToggle()"
      [(selection)]="selectedEmployeesForApproval"
    >
      <!-- Caption -->
      <ng-template pTemplate="caption">
        <div class="ui-helper-clearfix">
          <div class="filter-controls-wrapper" style="display: block">
            <div class="row mb-2">
              <div class="col-auto">
                <label for="documentname" class="col-form-label" translate
                  >SEARCH</label
                >
              </div>
              <div class="col-sm-6">
                <input
                  type="text"
                  pInputText
                  size="50"
                  (input)="filterResults($any($event.target)?.value)"
                />
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 55px">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th style="width: 55px"></th>
          <th pSortableColumn="EmployeeName">
            {{ "NAME" | translate }}
            <p-sortIcon field="EmployeeName"></p-sortIcon>
          </th>
          <th pSortableColumn="BusinessName" *ngIf="isPortal">
            {{ "BUSINESS_NAME" | translate }}
            <p-sortIcon field="BusinessName"></p-sortIcon>
          </th>
          <th pSortableColumn="ApprovedStatus" style="width: 80px">
            {{ "STATUS" | translate }}
            <p-sortIcon field="ApprovedStatus"></p-sortIcon>
          </th>
          <th pSortableColumn="TypeOfEmployee">
            {{ "TYPE_OF_EMPLOYEE" | translate }}
            <p-sortIcon field="TypeOfEmployee"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                employee.filter(
                  $any($event.target)?.value,
                  'EmployeeName',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th *ngIf="isPortal">
          <input
              pInputText
              type="text"
              (input)="
                employee.filter(
                  $any($event.target)?.value,
                  'BusinessName',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th></th>
          <th>
            <p-dropdown
              width="118px"
              [options]="typeList"
              (onChange)="
                employee.filter($event.value, 'TypeOfEmployee', 'equals')
              "
              styleClass="p-column-filter"
              [showClear]="true"
              apendTo="body"
            >
              <ng-template let-document pTemplate="item">
                {{ document.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template
        pTemplate="body"
        let-employees
        let-expanded="expanded"
        let-i="rowIndex"
      >
        <tr>
          <td style="width: 55px">
            <p-tableCheckbox id="selectedOrders" [value]="employees">
            </p-tableCheckbox>
          </td>
          <td
            style="display: flex; justify-content: center; align-items: center"
          >
            <a
              (click)="employee.toggleRow(employees)"
              class="btn btn-icon"
              style="border: none"
            >
              <span
                class="icon ic-sm icon-expand-arrow"
                aria-hidden="true"
                *ngIf="setCollapse(i)"
                (click)="toggleClick(i)"
              ></span>
              <span
                class="icon ic-sm icon-collapse-arrow"
                aria-hidden="true"
                *ngIf="!setCollapse(i)"
                (click)="toggleClickHide(i)"
              ></span>
            </a>
          </td>

          <td>{{ employees.EmployeeName }}</td>
          <td *ngIf="isPortal">{{ employees.BusinessName }}</td>
          <td>{{ employees.ApprovedStatus }}</td>
          <td>{{ employees.TypeOfEmployee }}</td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="5">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-docs>
        <tr>
          <td colspan="5">
            <div>
              <!-- Table starts -->
              <p-table [value]="docs.EmployeeDocuments" [rows]="5">
                <!-- Header -->
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="Filename">
                      {{ "DOCUMENT_NAME" | translate }}
                      <p-sortIcon field="Filename"></p-sortIcon>
                    </th>
                    <th pSortableColumn="ApprovedOn" width="125px">
                      {{ "DATE_APPROVE" | translate }}
                      <p-sortIcon field="ApprovedOn"></p-sortIcon>
                    </th>
                    <th pSortableColumn="CreatedBy">
                      {{ "UPLOADED_BY" | translate }}
                      <p-sortIcon field="CreatedBy"></p-sortIcon>
                    </th>
                    <th width="75px"></th>
                  </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-document>
                  <tr>
                    <td>
                      <div
                        class="truncate-data"
                        style="margin-right: 0.5px; display: flex"
                        pTooltip="{{ document.Filename | translate }}"
                        tooltipPosition="top"
                      >
                        <div
                          class="thumb-xs"
                          *ngIf="
                            document.Path != null &&
                            humanizeType(getFileExtension(document.Path)) ==
                              'Image'
                          "
                        >
                          <ecpl-image-viewer
                            [imgSrc]="document.Path"
                            [imgType]="document.Path"
                            [imgName]="document.Filename"
                            [id]="document.Id"
                            thumbNail="true"
                          >
                          </ecpl-image-viewer>
                        </div>
                        <div
                          class="thumb-xs"
                          *ngIf="
                            document.Path != null &&
                            humanizeType(getFileExtension(document.Path)) ==
                              'Pdf'
                          "
                        >
                          <ecpl-pdf-viewer
                            [id]="document.Id"
                            [pdfSrc]="document.Path"
                            [pdfName]="document.Filename"
                          ></ecpl-pdf-viewer>
                        </div>
                        <div
                          class="thumb-xs"
                          *ngIf="
                            document.Path != null &&
                            humanizeType(getFileExtension(document.Path)) ==
                              'Document'
                          "
                        >
                          <ecpl-onlyoffice-viewer
                            [onlyofficeSrc]="document.Path"
                            [onlyofficeType]="getFileExtension(document.Path)"
                            [onlyofficeName]="document.Filename"
                            [onlyofficeMode]="false"
                            [id]="document.Id"
                          >
                          </ecpl-onlyoffice-viewer>
                        </div>
                        <div class="thumb-xs">
                          <img
                            src="../../../../assets/file-icons/kb-article.png"
                            *ngIf="document.IsArticle == true"
                          />
                          <img
                            src="../../../../assets/file-icons/kb-file-ppt.png"
                            *ngIf="
                              (document.IsArticle == false ||
                                document.IsArticle == null) &&
                              (getFileExtension(document.Filename) == 'ppt' ||
                                getFileExtension(document.Filename) == 'pptx')
                            "
                          />
                          <img
                            src="../../../../assets/file-icons/kb-file-general.png"
                            *ngIf="
                              (document.IsArticle == false ||
                                document.IsArticle == null) &&
                              getFileExtension(document.Filename) == 'txt'
                            "
                          />
                          <img
                            src="../../../../assets/file-icons/kb-article.png"
                            *ngIf="document.DocumentType == 'article'"
                          />
                          <img
                            src="../../../../assets/file-icons/kb-file-video.png"
                            *ngIf="document.DocumentType == 'videos'"
                          />
                        </div>
                        <div class="ml-2">
                          {{ document.Filename }}
                        </div>
                      </div>
                    </td>
                    <td>
                      <span>{{
                        document.ApprovedOn | date : "dd/MM/yyyy"
                      }}</span>
                    </td>
                    <td>
                      <span>{{ document.CreatedBy }}</span>
                    </td>
                    <td width="75px" class="col-button">
                      <button
                        (click)="deleteDoc(document.Id)"
                        type="button"
                        class="btn btn-icon"
                        title="{{ 'DELETE' | translate }}"
                      >
                        <i class="icon ic-sm icon-trash"></i>
                      </button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              <!-- Table ends -->
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>

  <div class="documentrow">
    <div>
      <button
        class="btn btn-outline-success"
        *ngIf="hasRights"
        (click)="showDocumentDailog()"
      >
        <span translate>ADD_FROM_MANUAL</span>
      </button>
      <button
        class="btn btn-outline-success"
        *ngIf="hasRights"
        (click)="showQSDocumentDailog()"
      >
        <span translate>ADD_FROM_QUALITYSYSTEMS</span>
      </button>
      <button
        class="btn btn-outline-success"
        *ngIf="hasRights"
        (click)="showOSDocumentDailog()"
      >
        <span translate>ADD_FROM_OTHERSYSTEMS</span>
      </button>
      <ecpl-document-explorer
        [uploaderConfig]="upConfigApp"
        [uploadedFiles]="uploadedFiles"
        (saveChanges)="saveUploaded($event)"
      >
      </ecpl-document-explorer>
    </div>
  </div>
  <div class="action-btns-wrapper">
    <button
      class="btn btn-primary"
      [ngClass]="{ loading: loading }"
      [disabled]="
        loading ||
        (newDocumentAdded?.length <= 0 && selectedArticles?.length <= 0) ||
        selectedEmployeesForApproval?.length <= 0
      "
      type="button"
      (click)="saveDoc()"
    >
      <span translate>SAVE</span>
    </button>
    <button
      class="btn btn-primary"
      *ngIf="isEditor || hasRights"
      type="button"
      [disabled]="loading || selectedEmployeesForApproval <= 0"
      (click)="Approve(1)"
    >
      <span translate>SEND_APPROVAL_NOTICE</span>
    </button>
  </div>
</div>
<p-dialog
  header="{{ 'UPLOAD' | translate }}"
  [(visible)]="ShowPopUp"
  overflow="inherit"
  [style]="{ width: '500px', height: '480px' }"
  [resizable]="false"
  [draggable]="false"
>
  <div class="form-group">
    <label for="appPerson">
      <span translate>SELECT_MANUAL</span>
      <span class="required">*</span>
    </label>
    <div>
      <p-dropdown
        [(ngModel)]="selectedManual"
        (ngModelChange)="SelectManual($event)"
        [options]="manualList"
        placeholder="{{ 'SELECT_A_MANUAL' | translate }}"
        [filter]="true"
        [style]="{ width: '100%' }"
      >
      </p-dropdown>
    </div>
  </div>
  <div *ngIf="dropDownAlinement" style="height: 315px"></div>
  <div
    style="overflow: scroll; overflow-x: auto; max-height: 300px"
    class="mbtm-10"
    *ngIf="showArchiveDocumentDailog"
  >
    <app-ecpl-document-tree
      *ngIf="treeNode"
      treeId="adminManualsTree"
      [(ecplTreeData)]="treeNode"
      [(ecplActiveNode)]="treeActiveNode"
      [(ecplTreeCheckbox)]="addManualsEdit"
      [addFromManuals]="addFromManuals"
      (ecplNodeEvent)="catchEvent($event)"
      [(ecplApiResponse)]="apiResponse"
    >
    </app-ecpl-document-tree>
  </div>
  <ng-template pTemplate="footer">
    <button
      [disabled]="selectedManual == null"
      class="btn btn-primary"
      type="button"
      (click)="addArticlesToPrivateDocs()"
    >
      <span translate>ADD</span>
    </button>
  </ng-template>
</p-dialog>
<p-dialog
  header="{{ 'UPLOAD' | translate }}"
  [(visible)]="QualitydocumentPopUp"
  overflow="inherit"
  [style]="{ width: '500px', height: '480px' }"
  [resizable]="false"
  [draggable]="false"
>
  <div class="form-group">
    <label for="appPerson">
      <span translate>SELECT_MANUAL</span>
      <span class="required">*</span>
    </label>
    <div>
      <p-dropdown
        [(ngModel)]="selectedManual"
        (ngModelChange)="SelectManual($event)"
        [options]="qualityList"
        placeholder="{{ 'SELECT_DOCUMNETS' | translate }}"
        [filter]="true"
        [style]="{ width: '100%' }"
      >
      </p-dropdown>
    </div>
  </div>
  <div *ngIf="dropDownAlinement" style="height: 315px"></div>
  <div
    style="overflow: scroll; overflow-x: auto; max-height: 300px"
    class="mbtm-10"
    *ngIf="showArchiveDocumentDailog"
  >
    <app-ecpl-document-tree
      *ngIf="treeNode"
      treeId="adminManualsTree"
      [(ecplTreeData)]="treeNode"
      [(ecplActiveNode)]="treeActiveNode"
      [(ecplTreeCheckbox)]="addManualsEdit"
      [addFromManuals]="addFromManuals"
      (ecplNodeEvent)="catchEvent($event)"
      [(ecplApiResponse)]="apiResponse"
    >
    </app-ecpl-document-tree>
  </div>
  <ng-template pTemplate="footer">
    <button
      [disabled]="selectedManual == null"
      class="btn btn-primary"
      type="button"
      (click)="addArticlesToPrivateDocs()"
    >
      <span translate>ADD</span>
    </button>
  </ng-template>
</p-dialog>
<p-dialog
  header="{{ 'UPLOAD' | translate }}"
  [(visible)]="otherdocumentPopUp"
  overflow="inherit"
  [style]="{ width: '500px', height: '480px' }"
  [resizable]="false"
  [draggable]="false"
>
  <div class="form-group">
    <label for="appPerson">
      <span translate>SELECT_MANUAL</span>
      <span class="required">*</span>
    </label>
    <div>
      <p-dropdown
        [(ngModel)]="selectedManual"
        (ngModelChange)="SelectManual($event)"
        [options]="otherList"
        placeholder="{{ 'SELECT_DOCUMNETS' | translate }}"
        [filter]="true"
        [style]="{ width: '100%' }"
      >
      </p-dropdown>
    </div>
  </div>
  <div *ngIf="dropDownAlinement" style="height: 315px"></div>
  <div
    style="overflow: scroll; overflow-x: auto; max-height: 300px"
    class="mbtm-10"
    *ngIf="showArchiveDocumentDailog"
  >
    <app-ecpl-document-tree
      *ngIf="treeNode"
      treeId="adminManualsTree"
      [(ecplTreeData)]="treeNode"
      [(ecplActiveNode)]="treeActiveNode"
      [(ecplTreeCheckbox)]="addManualsEdit"
      [addFromManuals]="addFromManuals"
      (ecplNodeEvent)="catchEvent($event)"
      [(ecplApiResponse)]="apiResponse"
    >
    </app-ecpl-document-tree>
  </div>
  <ng-template pTemplate="footer">
    <button
      [disabled]="selectedManual == null"
      class="btn btn-primary"
      type="button"
      (click)="addArticlesToPrivateDocs()"
    >
      <span translate>ADD</span>
    </button>
  </ng-template>
</p-dialog>
<toaster-component></toaster-component>
<div class="panel-loader" *ngIf="loading">
  <span>
    <span class="loader-icon"></span>
    <span translate>PROCESSING</span>
  </span>
</div>

import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { EmployeeServices } from './../employees/services/employee.services';
import { DepartmentDropDownResolver } from './../employees/services/employee.resolver.service';
import { VehicleEditComponent } from './components/vehicle-edit.component';
import {
  VehicleEditResolver,
  VehicleListResolver,
  TypeOfVehicleVehicleResolver,
  VehicleFeaturesResolver,
  VehicleListResolverByUserResolver,
} from './services/vehicle.resolver.service';
import { VehicleServices } from './services/vehicle.service';
import { VehicleComponent } from './vehicle.component';
import { VehicleListComponent } from './components/vehicleList.component';
import { VehicleFormComponent } from './components/vehicleForm.component';
import { VehicleViewComponent } from './components/vehicle-view.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppSharedModule } from './../shared/appShared.module';
import { RouterModule } from '@angular/router';
import { EcplTabNavigationModule } from 'src/app/shared/ecpl-tab-navigation/ecpl-tab-navigation.module';
import { VehicleHeaderListComponent } from './components/vehicle-header.component';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { MyDatePickerModule } from 'mydatepicker';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    TableModule,
    SharedModule,
    FormsModule,
    CalendarModule,
    EditorModule,
    ContextMenuModule,
    MenuModule,
    DialogModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextareaModule,
    InputTextModule,
    TranslateModule,
    AppSharedModule,
    RouterModule,
    MyDatePickerModule,
    ButtonModule,
    EcplTabNavigationModule,
    KubaExportModule,
  ],
  exports: [
    VehicleListComponent,
    VehicleFormComponent,
    VehicleEditComponent,
    VehicleViewComponent,
    VehicleHeaderListComponent,
  ],
  declarations: [
    VehicleListComponent,
    VehicleFormComponent,
    VehicleEditComponent,
    VehicleViewComponent,
    VehicleHeaderListComponent,
  ],
  providers: [
    VehicleServices,
    VehicleEditResolver,
    VehicleListResolver,
    DepartmentDropDownResolver,
    EmployeeServices,
    TypeOfVehicleVehicleResolver,
    VehicleFeaturesResolver,
    VehicleListResolverByUserResolver,
  ],
})
export class VehicleModule {}

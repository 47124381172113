import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectItem, ConfirmationService } from 'primeng/api';

import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { SubContractor } from './../models/contractor';
import { SubContractorServices } from '../services/contractor.service';
import { TranslateService } from '@ngx-translate/core';
import { UserRole } from '../../follow-ups/models/deviation';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
@Component({
    selector: 'sub-contractor',
    templateUrl: 'sub-contractor-list.component.html'
})
export class SubContractorListComponent implements OnInit {
    //#region variables
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @ViewChild('dt',{static: false}) subcontractorTable: Table;
    statuses: SelectItem[];
    showStatus: any;
    contractorTypes: SelectItem[];
    contractors: SubContractor[];
    selectedContractor: any = [];
    additionalData: any;
    guest = true;
    private subscriptions: Subscription[] = [];
    //#endregion
    //#region constructor
    /**
     * constructor
     * @param subContractorService {subContractorService}
     * @param confirmationService {confirmationService}
     * @param route {route}
     */
    constructor(
        private subContractorService: SubContractorServices,
        private confirmationService: ConfirmationService,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {
        // filter dropdown
        this.subscriptions.push(this.translate.stream('SELECT_STATUS_DROP').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
                { label: val.SELECT, value: null },
                { label: val.INACTIVE, value: 2 },
                { label: val.ACTIVE, value: 1 }
            );
        }));
        // filter the type of contractor
        this.subscriptions.push(this.translate.stream('SUB_CONTRACTOR_TYPES').subscribe(val => {
            this.contractorTypes = [];
            this.contractorTypes.push(
                { label: val.SUB_CONTRACTOR_SELECT, value: null },
                { label: val.SUB_CONTRACTOR_PERSON, value: 1 },
                { label: val.SUB_CONTRACTOR_COMPANY, value: 2 }
            );
        }));
        this.subscriptions.push(this.translate.stream('FILENAME').subscribe(val => {
        this.additionalData = {
            fileName: val.SUB_CONTRACTOR_LIST,
            header: 'Sub Contractor List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Name', dataKey: 'Name' },
                { title: 'Sub Contractor type', dataKey: 'ContractorTypeName' },
                { title: 'Mobile', dataKey: 'Mobile' },
                { title: 'Telephone', dataKey: 'Telephone' },
                { title: 'Email', dataKey: 'Email' },
                { title: 'Status', dataKey: 'Status' }
            ]
        };
    }));
    }
    //#endregion
    //#region page-event
    /**
     * page event
     */
    ngOnInit() {
        $('body').removeClass('opened');
        if (+BaseServices.roleId === UserRole.GUEST || +BaseServices.roleId === UserRole.USER) {
            this.guest = false;
        }
        this.fetchData();
    }
    //#endregion
    //#region control-event
    /**
        * status dropdown change event
        * @param e
        * @param statusDrop
        */
     onStatusChanged(e: any, statusDrop: any) {
        if (e.value) {
            // custom filter datatable
            this.subcontractorTable.filter(statusDrop.selectedOption.label, 'Status', 'equals');
        } else {
            this.subcontractorTable.reset();
        }
    }

    /**
     * client dropdown change event
     * @param e
     * @param clientDropdown
     */
    onClientChanged(e: any, clientDropdown: any) {
        if (e.value) {
            // custom filter datatable
            this.subcontractorTable.filter(clientDropdown.selectedOption.value, 'ContractorTypeId', 'equals');
        } else {
            this.subcontractorTable.reset();
        }
    }
    //#endregion
    //#region methods
    /**
     * Fetch the Data
     */
    fetchData() {
        this.contractors = [];
        let list = this.route.snapshot.data['list'];
        if (list) {
            this.contractors = list;
        }
    }

    refreshData() {
        this.contractors = [];
        this.subscriptions.push(this.subContractorService.getContractorByBusiness(BaseServices.BusinessId).subscribe(res => {
            if (res) {
                this.contractors = res;
            }

        }));
    }
    /**
         * Active and Deactivate the Status
         * @param id
         * @param status
         */
    editStatus(id: any, status: any) {
        if (status === 'ACTIVE') {
            status = '1';
        } else if (status === 'INACTIVE') {
            status = '2';
        }
        this.confirmationService.confirm({
            message: this.translate.instant('ARE_SURE_TO_PERFORM_ACTION'),
            accept: () => {
                this.subscriptions.push(this.subContractorService.statusUpdate(id, status).subscribe(() => {
                    this.refreshData();
                }));
            }
        });
    }

    /**
     * Delete the contractor
     * @param selectedContractor
     */
    deleteContractor() {
        let contractorIds: any = [];
        if (this.selectedContractor.length > 0) {
            this.selectedContractor.forEach((element: any) => {
                contractorIds.push(element.Id);
            });

            this.confirmationService.confirm({
                message: this.translate.instant('DELETE_SELECTED'),
                accept: () => {
                    this.subscriptions.push(this.subContractorService.deleteMultipleContractor(contractorIds).subscribe(() => {
                        this.refreshData();
                    }));
                    this.selectedContractor = [];
                    this.toasterComponent.callToastDlt();
                }
            });
        } else {
            alert(this.translate.instant('PLEASE_SELECT_ATLEAST_ONE_CONTRACTOR_TO_DELETE'));
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
    //#endregion
}

import { Component, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeatureManualService } from '../../feature-documents/feature-document.services';
import { UploaderConfig } from '../../../shared/ecpl-document-explorer/uploader-config';
import { environment } from 'src/environments/environment';
import { PaginationInstance } from 'ngx-pagination';
import * as _ from 'lodash';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
@Component({
    selector: 'foodsafety-cleaning-list',
    templateUrl: 'cleaning-list.component.html'
})

export class FoodSafetyCleaningListComponent implements OnInit, OnChanges {

    featureId: number;
    featureKey: number;
    treeNode: any;
    uploadedFiles: any;
    upConfig: UploaderConfig;
    nodeId: any;
    isEditMode = false;
    private subscriptions: Subscription[] = [];
    pgConfig: PaginationInstance = {
        itemsPerPage: 5,
        currentPage: 1
    };
    pageSizeList: SelectItem[] = [
        { label: 'All', value: 0 },
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '50', value: 50 },
    ];
    public selectedPageSize = this.pgConfig.itemsPerPage;
    constructor(
        private route: ActivatedRoute,
        private featureManualService: FeatureManualService
    ) {
        this.featureKey = route.snapshot.params['Id'];
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        this.featureId = workingModule.id;
        this.upConfig = {
            title: 'All Files',
            viewSwitch: false,
            editMode: false,
            windowedHeight: true,
            showUserMeta: true,
            uploaderUri: environment.BASE_URL + '/file/upload/business/manual',
            addtionalData: null,
            showDownloadAll: true
        };
    }

    ngOnInit() {
        // To hide side menu bar
        document.querySelector("body").classList.remove("opened");
        this.BindData()
    }
    ngOnChanges() {
        this.selectedPageSize = 5;
        this.pgConfig.itemsPerPage = 5;
    }

    BindData() {
        this.subscriptions.push(this.featureManualService
            .getFeatureManualTree(this.featureKey)
            .subscribe(
                (result: any) => {
                    this.treeNode = result;
                    let flattenned = this.flattenArrObj(this.treeNode);
                    const parent = _.filter(flattenned, [
                        'documentType',
                        'checklist'
                    ]);
                    this.uploadedFiles = parent[0].nodes.filter(x => x.status === 1);
                    this.upConfig.title = parent[0].filename;
                },
                (error: any) => {
                    console.error('Api Error while refreshing tree:', error);
                },
                () => {
                    console.log('Refreshing tree service complete.');
                }
            ));
    }

    saveEmitter(eventData) {
        switch (eventData.eventName) {

        }
    }

    // private
    private flattenArrObj(arr) {
        let newArr = [];
        if (Array.isArray(arr) && arr.length > 0) {
            arr.forEach(element => {
                newArr.push(element);
                if (Array.isArray(element.nodes) && element.nodes.length > 0) {
                    newArr = newArr.concat(this.flattenArrObj(element.nodes));
                }
            });
        }
        return newArr;
    }

    onPageChanged(e: any) {
        if (this.selectedPageSize === 0) {
            this.pgConfig.itemsPerPage = this.uploadedFiles.length;
            this.pgConfig.currentPage = 1;
        } else {
            this.pgConfig.itemsPerPage = this.selectedPageSize;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}

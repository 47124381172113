<div class="tab-content">
    <div>
        <!-- Document upload-->
        <ecpl-document-explorer [uploaderConfig]="upConfig" [uploadedFiles]="uploadedFiles"
          (saveChanges)="saveUploaded($event)" (toogleEditDisplayMode)="toggleEditMode($event)"
          (showDocumentDialog)="showDocumentDailog()">
        </ecpl-document-explorer>
        <div class="action-btns-wrapper">
            <button class="btn btn-primary" type="button"
              [disabled]="loading || (newDocumentAdded?.length > 0) || isFileSeclected()" (click)="Approve()">
                <span translate>APPROVE </span>
            </button>
        </div>
    </div>
    <div class="saving-loader overlay" *ngIf="showSavingLoader">
        <div class="saving-loader-message text-center">
            <img src="/assets/icons/blocks.gif" alt="">
            <p translate>SAVING</p>
        </div>
    </div>
</div>
<div class="tab-content" *ngIf="!hasRights">
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label for="documents" class="col-form-label" translate>DOCUMENTS</label>
                <!-- Table starts -->
                <p-table #employee [value]="emp" dataKey="EmployeeId" [rows]="10" [paginator]="true" [pageLinks]="3"
                  [rowsPerPageOptions]="[5,10,20]" [style]="{'width':'700px'}" expandableRows="true" [responsive]="true"
                  (onHeaderCheckboxToggle)="onTableHeaderCheckboxToggle()" [(selection)]="selectedEmployeesForApproval">
                    <!-- Caption -->
                    <ng-template pTemplate="caption">
                        <div class="ui-helper-clearfix" [style]="{'width':'700px'}">
                            <div class="filter-controls-wrapper mb-2" style="display:block">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <label for="documentname" class="col-form-label" translate>SEARCH</label>
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="text" pInputText size="50"
                                          (input)="filterResults($event.target.value)" style="width:auto">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 38px;">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <th></th>
                            <th>{{'NAME' | translate}}</th>
                            <th>{{'STATUS' | translate}}</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>
                                <input pInputText type="text"
                                  (input)="employee.filter($any($event.target)?.value, 'EmployeeName', 'contains')"
                                  placeholder="" class="p-column-filter">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-emp let-expanded="expanded" let-i="rowIndex">
                        <tr>
                            <td style="width: 55px;">
                                <p-tableCheckbox id="selectedOrders" [value]="emp">
                                </p-tableCheckbox>
                            </td>
                            <td>
                                <a (click)="employee.toggleRow(emp);toggleClick(i)" *ngIf="emp.TotalDocuments > 0"
                                  class="btn btn-icon">
                                    <span class="icon ic-sm"
                                      [ngClass]="setCollapse(i) ? 'icon-expand-arrow' : 'icon-collapse-arrow'"
                                      aria-hidden="true"></span>
                                </a>
                            </td>
                            <td>
                                <span>{{emp.EmployeeName}}</span>
                            </td>
                            <td>
                                <span [style.color]="emp.Color">{{emp.ApprovedStatus}}</span>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-emp>
                        <tr>
                            <td colspan="4">
                                <div>
                                    <!-- Table starts -->
                                    <p-table [value]="emp.ControlDocuments" [rows]="5">
                                        <!-- Header -->
                                        <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 100px;">{{'DOCUMENT_NAME' | translate}}</th>
                            <th>{{'DATE_APPROVE' | translate}}</th>
                            <th>{{'UPLOADED_BY' | translate}}</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-emps>
                        <tr>
                            <td style="width: 100px;">
                                <div class="truncate-data" pTooltip="{{emps.Filename | translate }}"
                                  tooltipPosition="top">
                                    <ecpl-image-viewer
                                      *ngIf="emps.Path != null && humanizeType(getFileExtension(emps.Path)) == 'Image'"
                                      [imgSrc]="emps.Path" [imgType]="emps.Path" [imgName]="emps.Filename"
                                      [id]="emps.Id" thumbNail=true>
                                    </ecpl-image-viewer>

                                    <ecpl-pdf-viewer width="20px"
                                      *ngIf="emps.Path != null && humanizeType(getFileExtension(emps.Path)) == 'Pdf'"
                                      [id]="emps.Id" [pdfSrc]="emps.Path" [pdfName]="emps.Filename"></ecpl-pdf-viewer>

                                    <ecpl-onlyoffice-viewer
                                      *ngIf="emps.Path != null && humanizeType(getFileExtension(emps.Path)) == 'Document' "
                                      [onlyofficeSrc]="emps.Path" [onlyofficeType]="getFileExtension(emps.Path)"
                                      [onlyofficeName]="emps.Filename" [onlyofficeMode]="false" [id]="emps.Id">
                                    </ecpl-onlyoffice-viewer>
                                </div>
                            </td>
                            <td>
                                <span>{{emps.ApprovedOn | date:'dd/MM/yyyy, h:mm a' }}</span>
                            </td>
                            <td>
                                <div class="truncate-data" pTooltip="{{emps.CreatedBy | translate }}"
                                  tooltipPosition="top"> {{emps.CreatedBy}} </div>
                            </td>
                            <td class="col-button">
                                <button (click)="deleteDoc(emps.Id)" type="button" class="btn btn-icon">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="4">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table ends -->
            </div>
            </td>
            </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="4">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
            </p-table>
            <!-- Table ends -->
        </div>
    </div>
</div>


<div class="row">
    <div class="col-sm-12">
        <div class="form-group position-relative">
            <button *ngIf="manualSelected" class="btn btn-outline-success" (click)="showDocumentDailog()">
                <span translate>ADD_FROM_MANUAL</span>
            </button>
            <button class="btn btn-outline-success" (click)="showAddDocumentDailog()">
                <span translate>ADD_FROM_DOCUMENTS</span>
            </button>
            <ecpl-document-explorer [uploaderConfig]="upConfigApp" [uploadedFiles]="uploadedFilesGrid"
              (saveChanges)="saveUploaded($event)">
            </ecpl-document-explorer>
        </div>
    </div>
</div>
<div class="action-btns-wrapper">
    <button class="btn btn-primary" [ngClass]="{'loading' : loading}"
      [disabled]="loading || (newDocumentAdded?.length <= 0 && selectedArticles?.length <=0) || selectedEmployeesForApproval?.length <= 0 "
      type="button" (click)="saveDoc()">
        <span translate>SAVE</span>
    </button>
</div>
</div>


<p-dialog header="{{'UPLOAD'| translate}}" [(visible)]="ShowPopUp" [modal]="true" 
  overflow="inherit" [style]="{width: '500px', height:'480px'}" [resizable]="false" [draggable]="false">
    <div style="margin: 0 0 10px 0;">
        <label for="appPerson">
            <span translate>SELECT_MANUAL</span>
            <span class="required">*</span>
        </label>
        <div>
            <p-dropdown [(ngModel)]="selectedManual" (ngModelChange)="SelectManual($event)" [options]="manualList"
              placeholder="{{'SELECT_A_MANUAL'|translate}}" filter="true" [style]="{'width':'100%'}"> </p-dropdown>
        </div>
    </div>
    <div *ngIf="dropDownAlinement" style="height: 315px;"></div>
    <div style="overflow: scroll; overflow-x: auto; max-height:300px" class="mbtm-10" *ngIf="showArchiveDocumentDailog">
        <app-ecpl-document-tree *ngIf="treeNode" treeId="adminManualsTree" [(ecplTreeData)]="treeNode"
          [(ecplActiveNode)]="treeActiveNode" [(ecplTreeCheckbox)]="addManualsEdit" (ecplNodeEvent)="catchEvent($event)"
          [(ecplApiResponse)]="apiResponse">
        </app-ecpl-document-tree>
        <br />
    </div>
    <button [disabled]="selectedManual == null" class="btn btn-primary" type="button"
      (click)="addArticlesToPrivateDocs()">
        <span translate>ADD</span>
    </button>
</p-dialog>


<p-dialog header="{{'UPLOAD'| translate}}" [(visible)]="ShowDocumentPopUp" [modal]="true" 
  overflow="inherit" [style]="{width: '500px', height:'480px'}" [resizable]="false" [draggable]="false">
    <div style="margin: 0 0 10px 0;">
        <label for="appPerson">
            <span translate>SELECT_DOCUMNETS</span>
            <span class="required">*</span>
        </label>

    </div>
    <div style="overflow: scroll; overflow-x: auto; max-height:300px" class="mbtm-10" *ngIf="showArchiveDocumentDailog">
        <app-ecpl-document-tree *ngIf="treeNode" treeId="adminManualsTree" [(ecplTreeData)]="treeNode"
          [(ecplActiveNode)]="treeActiveNode" [(ecplTreeCheckbox)]="addManualsEdit" (ecplNodeEvent)="catchEvent($event)"
          [(ecplApiResponse)]="apiResponse">
        </app-ecpl-document-tree>
        <br />
    </div>
    <button [disabled]="selectedManual == null" class="btn btn-primary" type="button"
      (click)="addArticlesToPrivateDocs()">
        <span translate>ADD</span>
    </button>
</p-dialog>
<toaster-component></toaster-component>
<div class="panel-loader" *ngIf="loading">
    <span>
        <span class="loader-icon"></span>
        <span translate>PROCESSING</span>
    </span>
</div>
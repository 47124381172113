import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';

@Injectable()
export class KubaBulkReportService {
    // Generate pdf report for article
    downloadAllAsZip(reportData: any, businessId: number, portalId: number, projectId: number) {
        let dynamicType = 'application/zip';
        let myData = JSON.stringify({ data: reportData });
        let url = `${environment.BASE_URL}/export-zip/${businessId}/${portalId}/${projectId}`;
        return Observable.create((observer:any) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.withCredentials = true;

            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }

    // Generate pdf report for articles - Bulk print
    generateBulkArticleReport(reportData: any, businessId: number, cultureInfo: string, portalId: number, projectId: number, manualId: number) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: reportData });
        let url = `${environment.BASE_URL}/export-bulk-article/${businessId}/${cultureInfo}/${portalId}/${projectId}/${manualId}`;
        return Observable.create((observer:any) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.withCredentials = true;
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }

    generateArticleIndex(reportData: any, businessId: number, cultureInfo: string, portalId: number, projectId: number, appManualId: number) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: reportData });
        let url = `${environment.BASE_URL}/ExportPrintIndex/${businessId}/${cultureInfo}/${portalId}/${projectId}/${appManualId}`;
        return Observable.create((observer:any) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.withCredentials = true;
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }

    // Generate pdf report for checklit
    generateChecklistReport(reportData: any, businessId: number, cultureInfo: string, projectId: number) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: reportData });
        let url = `${environment.BASE_URL}/export-checklist/${businessId}/${projectId}/${cultureInfo}`;
        return Observable.create((observer:any) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.withCredentials = true;
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }
}

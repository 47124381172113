<div class="tab-content">
    <div class="panel-tab-inner">
        <div class="table-r">
            <!-- Table starts -->
            <p-table #dt [value]="users" [rows]="10" [paginator]="true" [pageLinks]="3"
                [rowsPerPageOptions]="[5,10,20]"
                [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
                <!-- Header -->
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="Name">{{'NAME'|translate}}</th>
                        <th pSortableColumn="Username">{{'USERNAME'|translate}}</th>
                        <th>{{'ADDITIONAL_ROLE'|translate}}</th>
                        <th pSortableColumn="Mobile">{{'MOBILE'|translate}}</th>
                        <th pSortableColumn="Email">{{'EMAIL'|translate}}</th>
                        <th pSortableColumn="Name">{{'ACCOUNT_OWNER'|translate}}</th>
                        <th>{{'MODULES'|translate}}</th>
                        <th width="90px">{{'OPTIONS'| translate}}</th>
                    </tr>
                    <tr>
                        <th>
                            <input pInputText type="text"
                                (input)="dt.filter($any($event.target)?.value, 'Name', 'contains')"
                                class="p-column-filter">
                        </th>
                        <th>
                            <input pInputText type="text"
                                (input)="dt.filter($any($event.target)?.value, 'Username', 'contains')"
                                class="p-column-filter">
                        </th>
                        <th>
                            <p-dropdown width='118px' [options]="userRole"
                                (onChange)="dt.filter($event.value, 'RoleId', 'equals')" styleClass="p-column-filter"
                                placeholder="" [showClear]="true">
                                <ng-template let-userRole pTemplate="item"> {{ userRole.label | translate }}
                                </ng-template>
                            </p-dropdown>
                        </th>
                        <th>
                            <input pInputText type="text"
                                (input)="dt.filter($any($event.target)?.value, 'Mobile', 'contains')"
                                class="p-column-filter">
                        </th>
                        <th>
                            <input pInputText type="text"
                                (input)="dt.filter($any($event.target)?.value, 'Email', 'startsWith')"
                                class="p-column-filter">
                        </th>
                        <th>
                            <input pInputText type="text"
                                (input)="dt.filter($any($event.target)?.value, 'Name', 'startsWith')"
                                class="p-column-filter">
                        </th>
                        <th>
                            <p-dropdown [options]="modules" (onChange)="dt.filter($event.value, 'FeatureId', 'equals')"
                                styleClass="p-column-filter" placeholder="Select" [showClear]="true">
                                <ng-template let-modules pTemplate="item"> {{ modules.label | translate }}
                                </ng-template>
                            </p-dropdown>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-business>
                    <tr>
                        <td><span translate pTooltip="{{business.Name}}" tooltipPosition="top">{{business.Name}}</span></td>
                        <td><span translate>{{business.Username}}</span></td>
                        <td><span translate>{{business.RoleId | role | translate}}</span></td>
                        <td><span translate>{{business.Mobile}}</span></td>
                        <td><span translate>{{business.Email}}</span></td>
                        <td><span translate>{{business.Name}}</span></td>
                        <td><span pTooltip="{{business.FeatureId | module | translate}}"
                                tooltipPosition="top">{{business.FeatureId | module | translate}}</span></td>
                        <td class="col-button">
                            <a [routerLink]="['./../../user/edit',business.Id]" class="btn btn-icon"
                                title="{{'EDIT'|translate}}" routerLinkActive="active">
                                <i class="icon ic-sm icon-pencil-tip"></i>
                            </a>
                            <button type="button" class="btn btn-icon" (click)="deleteUser(business.Id)"
                                title="{{'DELETE'|translate}}">
                                <i class="icon ic-sm icon-trash"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
                <!-- Empty message -->
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td colspan="8">
                            {{'NO_RECORDS_FOUND'|translate}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <!-- Table ends -->
        </div>
    </div>
</div>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
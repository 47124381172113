import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { EmployeeWorkExperienceList } from './../models/employeeWorkExperienceList';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EmployeeWorkExperienceServices {
    constructor(private http: HttpClient) {

    }

    getAll() {
        return this.http.get(environment.BASE_URL + '/employeeworkexperiences',)
            .map(result => result);
    }
    getEmployeeWorkExperienceByEmployeeId(id: number) {
        return this.http.get(environment.BASE_URL + '/workexperiencebyemployee/' + id,)
            .map((result: any) => result);
    }
    getById(id: number) {
        return this.http.get(environment.BASE_URL + '/employeeWorkExperience/' + id, )
            .map(result => result);
    }
    add(employee: EmployeeWorkExperienceList) {
        return this.http.post(environment.BASE_URL + '/employeeworkexperience', JSON.stringify(employee), )
            .map(result => result);
    }
    update(employee: EmployeeWorkExperienceList) {
        return this.http.put(environment.BASE_URL + '/employeeworkexperience/' + employee.Id, employee,)
            .map(result => result);
    }
    deleteById(id: number) {
        return this.http.delete(environment.BASE_URL + '/employeeworkexperience/' + id,)
            .map(result => result);
    }
}

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class BusinessFeatureService {
    constructor(private http: HttpClient) {}

    getBusinessFeature(bid: any) {
        return this.http
            .get<HttpHeaderResponse>(
                environment.BASE_URL + '/business/' + bid + '/features',

            )
            .map((response: HttpHeaderResponse) => {
                return response;
            });
    }
    updateBusinessFeature(bid:any, data:any) {
        return this.http
            .put<HttpHeaderResponse>(
                environment.BASE_URL + '/business/' + bid + '/features',
                data,

            )
            .map((response: HttpHeaderResponse) => {
                return response;
            });
    }
}

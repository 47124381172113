<div class="tab-content">
    <div class="page-title">
        <span translate>LEAVE_APPLICATION</span>
    </div>
    <div [formGroup]="leaveForm">
        <div class="form-group" [hidden]="isVisible">
            <label for="lblEmployeeName" class="col-form-label">
                <span translate>STATUS</span>
            </label>
            <div *ngIf="ApproverRights">
                <label class="form-check form-check-inline" *ngFor="let option of options">
                    <input type="radio" formControlName="Status" value="{{option.id}}"
                      [checked]="option.id === leaveForm.controls['Status'].value" class="form-check-input">
                    {{option.name | translate}}
                </label>
            </div>
            <div *ngIf="!ApproverRights">
                <label class="form-check form-check-inline" *ngFor="let option of options">
                    <input type="radio" formControlName="Status" value="{{option.id}}"
                      [checked]="option.id === leaveForm.controls['Status'].value" class="form-check-input" disabled>
                    {{option.name| translate}}
                </label>
            </div>
        </div>
        <div *ngIf="!statusChangeByUser || editOwnStatus">
            <span class="text-danger" *ngIf="isLeaveAvailable" translate>{{leaveAlerttext}}</span>

            <div class="form-group" [hidden]="isGridHidden">
                <label class="checkbox">
                    <p-checkbox label="{{'SHOW_WEEKEND' | translate}}" [binary]="true" [(ngModel)]="IsShowWeekend"
                      [ngModelOptions]="{standalone: true}" (onChange)="changeSettings()"></p-checkbox>
                </label>
            </div>
            <div class="form-group-50_50">
                <div class="form-group">
                    <label class="form-col-form-label">
                        <span translate>FROM_DATE</span>
                        <span class="required">*</span>
                    </label>
                    <span *ngIf="hasChildren">
                        <i class="pi pi-info-circle" aria-hidden="true" tooltip="This is driven by sub-activities"></i>
                    </span>
                    <div>
                        <div>
                            <my-date-picker name="startdate" [options]="startDateOptions" formControlName="startDate"
                              placeholder="{{'SELECT_START_DATE'|translate}}" required
                              (dateChanged)="onStartDateChanged($event)"></my-date-picker>
                            <control-messages [control]="leaveForm.controls['startDate']"></control-messages>
                        </div>
                        <span style="color:red" *ngIf="isFromDateGreaterThanEndDate"
                          translate>FROMDATE_GREATERTHAN_ENDDATE</span>
                    </div>
                </div>
                <div class="form-group" [hidden]="isHiddenEndDate">
                    <label class="form-col-form-label">
                        <span translate>END_DATE</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <my-date-picker name="enddate" [options]="endDateOptions" formControlName="endDate"
                          placeholder="{{'SELECT_END_DATE'|translate}}" (dateChanged)="onEndDateChanged($event)"
                          required></my-date-picker>
                        <control-messages [control]="leaveForm.controls['endDate']"></control-messages>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="percentage">
                    <span translate>YEAR</span>
                </label>
                <div>
                    <p-dropdown formControlName="Year" (onChange)="onYearChange($event)" id="Type" [options]="Year"
                      [style]="{'width':'100%'}"></p-dropdown>
                </div>
            </div>
            <div class="form-group">
                <label for="nDays">
                    <span translate>NUMBER_DAYS</span>
                </label>
                <div>
                    <input type="text" pInputText formControlName="NoOfDays" readonly>
                </div>
            </div>
            <div class="form-group">
                <label for="Availed">
                    <span translate>ALLOCATED_DAYS</span>
                </label>
                <control-messages [control]="leaveForm.controls['Availed']"></control-messages>
                <div>
                    <input type="text" pInputText readonly formControlName="LeaveToBeAvailed">
                </div>
            </div>
            <div class="form-group">
                <label for="Taken">
                    <span translate>LEAVE_TAKEN</span>
                </label>
                <control-messages [control]="leaveForm.controls['Taken']"></control-messages>
                <div>
                    <input type="text" pInputText formControlName="LeaveTaken" readonly>
                </div>
            </div>
            <div class="form-group">
                <label for="AvailableLeave">
                    <span translate>AVAILABLE_LEAVE</span>
                </label>
                <div>
                    <input type="text" pInputText formControlName='AvailableLeave' readonly>
                    <span style="color:red" *ngIf="isLeaveGreaterThanNoofDays"
                      translate>TOTALDAYS_GREATERTHAN_AVAILABLEDAYS</span>
                    <span *ngIf="isNoAvailableLeave" style="color:red" translate>NO_AVAILABLE_LEAVE</span>
                </div>
                <control-messages [control]="leaveForm.controls['AvailableLeave']"></control-messages>
            </div>
            <div class="form-group" *ngIf="workingModule.id == 7 || workingModule.id == 8">
                <label for="appPerson">
                    <span translate>APPROVAL_PERSON</span>
                    <span class="required">*</span>
                </label>
                <div>
                    <p-multiSelect [options]="approvalPersons | dropdownSelectTranslate" [style]="{'width':'100%'}"
                      formControlName="ApprovalPersonList">
                    </p-multiSelect>
                </div>
                <control-messages [control]="leaveForm.controls['ApprovalPersonList']"></control-messages>
            </div>
            <div class="form-group" *ngIf="workingModule.id != 7 && workingModule.id != 8">
                <label for="appPerson">
                    <span translate>APPROVAL_PERSON</span>
                    <span class="required">*</span>
                </label>
                <div>
                    <p-dropdown formControlName="ApprovalPerson" [options]="approvalPersons | dropdownSelectTranslate"
                      [style]="{'width':'100%'}" [disabled]="isApprovalPerson">
                    </p-dropdown>
                </div>
                <control-messages [control]="leaveForm.controls['ApprovalPersonList']"></control-messages>
            </div>
            <div class="form-group">
                <label for="Desc">
                    <span translate>DESCRIPTION</span>
                </label>
                <control-messages [control]="leaveForm.controls['Description']"></control-messages>
                <div>
                    <textarea pInputTextarea formControlName="Description"></textarea>
                </div>
            </div>
        </div>
        <div *ngIf="statusChangeByUser">
            <span style="color:red" *ngIf="isLeaveAvailable">{{leaveAlerttext}}</span>
            <div class="form-group" [hidden]="isGridHidden">
                <label class="checkbox" for="Show Weekend">
                    <p-checkbox [disabled]="true" label="{{'SHOW_WEEKEND' | translate}}" [binary]="true"
                      [(ngModel)]="IsShowWeekend" [ngModelOptions]="{standalone: true}" (onChange)="changeSettings()">
                    </p-checkbox>
                </label>
            </div>
            <div class="form-group-50_50">
                <div class="form-group">
                    <label class="form-col-form-label">
                        <span translate>FROM_DATE</span>
                        <span class="required">*</span>
                    </label>
                    <span *ngIf="hasChildren">
                        <i class="pi pi-info-circle" aria-hidden="true" tooltip="This is driven by sub-activities"></i>
                    </span>
                    <div>
                        <div>
                            <my-date-picker [disabled]="!ApproverRights" name="startdate" [options]="startDateOptions"
                              formControlName="startDate" placeholder="{{'SELECT_START_DATE'|translate}}" required
                              (dateChanged)="onStartDateChanged($event)"></my-date-picker>
                            <control-messages [control]="leaveForm.controls['startDate']"></control-messages>
                        </div>
                        <control-messages [control]="leaveForm.controls['startDate']"></control-messages>
                    </div>
                </div>
                <div class="form-group" [hidden]="isHiddenEndDate">
                    <label class="form-col-form-label">
                        <span translate>END_DATE</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <my-date-picker [disabled]="!ApproverRights" name="enddate" [options]="endDateOptions"
                          formControlName="endDate" placeholder="{{'SELECT_END_DATE'|translate}}"
                          (dateChanged)="onEndDateChanged($event)" required></my-date-picker>
                        <control-messages [control]="leaveForm.controls['endDate']"></control-messages>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="percentage">
                    <span translate>YEAR</span>
                </label>
                <div>
                    <p-dropdown [filter]="true" [disabled]="isLeaveExist" formControlName="Year" id="Type"
                      [options]="Year" [style]="{'width':'100%'}"></p-dropdown>
                </div>
            </div>
            <div class="form-group">
                <label for="nDays">
                    <span translate>NUMBER_DAYS</span>
                </label>
                <div>
                    <input type="text" pInputText formControlName="NoOfDays" readonly>
                </div>
            </div>
            <div class="form-group">
                <label for="Availed">
                    <span translate>ALLOCATED_DAYS</span>
                </label>
                <control-messages [control]="leaveForm.controls['Availed']"></control-messages>
                <div>
                    <input type="text" pInputText readonly formControlName="LeaveToBeAvailed">
                </div>
            </div>
            <div class="form-group">
                <label for="Taken">
                    <span translate>LEAVE_TAKEN</span>
                </label>
                <control-messages [control]="leaveForm.controls['Taken']"></control-messages>
                <div>
                    <input type="text" pInputText formControlName="LeaveTaken" readonly>
                </div>
            </div>
            <div class="form-group">
                <label for="Leave">
                    <span translate>AVAILABLE_LEAVE</span>
                </label>
                <control-messages [control]="leaveForm.controls['Leave']"></control-messages>
                <div>
                    <input type="text" pInputText formControlName='AvailableLeave' readonly>
                </div>
            </div>
            <div class="form-group">
                <label for="appPerson">
                    <span translate>APPROVAL_PERSON</span>
                    <span class="required">*</span>
                </label>
                <control-messages [control]="leaveForm.controls['ApprovalPersonList']"></control-messages>
                <div>
                    <p-multiSelect [disabled]="isApprovalPerson1" [options]="approvalPersons" [style]="{'width':'100%'}"
                      formControlName="ApprovalPersonList">
                    </p-multiSelect>
                </div>
            </div>
            <div class="form-group">
                <label for="Desc">
                    <span translate>DESCRIPTION</span>
                </label>
                <control-messages [control]="leaveForm.controls['Description']"></control-messages>
                <div>
                    <textarea pInputTextarea formControlName="Description"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="action-btns-wrapper">
        <div [hidden]="isHidden">
            <button class="btn btn-outline-secondary" type="submit" (click)="onClear()">
                <span translate>CLEAR</span>
            </button>
            <button class="btn btn-primary" type="submit" [ngClass]="{'loading' : loading}" (click)="onSubmit('SAVE')"
              [disabled]="!leaveForm.valid || loading || isLeaveGreaterThanNoofDays || isFromDateGreaterThanEndDate">
                <span *ngIf="!loading" translate>SAVE</span>
                <span *ngIf="loading" translate>SAVE_PROCESS</span>
                <span *ngIf="loading" class="loader-icon"></span>
            </button>
        </div>
        <div [hidden]="isVisible">
            <button class="btn btn-outline-secondary" type="submit" (click)="gotoList()">
                <span class="icon ic-xs icon-back"></span>
                <span translate>BACK_LIST</span>
            </button>
            <button *ngIf="!ApproverRights" class="btn btn-primary" type="submit" (click)="onSubmit('SAVEANDSEND')"
              [disabled]="!leaveSaveByUser">
                <span translate>SAVE_AND_SEND</span>
            </button>
            <button *ngIf="ApproverRights" class="btn btn-primary" type="submit" (click)="onSubmit('SAVEANDSEND')">
                <span translate>SAVE_AND_SEND</span>
            </button>
        </div>
        <div [hidden]="isMypage">
            <button class="btn btn-primary" type="submit" (click)="onSubmit('SAVEANDSEND')"
              [disabled]="!leaveForm.valid || isLeaveGreaterThanNoofDays || isFromDateGreaterThanEndDate">
                <span translate>SAVE_AND_SEND</span>
            </button>
            <button class="btn btn-outline-secondary" type="submit" (click)="onClear()">
                <span translate>CLEAR</span>
            </button>
        </div>
    </div>
    <div class="kb-progress-bar" *ngIf="loading">
        <div class="kb-progress"></div>
    </div>
    <div class="table-view" [hidden]="isGridHidden">

        <!-- angular 8 to change start -->
        <!-- Table starts -->
        <p-table [value]="employeeLeave" [rows]="10" #dt [paginator]="true" [pageLinks]="3"
            [rowsPerPageOptions]="[5,10,20]"
            [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
            <!-- Caption -->
            <ng-template pTemplate="caption">
                <div class="ui-helper-clearfix">
                    <div class="table-options">
                        <div class="pull-right">
                            <kuba-export [reportData]="dt.filteredValue || dt.value"
                              [additionalData]="additionalData"></kuba-export>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="EmployeeName">{{'EMPLOYEE_NAME'|translate}} <p-sortIcon
                          field="EmployeeName"></p-sortIcon></th>
                    <th pSortableColumn="StartDate" width='165px'>{{'FROM_DATE'|translate}} <p-sortIcon
                          field="StartDate"></p-sortIcon></th>
                    <th pSortableColumn="EndDate" width='110px'>{{'END_DATE'|translate}} <p-sortIcon
                          field="EndDate"></p-sortIcon></th>
                    <th pSortableColumn="NoOfDays">{{'NUMBER_DAYS'|translate}} <p-sortIcon
                          field="NoOfDays"></p-sortIcon></th>
                    <th pSortableColumn="StatusText">{{'STATUS'|translate}} <p-sortIcon field="StatusText"></p-sortIcon>
                    </th>
                    <th pSortableColumn="ApprovalPersonNameList">{{'APPROVAL_PERSON'|translate}} <p-sortIcon
                          field="ApprovalPersonNameList"></p-sortIcon></th>
                    <th width='75px'>{{'OPTIONS'| translate}}</th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-leave>
                <tr>
                    <td><span translate>{{leave.EmployeeName}}</span></td>
                    <td><span translate>{{leave.StartDate | date : 'dd/MM/yyyy' }}</span></td>
                    <td><span translate>{{leave.EndDate | date : 'dd/MM/yyyy' }}</span></td>
                    <td><span translate>{{leave.NoOfDays}}</span></td>
                    <td><span translate>{{leave.StatusText}}</span></td>
                    <td><span translate>{{leave.ApprovalPersonNameList}}</span></td>
                    <td>
                        <a (click)="editLeave(leave)" *ngIf="leave.Status===8" class="btn btn-icon"
                          title="{{'EDIT' | translate}}" routerLinkActive="active">
                            <i class="icon ic-sm icon-pencil-tip"></i>
                        </a>

                        <button type="button" (click)="delete(leave['Id'])"
                          *ngIf="((leave.Status===8 || leave.Status===10) && isUser===true) || (isNotUser)"
                          class="btn btn-icon" title="{{'DELETE' | translate}}">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="7">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <!-- angular 8 to change end -->
    </div>
</div>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<toaster-component></toaster-component>
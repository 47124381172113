import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/Observable';

import { BaseServices } from './../../kuba.services';
import { ManualLAR } from './../models';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ManualLARService {
    constructor(private http: HttpClient) {}

    getManualLAR(
        manualId: number,
        levelId: number,
        levelKey: number
    ): Observable<ManualLAR[]> {
        return this.http
            .get<any>(
                `${
                    environment.BASE_URL
                }/manual/${manualId}/law-regulations/${levelId}/${levelKey}`,

            )
            .map(result => result);
    }

    add(manualLAR: ManualLAR) {
        return this.http
            .post(
                `${environment.BASE_URL}/manual/${
                    manualLAR.AppManualId
                }/law-regulations/add`,
                manualLAR,

            )
            .map(result => {
                return result;
            });
    }

    update(Id: number, manualLAR: ManualLAR) {
        return this.http
            .put(
                `${environment.BASE_URL}/manual/${
                    manualLAR.AppManualId
                }/law-regulations/update/${Id}`,
                manualLAR,

            )
            .map(result => {
                return result;
            });
    }

    delete(Id: number, manualLAR: ManualLAR) {
        return this.http
            .delete(
                `${environment.BASE_URL}/manual/${
                    manualLAR.AppManualId
                }/law-regulations/delete/${Id}`,

            )
            .map(result => {
                return result;
            });
    }
}

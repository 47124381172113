
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { HelperService } from '../../../_services/helper.service';
import { ModuleOverview } from '../models/moduleoverview';
import { ModuleOverviewServices } from '../services/module-overview.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { DateSetting } from '../../../_helpers/date-setting';
import { TranslateService } from '@ngx-translate/core'
import { Feature } from 'src/app/_models';
import { Subscription } from 'rxjs';
@Component({
    selector: 'CreateModuleOverview',
    templateUrl: 'Createmodule-overview.component.html'
})
export class CreateModuleOverviewComponent extends DateSetting implements OnInit {
    //#region variables

    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    overviewForm: FormGroup;
    IsHidden = true;
    moduleInfo: any;
    moduleId: number;
    moduleoverviewId: number;
    moduleData = new ModuleOverview();
    createdOn: string;
    locale: string;
    isAdmin: boolean;
    IsFeatureName = false;
    featureName: any;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor

    /**
     * constructor
     * @param formBuilder {FormBuilder}
     * @param newsServices {NewsServices}
     * @param activatedRoute {ActivatedRoute}
     */
    constructor(
        private formBuilder: FormBuilder,
        public moduleoverviewServices: ModuleOverviewServices,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        protected location: Location,
        private translate: TranslateService
    ) {
        super();
    }

    //#endregion

    //#region page-event

    /**
     * init
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.overviewForm = this.formBuilder.group({
            FeatureName:['',Validators.required],
            Description: ['']
        });
        let moduleoverview = [];
        moduleoverview = JSON.parse(sessionStorage.getItem('FeatureOverviewDropdown'));
        if (moduleoverview) {
            sessionStorage.setItem('FeatureOverviewDropdown', JSON.stringify(moduleoverview));
            this.featureName = [];
            this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                this.featureName.push(
                    { label: val.SELECT, value: null }
                );
                moduleoverview.forEach((listData: any) => {
                    this.featureName.push({ label: this.translate.instant(listData.TranslationKey), value: listData.FeatureId });
                });
            }));
            
        }
        this.subscriptions.push(this.activatedRoute.params.subscribe((params: Params) => {
            let nid = params['id'];
            if (this.router.url.indexOf('admin') > 0) {
                if (+BaseServices.roleId === 3) {
                    this.isAdmin = false;
                } else {
                    this.isAdmin = true;
                }
            } else {
                this.isAdmin = true;
            }
            this.moduleId = +nid;
            if (this.moduleId !== 0) {
                this.subscriptions.push(this.moduleoverviewServices.get(this.moduleId).subscribe(moduleInfo => {
                    if (moduleInfo) {
                        this.IsFeatureName = true;
                        this.createdOn = moduleInfo.CreatedOn;
                        this.moduleData = moduleInfo;
                        this.moduleInfo = {
                            FeatureName: moduleInfo.FeatureId ,
                            Description: moduleInfo.Description ,
                            };
                        (<FormGroup>this.overviewForm).setValue(this.moduleInfo, {
                            onlySelf: true
                        });
                     
                    }
                }));
            }
        }));
    }

    //#endregion

    //#region control-event

    /**
    * back to the list
    */
    gotoList() {
        this.location.back();
    }
    /**
     * save news
     */
    Save() {
        let editmoduleId = this.moduleId;
            this.moduleData.Description = this.overviewForm.value.Description;
            this.moduleData.FeatureId = this.overviewForm.value.FeatureName;
            this.moduleData.ApplicationId = BaseServices.ApplicationId;
            this.moduleData.CreatedOn = this.createdOn;
           if (editmoduleId > 0) {
            this.subscriptions.push(this.moduleoverviewServices
                .update(editmoduleId, this.moduleData)
                .subscribe(isModuleUpdated => {
                    if (isModuleUpdated) {
                        this.toasterComponent.callToast();
                    }
                }));
        } else {
            this.moduleData.CreatedOn = HelperService.formatDate(new Date());
            this.moduleData.Id = 0;
            this.moduleData.Status = '1';
            this.subscriptions.push(this.moduleoverviewServices.add(this.moduleData).subscribe(isModuleAdded => {
                if (isModuleAdded) {
                    this.toasterComponent.callToast();
                }
            }));
        }
        this.gotoList();
    }

    reset() {
        this.moduleId = 0;
        this.overviewForm.reset();
    }
    //#endregion

    //#region method

    onFeatureChanged(event) {
            this.moduleId = event.value;
            this.subscriptions.push(this.moduleoverviewServices.getByModuleId(this.moduleId).subscribe((moduleInfo: any) => {
                if (moduleInfo) {
                    this.createdOn = moduleInfo.CreatedOn;
                    this.moduleoverviewId = moduleInfo.Id;
                    this.moduleData = moduleInfo;
                    this.moduleInfo = {
                        FeatureName: moduleInfo.FeatureId ,
                        Description: moduleInfo.Description ,
                        };
                    (<FormGroup>this.overviewForm).setValue(this.moduleInfo, {
                        onlySelf: true
                    });
                 
                }
            }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}

import { Route } from '@angular/router';
import { FeatureComponent } from './components/feature.component';
import { ApplicationListComponent } from './components/applicationList.component';
export const applicationRoutes: Route[] = [
    {
        path: '',
        component: ApplicationListComponent,
        children: [
            {
                path: 'application-list',
                component: ApplicationListComponent
            },
            {
                path: 'feature',
                component: FeatureComponent
            }

        ]
    }
];
import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomizeHomePageService } from './../../customize-homepage/customize-homepage.service';
import { ConfirmationService } from 'primeng/api';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { InternalTv } from './../models/businessEdit';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import {
    UploadFile,
    UploadInput,
    UploaderOptions
} from 'ngx-uploader';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { BusinessInternalTVService } from '../services/businessInternalTV.serivce';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
    selector: 'business-internal-tv',
    templateUrl: 'business-internal-tv.component.html'
})
export class BusinessInternalTvComponent implements OnInit {
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    upConfig: UploaderConfig;
    uploadedFiles: any = [];
    additionalData: any;
    newDocumentAdded: any = [];
    businessId: number;
    uploadFile = new InternalTv();
    loading = false;
    files: UploadFile[] = [];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: Function;
    dragOver: boolean;
    options: UploaderOptions;
    businessVideo: string;
    isFileChoosed = false;
    public selectedFiles: any;
    uploadStart: boolean;
    isDefaultVideo: boolean;
    applicationId: number;
    videoId: number;
    defaultVideoTv: string;
    private subscriptions: Subscription[] = [];
    constructor(
        public internalTvService: BusinessInternalTVService,
        public route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private http: HttpClient,
        private translate: TranslateService
    ) {
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        let defaultVideo = <any>this.route.snapshot.data['defaultVideo'];
        if (defaultVideo != null) {
            this.defaultVideoTv = defaultVideo.ContentData;
        }
        let video = this.route.snapshot.data['video'];
        if (video) {
            this.businessVideo = video.Path;
            this.videoId = video.Id;
            this.isDefaultVideo = false;
        } else {
            this.businessVideo = this.defaultVideoTv;
            this.isDefaultVideo = true;
        }
        this.uploadInput = new EventEmitter<UploadInput>();
        this.businessId = +this.route.parent?.snapshot.params['bid'];
        this.applicationId = BaseServices.ApplicationId;
        this.additionalData = {
            ApplicationId: BaseServices.ApplicationId,
            BusinessId: this.businessId ? this.businessId : 0,
            Status: '1',
            CreatedOn: new Date(),
            ModifiedBy: BaseServices.UserId,
            ModifiedOn: new Date()
        };
        this.upConfig = {
            title: 'Upload Video',
            titleAsLabel: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showSearchFilter: false,
            showUploadButton: true,
            uploaderUri:
                environment.BASE_URL +
                '/internaltv/file/upload/business/internal-tv',
            addtionalData: this.additionalData
        };
    }
    filesSelect(selectedFiles: any): void {
        this.businessVideo = '';
        this.uploadStart = true;
        this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(file => file);
        let formData = new FormData();
        formData.append('file', this.selectedFiles[0]);
        let session = JSON.parse(sessionStorage.getItem('session'));
        let token = session.AccessToken;
        var headers = {
            'Authorization': 'Bearer ' + token, // Add JWT token to headers
            // Add any other headers if needed
        };

        this.subscriptions.push(this.http
            .post(
                environment.BASE_URL +
                '/internaltv/file/upload/business/internal-tv/' +
                BaseServices.ApplicationId +
                '/' +
                this.businessId,
                formData,{headers:headers}
            )
            .map((response: any) => {
                this.uploadStart = false;
                let res = response;
                this.businessVideo = res.path;
                this.isDefaultVideo = false;
            })
            .subscribe(
            result => { },
            error => console.log('Upload article Sign Error : ', error),
            () => console.log('Upload article  Sign Complete')
            ));
    }


    saveChanges() {
        this.loading = true;
        this.uploadFile.BusinessId = this.businessId;
        this.uploadFile.Path = this.businessVideo;
        this.uploadFile.Id = this.videoId ? this.videoId : 0;
        if (this.uploadFile.Id) {
            this.subscriptions.push(this.internalTvService.update(this.uploadFile).subscribe(video => {
                if (video) {
                    this.toasterComponent.callToast();
                    this.isDefaultVideo = false;
                    this.loading = false;
                }
            }));
        } else {
            this.subscriptions.push(this.internalTvService.add(this.uploadFile).subscribe(video => {
                if (video) {
                    this.toasterComponent.callToast();
                    this.isDefaultVideo = false;
                }
            }));
        }
    }

    /**
     * delete video
     * @param videoId {number}
     */
    deleteVideo(videoId: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.businessVideo = '';
                this.subscriptions.push(this.internalTvService
                    .deleteInternalTvVideo(videoId)
                    .subscribe(isVideoDeleted => {
                        if (isVideoDeleted) {
                            this.toasterComponent.callToast();
                            this.businessVideo = this.defaultVideoTv;
                            this.isDefaultVideo = true;
                        }
                    }));
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}

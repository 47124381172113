import { FeaturesBaseServices } from './../features/services/features-base.services';
import { MyPageGuard } from './../../_guards/auth.guard';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EcplArticleViewerModule } from 'src/app/shared/ecpl-article-viewer';
import { EcplImageViewerModule } from 'src/app/shared/ecpl-image-viewer';
import { EcplOnlyofficeViewerModule } from 'src/app/shared/ecpl-onlyoffice-viewer';
import { EcplPdfViewerModule } from 'src/app/shared/ecpl-pdf-viewer';
import { TimetableServices } from './../time-table/services/timetable.service';

import {
  TimeTableListResolver,
  TimeTableEditResolver,
} from './../time-table/services/timetable.resolver.service';
import { EcplDocumentExplorerModule } from 'src/app/shared/ecpl-document-explorer';
import { ApprovalPersonResolver } from './../employees/services/employeeleave.resolver.service';
import { ToasterModule } from 'angular2-toaster';

import { MypageCommonDetailsComponent } from './components/common-details/mypage-common-details.component';
import { EmployeeServices } from './../employees/services/employee.services';
import { EmployeeFirstOfKinServices } from './../employees/services/employeefirstofkin.service';

import { EmployeePrivateDocumentServices } from './../employees/services/employeeprivatedocument.service';
import { EmployeeWorkExperienceServices } from './../employees/services/employeeworkexperience.service';
import { EmployeeWorkExperienceResolver } from './../employees/services/employeeWorkExperience.resolver.service';
import { MyPageCourseDetailComponent } from './components/coursedetails/mypage-coursedetails.component';
import { EmployeeLeaveService } from './../employees/services/employeeleave.service';
import { MyPageUserDetailResolver } from './services/common-details.resolver.service';
import { MyPageTimetableListComponent } from './components/timetable-list/mypage-timetable-list.component';
import {
  EmployeeEditResolver,
  EmployeeListResolver,
  BusinessCourseResolver,
  BusinessContractResolver,
} from './../employees/services/employee.resolver.service';
import { EmployeeCourseServices } from './../employees/services/employeecourse.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppSharedModule } from './../shared/appShared.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {
  FunctionDropdownResolver,
  CommonDetailsEditResolver,
  MyPageTimeTableResolver,
} from 'src/app/kuba/my-page/services/common-details.resolver.service';
import { DashboardCalendarActivityResolver } from './../dashboard/Services/dashboard.resolver.service';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SharedModule } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import { FullCalendarModule } from '@fullcalendar/angular';
@NgModule({
  imports: [
    AppSharedModule,
    RouterModule,
    TranslateModule,
    ConfirmDialogModule,
    TableModule,
    SharedModule,
    DialogModule,
    FileUploadModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BsDatepickerModule,
    ToasterModule,
    EcplPdfViewerModule,
    EcplOnlyofficeViewerModule,
    EcplImageViewerModule,
    EcplArticleViewerModule,
    EcplDocumentExplorerModule,
    CKEditorModule,
    FullCalendarModule,
  ],
  exports: [
    MyPageTimetableListComponent,
    MyPageCourseDetailComponent,
    MypageCommonDetailsComponent,
  ],
  declarations: [
    MyPageTimetableListComponent,
    MyPageCourseDetailComponent,
    MypageCommonDetailsComponent,
  ],
  providers: [
    EmployeeCourseServices,
    EmployeeEditResolver,
    EmployeeLeaveService,
    EmployeeWorkExperienceResolver,
    EmployeeWorkExperienceServices,
    EmployeePrivateDocumentServices,
    TimeTableListResolver,
    TimeTableEditResolver,
    TimetableServices,
    EmployeeFirstOfKinServices,
    EmployeeListResolver,
    EmployeeServices,
    BusinessCourseResolver,
    BusinessContractResolver,
    ApprovalPersonResolver,
    FunctionDropdownResolver,
    CommonDetailsEditResolver,
    MyPageTimeTableResolver,
    DashboardCalendarActivityResolver,
    MyPageUserDetailResolver,
    MyPageGuard,
    FeaturesBaseServices,
  ],
})
export class MyPageModule {}

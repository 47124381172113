import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BusinessEquipmentType } from '../models/';
import { BaseServices } from './../../kuba.services';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class BusinessInternalTVService {
  item: Object;
  constructor(private http: HttpClient) {}

  //add employee to list
  add(data: any) {
    let body = JSON.stringify(data);
    return this.http
      .post(
        environment.BASE_URL + '/internaltv/internaltv',
        body,

      )
      .map((result) => result);
  }

  update(data: any) {
    let body = JSON.stringify(data);
    return this.http
      .put(
        environment.BASE_URL + '/internaltv/update',
        body,

      )
      .map((result) => result);
  }

  getVideo(businessId: number) {
    return this.http
      .get<any>(
        environment.BASE_URL + '/internaltv/getall/' + businessId,

      )
      .map((result) => result);
  }

  deleteInternalTvVideo(Id: any) {
    return this.http
      .put(
        environment.BASE_URL + '/internaltv/deletevideo/' + Id,
        {}
      )
      .map((result) => result);
  }
  private extractData(res: any) {
    let body = res;

    return <BusinessEquipmentType[]>res;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}

import { BaseServices } from './../../kuba.services';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class CrewListServices {

    constructor(private http: HttpClient) { }

    getCrewList(id: number, key: string) {
        let bid = BaseServices.BusinessId;
        let appId = BaseServices.ApplicationId;
        return this.http.get(`${environment.BASE_URL}/${BaseServices.apiRoute(key)}/crewlist/list/${id}/
        ${bid}/${appId}`, )
            .map((result: any) => result);
    }

    getCrewListHistroy(id: number, key: string) {
        let bid = BaseServices.BusinessId;
        let appId = BaseServices.ApplicationId;
        return this.http.get(`${environment.BASE_URL}/${BaseServices.apiRoute(key)}/crewlistHistroy/list/${id}/
        ${bid}/${appId}`, )
            .map((result: any) => result);
    }

    getProjectUsers(projectId: number) {
        return this.http.get(environment.BASE_URL + '/project/projectUser/list/' + projectId, )
            .map((result: any) => result);
    }

    get(id: number, key: string) {
        return this.http.get(`${environment.BASE_URL}/${BaseServices.apiRoute(key)}/history/list/${id}`, )
            .map(result => result);
    }

    getProjectInfo(fkey: number, fname: string) {
        let fId;
        if (fname === 'FS') {
            fId = 20;
        } else {
            fId = 16;
        }
        return this.http.get(environment.BASE_URL + '/project/projectInfo/' + fkey + '/' + fId, )
            .map((result:any) => result);
    }
}

//#region import
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BaseServices } from './../../../kuba.services';
import { CMPComment } from '../../models/mpc-details';
import { MeasureProtocolService } from '../../services/measure-protocol.services';
import { Subscription } from 'rxjs';
//#endregion

@Component({
    selector: 'measure-protocol-comments',
    templateUrl: 'measure-protocol-comments.component.html'
})

export class MeasureProtocolCommentsComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    measureProtocolComments: FormGroup;
    cmpId: number;

    cmpCommentId: any;
    cmpComment: any;
    sign: any;
    noteDetail: any;
    createdOn: any;
    createdBy: any;
    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private location: Location,
        public measureprotocolservice: MeasureProtocolService, ) {
        this.measureProtocolComments = this.fb.group({
            'Comments': [''],
        });
    }

    ngOnInit(): void{
        document.querySelector("body").classList.remove("opened");
        this.cmpId = this.route.snapshot.parent.params['mpcid'];
        this.editCMPComment();
    }

    /**
     * get signature
     */
    signature() {
        let today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        let dateTime = date + ' ' + time;
        let note = this.measureProtocolComments.value.Comments ? this.measureProtocolComments.value.Comments : '';
        this.sign = ` ${note} ${BaseServices.userName} ${dateTime}`;
        if (this.noteDetail) {
            this.sign = `  ${note}   ${BaseServices.userName} ${dateTime}`;
        }
    }

    saveChanges(){
        let cmpcomment = new CMPComment();
        cmpcomment.Id =  (this.cmpCommentId) ? this.cmpCommentId : 0;
        cmpcomment.MeasureProtocolCompleteId = this.cmpId;
        cmpcomment.Comments = this.measureProtocolComments.value.Comments;
        cmpcomment.Status = "1";

        if(cmpcomment.Id > 0){
            cmpcomment.CreatedBy =  this.createdBy;
            cmpcomment.CreatedOn =  this.createdOn;
            cmpcomment.ModifiedOn =  new Date();
            cmpcomment.ModifiedBy =  BaseServices.UserId;
            this.subscriptions.push(this.measureprotocolservice
                .updateCMPComment(this.cmpId, cmpcomment)
                .subscribe(result => {
                    this.toasterComponent.callToast();
                    this.editCMPComment();
                }));
        }else{
            cmpcomment.CreatedBy =  BaseServices.UserId;
            cmpcomment.CreatedOn =  new Date();
            this.subscriptions.push(this.measureprotocolservice
                .createCMPComment(this.cmpId, cmpcomment)
                .subscribe((result) => {
                    if (result) {
                       this.toasterComponent.callToast();
                       this.editCMPComment();
                    }
                }));
        }
    }
    
    /**
    * editing power leakage
     * @param Id {number}
     */

    editCMPComment(){
        this.subscriptions.push(this.measureprotocolservice.getCMPCommentById(this.cmpId).subscribe((cmpComment: any) => {
            if (cmpComment) {
                this.cmpCommentId= 1;
                this.createdOn = cmpComment.CreatedOn;
                this.createdBy = cmpComment.CreatedBy;
                this.cmpComment = {
                    Comments: cmpComment.Comments,
                };
                (<FormGroup>this.measureProtocolComments).setValue(this.cmpComment, { onlySelf: true });
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}


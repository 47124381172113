<div class="tab-content">
    <div class="page-title">
        <span translate>COURSE_DETAILS</span>
    </div>
    <div class="table-view">
        <!-- Table starts -->
        <p-table [value]="courseList" [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
        [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}" #dt>
            <!-- Caption -->
            <ng-template pTemplate="caption">
                <div class="ui-helper-clearfix">
                    <div class="filter-controls-wrapper" style="display:none">
                        <form class="form" [formGroup]="courseForm">
                            <div class="form-group">
                                <label for="Course" class="col-form-label">
                                    <span translate>COURSE_NAME</span>
                                </label>
                                <div class="input-btn-group">
                                    <p-dropdown [options]="courseData" formControlName="CourseName" [filter]="true"
                                        #courseDropdown (onChange)="onCourseChanged($event, courseDropdown)"
                                        [style]="{'width':'100%'}"></p-dropdown>
                                    <button class="btn btn-outline-primary" (click)="addCourseModal = !addCourseModal"
                                        type="button">
                                        <i class="pi pi-plus" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="table-options">
                        <div class="pull-right">
                            <div class="action-btns-group">
                                <button class="btn p-3 filter-toggle">
                                    <span class="icon ic-sm icon-filter"></span>
                                    <span translate>FILTER</span>
                                </button>
                            </div>
                            <div class="action-btns-group">
                                <a disabled href="javascript:void(0);" class="btn">
                                    <!-- toggle-action-btn -->
                                    <i class="icon ic-sm icon-send-file-o"></i>
                                    <span translate>EXPORT</span>
                                </a>
                                <div class="export-btns">
                                    <button type="button" class="dropdown-item" (click)="dt.exportCSV()">
                                        <i class="icon ic-sm icon-xls mr-2"></i> Excel</button>
                                    <button type="button" class="dropdown-item">
                                        <i class="icon ic-sm icon-word mr-2"></i> Word</button>
                                    <button type="button" class="dropdown-item" (click)="exportPdf()">
                                        <i class="icon ic-sm icon-pdf mr-2"></i> PDF</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="Employee">{{'EMPLOYEE_NAME' | translate}}</th>
                    <th pSortableColumn="Course">{{'COURSE_NAME' | translate}}</th>
                </tr>
                <tr>
                    <th>
                        <input pInputText type="text"
                            (input)="dt.filter($any($event.target)?.value, 'Employee', 'contains')"
                            placeholder="{{'EMPLOYEE_NAME' | translate}}" class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="dt.filter($any($event.target)?.value, 'Course', 'contains')"
                            placeholder="{{'By Course Name' | translate}}" class="p-column-filter">
                    </th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-course>
                <tr>
                    <td>
                        <span>{{course.Employee}}</span>
                    </td>
                    <td>
                        <span>{{course.Course}}</span>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="2">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table ends -->
    </div>
    <router-outlet></router-outlet>
</div>
<p-dialog header="{{'ADD_COURSE' | translate}}" [(visible)]="addCourseModal" [style]="{width: '300px'}" [resizable]="false" [draggable]="false"
    (onHide)="onBeforeCourseDialogHide()">
    <form [formGroup]="coursePopup">
        <div class="form-group">
            <label for="title" class="col-form-label">
                <span translate>NAME</span>
                <span class="required">*</span>
            </label>
            <div>
                <input type="text" #title class="label-input form-control" formControlName="Title">
            </div>
        </div>
    </form>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <button type="button" class="btn btn-primary" [disabled]="!coursePopup.valid" (click)="saveCourse()">
                <span translate>SAVE</span>
            </button>
            <button type="button" class="btn btn-outline-primary" (click)="viewCourse = !viewCourse">
                <span translate>VIEW</span>
            </button>
        </div>
        <div *ngIf="viewCourse">
            <div class="table-view">
                <!-- Table starts -->
                <p-table #ct [value]="courseDetails" [rows]="5" [paginator]="true" [pageLinks]="3"
                    [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'COURSE_NAME'|translate}}</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text"
                                    (input)="ct.filter($any($event.target)?.value, 'Title', 'contains')"
                                    placeholder="{{'EMPLOYEE_NAME' | translate}}" class="p-column-filter">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-course>
                        <tr>
                            <td>
                                <span>{{course.Title}}</span>
                            </td>
                            <td class="col-button">
                                <button type="button" class="btn btn-icon" title="{{'EDIT' | translate}}"
                                    (click)="editCourseName(course)">
                                    <i class="icon ic-sm icon-pencil-tip"></i>
                                </button>
                                <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}"
                                    (click)="deleteCourse(course.Id)">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="2">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table ends -->
            </div>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="Notification" [(visible)]="notification" [modal]="true" [style]="{width: '300px'}" [resizable]="false" [draggable]="false">
    This course name already existing . please give another course name.
</p-dialog>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<div
  *ngIf="config"
  [ngClass]="
    config.windowedHeight ? 'window-height-fixed' : 'window-height-normal'
  "
>

  <div class="page-title">
    <div>
      <strong *ngIf="!config.titleAsLabel">{{
        config.title | translate
      }}</strong>
      <label *ngIf="config.titleAsLabel">
        {{ config.title | translate }}
      </label>
    </div>

    <div class="page-actions">
      <div class="custom-file" hidden>
        <input
          type="file"
          class="custom-file-input"
          id="customFile"
          ngFileSelect
          (uploadOutput)="onUploadOutput($event)"
          [uploadInput]="uploadInput"
          #uploadAction
          multiple
        />
        <label class="custom-file-label" for="customFile">Choose file</label>
      </div>
      <ng-template [ngIf]="config.showDocumentedTrainingButton">
        <button
          type="button"
          class="btn btn-outline-success"
          (click)="showDocumentDailog()"
          translate
        >
          DOCUMENTED_TRAINING
        </button>
      </ng-template>
      <ng-template [ngIf]="config.showUploadButton">
        <button
          type="button"
          class="btn btn-outline-success"
          (click)="uploadAction.click()"
          translate
        >
          ADD_FILES
        </button>
      </ng-template>
      <ng-template [ngIf]="config.IsPrivateUploadButton && config.editMode">
        <button
          type="button"
          style="left: -100px"
          class="btn btn-outline-success btn-add-documen"
          (click)="uploadAction.click()"
          translate
        >
          ADD_FILES
        </button>
      </ng-template>

      <ng-template
        [ngIf]="
          config.IsDocumentedUploadButton && config.editMode && isMypage == true
        "
      >
        <button
          type="button"
          style="left: 500px"
          class="btn btn-outline-success btn-add-document"
          (click)="uploadAction.click()"
          translate
        >
          ADD_FILES
        </button>
      </ng-template>
      <ng-template
        [ngIf]="
          config.IsDocumentedUploadButton &&
          config.editMode &&
          isMypage == false &&
          !isPortal
        "
      >
        <button
          type="button"
          class="btn btn-outline-success btn-add-document"
          (click)="uploadAction.click()"
          translate
        >
          ADD_FILES
        </button>
      </ng-template>
      <ng-template
        [ngIf]="
          config.IsDocumentedUploadButton &&
          config.editMode &&
          isMypage == false &&
          isPortal
        "
      >
        <button
          type="button"
          class="btn btn-outline-success btn-add-document"
          (click)="uploadAction.click()"
          translate
        >
          ADD_FILES
        </button>
      </ng-template>
      <ng-container
        *ngIf="
          config.documentType != null &&
          config.editMode &&
          config.documentType != 'archiveundercompletionfolder' &&
          config.title != 'Incomplete checklist'
        "
        [ngSwitch]="config.documentType"
      >
        <button
          *ngSwitchCase="'article'"
          type="button"
          class="btn btn-outline-success"
          (click)="createArticle()"
          translate
        >
          NEW_ARTICLE
        </button>
        <button
          *ngSwitchCase="'checklist'"
          type="button"
          class="btn btn-outline-success"
          (click)="createCheckList()"
          translate
        >
          NEW_CHECKLIST
        </button>
        <button
          *ngSwitchDefault
          type="button"
          class="btn btn-outline-success"
          (click)="uploadAction.click()"
          translate
        >
          ADD_FILES
        </button>
        <button
          type="button"
          class="btn btn-outline-success"
          (click)="createFolder()"
          translate
        >
          NEW_FOLDER
        </button>
      </ng-container>

      <ng-template [ngIf]="config.viewSwitch">
        <button
          *ngIf="
            config.isEditablePrivateDocument && config.title != 'TO_DO_LIST'
          "
          type="button"
          class="btn btn-icon btn-sm btn-border"
          (click)="toggleEditMode()"
          [ngClass]="config.editMode ? 'text-danger' : 'text-success'"
        >
          <span *ngIf="config.editMode" translate>GO_DISPLAY</span>
          <span *ngIf="!config.editMode" translate>GO_EDIT</span>
          <span
            class="icon ic-md"
            [ngClass]="
              config.editMode
                ? 'icon-edit-content text-danger'
                : 'icon-view-content text-success'
            "
          ></span>
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          (click)="onViewChange()"
          [class.active]="!listView"
        >
          <i class="pi pi-th-large" aria-hidden="true"></i>
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          (click)="onViewChange()"
          [class.active]="listView"
        >
          <i class="pi pi-list" aria-hidden="true"></i>
        </button>
      </ng-template>
      <ng-template [ngIf]="config.showSearchFilter"> </ng-template>
    </div>
  </div>
  <div *ngIf="config.showSearchFilter || config.showFoodSafetyFiler">
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <input
            class="form-control manuals-search"
            [(ngModel)]="query"
            placeholder="{{ 'SEARCH_FILENAME' | translate }}"
          />
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <my-date-picker
            class="manuals-search"
            name="startdate"
            [options]="fileDateOptions"
            (dateChanged)="onStartDateChanged($event)"
            placeholder="{{ 'SELECT_START_DATE' | translate }}"
            [selDate]="startDate"
          ></my-date-picker>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <my-date-picker
            class="manuals-search"
            name="enddate"
            [options]="fileDateOptions"
            (dateChanged)="onEndDateChanged($event)"
            placeholder="{{ 'SELECT_END_DATE' | translate }}"
            [selDate]="endDate"
          ></my-date-picker>
        </div>
      </div>
    </div>
    <div *ngIf="config.showFoodSafetyFiler" class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label class="col-form-label">
            <span translate>CREATED_BY</span>
          </label>
          <div>
            <p-dropdown
              [options]="createdBy"
              [(ngModel)]="selectedCreatedByUserId"
              #createdByDropdown
              [style]="{ width: '100%' }"
              [filter]="true"
            ></p-dropdown>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label class="col-form-label">
            <span translate>FOLDER</span>
          </label>
          <div>
            <p-dropdown
              [options]="folderList"
              [(ngModel)]="selectedFolderId"
              (onChange)="onFolderSelectionChanges($event)"
              #folderDropDown
              [style]="{ width: '100%' }"
              [filter]="true"
            ></p-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Files created in the system (article and checklist ..) -->
  <div
    class="drop-container"
    ngFileDrop
    [(options)]="options"
    (uploadOutput)="onUploadOutput($event)"
    [uploadInput]="uploadInput"
    [ngClass]="{ 'is-drop-over': dragOver }"
  >
    <!-- If grid view -->
    <div class="grid" *ngIf="!listView">
      <div class="row uploads" *ngIf="files?.length || uploadedFiles?.length">
        <div class="col-sm-2" *ngFor="let f of files; let i = index">
          <div class="thumbnail">
            <a href="javascript:void(0)" title="Uploading">
              <span class="percentage">
                <img src="/assets/icons/blocks.gif" alt="" />
              </span>
              <img src="/assets/file-icons/kb-file-unknown.png" alt="" />
            </a>
            <div class="caption">
              <small>{{ f.name }}</small>
            </div>
          </div>
        </div>

        <div
          class="col-sm-2"
          *ngFor="
            let f of uploadedFiles
              | search : 'filename,createdBy' : query : startDate : endDate
          "
        >
          <div class="thumbnail">
            <div class="grid-thumb">
              <ecpl-image-viewer
                *ngIf="
                  f.path != null &&
                  humanizeType(getFileExtension(f.path)) == 'Image'
                "
                [imgSrc]="f.path"
                [imgType]="getFileExtension(f.path)"
                [imgName]="f.filename"
                [id]="f.nodeId"
                thumbNail="true"
              >
              </ecpl-image-viewer>
              <ecpl-article-viewer
                *ngIf="f.type == 'file' && f.documentType == 'article'"
                [articleName]="f.filename"
                [(articleSrc)]="f.content"
                [id]="f.nodeId"
                (saveChanges)="saveEmitter($event)"
                [editMode]="config.editMode"
                [adminUsers]="adminUsers"
              ></ecpl-article-viewer>

              <ecpl-onlyoffice-viewer
                *ngIf="
                  f.path != null &&
                  humanizeType(getFileExtension(f.path)) == 'Document'
                "
                [onlyofficeSrc]="f.path"
                [onlyofficeKey]="f.nodeId + 'key'"
                [onlyofficeType]="getFileExtension(f.path)"
                [onlyofficeName]="f.filename"
                [onlyofficeMode]="config.editMode"
                [id]="f.nodeId"
              ></ecpl-onlyoffice-viewer>

              <ecpl-pdf-viewer
                *ngIf="
                  f.path != null &&
                  humanizeType(getFileExtension(f.path)) == 'Pdf'
                "
                [id]="f.nodeId"
                [pdfSrc]="f.path"
                [pdfName]="f.filename"
              ></ecpl-pdf-viewer>
              <ecpl-checklist-viewer
                *ngIf="f.type == 'file' && f.documentType == 'checklist'"
                [checklistName]="f.filename"
                [(checklistSrc)]="f.content"
                [id]="f.nodeId"
                [editMode]="config.editMode"
              ></ecpl-checklist-viewer>

              <a
                *ngIf="humanizeType(getFileExtension(f.path)) == 'Video'"
                (click)="openVideo(f)"
              >
                <img src="/assets/file-icons/kb-file-video.png" alt="Video" />
              </a>
              <a *ngIf="humanizeType(getFileExtension(f.path)) == 'Audio'">
                <img src="/assets/file-icons/kb-file-audio.png" alt="Audio" />
              </a>
              <a *ngIf="humanizeType(getFileExtension(f.path)) == 'Text'">
                <img src="/assets/icons/txt.png" alt="Text" />
              </a>

              <!-- New folder @ current Parent -->
              <a
                href="javascript:void(0)"
                *ngIf="f.type == 'folder'"
                [id]="f.nodeId"
                (click)="jumpIntoFolder(f.id)"
              >
                <img [src]="getFileIcon(f.documentType)" alt="Folder" />
              </a>
            </div>
            <div class="caption">
              <small>{{ f.filename }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- If list view -->
    <div class="list" *ngIf="listView">
      <div
        class="no-files"
        *ngIf="uploadedFiles?.length == 0 && config.documentType === 'document'"
      >
        <span>
          <span class="files-drop-icon"></span>
          <br />
          <span translate>DROP_FILES</span>
        </span>
      </div>
      <div
        class="no-files"
        *ngIf="
          uploadedFiles?.length == 0 && config.documentType === 'checklist'
        "
      >
        <span>
          <span translate>ADD_CHECKLIST_HERE</span>
        </span>
      </div>
      <div
        class="no-files"
        *ngIf="uploadedFiles?.length == 0 && config.documentType === 'article'"
      >
        <span>
          <span translate>ADD_ARTICLE_HERE</span>
        </span>
      </div>
      <!--Panel Loader-->
      <div *ngIf="showLoader" class="panel-loader">
        <span>
          <span class="loader-icon"></span>
          <span>Processing...</span>
        </span>
      </div>

      <!--Panel Loader-->
      <table
        class="table table-striped list"
        *ngIf="files?.length || uploadedFiles?.length"
      >
        <thead>
          <tr>
            <th
              *ngIf="
                config.title == 'PRIVATE_DOCUMENTS' ||
                config.title == 'DOCUMENTED_TRAINING' ||
                config.title == 'TO_DO_LIST' ||
                (config.showDownloadAll != null &&
                  config.showDownloadAll != false) ||
                config.documentType == 'archiveundercompletionfolder'
              "
            >
              <input
                type="checkbox"
                [(ngModel)]="selectedAll"
                (change)="SelectAllDocuments()"
              />
            </th>
            <th></th>
            <th translate>FILE_NAME</th>
            <th *ngIf="config.showUserMeta" translate>TYPE</th>
            <th *ngIf="config.documentType === 'article'" translate>
              DOCUMENT_NUMBER
            </th>
            <th
              *ngIf="
                config.documentType === 'article' ||
                config.documentType === 'allFiles'
              "
              translate
            >
              VERSION
            </th>
            <th
              *ngIf="config.documentType !== 'article' && config.showUserMeta"
              translate
            >
              CREATED_BY
            </th>
            <th
              style="width: 80px"
              *ngIf="config.documentType !== 'article' && config.showUserMeta"
              translate
            >
              CREATED_ON
            </th>
            <th
              *ngIf="config.documentType === 'article' && config.showUserMeta"
              translate
            >
              CREATED_BY
            </th>
            <th
              style="width: 80px"
              *ngIf="config.documentType === 'article' && config.showUserMeta"
              translate
            >
              CREATED_ON
            </th>
            <th *ngIf="config.showUserMeta && featureId != 60" translate>
              EDITED_BY
            </th>
            <th style="width: 80px" *ngIf="config.showUserMeta" translate>
              EDITED_ON
            </th>
            <th
              *ngIf="
                roleId > 3 &&
                projectId === 0 &&
                config.showUserMeta &&
                config.documentType === 'article'
              "
              translate
            >
              DATE_APPROVAL
            </th>
            <th
              *ngIf="config.documentType == 'archiveundercompletionfolder'"
              translate
            >
              ACTIVITY_NAME
            </th>
            <th
              *ngIf="config.documentType == 'archiveundercompletionfolder'"
              translate
            >
              CHECKLIST_NAME
            </th>
            <th *ngIf="config.showFDVItems && !config.showProjectPhoto">
              <span translate>ITEMNO</span>
            </th>
            <th *ngIf="config.showFDVItems"><span translate>ITEMNAME</span></th>
            <th
              *ngIf="
                config.title == 'PRIVATE_DOCUMENTS' ||
                config.title == 'DOCUMENTED_TRAINING'
              "
            >
              <span translate>EDITED_DATE</span>
            </th>
            <th
              *ngIf="
                config.title == 'PRIVATE_DOCUMENTS' ||
                config.title == 'DOCUMENTED_TRAINING' ||
                config.title == 'TO_DO_LIST'
              "
            >
              <span translate>REVIEWED_DATE</span>
            </th>
            <th
              *ngIf="
                config.title == 'PRIVATE_DOCUMENTS' ||
                config.title == 'DOCUMENTED_TRAINING' ||
                config.title == 'TO_DO_LIST'
              "
            >
              <span translate>UPLOADED_BY</span>
            </th>
            <th
              style="width: 80px"
              *ngIf="
                config.showDeleteButton &&
                config.editMode &&
                ((config.title == 'DOCUMENTED_TRAINING' &&
                  deleteRightsDocumentedTraining) ||
                  (config.title == 'PRIVATE_DOCUMENTS' &&
                    deleteRightsApprovalDocument) ||
                  (config.title == 'TO_DO_LIST' &&
                    deleteRightsApprovalDocument)) &&
                !(
                  config.documentType === 'article' ||
                  config.documentType == 'form'
                )
              "
              translate
            >
              ACTIONS
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody class="uploads">
          <div
            *ngIf="
              uploadedFiles
                ? (
                    uploadedFiles
                    | search
                      : 'filename,createdBy'
                      : query
                      : startDate
                      : endDate
                  ).length === 0
                : false
            "
          >
            <!-- <span translate>NO_RECORDS_FOUND</span> -->
          </div>
          <!-- uploaded Files -->
          <tr
            class="file-item"
            *ngFor="
              let f of uploadedFiles
                | search : 'filename,createdBy' : query : startDate : endDate
                | paginate : pgConfig
                | searchByUser : selectedCreatedByUserId;
              index as i
            "
          >
            <td
              *ngIf="
                config.title == 'PRIVATE_DOCUMENTS' ||
                config.title == 'DOCUMENTED_TRAINING' ||
                config.title == 'MANUALS' ||
                (config.showDownloadAll != null &&
                  config.showDownloadAll != false) ||
                (config.showDownloadAll != null &&
                  config.showDownloadAll != false) ||
                (config.documentType == 'archiveundercompletionfolder' &&
                  f.documentType !== 'article')
              "
            >
              <div *ngIf="manualpage">
                <input
                  type="checkbox"
                  [disabled]="f.approvedOn != null && userId == pdUserId"
                  *ngIf="
                    config.title == 'PRIVATE_DOCUMENTS' ||
                    config.title == 'DOCUMENTED_TRAINING'
                      ? (isEditor && f.createdById == pdUserId) ||
                        (isUser && f.createdById != pdUserId)
                      : f.type !== 'folder'
                  "
                  [(ngModel)]="f.selected"
                  (change)="checkIfAllSelected()"
                />
              </div>
              <div *ngIf="!manualpage">
                <input
                  type="checkbox"
                  [disabled]="f.approvedOn != null && userId == pdUserId"
                  *ngIf="
                    (config.title == 'PRIVATE_DOCUMENTS' ||
                      config.title == 'DOCUMENTED_TRAINING') &&
                    f.type !== 'folder'
                  "
                  [(ngModel)]="f.selected"
                  (change)="checkIfAllSelected()"
                />
              </div>
              <div *ngIf="commonpage">
                <input
                  type="checkbox"
                  [disabled]="f.approvedOn != null && userId == pdUserId"
                  *ngIf="f.type !== 'folder'"
                  [(ngModel)]="f.selected"
                  (change)="checkIfAllSelected()"
                />
              </div>
            </td>
            <td *ngIf="config.title == 'TO_DO_LIST'">
              <input
                type="checkbox"
                [disabled]="f.approvedOn != null"
                *ngIf="config.title == 'TO_DO_LIST'"
                [(ngModel)]="f.selected"
                (change)="checkIfAllSelected()"
              />
            </td>
            <td class="file-thumbnail">
              <div class="thumb-sm">
                <ecpl-image-viewer
                  *ngIf="
                    f.path != null &&
                    humanizeType(getFileExtension(f.path)) == 'Image'
                  "
                  [imgSrc]="f.path"
                  [imgType]="getFileExtension(f.path)"
                  [imgName]="f.filename"
                  [id]="f.nodeId"
                  thumbNail="true"
                ></ecpl-image-viewer>

                <a
                  *ngIf="humanizeType(getFileExtension(f.path)) == 'Video'"
                  (click)="openVideo(f)"
                >
                  <img
                    src="/assets/file-icons/kb-file-video.png"
                    alt="Video"
                  />
                </a>
                <a *ngIf="humanizeType(getFileExtension(f.path)) == 'Audio'">
                  <img src="/assets/file-icons/kb-file-audio.png" alt="Audio" />
                </a>
                <a *ngIf="humanizeType(getFileExtension(f.path)) == 'Text'">
                  <img src="/assets/icons/txt.png" alt="Text" />
                </a>
                <ecpl-article-viewer
                  *ngIf="
                    config.title == 'PRIVATE_DOCUMENTS' ||
                    config.title == 'DOCUMENTED_TRAINING' ||
                    config.title == 'TO_DO_LIST'
                      ? f.documentType == 'article'
                      : f.type == 'file' && f.documentType == 'article'
                  "
                  [businessMode]="businessMode"
                  [articleName]="f.filename"
                  [(articleSrc)]="f.content"
                  [id]="
                    config.title == 'PRIVATE_DOCUMENTS' ||
                    config.title == 'DOCUMENTED_TRAINING' ||
                    config.title == 'TO_DO_LIST'
                      ? f.documentId
                      : f.nodeId
                  "
                  [adminUsers]="adminUsers"
                  (saveChanges)="saveEmitter($event)"
                  [editMode]="
                    config.title == 'PRIVATE_DOCUMENTS' ||
                    config.title == 'DOCUMENTED_TRAINING' ||
                    config.title == 'TO_DO_LIST'
                      ? false
                      : config.editMode!
                  "
                ></ecpl-article-viewer>

                <div *ngIf="config.editMode">
                  <ecpl-onlyoffice-viewer
                    *ngIf="
                      f.path != null &&
                      humanizeType(getFileExtension(f.path)) == 'Document'
                    "
                    [onlyofficeSrc]="f.path"
                    [onlyofficeKey]="f.nodeId + 'key'"
                    [onlyofficeType]="getFileExtension(f.path)"
                    [onlyofficeName]="f.filename"
                    [onlyofficeMode]="
                      config.title == 'PRIVATE_DOCUMENTS' ||
                      config.title == 'DOCUMENTED_TRAINING' ||
                      config.title == 'TO_DO_LIST'
                        ? f.createdById == pdUserId
                        : f.isEditable
                    "
                    [id]="
                      config.title == 'PRIVATE_DOCUMENTS' ||
                      config.title == 'DOCUMENTED_TRAINING' ||
                      config.title == 'TO_DO_LIST'
                        ? f.id
                        : f.nodeId
                    "
                    (saveChanges)="
                      config.title == 'PRIVATE_DOCUMENTS' ||
                      config.title == 'DOCUMENTED_TRAINING' ||
                      config.title == 'TO_DO_LIST'
                        ? savePrivateDocs($event, f)
                        : saveEmitter($event)
                    "
                  ></ecpl-onlyoffice-viewer>
                </div>
                <div *ngIf="!config.editMode">
                  <ecpl-onlyoffice-viewer
                    *ngIf="
                      f.path != null &&
                      humanizeType(getFileExtension(f.path)) == 'Document'
                    "
                    [onlyofficeSrc]="f.path"
                    [onlyofficeKey]="f.nodeId + 'key'"
                    [onlyofficeType]="getFileExtension(f.path)"
                    [onlyofficeName]="f.filename"
                    [onlyofficeMode]="config.editMode!"
                    [id]="f.nodeId"
                    (saveChanges)="saveEmitter($event)"
                  ></ecpl-onlyoffice-viewer>
                </div>

                <ecpl-pdf-viewer
                  *ngIf="
                    f.path != null &&
                    humanizeType(getFileExtension(f.path)) == 'Pdf'
                  "
                  [id]="f.nodeId"
                  [pdfSrc]="f.path"
                  [pdfName]="f.filename"
                ></ecpl-pdf-viewer>
                <ecpl-checklist-viewer
                  *ngIf="
                    config.title == 'DOCUMENTED_TRAINING'
                      ? f.documentType == 'checklist'
                      : f.type == 'file' &&
                        f.documentType == 'checklist' &&
                        f.featureId !== 36
                  "
                  [checklistName]="f.filename"
                  [(checklistSrc)]="f.content"
                  [id]="f.nodeId"
                  (saveChanges)="saveEmitter($event)"
                  [editMode]="config.editMode"
                ></ecpl-checklist-viewer>

                <ecpl-lar-viewer
                  *ngIf="
                    (f.type == 'file' && f.documentType == 'Laws') ||
                    f.documentType == 'Regulation' ||
                    f.documentType == 'Videos'
                  "
                  [name]="f.filename"
                  [(larSrc)]="f.content"
                  [mode]="config.editMode"
                  [id]="f.nodeId"
                ></ecpl-lar-viewer>

                <!-- New folder @ current Parent -->
                <a
                  href="javascript:void(0)"
                  *ngIf="f.type == 'folder'"
                  [id]="f.nodeId"
                  (click)="jumpIntoFolder(f.id)"
                >
                  <img [src]="getFileIcon(f.documentType)" alt="Folder" />
                </a>

                <a
                  [routerLink]="[
                    '../../../../../fs-complete-checklist/incomplete',
                    f.nodeId
                  ]"
                  *ngIf="f.documentType == 'manualfsundercompletion'"
                >
                  <span class="icon ic-lg icon-046"></span>
                </a>
                <a
                  [routerLink]="[
                    '../../../../../complete-checklist/incomplete',
                    f.nodeId,
                    'feature',
                    featureKey.id,
                    0
                  ]"
                  *ngIf="f.documentType == 'undercompletion'"
                >
                  <span class="icon ic-lg icon-046"></span>
                </a>
                <a
                  href="javascript:void(0);"
                  (click)="openChecklistOrForm(f)"
                  *ngIf="
                    (f.documentType == 'checklist' ||
                      f.documentType == 'installationform') &&
                    f.type !== 'folder' &&
                    f.featureId === 36
                  "
                >
                  <img
                    src="/assets/file-icons/kb-checklist.png"
                    alt="checklist file"
                  />
                </a>
                <a
                  href="javascript:void(0);"
                  (click)="openChecklistOrForm(f)"
                  *ngIf="
                    (f.documentType == 'undercompletioninstallationform' ||
                      f.documentType == 'archiveundercompletion') &&
                    f.type !== 'folder' &&
                    f.featureId === 36
                  "
                >
                  <img
                    src="/assets/file-icons/kb-checklist-uc.png"
                    alt="checklist file"
                  />
                </a>
              </div>
            </td>
            <td class="file-name">
              <span
                *ngIf="
                  (f.documentType == 'undercompletioninstallationform' ||
                    f.documentType == 'archiveundercompletion') &&
                  f.type !== 'folder' &&
                  f.featureId === 36
                "
              >
                {{ f.filename }} ( <span translate>UNDER_COMPLETION</span> )
              </span>
              <span
                *ngIf="
                  f.documentType == 'fsundercompletion' && f.type !== 'folder'
                "
              >
                <a
                  [routerLink]="[
                    '../../fs-complete-checklist/incomplete',
                    f.nodeId
                  ]"
                  *ngIf="
                    f.documentType == 'fsundercompletion' && f.type !== 'folder'
                  "
                >
                  <span class="icon ic-lg icon-046"> </span>
                  {{ f.filename }} ( <span>FS Under Completion</span> )
                </a>
              </span>
              <!-- electro installation forms -->
              <span
                *ngIf="
                  f.documentType == 'archiveundercompletion' &&
                  f.featureId !== 36
                "
              >
                <a
                  [routerLink]="[
                    '../../complete-checklist/incomplete',
                    f.nodeId,
                    'activity',
                    featureId,
                    0
                  ]"
                >
                  <span class="icon ic-lg icon-046"></span>
                  {{ f.filename }} (<span translate>UNDER_COMPLETION</span>)
                </a>
              </span>
              <span *ngIf="f.documentType == 'manualfsundercompletion'">
                {{ f.filename }}
              </span>
              <span *ngIf="f.documentType == 'undercompletion'">
                {{ f.filename }}
              </span>
              <span
                *ngIf="
                  f.documentType != 'archiveundercompletion' &&
                  f.documentType != 'fsundercompletion' &&
                  f.documentType != 'undercompletion' &&
                  f.documentType != 'manualfsundercompletion' &&
                  f.documentType != 'undercompletioninstallationform'
                "
              >
                {{ f.filename }}
              </span>
              <a
                *ngIf="
                  f.path != null &&
                  (humanizeType(getFileExtension(f.path)) == 'Document' ||
                    humanizeType(getFileExtension(f.path)) == 'Text' ||
                    humanizeType(getFileExtension(f.path)) == 'Image') ||
                  humanizeType(getFileExtension(f.path)) == 'Video'
                "
                href="javascript:void(0);"
                class="btn btn-icon"
                (click)="downloadFile(f.path)"
              >
                <span class="icon ic-sm icon-send-file-o"></span>
              </a>
              <a
                *ngIf="
                  f.path != null &&
                  humanizeType(getFileExtension(f.path)) == 'Pdf'
                "
                href="javascript:void(0);"
                class="btn btn-icon"
                (click)="downloadFile(f.path)"
              >
                <span class="icon ic-sm icon-send-file-o"></span>
              </a>
            </td>
            <td *ngIf="config.showUserMeta">
              <span *ngIf="f.documentType === 'undercompletion'">
                {{ f.documentType | translate }}
              </span>
              <span *ngIf="f.documentType !== 'undercompletion'">
                {{ f.documentType | titlecase | translate }}
              </span>
            </td>
            <td *ngIf="config.documentType === 'article'">
              <span *ngIf="f.type === 'file'; else folderTemplate">
                <span
                  *ngIf="
                    f.content?.article?.documentNumber != null;
                    else folderTemplate
                  "
                >
                  {{ f.content?.article?.documentNumber ?? "" }}
                </span>
              </span>
              <ng-template #folderTemplate></ng-template>
            </td>
            <td
              *ngIf="
                config.documentType === 'article' ||
                config.documentType === 'allFiles'
              "
            >
              <span *ngIf="f.type === 'file'">
                <span *ngIf="f.documentType === 'article'">
                  {{ displayVersion(f.content.article, f.content.subArticles) }}
                </span>
              </span>
              <span *ngIf="f.type === 'folder'"> </span>
            </td>
            <td
              *ngIf="config.documentType !== 'article' && config.showUserMeta"
            >
              {{ f.createdBy }}
            </td>
            <td
              *ngIf="config.documentType !== 'article' && config.showUserMeta"
            >
              {{ f.createdOn | date : "dd/MM/yyyy" }}
            </td>
            <td
              *ngIf="config.documentType === 'article' && config.showUserMeta"
            >
              {{ f.createdBy }}
            </td>
            <td
              *ngIf="config.documentType === 'article' && config.showUserMeta"
            >
              {{ f.createdOn | date : "dd/MM/yyyy" }}
            </td>
            <td *ngIf="config.showUserMeta && featureId != 60">
              {{ f.modifiedBy }}
            </td>
            <td
              *ngIf="
                config.showUserMeta &&
                f.documentType !== 'article' &&
                featureId != 60
              "
            >
              {{ f.modifiedOn | date : "dd/MM/yyyy" }}
            </td>
            <td *ngIf="config.showUserMeta && f.documentType === 'article'">
              <span *ngIf="textName === 'Own Text' && f.documentType === 'article' && f.isEditable == false">
                {{ businessEditedDate | date : "dd/MM/yyyy" }}
              </span>
              <span *ngIf="f.documentType === 'article' && f.isEditable == true">
                {{ f.modifiedOn  | date : "dd/MM/yyyy" }}
              </span>
              <span *ngIf="f.documentType != 'article'">
                {{ f.modifiedOn | date : "dd/MM/yyyy" }}
              </span>
            </td>            
            <td
              *ngIf="
                roleId > 2 &&
                projectId === 0 &&
                config.showUserMeta &&
                f.documentType === 'article'
              "
            >
              <span *ngIf="config.documentType === 'article'">
                {{ f.content?.article?.approvedDate | date : "dd/MM/yyyy" }}
              </span>
            </td>
            <td
              *ngIf="
                f.documentType == 'archiveundercompletion' && f.featureId != 36
              "
            >
              {{ f.activityName }}
            </td>
            <td
              *ngIf="
                f.documentType == 'archiveundercompletion' && f.featureId != 36
              "
            >
              {{ f.checklistName }}
            </td>
            <td *ngIf="config.showFDVItems && !config.showProjectPhoto">
              <input [(ngModel)]="f.itemNo" />
            </td>
            <td *ngIf="config.showFDVItems">
              <input [(ngModel)]="f.itemName" />
            </td>
            <td *ngIf="config.showRegistrationItem && f.id === 0">
              <input
                [(ngModel)]="f.documentName"
                placeholder="{{ 'DOCUMENT_NAME' | translate }}"
              />
            </td>
            <td
              *ngIf="
                config.title == 'PRIVATE_DOCUMENTS' ||
                config.title == 'DOCUMENTED_TRAINING'
              "
            >
              {{ f.modifiedOn | date : "dd/MM/yyyy" }}
            </td>
            <td
              *ngIf="
                config.title == 'PRIVATE_DOCUMENTS' ||
                config.title == 'DOCUMENTED_TRAINING'
              "
            >
              {{ f.approvedOn | date : "dd/MM/yyyy" }}
            </td>
            <td *ngIf="config.title == 'TO_DO_LIST'">
              {{ f.approvedOn | date : "dd/MM/yyyy, h:mm a" }}
            </td>
            <td
              *ngIf="
                config.title == 'PRIVATE_DOCUMENTS' ||
                config.title == 'DOCUMENTED_TRAINING' ||
                config.title == 'TO_DO_LIST'
              "
            >
              {{ f.createdBy }}
            </td>
            <td class="col-button">
              <ng-template
                [ngIf]="
                  config.title == 'DOCUMENTED_TRAINING'
                    ? false
                    : f.documentType == 'checklist' ||
                      (f.featureId == 36 &&
                        (f.documentType == 'archiveundercompletion' ||
                          f.documentType == 'installationform' ||
                          f.documentType == 'undercompletioninstallationform'))
                "
              >
                <a
                  href="javascript:void(0);"
                  *ngIf="config.title != 'DOCUMENTED_TRAINING'"
                  class="btn btn-icon"
                  (click)="printChecklist(f)"
                >
                  <span
                    title="{{ 'PRINT' | translate }}"
                    class="icon ic-sm icon-print"
                  ></span>
                </a>
              </ng-template>
              <ng-template
                [ngIf]="
                  config.title == 'DOCUMENTED_TRAINING' ||
                  config.title == 'TO_DO_LIST'
                    ? false
                    : f.documentType == 'document'
                "
              >
                <a
                  href="javascript:void(0);"
                  class="btn btn-icon"
                  (click)="copyDocLink(f)"
                >
                  <span
                    title="{{ 'COPY_URL' | translate }}"
                    class="icon ic-sm icon-copy-to-clipboard"
                  ></span>
                </a>
              </ng-template>
              <div *ngIf="f.createdBy != 'Admin' || isAdmin">
                <ng-template
                  [ngIf]="
                    config.showDeleteButton && config.editMode &&
                    !f.isDefault &&
                    config.viewSwitch === false
                  "
                >
                  <a
                    href="javascript:void(0);"
                    name="3"
                    (click)="removeExistingFile(f)"
                    class="btn btn-icon text"
                    title="{{ 'DELETE' | translate }}"
                  >
                    <span
                      class="icon ic-sm icon-trash"
                      aria-hidden="true"
                    ></span>
                  </a>
                </ng-template>
              </div>
              <ng-template
                [ngIf]="
                  config.editMode &&
                  ((config.title == 'PRIVATE_DOCUMENTS' &&
                    deleteRightsApprovalDocument) ||
                    (config.title == 'DOCUMENTED_TRAINING' &&
                      deleteRightsDocumentedTraining))
                "
              >
                <a
                  href="javascript:void(0);"
                  name="4"
                  (click)="removeExistingFile(f)"
                  class="btn btn-icon"
                >
                  <span class="icon ic-sm icon-trash" aria-hidden="true"></span>
                </a>
              </ng-template>
            </td>
          </tr>
          <!-- for new files -->
          <tr class="file-item" *ngFor="let f of files; let i = index">
            <td>
              <span class="thumb-sm">
                <a href="javascript:void(0)">
                  <span class="percentage">
                    <img src="/assets/icons/blocks.gif" alt="" />
                  </span>
                  <img src="/assets/file-icons/kb-file-unknown.png" alt="" />
                </a>
              </span>
              <span>{{ f.name }}</span>
            </td>
            <td>
              {{ humanizeBytes(f.size) }}
            </td>
            <th scope="row">
              <a
                href="javascript:void(0);"
                name="1"
                *ngIf="config.title != 'DOCUMENTED_TRAINING'"
                (click)="removeFile(f.id)"
                style="margin-top: 8px; display: inline-block"
              >
                <span class="icon ic-sm icon-trash" aria-hidden="true"></span>
              </a>
            </th>
          </tr>
        </tbody>
      </table>

      <div
        class="action-btns-wrapper"
        *ngIf="files?.length || uploadedFiles?.length"
      >
        <div class="row">
          <div
            class="col-auto"
            *ngIf="
              (uploadedFiles
                | search : 'filename,createdBy' : query : startDate : endDate)!
                .length > 0 && selectedPageSize != 0
            "
          >
            <pagination-controls
              (pageChange)="pgConfig.currentPage = $event"
              previousLabel="{{ 'PREVIOUS' | translate }}"
              nextLabel="{{ 'NEXT' | translate }}"
            >
            </pagination-controls>
          </div>
          <div
            class="col-auto ml-auto"
            *ngIf="
              uploadedFiles
                | search : 'filename,createdBy' : query : startDate : endDate;
              let val
            "
          >
            <p-dropdown
              *ngIf="
                (uploadedFiles
                  | search
                    : 'filename,createdBy'
                    : query
                    : startDate
                    : endDate)!.length > 0
              "
              [options]="pageSizeList"
              [(ngModel)]="selectedPageSize"
              (onChange)="onPageChanged($event)"
            ></p-dropdown>
          </div>
        </div>
      </div>
      <ng-template
        [ngIf]="!config.isEditablePrivateDocument && featureId !== 36"
      >
        <div
          class="action-btns-wrapper"
          *ngIf="
            uploadedFiles
              | search : 'filename,createdBy' : query : startDate : endDate;
            let val
          "
        >
          <button
            *ngIf="
              config.title == 'DOCUMENTED_TRAINING'
                ? false
                : config.showDownloadAll != null &&
                  config.showDownloadAll != false &&
                  config.documentType === 'article'
            "
            class="btn btn-link"
            (click)="ArticleBulkPrintPDF(val)"
            translate
          >
            <span class="icon ic-sm icon-print"></span>
            BULK_PRINT
          </button>

          <button
            *ngIf="
              config.showDownloadAll != null && config.showDownloadAll != false
            "
            class="btn btn-link"
            (click)="DocumentsDownloadAll(val)"
            translate
          >
            <span class="icon ic-sm icon-download"></span>
            DOWNLOAD_ALL
          </button>

          <button
            *ngIf="
              config.title == 'DOCUMENT_TRAINING'
                ? false
                : config.showDownloadAll != null &&
                  config.showDownloadAll != false &&
                  config.documentType === 'article'
            "
            class="btn btn-link"
            (click)="ArticleIndexPrintPDF(val)"
            translate
          >
            <span class="icon ic-sm icon-print"></span>
            PRINT_INDEX
          </button>

          <button
            *ngIf="
              !config.editMode &&
              roleId > 2 &&
              config.showApproveDocumentButton == true
            "
            class="btn btn-link"
            (click)="approveDocument(val)"
            translate
          >
            <span class="icon ic-sm icon-download"></span>
            APPROVE_DOCUMENT
          </button>
          <button
            *ngIf="config.showDeleteAllButton && config.editMode"
            name="2"
            class="btn btn-link"
            (click)="onDeleteSelectedFiles()"
            translate
          >
            <span class="icon ic-sm icon-trash"></span>
            DELETE
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<!-- New Folder Modal -->
<ng-template #newFolderModal>
  <div class="modal-header">
    <h4 class="modal-title">{{ bsModalTitle.toUpperCase() | translate }}</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="bsModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="input-group">
      <input
        *ngIf="newFolderNode"
        type="text"
        [(ngModel)]="newFolderNode.filename"
        name="folderName"
        id="folderName"
        class="form-control"
        style="width: 300px"
      />
    </div>
    <br />
    <span class="input-group-btn">
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="validateNewFolder()"
        (click)="saveEmitter(currentEventData)"
        translate
        style="margin-top: 7px"
      >
        SAVE
      </button>
    </span>
  </div>
</ng-template>

<!-- Editors and Viewers -->
<div
  #hiddenAction
  style="width: 1px; height: 1px; overflow: hidden"
  *ngIf="file != null"
>
  <ecpl-image-viewer
    *ngIf="
      file.path != null &&
      humanizeType(getFileExtension(file.filename)) == 'Image'
    "
    [imgSrc]="file.path"
    [imgType]="file.mimetype"
    [imgName]="file.filename"
    [id]="file.nodeId"
    thumbNail="true"
  >
  </ecpl-image-viewer>

  <ecpl-article-viewer
    *ngIf="file.type == 'file' && file.documentType == 'article'"
    [articleName]="file.filename"
    [(articleSrc)]="file.content"
    [id]="file.nodeId"
    (saveChanges)="saveEmitter($event)"
    [editMode]="config.editMode"
  >
  </ecpl-article-viewer>

  <ecpl-onlyoffice-viewer
    *ngIf="
      file.path != null &&
      humanizeType(getFileExtension(file.path)) == 'Document'
    "
    [onlyofficeSrc]="file.path"
    [onlyofficeKey]="file.nodeId + 'key'"
    [onlyofficeType]="getFileExtension(file.path)"
    [onlyofficeName]="file.filename"
    [id]="file.nodeId"
    [onlyofficeMode]="config.editMode"
  ></ecpl-onlyoffice-viewer>

  <ecpl-pdf-viewer
    *ngIf="
      file.path != null && humanizeType(getFileExtension(file.path)) == 'Pdf'
    "
    [id]="file.nodeId"
    [pdfSrc]="file.path"
    [pdfName]="file.filename"
  ></ecpl-pdf-viewer>
  <ecpl-checklist-viewer
    *ngIf="file.type == 'file' && file.documentType == 'checklist'"
    [checklistName]="file.filename"
    [(checklistSrc)]="file"
    [id]="file.nodeId"
    (saveChanges)="saveEmitter($event)"
    [editMode]="config.editMode"
  ></ecpl-checklist-viewer>
</div>
<p-dialog
  header="{{ 'COPY_DOCUMENT_URL' | translate }}"
  [(visible)]="showCopyLinkDailog"
  [style]="{ width: '600px', height: '200px' }"
  [resizable]="false"
  [draggable]="false"
>
  <form [formGroup]="copyDocForm">
    <input
      type="text"
      #Name
      class="label-input form-control"
      style="width: 100%"
      formControlName="filePath"
    />
    <div class="action-btns-wrapper">
      <button
        class="btn btn-primary"
        *ngIf="config.title != 'DOCUMENTED_TRAINING'"
        type="button"
        (cbOnSuccess)="copyDocumnetUrl()"
        [ngxClipboard]="Name"
      >
        <span class="icon ic-sm icon-copy-to-clipboard"></span>
        <span translate>COPY_CLOSE</span>
      </button>
    </div>
  </form>
</p-dialog>

<p-dialog
  header="{{ 'HELPANDSUPPORT' | translate }}"
  [(visible)]="openVideoPopup"
  [style]="{ width: '600px' }"
  [resizable]="false"
  [draggable]="false"
  (onHide)="cancel()"
  [contentStyle]="{ 'min-height': '400px' }"
>
  <div class="form-group">
    <label>
      <span translate>{{ VideoTitle }}</span>
    </label>
    <div *ngIf="isVideos" class="wb-content">
      <iframe
        width="560"
        height="315"
        [src]="videoLink"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="closePopUp()"
      >
        <span translate>CLOSE</span>
      </button>
    </div>
  </p-footer>
</p-dialog>

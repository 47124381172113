import { CountryCodeResolver } from './../employees/services/employeeWorkExperience.resolver.service';
import { KubaNorgeAsComponent } from './components/kuba-norge-as.component';
import { ContactTypeResolver } from './../contact/services/contact.resolver.service';
import { NewEditorContactComponent } from './components/new-editorcontacts.component';
import {
    EditorContactsEditResolver,
    FunctionResolver,
    EditorContactListByBusinessResolver,
    KubaNorgeAsResolver,
} from './services/editorcontacts.services.resolver';
import { EditorContactListComponent } from './components/editorcontact-list.component';
import { EditorContactsComponent } from './editorcontacts.component';
import { Route } from '@angular/router';
import { AuthGuard } from '../../_guards/index';

export const EditorContactsRoutes: Route[] = [
    {
        path: 'editorcontact',
        component: EditorContactsComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'list',
                component: EditorContactListComponent,
                resolve: {
                    list: EditorContactListByBusinessResolver,
                    contactType: ContactTypeResolver
                }
            },
            {
                path: 'our',
                component: KubaNorgeAsComponent,
                resolve: {
                    list: KubaNorgeAsResolver,
                    function: FunctionResolver
                }
            }
        ]
    },
    {
        path: 'new',
        component: NewEditorContactComponent,
        resolve: {
            function: FunctionResolver,
            dropdown: CountryCodeResolver,
        }
    },
    {
        path: 'edit/:cId',
        component: NewEditorContactComponent,
        resolve: {
            edit: EditorContactsEditResolver,
            dropdown: CountryCodeResolver,
            function: FunctionResolver
        }
    }
];

<div class="tab-content">
  <div class="page-title">
    <span>
      <span translate>COOLING_HEATING</span>
    </span>
    <span class="page-actions">
      <a
        class="btn btn-success"
        (click)="addBatchAndMeasurement(null, 'add', null)"
      >
        <span class="icon ic-sm icon-add"></span>
        <span>
          <span translate>NEW_BATCH</span>
        </span>
      </a>
    </span>
  </div>
  <div class="table-view mbtm-30">
    <p-table
      #dt
      [value]="coolingList"
      [rows]="10"
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
      expandableRows="true"
      dataKey="Id"
      [(selection)]="selectedBatches"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 55px">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th style="width: 40px"></th>
          <th style="width: 130px" translate>TYPE</th>
          <th translate style="width: 80px">NR</th>
          <th translate>PRODUCT</th>
          <th pSortableColumn="DisplayRegisterdDateTime" translate>
            REGISTERED_DATE
            <p-sortIcon field="NofDaysWithPercentageAbsence"></p-sortIcon>
          </th>
          <th pSortableColumn="RegisteredBy" translate>
            REGISTERED_BY
            <p-sortIcon field="NofDaysWithPercentageAbsence"></p-sortIcon>
          </th>
          <th translate style="width: 130px">STATUS</th>
          <th translate style="width: 140px">TEMPERATURE_RC</th>
          <th translate style="width: 100px; text-align: center">ACTIONS</th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th>
            <p-dropdown
              [options]="controlTypeOptions"
              (onChange)="onControlTypeChange($event)"
              placeholder="Select Control Type"
              class="p-column-filter"
              [showClear]="true"
              appendTo="body"
            >
            </p-dropdown>
          </th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                dt.filter($any($event.target)?.value, 'Prefix', 'contains')
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th>
            <p-dropdown
              width="118px"
              [options]="productsList"
              (onChange)="onProductChange($event, ed)"
              #ed
              styleClass="p-column-filter"
              placeholder=""
              [showClear]="true"
              appendTo="body"
              [filter]="true"
              filterPlaceholder="Search"
              filterBy="label"
            >
              <ng-template let-product pTemplate="item">
                {{ product.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th></th>
          <th></th>
          <th>
            <p-dropdown
              [options]="statusOptions"
              placeholder="Select Status"
              (onChange)="onStatusFilterChange($event)"
              class="p-column-filter"
              [showClear]="true"
              appendTo="body"
            ></p-dropdown>
          </th>
          <th>
            <input
              pInputText
              type="text"
              (input)="onTemperatureFilter($event)"
              class="p-column-filter"
            />
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-cooling
        let-expanded="expanded"
        let-i="rowIndex"
      >
        <tr>
          <td>
            <p-tableCheckbox id="selectedProduct" [value]="cooling">
            </p-tableCheckbox>
          </td>
          <td>
            <button
              type="button"
              [pRowToggler]="cooling"
              class="btn btn-icon"
              (click)="toggleClick(cooling)"
            >
              <i
                [ngClass]="expanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
              ></i>
            </button>
          </td>
          <td class="table-icons">
            <span>
              <img
                [src]="getControlTypeIcon(cooling.ControlType)"
                alt="Control Type Icon"
                class="heating-cooling-icon"
              />
            </span>
          </td>
          <td>
            <span>{{ cooling.Prefix }}</span>
          </td>
          <td>
            <span>              
              <a
                href="javascript:void(0);"
                routerLinkActive="active"
                (click)="addBatchAndMeasurement(cooling, 'edit', cooling)"
                >{{ cooling.ProductName }}</a
              >
            </span>
          </td>
          <td>
            <span>{{ cooling.DisplayRegisterdDateTime }}</span>
          </td>
          <td>
            <span>{{ cooling.RegisteredBy }}</span>
          </td>
          <td class="table-icons">
            <span
              *ngIf="cooling.Status == 1"
              class="icon ic-sm icon-star-circle text-secondary"
            ></span>
            <span
              *ngIf="cooling.Status == 2"
              class="icon ic-sm icon-clear-deadline text-accent-1"
            ></span>
            <span
              *ngIf="cooling.Status == 3"
              class="icon ic-sm icon-clear-deadline"
            ></span>
          </td>
          <td>
            <span style="margin-left: 10px">{{
              cooling.CoolingMeasurements[
                cooling.CoolingMeasurements.length - 1
              ].DispalyTemperatureValue
            }}</span>
          </td>
          <td class="col-button">
            <button
              type="submit"
              class="btn text-primary"
              (click)="generateReport(cooling)"
            >
              <i class="icon ic-sm icon-print"></i>
            </button>
            <button
              type="button"
              class="btn btn-icon"
              title="{{ 'DELETE' | translate }}"
              (click)="deleteBatch(cooling.Id)"
            >
              <i class="icon ic-sm icon-trash"></i>
            </button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-measurement>
        <tr>
          <td colspan="10">
            <p-table [value]="measurement.CoolingMeasurements">
              <ng-template pTemplate="header">
                <tr>
                  <th translate>DATE_TIME</th>
                  <th translate>FOOD_SAFETY_TEMPRATURE</th>
                  <th translate>STATUS</th>
                  <th translate>REGISTERED_BY</th>
                  <th translate>COMMENTS</th>
                  <th translate>ACTIONS</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-coolingMeasurement>
                <tr>
                  <td>{{ coolingMeasurement.RegisterdDateTime }}</td>
                  <td>{{ coolingMeasurement.DispalyTemperatureValue }}</td>
                  <td translate>
                    {{ coolingMeasurement.Status == 1 ? "OK" : "COMMENT" }}
                  </td>
                  <td>{{ coolingMeasurement.RegisteredBy }}</td>
                  <td>{{ coolingMeasurement.Comments }}</td>
                  <td class="col-button">
                    <button
                      *ngIf="coolingMeasurement.BatchStatus == 1"
                      type="button"
                      class="btn btn-icon"
                      title="{{ 'EDIT' | translate }}"
                      (click)="
                        addBatchAndMeasurement(
                          measurement,
                          'editMeasurement',
                          coolingMeasurement
                        )
                      "
                    >
                      <i class="icon ic-sm icon-pencil-tip"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-icon"
                      title="{{ 'DELETE' | translate }}"
                      (click)="
                        deleteMeasurement(
                          coolingMeasurement.Id,
                          coolingMeasurement.BatchId
                        )
                      "
                    >
                      <i class="icon ic-sm icon-trash"></i>
                    </button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </td>
        </tr>
      </ng-template>
      <!-- Footer -->
      <ng-template pTemplate="summary">
        <div class="footer-data">
          <div class="symbol-descriptions">
            <div class="symbol-desc-title">
              <span translate>DESCRIPTION_SYMBOLS</span>
            </div>
            <div class="symbol-desc-items">
              <span class="clear-deadline">
                <i class="icon ic-md icon-clear-deadline"></i>
                <span>
                  <span translate>DONE_STATUS</span>
                </span>
              </span>
              <span class="text-accent-1">
                <i class="icon ic-md icon-clear-deadline"></i>
                <span>
                  <span translate>COMPLETED_COMMENT</span>
                </span>
              </span>
              <span class="text-secondary">
                <i class="icon ic-md icon-star-circle"></i>
                <span>
                  <span translate>TASK_STARTED</span>
                </span>
              </span>
              <span class="text-secondary">
                <img
                  src="/assets/icons/cooling.png"
                  alt=""
                  class="heating-cooling-icon"
                />
                <span class="text-cooling">
                  <span translate>COOLING</span>
                </span>
              </span>
              <span class="text-secondary">
                <img
                  src="/assets/icons/heating.png"
                  alt=""
                  class="heating-cooling-icon"
                />
                <span class="text-heating">
                  <span translate>HEATING</span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="9">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<div class="mbtm-30 action-btns-wrapper">
  <button type="button" class="btn btn-primary" (click)="generateReport(0)">
    <span translate>GENERATE_REPORT</span>
  </button>
</div>
<p-dialog
  [(visible)]="showNewBatchForm"
  [style]="{ width: '500px' }"
  [resizable]="false"
  [draggable]="false"
  [modal]="true"
  [ngClass]="{
    'cooling-dialog': isCoolorHeat == 1,
    'heating-dialog': isCoolorHeat == 2
  }"
>
  <p-header *ngIf="addBatch">{{ "NEW_BATCH" | translate }}</p-header>
  <p-header *ngIf="editBatch"
    >{{ ControlTypeText | translate }}: {{ productName }} -
    {{ "ADD_MEASUREMENT" | translate }}</p-header
  >
  <p-header *ngIf="editMeasurement"
    >{{ ControlTypeText | translate }}: {{ productName }} -
    {{ "EDIT_MEASUREMENT" | translate }}</p-header
  >
  <div *ngIf="editBatch" class="form-group">
    <label class="col-form-label" translate>PREVIOUS_MEASUREMENT </label>
  </div>
  <div *ngIf="editBatch">
    <p-table [value]="measurementList">
      <ng-template pTemplate="header">
        <tr>
          <th translate style="width: 140px">DATE_TIME</th>
          <th translate>FOOD_SAFETY_TEMPRATURE</th>
          <th translate style="width: 60px">STATUS</th>
          <th translate>REGISTERED_BY</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-measurement>
        <tr>
          <td>{{ measurement.RegisterdDateTime }}</td>
          <td>{{ measurement.DispalyTemperatureValue }}</td>
          <td>
            <span
              translate
              pTooltip="{{
                measurement.Status == 1 ? 'OK' : ('COMMENT' | translate)
              }}"
              tooltipPosition="right"
              >{{ measurement.Status == 1 ? "OK" : "COMMENT" }}</span
            >
          </td>
          <td>{{ measurement.RegisteredBy }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <form [formGroup]="batchForm">
    <div *ngIf="addBatch" class="form-group">
      <label for="ControlType" class="col-form-label" translate
        >TYPE
        <span class="required">*</span>
      </label>
      <div class="input-btn-group">
        <p-dropdown
          [options]="controlType"
          formControlName="controlId"
          optionLabel="label"
          optionValue="value"
        >
        </p-dropdown>
      </div>
      <label for="ProductName" class="col-form-label" translate
        >PRODUCT
        <span class="required">*</span>
      </label>
      <div class="input-btn-group">
        <p-dropdown [options]="products" formControlName="productId">
        </p-dropdown>
        <button
          class="btn btn-outline-primary"
          type="button"
          (click)="addProduct = !addProduct"
        >
          <i class="pi pi-plus" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="form-group" [ngClass]="{ 'ptop-20': editBatch == true }">
      <label for="Measurement" class="col-form-label" translate
        >TIME_OF_MEASUREMENT
        <span class="required">*</span>
      </label>
      <div class="input-btn-group">
        <p-calendar
          [timeOnly]="true"
          readonlyInput="true"
          formControlName="timeOfMeasurement"
          inputId="timeonly"
        ></p-calendar>
        <button
          type="button"
          class="btn btn-primary ml-2"
          (click)="setCurrentTime()"
          [ngClass]="{
            'cooling-button': isCoolorHeat == 1,
            'heating-button': isCoolorHeat == 2
          }"
        >
          <span translate>NOW</span>
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          (click)="clear()"
        >
          <span translate>CLEAR</span>
        </button>
      </div>
    </div>
    <div class="form-group">
      <label for="Temperature" class="col-form-label mr-2" translate
        >TEMPERATURE_RC
        <span class="required">*</span>
      </label>
      <p-spinner
        size="6"
        (keypress)="numberOnly1($event)"
        [disabled]="isReadonly"
        formControlName="temperature"
        [step]="1.0"
        [min]="minTemperature"
        [max]="maxTemperature"
        readonly
      >
      </p-spinner>
    </div>
    <div>
      <label for="Comments" class="col-form-label" translate>COMMENTS </label>
      <div>
        <textarea pInputTextarea formControlName="Comments"></textarea>
      </div>
    </div>
    <div style="padding-top: 10px; margin-top: 10px">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="backtoList()"
      >
        <span class="icon ic-xs icon-back"></span>
        <span translate>CLOSE</span>
      </button>
      <button
        *ngIf="showSaveButton"
        class="btn btn-primary"
        type="submit"
        (click)="saveBatch('save')"
        [disabled]="!batchForm.valid || saveDisable"
        [ngClass]="{
          'cooling-button': isCoolorHeat == 1,
          'heating-button': isCoolorHeat == 2
        }"
      >
        <span translate>SAVE</span>
      </button>
      <button
        *ngIf="editBatch && showSaveButton"
        class="btn btn-primary"
        type="submit"
        (click)="saveBatch('complete')"
        [disabled]="!batchForm.valid"
        [ngClass]="{
          'cooling-button': isCoolorHeat == 1,
          'heating-button': isCoolorHeat == 2
        }"
      >
        <span translate>SAVE_AND_COMPLETE</span>
      </button>
    </div>
  </form>
  <div class="mtop-20 bold" *ngIf="isCoolorHeat == 1">
    <p translate style="color: red">GUIDE_TEXT</p>
    <p translate>GUIDE_TEXT_1</p>
    <p translate>GUIDE_TEXT_2</p>
    <p translate>GUIDE_TEXT_3</p>
    <p translate>GUIDE_TEXT_4</p>
    <p translate>GUIDE_TEXT_5</p>
    <p translate class="ptop-20">GUIDE_TEXT_6</p>
    <p translate>GUIDE_TEXT_7</p>
    <p translate>GUIDE_TEXT_8</p>
  </div>
  <div class="mtop-20 bold" *ngIf="isCoolorHeat == 2">
    <p translate style="color: red">HEATING_GUIDE_TEXT</p>
    <p translate>HEATING_GUIDE_TEXT_1</p>
    <p translate>HEATING_GUIDE_TEXT_2</p>
  </div>
</p-dialog>
<p-dialog
  header="{{ 'ADD_PRODUCT' | translate }}"
  [style]="{ width: '350px' }"
  [resizable]="false"
  [draggable]="false"
  [(visible)]="addProduct"
  [responsive]="true"
  [modal]="true"
>
  <form [formGroup]="productForm">
    <div class="form-group">
      <label for="ProductName" class="col-form-label" translate
        >PRODUCT
        <span class="required">*</span>
      </label>
      <div>
        <input
          type="text"
          id="title"
          class="label-input form-control"
          formControlName="productName"
        />
      </div>
      <label
        class="col-form-label"
        *ngIf="productExists"
        [ngStyle]="{ color: 'red' }"
      >
        <span translate>TOPIC_EXIST</span>
      </label>
    </div>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="showProducts = !showProducts"
          (click)="viewProduct()"
        >
          <span translate>VIEW</span>
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="!productForm.valid"
          (click)="saveProduct()"
        >
          <span translate>SAVE</span>
        </button>
      </div>
      <label
        for="title"
        color
        class="col-form-label"
        *ngIf="isProductAllocated"
        [ngStyle]="{ color: 'red' }"
      >
        <span translate>CANNOT_DELETE</span>
      </label>
      <div class="table-view mtop-20" *ngIf="showProducts">
        <p-table
          [value]="productList"
          [rows]="5"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "PRODUCT_NAME" | translate }}</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr>
              <td>
                <span>{{ product.Name }}</span>
              </td>
              <td class="col-button">
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="editProduct(product)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deleteProduct(product)"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-footer>
  </form>
</p-dialog>
<toaster-component></toaster-component>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>

<div class="panel-loader" *ngIf="loading">
  <span>
    <span class="loader-icon"></span>
    <span translate>PROCESSING</span>
  </span>
</div>

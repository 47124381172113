import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ActivityLogServices {
    constructor(private http: HttpClient) {}
    getAllActivityLog() {
        return this.http
            .get(
                environment.BASE_URL + '/activitylogs',

            )
            .map((result: any) => result);
    }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { FeatureKey } from 'src/app/_models';

@Component({
  selector: 'fdv-view',
  templateUrl: 'fdvView.component.html',
})
export class FdvViewComponent implements OnInit {
  pageTitle: string;
  additionalFeatures: any;
  enabledAdditionalFeatures: any;
  fdvId: number;
  initialPage: any;
  constructor(private route: Router, private activatedRoute: ActivatedRoute) {
    this.fdvId = this.activatedRoute.snapshot.params['id'];
    let allAdditionalFeatures = JSON.parse(
      sessionStorage.getItem('workingModule')
    ).children;
    let fdvData = this.activatedRoute.snapshot.data['edit'];
    this.pageTitle = fdvData.Title;
    sessionStorage.setItem('FdvName', this.pageTitle);
    this.enabledAdditionalFeatures = allAdditionalFeatures.filter(
      (element) => element.checked || element.IsDefault
    );
    this.enabledAdditionalFeatures = this.enabledAdditionalFeatures.filter(
      (x) => x.id !== 164
    );
    let index = _.findIndex(this.enabledAdditionalFeatures, {
      translationKey: 'DETAILS',
    });
    if (index >= 0) {
      this.enabledAdditionalFeatures[index].translationKey = 'FDV_DETAILS';
    }
    this.enabledAdditionalFeatures = this.enabledAdditionalFeatures.filter(
      (element) => element.id !== FeatureKey.SJA_CHECKLIST
    );

    if (this.enabledAdditionalFeatures.length > 0) {
      this.route.navigate(
        [this.enabledAdditionalFeatures[0].linkText, { Id: this.fdvId }],
        { relativeTo: this.activatedRoute }
      );
    }

  }

  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
  }
}

<div class="main-content">
    <div class="card">
        <div class="card-body">
            <div class="page-title">
                <span translate>HELPANDSUPPORT</span>
            </div>
            <form [formGroup]="helpsupportForm">
                <div class="form-group row">
                    <div class="col-md-4 text-nowrap">
                        <label for="title" class="col-form-label">
                            <span translate>CATEGORY_NAME</span>
                        </label>
                        <span class="required">*</span>
                    </div>
                    <div class="col-md-8">
                        <p-dropdown [disabled]="IsCategoryName" formControlName="CategoryName" id="CategoryName"
                          [options]="categoryName" (onChange)="onCatrgoryChanged($event)"
                          [style]="{'width':'100%'}"></p-dropdown>
                        <control-messages [control]="helpsupportForm.controls['CategoryName']"></control-messages>
                    </div>
                </div>
                <div class="form-group row" *ngIf="IsStory">
                    <div class="col-md-4">
                        <label for="title" class="col-form-label text-nowrap">
                            <span translate>PUBLISHED_DATE</span>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <my-date-picker [style]="{'width':'160px'}" [options]="publishedDateOpitons"
                          formControlName="PublishedOn" placeholder="{{'SELECT_PUBLISHED_DATE'|translate}}">
                        </my-date-picker>
                    </div>
                </div>
                <div class="form-group row" *ngIf="!IsEmail && IsStory">
                    <div class="col-md-4">
                        <label for="title" class="col-form-label">
                            <span translate>TITLE</span>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <input type="text" class="label-input form-control" formControlName="Title">
                    </div>
                </div>
                <div class="form-group row" *ngIf="!IsEmail && IsStory">
                    <div class="col-md-4 text-nowrap">
                        <label for="title" class="col-form-label">
                            <span translate>MODULE</span>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <p-dropdown formControlName="ModuleName" id="ModuleName" [options]="moduleName"
                          [style]="{'width':'100%'}"></p-dropdown>
                        <control-messages [control]="helpsupportForm.controls['ModuleName']"></control-messages>
                    </div>
                </div>
                <div class="form-group row" *ngIf="IsEmail">
                    <div class="col-md-4">
                        <label for="email" class="col-form-label">
                            <span translate>EMAIL</span>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <input type="text" class="label-input form-control" formControlName="EmailId">
                    </div>
                </div>
                <div class="form-group row" *ngIf="!IsEmail && IsVideo">
                    <div class="col-md-4">
                        <label for="contentlink" class="col-form-label">
                            <span translate>CONTENT_LINK</span>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <input type="text" class="label-input form-control"
                          placeholder="https://www.youtube.com/embed/sX6f6iUL8E8" formControlName="ContentLink">
                    </div>
                </div>
                <div *ngIf="!IsEmail" class="mb-3">
                    <h3 class="first">
                        <span translate>CONTENT</span>
                    </h3>
                    <p-editor formControlName="Content" [style]="{'height':'320px'}"></p-editor>

                    <hr style="border-top:0px;border-color:#dde3e6">
                </div>
                <div class="action-btns-wrapper">
                    <button *ngIf="isAdmin" type="submit" class="btn btn-primary" (click)="Save()"
                      [disabled]="!helpsupportForm.valid">
                        <span translate>SAVE</span>
                    </button>
                    <button type="button" class="btn btn-primary" *ngIf="IsStory" [disabled]="!helpsupportForm.valid"
                      (click)="SaveAndPublish()">
                        <span translate>PUBLISH</span>
                    </button>
                    <a routerLink="create" [routerLink]="['../../list']" class="btn btn-secondary"
                      routerLinkActive="active">
                        <span translate>BACK_LIST</span>
                    </a>
                </div>
            </form>
        </div>
    </div>
    <toaster-component></toaster-component>
</div>
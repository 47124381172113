<div class="sub-panel-title">
  <span translate>SJA_SCHEME</span>
</div>

<table class="table table-bordered SjaTable" *ngIf="newSJAForm">
  <tbody>
    <tr>
      <td class="SjaRow">
        <div class="form-group">
          <label class="col-form-label">
            <span translate>SJA_TITLE</span>
            <span class="required">*</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown
              [filter]="true"
              [(ngModel)]="newSJAForm.SjaTitleId"
              [options]="editSJATitle"
              [style]="{ width: '100%' }"
              placeholder="{{ 'SELECT' | translate }}"
            >
              <ng-template let-sjaTitle pTemplate="item">{{
                sjaTitle.label | translate
              }}</ng-template>
            </p-dropdown>
            <button
              class="btn btn-outline-primary"
              type="button"
              (click)="AddSJATitle()"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>
<table class="table table-bordered SjaTable">
  <tbody>
    <tr>
      <td class="SjaRow" style="width: 22%">
        <label class="col-form-label">
          <span translate>CHECK_FOR_COMPLETED_CHECKLIST</span>
        </label>
      </td>
      <td class="SjaRow text-center" style="width: 4%; padding: 35px">
        <input
          type="checkbox"
          [(ngModel)]="newSJAForm.IsCheckCompletedChecklist"
        />
      </td>
      <td class="SjaRow">
        <div class="form-group">
          <label class="col-form-label">
            <span translate>LOCATION</span>
          </label>
          <div>
            <input
              type="text"
              class="label-input form-control"
              [(ngModel)]="newSJAForm.Location"
            />
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<table class="table table-bordered SjaTable">
  <tbody>
    <tr>
      <td class="SjaRow">
        <div class="form-group">
          <label class="col-form-label">
            <span translate>DATE</span>
          </label>
          <div>
            <my-date-picker
              [options]="DateOptions"
              [(ngModel)]="newSJAForm.Date"
              (keydown)="dateKeyUp()"
            >
            </my-date-picker>
          </div>
        </div>
      </td>
      <td class="SjaRow">
        <div class="form-group">
          <label class="col-form-label">
            <span translate>PARTICIPANTS</span>
          </label>
          <div>
            <input
              type="text"
              class="label-input form-control"
              [(ngModel)]="newSJAForm.Participants"
            />
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<table class="table table-bordered SjaTable">
  <tbody>
    <tr>
      <td class="SjaRow">
        <div class="form-group">
          <label class="col-form-label">
            <span translate>SJA_RESPONSIBLE</span>
          </label>
          <div>
            <input
              type="text"
              class="label-input form-control"
              [(ngModel)]="newSJAForm.SjaResponsible"
            />
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<table class="table table-bordered SjaTable">
  <tbody>
    <tr>
      <td class="SjaRow">
        <div class="form-group">
          <label class="col-form-label">
            <span translate>JOB_DESCRIPTION</span>
          </label>
          <div>
            <textarea
              class="form-control"
              [(ngModel)]="newSJAForm.JobDescription"
            ></textarea>
          </div>
        </div>
      </td>
      <td class="SjaRow">
        <div class="form-group">
          <label class="col-form-label">
            <span translate>RISK_ASSOCIATED_WITH_WORK</span>
          </label>
          <div>
            <textarea
              class="form-control"
              [(ngModel)]="newSJAForm.RiskAssociatedWithWork"
            ></textarea>
          </div>
        </div>
      </td>
      <td class="SjaRow">
        <div class="form-group">
          <label class="col-form-label">
            <span translate>PROTECTION_OR_SECURITY</span>
          </label>
          <div>
            <textarea
              class="form-control"
              [(ngModel)]="newSJAForm.ProtectionOrSecurity"
            ></textarea>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>
<table class="table table-bordered SjaTable">
  <tbody>
    <tr>
      <td class="SjaRow">
        <div class="form-group">
          <label class="col-form-label">
            <span translate>CONCLUSION_OR_COMMENTS</span>
          </label>
          <div>
            <textarea
              class="form-control"
              [(ngModel)]="newSJAForm.ConclusionOrComments"
            ></textarea>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<table class="table table-bordered SjaTable">
  <tbody>
    <tr>
      <th translate class="SjaRowHeading">RECOMMENDATION_OR_APPROVAL</th>
      <th translate class="SjaRowHeading">
        <span translate>DATE_OR_SIGN</span>
        <div>
          <my-date-picker
            [options]="DateOptions"
            [(ngModel)]="newSJAForm.SjaResponsibleSignedDate"
            (keydown)="dateKeyUp()"
          ></my-date-picker>
        </div>
      </th>
      <th translate class="SjaRowHeading">RECOMMENDATION_OR_APPROVAL</th>
      <th translate class="SjaRowHeading">
        <span translate>DATE_OR_SIGN</span>
        <div>
          <my-date-picker
            [options]="DateOptions"
            [(ngModel)]="newSJAForm.AreaManagerSignedDate"
            (keydown)="dateKeyUp()"
          >
          </my-date-picker>
        </div>
      </th>
    </tr>

    <tr>
      <td translate class="sja-use-form-row">SJA_RESPONSIBLE</td>
      <td class="sja-use-form-row">
        <span translate>ENTER_YOUR_SIGN</span>
        <div class="form-group" style="border: 1px solid #999999">
          <canvas #canvas> </canvas>
        </div>
        <button (click)="clearSign()" class="btn btn-outline-secondary">
          <span translate>CLEAR_SIGN</span>
        </button>
        <strong class="pull-right">{{
          newSJAForm.DigitalResponsibleSign
        }}</strong>
      </td>
      <td translate class="sja-use-form-row">AREA_MANAGER</td>
      <td class="sja-use-form-row">
        <span translate>ENTER_YOUR_SIGN</span>
        <div class="form-group" style="border: 1px solid #999999">
          <canvas #canvas1> </canvas>
        </div>
        <button (click)="clearSign1()" class="btn btn-outline-secondary">
          <span translate>CLEAR_SIGN</span>
        </button>
        <strong class="pull-right">{{
          newSJAForm.DigitalAreaManagerSign
        }}</strong>
      </td>
    </tr>

    <tr>
      <th class="SjaRowHeading"></th>
      <th class="SjaRowHeading">
        <span translate>DATE_OR_SIGN</span>
        <div>
          <my-date-picker
            [options]="DateOptions"
            [(ngModel)]="newSJAForm.ResponsibleExecutionSignedDate"
            (keydown)="dateKeyUp()"
          ></my-date-picker>
        </div>
      </th>
      <th class="SjaRowHeading"></th>
      <th class="SjaRowHeading">
        <span translate>DATE_OR_SIGN</span>
        <div>
          <my-date-picker
            [options]="DateOptions"
            [(ngModel)]="newSJAForm.OthersSignedDate"
            (keydown)="dateKeyUp()"
          >
          </my-date-picker>
        </div>
      </th>
    </tr>

    <tr>
      <td translate class="sja-use-form-row">RESPONSIBLE_FOR_EXECUTION</td>
      <td class="sja-use-form-row">
        <span translate>ENTER_YOUR_SIGN</span>
        <div class="form-group" style="border: 1px solid #999999">
          <canvas #canvas2> </canvas>
        </div>
        <button (click)="clearSign2()" class="btn btn-outline-secondary">
          <span translate>CLEAR_SIGN</span>
        </button>
        <strong class="pull-right">{{
          newSJAForm.DigitalExecutionSign
        }}</strong>
      </td>
      <td translate class="sja-use-form-row">OTHER_POSITION</td>
      <td class="sja-use-form-row">
        <span translate>ENTER_YOUR_SIGN</span>
        <div class="form-group" style="border: 1px solid #999999">
          <canvas #canvas3> </canvas>
        </div>
        <button (click)="clearSign3()" class="btn btn-outline-secondary">
          <span translate>CLEAR_SIGN</span>
        </button>
        <strong class="pull-right">{{ newSJAForm.DigitalOtherSign }}</strong>
      </td>
    </tr>
  </tbody>
</table>

<div *ngIf="newSJAForm.IsCheckCompletedChecklist">
  <h4 translate class="text-center">CHECKLIST</h4>
  <div>
    <table class="table table-hover table-bordered SjaTable">
      <tbody>
        <tr style="background-color: #efefef">
          <th class="SjaChecklistHeading"></th>
          <th translate class="SjaChecklistHeading">HSC_ASPECT</th>
          <th translate class="SjaChecklistHeading">Yes</th>
          <th translate class="SjaChecklistHeading">No</th>
          <th translate class="SjaChecklistHeading">NOT_APPLICABLE</th>
          <th translate class="SjaChecklistHeading">COMMENTS_OR_MEASURES</th>
          <th translate class="SjaChecklistHeading">RESPO</th>
        </tr>

        <tr *ngFor="let item of SjaCheckList; let i = index">
          <td class="SjaRow text-right col-button" style="width: 8%">
            <button
              *ngIf="item.IsParent"
              type="button"
              class="btn btn-icon"
              (click)="addItem(item)"
              title="Add Row"
            >
              <span class="icon ic-sm icon-add"></span>
            </button>
            <button
              *ngIf="SjaCheckList.length > 1"
              type="button"
              class="btn btn-icon"
              (click)="deleteItem(item, i)"
              title="Delete Row"
            >
              <span class="icon ic-sm icon-trash"></span>
            </button>
          </td>

          <td
            translate
            class="SjaRow"
            [ngClass]="{ parentField: item.IsParent }"
          >
            <input
              type="text"
              class="label-input form-control"
              [(ngModel)]="item.Title"
            />
          </td>
          <td class="SjaRow text-center" style="width: 4%; padding: 0">
            <input
              *ngIf="!item.IsParent"
              type="checkbox"
              [(ngModel)]="item.Yes"
              (change)="uncheckExceptYes(item)"
            />
          </td>
          <td class="SjaRow text-center" style="width: 4%; padding: 0">
            <input
              *ngIf="!item.IsParent"
              type="checkbox"
              [(ngModel)]="item.No"
              (change)="uncheckExceptNo(item)"
            />
          </td>
          <td class="SjaRow text-center" style="width: 4%; padding: 0">
            <input
              *ngIf="!item.IsParent"
              type="checkbox"
              [(ngModel)]="item.NotApplicable"
              (change)="uncheckExceptNotApplicable(item)"
            />
          </td>
          <td class="SjaRow">
            <input
              *ngIf="!item.IsParent"
              type="text"
              class="label-input form-control"
              [(ngModel)]="item.Comments"
            />
          </td>
          <td class="SjaRow">
            <input
              *ngIf="!item.IsParent"
              type="text"
              class="label-input form-control"
              [(ngModel)]="item.Respo"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <button
    type="button"
    class="btn btn-outline-success"
    (click)="addNewSection()"
  >
    <span class="icon ic-sm icon-add"></span>
    <span translate>NEW_SECTION</span>
  </button>
</div>
<div class="action-btns-wrapper">
  <button type="button" class="btn btn-outline-secondary" (click)="gotoList()">
    <span class="icon ic-xs icon-back"></span>
    <span translate>BACK_LIST</span>
  </button>
  <button
    *ngIf="hasRights"
    type="button"
    class="btn btn-primary"
    [disabled]="!newSJAForm.SjaTitleId || loading"
    (click)="saveForm()"
  >
    <span translate>SAVE</span>
  </button>
</div>
<toaster-component></toaster-component>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<p-dialog
  header="{{ 'SJA_TITLE' | translate }}"
  [(visible)]="addTitle"
  [style]="{ width: '350px' }"
  [resizable]="false"
  [draggable]="false"
>
  <form [formGroup]="SJATitleForm">
    <div class="form-group">
      <label class="col-form-label">
        <span translate>SJA_TITLE</span>
        <span class="required">*</span>
      </label>
      <div>
        <input
          type="text"
          formControlName="SjaTitle"
          class="label-input form-control"
          required
        />
      </div>
      <label
        color
        class="col-form-label"
        *ngIf="isLabelDisabled"
        [ngStyle]="{ color: 'red' }"
      >
        <span translate>TOPIC_EXIST</span>
      </label>
      <label
        color
        class="col-form-label"
        *ngIf="isTitleAllocated"
        [ngStyle]="{ color: 'red' }"
      >
        <span translate>CANNOT_DELETE</span>
      </label>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="viewSJATitle = !viewSJATitle"
        (click)="BindSJATitle()"
      >
        <span translate>VIEW</span>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="SJATitleForm.invalid"
        (click)="SaveTitle()"
      >
        <span translate>SAVE</span>
      </button>
    </div>
    <div *ngIf="viewSJATitle">
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          #dt
          [value]="editSJATitle"
          [rows]="5"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "SJA_TITLE" | translate }}</th>
              <th></th>
            </tr>
            <tr>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    dt.filter($any($event.target)?.value, 'label', 'contains')
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-sjaTitleData>
            <tr>
              <td>
                <span>{{ sjaTitleData.label }}</span>
              </td>
              <td class="col-button">
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="EditSJATitle(sjaTitleData)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="DeleteSJATitle(sjaTitleData)"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends -->
      </div>
    </div>
  </p-footer>
</p-dialog>

<p-progressBar
  mode="indeterminate"
  [style]="{ height: '6px' }"
  *ngIf="loading"
></p-progressBar>

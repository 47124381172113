
import { Component, OnInit } from '@angular/core';
import { BaseServices } from './../../kuba.services';
import { Router, ActivatedRoute } from '@angular/router';
import { FeaturesBaseServices } from './../../features/services/features-base.services';
import { ModuleOverviewServices } from './../../module-overview/services/module-overview.service';
import { Feature, FeatureBase } from './../../features/models/features-base.model';
import { ModuleOverview, ModuleOverviewDetails } from './../../module-overview/models/moduleoverview';
import * as _ from 'lodash';
import { FeatureKey } from 'src/app/_models';
import { Subscription } from 'rxjs';
@Component({
    selector: 'my-features',
    templateUrl: 'my-features-list.component.html',
    styleUrls: ['my-features-list.component.css']
})
export class MyFeaturesListComponent implements OnInit {
    featureBase: FeatureBase;
    userRole: string;
    businessId: number;
    isAdmin: boolean;
    isChecked = false;
    moduleoverview = new ModuleOverviewDetails();
    showFeature: boolean;
    featureName: string;
    featureDesc: string;
    private subscriptions: Subscription[] = [];
    constructor(private route: ActivatedRoute, private moduleServices: ModuleOverviewServices) { }
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        let roleId = window.name === 'Remote' ? 'Remote' : BaseServices.roleId;
        this.isAdmin = roleId === '1' ? true : false;
        let featuresBaseDetails = [];
        switch (roleId) {
            case '1':
                this.userRole = 'admin';
                this.featureBase = BaseServices.getUserFeatures();
                break;
            case '2':
                this.userRole = 'portal';
                this.featureBase = BaseServices.getUserFeatures();
                featuresBaseDetails = <Feature[]>JSON.parse(this.featureBase.Features);
                break;
            case '3':
                this.userRole = 'business';
                featuresBaseDetails = FeaturesBaseServices.getFeatureDetails(BaseServices.getUserFeatures());
                break;
            case 'Remote':
                this.subscriptions.push(this.moduleServices.getRModeModuleOverview().subscribe((feature: any) => {
                    if (feature) {

                        featuresBaseDetails = FeaturesBaseServices.getFeatureDetails(feature);
                    }
                }));
                break;
        }
        this.businessId = BaseServices.BusinessId;
        if (roleId != '1') {
            this.subscriptions.push(this.moduleServices.getAllModuleOverviewDetails().subscribe((result: any) => {
                if (result) {
                this.moduleoverview.HSE_MODULES = [];
                this.moduleoverview.ADDITIONAL_MODULES= [];
                this.moduleoverview.HR_MODULES = [];
                this.moduleoverview.OTHER = [];
                this.moduleoverview.ALTERNATIVE_SETTINGS = [];
                this.moduleoverview.HSE_MODULES = (result && result.HSE_MODULES.length > 0) ? result.HSE_MODULES : [];
                this.moduleoverview.ADDITIONAL_MODULES = (result && result.ADDITIONAL_MODULES.length > 0) ? result.ADDITIONAL_MODULES : [];
                this.moduleoverview.HR_MODULES = (result && result.HR_MODULES.length > 0) ? result.HR_MODULES: [];
                this.moduleoverview.OTHER = (result && result.OTHER.length > 0) ? result.OTHER: [];
                this.moduleoverview.ALTERNATIVE_SETTINGS = (result && result.ALTERNATIVE_SETTINGS.length > 0) ? result.ALTERNATIVE_SETTINGS : [];
                    if (featuresBaseDetails.length > 0 && this.moduleoverview) {
                        this.moduleoverview.HR_MODULES.forEach(element => {
                            this.isChecked = this.flattenFind(featuresBaseDetails, element.FeatureId);
                            element.checked = this.isChecked;
                        });
                        this.moduleoverview.ADDITIONAL_MODULES.forEach(element => {
                            this.isChecked = this.flattenFind(featuresBaseDetails, element.FeatureId);
                            element.checked = this.isChecked;
                        });
                        this.moduleoverview.HR_MODULES.forEach(element => {
                            this.isChecked = this.flattenFind(featuresBaseDetails, element.FeatureId);
                            element.checked = this.isChecked;
                        });
                        this.moduleoverview.OTHER.forEach(element => {
                            this.isChecked = this.flattenFind(featuresBaseDetails, element.FeatureId);
                            element.checked = this.isChecked;
                        });
                        this.moduleoverview.ALTERNATIVE_SETTINGS.forEach(element => {
                            this.isChecked = this.flattenFind(featuresBaseDetails, element.FeatureId);
                            element.checked = this.isChecked;
                        });
                    }
                }
            }));

        }



    }

    /**
     * recursive search in a nested array
     * @param data {array}
     * @param featureId {number}
     */
    flattenFind(data, key) {
        let filteroptions = {};
        filteroptions =  +key === 101 ? { id: +key , linkText : "" } :{ id: +key} ;
        let checkFeature = <Feature>_(data)
            .thru((parent) => {
                return _.union(parent, _.map(parent, 'children'));
            })
            .flatten()
            .find(filteroptions);
        if (checkFeature !== undefined) {
            return checkFeature.checked;
        } else {
            return false;
        }

    }

    /**
   * show popup
   * @param feature {any}
   */
    showTitlePopup(feature: any) {
        this.showFeature = true;
        this.featureName = feature.TranslationKey;
        this.featureDesc = feature.Description;
    }

    /**
  * reset popup
  */
    closePopUp() {

        this.showFeature = false;
    }

    cancel() {
        this.featureName = '';
        this.featureDesc = '';
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}

export class VisualControl {
    Id: number;
    Data: string;
    MeasureProtocolCompleteId: number;
    Status: number;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: Date;
    ModifiedOn: Date;
}

export class VisualControlData {
    Resistance: Resistance;
    Continuity: Continuity;
    Insulation: Insulation;
    Voltage: Voltage;
    Power: Power;
    Effect: Effect;
    Reactive: Reactive;
    Short: Short;
    constructor() {
    }
}

export class Resistance {
    MeasureValue: string;
    Method: string;
    constructor() {
        this.MeasureValue = '';
        this.Method = '';
    }
}

export class Continuity {
    HighestValue: string;
    Description: string;
}

export class Insulation {
    L1_PE: string;
    L2_PE: string;
    L3_PE: string;
    N_PE: string;
}

export class Voltage {
    L1_L2: string;
    L1_L3: string;
    L2_L3: string;
    L1_PE: string;
    L2_PE: string;
    L3_PE: string;
    N_L1: string;
    N_L2: string;
    N_L3: string;
}

export class Power {
    L1: string;
    L2: string;
    L3: string;
    N: string;
    PE: string;
}

export class Effect {
    L1: string;
    L2: string;
    L3: string;
    P_tot: string;
}

export class Reactive {
    S: string;
    Cosphi: string;
}

export class Short {
    Polmin: string;
    Description1: string;
    Polmax: string;
    Description2: string;
}

import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import 'rxjs/add/operator/map';
import {
    CompleteCheckList,
    DeviationIds,
    FoodSafetyManualCompleteChecklist,
    VehiclesCompleteChecklist
} from '../models/complete-checklist';
import { BaseServices } from './../../kuba.services';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable()
export class CompleteCheckListServices {

    constructor(private http: HttpClient) {
    }

    // Add Manual Complete checklist
    addAppCompleteCheckList(CompleteCheckList: CompleteCheckList) {
        return this.http
            .post(
                environment.BASE_URL + '/manual/checklist/complete/save',
                CompleteCheckList,

            )
            .map(result => result);
    }

    // Add Manual Complete checklist
    addFeatureCompleteCheckList(CompleteCheckList: CompleteCheckList) {
        return this.http
            .post(
                environment.BASE_URL + '/features/FeatureManualComplete/save',
                CompleteCheckList,

            )
            .map(result => result);
    }

    SendMailforDeviation(dIds: DeviationIds ){
        return this.http
            .post(
                environment.BASE_URL + '/features/FeatureManualComplete/sendemaildeviation/' ,dIds ,
            )
            .map(result => result);

    }

    // get feature checklist data
    getFeatureCheckListData(id: number) {
        return this.http
            .get(
                environment.BASE_URL +
                '/features/FeatureManualComplete/get/checklistdata/' +
                id,

            )
            .map(result => result);
    }
    // get feature checklist data
    getManualCheckListData(id: number) {
        return this.http
            .get(
                environment.BASE_URL +
                '/manual/checklist/complete/get/checklistdata/' +
                id,

            )
            .map(result => result);
    }

    // get feature complete checklist data
    getFeatureCompleteCheckListData(id: number) {
        return this.http
            .get(
                environment.BASE_URL +
                '/features/FeatureManualComplete/get/' +
                id,

            )
            .map(result => result);
    }


    getRModeFeature() {
        return this.http.get<HttpHeaderResponse>(environment.BASE_URL + '/rmode-feature/' + BaseServices.BusinessId, )
        .pipe(map((response: HttpHeaderResponse) => {
            return response
        }));
}

    // get feature complete checklist data
    getManualCompleteCheckListData(id: number) {
        return this.http
            .get(
                environment.BASE_URL + '/manual/checklist/complete/get/' + id,

            )
            .map(result => result);
    }

    // get app manual checklist data
    getManualChecklistDetails(id: number) {
        return this.http
            .get(
                environment.BASE_URL + '/manual/0/checklist/retrive/' + id,

            )
            .map(result => result);
    }

    // get app manual checklist data
    getFeatureChecklistDetails(id: number) {
        return this.http
            .get(
                environment.BASE_URL + '/feature/checklist/' + id,

            )
            .map(result => result);
    }

    // get app manual checklist data
    getFoodSafetyChecklistById(id: number, key:any) {
        return this.http
            .get(
                environment.BASE_URL + '/'+key+'/CompleteChecklist/' + id,

            )
            .map(result => result);
    }

    GetAllFoodSafetyChecklistData(key:any) {
        return this.http
            .get(
                environment.BASE_URL + '/'+key+'/CompleteFoodSafetyData/' + BaseServices.BusinessId
            ).map(result => result);
    }

    GetFoodSafetyChecklistDataByFoodId(fId: number,key:any) {
        return this.http
            .get(
                environment.BASE_URL + '/'+key+'/CompleteFoodSafetyDataById/' + fId,
            ).map(result => result);
    }

    SaveFoodSafetyChecklistById(
        FoodSafetyManualCompleteChecklist: FoodSafetyManualCompleteChecklist, cultureInfo: string,key:any
    ) {
        return this.http
            .put(
                environment.BASE_URL + '/'+key+'/updateCompleteChecklistByFoodId/' + cultureInfo,
                FoodSafetyManualCompleteChecklist,

            )
            .map(result => result);
    }
    getCheckListReferenceData() {
        return this.http
            .get(
                environment.BASE_URL +
                '/features/FeatureManualComplete/get/checklistreference',

            )
            .map(result => result);
    }
    getCurrentDate() {
        return this.http
            .get(
                environment.BASE_URL +
                '/features/FeatureManualComplete/currentdate',

            )
            .map(result => result);
    }
    // get app manual checklist data
    getVehiclesChecklistById(id: number, fkey: number) {
        return this.http
            .get(
                environment.BASE_URL + '/vehicle/CompleteChecklist/' + id + '/' + fkey,

            )
            .map(result => result);
    }

    VehiclesChecklistById(
        VehiclesCompleteChecklist: VehiclesCompleteChecklist, cultureInfo: string
    ) {
        return this.http
            .put(
                environment.BASE_URL +
                '/vehicle/updateCompleteChecklistByVehicleId/' + cultureInfo,
                VehiclesCompleteChecklist,

            )
            .map(result => result);
    }

    // Complete checklist and generate report
    SaveAndCompleteCheckList(CompleteCheckList: CompleteCheckList, IsScoreIndex: boolean, CultureInfo: string) {
        return this.http
            .post(
                environment.BASE_URL + '/complete-checklist/' + IsScoreIndex + '/' + CultureInfo,
                CompleteCheckList,

            )
            .map(result => result);
    }

    getFeatureFolders(featureid: number, featureKey: number) {
        return this.http
            .get(
                environment.BASE_URL + '/feature/tree/featurefoldertreedata/' + featureid
                + '/' + featureKey,

            )
            .map(result => result);
    }

    generateUnderCompletionChecklistReport(CompleteCheckList: CompleteCheckList, IsScoreIndex: boolean, CultureInfo: string){
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: CompleteCheckList });
        let url = `${environment.BASE_URL}/print-undercompletion-checklist/${IsScoreIndex}/${CultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.withCredentials = true;
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }

     // Add FS step-1 Complete checklist
     SaveFirstStepCompleteCheckListForFS(CompleteCheckList: CompleteCheckList) {
        return this.http
            .post(
                environment.BASE_URL + '/features/FeatureManualComplete/save/fs-stepone/checklist',
                CompleteCheckList,

            )
            .map(result => result);
    }

    Deletefeaturecompletechecklist(Id: number){
        return this.http.delete(environment.BASE_URL + '/features/FeatureManualComplete/delete/fs-stepone/' + Id)
        .map(result => result);
    }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BaseServices } from "../../kuba.services";
import { Observable } from "rxjs";
import { ActivityEditDto } from "../ohs-plan";


@Injectable({
    providedIn: 'root'
})
export class FlexiServices {
    constructor(private http: HttpClient) { }


    // bind business dropdown
    getAdvisor(businessId: number) {
        return this.http
            .get<any>(
                environment.BASE_URL + '/flexi/getadvisor/' + businessId,

            )
            .map(result => result);
    }

    PostOHSData(ohsData) {
        return this.http
            .post(
                environment.BASE_URL +
                '/flexi/PostOHSContent', ohsData,

            )
            .map((response: Response) => {
                return response;
            });
    }

    updateOHSData(ohsData, id) {
        return this.http.put(environment.BASE_URL + '/flexi/updateOHSdata/' + id, ohsData, )
            .map((response: any) => { return response });
    }

    getOHSdata(businessId: number) {
        return this.http
            .get<any>(
                environment.BASE_URL + '/flexi/getOHSdata/' + businessId,

            )
            .map(result => result);
    }

    generateOHSFile(reportData: any, businessId: number, cultureInfo: string, ohsId: number, userId: number) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: reportData });
        let url = `${environment.BASE_URL}/flexi/export-article/${businessId}/${cultureInfo}/${ohsId}/${userId}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.withCredentials = true;
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }

    generateOHSPDFInDocumnet(data: any, businessId: number, cultureInfo: string, portalId: number, ohsId = 0, userId: number, featureId: number, featureKey: number) {
        let jsonString = JSON.stringify({ data });
        return this.http.post<any>(environment.BASE_URL + '/flexi/GeneratePDFInDocumnet/' + businessId + '/' + cultureInfo + '/' + portalId + '/' + ohsId + '/' + userId + '/' + featureId + '/' + featureKey, jsonString, )
            .map(result => result);
    }


    SaveOHSToDetailsActivity(dataItem: ActivityEditDto[], businessId: number, applicationId: number, userId: number) {
       // Send dataItem as an array with a single item
        return this.http.post<any>(
            environment.BASE_URL + '/flexi/save-ohsDetailsToactivity/' + businessId + '/' + applicationId + '/' + userId, dataItem,);
    }

    saveOHSActivity(data: any) {
        return this.http.post<any>(environment.BASE_URL + '/flexi/save-ohsactivity', data,)
            .map(result => result);
    }

    getDefaultOhsActivity() {
        let cultureInfo = sessionStorage.getItem('languageMode')
        let languageId = 2;
        return this.http.get<any>(environment.BASE_URL + '/flexi/get/defaultohsactivity/' + languageId,)
            .map(result => result);
    }

    getOhsActivityDetailsById(ohsId: number) {
        return this.http.get<any>(environment.BASE_URL + '/flexi/get/ohs-activity/' + ohsId + '/' + BaseServices.BusinessId,)
            .map(result => result);
    }

    DeleteOhsActivity(Id: number) {
        return this.http.delete(environment.BASE_URL + '/flexi/delete/' + Id,)
            .map(result => result);
    }
}

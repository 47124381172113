<div *ngIf="isGuestHideButton" class="page-title">
    <span class="page-actions">
        <a [routerLink]="['./../edit',0]" class="btn btn-success">
            <span class="icon ic-sm icon-add"></span>
            <span>
                <span translate>NEW</span>
            </span>
        </a>
    </span>
</div>
<div class="tab-content">
    <div class="table-view">
        <!-- getbusinesscontract -->
        <!-- Table starts -->
        <p-table [value]="electroList" #electroTable [rows]="10" [paginator]="true" [pageLinks]="3"
            [rowsPerPageOptions]="[5,10,20]"
            [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">

            <!-- Caption -->
            <ng-template pTemplate="caption">
                <div class="table-options">
                    <div class="pull-right">
                    </div>
                </div>
            </ng-template>
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="ContractNumber">{{'CONTRACT_NUMBER' | translate}} <p-sortIcon field="ContractNumber"></p-sortIcon></th>
                    <th pSortableColumn="ContractName">{{'CONTRACT_NAME'| translate}} <p-sortIcon field="ContractName"></p-sortIcon></th>
                    <th pSortableColumn="ClientName">{{'CLIENT_KUNDE' | translate }} <p-sortIcon field="ClientName"></p-sortIcon></th>
                    <th>{{'LOGO' | translate}}</th>
                    <th pSortableColumn="ResponsibleUserName">{{'RESPONSIBLE_USER'| translate}} <p-sortIcon field="ResponsibleUserName"></p-sortIcon></th>
                    <th pSortableColumn="StatusText">{{'STATUS'| translate}} <p-sortIcon field="StatusText"></p-sortIcon></th>
                    <th pSortableColumn="Visibilty">{{'VISIBILITY'| translate}} <p-sortIcon field="Visibilty"></p-sortIcon></th>
                    <th *ngIf="isGuestHideButton">{{'OPTIONS'| translate}}</th>
                </tr>
                <tr>
                    <th>
                        <input pInputText type="text"
                            (input)="electroTable.filter($event.target.value, 'ContractNumber', 'contains')"
                            placeholder="" class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="electroTable.filter($event.target.value, 'ContractName', 'contains')"
                            placeholder="" class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="electroTable.filter($event.target.value, 'ClientName', 'contains')" placeholder=""
                            class="p-column-filter" >
                    </th>
                    <th></th>
                    <th>
                        <p-dropdown width='118px' [options]="responsible" #res
                            (onChange)="onResponsibleChange($event, res)" styleClass="p-column-filter" placeholder=""
                            [filterBy]="null" [showClear]="true">
                            <ng-template let-res pTemplate="item"> {{ res.label | translate }}
                            </ng-template>
                        </p-dropdown>
                    </th>
                    <th>
                        <p-dropdown [options]="statuses" (onChange)="onStatusChange($event, st)" #st
                            styleClass="p-column-filter" placeholder="Select" [filterBy]="null" [showClear]="true">
                            <ng-template let-status pTemplate="item"> {{ status.label | translate }}
                            </ng-template>
                        </p-dropdown>
                    </th>
                    <th>
                        <p-dropdown [options]="visibility" (onChange)="onVisibilityChange($event, vis)" #vis
                            styleClass="p-column-filter" placeholder="Select" [filterBy]="null" [showClear]="true">
                            <ng-template let-status pTemplate="item"> {{ status.label | translate }}
                            </ng-template>
                        </p-dropdown>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-el>
                <tr>
                    <td>
                        <span>{{el.ContractNumber}}</span>
                    </td>
                    <td>
                        <a [routerLink]="['../details',el.Id]" (click)="saveContractNumber(el)">{{el.ContractName}}</a>
                    </td>
                    <td>
                        <span>{{el.ClientName}}</span>
                    </td>
                    <td>
                        <img src="{{el.ClientLogo}}" alt="" width="100" height="100" class="thumbnail">
                    </td>
                    <td>
                        <span>{{el.ResponsibleUserName}}</span>
                    </td>
                    <td>
                        <span [style.color]="el.StatusText === 'ACTIVE' ? 'green' : 'red'">{{el.StatusText
                            | translate}}</span>
                    </td>
                    <td>
                        <span>{{(el.Visibilty === 1 ? 'VISIBLE' : 'HIDDEN')| translate}}</span>
                    </td>
                    <td class="col-button" *ngIf="isGuestHideButton">
                        <button type="button" class="btn btn-icon" (click)="saveContractNumber(el)"
                            [routerLink]="['./../edit',el.Id]" title="{{'EDIT' | translate}}">
                            <i class="icon ic-sm icon-pencil-tip"></i>
                        </button>
                        <button type="button" class="btn btn-icon" (click)="deleteElectro(el['Id'])"
                            title="{{'DELETE' | translate}}">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="7">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" *ngIf="isGuestHideButton" let-columns>
                <tr>
                    <td colspan="8">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table Ends -->
    </div>
</div>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
import { FeatureKey } from './../../../_models/feature';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Observable } from 'rxjs';


import { Department } from './../models/deviationDepartment';
import { DeviationList } from './../models/deviationList';
import { DeviationServices } from './deviation.service';
import { BusinessDepartmentService } from '../../business-department.services';

@Injectable()
export class DeviationEditResolver implements Resolve<DeviationList[]> {
    constructor(private backend: DeviationServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<DeviationList[]> {
        return this.backend.getById(+route.params['deviationId']);
    }
}

@Injectable()
export class DeviationListResolver implements Resolve<DeviationList[]> {
    constructor(private backend: DeviationServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<DeviationList[]> {
        return this.backend.getAllByEach(
            +route.params['Id'],
            route.parent.parent.data['parent']
        );
    }
}
@Injectable()
export class RespForFollowupResolver implements Resolve<Department[]> {
    constructor(private backend: DeviationServices) { }
    resolve(): Observable<Department[]> {
        return this.backend.getUser(BaseServices.BusinessId);
    }
}
@Injectable()
export class DepartmentResolver implements Resolve<Department[]> {
    constructor(private backend: BusinessDepartmentService) { }
    resolve(): Observable<Department[]> {
        return this.backend.getCachedBusinessDepartment();
    }
}
@Injectable()
export class CreatedByResolver implements Resolve<any[]> {
    constructor(private backend: DeviationServices) { }
    resolve(): Observable<any[]> {
        return this.backend.getAllUsers();
    }
}
@Injectable()
export class TypeCaseResolver implements Resolve<Department[]> {
    constructor(private backend: DeviationServices) { }
    resolve(): Observable<Department[]> {
        return this.backend.getAllCaseType();
    }
}
@Injectable()
export class DepartmentForAdminResolver implements Resolve<Department[]> {
    constructor(private backend: DeviationServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<Department[]> {
        return this.backend.getDepartmentForAdmin(+route.params['deviationId']);
    }
}
@Injectable()
export class DeviationManualResolver implements Resolve<any[]>{
    constructor(private backend: DeviationServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getAssignedBusinessManual(+BaseServices.BusinessId);
    }
}
@Injectable()
export class DeviationResponsibleAssignedUserResolver implements Resolve<any[]>{
    constructor(
        private backend: DeviationServices,
    ) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        let featureId: any;
        let parent = route.parent.parent.data['parent']
        switch (parent) {
            case 'PROJECT':
                featureId = FeatureKey.PROJECT;
                break;
            case 'KUNDE_EL':
                featureId = FeatureKey.KUNDE_EL;
                break;
            case 'FS':
                featureId = FeatureKey.FOOD_SAFETY;
                break;
            case 'FDV':
                featureId = FeatureKey.FDV;
                break;
            case 'IC':
                featureId = FeatureKey.INTERNAL_CONTROL;
                break;            
            case 'OS':
                featureId = FeatureKey.OTHER_SYSTEMS;
                break;          
            case 'QS':
                featureId = FeatureKey.QUALITY_SYSTEMS;
                break;
            case 'VEHICLE':
                featureId = FeatureKey.VEHICLES;
                break;
            case 'SA':
                featureId = FeatureKey.SERVICE_AGREEMENT;
                break;
            case 'FA':
                featureId = FeatureKey.FRAMEWORK_AGREEMENT;
                break;
            case 'FRAMEWORK':
                featureId = FeatureKey.FRAMEWORK_AGREEMENT;
                break;
            case 'KUBA_CONTROL':
                featureId = FeatureKey.KUBA_CONTROL;
                break;
        }
        return this.backend.getAssignedUsers(+BaseServices.BusinessId, featureId,
            route.parent.parent.params['id'], BaseServices.ApplicationId);
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { HelperService } from './../../../_services/helper.service';
import { SelectItem } from 'primeng/api';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { ConfirmationService } from 'primeng/api';
import { ProjectServices } from '../services/project.service';
import { Rights } from 'src/app/_models';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { ProjectList } from '../models/projectlist';
import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'project-list-own',
  templateUrl: 'project-list-own.component.html',
})
export class ProjectListOwnComponent implements OnInit {
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  myProject: any[];
  @ViewChild('MyprojectsTable', { static: false }) MyprojectsTable: Table;
  pdf: any;
  ProjectResponsibility: SelectItem[];
  isUser: boolean;
  isDisableDelete: boolean;
  datasource: ProjectList[];
  loading: boolean;
  additionalData: any;
  statuses: SelectItem[];
  filterdata: any[];
  private subscriptions: Subscription[] = [];
  /**
   * constructor
   * @param projectServices {{ProjectServices}}
   * @param router {{Router}}
   * @param route {{ActivatedRoute}}
   * @param confirmationService {{ConfirmationService}}
   */
  constructor(
    private projectServices: ProjectServices,
    public router: Router,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private translate: TranslateService
  ) {
    this.additionalData = {
      fileName: 'Project List',
      header: 'Project List',
      businessId: BaseServices.BusinessId,
      cultureInfo: BaseServices.userCultureInfo(),
      columnNames: [
        { title: 'Project Number', datakey: 'Number' },
        { title: 'Project Name', dataKey: 'Title' },
        { title: 'Start Date', datakey: 'StartDate' },
        { title: 'End Date', datakey: 'ExpectedEndDate' },
        { title: 'Project Manager', datakey: 'ProjectManagers' },
        { title: 'Status', datakey: 'Status' },
        { title: 'Created By', datakey: 'CreatorName' },
        { title: 'Edited By', datakey: 'ModifierName' },
      ],
    };

    this.subscriptions.push(
      this.translate.stream('SELECT_STATUSES').subscribe((val) => {
        this.statuses = [];
        this.statuses.push(
          { label: val.OPEN, value: 'OPEN' },
          { label: val.DONE, value: 'DONE' },
          { label: val.REJECTED, value: 'REJECTED' }
        );
      })
    );
  }
  /**
   * get own project list
   */
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    if (BaseServices.roleId === '5') {
      this.isUser = false;
    }
    this.myProject = [];
    this.filterdata = [];
    let list = <ProjectList[]>this.route.snapshot.data['myList'];
    if (list) {
      list.forEach((element) => {
        let startDate = HelperService.formatDate(element.StartDate);
        let endDate = HelperService.formatDate(element.ExpectedEndDate);
        let status = element.Status == 'NEW' ? 'OPEN' : element.Status;
        return (
          (element.StartDate = startDate),
          (element.ExpectedEndDate = endDate),
          (element.Status = status)
        );
      });
      this.myProject.push(...list);
      this.filterdata.push(...list);
    }

    this.ProjectResponsibility = [];
    let responsible = this.route.snapshot.data['reponsibilities'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.ProjectResponsibility = [];
        this.ProjectResponsibility.push({ label: val.SELECT, value: null });
        if (responsible) {
          responsible.forEach((element: any) => {
            let label = this.translate.instant(element.Name);
            this.ProjectResponsibility.push({
              label: label,
              value: element.Id,
            });
          });
        }
      })
    );
    this.myProject = this.filterdata.filter(
      (x) => x.Status == 'OPEN' || x.Status == 'NEW'
    );
  }

  getProjectList() {
    this.subscriptions.push(
      this.projectServices
        .getProjectByUser(BaseServices.UserId, BaseServices.BusinessId)
        .subscribe((result) => {
          let list = result || [];
          list.forEach((element: any) => {
            let startDate = HelperService.formatDate(element.StartDate);
            let endDate = HelperService.formatDate(element.ExpectedEndDate);
            let status = element.Status == 'NEW' ? 'OPEN' : element.Status;
            return (
              (element.StartDate = startDate),
              (element.ExpectedEndDate = endDate),
              (element.Status = status)
            );
          });
          this.myProject = [];
          this.filterdata = [];
          this.myProject.push(...list);
          this.filterdata.push(...list);
        })
    );
  }
  /**
   * delete own project by id
   * @param myProject {{any}}
   */
  deleteProject(myProject: any) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.projectServices.deleteproject(myProject).subscribe(() => {
            this.toasterComponent.callToastDlt();
            this.getProjectList();
          })
        );
      },
    });
  }
  /**
   * client dropdown change event
   * @param e
   * @param responsibleDropdown
   */
  onResponsibleChanged(e: any, responsibleDropdown: any) {
    if (e.value) {
      // custom filter datatable
      let ResponsibilityId: string = responsibleDropdown.selectedOption.value;
      this.MyprojectsTable.filter(
        ResponsibilityId.toString().toLowerCase(),
        'ProjectResponsibilityIds',
        'contains'
      );
    } else {
      this.MyprojectsTable.reset();
    }
  }

  // TODO: to be implemented
  exportCSV() {}

  /**
   * Hide the AllProjectList based on administrate project rights
   */
  onAdministrateProject() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId;
    let userRights;
    let data;
    if (currentUserRole === 'User') {
      userRightsId = Rights.ADMINISTRATE_PROJECTS;
      userRights = BaseServices.getUserRights();
      data = JSON.parse(userRights);
      this.isDisableDelete = HelperService.checkRightsData(data, userRightsId);
    } else {
      this.isDisableDelete = false;
    }
  }

  onStatusChanged(e: any, statusDropdown: any) {
    if (e.value) {
      // custom filter datatable
      if (e.value == 'OPEN') {
        this.myProject = this.filterdata.filter(
          (x) => x.Status == 'OPEN' || x.Status == 'NEW'
        );
      } else {
        this.myProject = this.filterdata.filter((x) => x.Status == e.value);
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}

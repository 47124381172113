import { Injectable } from '@angular/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { OtherSystem } from '../models/other-system';
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class OtherSystemService {

  constructor(private http: HttpClient) {}

  // List all InternalControl
  getAll(Id: number) {
      return this.http
          .get(
              environment.BASE_URL + '/othersystems/getall/' + Id,

          )
          .map((result: any) => result);
  }

  list(Id: number) {
      return this.http
          .get(
              environment.BASE_URL + '/othersystems/getall/' + Id,

          )
          .map(result => result);
  }



  getInternalControlById(Id: number) {
      return this.http
          .get(
              environment.BASE_URL + '/othersystems/' + Id,

          )
          .map((result: any) => result);
  }

  add(data) {
      return this.http
          .post(
              environment.BASE_URL + '/othersystems/save',
              data,

          )
          .map(result => result);
  }

  update(data: OtherSystem) {
      return this.http
          .put(
              environment.BASE_URL + '/othersystems/update',
              data,

          )
          .map(result => result);
  }

  deleteControl(Id) {
      return this.http
          .delete(
              environment.BASE_URL + '/othersystems/delete/' + Id,

          )
          .toPromise()
          .then((response: Response) => {
              return response;
          });
  }

  getInternalControlFeatures(businessId: number) {
      let featureId: number;
      let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
      featureId = workingModule.id;
      return this.http
          .get(
              environment.BASE_URL + '/business/' + businessId,

          )
          .map((result: any) => {
              let feature = result.Features;
              let internalcontrolAdditionalFeature = _.find(
                  JSON.parse(feature),
                  { id: featureId, access: true }
              );
              return internalcontrolAdditionalFeature;
          });
  }

  getInternalByUser(Id: number) {
      return this.http.get(environment.BASE_URL + '/othersystems/getuserOS/' + Id, )
          .map((result: any) => result);
  }

  getOtherSystemDocumentTraining(id: number) {
    return this.http
        .get(
            `${environment.BASE_URL}/othersystems/getOtherSystemDocumentTraining/${id}`,

        )
        .map(result => result);
}

}

import { EmployeeSicknessAbscence, SicknessAbsenceFollowUp, Sickness } from './../models/employeeSicknessAbscence';
import { BaseServices } from './../../kuba.services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from "src/environments/environment";
import 'rxjs/add/operator/map'
import {
    Employee,
    EmployeeLeave,
    EmployeeFirstOfKin,
    EmployeeCourse,
    EmployeeType,
    UserView,
    EmployeePosition
} from '../models/index'
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EmployeeSicknessServices {
    item: Object;
    constructor(private http: HttpClient) {
    }
    // listing employee sickness absence
    list(businessId:number) {
        return this.http.get(environment.BASE_URL + '/employeesicknessabsences/' + businessId,)
            .map((result: any) => result);
    }
    // listing employee sickness absence
    listcontract(businessId:number) {
        return this.http.get(environment.BASE_URL + '/employeesicknessabsences/' + businessId,)
            .map(result => result);
    }
    // listing employee sickness absence
    portalList(userId: number) {
        return this.http.get(environment.BASE_URL + '/portalemployeesicknessabsences/' + userId, )
            .map((result: any) => result);
    }
    // listing employee sickness absence
    portalListContract(userId: number) {
        return this.http.get(environment.BASE_URL + '/portalemployeesicknessabsences/' + userId, )
            .map(result => result);
    }
    // retrive an employee sickness for editing and View
    getById(id: number) {
        return this.http.get(environment.BASE_URL + '/employeesicknessabsence/' + id, )
        .map(result => result);
    }

    // add employee sickness to list
    add(data:any) {
        return this.http.post(environment.BASE_URL + '/employeesicknessabsence', data, )
            .map(result => result);
    }
    // updating employee sickness list
    update(id: number, ExistingEmployee: EmployeeSicknessAbscence,UserId : number) {
        return this.http.put(environment.BASE_URL + '/updateemployeesicknessabsence/' + id +'/'+ UserId, ExistingEmployee , );
    }
    DeleteAbsence(id: number) {
        return this.http.delete(environment.BASE_URL + '/employeesicknessabsence/' + id,)
            .map(result => result);
    }

    // get approval person list
    getAllApprovedPerson() {
        return this.http.get(environment.BASE_URL + '/users',)
            .map(result => result);
    }

    getSicknessAbsenceCode(businessId: number) {
        return this.http.get(environment.BASE_URL + '/sicknessabsencecode/' + businessId, )
            .map(result => result);
    }
    getEmployeeSicknessByEmployeeId(empId: number) {
        return this.http.get(environment.BASE_URL + '/employeesicknessabsencebyemployee/' + empId, )
            .map(result => result);
    }

    deleteEmployeeSickness(id:number) {
        let result: boolean;
        return this.http.delete(environment.BASE_URL + '/employeesicknessabsence/' + id,)
            .map(result => result);
    }
    getallDocuments(eid: number) {
        return this.http.get(environment.BASE_URL + '/employeedocuments/' + eid, )
            .map(result => result);
    }
    addEmployeeSicknessFollowUp(sicknessAbsenceFollowUp: Sickness) {
        return this.http.post(environment.BASE_URL + '/employeesicknessabsencefollowup', sicknessAbsenceFollowUp, )
            .map(result => result);
    }
    private extractData(res: any) {
        let body = res;       // return body || {};
        return <Employee[]>res;

    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}

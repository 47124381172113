import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { EmployeeDocumentUploadable } from 'src/app/kuba/employees/models/employeeDocument';
import { EmployeeServices } from 'src/app/kuba/employees/services/employee.services';
import { EmployeePrivateDocumentServices } from 'src/app/kuba/employees/services/employeeprivatedocument.service';
import { FeatureManualService } from 'src/app/kuba/feature-documents/feature-document.services';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ManualService } from 'src/app/kuba/manuals/services/manual.services';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { HelperService } from 'src/app/_services/helper.service';
import { environment } from 'src/environments/environment';
import { KubaControlService } from '../../services/kuba-control.service';
import { FeatureKey } from 'src/app/_models';

@Component({
  selector: 'app-to-do-list',
  templateUrl: './to-do-list.component.html',
  styleUrls: ['./to-do-list.component.css']
})
export class ToDoListComponent implements OnInit {
  @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
  apiResponse = 'none';
  selectedAll: any;
  approvalNotices: any = [];
  additionalData: any;
  loading = false;
  upConfig: UploaderConfig;
  upConfigApp: UploaderConfig;
  UploadableFile: EmployeeDocumentUploadable;
  newDocumentAdded: any = [];
  uploadedFiles: any = [];
  uploadedFilesGrid: any = [];
  docId: number;
  controlZoneId: number;
  employeeId: number;
  isShowDelete: boolean;
  editMode = false;
  addManualsEdit = true;
  showSavingLoader = false;
  isEditor = false;
  isUser = false;
  featureId: any;
  selectedFiles: any;
  isMultiDocument = false;
  employees: any = [];
  emp: any = [];
  approvalData: any = [];
  FilterEmp: any = [];
  isallow = false;
  rowID: number = null;
  isCollapsed = false;
  employeeForm: FormGroup;
  selectedEmployees: SelectItem[];
  selectedEmployeesForApproval: any = [];
  files: any = [];
  selectedDocumentsForApprovalNotice: any = [];
  type: any;
  all = false;
  title: any = 'TO_DO_LIST';
  typeList: any = [];
  originalEmp: any = [];
  showArchiveDocumentDailog: boolean = false;
  ShowPopUp: boolean = false;
  ShowDocumentPopUp: boolean = false;
  treeNode: any;
  selectFolderId?: number;
  selectFolderName?: string;
  treeActiveNode: any = null;
  appManualTree: any;
  usersId: any;
  appManualTreeClone: any;
  flattenedClone: any;
  selectedArticles: any = [];
  ecplIsPrivateDocument = true;
  changeCheckBox = false;
  isDocTrainButton = true;
  hasRights = false;
  dropDownAlinement = false;
  manualList: SelectItem[];
  manualSelected = false;
  selectedManual: any = 543;
  featureKey: any;
  approvalDatas: any = [];
  filterApprovalDatas: any = [];
  arroveOption = true;
  private subscriptions: Subscription[] = [];
  constructor(
    public kubaControlService: KubaControlService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private manualservices: ManualService,
    private employeePrivateDocumentServices: EmployeePrivateDocumentServices,
    private featureManualService: FeatureManualService,
  ) {
    //#endregion
    this.employeeId = route.snapshot.params['eid'];
    this.controlZoneId = route.snapshot.params['Id'];
    this.featureKey = this.route.snapshot.parent.params['id'];

    this.title = 'TO_DO_LIST';
    this.isMultiDocument = true
    this.usersId = BaseServices.UserId;

    this.subscriptions.push(this.kubaControlService.toDoList(this.featureKey)
      .subscribe(result => {
        if (result) {
          result.forEach((r: any) => {
            this.employees.push({
              label: r.Name,
              value: r.Id
            });
          });
        }

      }));

    this.emp = this.route.snapshot.data['toDoApplist'];
    this.emp.filter(x => x.EmployeeId == this.usersId)
    this.originalEmp = this.route.snapshot.data['ToDoDocument'];
    this.FilterEmp = this.route.snapshot.data['toDoApplist'];



    this.featureId = route.snapshot.params['fId'];
    if (BaseServices.roleId == '3') {
      if (BaseServices.userBase.UserFeatureKey == this.controlZoneId)
        this.isUser = true;
      else
        this.isEditor = true;
    }
    if ((BaseServices.roleId == '4' || BaseServices.roleId == '5')) {
      this.isUser = true;
    }

    if ((BaseServices.roleId == '5')) {
      var userRights = JSON.parse(BaseServices.getUserRights());
      var checkId = userRights.filter(x => x.id == 51);
      this.hasRights = false;
    }
    else if ((BaseServices.roleId == '3')) {
      this.hasRights = false;
    } else if (BaseServices.roleId == '4') {
      this.hasRights = true;
    }


    // TODO: need to send dynamic values
    this.additionalData = {
      'ApplicationId': BaseServices.ApplicationId,
      'BusinessId': BaseServices.BusinessId,
      'ControlZoneId': this.controlZoneId,
      'IsPrivateDocument': true,
      'Status': '1',
      'CreatedBy': BaseServices.UserId,
      'CreatedOn': '',
      'ModifiedBy': BaseServices.UserId,
      'ModifiedOn': '',
      'IsDocumentedTraining': true
    };

    this.upConfig = {
      title: 'TO_DO_LIST',
      viewSwitch: true,
      IsDocumentedUploadButton: true,
      showDeleteButton: false,
      editMode: this.editMode,
      windowedHeight: false,
      showUserMeta: false,
      showSearchFilter: false,
      showDocumentedTrainingButton: !this.isDocTrainButton && this.hasRights,
      uploaderUri: environment.BASE_URL + '/file/upload/business/controlzone/private-docs',
      addtionalData: this.additionalData,
      isEditablePrivateDocument: true,
      zoneId: this.controlZoneId
    };

    this.upConfigApp = {
      title: '',
      titleAsLabel: true,
      editMode: true,
      windowedHeight: false,
      viewSwitch: false,
      showUserMeta: false,
      showSearchFilter: false,
      IsDocumentedUploadButton: true,
      showDeleteButton: true,
      uploaderUri:
        environment.BASE_URL +
        '/file/upload/business/controlzone/private-docs',
      addtionalData: this.additionalData,
      zoneId: this.controlZoneId
    };

  }

  /**
  * file upload events
  * @param event {any}
  */
  isFileSeclected() {
    var selected = this.uploadedFiles.filter(x => x.selected == true);
    if (selected.length > 0)
      return false;
    else
      return true;
  }

  isApprovalSelected() {
    var selected = this.approvalData.filter(x => x.selected == true);
    if (selected.length > 0)
      this.arroveOption = true;
    else
      this.arroveOption = false;
  }

  setCollapse(i: number): boolean {
    let isCollapsed = (i === this.rowID);
    return !isCollapsed;
  }

  toggleClick(i: number): void {
    this.rowID = i;
    this.isCollapsed = !this.isCollapsed;
    if (this.rowID !== null) {
      this.rowID = i;
      this.isCollapsed = !this.isCollapsed;
    } else {
      this.rowID = null;
    }
  }

  saveUploaded(event: any) {
    if (event == "showDocumentDialog") {
      this.showDocumentDailog();
    }
    else {
      if (event) {
        let eventType = event.eventName;
        switch (eventType) {
          case 'DeleteArticle':
            {
              this.uploadedFilesGrid = this.uploadedFilesGrid.filter(
                (x: any) => x.id !== +event.item.data.id || (x.id == +event.item.data.id && x.documentType != 'article')
              );
              this.selectedArticles = this.selectedArticles.filter(
                (x: any) => x.id !== +event.item.data.id
              );
              if (event.item.data.id != 0) {
                this.subscriptions.push(this.kubaControlService
                  .deleteEmployeeDocument(event.item.data.id)
                  .subscribe((result: any) => {
                    if (result) {
                      this.newDocumentAdded = this.newDocumentAdded.filter((x: any) => x.Path !== event.item.data.path);
                      // remove from display list
                      this.getDoc();
                      this.toasterComponent.callToastDlt();
                    }
                  }));
              }
            }
            break;
          case 'DeleteFile':
            {
              this.uploadedFilesGrid = this.uploadedFilesGrid.filter(
                (x: any) => x.id !== +event.item.data.id || (x.id == +event.item.data.id && x.documentType != 'document')
              );
              this.selectedArticles = this.selectedArticles.filter(
                (x: any) => x.id !== +event.item.data.id
              );
            }
            break;
          case 'DeleteChecklist':
            {
              this.uploadedFilesGrid = this.uploadedFilesGrid.filter(
                (x: any) => x.id !== +event.item.data.id || (x.id == +event.item.data.id && x.documentType != 'checklist')
              );
              this.selectedArticles = this.selectedArticles.filter(
                (x: any) => x.id !== +event.item.data.id
              );
              if (event.item.data.id != 0) {
                this.subscriptions.push(this.kubaControlService
                  .deleteEmployeeDocument(event.item.data.id)
                  .subscribe((result: any) => {
                    if (result) {
                      this.newDocumentAdded = this.newDocumentAdded.filter((x: any) => x.Path !== event.item.data.path);
                      // remove from display list
                      this.getDoc();
                      this.toasterComponent.callToastDlt();
                    }
                  }));
              }
            }
            break;
          case 'DeleteDefaultFile':
            if (event.item.data.id != 0) {
              // remove unsaved attachment
              this.newDocumentAdded = this.newDocumentAdded.filter((x: any) => x.Path !== event.item.data.path);
              this.uploadedFilesGrid = this.uploadedFilesGrid.filter((x: any) => x.path !== event.item.data.path);
              this.toasterComponent.callToastDlt();
            } else {
              // remove existing attachement
              this.subscriptions.push(this.kubaControlService
                .deleteEmployeeDocument(event.item.data.id)
                .subscribe((result: any) => {
                  if (result) {
                    this.newDocumentAdded = this.newDocumentAdded.filter((x: any) => x.Path !== event.item.data.path);
                    // remove from display list
                    this.getDoc();
                    this.toasterComponent.callToastDlt();
                  }
                }));
            }
            break;
          case 'remove':
            this.uploadedFilesGrid = this.uploadedFilesGrid.filter(
              (x: any) => x.id !== +event.id
            );
            break;
          case 'ShowOfficeFileSaveloader':
            this.subscriptions.push(this.employeePrivateDocumentServices.updateDoc(event.item.id, this.controlZoneId, false).subscribe((res) => {
              this.docId = event.item.id;
              if (res) {
                this.subscriptions.push(this.employeePrivateDocumentServices.list(this.controlZoneId, 2).subscribe((result) => {
                  if (result.length > 0) {
                    this.uploadedFilesGrid = result;
                    this.Approve();
                  }
                }))
              }
            }))
            if (
              parseInt(sessionStorage.getItem('isDocumentChanged'), null)
            ) {
              this.showSavingLoader = true;
              setTimeout(() => {
                this.showSavingLoader = false;
                this.newDocumentAdded = [];
              }, 15000);
              sessionStorage.setItem('isDocumentChanged', '0');
            }
            break;
          default:
            let keyBindings = {
              Originalname: 'OriginalFileName',
              FileTypeId: 'FileType'
            };
            let privateDoc = Object.assign(
              {},
              event,
              this.additionalData
            );
            privateDoc.id = 0;
            privateDoc.status = '1';
            privateDoc.createdBy = BaseServices.UserId;
            this.newDocumentAdded.push(
              HelperService.switchKeysCase(privateDoc, keyBindings)
            );
            break;
        }
      }
    }
  }

  saveDoc() {
    if (this.isMultiDocument == true) {
      if ((this.newDocumentAdded.length > 0 || this.selectedArticles.length > 0) && this.selectedEmployeesForApproval.length > 0) {
        this.loading = true;
        this.newDocumentAdded.forEach(element => {
          this.selectedEmployeesForApproval.forEach(x => {
            var tempDoc: any = {};
            tempDoc.ControlId = this.controlZoneId;
            tempDoc.BusinessId = element.BusinessId;
            tempDoc.CreatedBy = element.CreatedBy;
            tempDoc.CreatedOn = element.CreatedOn;
            tempDoc.userId = x.EmployeeId;
            tempDoc.Extension = element.Extension;
            tempDoc.FileType = element.FileType;
            tempDoc.Filename = element.Filename;
            tempDoc.Id = element.Id;
            tempDoc.IsPrivateDocument = element.IsPrivateDocument;
            tempDoc.Mimetype = element.Mimetype;
            tempDoc.ModifiedBy = element.ModifiedBy;
            tempDoc.ModifiedOn = element.ModifiedOn;
            tempDoc.OriginalFileName = element.OriginalFileName;
            tempDoc.Path = element.documentType == "videos" ? element.url : element.Path;
            tempDoc.Size = element.Size;
            tempDoc.Status = element.Status;
            tempDoc.IsDocumentedTraining = true;
            this.files.push(tempDoc);

          });
        });

        this.selectedArticles.forEach(element => {
          this.selectedEmployeesForApproval.forEach(x => {
            var tempDoc: any = {};
            tempDoc.ControlId = this.controlZoneId;
            tempDoc.BusinessId = BaseServices.BusinessId;
            tempDoc.CreatedBy = BaseServices.UserId;
            tempDoc.CreatedOn = element.createdOn;
            tempDoc.userId = x.EmployeeId;
            tempDoc.Extension = null;
            tempDoc.FileType = null;
            tempDoc.Filename = element.filename;
            tempDoc.Id = element.Id;
            tempDoc.IsPrivateDocument = true;
            tempDoc.Mimetype = null;
            tempDoc.ModifiedBy = null;
            tempDoc.ModifiedOn = null;
            tempDoc.OriginalFileName = element.originalFileName;
            tempDoc.Path = null;
            tempDoc.Size = null;
            tempDoc.Status = 1;
            tempDoc.DocumentTypeId = element.documentTypeId;
            tempDoc.DocumentType = element.documentType;
            tempDoc.Path = element.documentType == "videos" ? element.url : element.path;
            tempDoc.DocumentId = element.nodeId;
            tempDoc.documentType = element.documentType;
            tempDoc.IsDocumentedTraining = true;
            this.files.push(tempDoc);

          });
        });
        this.subscriptions.push(this.kubaControlService.saveDocs(this.files, this.controlZoneId).subscribe((result) => {
          if (result) {
            this.toasterComponent.callToast();
            this.loading = false;
            this.newDocumentAdded = [];
            this.selectedArticles = [];
            this.getApprovalDoc();
            this.UpdatedApproval();
            this.selectedEmployeesForApproval = [];
            this.uploadedFilesGrid = [];
            this.files = [];
          }
        }));
      }
    } else {
      if (this.selectedArticles.length > 0) {
        var temp: any = [];
        this.selectedArticles.forEach(element => {
          this.loading = true;
          var tempDoc: any = {};
          tempDoc.ControlId = this.controlZoneId;
          tempDoc.BusinessId = BaseServices.BusinessId;
          tempDoc.CreatedBy = BaseServices.UserId;
          tempDoc.CreatedOn = element.createdOn;
          tempDoc.userId = Number(this.controlZoneId);
          tempDoc.Extension = null;
          tempDoc.FileType = null;
          tempDoc.Filename = element.filename;
          tempDoc.Id = element.Id;
          tempDoc.IsPrivateDocument = true;
          tempDoc.Mimetype = null;
          tempDoc.ModifiedBy = null;
          tempDoc.ModifiedOn = null;
          tempDoc.OriginalFileName = element.originalFileName;
          tempDoc.Path = null;
          tempDoc.Size = null;
          tempDoc.Status = 1;
          tempDoc.DocumentTypeId = element.documentTypeId;
          tempDoc.Path = element.documentType == "videos" ? element.url : element.path;
          tempDoc.DocumentId = element.nodeId;
          tempDoc.DocumentType = element.documentType;
          tempDoc.CreatedBy = Number(BaseServices.UserId);
          tempDoc.IsDocumentedTraining = true;
          temp.push(tempDoc);
        });
        this.subscriptions.push(this.employeePrivateDocumentServices.save(temp, this.controlZoneId).subscribe((result) => {
          if (result) {
            this.toasterComponent.callToast();
            this.loading = false;
            this.selectedArticles = [];
            this.getDoc();
          }
        }))
      }

      if (this.newDocumentAdded.length > 0) {
        this.loading = true;
        this.newDocumentAdded.forEach(element => {
          element.IsDocumentedTraining = true;
        });
        this.subscriptions.push(this.employeePrivateDocumentServices.save(this.newDocumentAdded, this.controlZoneId).subscribe((result) => {
          if (result) {
            this.toasterComponent.callToast();
            this.loading = false;
            this.newDocumentAdded = [];
            this.getDoc();
          }
        }))
      }
    }

  }

  getApprovalDoc() {
    this.subscriptions.push(this.kubaControlService.getAllApprovedForKubaControlToDoList(this.controlZoneId).subscribe((result) => {
      this.emp = result;
      this.originalEmp = result;
      this.subscriptions.push(this.translate.stream('SELECT').subscribe(val => {
        this.typeList = [];
        this.typeList.push(
          { label: val.SELECT, value: null },
        );
      }));
    }))
  }
  //#endregion

  //#region page-events
  /**
  * page initialization
  */
  ngOnInit() {
    let featureBase = BaseServices.getUserFeatures();
    var temp = <any>JSON.parse(featureBase.Features);
    temp = temp.filter(element => element.id == 13)
    if (temp[0].checked == true) {
      this.manualSelected = true
    }
    BaseServices.setActiveTreeNode(null);
    this.treeActiveNode = null;
    this.privateDocuments();

  }

  privateDocuments() {
    let uploadfiles = this.route.snapshot.data['ToDoDocument'];
    if (uploadfiles && uploadfiles.length > 0) {
      this.uploadedFiles = uploadfiles;
    }
  }

  ngOnChanges() {
    this.selectedFiles = this.uploadedFiles.filter(x => x.selected == true);
  }

  getDoc() {
    this.subscriptions.push(this.kubaControlService.toDoList(this.featureKey).subscribe((result) => {
      this.uploadedFiles = result;
    }));
  }
  getApprovalDocuments() {
    this.loading = true;
    this.subscriptions.push(this.kubaControlService.getAllApprovedForKubaControlToDoList(this.controlZoneId).subscribe((result) => {
      this.emp = result;
      this.originalEmp = result;
      this.subscriptions.push(this.translate.stream('SELECT').subscribe(val => {
        this.typeList = [];
        this.typeList.push(
          { label: val.SELECT, value: null },
        );
      }));
      this.loading = false;
    }));
  }

  toggleEditMode(mode: any) {
    this.editMode = mode;
    this.upConfig.editMode = mode;
    this.upConfig.showDeleteButton = mode;
  }

  onTableHeaderCheckboxToggle() {
    this.selectedEmployeesForApproval = this.emp;
  }

  Approve() {
    this.saveDoc();
    if (this.type == 1) {
      this.loading = true;
      this.selectedDocumentsForApprovalNotice = this.selectedEmployeesForApproval.filter(x => x.TotalDocumentsForApproval > 0).map(ele => ele.EmployeeDocuments);
      var merged = [].concat.apply([], this.selectedDocumentsForApprovalNotice);
      if (this.selectedDocumentsForApprovalNotice.length > 0) {
        this.subscriptions.push(this.employeePrivateDocumentServices.update(merged, 3, BaseServices.UserFeatureKey, FeatureKey.KUBA_CONTROL,2).subscribe((result) => {
          if (result) {
            this.toasterComponent.callToastApprovedNotice();
            this.getApprovalDoc();
            this.loading = false;
            this.selectedEmployeesForApproval = [];
          }
        }));
      } else {
        this.loading = false;
        this.getApprovalDoc();
        this.selectedEmployeesForApproval = [];
      }
    }
    else {
      this.loading = true;
      if (this.docId != null) {
        this.selectedFiles = this.uploadedFiles.filter(x => x.id == this.docId);
      }
      else {
        this.selectedFiles = this.uploadedFiles.filter(x => x.selected == true);

      }
      if (this.selectedFiles.length > 0) {
        this.subscriptions.push(this.kubaControlService.updateApprovel(this.selectedFiles, this.controlZoneId).subscribe((result) => {
          if (result) {
            this.toasterComponent.callToastApprovedNotice();
            this.loading = false;
            this.UpdatedApproval();
            this.subscriptions.push(this.kubaControlService.getAllApprovedForKubaControlToDoList(this.controlZoneId).subscribe((results) => {
              if (results) {

                this.emp = results;
              }
            }))

          }
        }));

      } else {
        this.loading = false;
      }
    }
  }

  UpdatedApproval() {
    var userId = BaseServices.UserId
    this.subscriptions.push(this.kubaControlService.getTodoDocumnet(this.featureKey, userId).subscribe((result) => {
      if (result) {
        if (result && result.length > 0) {
          this.uploadedFiles = result;
        }
      }
    }))
  }
  choose(event: any) {
  }

  getFileExtension(filename: any) {
    let fileExtension = null;
    if (filename != null) {
      fileExtension = filename.substr(filename.lastIndexOf('.') + 1);
    }
    return fileExtension.toLowerCase();
  }

  humanizeType(mimetype) {
    let type = '';


    switch (mimetype) {
      case 'xlsx':
      case 'xls':
      case 'csv':
      case 'docx':
      case 'doc':
      case 'rtf':
      case 'ppt':
      case 'pptx':
      case 'csv':
        type = 'Document';
        break;
      case 'png':
      case 'jpg':
      case 'JPG':
      case 'jpeg':
      case 'bmp':
      case 'gif':
      case 'tiff':
        type = 'Image';
        break;
      case 'mp3':
      case 'wav':
        type = 'Audio';
        break;
      case 'avi':
      case 'mp4':
      case '3gpp':
      case 'webm':
      case 'flv':
        type = 'Video';
        break;
      case 'txt':
        type = 'Text';
        break;
      case 'PDF':
      case 'pdf':
        type = 'Pdf';
        break;
      case 'zip':
      case 'rar':
      case '7z':
        type = 'Archive file';
        break;
      default:
        type = 'Article';
        break;
    }

    return type;
  }



  checkIfAllSelected() {
    this.all = !this.all
  }

  filterResults(data) {
    if (data == null || data == '') {
      this.emp = this.FilterEmp;
    } else {
      var arr = [];
      this.FilterEmp.forEach(x => {
        var filter = [];
        if (x.ControlDocuments != null && x.ControlDocuments.length > 0) {
          x.ControlDocuments.forEach(ele => {
            if (ele.Filename != null && ele.Filename.toLowerCase().includes(data.toLowerCase()))
              filter.push(ele);
          });
          if (filter.length > 0)
            arr.push(x);
        }
      });
      this.emp = arr;
    }
  }
  showDocumentDailog() {
    this.binddetails();
    this.ShowPopUp = true;
    this.dropDownAlinement = true;
    this.showArchiveDocumentDailog = false;
  }
  catchEvent(event): void {
    if (event.eventName == 'CheckBoxStatus') {
      if (event.node.data.nodes != null && event.node.data.nodes.length > 0) {
        this.updateStatus(event.node.data.nodes, event.node.data.status);
      } else {
        this.updateStatus(event.node.data, event.node.data.status);
      }
    }
  }

  getAllArticles(allData) {
    allData.forEach(x => {
      if (x.isFolder == 1) {
        this.getAllArticles(x.nodes);
      }
      else {
        if (x.isFolder == 0) {
          if (this.selectedArticles.find(result => result.id == x.id) == null) {
            this.selectedArticles.push(x);
          }
        }
      }
    });
    return allData;
  }

  removeAllArticles(allData) {
    allData.forEach(x => {
      if (x.isFolder == 1) {
        this.removeAllArticles(x.nodes);
      }
      else {
        if (x.isFolder == 0) {
          if (this.selectedArticles.filter(result => result.id == x.id) != null) {
            var index = this.selectedArticles.findIndex(res => res.id == x.id);
            this.selectedArticles.splice(index, 1);
          }
        }
      }
    });
    return allData;
  }


  add(data) {
    data.forEach(element => {
      if (element.type == "folder") {
        this.add(element.nodes)
      } else {
        if (element.type == "file" && element.status == 1) {
          this.selectedArticles.push(element);
          this.uploadedFilesGrid.push(element);
        }
      }

    })
  }
  private flattenArrObj(arr) {
    let newArr = [];
    if (Array.isArray(arr) && arr.length > 0) {
      arr.forEach(element => {
        newArr.push(element);
        if (Array.isArray(element.nodes) && element.nodes.length > 0) {
          newArr = newArr.concat(this.flattenArrObj(element.nodes));
        }
      });
    }
    return newArr;
  }

  updateStatus(data, status) {
    data.forEach(element => {
      element.status = Number(status);
      if (element.nodes != null && element.nodes.length > 0) {
        this.updateStatus(element.nodes, status);
      }
    });
  }
  nodeStatusChangeFunction(treedatas) {
    treedatas.forEach(element => {
      element.status = 2;
      if (element.nodes != null && element.nodes.length > 0) {
        this.nodeStatusChangeFunction(element.nodes);
      }
    });
  }
  deleteDoc(Id: any) {
    this.loading = true;
    this.subscriptions.push(this.kubaControlService.delete(this.controlZoneId, Number.parseInt(Id)).subscribe(res => {
      this.loading = false;
      this.toasterComponent.callToastDlt();
      this.selectedArticles = [];
      this.getApprovalDocuments();
    }));
  }
  binddetails() {
    this.manualList = [];
    let manuals = this.manualservices.getManualsDocumentTraining(BaseServices.BusinessId).subscribe(
      (result: any) => {
        if (result) {
          result.forEach((approvalPersons: any) => {
            if (manuals) {
              this.manualList.push({ label: approvalPersons.Title, value: approvalPersons.Id });
            }
          });
        }
      }
    );
  }
  SelectManual(e: any) {
    this.treeNode = [];
    this.subscriptions.push(this.manualservices
      .getManualTreeBusinessLevel(
        e,
        BaseServices.ApplicationId,
        0,
        BaseServices.BusinessId,
        false
      )
      .subscribe(
        (result: any) => {
          this.treeNode = result.filter(x => x.status == 1 && (x.id !== 10 && x.id !== 18 && x.id !== 19));
          this.treeNode = this.treeNode.filter(x => x.documentType != "checklist");
          if (this.treeNode.length > 0) {
            this.nodeStatusChangeFunction(this.treeNode);
          }
        },
        (error: any) => {
          console.error('Api Error while refreshing tree:', error);
        },
        () => {
          this.showArchiveDocumentDailog = true;
          this.dropDownAlinement = false;
        }
      ));
  }
  //#endregion

  addArticlesToPrivateDocs() {
    this.showArchiveDocumentDailog = false;
    this.ShowDocumentPopUp = false;
    this.ShowPopUp = false;
    this.add(this.treeNode);
  }

  showAddDocumentDailog() {
    this.ShowDocumentPopUp = true;
    this.dropDownAlinement = true;
    this.showArchiveDocumentDailog = true;
    this.subscriptions.push(this.featureManualService
      .getFeatureManualTree(this.featureKey).
      subscribe(
        (result: any) => {
          result.forEach(x => {
            x.nodes.forEach(element => { element.status = 2 })
            x.status = 2
          });
          this.treeNode = result;
          if (this.upConfig.title !== 'ALL_FILES') {
            let flattenned = this.flattenArrObj(this.treeNode);
            if (this.treeActiveNode) {
              const parent = _.filter(flattenned, [
                'id',
                this.treeActiveNode.data.id
              ]);
              this.uploadedFiles = parent[0].nodes;
              this.upConfig.title = parent[0].filename;
            }
            this.loading = false;
          } else {
            this.ngOnInit();
          }
        },
        (error: any) => {
          console.error('Api Error while refreshing tree:', error);
          this.loading = false;
        },
        () => {
          this.showArchiveDocumentDailog = true;
          this.dropDownAlinement = false;
          this.apiResponse = 'reloaded';

          this.loading = false;
        }
      ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}

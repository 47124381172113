//#region import
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
//#endregion

@Component({
    selector: 'mpc-tab-navigation',
    templateUrl: 'mpc-tab-navigation.component.html'
})

export class MPCTabNavigationComponent implements OnInit {


    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private location: Location, ) {
    }

    ngOnInit(){
        document.querySelector("body").classList.remove("opened");
    }
}


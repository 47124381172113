import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';

import { SelectItem } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { BaseServices } from './../../kuba.services';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { TranslateService } from '@ngx-translate/core';
import {
  BusinessSetting,
  SettingsMeta,
} from './../models/business-setting-add';
import { BusinessSettingService } from './../services/businessSetting.service';
import {
  UploadInput,
  UploadFile,
  humanizeBytes,
  UploaderOptions,
} from 'ngx-uploader';
import { Application } from 'src/app/_models';
import { HttpClient } from '@angular/common/http';
let jsPDF = require('jspdf');
require('jspdf-autotable');
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';

export class Emp {
  chkEmployee = false;
  chkMainSafetyDelegate = false;
  chkSafetyDelegate = false;
  chkEmployeeDelegate = false;
  chkFireDelegate = false;
  chkAccountant = false;
  chkAuditor = false;
  chkEnv = true;
  chkCompensationInsurance = false;
  chkStatutoryInsurance = false;
  chkOccupational = false;
  chkpersonal = false;
  chkemployment = false;
  constructor() {}
}

@Component({
  selector: 'business-settings',
  templateUrl: 'business-settings.component.html',
  providers: [BusinessSettingService],
})
export class BusinessSettingsComponent implements OnInit {
  //#region variables
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  businessSetting: FormGroup;
  Language: SelectItem[];
  Employee: SelectItem[];
  MultipleEmployee: SelectItem[];
  OnlyEmployee: SelectItem[];
  businessId: number;
  bId: any;
  Theme: SelectItem[];
  selectedEmployee: any;
  selectedOccupational: any;
  selectedPersonal: number;
  selectedMainSafetyDelegate: any = [];
  selectedSafetyDelegate: any = [];
  selectedEmployeeDelegate: any = [];
  selectedFireDelegate: number;
  selectedAccountant: number;
  selectedAuditor: number;
  selectedEnv: number;
  selectedEmployeeInsurance: any;
  InsuranceCompany: string;
  HomePageAddress: string;
  public selectedLanguage: number;
  selectedTheme: number;
  selectedValues: any;
  employee: any;
  employeeonly: any;
  language: any;
  setting: any;
  theme: any;
  chkEmployee = false;
  chkOccupational = false;
  chkpersonal = false;
  chkemployment = false;
  emp = new Emp();
  uploadedFiles: any[] = [];
  value: number;
  settingId: number;
  selectedHseManager: any;
  selectedHseAdviser: any;
  selectedEnvLighthosue: any;
  selectedPortalContact: any;
  HseGoals: any;
  HseManager: SelectItem[];
  EnvLight: SelectItem[];
  HSEAdviser: SelectItem[];
  Sms: Boolean;
  Email: Boolean;
  businessLogoUpload: any;
  businessLogo: string;
  businessImage: string;
  file: any;
  PortalContact: SelectItem[];
  isNewBusiness = false;
  isFileChoosed = false;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  uploadInputForImage: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  options: UploaderOptions;
  optionForImage: UploaderOptions;
  selectedFiles: any;
  loading: boolean;
  uploadStart: boolean;
  uploadStartForLogo: boolean;
  siteAddress: FormGroup;
  isPortalBusiness: boolean;
  loadingReport = false;
  EmployeeContact: SelectItem[];
  employeeContact: any;
  HavePersonalDelegateOptions: SelectItem[];
  isDinHMSLogin = false;
  uploadExtions = ['image/png', 'image/jpeg', 'image/gif'];
  isShowBusinessLanguage: boolean;
  private subscriptions: Subscription[] = [];
  isAdminPortal: boolean = false;
  selectedEmpInsurance: boolean = true;
  selectedEmpstatutory: boolean = true;
  selectedEmpOccupational: boolean = true;
  //#endregion

  //#region constructors
  /**
   * constructor
   * @param businessSettingService {BusinessSettingService}
   * @param activatedRoute {ActivatedRoute}
   * @param _fb {FormBuilder}
   * @param router {Router}
   */
  constructor(
    private businessSettingService: BusinessSettingService,
    private activatedRoute: ActivatedRoute,
    private _fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private translate: TranslateService,
    private confirmationService: ConfirmationService
  ) {
    this.isPortalBusiness = this.activatedRoute.snapshot.data['portalbusiness'];
    this.options = {
      concurrency: 1,
      allowedContentTypes: ['image/png', 'image/jpeg', 'image/gif'],
    };
    this.optionForImage = {
      concurrency: 1,
      allowedContentTypes: ['image/png', 'image/jpeg', 'image/gif'],
    };
    this.files = [];
    this.humanizeBytes = humanizeBytes;
  }
  //#endregion

  //#region page-events
  /**
   * page load event,get business setting data for edit and bind drodowns
   */
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    if (
      BaseServices.ApplicationId === Application.DIN_HMS ||
      BaseServices.ApplicationId === Application.IK
    ) {
      this.isDinHMSLogin = true;
    }
    if (BaseServices.roleId == '1' || BaseServices.roleId == '2') {
      this.isAdminPortal = true;
    } else {
      this.isAdminPortal = false;
    }
    this.bindDropDowns();
    this.businessSetting = this._fb.group({
      safetyDelegate: ['', [Validators.required]],
      employeeDelegate: ['', [Validators.required]],
      fireDelegate: ['', [Validators.required]],
      accountant: ['', [Validators.required]],
      auditor: ['', [Validators.required]],
      employeeInsurance: ['', [Validators.required]],
      employee: ['', [Validators.required]],
    });
    this.siteAddress = this._fb.group({
      homeSiteAddress: ['', ValidationService.websiteValidator],
    });

    this.businessLogoUpload =
      environment.BASE_URL +
      '/file/upload/business/logo/' +
      BaseServices.ApplicationId +
      '/' +
      this.businessId;
    this.subscriptions.push(
      this.activatedRoute.parent.params.subscribe((params: Params) => {
        let bid = params['bid'];
        this.businessId = +bid;
        if (this.businessId) {
          this.businessLogoUpload = `${environment.BASE_URL}
                                            /file/upload/business/logo/
                                            ${BaseServices.ApplicationId}/${this.businessId}`;
          let result = this.activatedRoute.snapshot.data['setting'];
          if (result) {
            this.HseGoals = result.HseGoal;
            this.settingId = result.Id;
            this.assignCheckBoxes(
              result.SettingsMeta ? result.SettingsMeta : []
            );
            this.selectedLanguage = result.LanguageId;
            this.InsuranceCompany = result.InsuranceCompany;
            this.selectedHseManager = result.HseManagerKey;
            this.selectedHseAdviser = result.HseAdviserKey;
            this.selectedEnvLighthosue = result.EnvironmentallighthouseKey;
            this.businessLogo = result.BusinessLogo;
            this.businessImage = result.BusinessImg;
            this.selectedPortalContact = result.PortalContact;
            let address = {
              homeSiteAddress: result.HomePageAddress
                ? result.HomePageAddress
                : 'http://',
            };
            (<FormGroup>this.siteAddress).setValue(address, { onlySelf: true });
          } else {
            this.selectedLanguage = 2;
            (<FormGroup>this.siteAddress).setValue(
              {
                homeSiteAddress: 'http://',
              },
              { onlySelf: true }
            );
          }
        }
      })
    );
    if (this.settingId) {
      if (this.settingId > 0) {
        this.isNewBusiness = true;
      }
    }

    if (BaseServices.ApplicationId == 5) {
      this.isShowBusinessLanguage = false;
    } else {
      this.isShowBusinessLanguage = true;
    }

    if (BaseServices.ApplicationId == 5) {
      this.isShowBusinessLanguage = false;
    } else {
      this.isShowBusinessLanguage = true;
    }
    let userid = BaseServices.UserId;
    let user = BaseServices.UserRole;
  }

  //#endregion

  //#region control-events

  /**
   * print this page event
   */
  exportPdf() {
    this.loadingReport = true;
    let fileName = this.translate.instant('BUSINESS_DETAIL');
    let filetype = 'pdf';
    let type = 2;
    let position = 0;
    let cultureInfo = sessionStorage.getItem('languageMode')!;
    let ReportJson = this.activatedRoute.parent?.snapshot.data['detail'];
    ReportJson.BusinessId = this.activatedRoute.parent.params['bid'];
    let buId: any = this.activatedRoute.snapshot.parent?.params['bid'];
    ReportJson.BusinessId = buId;
    this.subscriptions.push(
      this.businessSettingService
        .reportGenerate(
          +this.activatedRoute.snapshot.parent.params['bid'],
          type,
          cultureInfo,
          ReportJson,
          BaseServices.ApplicationId
        )
        .subscribe(
          (blob: any) => {
            this.loadingReport = false;
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName}.${filetype.toLowerCase()}`;
            link.click();
          },
          (error: any) => {
            this.loadingReport = false;
            alert('Export not downloaded');
          }
        )
    );
  }

  /**
   * print all page event
   */
  exportallPdf() {
    this.loadingReport = true;
    let fileName = this.translate.instant('BUSINESS_DETAIL');
    let filetype = 'pdf';
    let type = 3;
    let position = 0;
    let cultureInfo = sessionStorage.getItem('languageMode')!;
    let list = this.activatedRoute.parent?.snapshot.data['employeeCount'];
    let employeeCount = list.filter((x: any) => x.Status === 1);
    let ReportJson = this.activatedRoute.parent?.snapshot.data['detail'];
    ReportJson.EmployeeCount = employeeCount.length;
    ReportJson.BusinessId = this.activatedRoute.snapshot.parent.params['bid'];
    this.subscriptions.push(
      this.businessSettingService
        .reportGenerate(
          +this.activatedRoute.snapshot.parent.params['bid'],
          type,
          cultureInfo,
          ReportJson,
          BaseServices.ApplicationId
        )
        .subscribe(
          (blob: any) => {
            this.loadingReport = false;
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName}.${filetype.toLowerCase()}`;
            link.click();
          },
          (error: any) => {
            this.loadingReport = false;
            alert('Export not downloaded');
          }
        )
    );
  }

  /**
   * upload for business logo
   * @param selectedFiles
   */
  filesSelectForLogo(selectedFiles: any) {
    this.uploadStartForLogo = true;
    let isValidImage =
      this.uploadExtions.some(
        (x) => x === selectedFiles.currentTarget.files[0].type
      ) && selectedFiles.currentTarget.files[0].size < 5120000
        ? true
        : false;
    this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(
      (file) => file
    );
    let formData = new FormData();
    formData.append('file', this.selectedFiles[0]);

    this.subscriptions.push(
      this.http
        .post(
          environment.BASE_URL +
            '/file/upload/business/logo/' +
            BaseServices.ApplicationId +
            '/' +
            this.businessId,
          formData
        )
        .map((response: any) => {
          this.uploadStartForLogo = false;
          let res = response;
          this.businessLogo = res.path;
          this.loading = false;
        })
        .subscribe(
          (result) => {},
          (error) => console.log('Upload article Sign Error : ', error),
          () => console.log('Upload article  Sign Complete')
        )
    );
  }
  /**
   * upload for business image
   * @param selectedFiles
   */
  filesSelect(selectedFiles: any): void {
    this.uploadStart = true;
    let isValidImage =
      this.uploadExtions.some(
        (x) => x === selectedFiles.currentTarget.files[0].type
      ) && selectedFiles.currentTarget.files[0].size < 5120000
        ? true
        : false;
    this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(
      (file) => file
    );
    let formData = new FormData();
    formData.append('file', this.selectedFiles[0]);

    this.subscriptions.push(
      this.http
        .post(
          environment.BASE_URL +
            '/file/upload/business/logo/' +
            BaseServices.ApplicationId +
            '/' +
            this.businessId,
          formData
        )
        .map((response: any) => {
          this.uploadStart = false;
          let res = response;
          this.businessImage = res.path;
          this.loading = false;
        })
        .subscribe(
          (result) => {},
          (error) => console.log('Upload article Sign Error : ', error),
          () => console.log('Upload article  Sign Complete')
        )
    );
  }

  /**
   * cancel upload
   * @param id
   */
  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  /**
   * removing uploaded file
   * @param id
   */
  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }
  //#endregion

  //#region methods
  /**
   * binding dropdowns
   */
  bindDropDowns() {
    this.MultipleEmployee = [];
    this.employee = this.activatedRoute.snapshot.data['user'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.MultipleEmployee = [];
        this.MultipleEmployee.push({ label: val.SELECT, value: '' });
      })
    );
    if (this.employee) {
      this.employee.forEach((element: any) => {
        this.MultipleEmployee.push({
          label: element.Name,
          value: element.Id,
        });
      });
    }
    this.employeeonly = this.activatedRoute.snapshot.data['EmployeeOnly'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.OnlyEmployee = [];
        this.OnlyEmployee.push({ label: val.SELECT, value: '' });
      })
    );
    if (this.employeeonly) {
      this.employeeonly.forEach((element: any) => {
        this.OnlyEmployee.push({
          label: element.Name,
          value: element.Id,
        });
      });
    }

    this.Employee = [];
    this.employee = this.activatedRoute.snapshot.data['user'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.Employee = [];
        this.Employee.push({ label: val.SELECT, value: '' });
      })
    );
    if (this.employee) {
      this.employee.forEach((element: any) => {
        this.Employee.push({
          label: element.Name,
          value: element.Id,
        });
      });
    }

    this.employeeContact = this.activatedRoute.snapshot.data['employeeContact'];
    this.EmployeeContact = [];

    if (this.employeeContact) {
      this.employeeContact.forEach((element: any) => {
        this.EmployeeContact.push({
          label: element.Name,
          value: element.Id,
        });
      });
    }

    let HPDOptions =
      this.activatedRoute.snapshot.data['havePersonalDelegateOptions'];
    this.HavePersonalDelegateOptions = [];
    this.HavePersonalDelegateOptions.push({ label: 'Select', value: null });
    if (HPDOptions) {
      HPDOptions.forEach((element: any) => {
        this.HavePersonalDelegateOptions.push({
          label: element.Name,
          value: element.Id,
        });
      });
    }

    this.Language = [];
    this.language = this.activatedRoute.snapshot.data['language'];
    this.Language = [];
    this.Language.push({ label: 'Select', value: null });

    if (this.language) {
      this.language.forEach((element: any) => {
        this.Language.push({ label: element.Title, value: element.Id });
      });
    }

    let manager = this.activatedRoute.snapshot.data['user'];
    this.HseManager = [];
    this.HseManager.push({ label: 'Select', value: null });
    if (manager) {
      manager.forEach((element: any) => {
        this.HseManager.push({ label: element.Name, value: element.Id });
      });
    }

    let manager1 = this.activatedRoute.snapshot.data['user'];
    let adviser = this.activatedRoute.snapshot.data['HseAdviser'];
    this.HSEAdviser = [];
    this.HSEAdviser.push({ label: 'Select', value: null });
    if (adviser) {
      adviser.forEach((element: any) => {
        this.HSEAdviser.push({ label: element.Name, value: element.Id });
      });
    }

    this.EnvLight = [];
    this.EnvLight.push({ label: 'Select', value: null });
    if (manager1) {
      manager1.forEach((element: any) => {
        this.EnvLight.push({ label: element.Name, value: element.Id });
      });
    }
    let portal = this.activatedRoute.snapshot.data['portalcontact'];
    this.PortalContact = [];
    this.PortalContact.push({ label: 'Select', value: null });

    if (portal) {
      portal.forEach((element: any) => {
        this.PortalContact.push({ label: element.Name, value: element.Id });
      });
    }
  }

  /**
   * assign checkbox values
   * @param settingMetas
   */
  assignCheckBoxes(settingMetas: any) {
    settingMetas.forEach((meta: any) => {
      if (meta.BusinessMetadataId === 1) {
        this.emp.chkEmployee = true;
      }
      if (meta.BusinessMetadataId === 10) {
        this.emp.chkOccupational = true;
        this.selectedOccupational = meta.HealthCare;
      }
      if (meta.BusinessMetadataId === 11) {
        this.emp.chkpersonal = true;
        this.selectedPersonal = meta.EmployeeId;
      }
      if (meta.BusinessMetadataId === 12) {
        this.emp.chkemployment = true;
      }
      if (meta.BusinessMetadataId === 2) {
        this.emp.chkMainSafetyDelegate = true;
        this.selectedMainSafetyDelegate.push(meta.EmployeeId);
      }
      if (meta.BusinessMetadataId === 3) {
        this.emp.chkSafetyDelegate = true;
        this.selectedSafetyDelegate.push(meta.EmployeeId);
      }
      if (meta.BusinessMetadataId === 4) {
        this.emp.chkEmployeeDelegate = true;
        this.selectedEmployeeDelegate.push(meta.EmployeeId);
      }
      if (meta.BusinessMetadataId === 5) {
        this.emp.chkFireDelegate = true;
        this.selectedFireDelegate = meta.EmployeeId;
      }
      if (meta.BusinessMetadataId === 6) {
        this.emp.chkAccountant = true;
        this.selectedAccountant = meta.EmployeeId;
      }
      if (meta.BusinessMetadataId === 7) {
        this.emp.chkAuditor = true;
        this.selectedAuditor = meta.EmployeeId;
      }
      if (meta.BusinessMetadataId === 8) {
        this.emp.chkCompensationInsurance = true;
        this.selectedEmployeeInsurance = meta.WorkmanInsurance;
      }
      if (meta.BusinessMetadataId === 9) {
        this.emp.chkStatutoryInsurance = true;
        this.selectedEmployee = meta.OtpStatutoryInsurance;
      }
      if (meta.BusinessMetadataId === 13) {
        this.emp.chkEnv = true;
        this.selectedEnv = meta.EmployeeId;
      }
    });
  }
  /**
   * multi-select safety delegate
   * @param metaId {any}
   * @param empList {any}
   */
  multiSelectSafetyDelegate(metaId: any, empList: any) {
    let settingMetas: any = [];
    empList.forEach((employeeId: number) => {
      let settingMeta = new SettingsMeta();
      settingMeta.BusinessMetadataId = metaId;
      settingMeta.EmployeeId = employeeId;
      settingMeta.BusinessSettingId = this.settingId;
      settingMetas.push(settingMeta);
    });
    empList = [];
    return settingMetas;
  }

  /**
   * get selected dropdown value and selected checkbox value
   */
  empCheckBoxes() {
    let settingMetas: SettingsMeta[] = [];
    if (this.emp.chkEmployee) {
      let settingMeta = new SettingsMeta();
      settingMeta.BusinessMetadataId = 1;
      settingMeta.EmployeeId = 0;
      settingMetas.push(settingMeta);
    }
    if (this.emp.chkMainSafetyDelegate) {
      settingMetas.push(
        ...this.multiSelectSafetyDelegate(2, this.selectedMainSafetyDelegate)
      );
    }
    if (this.emp.chkSafetyDelegate) {
      settingMetas.push(
        ...this.multiSelectSafetyDelegate(3, this.selectedSafetyDelegate)
      );
    }
    if (this.emp.chkEmployeeDelegate) {
      settingMetas.push(
        ...this.multiSelectSafetyDelegate(4, this.selectedEmployeeDelegate)
      );
    }
    if (this.emp.chkFireDelegate && this.selectedFireDelegate) {
      let settingMeta = new SettingsMeta();
      settingMeta.BusinessMetadataId = 5;
      settingMeta.EmployeeId = this.selectedFireDelegate;
      settingMetas.push(settingMeta);
    }
    if (this.emp.chkAccountant && this.selectedAccountant) {
      let settingMeta = new SettingsMeta();
      settingMeta.BusinessMetadataId = 6;
      settingMeta.EmployeeId = this.selectedAccountant;
      settingMetas.push(settingMeta);
    }
    if (this.emp.chkAuditor && this.selectedAuditor) {
      let settingMeta = new SettingsMeta();
      settingMeta.BusinessMetadataId = 7;
      settingMeta.EmployeeId = this.selectedAuditor;
      settingMetas.push(settingMeta);
    }
    if (this.emp.chkCompensationInsurance) {
      if (this.selectedEmployeeInsurance != '') {
        this.selectedEmpInsurance = true;
        let settingMeta = new SettingsMeta();
        settingMeta.BusinessMetadataId = 8;
        settingMeta.WorkmanInsurance = this.selectedEmployeeInsurance;
        settingMetas.push(settingMeta);
      } else {
        this.selectedEmpInsurance = false;
      }
    }
    if (this.emp.chkCompensationInsurance == false) {
      this.selectedEmpInsurance = true;
    }
    if (this.emp.chkStatutoryInsurance) {
      if (this.selectedEmployee != '') {
        this.selectedEmpstatutory = true;
        let settingMeta = new SettingsMeta();
        settingMeta.BusinessMetadataId = 9;
        settingMeta.OtpStatutoryInsurance = this.selectedEmployee;
        settingMetas.push(settingMeta);
      } else {
        this.selectedEmpstatutory = false;
      }
    }
    if (this.emp.chkStatutoryInsurance == false) {
      this.selectedEmpstatutory = true;
    }
    if (this.emp.chkOccupational) {
      if (this.selectedOccupational != '') {
        this.selectedEmpOccupational = true;
        let settingMeta = new SettingsMeta();
        settingMeta.BusinessMetadataId = 10;
        settingMeta.HealthCare = this.selectedOccupational;
        settingMetas.push(settingMeta);
      } else {
        this.selectedEmpOccupational = false;
      }
    }
    if (this.emp.chkOccupational == false) {
      this.selectedEmpOccupational = true;
    }
    if (this.emp.chkpersonal && this.selectedPersonal) {
      let settingMeta = new SettingsMeta();
      settingMeta.BusinessMetadataId = 11;
      settingMeta.EmployeeId = this.selectedPersonal;
      settingMetas.push(settingMeta);
    }
    if (this.emp.chkemployment) {
      let settingMeta = new SettingsMeta();
      settingMeta.BusinessMetadataId = 12;
      settingMeta.EmployeeId = 0;
      settingMetas.push(settingMeta);
    }
    if (this.emp.chkEnv) {
      let settingMeta = new SettingsMeta();
      settingMeta.BusinessMetadataId = 13;
      settingMeta.EmployeeId = 0;
      settingMetas.push(settingMeta);
    }
    return settingMetas;
  }

  /**
   * add and update business settings
   */
  saveChanges() {
    let businessSetting = new BusinessSetting();
    businessSetting.SettingsMeta = this.empCheckBoxes();
    if (
      this.selectedEmpInsurance == true &&
      this.selectedEmpstatutory == true &&
      this.selectedEmpOccupational == true
    ) {
      this.loading = true;
      businessSetting.Id = this.settingId ? this.settingId : 0;
      businessSetting.BusinessId = this.businessId;
      businessSetting.LanguageId = this.selectedLanguage;
      businessSetting.ThemeId = this.selectedTheme;
      businessSetting.InsuranceCompany = this.InsuranceCompany;
      if (this.siteAddress.value.homeSiteAddress) {
        if (this.siteAddress.value.homeSiteAddress.includes('http')) {
          businessSetting.HomePageAddress =
            this.siteAddress.value.homeSiteAddress;
        } else {
          businessSetting.HomePageAddress =
            'http://' + this.siteAddress.value.homeSiteAddress;
        }
      } else {
        businessSetting.HomePageAddress = '';
      }
      businessSetting.HseGoal = this.HseGoals;
      businessSetting.HseFeatureId = this.selectedHseManager;
      businessSetting.HseManagerKey = this.selectedHseManager;
      (businessSetting.HseAdviserKey = this.selectedHseAdviser),
        (businessSetting.EnvironmentallighthouseKey =
          this.selectedEnvLighthosue);
      businessSetting.BusinessLogo = this.businessLogo;
      businessSetting.BusinessImg = this.businessImage;
      businessSetting.PortalContact = this.selectedPortalContact;
      if (businessSetting.Id > 0) {
        this.subscriptions.push(
          this.businessSettingService
            .updateBusinessSetting(businessSetting)
            .subscribe((result) => {
              this.toasterComponent.callToast();
              this.loading = false;
            })
        );
      } else {
        this.subscriptions.push(
          this.businessSettingService
            .addSetting(businessSetting)
            .subscribe((result: any) => {
              this.settingId = result.Id;
              this.toasterComponent.callToast();
              this.loading = false;
            })
        );
      }
    }
  }

  /**
   * get upload url
   * @param url
   * @param callback
   */
  getDataUri(url: any, callback: any) {
    let image = new Image();
    image.onload = function () {
      let canvas = document.createElement('canvas');
      canvas.width = 15; // this.naturalWidth; // or 'width' if you want a special/scaled size
      canvas.height = 15; // this.naturalHeight; // or 'height' if you want a special/scaled size
      canvas.getContext('2d')!.drawImage(url, 0, 0);
      callback(canvas.toDataURL('image/jpg'));
    };
    image.src = url;
  }

  deleteImage(imageType: any) {
    if (imageType != null) {
      this.confirmationService.confirm({
        message: this.translate.instant('DELETE_THIS_RECORD'),
        accept: () => {
          if (imageType == 'Business_Logo') {
            this.businessLogo = null!;
          } else if (imageType == 'Business_Image') {
            this.businessImage = null!;
          }
        },
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
  //#endregion
}

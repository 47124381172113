import { UserRole } from './../../follow-ups/models/deviation';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';

import { DateSetting } from './../../../_helpers/date-setting';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { HelperService, PdfSetting } from './../../../_services/helper.service';
import { EmployeeWorkExperienceList } from './../models/employeeWorkExperienceList';
import { EmployeeWorkExperienceServices } from './../services/employeeworkexperience.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
    moduleId: module.id,
    selector: 'work-exp-edit',
    templateUrl: 'employee-workexp-edit.component.html'
})

export class WorkExpEditComponent extends DateSetting implements OnInit {
    //#region variables
    @ViewChild(ToasterComponent,{static:false}) toasterComponent: ToasterComponent;
    pdf: any;
    IsHide = true;
    IsHidding: Boolean = false;
    workexp: EmployeeWorkExperienceList[];
    loading = false;
    workexpForm: FormGroup;
    employee: any;
    empId: number;
    data: any;
    workExpId: number;
    CountryCode: SelectItem[];
    showNotification = false;
    empdata = new EmployeeWorkExperienceList();
    userId: number;
    currentDate = new Date();
    otherCode: string;
    countryCode: string;
    additionalData: any;
    isNotUser: boolean;
    private subscriptions: Subscription[] = [];
    public override defaultDateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        inline: false,
        height: '26px',
        selectionTxtFontSize: '14px',
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        }
    };
    public startDateOptions: IMyDpOptions = this.defaultDateOptions;
    public endDateOptions: IMyDpOptions = this.startDateOptions;
    //#endregion

    //#region constructor
    constructor(
        public fb: FormBuilder,
        private router: Router,
        private employeeworkexp: EmployeeWorkExperienceServices,
        private confirmationService: ConfirmationService,
        private route: ActivatedRoute,
        private translate: TranslateService

    ) {
        super();
        this.workexpForm = this.fb.group({
            CompanyName: ['', Validators.required],
            StartDate: ['', Validators.required],
            EndDate: ['', Validators.required],
            Position: [''],
            ContactPerson: [''],
            EmailId: [''],
            Mobile: ['', Validators.required],
            MobileCodes: [2],
            OtherCode: ['']
        });
        this.subscriptions.push(this.router
            .routerState.root
            .queryParams
            .subscribe(params => {
                if (params['emp']) {
                    this.empId = +params['emp'];
                } else {
                    this.empId = +this.route.snapshot.params['eid'];
                }
            }));
    }

    //#endregion

    //#region page-events
    /**
     * getting employee id,binding employee work experience datatable,binding country code dropdown
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.isNotUser = +BaseServices.roleId === UserRole.USER ? false : true;

        if (this.userId) {
            this.subscriptions.push(this.employeeworkexp.getEmployeeWorkExperienceByEmployeeId(this.userId).subscribe(result => {
                this.workexp = result;
            }));
        }
        this.initForm();
        this.getEmployee();
        this.CountryCode = [];
        this.data = this.route.snapshot.data['countrycodedropdown'];
        if (this.data) {
            this.data.forEach((element: any) => {
                this.CountryCode.push({ label: '+' + element.Phonecode, value: element.Id });
            });
        }
        this.subscriptions.push(this.translate.stream('FILENAME').subscribe(val => {
        this.additionalData = {
            fileName: val.WORK_EXPERIENCE_LIST,
            header: 'Work Experience List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Company Name', dataKey: 'CompanyName' },
                { title: 'Joining Date', dataKey: 'JoiningDate' },
                { title: 'Relieving Date', dataKey: 'RelievingDate' },
                { title: 'Position', dataKey: 'Position' },
                { title: 'Contact Person', dataKey: 'ContactPerson' },
                { title: 'Email', dataKey: 'EmailId' },
                { title: 'Mobile', dataKey: 'Mobile' }
            ]
        };
    }));

    }
    //#endregion

    //#region methods
    initForm(data?: any) {
        let StartDate: any;
        let EndDate: any;
        let CompanyName = '';
        let Position = '';
        let ContactPerson = '';
        let EmailId = '';
        let Mobile;
        let MobileCodes = 2;
        let OtherCode = '';
        if (data) {
            CompanyName = data.CompanyName ? data.CompanyName : '';
            // Start Date Input and Date Range set
            StartDate = HelperService.formatInputDate(data.JoiningDate ?
                data.JoiningDate : null);
            let startdate: Date = HelperService.formatDate(data.JoiningDate) ?
                new Date(HelperService.formatDate((data.JoiningDate))) : null!;
            this.endDateOptions = HelperService.setDateRange(this.endDateOptions, startdate, 'startDate');
            // End Date Input and Date Range set
            EndDate = HelperService.formatInputDate(data.RelievingDate ? data.RelievingDate : null);
            let enddate: Date = HelperService.formatDate(data.RelievingDate)
                ? new Date(HelperService.formatDate((data.RelievingDate)))
                : null!;
            this.startDateOptions = HelperService.setDateRange(this.startDateOptions, enddate, 'endDate');
            Position = data.Position ? data.Position : '';
            ContactPerson = data.ContactPerson ? data.ContactPerson : '';
            EmailId = data.EmailId ? data.EmailId : '';
            Mobile = data.Mobile ? data.Mobile : '';
            MobileCodes = data.CountryCode ? data.CountryCode : 2;
            OtherCode = data.OtherCode ? data.OtherCode : ''
            let workExperience;
            workExperience = {
                CompanyName: CompanyName,
                StartDate: StartDate ? StartDate : null,
                EndDate: EndDate ? EndDate : null,
                Position: Position,
                ContactPerson: ContactPerson,
                EmailId: EmailId,
                Mobile: Mobile,
                MobileCodes: MobileCodes,
                OtherCode: OtherCode
            };
            (<FormGroup>this.workexpForm)
                .setValue(workExperience, { onlySelf: true });
        }
    }
    /**
     *getting employee list by passing employee id
     */
    getEmployee() {
        this.subscriptions.push(this.employeeworkexp.getEmployeeWorkExperienceByEmployeeId(this.empId).subscribe(result => {
            this.workexp = result;
        }));
    }

    /**
     * getting employee work exp data for edit
     * @param id {number}
     */
    editEmployee(id: number) {
        this.subscriptions.push(this.employeeworkexp.getById(id).subscribe((result: any) => {
            if (result) {
                this.workExpId = id;
                this.empId = result.EmployeeId;
                this.otherCode = result.OtherCode;
                this.IsHidding = (this.otherCode !== null);
                this.countryCode = result.CountryCode;
                this.IsHide = (this.countryCode !== null)
                this.initForm(result);
            }
        }));
    }
    /**
     * Method for setting date range
     * @param dateConfig {IMyDpOptions}
     * @param event{IMyDateModel}
     * @param type  {string}
     */
    setDateInput(dateConfig: IMyDpOptions, event: IMyDateModel, type: string): IMyDpOptions {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
        let dateOptions = HelperService.setDateRange(dateConfig, date, type);
        return dateOptions;
    }
    /**
     * mobile number with code
     * @param data {any}
     */
    mobileNumberWithCode(data: any) {
        this.workexp = [];
        if (data) {
            data.forEach((element: any) => {
                element.Mobile = `${element.CountryCode}${element.Mobile}`;
                this.workexp.push(element);
            });
        }
    }

    /**
     * adding and updating emp work exp
     */
    saveChanges() {
        this.loading = true;
        let employee = new EmployeeWorkExperienceList();
        if (this.workexpForm.valid) {
            employee.Id = (this.workExpId ? this.workExpId : 0);
            employee.EmployeeId = this.empId;
            employee.CompanyName = this.workexpForm.value.CompanyName;
            employee.JoiningDate = this.workexpForm.value.StartDate ?
                HelperService.formatDateFilter(this.workexpForm.value.StartDate.formatted) : null!;
            employee.RelievingDate = this.workexpForm.value.EndDate ?
                HelperService.formatDateFilter(this.workexpForm.value.EndDate.formatted) : null!;
            employee.Position = this.workexpForm.value.Position;
            employee.ContactPerson = this.workexpForm.value.ContactPerson;
            employee.EmailId = this.workexpForm.value.EmailId;
            employee.Mobile = this.workexpForm.value.Mobile;
            employee.Status = '1';
            if (employee.Id > 0) {
                if (!this.IsHide) {
                    employee.OtherCode = this.workexpForm.value.OtherCode;
                    employee.CountryCode = null!;
                } else if (!this.IsHidding) {
                    employee.OtherCode = null!;
                    employee.CountryCode = this.workexpForm.value.MobileCodes;
                }
                this.subscriptions.push(this.employeeworkexp.update(employee).subscribe(() => {
                    this.getEmployee();
                    this.toasterComponent.callToast();
                    this.loading = false;
                }));
            } else {
                if (!this.IsHidding) {
                    employee.CountryCode = this.workexpForm.value.MobileCodes;
                    employee.OtherCode = null!;
                } else if (!this.IsHide) {
                    employee.CountryCode = null!;
                    employee.OtherCode = this.workexpForm.value.OtherCode;
                }
                this.subscriptions.push(this.employeeworkexp.add(employee).subscribe(() => {
                    this.getEmployee();
                    this.toasterComponent.callToast();
                    this.loading = false;
                }));
            }
        }
        this.Clear();
    }

    /**
     * deleting employee work exp
     * @param id {number}
     */
    delete(id: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.employeeworkexp.deleteById(id).subscribe(() => {
                    this.getEmployee();
                }));
            }
        });
    }

    /**
     * clearing the data in the form
     */
    Clear() {
        this.workExpId = 0;
        this.workexpForm.reset();
    }
    /**
     * Print list
     */
    print() {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to generate report?',
            accept: () => {
                let pdfSetting = new PdfSetting();
                pdfSetting.date = 'Date:' + HelperService.formatDate(new Date());
                pdfSetting.businessName = BaseServices.BusinessName;
                pdfSetting.pageHeader = 'Work Experience';
                let data = this.workexp;
                if (data.length > 0) {
                    let columns:any = [
                        { title: 'Company Name', dataKey: 'CompanyName' },
                        { title: 'Joining Date', dataKey: 'JoiningDate' },
                        { title: 'Relieving Date', dataKey: 'RelievingDate' },
                        { title: 'Position', dataKey: 'Position' },
                        { title: 'Contact Person', dataKey: 'ContactPerson' },
                        { title: 'Email Id', dataKey: 'EmailId' },
                        { title: 'Mobile', dataKey: 'Mobile' },

                    ];
                    HelperService.generatePdf(data, columns, 'Work Experience', pdfSetting, 'l');
                } else {
                    this.showNotification = true;
                }
            }
        });
    }

    //#endregion

    //#region control-events
    /**
    * mobilenumber keypress event restrict number greater than 20
    * and restrict text
    */
    onMobileNumberChange(e: any, limitNumber: any) {
        const limit = limitNumber;
        let charCode = (e.which) ? e.which : e.keyCode
        if (e.target.value.length === limit || charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
        }
    }

    /**
     * mobilecoder keypress event restrict number greater than 4 and restrict text
     */
    onMobileCode(e: any) {
        const limit = 4;
        if (e.target.value.length === limit) {
            e.preventDefault();
            const pattern = /[0-9\+\-\ ]/;
            let inputChar = String.fromCharCode(e.charCode);

            if (!pattern.test(inputChar) && e.charCode !== '0') {
                event!.preventDefault();
            }
        }
    }

    /**
     * mobilenumber keypress event restrict number greater than 20 and restrict text
     */
    onMobileNumber(e: any) {
        const limit = 20;
        if (e.target.value.length === limit) {
            e.preventDefault();
            const pattern = /[0-9\+\-\ ]/;
            let inputChar = String.fromCharCode(e.charCode);

            if (!pattern.test(inputChar) && e.charCode !== '0') {
                event!.preventDefault();
            }
        }
    }

    /**
     * changing country code dropdown to textbox
     */
    changeCountryCode() {
        this.IsHide = false;
        this.IsHidding = true;
    }

    /**
     * changing country code textbox to dropdown
     */
    changeToCountryCodeDropDown() {
        this.IsHide = true;
        this.IsHidding = false;
    }
    /**
   * event handler for Start date
   * @param event {IMyDateModel}
   */
    onStartDateChanged(event: IMyDateModel) {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
        this.endDateOptions = this.setDateInput(this.endDateOptions, event, 'startDate');
        if (!date) {
            let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(this.endDateOptions);
            copyConfig.disableSince = HelperService.formatInputDate((new Date()).setDate((new Date()).getDate() + 1)).date;
            this.startDateOptions = copyConfig;
        }
    }

    /**
     * Event handler for End date
     * @param event {IMyDateModel}
     */
    onEndDateChanged(event: IMyDateModel) {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
        this.startDateOptions = this.setDateInput(this.startDateOptions, event, 'endDate');
        if (!date) {
            let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(this.startDateOptions);
            copyConfig.disableSince = HelperService.formatInputDate((new Date()).setDate((new Date()).getDate() + 1)).date;
            this.startDateOptions = copyConfig;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}

import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ActivatedRoute } from '@angular/router';
import { ElectroDocumentService } from 'src/app/kuba/electro/services/electro-document.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { IMyDpOptions } from 'mydatepicker';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from 'src/app/_services/helper.service';
import * as _ from 'lodash';
import { ArticleContent } from 'src/app/shared/ecpl-article-viewer/interfaces';
import { Subscription } from 'rxjs';

@Component({
    selector: 'electro-facts-abt-company',
    templateUrl: 'electro-facts-abt-company.component.html',
    styleUrls: ['electro-facts-abt-company.component.css']
})

export class ElectroFactsAbtCompanyComponent implements OnInit {

    @Input() editMode: boolean;
    @Input() facts: any;
    @Input() articleSrc: ArticleContent;
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @Output() save: EventEmitter<any> = new EventEmitter<any>();
    public articleDateOptions: IMyDpOptions;
    showModalWindow: boolean;
    displayDate: any;
    contractId: any;
    private subscriptions: Subscription[] = [];
    constructor(private route: ActivatedRoute,
        private translator: TranslateService,
        private electroDocumentServices: ElectroDocumentService) {
            this.articleSrc = this.articleSrc;
         }

    ngOnInit() {
        this.facts = JSON.parse(this.facts);
        this.facts.FactsData.ModifiedOn = this.getCurrentDate(this.facts.FactsData.ModifiedOn);
        this.contractId = +this.route.snapshot.params['Id'];
    }

    /**
     * show model event.
     */
    showModal() {
        this.showModalWindow = true;
    }

    /**
     * hide model event.
     */
    hideModal() {
        this.showModalWindow = false;
    }

    /**
    * get current date
    */
    getCurrentDate(date = null) {
        let dtObj, dt;
        this.articleDateOptions = {
            dateFormat: 'dd/mm/yyyy',
            editableDateField: false,
            openSelectorOnInputClick: true,
            firstDayOfWeek: 'su',
            satHighlight: true,
            todayBtnTxt: this.translator.instant('TODAY'),
            dayLabels: {
                su: this.translator.instant('SUN'),
                mo: this.translator.instant('MON'),
                tu: this.translator.instant('TUE'),
                we: this.translator.instant('WED'),
                th: this.translator.instant('THU'),
                fr: this.translator.instant('FRI'),
                sa: this.translator.instant('SAT')
            },
            monthLabels: {
                1: this.translator.instant('JANUARY'),
                2: this.translator.instant('FEBRUARY'),
                3: this.translator.instant('MARCH'),
                4: this.translator.instant('APRIL'),
                5: this.translator.instant('MAY'),
                6: this.translator.instant('JUNE'),
                7: this.translator.instant('JULY'),
                8: this.translator.instant('AUGUST'),
                9: this.translator.instant('SEPTEMBER'),
                10: this.translator.instant('OCTOBER'),
                11: this.translator.instant('NOVEMBER'),
                12: this.translator.instant('DECEMBER')
            }
        };
        if (date === null) {
            dtObj = new Date();
            dt = {
                date: {
                    year: dtObj.getFullYear(),
                    month: dtObj.getMonth() + 1,
                    day: dtObj.getDate()
                }
            }
        } else {
            dtObj = new Date(date);
            dt = {
                date: {
                    year: dtObj.getFullYear(),
                    month: dtObj.getMonth() + 1,
                    day: dtObj.getDate()
                }
            }
        }
        this.displayDate = dtObj.toLocaleDateString('en-GB');
        return dt;
    }

    updateTextVersion() {
        this.articleSrc.subArticles.forEach((element:any) => {
            element.version = this.articleSrc.article.version;
        });
    }
    
    /**
     * save facts
     */
    saveFacts() {
        this.facts.FactsData.BusinessId = BaseServices.BusinessId;
        this.facts.FactsData.ElectroContractId = this.route.snapshot.params['Id'];
        this.facts.FactsData.ModifiedOn = HelperService.formatDateFilter(this.facts.FactsData.ModifiedOn.formatted);
        if (this.facts.FactsData.Id) {
            this.facts.FactsData.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.electroDocumentServices.updateFactsAboutCompany(this.facts.FactsData).subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                    this.save.emit();
                }
            }));
        }
        else {
            this.facts.FactsData.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.electroDocumentServices.saveFactsAboutCompany(this.facts.FactsData).subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                    this.save.emit();
                }
            }));
        }
    }

    /**
     * print fact as pdf event.
     */
    printFactPdf() {
        let type = 'pdf';
        let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
        let languageId = appSettings.Language.LanguageId;
        let CultureInfo = BaseServices.userCultureInfo();
        this.subscriptions.push(this.electroDocumentServices
            .generateFactsAboutCompanyPdf(this.contractId, CultureInfo)
            .subscribe(
                blob => {
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${this.translator.instant('FACTS_ABOUT_THE_COMPANY')}.${type.toLowerCase()}`;
                    link.click();
                },
                error => {
                    alert('Export not downloaded');
                }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectItem, ConfirmationService } from 'primeng/api';

import * as _ from 'lodash';
import { BusinessDepartmentService } from './../../business-department.services';
import { ActivityServices } from './../services/activities.services';
import { DeviationServices } from '../../deviation/services/deviation.service';
import { JobAnalysisService } from '../../safety-job-analysis/services/jobanalysis.services';
import { BusinessServices } from './../../businesses/services/business.services';
import { ApprovalNotice } from '../../safety-job-analysis/models';
import { HelperService, PdfSetting } from './../../../_services/helper.service';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { RiskanalysisService } from '../services/risk-analysis.service';
import { SafetyBaseComponent } from '../../safety-job-analysis/components/safety-base.component';
import {
  Riskanalysis,
  ApprovalNotices,
  MailInformation,
} from './../models/riskanalysis';
import { Department } from '../models/riskanalysis';
import { Rights, FeatureKey, Status } from 'src/app/_models';
import { FeatureBase } from 'src/app/kuba/features/models/features-base.model';
import { TranslateService } from '@ngx-translate/core';
import { element } from 'protractor';
import { Table } from 'primeng/table';
import { environment } from 'src/environments/environment.prod';
import { Subscription } from 'rxjs';
import { log } from 'console';

declare var pdfMake: any;
declare var dataBulider: any;
@Component({
  selector: 'followups-riskanalysis',
  templateUrl: 'riskanalysis-list.component.html',
  styleUrls: ['riskanalysis-list.component.css'],
})
export class RiskAnalysisListComponent
  extends SafetyBaseComponent
  implements OnInit
{
  // #region variable
  @ViewChild('riskTable', { static: false }) riskTable: Table;
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  @ViewChild('dt', { static: false }) dt: Table;
  @ViewChild('td', { static: false }) td: Table;

  isAddNewRisk = false;
  IsGenerateReport = true;
  IsApproveButton: boolean;
  featureBase: FeatureBase;
  isSorting = true;
  riskanalysis: Riskanalysis[];
  riskDetails: any = [];
  showNotification: boolean;
  selectedRisks: any = [];
  approvalNotices: any = [];
  approvalList: ApprovalNotice[] = [];
  riskAnalysisDepartment: FormGroup;
  riskDepartmentDropDown: FormGroup;
  pdf: any;
  departments: SelectItem[];
  Topics: SelectItem[];
  Topics1: SelectItem[];
  selectedDept = new Department();
  editDepartments: any = [];
  // collapse button
  rowID: number = null!;
  isCollapsed = false;
  addType = false;
  viewType = false;
  departmentId: number;
  Sorting: SelectItem[];
  parentId: number;
  totalRiskList: number;
  isAdministrateRiskAnalysis = true;
  isDisableDeleteIcon = true;
  isAdmin: boolean;
  isPortal: boolean;
  Status: SelectItem[];
  statuses: SelectItem[];
  Business: SelectItem[];
  riskInfo: any[];
  departmentExisting: string;
  existsAlerttext: string;
  isdepartmentExists = false;
  isNameExists = false;
  isGuest: boolean;
  Riskdata: any;
  projectId: number;
  featureId: number;
  isOnlyAdmin: boolean;
  loading: boolean;
  selectedBusinessId: number;
  IsSendEmail: boolean = false;
  loadingMail: boolean = false;
  IncidentList: any = [];
  CategoryList: any = [];
  showActionPlan: boolean;
  showRiskScheme: boolean;
  ActionPlanList = [];
  allIncidents: any = [];
  totalIncidents: number;
  StatusIcon: string;
  sendMail = true;
  approveall: any;
  riskList: any;
  isfunctionExists: boolean;
  riskForm: FormGroup;
  riskFormData: any;
  roleId: any;
  Category: any[];
  private subscriptions: Subscription[] = [];
  showNewBtn: boolean;
  showWarning: boolean;
  isEditAccess = false;
  businessDropdown: SelectItem;
  riskDatas: any;
  riskAnalysisData: any;
  sorting: SelectItem[];
  Approval: any[];
  filteredIncidents: any[] = [];
  isRisk: boolean = false;
  loadingReport = false;
  fileName: string;
  selectedRiskId: number;
  ReportJson: any;
  fetureId: number;
  selectedRisksCount: number;
  displayDialog: boolean = false;
  showStaticData: boolean = false;
  //#endregion

  // #region constructor

  /**
   * constructor
   * @param riskanalysisService {RiskanalysisService}
   * @param jobAnalysisService{JobAnalysisService}
   * @param route {ActivatedRoute}
   * @param router {Router}
   * @param _fb {FormBuilder}
   * @param confirmationService {ConfirmationService}
   */
  constructor(
    private riskanalysisService: RiskanalysisService,
    private route: ActivatedRoute,
    public router: Router,
    private _fb: FormBuilder,
    public confirmationService: ConfirmationService,
    private businessService: BusinessServices,
    private businessDepartmentService: BusinessDepartmentService,
    private translate: TranslateService,
    private deviationService: DeviationServices,
    private safetyjobservice: JobAnalysisService
  ) {
    super();
    this.riskAnalysisDepartment = this._fb.group({
      department: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.riskDepartmentDropDown = this._fb.group({
      department: [''],
    });
  }

  //#endregion

  // #region page-event

  /**
   * bind risk analysis list
   */
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    this.roleId = BaseServices.roleId;
    this.riskForm = this._fb.group({
      CategoryId: [],
      Title: [],
      Location: [],
      TopicId: [],
      DepartmentId: [],
      ExpectedBehaviour: [],
      RelatedIncident: [],
      Alreadyactiontaken: [],
      Probability: [],
      Consequence: [],
      RiskFigure: [],
      Participants: [],
      IsActive: [],
      Sorting: [],
    });
    this.showNewBtn = BaseServices.roleId === '1' ? false : true;
    this.isAdmin = BaseServices.roleId === '1' ? true : false;
    this.isGuest = BaseServices.roleId === '4' ? true : false;
    this.isPortal = BaseServices.roleId === '2' ? true : false;
    this.isOnlyAdmin = BaseServices.roleId === '1' ? true : false;
    switch (BaseServices.UserRole) {
      case 'Admin':
        this.IsGenerateReport = false;
        this.isAddNewRisk = false;
        this.IsApproveButton = true;
        this.IsSendEmail = false;
        this.riskInfo = [];
        break;
      case 'Editor':
        this.IsGenerateReport = true;
        this.isAddNewRisk = false;
        this.IsApproveButton = false;
        this.riskDetails = this.route.snapshot.data['list'];
        this.IsSendEmail = true;
        this.getStatusUnApproved(this.riskDetails);
        break;
      case 'User':
        this.IsGenerateReport = true;
        this.isAddNewRisk = false;
        this.IsApproveButton = false;
        this.riskDetails = this.route.snapshot.data['list'];
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.ADMINISTRATE_RISK_ANALYSIS;
        this.IsSendEmail = BaseServices.checkUserRights(
          userRightsId,
          currentUserRole
        );
        this.getStatusUnApproved(this.riskDetails);
        break;
      case 'Guest':
        this.IsGenerateReport = false;
        this.isAddNewRisk = false;
        this.IsApproveButton = false;
        this.riskDetails = this.route.snapshot.data['list'];
        break;
      case 'Portal':
        this.IsGenerateReport = false;
        this.isAddNewRisk = true;
        this.IsApproveButton = true;
        this.riskDetails = this.route.snapshot.data['Risklist'];
        break;
    }
    document.querySelector('body').classList.remove('opened');

    //kW-1279
    if (this.riskDetails.length > 0) {
      let data = this.riskDetails.filter((x: any) => x.SentMail == false);
      if (data.length > 0) {
        this.sendMail = true;
      } else {
        this.sendMail = false;
      }
    }

    if (window.name === 'Remote') {
      this.subscriptions.push(
        this.businessService
          .getById(BaseServices.BusinessId)
          .subscribe((res) => {
            let featureRights = [];
            featureRights = JSON.parse(res.Features);
            let features = [];
            features = featureRights;
            if (features) {
              features.forEach((feature: any) => {
                if (feature.id === FeatureKey.FOLLOW_UP) {
                  let childrens = feature.children;
                  childrens.forEach((children: any) => {
                    if (
                      children.id ===
                        FeatureKey.RISK_ANALYSIS_EXTRA_SORTINGFIELD &&
                      children.checked
                    ) {
                      this.isSorting = false;
                    }
                  });
                }
              });
            }
          })
      );
    } else {
      this.featureBase = BaseServices.getUserFeatures();
      let features = [];
      features = JSON.parse(this.featureBase.Features);
      if (features) {
        features.forEach((feature: any) => {
          if (feature.id === FeatureKey.FOLLOW_UP) {
            let childrens = feature.children;
            childrens.forEach((children: any) => {
              if (
                children.id === FeatureKey.RISK_ANALYSIS_EXTRA_SORTINGFIELD &&
                children.checked
              ) {
                this.isSorting = false;
              } else {
                this.isSorting = true;
              }
            });
          }
        });
      }
    }

    if (BaseServices.UserRole === 'User') {
      this.isDisableDeleteIcon = false;
      this.onAdministrateRiskAnalysis();
    } else if (BaseServices.UserRole !== 'Editor') {
      this.isAdministrateRiskAnalysis = false;
    }
    this.approvalNotices = this.route.snapshot.data['approvalNotices'];
    this.departments = [];
    this.editDepartments = [];
    let department = this.route.snapshot.data['departments'];
    if (department) {
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
          this.departments = [];
          this.departments.push({ label: val.SELECT, value: null });
          department.forEach((departmentCheck: any) => {
            if (departmentCheck) {
              this.departments.push({
                label: departmentCheck.Name,
                value: departmentCheck.Id,
              });
              this.editDepartments.push({
                Name: departmentCheck.Name,
                Id: departmentCheck.Id,
              });
            }
          });
        })
      );
    }
    // Topics
    this.Topics = [];
    let topics = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.Topics.push({ label: val.SELECT, value: null });
      })
    );
    if (BaseServices.roleId === '1') {
      topics = this.route.snapshot.data['adminTopic'];
    } else {
      topics = this.route.snapshot.data['topic'];
    }
    if (topics) {
      topics.forEach((topicsList: any) => {
        this.Topics.push({
          label: topicsList.Name,
          value: topicsList.Id,
        });
      });
    }
    this.Topics1 = this.Topics;
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.Sorting = [];
        this.Sorting.push({ label: val.SELECT, value: null });
        this.Sorting.push({ label: 'Person', value: 'Person' });
        this.Sorting.push({ label: 'Miljø', value: 'Miljø' });
        this.Sorting.push({ label: 'Materiell', value: 'Materiell' });
      })
    );
    this.subscriptions.push(
      this.translate.stream('SELECT_STATUS_DROP').subscribe((val) => {
        this.Status = [];
        this.Status.push(
          { label: val.SELECT, value: null },
          { label: val.INACTIVE, value: 0 },
          { label: val.ACTIVE, value: 1 }
        );
      })
    );

    this.subscriptions.push(
      this.translate.stream('SELECT_STATUSES').subscribe((val) => {
        this.statuses = [];
        this.statuses.push(
          { label: val.SELECT, value: null },
          { label: val.NEW, value: 7 },
          { label: val.OPEN, value: 8 },
          { label: val.DONE, value: 9 },
          { label: val.REJECTED, value: 10 }
        );
      })
    );

    this.subscriptions.push(
      this.translate.stream('RISKFIGURE_DROPDOWN').subscribe((val) => {
        this.Riskdata = [];
        this.Riskdata.push(
          { label: val.SELECT, value: null },
          { label: val.LOW, value: 'Low' },
          { label: val.MEDIUM, value: 'Medium' },
          { label: val.HIGH, value: 'High' }
        );
      })
    );
    this.subscriptions.push(
      this.translate.stream('APPROVAL').subscribe((val) => {
        this.Approval = [];
        this.Approval.push(
          { label: val.SELECT, value: null },
          { label: val.APPROVE, value: val.APPROVE },
          { label: val.UNAPPROVED, value: val.UNAPPROVED }
        );
      })
    );

    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.Business = [];
        this.Business.push({ label: val.SELECT, value: null });
      })
    );

    let businessList = this.route.snapshot.data['business'];
    if (businessList) {
      businessList.forEach((repitition: any) => {
        if (repitition.Features != null) {
          var feature = JSON.parse(repitition.Features);
          var checkFollowup = feature.find((x) => x.id == 15);
          if ( checkFollowup != null && checkFollowup!= undefined && checkFollowup.checked) {
            this.Business.push({
              label: repitition.Name,
              value: repitition.Id,
            });
          }
        }
      });
    }
    if (!this.isAdmin) {
      this.bindRiskDetails();
    }
    if (this.roleId == '2') {
      this.riskDetails = [];
      this.allIncidents = [];
      this.selectedBusinessId = JSON.parse(
        sessionStorage.getItem('CurrentBusinessId')
      );

      if (this.selectedBusinessId > 0) {
        this.subscriptions.push(
          this.riskanalysisService
            .portalList(this.selectedBusinessId)
            .subscribe((result) => {
              if (result) {
                this.riskDetails = result;
                this.GetCategorizedRiskDetails();
              }
            })
        );
      } else {
        this.riskDetails = this.route.snapshot.data['Risklist'];
        if (this.riskDetails != null) {
          this.GetCategorizedRiskDetails();
        }
      }
      this.riskDepartmentDropDown.controls['businessId'].setValue(
        this.selectedBusinessId
      );
    }

    this.subscriptions.push(
      this.translate.stream('SELECT_FOR_CATEGORY').subscribe((val) => {
        let category: any;
        this.Category = [];
        let isLiveEnvironment =
          environment.BASE_FRONT_URL == 'https://ik.kuba.no/' ? true : false;
        if (isLiveEnvironment) {
          this.Category.push(
            { label: val.SELECT, value: null },
            { label: val.BRANN, value: 206 },
            { label: val.PERSONKADER, value: 207 },
            { label: val.ERGONOMI, value: 208 },
            { label: val.PSYKOSOSIALT, value: 209 },
            { label: val.TYVERI, value: 210 },
            { label: val.FYSISKEFARER, value: 535 },
            { label: val.KJEMISKOGBIOLOGISK, value: 536 },
            { label: val.ORGANIZATIONALLY, value: 537 }
          );
        } else {
          this.Category.push(
            { label: val.SELECT, value: null },
            { label: val.BRANN, value: 69 },
            { label: val.PERSONKADER, value: 70 },
            { label: val.ERGONOMI, value: 71 },
            { label: val.PSYKOSOSIALT, value: 72 },
            { label: val.TYVERI, value: 73 },
            { label: val.FYSISKEFARER, value: 119 },
            { label: val.KJEMISKOGBIOLOGISK, value: 120 },
            { label: val.ORGANIZATIONALLY, value: 121 }
          );
        }
        category = this.route.snapshot.data['category'];
        if (category) {
          category.forEach((categoryList: any) => {
            this.Category.push({
              label: categoryList.Name,
              value: categoryList.Id,
            });
          });
        }
      })
    );
    if (this.roleId == '2') {
      this.isEditAccess =
        this.roleId == '2' && this.selectedBusinessId > 0 ? true : false;
    } else {
      this.isEditAccess = this.roleId == '3' ? true : false;
    }
    this.bindDepartmentDropdown();
    this.sortingDropdown();
    console.log(this.riskDetails);
  }
  updateSelectedRisksCount() {
    this.selectedRisksCount = this.selectedRisks.length;
  }

  //#endregion

  // #region methods

  /**
   * bind department dropdown
   */
  bindDepartmentDropdown() {
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.departments = [];
        this.departments.push({ label: val.SELECT, value: null });
      })
    );
    this.subscriptions.push(
      this.businessDepartmentService
        .getCachedBusinessDepartment()
        .subscribe((departmentList) => {
          if (departmentList) {
            this.editDepartments = [];
            departmentList.forEach((deptDropDown: any) => {
              if (deptDropDown) {
                this.departments.push({
                  label: deptDropDown.Name,
                  value: deptDropDown.Id,
                });
                this.editDepartments.push({
                  Name: deptDropDown.Name,
                  Id: deptDropDown.Id,
                });
              }
            });
          }
        })
    );
  }

  bindRiskDetails() {
    this.allIncidents = [];
    this.subscriptions.push(
      this.riskanalysisService.list().subscribe((result) => {
        if (result) {
          this.riskDetails = result;
          this.selectedRisks = [];
        }
      })
    );
    this.GetCategorizedRiskDetails();
    if (this.allIncidents != null) {
      this.riskDatas = this.allIncidents;
    }
  }

  //Get Category name from Id : KW-907
  GetCategoryNamefromID(CategoryId) {
    let Id = parseInt(CategoryId);
    if (CategoryId === '0') {
      return 'Ukategorisert';
    } else if (this.CategoryList) {
      return this.CategoryList.find((x) => x.Id === Id).Name;
    } else return 'Ukategorisert';
  }

  //To get Categorized Risk Details : KW-907
  GetCategorizedRiskDetails() {
    if (this.riskDetails != null) {
      var riskDetails = this.riskDetails;
      var categorizedRisks = [];
      let catList: any = [];
      var groups = [];

      categorizedRisks = riskDetails.filter((x: any) => x.CategoryId != 0);
      groups = categorizedRisks.reduce(function (obj: any, item: any) {
        obj[item.CategoryId] = obj[item.CategoryId] || [];
        obj[item.CategoryId].push(item.Category);
        catList.push({ Id: item.CategoryId, Name: item.Category });
        return obj;
      }, Object.create(null));
      this.CategoryList = catList;

      var keys = [];
      keys = Object.keys(groups);
      keys.push('0'); // Add to get uncategorized

      var incidentCount: number = 0;
      keys.forEach((element: any) => {
        this.IncidentList = [];
        let incident = [];
        incident = this.riskDetails.filter(
          (x: any) => x.CategoryId == parseInt(element) && x.Title != null
        );
        incident.forEach((item: any) => {
          var IsDuplicate = false;
          //Check for duplicate Incidents
          let groupByStatus: any = [];
          item.TaskPlanListDto.forEach(function (a: any) {
            groupByStatus.push({ Id: a.Id, StatusText: a.StatusText });
          });
          this.StatusIcon = 'null';
          if (groupByStatus.length > 0) {
            let isDoneStatus = groupByStatus.every(function (x: any) {
              return x.StatusText == 'DONE';
            });
            if (isDoneStatus == true) {
              this.StatusIcon = 'icon ic-sm icon-clear-deadline';
            } else if (isDoneStatus == false) {
              this.StatusIcon = 'icon ic-sm icon-far-deadline';
            }
          }
          if (this.IncidentList != null) {
            let data = [];
            data = this.IncidentList;
            if (data.find((x: any) => x.Id === item.Id)) {
              IsDuplicate = true;
            }
            if (!IsDuplicate) {
              this.IncidentList.push({
                Id: item.Id,
                Category: item.Category,
                CategoryId: item.CategoryId,
                Title: item.Title,
                Location: item.Location,
                Topics: item.Topics,
                Description: item.Description,
                Alreadyactiontaken: item.Alreadyactiontaken,
                CreatedOn: item.CreatedOn,
                ModifiedOn: item.ModifiedOn,
                ModifiedBy: item.EditedBy,
                ApprovalStatus: item.ApprovalStatus,
                BackColor: item.RiskFigureData.BackColor,
                Value: item.RiskFigureData.Value,
                ValueText: item.RiskFigureData.ValueText,
                StatusIcon:
                  this.StatusIcon != undefined ? this.StatusIcon : 'null',
                ActionPlan: item.TaskPlanListDto,
                RiskFigure: item.RiskFigure,
                RiskFigureValue: item.RiskFigureData.Value,
                RelatedIncident: item.RelatedIncident,
                Partcipants: item.Partcipants,
                TaskPlanListDto: item.TaskPlanListDto,
                BusinessDepartmentId: item.BusinessDepartmentId,
                TopicId: item.TopicId,
                Sorting: item.Sorting,
              });
              incidentCount = incidentCount + 1;
            }
          }
        });
        if (element != 'null') {
          this.allIncidents.push({
            CategoryId: element,
            Category:
              element != '0'
                ? this.GetCategoryNamefromID(element)
                : 'Ukategorisert',
            IncidentList: this.IncidentList,
          });
        }
      });
      this.totalIncidents = incidentCount;
      this.allIncidents = this.allIncidents.sort((a, b) => {
        var nameA; // ignore upper and lowercase
        var nameB;
        if (a.Category != null) {
          nameA = a.Category.toUpperCase();
        }
        if (b.Category != null) {
          nameB = b.Category.toUpperCase();
        } // ignore upper and lowercase .toUpperCase()
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      this.riskAnalysisData = this.allIncidents;
      const incidentLists = this.allIncidents.map(
        (item) => item.IncidentList.length
      );
      this.selectedRisksCount = incidentLists;
    }
  }

  /**
   * check all checkbox
   */
  toggleSelect = (event: any) => {
    this.approveall = event.target.checked;
    this.riskList.forEach(function (item: any) {
      item.selected = event.target.checked;
    });
  };

  public setCollapse(i: number): boolean {
    let isCollapsed = i === this.rowID;
    return !isCollapsed;
  }

  //#endregion

  // #region control-events

  /**
   * edit department
   * @param DeptType {any}
   */
  editDeptType(DeptType: Department) {
    this.departmentId = DeptType.Id!;
    let result = {
      department: DeptType.Name,
    };
    (<FormGroup>this.riskAnalysisDepartment).setValue(result, {
      onlySelf: true,
    });
  }
  /**
   * delete department
   * @param selectedDept {any}
   */
  deleteDept(selectedDept: any) {
    this.subscriptions.push(
      this.deviationService
        .checkDepartmentExist(selectedDept)
        .subscribe((result: any) => {
          if (result) {
            this.isdepartmentExists = true;
            this.departmentExisting = this.translate.instant(
              'CANT_DELETE_DEPARTMENT_USED_BY_ANOTHER_RA'
            );
            setTimeout(() => {
              this.isfunctionExists = false;
            }, 3000);
          } else {
            this.confirmationService.confirm({
              message: this.translate.instant('DELETE_THIS_RECORD'),
              accept: () => {
                this.subscriptions.push(
                  this.riskanalysisService
                    .deleteDepartement(selectedDept.Id)
                    .subscribe((isDepartmentDeleted) => {
                      if (isDepartmentDeleted) {
                        this.clearCachedDepartment();
                        this.bindDepartmentDropdown();
                      }
                    })
                );
              },
            });
          }
        })
    );
  }
  /**
   * export pdf
   */
  generateReport() {
    let data = [];
    if (this.selectedRisks.length > 0) {
      data.push(...this.selectedRisks);
      this.confirmationService.confirm({
        message: 'Are you sure that you want to generate report?',
        accept: () => {
          let pdfSetting = new PdfSetting();
          pdfSetting.date = 'Date:' + HelperService.formatDate(new Date());
          pdfSetting.businessName = BaseServices.BusinessName;
          pdfSetting.pageHeader = 'Risk Analysis';
          let html = document.querySelector('#staticData');
          if (data.length > 0) {
            let columns: any = [
              { title: 'Incident/Stress', dataKey: 'Title' },
              { title: 'Location', dataKey: 'Location' },
              { title: 'Topics', dataKey: 'Topics' },
              { title: 'RiskFigure', dataKey: 'RiskFigure.value' },
              { title: 'Sorting', dataKey: 'Sorting' },
              { title: 'What can happen', dataKey: 'Description' },
              { title: 'Created On', dataKey: 'CreatedOn' },
              { title: 'Edited On', dataKey: 'EditedOn' },
              { title: 'Participants', dataKey: 'Participants' },
            ];
            HelperService.generatePdfSafetyJobAnalysis(
              this.selectedRisks,
              columns,
              'RiskAnalysis',
              pdfSetting,
              'l',
              html
            );
          }
        },
      });
    } else {
      this.showNotification = true;
    }
  }

  showDialog() {
    this.displayDialog = true;
  }

  /**
   * export pdf
   */
  generateReport1(withApprovalStatus: boolean) {
    this.displayDialog = false;
    let selectedRisks = this.selectedRisks;
    this.selectedRisks = [];
    selectedRisks.forEach((element: any) => {
      if (element.IncidentList) {
        element.IncidentList.forEach((data: any) => {
          this.selectedRisks.push(data);
        });
      } else {
        this.selectedRisks.push(element);
      }
    });

    const ids = this.selectedRisks.map((o: any) => o.Id); // report duplication issue fix
    const filteredList = this.selectedRisks.filter(
      ({ Id }: any, index: any) => !ids.includes(Id, index + 1)
    );

    if (this.selectedRisks.length > 0) {
      this.loading = true;
      let fileName = this.translate.instant('RISK_ANALYSIS');
      let type = 'pdf';
      let position = 0;
      let cultureInfo = 'en-GB';
      let languageMode = sessionStorage.getItem('languageMode');
      if (languageMode != null) {
        cultureInfo = languageMode;
      }
      let ReportJson = filteredList;
      let workingModule = JSON.parse(sessionStorage.getItem('workingModule')!);
      this.featureId = workingModule.id;
      if (BaseServices.UserRole == 'Portal') {
        this.subscriptions.push(
          this.safetyjobservice
            .reportGenerate(
              this.selectedBusinessId,
              false,
              BaseServices.UserId,
              this.projectId,
              this.featureId,
              cultureInfo,
              ReportJson,
              BaseServices.ApplicationId,
              withApprovalStatus
            )
            .subscribe(
              (blob: any) => {
                this.loading = false;
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${fileName}.${type.toLowerCase()}`;
                link.click();
              },
              (error: any) => {
                this.loading = false;
                alert('Export not downloaded');
              }
            )
        );
      } else {
        this.subscriptions.push(
          this.safetyjobservice
            .reportGenerate(
              BaseServices.BusinessId,
              false,
              BaseServices.UserId,
              this.projectId,
              this.featureId,
              cultureInfo,
              ReportJson,
              BaseServices.ApplicationId,
              withApprovalStatus
            )
            .subscribe(
              (blob: any) => {
                this.loading = false;
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${fileName}.${type.toLowerCase()}`;
                link.click();
              },
              (error: any) => {
                this.loading = false;
                alert('Export not downloaded');
              }
            )
        );
      }
    } else {
      alert(this.translate.instant('PLEASE_SELECT_A_REPORT'));
    }
  }
  /**
   * save department
   */
  saveDept() {
    let riskDept = new Department();
    riskDept.Id = this.departmentId ? this.departmentId : 0;
    riskDept.Name = this.riskAnalysisDepartment.value.department;
    riskDept.Status = '1';
    riskDept.BusinessId = BaseServices.BusinessId;
    riskDept.ApplicationId = BaseServices.ApplicationId;
    if (riskDept.Id > 0) {
      this.subscriptions.push(
        this.riskanalysisService
          .updateDept(riskDept)
          .subscribe((isDeptUpdated: any) => {
            if (isDeptUpdated) {
              this.clearCachedDepartment();
              this.bindDepartmentDropdown();
              this.riskDepartmentDropDown
                .get('department')!
                .patchValue(isDeptUpdated.Id);
              this.toasterComponent.callToast();
            } else {
              this.clearCachedDepartment();
              this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
              this.isNameExists = true;
              setTimeout(() => {
                this.isNameExists = false;
              }, 3000);
            }
          })
      );
    } else {
      this.subscriptions.push(
        this.riskanalysisService
          .createDept(riskDept)
          .subscribe((isDeptAdded: any) => {
            if (isDeptAdded) {
              this.bindDepartmentDropdown();
              this.riskDepartmentDropDown
                .get('department')!
                .patchValue(isDeptAdded.Id);
              this.toasterComponent.callToast();
            } else {
              this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
              this.isNameExists = true;
              setTimeout(() => {
                this.isNameExists = false;
              }, 3000);
            }
          })
      );
    }
  }
  /**
   * cache clear of department
   */
  clearCachedDepartment() {
    this.businessDepartmentService.clearCachedAllDepartment();
  }
  /**
   * bind nested list based on index
   * @param event {any}
   * @param i {number}
   */
  checkSelect(event: any, i: number) {
    if (event.target.checked) {
      let approval = new ApprovalNotice();
      approval.Id = 0;
      approval.Name = BaseServices.UserId;
      approval.RiskAnalysisId = i;
      approval.ApprovedBy = BaseServices.UserId;
      approval.ApprovedOn = new Date();
      this.approvalList.push(approval);
    } else {
      _.remove(this.approvalList, (x: any) => {
        return x.RiskAnalysisId === i;
      });
    }
  }

  onBusinessChanged(event: any, businessDropdown: any) {
    this.loading = true;
    if (event.value) {
      this.selectedBusinessId = event.value;
      sessionStorage.setItem('CurrentBusinessId', event.value);
      this.IsApproveButton = true;
      if (BaseServices.UserRole == 'Portal') {
        this.subscriptions.push(
          this.riskanalysisService
            .portalList(this.selectedBusinessId)
            .subscribe((result) => {
              if (result) {
                this.riskDetails = null;
                this.allIncidents = [];
                this.riskDetails = result;
                this.loading = false;
                this.GetCategorizedRiskDetails();
              }
            })
        );
      } else {
        this.subscriptions.push(
          this.riskanalysisService
            .getRiskAnalysisForAdmin(BaseServices.ApplicationId, event.value)
            .subscribe((result) => {
              if (result) {
                this.riskDetails = null;
                this.allIncidents = [];
                this.riskDetails = result;
                this.loading = false;
                this.GetCategorizedRiskDetails();
              }
            })
        );
      }
    } else {
      this.riskDetails = this.route.snapshot.data['Risklist'];
      this.loading = false;
      this.GetCategorizedRiskDetails();
    }
  }

  onFilterValueChanged(event: any, valueropdown: any, filterType: string) {
    if (event.value) {
      this.allIncidents = this.riskAnalysisData;
      if (filterType == 'department') {
        const searchTerm = valueropdown.selectedOption.value;
        this.allIncidents = this.allIncidents.filter((item) => {
          return item.IncidentList.some((subItem) => {
            return subItem.BusinessDepartmentId.includes(searchTerm);
          });
        });
        this.dt = this.allIncidents.forEach((x) => {
          x.IncidentList = x.IncidentList.filter((x) =>
            x.BusinessDepartmentId.includes(valueropdown.selectedOption.value)
          );
        });
      } else if (filterType == 'topic') {
        const searchTerm = valueropdown.selectedOption.value;
        this.allIncidents = this.allIncidents.filter((item) => {
          return item.IncidentList.some(
            (subItem) => subItem.TopicId == searchTerm
          );
        });
        this.dt = this.allIncidents.forEach((x) => {
          x.IncidentList = x.IncidentList.filter(
            (x) => x.TopicId == valueropdown.selectedOption.value
          );
        });
      } else if (filterType == 'sorting') {
        const searchTerm = valueropdown.selectedOption.value;
        this.allIncidents = this.allIncidents.filter((item) => {
          return item.IncidentList.some(
            (subItem) => subItem.Sorting == searchTerm
          );
        });
        this.dt = this.allIncidents.forEach((x) => {
          x.IncidentList = x.IncidentList.filter(
            (x) => x.Sorting == valueropdown.selectedOption.value
          );
        });
      } else if (filterType == 'Riskdata') {
        this.bindRiskDetails();
        const searchTerm = valueropdown.selectedOption.value.toUpperCase();
        this.allIncidents = this.allIncidents.filter((item) => {
          return item.IncidentList.some(
            (subItem) => subItem.ValueText == searchTerm
          );
        });
        this.dt = this.allIncidents.forEach((x) => {
          x.IncidentList = x.IncidentList.filter(
            (x) =>
              x.ValueText == valueropdown.selectedOption.value.toUpperCase()
          );
        });
      }
    } else {
      this.allIncidents = this.riskAnalysisData;
    }
  }

  //Implemented Incident search bar.
  onIncidentChanged(control: any) {
    if (control.value !== '') {
      this.allIncidents = this.riskAnalysisData;
      const searchTerm = control.value;

      this.allIncidents = this.allIncidents.filter((item) => {
        return item.IncidentList.some((subItem) =>
          subItem.Title.includes(searchTerm)
        );
      });
    } else {
      this.allIncidents = this.riskAnalysisData;
    }
  }
  /**
   * department popup reset
   */
  onBeforeRiskDepartmentDialogHide() {
    this.riskAnalysisDepartment.reset();
  }

  toggleClick(i: number): void {
    this.rowID = i;
    this.isCollapsed = !this.isCollapsed;
  }

  toggleClickHide(): void {
    this.rowID = null!;
  }
  /**
   * delete risk
   * @param id {number}
   */
  confirm(id: number) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.riskanalysisService
            .deleteRisk(id)
            .subscribe((isRiskDeleted: any) => {
              if (isRiskDeleted) {
                this.toasterComponent.callToastDlt();
                this.allIncidents.forEach((data: any) => {
                  data.IncidentList = data.IncidentList.filter(
                    (x: any) => x.Id !== id
                  );
                });
              }
            })
        );
      },
    });
  }
  /**
   * sort change
   * @param event {any}
   * @param sortingDropdown {any}
   */
  onSortingChange(event: any, sortingDropdown: any) {
    if (event.value) {
      this.riskTable.filter(
        sortingDropdown.selectedOption.value,
        'Sorting',
        'equals'
      );
    } else {
      this.riskTable.reset();
    }
  }
  /**
   * sort change
   * @param event {any}
   * @param sortingDropdown {any}
   */
  onTopicChange(event: any, topicDropdown: any) {
    if (event.value) {
      this.td.filter(topicDropdown.selectedOption.label, 'Topics', 'contains');
    } else {
      this.td.reset();
    }
  }
  onApprovalChange(event: any, topicDropdown: any) {
    if (event.value) {
      this.td.filter(
        topicDropdown.selectedOption.value,
        'ApprovalStatus',
        'equals'
      );
    } else {
      this.td.reset();
    }
  }
  onStatusChanged(event: any, statusDropdown: any) {
    if (event) {
      this.riskTable.filter(
        statusDropdown.selectedOption.value,
        'IsActive',
        'equals'
      );
    } else {
      this.riskTable.reset();
    }
  }
  saveApprovalNotice() {
    this.loading = true;
    let approvalNotices: any = [];
    let selectedRisks = this.selectedRisks; //approve issue fix
    this.selectedRisks = [];
    selectedRisks.forEach((element) => {
      if (element.IncidentList) {
        element.IncidentList.forEach((data) => {
          this.selectedRisks.push(data);
        });
      } else {
        this.selectedRisks.push(element);
      }
    });

    if (this.selectedRisks.length > 0) {
      this.selectedRisks.forEach((e: any) => {
        let approvalNotice = new ApprovalNotices();
        approvalNotice.Id = 0;
        approvalNotice.BusinessId = BaseServices.BusinessId;
        approvalNotice.RiskAnalysisId = e.Id;
        approvalNotice.ApprovedBy = BaseServices.UserId;
        approvalNotice.ApprovedOn = new Date();
        approvalNotices.push(approvalNotice);
      });
    } else {
      this.showNotification = true;
    }
    this.subscriptions.push(
      this.riskanalysisService
        .addApprovalNotices(approvalNotices)
        .subscribe((addResult) => {
          if (addResult) {
            this.refreshNotificationTable();
          }
          this.subscriptions.push(
            this.riskanalysisService.list().subscribe((result) => {
              if (result) {
                this.riskDetails = result;
                this.selectedRisks = [];
                this.allIncidents = [];

                var riskDetails = result;
                var categorizedRisks = [];
                let catList = [];
                var groups = [];

                categorizedRisks = riskDetails.filter((x) => x.CategoryId != 0);
                groups = categorizedRisks.reduce(function (obj, item) {
                  obj[item.CategoryId] = obj[item.CategoryId] || [];
                  obj[item.CategoryId].push(item.Category);
                  catList.push({ Id: item.CategoryId, Name: item.Category });
                  return obj;
                }, Object.create(null));
                this.CategoryList = catList;

                var keys = [];
                keys = Object.keys(groups);
                keys.push('0'); // Add to get uncategorized

                var incidentCount: number = 0;
                keys.forEach((element: any) => {
                  this.IncidentList = [];
                  let incident = [];
                  incident = this.riskDetails.filter(
                    (x) => x.CategoryId == parseInt(element) && x.Title != null
                  );
                  incident.forEach((item: any) => {
                    var IsDuplicate = false;
                    //Check for duplicate Incidents
                    let groupByStatus = [];
                    item.TaskPlanListDto.forEach(function (a) {
                      groupByStatus.push({
                        Id: a.Id,
                        StatusText: a.StatusText,
                      });
                    });
                    this.StatusIcon = 'null';
                    if (groupByStatus.length > 0) {
                      let isDoneStatus = groupByStatus.every(function (x: any) {
                        return x.StatusText == 'DONE';
                      });
                      if (isDoneStatus == true) {
                        this.StatusIcon = 'icon ic-sm icon-clear-deadline';
                      } else if (isDoneStatus == false) {
                        this.StatusIcon = 'icon ic-sm icon-far-deadline';
                      }
                    }
                    if (this.IncidentList != null) {
                      let data = [];
                      data = this.IncidentList;
                      if (data.find((x) => x.Id === item.Id)) {
                        IsDuplicate = true;
                      }
                      if (!IsDuplicate) {
                        this.IncidentList.push({
                          Id: item.Id,
                          Category: item.Category,
                          CategoryId: item.CategoryId,
                          Title: item.Title,
                          Location: item.Location,
                          Topics: item.Topics,
                          Description: item.Description,
                          Alreadyactiontaken: item.Alreadyactiontaken,
                          CreatedOn: item.CreatedOn,
                          ModifiedOn: item.ModifiedOn,
                          ModifiedBy: item.EditedBy,
                          ApprovalStatus: item.ApprovalStatus,
                          BackColor: item.RiskFigureData.BackColor,
                          Value: item.RiskFigureData.Value,
                          ValueText: item.RiskFigureData.ValueText,
                          StatusIcon:
                            this.StatusIcon != undefined
                              ? this.StatusIcon
                              : 'null',
                          ActionPlan: item.TaskPlanListDto,
                          RiskFigure: item.RiskFigure,
                          RiskFigureValue: item.RiskFigureData.Value,
                          Partcipants: item.Partcipants,
                          TaskPlanListDto: item.TaskPlanListDto,
                        });
                        incidentCount = incidentCount + 1;
                      }
                    }
                  });
                  if (element != 'null') {
                    this.allIncidents.push({
                      CategoryId: element,
                      Category:
                        element != '0'
                          ? this.GetCategoryNamefromID(element)
                          : 'Ukategorisert',
                      IncidentList: this.IncidentList,
                    });
                  }
                });
                this.totalIncidents = incidentCount;
              }
              this.loading = false;
            })
          );
        })
    );
    this.toasterComponent.callToast();
  }

  refreshNotificationTable() {
    this.subscriptions.push(
      this.riskanalysisService
        .getApprovalNoticeByBusiness()
        .subscribe((notificationList: any) => {
          if (notificationList) {
            this.approvalNotices = notificationList;
          }
        })
    );
  }

  bindApprovalNoticeList() {
    this.subscriptions.push(
      this.riskanalysisService
        .getApprovalNoticeByBusiness()
        .subscribe((result: any) => {
          if (result) {
            this.approvalNotices = result;
          }
        })
    );
  }

  /**
   * disable new button based upon the rights
   */
  onAdministrateRiskAnalysis() {
    let userRightsId = Rights.ADMINISTRATE_RISK_ANALYSIS;
    let role = BaseServices.UserRole;
    if (role === 'User') {
      this.isAdministrateRiskAnalysis = BaseServices.checkUserRights(
        userRightsId,
        role
      );
    }
  }

  gotoNew() {
    sessionStorage.setItem('CurrentBusinessId', null);
    setTimeout(() => {
      this.router.navigate(['./newrisk'], {
        queryParams: {},
        relativeTo: this.route,
      });
    }, 1000);
  }
  public checkDoneRisk(data: any) {
    if (data.length > 0) {
      return true;
    }
    return false;
  }
  public getMyStyles(data: any) {
    var myStyles;
    if (data.length > 0) {
      let result = [];
      result = data.filter((x: any) => x.Status === Status.Done);
      if (result.length > 0) {
        if (result.length === data.length) {
          myStyles = {
            color: 'green',
          };
          let res: any = myStyles;
          return res;
        } else {
          return;
        }
      }
    }
  }
  sendReportMail() {
    this.loadingMail = true;
    let CultureInfo = 'en-GB';
    let objMailInformation = new MailInformation();
    let Tomail = this.approvalNotices.map((x: any) => x.Id);
    let TomailName = this.approvalNotices.map((x: any) => x.Name);
    objMailInformation.ToMail = Tomail;
    objMailInformation.ToMailName = TomailName;
    objMailInformation.BusinessId = BaseServices.BusinessId;
    let languageMode = sessionStorage.getItem('languageMode');
    if (languageMode != null) {
      objMailInformation.CultureInfo = languageMode;
    }
    this.subscriptions.push(
      this.riskanalysisService
        .sendRiskMail(objMailInformation)
        .subscribe((success) => {
          this.loadingMail = false;
          this.sendMail = false;
          alert(this.translate.instant('MAIL_SUCCESS'));
        })
    );
  }
  getStatusUnApproved(data) {
    if (this.IsSendEmail && data != null) {
      let IsUnApproved = [];
      IsUnApproved = data.filter((x: any) => x.ApprovalStatus == 'Unapproved');
      if (IsUnApproved.length > 0) {
        this.IsSendEmail = true;
      } else {
        this.IsSendEmail = false;
      }
    }
  }

  /**
   * toggle expand icon click
   * @param i {number}
   */
  toggleClickRisk(i: number): void {
    this.rowID = i;
    this.isCollapsed = !this.isCollapsed;
  }
  /**
   * toggle collapse icon click
   */
  toggleClickHideRisk(): void {
    this.rowID = null!;
  }

  checkRisk(data: any) {
    if (data != null) {
      return true;
    }
    return false;
  }
  closePopUp() {
    this.showActionPlan = false;
  }

  ActionPlanDialog(incidentID: number): void {
    this.showActionPlan = true;
    let data = [];
    data = this.riskDetails.filter((x: any) => x.Id == incidentID);
    this.ActionPlanList = [];
    let list: any = [];
    data.forEach((element: any) => {
      if (element.TaskPlanListDto != null) {
        list = element.TaskPlanListDto;
      }
    });
    this.ActionPlanList = list;
  }
  RiskSchemeDialog(incidentID: number) {
    this.showRiskScheme = true;
    this.Topics = [];
    this.selectedRiskId = incidentID;
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        let topics;
        this.Topics = [];
        this.Topics.push({ label: val.SELECT, value: null });
        if (this.roleId === 1) {
          topics = this.route.snapshot.data['adminTopic'];
        } else {
          topics = this.route.snapshot.data['topic'];
        }
        if (topics) {
          topics.forEach((topicsList: any) => {
            this.Topics.push({
              label: topicsList.Name,
              value: topicsList.Id,
            });
          });
        }
      })
    );

    this.departments = [];
    this.editDepartments = [];
    let department;
    this.subscriptions.push(
      this.riskanalysisService
        .getDepartmentsForAdmin(incidentID)
        .subscribe((riskForm) => {
          department = riskForm;
          if (department) {
            this.editDepartments = department;
            this.subscriptions.push(
              this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
                this.departments = [];
                department.forEach((departmentList: any) => {
                  this.departments.push({
                    label: departmentList.Name,
                    value: departmentList.Id,
                  });
                });
              })
            );
          }
        })
    );

    this.subscriptions.push(
      this.riskanalysisService.getById(incidentID).subscribe((riskForm) => {
        this.riskFormData = riskForm;
        let catId: Number = this.riskFormData.CategoryId;
        const resultCat: any = this.Category.find((cat_val) => {
          return cat_val.value == this.riskFormData.CategoryId;
        });
        this.riskForm.get('Title').patchValue(this.riskFormData.Title);
        this.riskForm.get('CategoryId').patchValue(resultCat.label);
        this.riskForm.get('Location').patchValue(this.riskFormData.Location);
        this.riskForm.get('TopicId').patchValue(this.riskFormData.TopicId);
        let departmentList = [];
        let checkDepartmentList = [];
        this.riskFormData.RiskAnalysisDepartment != null
          ? this.riskFormData.RiskAnalysisDepartment.forEach((element) => {
              departmentList.push(element.RiskDepartmentId);
            })
          : this.riskFormData.DepartmentId;
        departmentList.forEach((element) => {
          let checkValueDept = this.departments.filter(
            (x) => x.value == element
          );
          if (checkValueDept.length > 0) {
            checkDepartmentList.push(checkValueDept);
          }
        });
        if (checkDepartmentList.length == 0) {
          departmentList = [];
        }
        this.riskForm.get('DepartmentId').patchValue(departmentList);
        this.riskForm.get('Sorting').patchValue(this.riskFormData.Sorting);
        this.riskForm
          .get('ExpectedBehaviour')
          .patchValue(this.riskFormData.ExpectedBehaviour);
        this.riskForm
          .get('RelatedIncident')
          .patchValue(this.riskFormData.RelatedIncident);
        this.riskForm
          .get('Alreadyactiontaken')
          .patchValue(this.riskFormData.Alreadyactiontaken);
        this.riskForm
          .get('Probability')
          .patchValue(this.riskFormData.Probability);
        this.riskForm
          .get('Consequence')
          .patchValue(this.riskFormData.Consequence);
        this.riskForm
          .get('Participants')
          .patchValue(this.riskFormData.Partcipants);
        let Probability = this.riskFormData.Probability;
        let Consequence = this.riskFormData.Consequence;
        this.setRiskFigure(Probability, Consequence);
      })
    );

    this.subscriptions.push(
      this.riskanalysisService.getById(this.selectedRiskId).subscribe((res) => {
        this.ReportJson = res;
        this.featureId = this.ReportJson.FeatureKey;
      })
    );
  }

  exportPdf() {
    this.loadingReport = true;
    this.fileName = this.translate.instant('RISKANALYSIS_DETAILS');
    let fileType = 'pdf';
    let cultureInfo = sessionStorage.getItem('languageMode');
    this.subscriptions.push(
      this.riskanalysisService
        .riskAnalysisReportGenerate(
          BaseServices.BusinessId,
          this.selectedRiskId,
          cultureInfo,
          this.ReportJson,
          this.featureId,
          BaseServices.UserId,
          false
        )
        .subscribe(
          (blob) => {
            this.loadingReport = false;
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${this.fileName}.${fileType.toLowerCase()}`;
            link.click();
            this.showRiskScheme = false;
          },
          (error) => {
            this.loadingReport = false;
            alert('Export not downloaded');
          }
        )
    );
  }

  sortingDropdown() {
    // Sorting
    this.subscriptions.push(
      this.translate.stream('SELECT_SORTING').subscribe((val) => {
        this.sorting = [];
        this.sorting.push({ label: val.SELECT, value: null });
        this.sorting.push({ label: val.PERSON, value: val.PERSON });
        this.sorting.push({
          label: val.ENVIRONMENTAL,
          value: val.ENVIRONMENTAL,
        });
        this.sorting.push({ label: val.MATERIAL, value: val.MATERIAL });
      })
    );
  }

  /**
   * to filter status in list
   * @param e {event}
   * @param statusDropdown {any}
   */
  onRiskChanged(e: any, statusDropdown: any) {
    if (e.value) {
      // custom filter datatable
      this.td.filter(
        statusDropdown.selectedOption.value,
        'ValueText',
        'contains'
      );
    } else {
      this.dt.reset();
    }
  }

  toggleStaticData() {
    this.showStaticData = !this.showStaticData;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}

//#endregion


import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './kuba.services';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class KubaGeneralService {

    constructor(private http: HttpClient) { }

    // Get overall business list for dropdown binding
    getBusinessList() {
        return this.http.get(environment.BASE_URL + '/businessnamelist', )
            .pipe(map((result: any) => result));
    }

    getAdminBusinessList() {
        return this.http.get(environment.BASE_URL + '/manuals/businessnamelist', )
            .pipe(map((result: any) => result));
    }

    // Get overall portal admin list for dropdown binding
    getPortalList() {
        return this.http.get(environment.BASE_URL + '/portalnamelist', )
            .pipe(map((result: any) => result));
    }
}


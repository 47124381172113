import { DeviationServices } from './../../deviation/services/deviation.service';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from 'src/app/_services/helper.service';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { environment } from 'src/environments/environment';
import { ConfirmationService, SelectItem } from 'primeng/api';

import { RiskanalysisService } from 'src/app/kuba/follow-ups/services/risk-analysis.service';
import { Issue, SafetyJobListedit } from '../models';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { RiskAnalysisAttachment } from './../models/safetyJobListEdit';
import { JobAnalysisService } from './../services/jobanalysis.services';
import { TranslateService } from '@ngx-translate/core';
import { Rights } from 'src/app/_models';
import { Topic } from './../models/topic';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { Subscription } from 'rxjs';
@Component({
    selector: 'safety-job',
    templateUrl: 'safety-job-form.component.html'
})
export class FormComponent implements OnInit {
    //#region variables
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    safetyjob: any;
    newSafetyJobAnalysis: FormGroup;
    safetyIssueForm: FormGroup;
    Issue: SelectItem[];
    Topic: SelectItem[];
    issuetype: any;
    isLocation = false;
    isWhatCanHappen = false;
    addType = false;
    editTypes:any = [];
    selectedType = new Issue();
    loading = false;
    parentId: number;
    safetyJobId: number;
    hidingElement = false;
    issueId: number;
    upConfig: UploaderConfig;
    uploadedFiles: any = [];
    additionalData: any;
    newDocumentAdded: any = [];
    parentKey: any;
    isTopicExists = false;
    isHideButton = true;
    isICSJA = false;
    isFS = false;
    addTopic = false;
    topicForm: FormGroup;
    isCaseExists = false;
    topicId: number
    addIssue = false;
    editTopics = [];
    selectedTopic = new Topic();
    istopicfunctionExists = false;
    topicFunctionExisting: string;

    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor
    /**
     * constructor
     * @param formBuilder {{FormBuilder}}
     * @param route {{ActivatedRoute}}
     * @param router {{Router}}
     * @param safetyjobservice {{JobAnalysisService}}
     * @param _location {{Location}}
     * @param confirmationService {{ConfirmationService}}
     */
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private safetyjobservice: JobAnalysisService,
        private _location: Location,
        private confirmationService: ConfirmationService,
        private riskanalysisService: RiskanalysisService,
        private translate: TranslateService,
        private deviationService: DeviationServices,
        private deviationservice: DeviationServices,
    ) {
        this.parentId = route.snapshot.parent!.parent!.params['id'];
        this.parentKey = route.snapshot.parent!.parent!.data['parent'];
        this.newSafetyJobAnalysis = this.formBuilder.group({
            TypeofIssue: ['', Validators.required],
            Topics: ['', Validators.required],
            Description: [''],
            Location: ['']
        });
        if (this.parentKey === "IC"|| this.parentKey === 'OS' || this.parentKey === 'QS') {
            this.isICSJA = true;
        } else if (this.parentKey === "FS") {
            this.isFS = true;
        }
    }
    //#endregion

    //#region page-events
    /**
     * bind dropdown
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        if (BaseServices.UserRole === 'User') {
            this.isHideButton = this.hideTypeOfcase(this.parentKey);
        } else if (BaseServices.UserRole !== 'Editor') {
            this.isHideButton = false;
        }
        switch (this.parentKey) {
            case 'PROJECT': this.isLocation = false;
                this.isWhatCanHappen = true;
                break;
            case 'FDV':
                this.isLocation = true;
                this.isWhatCanHappen = false;
                break;
            case 'IC':
                this.isLocation = true;
                this.isWhatCanHappen = false;
                break;
            case 'OS':
                this.isLocation = true;
                this.isWhatCanHappen = false;
                break;
            case 'QS':
                this.isLocation = true;
                this.isWhatCanHappen = false;
                break;
            case 'FS':
                this.isLocation = true;
                this.isWhatCanHappen = false;
                break;
        }
        this.topicForm = this.formBuilder.group({
            Name: ['', Validators.required]
            
        });
        this.safetyIssueForm = this.formBuilder.group({
            Name: ['', Validators.required]
        });


        this.bindIssueDropdown();
        this.bindTopicDropDown();
        this.additionalData = {
            ApplicationId: BaseServices.ApplicationId,
            BusinessId: BaseServices.BusinessId,
            RiskAnalysisId: 0,
            Status: '1',
            CreatedOn: new Date(),
            ModifiedBy: BaseServices.UserId,
            ModifiedOn: new Date(),
            ModuleName: 'RiskAssessment'
        };
        this.upConfig = {
            title: this.translate.instant('DOCUMENTS'),
            titleAsLabel: true,
            editMode: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showSearchFilter: false,
            showUploadButton: true,
            showDeleteButton: true,
            uploaderUri:environment.BASE_URL +
                        '/file/upload/business/project/risk-analysis-docs',     
            addtionalData: this.additionalData
        };
    }
    //#endregion

    //#region control-events
    /**
     * hide event
     */
    onIssuekDialogHide() {
        this.safetyIssueForm.reset();
        this.issueId = 0;
    }
    //#endregion

    //#region methods
    /**
     * bind case dropdown
     */
    bindIssueDropdown() {
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.Issue = [];
            this.Issue.push(
                { label: val.SELECT, value: null }
            );
            this.editTypes = [];
            let issueType = this.route.snapshot.data['issue'];
            if (issueType) {
                issueType.forEach((element:any) => {
                    this.Issue.push({ label: element.Name, value: element.Id });
                    this.editTypes.push({ Name: element.Name, Id: element.Id });
                });
            }
        }));
    }

    bindTopicDropDown() {
        this.subscriptions.push(this.deviationservice.getAllTopics(BaseServices.BusinessId).subscribe((topic:any) => {
            if (topic) {
                this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe((val:any) => {
                    this.Topic = [];
                    this.Topic.push(
                        { label: val.SELECT, value: null }
                    );
                    topic.forEach((element: any) => {
                        this.Topic.push({ label: element.Name, value: element.Id });
                    });
                    this.editTopics = topic;
                }));

            }
        }));
    }

    /**
     * save uploaded file
     */
    saveUploaded(event: any) {
        if (event) {
            let eventType = event.eventName;
            let keyBindings = {
                Originalname: 'OriginalFileName',
                FileTypeId: 'FileType'
            };
            let deviationDoc = Object.assign({}, event, this.additionalData);
            switch (eventType) {
                case 'DeleteDefaultFile':
                    if (event.item.data.id === 0) {
                        // remove unsaved attachment
                        this.newDocumentAdded = this.newDocumentAdded.filter(
                            (x:any) => x.Path !== event.item.data.path
                        );
                        this.uploadedFiles = this.uploadedFiles.filter(
                            (x:any) => x.path !== event.item.data.path
                        );
                        this.toasterComponent.callToastDlt();
                    } else {
                        // remove existing attachement
                        this.subscriptions.push(this.riskanalysisService
                            .deleteRiskAttachment(event.item.data.id)
                            .subscribe((result: any) => {
                                if (result) {
                                    // remove from display list
                                    this.uploadedFiles = this.uploadedFiles.filter(
                                        (x:any) => x.path !== event.item.data.path
                                    );
                                    this.toasterComponent.callToastDlt();
                                }
                            }));
                    }
                    break;
                default:
                    deviationDoc.id = 0;
                    deviationDoc.createdBy = BaseServices.UserId;
                    deviationDoc.status = 1;
                    this.newDocumentAdded.push(
                        HelperService.switchKeysCase(deviationDoc, keyBindings)
                    );
                    break;
            }
        }

    }
    /**
     * save safety job analysis
     */
    saveChanges() {
        this.loading = true;
        if (this.newSafetyJobAnalysis.valid) {
            let safetyjob = new SafetyJobListedit();
            safetyjob.FeatureKey = this.parentId;
            safetyjob.Status = '7';
            safetyjob.IssueTypeId = this.newSafetyJobAnalysis.value.TypeofIssue;
            safetyjob.TopicId = this.newSafetyJobAnalysis.value.Topics;
            safetyjob.Location = this.newSafetyJobAnalysis.value.Location;
            safetyjob.ExpectedBehaviour = this.newSafetyJobAnalysis.value.Description;
            safetyjob.RelatedIncident = this.newSafetyJobAnalysis.value.Description;
            safetyjob.CreatedBy = BaseServices.UserId;
            safetyjob.CreatorName = BaseServices.userName;
            safetyjob.BusinessId = BaseServices.BusinessId;
            safetyjob.ApplicationId = BaseServices.ApplicationId;
            safetyjob.RiskAnalysisAttachment = this.newDocumentAdded;
            safetyjob.CreatedOn = new Date();
            this.subscriptions.push(this.safetyjobservice
                .create(
                    safetyjob,
                    this.route.snapshot.parent!.parent!.data['parent']
                )
                .subscribe((result:any) => {
                    this.safetyJobId = result.Id;
                    this.toasterComponent.callToast();
                    this.loading = false;
                    this.router.navigate(['../list', result], {
                        relativeTo: this.route
                    });
                }));
        }
    }

    saveAndOpen() {
        if (this.newSafetyJobAnalysis.valid) {
            let safetyjob = new SafetyJobListedit();
            safetyjob.FeatureKey = this.parentId;
            safetyjob.Status = '7';
            safetyjob.IssueTypeId = this.newSafetyJobAnalysis.value.TypeofIssue;
            safetyjob.TopicId = this.newSafetyJobAnalysis.value.Topics;
            safetyjob.Location = this.newSafetyJobAnalysis.value.Location;
            safetyjob.ExpectedBehaviour = this.newSafetyJobAnalysis.value.Description;
            safetyjob.CreatedBy = BaseServices.UserId;
            safetyjob.CreatorName = BaseServices.userName;
            safetyjob.BusinessId = BaseServices.BusinessId;
            safetyjob.RiskAnalysisAttachment = this.newDocumentAdded;
            safetyjob.CreatedOn = new Date();
            this.subscriptions.push(this.safetyjobservice
                .create(
                    safetyjob,
                    this.route.snapshot.parent!.parent!.data['parent']
                )
                .subscribe((result: any) => {
                    this.safetyJobId = result.Id;
                    this.toasterComponent.callToast();
                    this.router.navigate(['../edit', this.safetyJobId], {
                        relativeTo: this.route
                    });
                }));
        }
    }
    /**
     * list all casetype
     */
    viewIssue() {
        this.subscriptions.push(this.safetyjobservice.getAllIssues().subscribe((result: any) => {
            this.editTypes = result;
            if (result) {
                this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                    this.Issue = [];
                    this.Issue.push(
                        { label: val.SELECT, value: null }
                    );
                    if (result) {
                        result.forEach((element:any) => {
                            this.Issue.push({
                                label: element.Name,
                                value: element.Id
                            });
                        });
                    }
                }));
            }
        }));
    }

    /**
     *  get case type value for edit in field
     */

    editIssue(issuetype: Issue) {
        this.issueId = issuetype.Id;
        let result = {
            Name: issuetype.Name
        };
        (<FormGroup>this.safetyIssueForm).setValue(result, { onlySelf: true });
    }

    /**
     * save and update type of case dropdown
     */
    saveIssue() {
        this.loading = true;
        let issuetype = new Issue();
        issuetype.Id = this.issueId ? this.issueId : 0;
        issuetype.Name = this.safetyIssueForm.value.Name;
        issuetype.Status = '1';
        issuetype.BusinessId = BaseServices.BusinessId;
        issuetype.ApplicationId = BaseServices.ApplicationId;
        if (issuetype.Id > 0) {
            this.subscriptions.push(this.safetyjobservice
                .updateIssue(issuetype)
                .subscribe((issueTypeUpdated: any) => {
                    if (issueTypeUpdated) {
                        this.viewIssue();
                        this.toasterComponent.callToast();
                        this.loading = false;
                        this.newSafetyJobAnalysis
                            .get('TypeofIssue')!
                            .patchValue(issueTypeUpdated.Id);
                        this.addType = false;
                        this.issueId = 0;
                    } else {
                        this.isTopicExists = true;
                        this.loading = false;
                        setTimeout(
                             () => {
                                this.isTopicExists = false;
                            },
                            3000
                        );
                    }
                }));
        } else {
            this.isTopicExists = false;
            this.subscriptions.push(this.safetyjobservice.createIssue(issuetype).subscribe((caseType:any) => {
                if (caseType) {
                    this.viewIssue();
                    this.loading = false;
                    this.toasterComponent.callToast();
                    this.newSafetyJobAnalysis
                        .get('TypeofIssue')!
                        .patchValue(caseType.Id);
                    this.addType = false;
                } else {
                    this.isTopicExists = true;
                    this.loading = false;
                    setTimeout(
                         () => {
                            this.isTopicExists = false;
                        },
                        3000
                    );
                }
            }));
            this.safetyIssueForm.reset();
            this.clear();
        }
    }
    clear() {
        this.selectedType.Id = 0;
        this.selectedType.Name = '';
    }

    /**
     *  delete case type dropdown
     */
    deleteIssue(selectedType:any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.safetyjobservice
                    .deleteIssue(selectedType)
                    .subscribe(result => {
                        this.viewIssue();
                        this.toasterComponent.callToastDlt();
                    }));
            }
        });
    }
    /**
     * go to list
     */
    gotoBack() {
        this._location.back();
    }
    deleteApprovalNotice() {
        if (this.safetyJobId) {
            this.subscriptions.push(this.riskanalysisService
                .getApprovalNoticeByRisk(this.safetyJobId)
                .subscribe((result:any) => {
                    if (result) {
                        this.subscriptions.push(this.riskanalysisService
                            .deleteApprovalNotice(result[0].Id)
                            .subscribe(result => { }));
                    }
                }));
        }
    }
    //#endregion
    /**
        * 
        * @param featureName
        */
    hideTypeOfcase(featureName: string): boolean {
        if (featureName === 'PROJECT') {
            return this.isUserRights(Rights.ADMINISTRATE_PROJECTS);
        } else if (featureName === 'FDV') {
            return this.isUserRights(Rights.ADMINISTRATE_FDV);
        } else if (featureName === 'IC') {
            return this.isUserRights(Rights.ADMINISTRATE_INTERNAL_CONTROL);
        } else if (featureName === 'OS') {
            return this.isUserRights(Rights.ADMINISTRATE_OTHER_SYSTEMS);
        } else if (featureName === 'QS') {
            return this.isUserRights(Rights.ADMINISTRATE_QUALITY_SYSTEM);
        } else if (featureName === 'VEHICLE') {
            return false;
        } else if (featureName === 'FS') {
            return false;
        } else if (featureName === 'KUNDE_EL') {
            return this.isUserRights(Rights.IK_KUNDE_EL_RIGHTS);
        } else if (featureName === 'FRAMEWORK') {
            return this.isUserRights(Rights.FRAMEWORK_AGREEMENT_RIGHTS);
        } else if (featureName === 'SA') {
            return this.isUserRights(Rights.SERVICE_AGREEMENT_RIGHTS);
        }
        return true;
    }

    isUserRights(rightsId: number): boolean {
        return BaseServices.checkUserRights(
            +rightsId,
            BaseServices.UserRole
        );
    }

    /**
       * list all topics
       */
    viewTopic() {
        this.subscriptions.push(this.safetyjobservice.getTopicList(BaseServices.BusinessId).subscribe((result: any) => {
            this.editTopics = result;
            if (result) {
                this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                    this.Topic = [];
                    this.Topic.push(
                        { label: val.SELECT, value: null }
                    );
                }));
                if (result) {
                    result.forEach((element:any) => {
                        this.Topic.push({
                            label: element.Name,
                            value: element.Id
                        });
                    });
                }
            }
        }));
    }


    onTopicFormHide() {
        this.topicForm.reset();
    }

    /**
     * save topic
     */
    saveTopic() {
        this.loading = true;
        let topic = new Topic();
        topic.Id = this.topicId ? this.topicId : 0;
        topic.Name = this.topicForm.value.Name;
        topic.Status = '1';
        topic.BusinessId = BaseServices.BusinessId;
        if (topic.Id > 0) {
            this.subscriptions.push(this.safetyjobservice.updateTopic(topic).subscribe((topicEdited:any) => {
                if (topicEdited) {
                    this.toasterComponent.callToast();
                    this.addTopic = false;
                    this.viewTopic();
                    this.loading = false;
                    this.newSafetyJobAnalysis
                        .get('Topics')!
                        .patchValue(topicEdited.Id);
                    this.topicId = 0;
                } else {
                    this.isCaseExists = true;
                    this.loading = false;
                    setTimeout(
                         () => {
                            this.isCaseExists = false;
                        },
                        3000
                    );
                }
            }));
        } else {
            this.isCaseExists = false;
            this.subscriptions.push(this.safetyjobservice.createTopic(topic).subscribe((caseType:any) => {
                if (caseType) {
                    this.toasterComponent.callToast();
                    this.addTopic = false;
                    this.viewTopic();
                    this.loading = false;
                    this.newSafetyJobAnalysis.get('Topics')!.patchValue(caseType.Id);
                } else {
                    this.isCaseExists = true;
                    this.loading = false;
                    setTimeout(
                         () => {
                            this.isCaseExists = false;
                        },
                        3000
                    );
                }
            }));
            this.topicForm.reset();
            this.clear();
        }
    }

    clearTopic() {
        this.selectedTopic.Name = '';
        this.selectedTopic.Id = 0;
    }

    /**
    * edit topic
    * @param topic {{any}}
    */
    editTopic(topic: Topic) {
        this.topicId = topic.Id;
        let result = {
            Name: topic.Name
        };
        (<FormGroup>this.topicForm).setValue(result, { onlySelf: true });
    }

    /**
    * delete topic
    * @param selectedTopic {{any}}
    */
    deleteTopic(selectedTopic:any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.safetyjobservice
                    .deleteTopic(selectedTopic)
                    .subscribe(result => {
                        this.viewTopic();
                        this.toasterComponent.callToastDlt();
                    }));
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}

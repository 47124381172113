import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';
import { Component, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PaginationInstance } from 'ngx-pagination';
import * as _ from 'lodash';
import { SelectItem } from 'primeng/api';

import { BaseServices } from 'src/app/kuba/kuba.services';
import { HelperService } from '../../../_services/helper.service'
import { KubaBulkReportService } from '../../../shared/ecpl-document-explorer/file-window.service';
import { FeatureManualService } from '../../feature-documents/feature-document.services';
import { UploaderConfig } from '../../../shared/ecpl-document-explorer/uploader-config';
import { ElCheckListServices } from 'src/app/kuba/el-checklist/services/elChecklist.services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs/Rx';

// TODO: to be worked
@Component({
    templateUrl: 'elchecklistList.component.html'
})
export class ElCheckListComponent implements OnInit, OnChanges {
    featureId: number;
    featureKey: number;
    treeNode: any;
    uploadedFiles: any;
    upConfig: UploaderConfig;
    nodeId: any;
    isEditMode = false;
    businessId: number;
    portalId: number;
    pgConfig: PaginationInstance = {
        itemsPerPage: 5,
        currentPage: 1
    };
    pageSizeList: SelectItem[] = [
        { label: 'All', value: 0 },
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '50', value: 50 },
    ];
    selectedAll: any;
    private subscriptions: Subscription[] = [];
    public selectedPageSize = this.pgConfig.itemsPerPage;
    constructor(
        private route: ActivatedRoute,
        private featureManualService: FeatureManualService,
        private bulkReportService: KubaBulkReportService,
        public translate: TranslateService
    ) {
        this.featureKey = route.snapshot.params['Id'];
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        this.featureId = workingModule.id;
        this.upConfig = {
            title: 'All Files',
            viewSwitch: false,
            editMode: false,
            windowedHeight: true,
            showUserMeta: true,
            uploaderUri: environment.BASE_URL + '/file/upload/business/manual',
            addtionalData: null,
            showDownloadAll: true
        };
        this.businessId = BaseServices.BusinessId;
        this.portalId = +BaseServices.PortalId;
    }

    ngOnInit() {
        this.BindData()
    }
    ngOnChanges() {
        this.selectedPageSize = 5;
        this.pgConfig.itemsPerPage = 5;
    }

    BindData() {
        this.subscriptions.push(this.featureManualService
            .getFeatureManualTree(this.featureKey)
            .subscribe(
                (result: any) => {
                    this.treeNode = result;
                    let flattenned = this.flattenArrObj(this.treeNode);
                    const parent = _.filter(flattenned, [
                        'documentType',
                        'checklist'
                    ]);
                    this.uploadedFiles = parent[0].nodes;
                    this.upConfig.title = parent[0].filename;
                },
                (error: any) => {
                    console.error('Api Error while refreshing tree:', error);
                },
                () => {
                    console.log('Refreshing tree service complete.');
                }
            ));
    }

    saveEmitter(eventData) {
        switch (eventData.eventName) {

        }
    }

    // private
    private flattenArrObj(arr) {
        let newArr = [];
        if (Array.isArray(arr) && arr.length > 0) {
            arr.forEach(element => {
                newArr.push(element);
                if (Array.isArray(element.nodes) && element.nodes.length > 0) {
                    newArr = newArr.concat(this.flattenArrObj(element.nodes));
                }
            });
        }
        return newArr;
    }

    onPageChanged(e: any) {
        if (this.selectedPageSize === 0) {
            this.pgConfig.itemsPerPage = this.uploadedFiles.length;
            this.pgConfig.currentPage = 1;
        } else {
            this.pgConfig.itemsPerPage = this.selectedPageSize;
        }
    }

    // Checkbox selection
    SelectAllDocuments() {
        for (let i = 0; i < this.uploadedFiles.length; i++) {
            this.uploadedFiles[i].selected = this.selectedAll;
        }
    }
    checkIfAllSelected() {
        this.selectedAll = this.uploadedFiles.every(function (item: any) {
            return item.selected === true;
        })
    }

    // Documents download as zip
    DocumentsDownloadAll() {
        let selectedDoc: any = [];
        let BusinessId = BaseServices.BusinessId;
        let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
        let languageId = appSettings.Language.LanguageId;
        let CultureInfo = 'en-GB';
        switch (languageId) {
            case 1:
                CultureInfo = 'en-GB';
                break;
            case 2:
                CultureInfo = 'no-NO';
                break;
            case 3:
                CultureInfo = 'sv-SE';
                break;
            case 4:
                CultureInfo = 'pl-PL';
                break;
        }
        for (let i = 0; i < this.uploadedFiles.length; i++) {
            let selectStatus = this.uploadedFiles[i].selected;

            if (selectStatus !== undefined && selectStatus === true) {
                let requestObject = {
                    'FilePath': this.uploadedFiles[i].path,
                    'FileName': this.uploadedFiles[i].filename,
                    'FileType': this.uploadedFiles[i].documentType.toLowerCase(),
                    'FileContent': (this.uploadedFiles[i].documentType === 'checklist') ? this.uploadedFiles[i].content.checkList
                        : this.uploadedFiles[i].content,
                    'BusinessId': BusinessId,
                    'CultureInfo': CultureInfo
                };
                selectedDoc.push(HelperService.switchKeysCase(requestObject, null, 'U'));
            }
        }
        if (selectedDoc.length === 0) {
            alert(this.translate.instant("NO_FILE_SELECTED"));
        } else {
            let reportname = 'Download all.zip';
            this.subscriptions.push(this.bulkReportService
                .downloadAllAsZip(selectedDoc, this.businessId, this.portalId, this.featureKey)
                .subscribe(
                    blob => {
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `${reportname}`;
                        link.click();
                    },
                    error => {
                        alert('Please try again');
                    }));
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}

import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class LicenseAgreementServices {
    _business: Observable<any> = null;
    constructor(private http: HttpClient) { }

    getBusinessLicenseAgreementList() {
        return this.http.get<any>(environment.BASE_URL + '/businesses/liceseagreement', )
            .map(result => result);
    }
    getBusinessFeatureLicenseAgreementList() {
        return this.http.get<any>(environment.BASE_URL + '/business/features/' + BaseServices.ApplicationId, )
            .map(result => result);
    }

}

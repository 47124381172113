import { BaseServices } from './../../kuba.services';
import { Toast } from 'angular2-toaster';
import { KubaGeneralService } from './../../kuba-general.services';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ManualView, ManualEdit } from './../models/index';
import { ManualService } from './../services/manual.services';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
@Component({
  selector: 'manual-form',
  templateUrl: 'manual-form.component.html',
})
export class ManualFormComponent implements OnInit {
  @Input() manualType: string;
  @Input() manual = new ManualView();
  @Output() onClose = new EventEmitter<any>();
  allManualList: ManualView[];
  createManualForm: FormGroup;
  status: SelectItem[];
  chooseBusiness: SelectItem[];
  choosePortal: SelectItem[];
  showUpload = false;
  Cancel = 'Cancel';
  SaveClose = 'Save & Close';
  SaveProceed = 'Save & Proceed';
  uploadStartForCoverImage: boolean;
  public selectedFiles: any;
  coverImage: string;
  loading: boolean;
  hideSaveandcontinue = false;
  showSaveAndClose = true;
  private subscriptions: Subscription[] = [];
  constructor(
    private kubaGeneralService: KubaGeneralService,
    private formBuilder: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private manualService: ManualService,
    private http: HttpClient,
    private translate: TranslateService
  ) {
    this.subscriptions.push(
      this.translate.stream('SELECT_STATUS_DROP').subscribe((val) => {
        this.status = [];
        this.status.push(
          { label: val.SELECT, value: null },
          { label: val.INACTIVE, value: 2 },
          { label: val.ACTIVE, value: 1 }
        );
      })
    );
    this.createManualForm = this.formBuilder.group({
      title: [
        '',
        Validators.compose([Validators.required, this.noWhitespaceValidator]),
      ],
      description: [''],
      status: [1, Validators.required],
      business: [null],
      portal: [null],
      coverImg: [''],
    });
  }

  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    if (this.manualType && this.allManualList) {
      switch (this.manualType) {
        case 'portal':
          this.createManualForm = this.formBuilder.group({
            title: [
              '',
              Validators.compose([
                Validators.required,
                this.noWhitespaceValidator,
              ]),
            ],
            description: [''],
            status: [1, Validators.required],
            business: [null],
            portal: [null, Validators.required],
            coverImg: [''],
          });
          this.createManualForm
            .get('portal')!
            .setValidators(Validators.required);
          this.createManualForm.get('portal')!.updateValueAndValidity();
          break;
        case 'business':
          this.createManualForm = this.formBuilder.group({
            title: [
              '',
              Validators.compose([
                Validators.required,
                this.noWhitespaceValidator,
              ]),
            ],
            description: [''],
            status: [1, Validators.required],
            business: [null, Validators.required],
            portal: [null],
            coverImg: [''],
          });
          this.createManualForm
            .get('business')!
            .setValidators(Validators.required);
          this.createManualForm.get('business')!.updateValueAndValidity();
          break;
      }
    }

    if (this.manualType === 'business') {
      this.createManualForm = this.formBuilder.group({
        title: [
          '',
          Validators.compose([Validators.required, this.noWhitespaceValidator]),
        ],
        description: [''],
        status: [1, Validators.required],
        business: [null, Validators.required],
        portal: [null],
        coverImg: [''],
      });
    } else if (this.manualType === 'portal') {
      this.createManualForm = this.formBuilder.group({
        title: [
          '',
          Validators.compose([Validators.required, this.noWhitespaceValidator]),
        ],
        description: [''],
        status: [1, Validators.required],
        business: [null],
        portal: [null, Validators.required],
        coverImg: [''],
      });
    } else {
      this.createManualForm = this.formBuilder.group({
        title: [
          '',
          Validators.compose([Validators.required, this.noWhitespaceValidator]),
        ],
        description: [''],
        status: [1, Validators.required],
        business: [null],
        portal: [null],
        coverImg: [''],
      });
    }

    this.chooseBusiness = [];

    this.subscriptions.push(
      this.kubaGeneralService.getBusinessList().subscribe((result: any) => {
        this.subscriptions.push(
          this.translate.stream('SELECT_BUSINESS_DROP').subscribe((val) => {
            this.chooseBusiness.push({
              label: val.SELECT_BUSINESS,
              value: null,
            });
          })
        );
        result.forEach((element) => {
          this.chooseBusiness.push({
            label: element.Name,
            value: element.Id,
          });
        });
      })
    );

    this.choosePortal = [];
    this.subscriptions.push(
      this.kubaGeneralService.getPortalList().subscribe((result: any) => {
        this.subscriptions.push(
          this.translate.stream('SELECT_PORTAL_DROP').subscribe((val) => {
            this.choosePortal.push({ label: val.SELECT_PORTAL, value: null });
          })
        );
        result.forEach((element) => {
          this.choosePortal.push({
            label: element.Name,
            value: element.Id,
          });
        });
      })
    );

    if (this.manual.Id !== 0) {
      this.showUpload = true;
      let manualEdit = {
        title: this.manual.Title,
        description: this.manual.Description,
        status: this.manual.Status,
        business: this.manual.LevelId === 3 ? this.manual.LevelKeyId : null,
        portal: this.manual.LevelId === 2 ? this.manual.LevelKeyId : null,
        coverImg: this.manual.CoverImg,
      };
      this.coverImage = this.manual.CoverImg;
      (<FormGroup>this.createManualForm).setValue(manualEdit, {
        onlySelf: true,
      });
    }
  }

  cancelClose() {
    this.onClose.emit(this.closePopUp(false));
  }

  saveManual(proceed?: boolean) {
    this.subscriptions.push(
      this.manualService
        .getAllManuallist(BaseServices.ApplicationId)
        .subscribe((result) => {
          if (result) {
            this.allManualList = result;
            let manualList = this.allManualList;
            let existingData = manualList.filter(
              (x) => x.Title === this.createManualForm.value.title
            );

            let existingTitle = this.manual.Title;
            let newTitle = this.createManualForm.value.title;
            let manualId = this.manual.Id;
            if (existingData.length > 0 && manualId === 0) {
              alert(this.translate.instant('MANUAL_NAME_ALREADY_EXIST'));
            } else if (existingTitle !== newTitle && existingData.length > 0) {
              alert(this.translate.instant('MANUAL_NAME_ALREADY_EXIST'));
            } else {
              let manualSave = this.createManualForm.value;
              if (manualSave.business) {
                this.subscriptions.push(
                  this.manualService
                    .getBusinessFeatureManual(manualSave.business)
                    .subscribe((result) => {
                      if (result) {
                        if (result === 'false') {
                          alert('Manual feature was not enabled');
                        } else {
                          this.saveManualDetails(proceed);
                        }
                      }
                    })
                );
              } else if (manualSave.portal) {
                this.subscriptions.push(
                  this.manualService
                    .getPortalFeatureManual(manualSave.portal)
                    .subscribe((result) => {
                      if (result) {
                        if (result === 'false') {
                          alert('Manual feature was not enabled');
                        } else {
                          this.saveManualDetails(proceed);
                        }
                      }
                    })
                );
              } else {
                this.saveManualDetails(proceed);
              }
            }
          }
        })
    );
  }

  saveManualDetails(proceed?: boolean) {
    let manualEdit = new ManualEdit();
    let manualSave = this.createManualForm.value;
    manualEdit.Id = this.manual.Id;
    manualEdit.CreatedOn = this.manual.CreatedOn
      ? this.manual.CreatedOn
      : null!;
    manualEdit.ModifiedOn = this.manual.ModifiedOn
      ? this.manual.ModifiedOn
      : null!;
    manualEdit.CreatedBy = this.manual.CreatedBy
      ? this.manual.CreatedBy
      : null!;
    manualEdit.ModifiedBy = this.manual.ModifiedBy
      ? this.manual.ModifiedBy
      : null!;
    manualEdit.Title = manualSave.title;
    manualEdit.Description = manualSave.description;
    manualEdit.Status = manualSave.status;
    manualEdit.CoverImg = this.coverImage;
    manualEdit.AppManualId = this.manual.AppManualId;
    if (manualSave.business) {
      manualEdit.LevelId = 3;
      manualEdit.LevelKey = manualSave.business;
    } else if (manualSave.portal) {
      manualEdit.LevelId = 2;
      manualEdit.LevelKey = manualSave.portal;
    } else {
      manualEdit.LevelId = 1;
      manualEdit.LevelKey = BaseServices.ApplicationId;
    }

    if (manualEdit.Id !== 0) {
      manualEdit.ModifiedBy = BaseServices.UserId;

      if (manualSave.business) {
        this.subscriptions.push(
          this.manualService
            .updateBusinessManual(manualEdit)
            .subscribe((result: any) => {
              if (result) {
                manualEdit.Id = result.Id;
                this.onClose.emit(this.closePopUp(true, manualEdit, proceed));
              }
            })
        );
      } else if (manualSave.portal) {
        this.subscriptions.push(
          this.manualService
            .updatePortalManual(manualEdit)
            .subscribe((result: any) => {
              if (result) {
                manualEdit.Id = result.Id;
                this.onClose.emit(this.closePopUp(true, manualEdit, proceed));
              }
            })
        );
      } else {
        this.subscriptions.push(
          this.manualService
            .updateCommonManual(manualEdit)
            .subscribe((result: any) => {
              if (result) {
                manualEdit.Id = result.Id;
                this.onClose.emit(this.closePopUp(true, manualEdit, proceed));
              }
            })
        );
      }
    } else {
      manualEdit.CreatedBy = BaseServices.UserId;
      if (manualSave.business) {
        manualEdit.AppManualId = 0;
        manualEdit.ApplicationId = BaseServices.ApplicationId;
        this.subscriptions.push(
          this.manualService
            .addBusinessManual(manualEdit)
            .subscribe((result: any) => {
              if (result) {
                manualEdit.Id = result.Id;
                manualEdit.LevelId = 1;
                manualEdit.LevelKey = 1;
                this.onClose.emit(this.closePopUp(true, manualEdit, proceed));
              }
            })
        );
      } else if (manualSave.portal) {
        manualEdit.AppManualId = 0;
        manualEdit.ApplicationId = BaseServices.ApplicationId;
        this.subscriptions.push(
          this.manualService
            .addPortalManual(manualEdit)
            .subscribe((result: any) => {
              if (result) {
                manualEdit.Id = result.Id;
                manualEdit.LevelId = 1;
                manualEdit.LevelKey = 1;
                this.onClose.emit(this.closePopUp(true, manualEdit, proceed));
              }
            })
        );
      } else {
        manualEdit.AppManualId = 0;
        manualEdit.ApplicationId = BaseServices.ApplicationId;
        this.subscriptions.push(
          this.manualService
            .addCommonManual(manualEdit)
            .subscribe((result: any) => {
              if (result) {
                manualEdit.Id = result.Id;
                manualEdit.LevelId = 1;
                manualEdit.LevelKey = 1;
                this.onClose.emit(this.closePopUp(true, manualEdit, proceed));
              }
            })
        );
      }
    }
  }

  onUpload(event: any) {
    // file upload
  }

  popToastMessage(manualEdit: ManualEdit): Toast {
    if (manualEdit) {
      let mode = this.manual.Id > 0 ? 'updated' : 'created';
      let body = `${manualEdit.Title} manual ${mode} successfully`;
      let toast: Toast = {
        type: 'success',
        title: 'Add/Update Manual',
        body: body,
      };
      return toast;
    }
    return null!;
  }

  closePopUp(saved: boolean, manualEdit?: ManualEdit, proceed?: boolean) {
    let returnObj = {
      isSaved: saved,
      isProceed: proceed,
      toast: this.popToastMessage(manualEdit!),
      manual: manualEdit,
    };
    return returnObj;
  }

  /**
   * upload for manual cover image
   * @param selectedFiles
   */
  filesSelectForCoverImage(selectedFiles: any) {
    this.showSaveAndClose = false;
    this.uploadStartForCoverImage = true;
    this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(
      (file) => file
    );
    let formData = new FormData();
    formData.append('file', this.selectedFiles[0]);

    this.subscriptions.push(
      this.http
        .post(
          environment.BASE_URL +
            '/file/upload/manual/coverImage/' +
            BaseServices.ApplicationId +
            '/' +
            this.manual.Id,
          formData
        )
        .map((response: any) => {
          if (response) {
            this.uploadStartForCoverImage = false;
            let res = response;
            this.coverImage = res.path;
            this.loading = false;
          }
        })
        .subscribe(
          (result) => {
            this.showSaveAndClose = true;
          },
          (error) => console.log('Upload manual cover image Error : ', error),
          () => console.log('Upload manual cover image Complete')
        )
    );
  }

  public noWhitespaceValidator(control: FormGroup) {
    let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}

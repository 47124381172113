import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

import { BaseServices } from './../../kuba.services';
import { TypeOfWork } from './../../time-table/models/timetable';
import {
  BusinessDepartment,
  Employee,
  EmployeeDocument,
  EmployeePosition,
  EmployeeType,
} from './../models/employee';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class EmployeeServices {
  item: Object;
  ses: string;
  constructor(private http: HttpClient) {}

  getContractorContact(Id: any) {
    return this.http
      .get(
        environment.BASE_URL + '/contractorcontacts/' + Id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  getAllFunctions() {
    return this.http
      .get(
        environment.BASE_URL + '/contactfunctions/contactFunctions/',
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }
  deleteDepartment(id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/departmentdelete/' + id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  // List all employees
  list(id: number) {
    return this.http
      .get(environment.BASE_URL + '/employees/' + id, BaseServices.headerOption)
      .map((result: any) => result);
  }
  // List all employees
  portalList(userId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/portalemployees/' + userId,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }
  UpdateUser(id: number, data: any) {
    return this.http
      .put(
        environment.BASE_URL + '/user/' + id,
        data,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  getActiveEmployees(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/activeemployees/' + id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  getInActiveEmployees(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/inactiveemployees/' + id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  // retrive an employee for editing and View
  getById(id: any) {
    return this.http
      .get(environment.BASE_URL + '/employee/' + id, BaseServices.headerOption)
      .map((result: any) => result);
  }
  getSubDepartmentByEmployee(employeeId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/businesssubdepartment/' + employeeId,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }
  getUsersById(id: number) {
    return this.http
      .get(environment.BASE_URL + '/user/' + id, BaseServices.headerOption)
      .map((result) => result);
  }
  getUsersInfoById(id: number) {
    return this.http
      .get(environment.BASE_URL + '/userinfo/' + id, BaseServices.headerOption)
      .map((result: any) => result);
  }
  // add employee to list
  add(data: any) {
    let body = JSON.stringify(data);
    return this.http
      .post(environment.BASE_URL + '/employee', body, BaseServices.headerOption)
      .map((result) => result);
  }
  // Active and Inactive the Employee
  statusUpdate(id: number, status: any) {
    return this.http
      .put(
        `${environment.BASE_URL}/employee/${id}/${status}`,
        null,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  // updating employee list
  update(id: number, ExistingEmployee: Employee) {
    return this.http
      .put(
        environment.BASE_URL + '/employee/' + id,
        ExistingEmployee,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  updateDoc(data: EmployeeDocument) {
    return this.http
      .put(
        environment.BASE_URL + '/employee/' + data,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  // Delete equipment from list
  deleteEmployee(Id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/employee/' + Id,
        BaseServices.headerOption
      )
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }
  // multiple delete
  deleteMultipleEmployee(ids: any) {
    return this.http
      .put(environment.BASE_URL + '/employees', ids, BaseServices.headerOption)
      .map((result) => result);
  }
  // bind employee type
  getAllByType(Id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/employeetypes/' + Id,
        BaseServices.headerOption
      )
      .map((result) => <EmployeeType[]>result);
  }
  // update employee type
  updateType(existingCategory: EmployeeType) {
    return this.http
      .put(
        environment.BASE_URL + '/employeetype/' + existingCategory.Id,
        existingCategory,
        BaseServices.headerOption
      )
      .map((response: any) => {
        return response;
      });
  }

  // adding employee Type
  createType(newCategory: EmployeeType) {
    return this.http
      .post(
        environment.BASE_URL + '/employeetype',
        JSON.stringify(newCategory),
        BaseServices.headerOption
      )
      .map((response: any) => {
        return response;
      });
  }

  // adding employee Type
  getemployeename() {
    return this.http
      .post(
        environment.BASE_URL + '/employeename/' + BaseServices.BusinessId,
        BaseServices.headerOption
      )
      .map((response: any) => {
        return response;
      });
  }

  // deleting employee type
  deleteType(id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/employeetype/' + id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  // delete employe image
  deleteEmployeImage(empId: number, userId: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/employeeImage/' + empId + '/' + userId,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  deleteSubDepartment(id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/delete/subdepartment/' + id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  deletePosition(id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/employeeposition/' + id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  // bind employee position
  getAllByPosition(Id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/employeepositions/' + Id,
        BaseServices.headerOption
      )
      .map((result: any) => {
        return result;
      });
  }
  // update employee position
  updatePosition(existingCategory: EmployeePosition) {
    return this.http
      .put(
        environment.BASE_URL + '/employeeposition/' + existingCategory.Id,
        existingCategory,
        BaseServices.headerOption
      )
      .map((response: any) => {
        return response;
      });
  }
  // adding employee position
  createPosition(newCategory: EmployeePosition) {
    return this.http
      .post(
        environment.BASE_URL + '/employeeposition',
        JSON.stringify(newCategory),
        BaseServices.headerOption
      )
      .map((response: any) => {
        return response;
      });
  }

  // check user name
  getUserName(userName: string) {
    return this.http
      .get(
        environment.BASE_URL + '/check-username/' + userName,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  // check user name With Application
  getUserNameWithAppId(AppId: number, userName: string) {
    return this.http
      .get(
        environment.BASE_URL + '/check-username-id/' + AppId + '/' + userName,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  // check email name
  getEmail(AppId: number, email: string) {
    return this.http
      .get(
        environment.BASE_URL + '/check-email-employee/' + AppId + '/' + email,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  // update employee department
  updateDept(existingCategory: Employee): Observable<BusinessDepartment> {
    return this.http
      .put(
        environment.BASE_URL + '/department/' + existingCategory.Id,
        existingCategory,
        BaseServices.headerOption
      )
      .map((response: BusinessDepartment) => {
        return response;
      });
  }

  // adding employee department
  createDept(newCategory: Employee): Observable<BusinessDepartment> {
    return this.http
      .post(
        environment.BASE_URL + '/department',
        JSON.stringify(newCategory),
        BaseServices.headerOption
      )
      .map((response: any) => {
        return response;
      });
  }
  createSubDept(newSubDept: Employee) {
    return this.http
      .post(
        environment.BASE_URL + '/createsubdepartment',
        JSON.stringify(newSubDept),
        BaseServices.headerOption
      )
      .map((response: any) => {
        return response;
      });
  }
  // timttable list

  getAllTimetableByBusinessId(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/business-timetables/' + id,
        BaseServices.headerOption
      )
      .map((result: any) => (result ? result : null));
  }

  getTimeTableByEmployeeId(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/timetablebyemployee/' + id,
        BaseServices.headerOption
      )
      .map((result: any) => (result ? result : null));
  }
  // create timetable
  create(data: any) {
    return this.http
      .post(
        environment.BASE_URL + '/timetable/',
        data,
        BaseServices.headerOption
      )
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }
  // delete timetable
  deleteTimetable(id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/timetable/' + id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  // bind type dropdown
  getType(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/typeofworks/' + id,
        BaseServices.headerOption
      )
      .map((result) => <TypeOfWork[]>result);
  }
  // bind project dropdown
  getAllProject(Id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/project/getall/' + Id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  // add Private document
  addDoc(data: any) {
    let body = JSON.stringify(data);
    return this.http
      .post(
        environment.BASE_URL + '/employeedocument',
        body,
        BaseServices.headerOption
      )
      .map(this.extractData);
  }
  private extractData(res: any) {
    let body = res; // return body || {};
    return <Employee[]>res;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
  getallDocuments(eid: number) {
    return this.http
      .get(
        environment.BASE_URL + '/employeedocuments/' + eid,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  getAllSubDepartments(id: Array<number>) {
    const params = id?.map((i) => `departmentId=${i}`).join('&');
    const url = `${environment.BASE_URL}/subdepartments${
      params ? `?${params}` : ''
    }`;

    return this.http
      .get(url, BaseServices.headerOption)
      .map((result) => result);
  }

  getAssignedDepartments(id: Array<number>) {
    const params = id.map((i) => `departmentId=${i}`).join('&');
    return this.http
      .get(
        `${environment.BASE_URL}/assigneddepartments?${params}`,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  getSubDepartments(id: number | number[], businessId: string | number) {
    const businessIdNum = Number(businessId);
    // Ensure id is an array
    const ids = Array.isArray(id) ? id : [id];

    const params = ids.map((i) => `departmentId=${i}`).join('&');

    return this.http
      .get(
        `${environment.BASE_URL}/getsubdepartments?${params}&businessId=${businessIdNum}`,
        BaseServices.headerOption
      )
      .pipe(map((result) => result)); // Using pipe for RxJS operators
  }

  deleteDocument(id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/employeedocument/' + id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  getEmployeeUser(featureKey: number) {
    return this.http
      .get(
        environment.BASE_URL + '/employeeUser/' + featureKey,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  save(employeeId: number, document: any) {
    return this.http
      .post(
        environment.BASE_URL + '/employee/' + employeeId + '/private-doc',
        document,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  listDoc(employeeId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/employee/' + employeeId + '/common-docs',
        BaseServices.headerOption
      )
      .map((response: any) => {
        if (response.status === 200) {
          let data = response.data || [];
          data.forEach((d: any) => {
            d.CreatedOn = new Date(d.CreatedOn);
            d.ModifiedOn = new Date(d.ModifiedOn);
          });
          response.data = data;
          return response;
        } else {
          return response || {};
        }
      })
      .catch((error) => {
        return [];
      });
  }
  /**
   * delete documents
   * @param Id {any}
   */
  deleteEmployeeDocument(Id: any) {
    return this.http
      .delete(
        environment.BASE_URL + '/delete-employee-document/' + Id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  reportGenerate(businessId: number, cultureInfo: string, ReportJson: any) {
    let dynamicType = 'application/pdf';
    let myData = JSON.stringify({ data: ReportJson });
    let url = `${environment.BASE_URL}/export-commondetailslist/${businessId}/${cultureInfo}`;
    return Observable.create((observer: any) => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      let session = JSON.parse(sessionStorage.getItem('session'));
      let type = 'Bearer';
      let token = session.AccessToken;
      const tokenParts = token.split('.');
      tokenParts[1] =
        tokenParts[1].substring(0, 5) + 'W' + tokenParts[1].substring(5);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', type + ' ' + token);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }
  approveTimeTable(timeTableId: any) {
    return this.http
      .put(
        environment.BASE_URL + '/approvetimetable',
        timeTableId,
        BaseServices.headerOption
      )
      .map((response: any) => {
        return response;
      });
  }

  currentUserTOCStatus(userId: number, IsTOCApproved: boolean) {
    this.ses = sessionStorage.getItem('session');
    let headers = new HttpHeaders();
    let session = JSON.parse(this.ses);
    if (session) {
      let type = 'Bearer';
      let token = session.AccessToken;

      headers = headers.append('content-type', 'application/json');
      headers = headers.append('Authorization', type + ' ' + token);
    }

    return this.http
      .put(
        `${environment.BASE_URL}/current-user/${userId}/${IsTOCApproved}`,
        null,
        { responseType: 'blob', headers: headers }
      )
      .map((result) => result);
  }
  getUserByEmployeeId(empId: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/GetUserByEmployee/${empId}`,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  /**
   * Checks if a sub department is in use
   * @param Id {number}
   * @param businessId {number}
   */
  checkSubDeptById(id: number, businessId: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/check/subdepartment/${id}/${businessId}`,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
}

import { MenuItem, ConfirmationService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { UploaderConfig } from '../../../shared/ecpl-document-explorer/uploader-config';
import { FdvAttachment } from '../models/fdv-attachments';
import { } from '../models/fdv-attachments';
import { FdvDocumentServices } from '../services/fdv-doc.services';
import * as XLSX from 'xlsx';
import { MaterialItemLookup } from '../models/material-item-lookup';
import { MaterialItem } from '../models/material-item';
import {
    FDVDocumentList,
    CommonDocumentList,
    ForvaltningElList
} from '../models/fdv-document-list';
import { SelectItem } from 'primeng/api';
import { HelperService } from '../../../_services/helper.service';
import { KubaArticleViewerService } from './../../../shared/ecpl-article-viewer/ecpl-article-viewer.service';
import { ArticleContent } from 'src/app/shared/ecpl-article-viewer/interfaces';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../common/services';
import { FeatureManualService } from 'src/app/kuba/feature-documents/feature-document.services';
import * as signalR from '@aspnet/signalr';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
    moduleId: module.id,
    selector: 'fdv-doc',
    templateUrl: 'fdv-doc.component.html'
})
export class FdvDocComponent implements OnInit {
    docType : number;
    arrayBuffer: any;
    file: File;
    documentUploadUrl: any;
    uploadedFiles: any;
    displayEFOBase = false;
    displaydoc = false;
    displayphoto = false;
    displayPreviewPhoto = false;
    displayPhotoTitle: string;
    displayPhotoURL: string;
    displayMissingEFOReplace = false;
    upConfig: UploaderConfig;
    additionalData: any;
    fdvAttachments: FdvAttachment[];
    items: MenuItem[];
    activeItem: MenuItem;
    type: string;
    materialItemLookUpList: MaterialItemLookup[];
    materialItemList: MaterialItem[];
    projectId: number;
    fdvDocumentList: FDVDocumentList[];
    forvaltningDocumentList: ForvaltningElList[];
    commonDocumentList: CommonDocumentList[];
    selectedOwnDocumentList: FDVDocumentList[];
    selectedEFODocumentList: FDVDocumentList[];
    selectedCommonDocumentList: CommonDocumentList[];
    selectedforvaltningDocumentList: ForvaltningElList[];
    fdvProjectPhoto: any[];
    selectedProjectPhotoList: any[];
    checkEFOArticle = false;
    itemId: any;
    ownDocumentList: SelectItem[];
    selectOwnDocument: any;
    public selectedFiles;
    treeNode: any;
    articleContent: ArticleContent;
    loading = false;
    showLoader = false;
    filename = 'BROWSE';
    rowsPerPageOptions = [];
    defaultRowsPerPage = 10;
    isDisableUploadButton = true;
    isHideGuestButton = true;
    isOwnDocumentArticleSelected = true;
    efodocumentdailog = false;
    efobulkreportdailog = false;
    replaceHeader: string;
    showProgress = false;
    commonPNList: CommonDocumentList[] = [];
    progressPercentage: number;
    private subscriptions: Subscription[] = [];

    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;

    constructor(
        private fdvDocumentService: FdvDocumentServices,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private fdvService: FdvDocumentServices,
        private route: ActivatedRoute,
        private router: Router,
        private confirmationService: ConfirmationService,
        private http: HttpClient,
        private translate: TranslateService,
        public commonService: CommonService,
        private featureManualService: FeatureManualService
    ) {

        this.projectId = route.snapshot.params['Id'];
        this.fdvAttachments = [];
        this.additionalData = {
            ApplicationId: BaseServices.ApplicationId,
            BusinessId: BaseServices.BusinessId,
            Status: '1',
            CreatedOn: new Date(),
            ModifiedBy: BaseServices.UserId,
            ModifiedOn: new Date(),
            ModuleName: 'completechecklist'
        };

        this.upConfig = {
            title: this.translate.instant('DOCUMENTS'),
            titleAsLabel: true,
            editMode: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showSearchFilter: false,
            showUploadButton: true,
            showFDVItems: true,
            showProjectPhoto: false,
            showDeleteButton: true,
            uploaderUri:
                environment.BASE_URL +
                '/file/upload/business/completechecklist',
            addtionalData: this.additionalData
        };
        if (+BaseServices.roleId === 4) {
            this.isHideGuestButton = false;
        }
    }

    /**
     * page load event
     */
    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.bindTab();
        this.activeItem = this.items[0];
        this.type = 'common';
        this.BindDocument(this.type);
        this.BindOwnDocumentDropDownList();
        
        const connection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Information)
        .withUrl(environment.NOTIFICATION_URL + "/NotificationHub")
        .build();

        connection.start().then(function () {
            }).catch(function (err) {
             return console.error(err)
 
         });
         connection.onclose(() => {
             setTimeout(function(){
                 connection.start().then(function () {
                     }).catch(function (err) {
                       console.error(err)
                  });
                    },3000); 
         });
 
         connection.on('PushNotificationEFORecord', msg => {
             if(msg["projectId"] == this.projectId) {
                this.fdvDocumentList = [msg,...this.fdvDocumentList];
             }
         });
         connection.on('CommonEL', msg => {
            if(msg == "Completed")
            {
                this.progressPercentage = 100;
                this.BindDocument('common');
            }
            else {
                if(msg["createdBy"] == BaseServices.UserId && this.commonDocumentList.length == 3)
                {
                    this.showProgress = true;
                    this.commonPNList.push(msg);
                    this.commonDocumentList = [...this.commonDocumentList,msg];
                    this.progressPercentage = msg["progressPercentage"];
                } else {
                    this.showProgress = true;
                    if(msg["createdBy"] == BaseServices.UserId && (this.commonDocumentList.findIndex(x => x.Id == msg["id"]) < 0)) 
                        {
                            this.commonPNList.push(msg);
                            this.commonDocumentList = [...this.commonDocumentList,msg];
                            this.progressPercentage = msg["progressPercentage"];
                        } else  {
                            if(msg["createdBy"] == BaseServices.UserId && (this.commonDocumentList.findIndex(x => x.Id == msg["id"]) > 0)) 
                            {
                                this.commonPNList.push(msg);
                                var id = this.commonDocumentList.findIndex(x => x.Id == msg["id"]);
                                this.commonDocumentList[id] = msg;
                                this.progressPercentage = msg["progressPercentage"];
                            }
                        }  
                }
            } 
        });
    }

    menuChange(type: string, item: any) {
        this.activeItem = item;
        this.type = type;
        this.BindDocument(this.type);
        if (this.type === 'projectphotos') {
            this.upConfig.showProjectPhoto = true;
        } else if (this.type === 'efo') {
        } else {
            this.upConfig.showProjectPhoto = false;
        }
    }

    BindOwnDocumentDropDownList() {
        this.subscriptions.push(this.fdvService
            .GetFDVOwnDocument(
                BaseServices.ApplicationId,
                BaseServices.BusinessId,
                this.projectId
            )
            .subscribe((response:any) => {
                if (response) {
                    this.ownDocumentList = [];
                    let ownList: SelectItem = {
                        label: 'Select',
                        value: null
                    };
                    this.ownDocumentList.push(ownList);
                    let list = response;
                    list.forEach(element => {
                        let ownList: SelectItem = {
                            label: element.itemName,
                            value: element.itemNumber
                        };
                        this.ownDocumentList.push(ownList);
                    });
                }
            }));
    }

    BindDocument(type) {
        if (this.type === 'owndocument') {
            this.showLoader = true;
            this.fdvDocumentList = [];
            this.selectedOwnDocumentList = [];
            this.subscriptions.push(this.fdvService
                .GetFDVOwnDocument(
                    BaseServices.ApplicationId,
                    BaseServices.BusinessId,
                    this.projectId
                )
                .subscribe((response: any) => {
                    if (response) {
                        this.fdvDocumentList = response;
                        this.showLoader = false;
                    }
                }));
        } else if (type === 'projectphotos') {
            this.showLoader = true;
            this.fdvProjectPhoto = [];
            this.subscriptions.push(this.fdvService
                .GetFDVProjectPhoto(
                    BaseServices.ApplicationId,
                    BaseServices.BusinessId,
                    this.projectId
                )
                .subscribe((response:any) => {
                    if (response) {
                        this.showLoader = false;
                        this.fdvProjectPhoto = response;
                    }
                }));
        } else if (type === 'efo') {
            this.fdvDocumentList = [];
            this.selectedEFODocumentList = [];
            this.showLoader = true;
            this.subscriptions.push(this.fdvService
                .GetFDVEFODocument(
                    BaseServices.ApplicationId,
                    BaseServices.BusinessId,
                    this.projectId
                )
                .subscribe((response: any) => {
                    if (response) {
                        this.fdvDocumentList = response;
                        this.showLoader = false;
                    }
                }));
        } else if (type === 'common') {
          this.showLoader = true;
          if (this.progressPercentage == 100) {
            this.showLoader = false;
            this.progressPercentage = 0;
          }
          this.commonDocumentList = [];
          this.selectedCommonDocumentList = [];
          this.subscriptions.push(
            this.fdvService
              .GetFDVCommonDocument(16, BaseServices.BusinessId, this.projectId)
              .subscribe((response: any) => {
                if (response) {
                  this.activeItem = this.items[0];
                  this.commonDocumentList = response;
                  this.showLoader = false;
                  this.rowsPerPageOptions = [];
                  this.rowsPerPageOptions.push(10);
                  this.rowsPerPageOptions.push(50);
                  this.rowsPerPageOptions.push(100);
                  this.defaultRowsPerPage = this.commonDocumentList.length;
                  if (this.commonDocumentList.length > 100) {
                    this.rowsPerPageOptions.push(
                      this.commonDocumentList.length
                    );
                  }
                  this.showProgress = false;
                }
              })
          );
        }
    }

    bindTab() {
        this.subscriptions.push(this.translate.stream('FDV_TAB_MENU').subscribe(val => {
            this.items = [];
            this.items = [
              {
                label: this.translate.instant('COMMON'),
                icon: 'pi pi-book',
                command: (event) => this.menuChange('common', event.item),
              },
              {
                label: this.translate.instant('EFO_BASE'),
                icon: 'pi pi-book',
                command: (event) => this.menuChange('efo', event.item),
              },
              {
                label: this.translate.instant('OWN_DOCUMENT'),
                icon: 'pi pi-book',
                command: (event) => this.menuChange('owndocument', event.item),
              },
              {
                label: this.translate.instant('PROJECT_PHOTOS'),
                icon: 'pi pi-book',
                command: (event) =>
                  this.menuChange('projectphotos', event.item),
              },
            ];

        }));
    }
    /**
     * excel upload
     * @param event {any}
     */
    onUpload(event) {
    }

    /**
     * Read and save excel document
     */
    saveUpload() { }
    showDlgBase() {
        this.displayEFOBase = true;
    }
    showDlgdoc() {
        this.fdvAttachments = [];
        this.displaydoc = true;
    }
    showDlgPhoto() {
        this.fdvAttachments = [];
        this.displayphoto = true;
    }

    gotoBack() {
        this.location.back();
    }

    uploaderEvents(event: any) {
        switch (event.eventName) {
            case 'DeleteDefaultFile':
                this.fdvAttachments = this.fdvAttachments.filter(
                    x => x.path !== event.item.data.path
                );
                break;
            default:
                let uploadedFile = event;
                if (this.type === 'owndocument') {
                    this.fdvAttachments = this.fdvAttachments.filter(
                        x => x.extension.toLowerCase() === 'pdf'
                    );
                } else if (this.type === 'projectphotos') {
                    this.fdvAttachments = this.fdvAttachments.filter(
                        x =>
                            x.extension.toLowerCase() === 'jpeg' ||
                            x.extension.toLowerCase() === 'png' ||
                            x.extension.toLowerCase() === 'bmp' ||
                            x.extension.toLowerCase() === 'jpg'
                    );
                }
                // TODO: add  uploaded files to attachment objects
                break;
        }
    }

    saveUploadedOwnDocument() {
        this.materialItemLookUpList = [];
        let attachmentList = this.fdvAttachments;
        attachmentList.forEach(element => {
            let fileList: MaterialItemLookup = {
                Id: 0,
                ApplicationId: BaseServices.ApplicationId,
                BusinessId: BaseServices.BusinessId,
                ItemNumber:
                    element.itemNo === undefined ? null : element.itemNo.trim(),
                ItemName:
                    element.itemName === undefined
                        ? null
                        : element.itemName.trim(),
                Type: 2,
                Path: element.path,
                Status: 1,
                ProjectId : this.projectId
            };
            this.materialItemLookUpList.push(fileList);
        });
        this.subscriptions.push(this.fdvService
            .saveOwnDocumentUploadedFile(this.materialItemLookUpList)
            .subscribe(response => {
                if (response) {
                    this.materialItemList = [];
                    response.forEach(element => {
                        let list: MaterialItem = {
                            Id: 0,
                            ItemNumber: element.ItemNumber,
                            ItemName: element.ItemName,
                            ProjectId: this.projectId,
                            BusinessId: element.BusinessId,
                            ApplicationId: element.ApplicationId,
                            Status: 1,
                            Path: element.Path,
                            FDVManualDocPath: null,
                            Type: 2,
                            ImageTitle: null,
                            CreatedBy: BaseServices.UserId
                        };
                        this.materialItemList.push(list);
                    });
                    this.subscriptions.push(this.fdvService
                        .SaveOwnDocumentMaterialItem(this.materialItemList)
                        .subscribe(response => {
                            if (response) {
                                this.displaydoc = false;
                                this.BindDocument('owndocument');
                                this.toasterComponent.callToast();
                            }
                        }));
                }
            }));
    }

    saveSelectedOwnDocumentList() {

        if (!this.isOwnDocumentArticleSelected) {
            this.confirmationService.confirm({
                message:
                this.translate.instant('EFO_NRF_MISSING_SORTING_MESSAGE'),
                accept: () => {
                    this.saveOperationForOwnDocument()
                },
                reject: () => {
                    this.showLoader = false;
                }
            });
        } else {
            this.saveOperationForOwnDocument()
        }
    }

    saveOperationForOwnDocument() {
        this.showLoader = true;
        let list:any = HelperService.switchKeysCaseForDetail(
            this.selectedOwnDocumentList,
            'U'
        );
        list.forEach(element => {
            element.ProjectArticle = HelperService.switchKeysCaseForDetail(
                element.ProjectArticle,
                'U'
            );
            element.ProjectSelectedArticle = HelperService.switchKeysCaseForDetail(
                element.ProjectSelectedArticle,
                'U'
            );
            element.ApplicationId = BaseServices.ApplicationId;
            element.BusinessId = BaseServices.BusinessId;
            element.ProjectId = this.projectId;
            element.UserId = BaseServices.UserId;
            element.Type = 2;
        });

        this.materialItemList = [];
        list.forEach(element => {
            let list: MaterialItem = {
                Id: 0,
                ItemNumber: element.ItemNumber,
                ItemName: element.ItemName,
                ProjectId: this.projectId,
                BusinessId: element.BusinessId,
                ApplicationId: element.ApplicationId,
                Status: 1,
                Path: element.Path,
                FDVManualDocPath: null,
                Type: element.ProjectSelectedArticle.length > 0 ? element.Type : 6,
                ImageTitle: null,
                CreatedBy: BaseServices.UserId
            };
            this.materialItemList.push(list);
        });

        this.subscriptions.push(this.fdvService
            .SaveOwnDocumentMaterialItem(this.materialItemList)
            .subscribe(response => {
                if (response) {
                    this.subscriptions.push(this.fdvService.SaveFDVOwnDocumentList(list).subscribe(
                        response => {
                            if (response) {
                                this.showLoader = false;
                                this.toasterComponent.callToast();
                                this.activeItem = this.items[0];
                                this.type = 'common';
                                this.BindDocument('common');
                            }
                        }
                    ));
                }
            }));
    }

    saveSelectedEFODocumentList() {
        if (this.checkEFOArticle) {
            this.confirmationService.confirm({
                message:
                this.translate.instant('EFO_NRF_MISSING_SORTING_MESSAGE'),
                accept: () => {
                    this.saveEFODocument();
                    this.toasterComponent.callToast();
                },
                reject: () => {
                    this.showLoader = false;
                }

            });
        } else {
            this.saveEFODocument();
            this.toasterComponent.callToast();
        }
    }

    saveEFODocument() {
        this.showProgress = true;
        let list:any = HelperService.switchKeysCaseForDetail(
            this.selectedEFODocumentList,
            'U'
        );
        list.forEach(element => {
            element.ProjectArticle = HelperService.switchKeysCaseForDetail(
                element.ProjectArticle,
                'U'
            );
            element.ProjectSelectedArticle = HelperService.switchKeysCaseForDetail(
                element.ProjectSelectedArticle,
                'U'
            );
            element.ApplicationId = BaseServices.ApplicationId;
            element.BusinessId = BaseServices.BusinessId;
            element.ProjectId = this.projectId;
            element.UserId = BaseServices.UserId;
            element.Type = 1;
        });

        this.subscriptions.push(this.fdvService
            .SaveFDVEFODocumentList(
                list,
                BaseServices.BusinessId,
                this.projectId
            )
            .subscribe(response => {
                if (response) {
                    this.activeItem = this.items[0];
                    this.type = 'common';
                    this.progressPercentage = 0;
                    this.toasterComponent.callToast();
                }
            }));
    }

    DeleteFdvLookUp(fdvOwnDocList: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                fdvOwnDocList.projectId = this.projectId;
                this.subscriptions.push(this.fdvService
                    .DeleteFDVOwnDocument(fdvOwnDocList)
                    .subscribe(response => {
                        if (response) {
                            this.BindDocument('owndocument');
                            this.toasterComponent.callToastDlt();
                        }
                    }));
            }
        });
    }

    DeleteSingleFDVEfo(fdvEfoList: any) {
        let efoList:any;
        efoList = [];
        efoList.push(fdvEfoList);
        efoList = HelperService.switchKeysCaseForDetail(efoList, 'U');
        this.DeleteEFODoc(efoList);
    }

    DeleteMultipleEfoDoc() {
        let selectedList:any = HelperService
            .switchKeysCaseForDetail(this.selectedEFODocumentList, 'U');
        this.DeleteEFODoc(selectedList);
    }

    DeleteEFODoc(efoList: any[]) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.fdvService
                    .DeleteFDVFfoDocument(efoList)
                    .subscribe(response => {
                        if (response) {
                            this.BindDocument('efo');
                            this.toasterComponent.callToastDlt();
                        }
                    }));
            }
        });
    }


    UploadPhotoFiles() {
        this.materialItemList = [];
        let fdvAttachments = this.fdvAttachments;
        fdvAttachments.forEach(element => {
            let list: MaterialItem = {
                Id: 0,
                ItemNumber: null,
                ItemName: element.filename,
                ProjectId: this.projectId,
                BusinessId: BaseServices.BusinessId,
                ApplicationId: BaseServices.ApplicationId,
                Status: 1,
                Path: null,
                FDVManualDocPath: element.path,
                Type: 3,
                ImageTitle: element.itemName,
                CreatedBy: BaseServices.UserId
            };
            this.materialItemList.push(list);
        });

        this.subscriptions.push(this.fdvService
            .SaveProjectPhotoMaterialItem(this.materialItemList)
            .subscribe(response => {
                if (response) {
                    this.displayphoto = false;
                    this.BindDocument('projectphotos');
                    this.toasterComponent.callToast();
                    this.materialItemList = [];
                }
            }));
    }

    saveElProjectPhoto() {
        let list = this.selectedProjectPhotoList;
        list.forEach(element => {
            element.ApplicationId = BaseServices.ApplicationId;
            element.BusinessId = BaseServices.BusinessId;
            element.ProjectId = this.projectId;
            element.UserId = BaseServices.UserId;
            element.Type = 3;
        });

        this.subscriptions.push(this.fdvService.SaveFDVPhotoList(list).subscribe(
            response => {
                if (response) {
                    this.activeItem = this.items[0];
                    this.type = 'common';
                    this.BindDocument('common');
                    this.toasterComponent.callToast();
                }
            }
        ));
    }

    saveSelectedProjectPhotoList() {
        this.showLoader = true;
        if (this.checkEFOArticle) {
            this.confirmationService.confirm({
                message:
                    'One or more image are missing sorting, are you sure you want to add them to the grid?',
                accept: () => {
                    this.saveElProjectPhoto();
                    this.toasterComponent.callToast();
                    this.showLoader = false;
                },
                reject: () => {
                    this.showLoader = false;
                }
            });
        } else {
            this.saveElProjectPhoto();
            this.toasterComponent.callToast();
            this.showLoader = false;
        }

    }

    SaveSelectedForvaltningEL() {
        let list = this.selectedforvaltningDocumentList;
        this.subscriptions.push(this.fdvService
            .SaveForvaltningEL(
                list,
                this.projectId,
                BaseServices.BusinessId,
                BaseServices.UserId
            )
            .subscribe(response => {
                if (response) {
                    this.activeItem = this.items[0];
                    this.type = 'common';
                    this.BindDocument('common');
                    this.toasterComponent.callToast();
                }
            }));
    }

    DeleteFdvPhotoProject(id: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.fdvService.DeleteFDVPhotoProject(id).subscribe(respose => {
                    if (respose) {
                        this.BindDocument('projectphotos');
                        this.toasterComponent.callToastDlt();
                    }
                }));
            }
        });
    }

    PreviewProjectPhoto(fdvProjectPhoto: any) {
        this.displayPreviewPhoto = true;
        this.displayPhotoURL = fdvProjectPhoto.FDVManualDocPath;
        this.displayPhotoTitle = fdvProjectPhoto.ImageTitle;
    }
    ExitPreviewProjectPhoto() {
        this.displayPreviewPhoto = false;
    }

    incomingfile(event) {
        this.file = event.target.files[0];
        this.filename = this.file.name;
        this.isDisableUploadButton = false;
    }

    Upload() {
        let efofilename =  this.filename;
        this.filename = 'BROWSE';
        this.displayEFOBase = false;
        this.showLoader = true;
        let efoJson;
        let worksheet;
        let fileReader = new FileReader();
        fileReader.onload = e => {
            this.arrayBuffer = fileReader.result;
            let data = new Uint8Array(this.arrayBuffer);
            let arr = new Array();
            for (var i = 0; i != data.length; ++i)
                arr[i] = String.fromCharCode(data[i]);
            let bstr = arr.join('');
            let workbook = XLSX.read(bstr, { type: 'binary' });
            let first_sheet_name = workbook.SheetNames[0];
            worksheet = workbook.Sheets[first_sheet_name];
            
            efoJson = JSON.stringify(XLSX.utils.sheet_to_json(worksheet, { header: ["ProductId", "ProductName"] , raw: false }));
            if (efoJson) {
                this.subscriptions.push(this.fdvDocumentService
                    .uploadEFO(
                        +BaseServices.ApplicationId,
                        +BaseServices.BusinessId,
                        +this.projectId,
                        efofilename,
                        efoJson
                    )
                    .subscribe(
                        response => {
                            if (response) {
                                this.showLoader = false;
                                this.displayEFOBase = false;
                                this.efodocumentdailog = true;
                                this.isDisableUploadButton = true;
                                this.BindDocument('efo');
                            }
                        },
                        error => {
                            console.log("Upload error : ", error);
                        },
                        () => console.log("uploaded completed")
                    ));
            }
        }
        fileReader.readAsArrayBuffer(this.file);
    }

    validateSelectedFDVEFODocument() {
        this.checkEFOArticle = false;
        if (this.selectedEFODocumentList !== undefined) {
            if (this.selectedEFODocumentList.length > 0) {
                let returnValue = false;
                this.selectedEFODocumentList.forEach(element => {
                    let list: any = element;
                    if (list.projectSelectedArticle !== undefined) {
                        if (list.projectSelectedArticle.length === 0) {
                            this.checkEFOArticle = true;
                        }
                    }
                });
                let res:any= returnValue;
                return res;
            } else {
                return true;
            }
        }
    }
    onTableHeaderCheckboxToggle(event: any, type : string) {
        switch(type)  {
            case 'common' : 
                        this.selectedCommonDocumentList = this.commonDocumentList; 
                        break; 
            case 'efo' :
                        this.selectedEFODocumentList = this.fdvDocumentList;
                        break; 
            case 'own' :
                        this.selectedOwnDocumentList = this.fdvDocumentList;
                        break;
            case 'projectphotos' : 
                        this.selectedProjectPhotoList  = this.fdvProjectPhoto;
                        break;
            case 'ahlselldatabase' :  
                        this.selectedforvaltningDocumentList = this.forvaltningDocumentList;
                        break; 
            default: break;
        }
       this.selectedEFODocumentList = this.fdvDocumentList;
    }

    validateSelectedFDVOwnDocument() {
        if (this.selectedOwnDocumentList !== undefined) {
            if (this.selectedOwnDocumentList.length > 0) {
                let returnValue = false;
                this.isOwnDocumentArticleSelected = true;
                this.selectedOwnDocumentList.forEach(element => {
                    let list: any = element;
                    if (list.projectSelectedArticle !== undefined) {
                        if (list.projectSelectedArticle.length === 0) {
                            this.isOwnDocumentArticleSelected = false;
                        }
                    }
                });
                let res:any = returnValue;
                return res;
            } else {
                return true;
            }
        }
    }

    validateSelectedPhotoProject() {
        this.checkEFOArticle = false;
        if (this.selectedProjectPhotoList !== undefined) {
            if (this.selectedProjectPhotoList.length > 0) {
                let returnValue = false;
                this.selectedProjectPhotoList.forEach(element => {
                    if (element.ProjectSelectedArticle.length === 0) {
                        this.checkEFOArticle = true;
                    }
                });
                return returnValue;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }


    validateOwnDocumentUpload() {
        let result = false;
        if (this.fdvAttachments !== undefined) {
            if (this.fdvAttachments.length > 0) {
                this.fdvAttachments.forEach(element => {
                    if ((element.itemNo !== null && element.itemNo !== undefined)
                        && (element.itemName !== null && element.itemName !== undefined)) {
                    } else {
                        result = true;
                    }
                });
            } else {
                result = true;
            }
        } else {
            result = true;
        }
        return result;
    }

    ReplaceMissingEFODocument(id: any) {
        this.itemId = id;
        this.displayMissingEFOReplace = true;
        this.replaceHeader = this.translate.instant('REPLACE_EFO_MISSING_DOCUMENT');
        this.docType = 1;
    }
    ReplaceOwnDocument(x: any) {
        this.itemId = x.id;
        this.displayMissingEFOReplace = true;
        this.replaceHeader = this.translate.instant('REPLACE_OWN_DOCUMENT');
        this.docType = (x.type == 6) ? 1 : x.type;
    }

    replaceEfoWithOwnDocument() {
        this.subscriptions.push(this.fdvService
            .ReplaceEFODocumentWithOwnDocument(
                this.itemId,
                this.selectOwnDocument
            )
            .subscribe(response => {
                this.displayMissingEFOReplace = false;
                this.BindDocument('common');
                this.toasterComponent.callToast();
            }));
    }
    replaceEFOwithFileUpload(selectedFiles: any) {
        if (selectedFiles.currentTarget.files[0].type === 'application/pdf') {
            this.confirmationService.confirm({
                message: this.translate.instant('EFO_VVS_REPLACE_CONFIRM_MESSAGE'),
                accept: () => {
                    this.showLoader = true;
                    this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(
                        file => file
                    );
                    let formData = new FormData();
                    formData.append('file', this.selectedFiles[0]);
                    let session = JSON.parse(sessionStorage.getItem('session'));
        let token = session.AccessToken;
        var headers = {
            'Authorization': 'Bearer ' + token, // Add JWT token to headers
            // Add any other headers if needed
        };
                    this.subscriptions.push(this.http
                        .post(
                            environment.BASE_URL +
                            '/file/upload/fdv/pdf-file/' +
                            BaseServices.ApplicationId +
                            '/' +
                            this.projectId,
                            formData,{headers:headers}
                        )
                        .map((response: any) => {
                            let res = response;
                            let path = res.Result.path;
                            let list: CommonDocumentList = {
                                Id: 0,
                                ItemId: this.itemId,
                                ItemNumber: null,
                                ItemName: null,
                                Path: null,
                                Type: this.docType,
                                FilePath: null,
                                FDVManualDocPath: path,
                                ProjectArticleId: 0,
                                ProjectId: this.projectId,
                                Status: 1,
                                ImageTitle: null,
                                ParentArticleId: 0
                            };
                            this.subscriptions.push(this.fdvService
                                .ReplaceEFODocument(list)
                                .subscribe(result => {
                                    if (result) {
                                        this.showLoader = false;
                                        this.displayMissingEFOReplace = false;
                                        this.toasterComponent.callToast();
                                        this.BindDocument('common');
                                    }
                                }));
                        })
                        .subscribe(
                            result => {
                                if (result != null) {
                                    let test = result;
                                }
                            },
                            error =>

                                console.log(
                                    'Upload manual cover image Error : ',
                                    error
                                ),
                            () =>
                                console.log(
                                    'Upload manual cover image Complete'
                                )
                        ));
                }
            });
        } else {
            alert('Please upload pdf file.');
            this.showLoader = false;
        }
    }
    validateSelectedForvaltning() {
        if (this.selectedforvaltningDocumentList !== undefined) {
            if (this.selectedforvaltningDocumentList.length > 0) {
                let returnValue = false;
                return returnValue;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    validateSelectedCommon() {
        if (this.selectedCommonDocumentList !== undefined) {
            if (this.selectedCommonDocumentList.length > 0) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    downloadFDF(details: any) {
        this.showLoader = false;
        if (details.type === 7 && details.filePath === 'Forside') {
            this.downloadForeSidePage();
        } else if (
            details.type === 7 &&
            details.filePath === 'ProjectDetails'
        ) {
            this.downloadProjectDetails();
        } else if (
            details.type === 7 &&
            details.filePath === 'Innholdsfortegnelse komplett'
        ) {
            this.downloadInnholdsfortegnelse();
        } else if (
            details.type === 7 &&
            details.filePath === 'Kapittelinndeling'
        ) {
            this.downloadIndex();
        }else if (
            details.type === 1 ||
            details.type === 2 ||
            details.type === 3 ||
            details.type === 6 ||
            details.type === 8
        ) {
            this.downloadMaerialItem(details);
        } else if (
            (details.type === 5 || details.type === 4) &&
            details.filePath === 'article'
        ) {
            this.downloadArticle(details.itemId, details.itemName);
        } 
    }

    downloadMaerialItem(details: any) {
        let fileName = details.itemName;
        let type = 'pdf';
        let selectedCommon: any[] = [];
        let position = 0;
        let tempselectedCommonDocumentList: any[] = this
            .selectedCommonDocumentList;
        let list: any = {
            Id: (typeof details.itemId !== 'undefined') ? details.itemId : details.id,
            Position: 0,
            ItemNumber: details.itemNumber,
            ItemName: details.itemName,
            Type: details.type,
            isSubSection: false,
            FilePath: (details.filePath == null) ? details.path : details.filePath
        };

        this.subscriptions.push(this.fdvService
            .generateExportMaterialItemFile(
                list,
                BaseServices.BusinessId,
                'en-GB',
                this.projectId
            )
            .subscribe(
                blob => {
                    this.showLoader = false;
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${fileName}.${type.toLowerCase()}`;
                    link.click();
                },
                error => {
                    this.BindDocument('common');
                    alert('Invalid PDF file');
                }
            ));
    }

    downloadForeSidePage() {
        let fileName = 'ForeSide';
        let type = 'pdf';
        let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
        let languageId = appSettings.Language.LanguageId;
        let CultureInfo = BaseServices.userCultureInfo();
        let fdvDetails: any = [];
        let detail: any = {
            projectId: this.projectId
        };
        fdvDetails.push(detail);
        this.subscriptions.push(this.fdvService
            .generateForesSideReport(fdvDetails, BaseServices.BusinessId, CultureInfo, this.projectId, 1)
            .subscribe(
                blob => {
                    this.showLoader = false;
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${fileName}.${type.toLowerCase()}`;
                    link.click();
                },
                error => {
                    alert('Export not downloaded');
                }
            ));
    }

    downloadNoEFOPage(details: any) {
        let fileName = 'NoEFODocument';
        let type = 'pdf';
        let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
        let languageId = appSettings.Language.LanguageId;
        let CultureInfo = BaseServices.userCultureInfo();
        let fdvDetails: any = [];
        let detail: any = {
            ProjectId: this.projectId,
            Title: details.itemName
        };
        fdvDetails.push(detail);
        this.subscriptions.push(this.fdvService
            .generateNoEFODocument(fdvDetails, ' ', 1, BaseServices.BusinessId)
            .subscribe(
                blob => {
                    this.showLoader = false;
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${fileName}.${type.toLowerCase()}`;
                    link.click();
                },
                error => {
                    alert('Export not downloaded');
                }
            ));
    }

    downloadInnholdsfortegnelse() {
        let fileName = 'Innholdsfortegnelse';
        let type = 'pdf';
        let selectedCommon: any[] = [];
        let position = 0;
        let tempselectedCommonDocumentList: any[] = this
            .selectedCommonDocumentList;
        tempselectedCommonDocumentList.forEach(element => {
            let details: any = {
                Position: position++,
                ItemNumber: element.itemNumber,
                ItemName: element.itemName,
                Type: element.type,
                isSubSection: false
            };
            selectedCommon.push(details);
        });

        this.subscriptions.push(this.fdvService
            .generateInnholdsfortegnelse(
                selectedCommon,
                BaseServices.BusinessId,
                'en-GB',
                this.projectId
            )
            .subscribe(
                blob => {
                    this.showLoader = false;
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${fileName}.${type.toLowerCase()}`;
                    link.click();
                },
                error => {
                    alert('Export not downloaded');
                }
            ));
    }
    
    downloadIndex() {
        let fileName = 'Kapittelinndeling';
        let type = 'pdf';
        let selectedCommon: any[] = [];
        let position = 0;
        let tempselectedCommonDocumentList: any[] = this
            .selectedCommonDocumentList;
        tempselectedCommonDocumentList.forEach(element => {
            let details: any = {
                Position: position++,
                ItemNumber: element.itemNumber,
                ItemName: element.itemName,
                Type: element.type,
                isSubSection: false
            };
            selectedCommon.push(details);
        });

        this.subscriptions.push(this.fdvService
            .generateIndex(
                selectedCommon,
                BaseServices.BusinessId,
                'en-GB',
                this.projectId
            )
            .subscribe(
                blob => {
                    this.showLoader = false;
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${fileName}.${type.toLowerCase()}`;
                    link.click();
                },
                error => {
                    alert('Export not downloaded');
                }
            ));
    }
    fdvBulkDownLoad() {
        this.subscriptions.push(this.commonService.getByProjectId(this.projectId).subscribe(res => {
            if (res) {
                this.showLoader = true;
                this.loading = true;
                let fileName = 'FDV-dokumentasjon-' + res.Title;
                let type = 'pdf';
                let selectedCommon: any[] = [];
                let position = 0;
                let tempselectedCommonDocumentList: any[] = this
                    .selectedCommonDocumentList;
                tempselectedCommonDocumentList.forEach(element => {
                    let details: any = {
                        Position: element.id,
                        ItemNumber: element.itemNumber,
                        ItemName: element.itemName,
                        Type: element.type,
                        isSubSection: false,
                        FilePath: element.filePath,
                        Id: element.itemId
                    };
                    selectedCommon.push(details);
                });

                this.subscriptions.push(this.fdvService
                    .generateFDVBulkDownLoad(selectedCommon, BaseServices.BusinessId,sessionStorage.getItem('languageMode') ,fileName, this.projectId, 1)
                    .subscribe(
                        blob => {
                            this.showLoader = false;
                            this.loading = false;
                            this.efobulkreportdailog= true;
                            this.selectedCommonDocumentList= [];
                        },
                        error => {
                            this.loading = false;
                            alert('Export not downloaded');
                        }
                    ));
            }
        }));


    }

    downloadProjectDetails() {
        let fileName = 'ForeSide';
        let type = 'pdf';
        let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
        let languageId =  JSON.parse(sessionStorage.getItem('languageId'));
        let CultureInfo = BaseServices.userCultureInfo(languageId);
        let fdvDetails: any = [];
        let detail: any = {
            projectId: this.projectId
        };
        fdvDetails.push(detail);
        this.subscriptions.push(this.fdvService
            .generateProjectDetailReport(
                fdvDetails,
                BaseServices.BusinessId,
                CultureInfo,
                this.projectId
            )
            .subscribe(
                blob => {
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${fileName}.${type.toLowerCase()}`;
                    link.click();
                },
                error => {
                    alert('Export not downloaded');
                }
            ));
    }

    downloadArticle(aId: number, aName: string) {
        let type = 'pdf';
        let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
        let CultureInfo = BaseServices.userCultureInfo();
        this.subscriptions.push(this.fdvService
            .generateArticleFile(
                null,
                aId,
                this.projectId,
                BaseServices.BusinessId,
                CultureInfo
            )
            .subscribe(
                blob => {
                    this.showLoader = false;
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = aName;
                    link.click();
                },
                error => {
                    alert('Export not downloaded');
                }
            ));
    }

    deleteCommonList(commonList: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.fdvService
                    .DeleteFDVCommonDocunet(commonList, this.projectId)
                    .subscribe(respose => {
                        if (respose) {
                            this.BindDocument('common');
                        }
                    }));
            }
        });
    }

    deleteBulkCommonList() {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_SELECTED'),
            accept: () => {
                this.subscriptions.push(this.fdvService
                    .DeleteFDVCommonDocunetList(this.selectedCommonDocumentList, this.projectId)
                    .subscribe(respose => {
                        if (respose) {
                            this.BindDocument('common');
                        }
                    }));
            }
        });
    }

    gotoArticleEditMode(details: any) {
        this.router.navigate(
            ['./../../document/list', { Id: this.projectId }],
            { relativeTo: this.activatedRoute }
        );
    }

    openArticleViewer(data: any) {
    }

    // emitter for save from Document Explorer
    saveUploaded(event: any) {
        switch (event.eventName) {
            case 'SaveArticle':
                this.catchEvent(event);
                break;
            default:
                break;
        }
    }

    catchEvent(event): void {
        switch (event.eventName) {
            case 'SaveArticle':
                let version = event.item.articleContent.article.version;
                this.subscriptions.push(this.featureManualService
                    .saveArticle(this.projectId, event)
                    .subscribe(
                        (response:any) => {
                            this.subscriptions.push(this.featureManualService
                                .newArticleText(
                                    this.projectId,
                                    response.Id,
                                    event,
                                    false,
                                    version
                                )
                                .subscribe(result => {
                                    this.type = 'common';
                                    this.BindDocument(this.type);
                                    this.toasterComponent.callToast();
                                }));
                        },
                        error => console.log('Save article error : ', error),
                        () => console.log('save article Complete')
                    ));
                break;
            default:
                break;
        }
    }
    showNotificationTab()
    {
        this.efodocumentdailog = false;
        this.efobulkreportdailog = false
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}

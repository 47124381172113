import { ArchiveServices } from './archive.services';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppSharedModule } from './../shared/appShared.module';
import { RouterModule } from '@angular/router';
import { ArchiveComponent } from './archive.component';
import { ArchiveTreeDataResolver } from './archive.resolver.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { EcplDocumentTreeModule } from '../../shared/ecpl-document-tree/ecpl-document-tree.module';
import { EcplDocumentExplorerModule } from '../../shared/ecpl-document-explorer';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AppSharedModule,
        RouterModule,
        EcplDocumentTreeModule,
        EcplDocumentExplorerModule,
        ModalModule.forRoot()
    ],
    declarations: [
        ArchiveComponent
    ],
    providers: [ArchiveServices, ArchiveTreeDataResolver],
    exports: [
        ArchiveComponent
    ]
})
export class ArchiveModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: ArchiveModule
        };
    }
}

import { HelperService } from 'src/app/_services/helper.service';
import { PdfSetting } from './../../../_services/helper.service';
import { BaseServices } from './../../kuba.services';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { FormBuilder } from '@angular/forms';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { DatePipe } from '@angular/common';
import { MeasureProtocolService } from '../services/measure-protocol.services';
import { MeasureProtocolList, CompleteMeasureProtocolList } from '../models/measure-protocol';
import { CMP } from './../models/measure-protocol';
import { Instrument } from './../models/instrument';
import { TranslateService } from '@ngx-translate/core';
import { FeatureKey } from 'src/app/_models';
import { Subscription } from 'rxjs';
@Component({
    selector: 'measure-protocol-list',
    templateUrl: 'measure-protocol-list.component.html'
})

export class MeasureProtocolListComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    measureProtocols: MeasureProtocolList[];
    completeMeasureProtocols: CompleteMeasureProtocolList[];
    parentKey: any;
    parentId: any;
    additionalData: any;
    isGuest = true;
    private subscriptions: Subscription[] = [];
    constructor(private confirmationService: ConfirmationService,
        private route: ActivatedRoute,
        public measureprotocolservice: MeasureProtocolService,
        private fb: FormBuilder,
        private router: Router,
        private datePipe: DatePipe,
        private translate: TranslateService) {
        this.parentId = route.snapshot.parent.parent.params['id'];
        this.parentKey = route.snapshot.parent.parent.data['parent'];

        this.additionalData = {
            fileName: 'Measure Protocol',
            header: 'Measure Protocol',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'MP Number', dataKey: 'Number' },
                { title: 'MP Name', dataKey: 'Name' },
                { title: 'Created Date', dataKey: 'CreatedOn' },
                { title: 'Edited Date', dataKey: 'ModifiedOn' },
                { title: 'Status', dataKey: 'Status' },
                { title: 'Created By', dataKey: 'CreatedName' },
                { title: 'Edited By', dataKey: 'ModifiedName' },
            ]
        };

    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        if (BaseServices.roleId === '4') {
            this.isGuest = false;
        }
        this.measureProtocols = [];
        this.measureProtocols = this.route.snapshot.data['listMP'];
        this.completeMeasureProtocols = this.route.snapshot.data['listMPComplete'];
    }

    /**
     * get all measure protocol list
    */
    getAllMeasureProtocols() {
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        let moduleId = workingModule ? workingModule.id : 0;
        this.subscriptions.push(this.measureprotocolservice
            .getAllMeasureProtocol(moduleId,this.parentId, this.parentKey)
            .subscribe((result: any) => this.measureProtocols = result));
    }

    /**
     * get all complete measure protocol list
    */
    getAllCompleteMeasureProtocols() {
        this.subscriptions.push(this.measureprotocolservice
            .getAllCompleteMeasureProtocol(this.parentId, this.parentKey)
            .subscribe((result: any) => this.completeMeasureProtocols = result));
    }

    /**
     * delete mp based on id
     * @param  mp
    */
    deleteMP(measureProtocols) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.measureprotocolservice.deleteMP(measureProtocols, this.parentId).subscribe(result => {
                    this.getAllMeasureProtocols();
                    this.toasterComponent.callToastDlt();
                }));
            }
        });
    }

    /**
     * delete mp based on id
     * @param  cmp
    */

    deleteCMP(measureProtocols) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.measureprotocolservice.deleteCMP(measureProtocols, this.parentId).subscribe(result => {
                    this.getAllCompleteMeasureProtocols();
                    this.toasterComponent.callToastDlt();
                }));
            }
        });
    }

    /**
     * pdf report for framework and agreement list
    */
    exportPdf() {
        let pdfSetting = new PdfSetting();
        pdfSetting.date = 'Date' + HelperService.formatDate(new Date());
        pdfSetting.businessName = BaseServices.BusinessName;
        pdfSetting.pageHeader = 'Measure Protocol list';
        let columns = [
            { title: 'MP Number', dataKey: 'Number' },
            { title: 'MP Name', dataKey: 'Name' },
            { title: 'Created Date', dataKey: 'CreatedOn' },
            { title: 'Edited Date', dataKey: 'ModifiedOn' },
            { title: 'Status', dataKey: 'Status' },
            { title: 'Created By', dataKey: 'CreatedName' },
            { title: 'Edited By', dataKey: 'ModifiedName' },
        ];
        HelperService.generatePdf(this.measureProtocols, columns, 'Measure Protocol list', pdfSetting, 'l');
    }

    /** Trigger complete measure protocol **/
    complete(mpId) {
        this.subscriptions.push(this.measureprotocolservice
            .getMeasureProtocolById(mpId, this.parentId)
            .subscribe((result: any) => {
                result
                if (result != "") {
                    let date = new Date();
                    let formatted_date = this.datePipe.transform(date, "yyyy-MM-dd-HH-mm-ss");
                    let cmp = new CMP();
                    cmp.Id = 0;
                    cmp.Name = result.Name + '-' + formatted_date;
                    cmp.Number = result.Number;
                    cmp.MeasureProtocolId = result.Id;
                    cmp.KundeELId = this.parentId;
                    cmp.Status = 1;
                    cmp.CreatedOn = new Date();
                    cmp.CreatedBy = BaseServices.UserId;
                    cmp.FeatureId = FeatureKey.KUNDE_EL;
                    this.subscriptions.push(this.measureprotocolservice
                        .createCMP(cmp.MeasureProtocolId, cmp)
                        .subscribe((result: any) => {
                            if (result) {
                                this.subscriptions.push(this.measureprotocolservice
                                    .getAllInstruments(result.MeasureProtocolId)
                                    .subscribe(instresult => {
                                        instresult
                                        if (instresult != "") {
                                            var instru = instresult;
                                            for (var inst in instru) {
                                                let instrument = new Instrument();
                                                instrument.Id = 0;
                                                instrument.InstrumentTypeId = instresult[inst].InstrumentTypeId;
                                                instrument.InstrumentModelId = instresult[inst].InstrumentModelId;
                                                instrument.CalibratedDate = instresult[inst].CalibratedDate;
                                                instrument.MeasureProtocolCompleteId = result.Id;
                                                instrument.Status = "1";
                                                instrument.CreatedBy = BaseServices.UserId;
                                                instrument.ModifiedBy = BaseServices.UserId;
                                                instrument.CreatedOn = new Date();
                                                instrument.ModifiedOn = new Date();

                                                this.subscriptions.push(this.measureprotocolservice
                                                    .createCMPInstrument(instrument)
                                                    .subscribe(result => {
                                                        this.toasterComponent.callToast();
                                                    }));
                                            }
                                        }
                                    }))
                                this.getAllCompleteMeasureProtocols();
                                this.toasterComponent.callToast();
                            }
                        }));
                }

            }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}

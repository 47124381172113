import { TranslateService } from '@ngx-translate/core';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseServices } from './../../kuba.services';
import { MaterialItemLookup } from '../models/material-item-lookup';
import { MaterialItem } from '../models/material-item';
import { FDVDocumentList } from '../models/fdv-document-list';
import { TreeData } from './../../common/models/tree-data.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FdvDocumentServices {

    featureId: number;
    workingModule: any;
    constructor(private http: HttpClient,
        private translate: TranslateService) {
        this.workingModule = JSON.parse(
            sessionStorage.getItem('workingModule')
        );
        this.featureId = this.workingModule.id;
    }

    uploadEFO(appId: number, businessId: number, featurekey: number,filename: string, efoJson: string) {
        let sData = {
            ApplicationId: +appId,
            BusinessId: +businessId,
            UserId: +BaseServices.UserId,
            ProjectId: +featurekey,
            EfoJsonData: efoJson,
            FileName: filename
        };
        return this.http
            .post(
                `${
                environment.BASE_URL
                }/project/efodocument/materiallookup/save`,
                sData,BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));

    }

    saveOwnDocumentUploadedFile(itemList: MaterialItemLookup[]) {
        return this.http.post(environment.BASE_URL + '/project/owndocument/materiallookup/save', itemList, BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    SaveOwnDocumentMaterialItem(itemList: MaterialItem[]) {
        return this.http.post(environment.BASE_URL + '/project/owndocument/materialitem/save', itemList, BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    GetFDVEFODocument(applicationId: number, businessId: number, projectId: number) {
        return this.http
            .get(
                environment.BASE_URL +
                '/project/efodocumentlist/get/' +
                applicationId +
                '/' +
                businessId +
                '/' +
                projectId,BaseServices.headerOption
            )
            .map(result => result);
    }

    DeleteFDVFfoDocument(fDVDocumentList: any[]) {
        return this.http.put(environment.BASE_URL + '/project/efodocumentlist/delete',
            fDVDocumentList, BaseServices.headerOption).map((response: any) => { return response });
    }

    GetForvaltningELDocument(featureId: number, businessId: number, projectId: number) {
        return this.http
            .get(
                environment.BASE_URL +
                '/feature/article/forvaltningELArticle/' +
                projectId +
                '/' +
                businessId,BaseServices.headerOption
            )
            .map(result => result);
    }

    GetFDVCommonDocument(featureId: number, businessId: number, projectId: number) {
        let opt = BaseServices.headerOption
        let lang = BaseServices.userCultureInfo(this.translate.currentLang);
        return this.http
            .get(
                environment.BASE_URL +
                '/project/commondocumentlist/get/' +
                featureId +
                '/' +
                businessId +
                '/' +
                projectId + '/' + lang,
                opt
            )
            .map(result => result);
    }

    DeleteFDVCommonDocunet(commonList: any, projectId: number) {
        return this.http.put(`${environment.BASE_URL}/project/commondocumentlist/delete/${
            projectId}`,
            commonList,
            BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    DeleteFDVCommonDocunetList(commonList: any[], projectId: number) {
        return this.http.put(`${environment.BASE_URL}/project/commondocumentlist/list/delete/${
            projectId}`,
            commonList,
            BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    GetFDVOwnDocument(applicationId: number, businessId: number, projectId: number) {
        return this.http
            .get(
                environment.BASE_URL +
                '/project/owndocument/owndocumentlist/get/' +
                applicationId +
                '/' +
                businessId +
                '/' +
                projectId,BaseServices.headerOption
            )
            .map(result => result);
    }

    getProjectFDVStatus(pId: number) {
        return this.http.get(environment.BASE_URL + '/project/fdvStatus/' + pId, BaseServices.headerOption)
            .map(result => result);
    }


    SaveFDVEFODocumentList(fDVDocumentList: FDVDocumentList[], businessId: number, projectId: number) {

        return this.http.put(`${environment.BASE_URL}/project/efodocumentlist/save/${
            businessId}/${projectId
            }`,
            fDVDocumentList,
            BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    SaveFDVOwnDocumentList(fDVDocumentList: FDVDocumentList[]) {
        return this.http.put(environment.BASE_URL + '/project/owndocument/owndocumentlist/save',
            fDVDocumentList, BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    DeleteFDVOwnDocument(fDVDocumentList: FDVDocumentList) {
        return this.http.put(environment.BASE_URL + '/project/owndocument/owndocumentlist/delete',
            fDVDocumentList, BaseServices.headerOption).map((response: any) => { return response });
    }

    SaveProjectPhotoMaterialItem(itemList: MaterialItem[]) {
        return this.http.post(environment.BASE_URL + '/project/photoproject/materialitem/save', itemList, BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    GetFDVProjectPhoto(applicationId: number, businessId: number, projectId: number) {
        return this.http
            .get(
                environment.BASE_URL +
                '/project/photoproject/photoprojectlist/get/' +
                applicationId +
                '/' +
                businessId +
                '/' +
                projectId,BaseServices.headerOption
            )
            .map(result => result);
    }

    SaveFDVPhotoList(fDVPhotoProjectList: any[]) {
        return this.http.put(environment.BASE_URL + '/project/photoproject/photodocumentlist/save',
            fDVPhotoProjectList, BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    SaveForvaltningEL(forvaltningList: any[], projectId: number, businessId: number, userId: number) {
        return this.http.put(`${environment.BASE_URL}/project/forvaltning/forvaltningList/save/${
            projectId}/${businessId}/${userId}`,
            forvaltningList,
            BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    DeleteFDVPhotoProject(id: any) {
        return this.http.delete(environment.BASE_URL + '/project/photoproject/photoprojectitem/delete/' +
            id,BaseServices.headerOption
        ).map((response: any) => { return response });
    }

    ReplaceEFODocument(list: any) {
        return this.http.put(environment.BASE_URL + '/project/commondocumentlist/replaceEFODocument/save',
            list, BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    ReplaceEFODocumentWithOwnDocument(efoItemId: number, ownDocument: string) {
        return this.http.put(environment.BASE_URL + '/project/commondocumentlist/replaceEFOWithOwnDoc/save/' +
            efoItemId + '/' + ownDocument,
            BaseServices.headerOption)
            .map((response: any) => { return response });
    }


    generateForesSideReport(reportData: any, businessId: number, cultureInfo: string, projectId: number, type: number) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: reportData });
        let url = `${environment.BASE_URL}/FDVForeSide/${businessId}/${projectId}/${type}/${cultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }

    generateInnholdsfortegnelse(reportData: any, businessId: number, cultureInfo: string, projectId: number) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: reportData });
        let url = `${environment.BASE_URL}/Innholdsfortegnelse/${businessId}/${projectId}/${cultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }
    
    generateIndex(reportData: any, businessId: number, cultureInfo: string, projectId: number) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: reportData });
        let url = `${environment.BASE_URL}/Index/${businessId}/${projectId}/${cultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }

    generateProjectDetailReport(reportData: any, businessId: number, cultureInfo: string, projectId: number) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: reportData });
        let url = `${environment.BASE_URL}/export-ProjectDetails/${projectId}/${businessId}/${cultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }

    generateExportMaterialItemFile(reportData: any, businessId: number, cultureInfo: string, projectId: number) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: reportData });
        let url = `${environment.BASE_URL}/exportMaterialItemFile/${businessId}/${projectId}/${cultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }

    generateFDVBulkDownLoad(reportData: any, businessId: number, cultureInfo: string,filename: string, projectId: number, type: number) {
        let myData = JSON.stringify({ data: reportData });
        return this.http.post(environment.BASE_URL + '/fdvBulkDownLoad/'+businessId+'/'+ projectId +'/'+type +'/'+filename+'/'+cultureInfo, myData, BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    generateArticleFile(reportData: any, aId: number, fkey: number, businessId: number, cultureInfo: string) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: reportData });
        let url = `${environment.BASE_URL}/export-ElArticle/${aId}/${this.featureId}/${fkey}/${businessId}/${cultureInfo}`;

        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }

    generateNoEFODocument(reportData: any, itemName: string, type: number, businessId: number) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: reportData });
        let url = `${environment.BASE_URL}/noFDVELDocument/${itemName}/${type}/${businessId}`;

        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }

}

import { BaseServices } from './../../kuba/kuba.services';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class KubaArticleViewerService {
    constructor(private http: HttpClient) {

    }
    // Generate pdf report for article
    generateArticleFile(reportData: any, businessId: number, cultureInfo: string, portalId: number, projectId: number) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: reportData });
        let url = `${environment.BASE_URL}/export-article/${businessId}/${cultureInfo}/${portalId}/${projectId}`;
        return Observable.create((observer:any) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }

    getCKEditorImageFolderList(applicationId: number, portalId: number, businessId: number) {
        return this.http
            .get(
                environment.BASE_URL + '/manual/0/article/ck-editor/getfolder/' + applicationId
                + '/' + portalId + '/' + businessId, BaseServices.headerOption).pipe(map(result => result));
    }

    SaveCKEditorImageFolder(folderDetails: any) {
        return this.http
            .post(
                environment.BASE_URL + '/manual/0/article/ck-editor/savefolder',
                folderDetails, BaseServices.headerOption
            )
            .pipe(map(result => result));
    }

    DeleteCKEditorImageFolder(folderId: number, userId: number) {
        
        return this.http
            .delete(
                environment.BASE_URL + '/manual/0/article/ck-editor/deletefolder/' + folderId
                + '/' + userId, BaseServices.headerOption).pipe(map(result => result));
    }

    getCKEditorImageList(applicationId: number, businessId: number, portalId: number, folderId: number) {
        
        return this.http
            .get(
                environment.BASE_URL + '/manual/0/article/ck-editor/getfile/' + applicationId
                + '/' + businessId + '/' + portalId + '/' + folderId, BaseServices.headerOption).pipe(map(result => result));
    }

    RenameCKEditorImage(fileDetails: any) {
        return this.http
            .put(
                environment.BASE_URL + '/manual/0/article/ck-editor/renameImageFile',
                fileDetails, BaseServices.headerOption
            ).pipe(
            map(result => result));
    }

    DeleteCKEditorImage(fileDetails: any) {
        return this.http
            .put(
                environment.BASE_URL + '/manual/0/article/ck-editor/deleteImageFile',
                fileDetails, BaseServices.headerOption
            ).pipe(
            map(result => result));
    }


    SaveCKEditorImage(fileDetails: any) {
        return this.http
            .post(
                environment.BASE_URL + '/manual/0/article/ck-editor/saveImage',
                fileDetails, BaseServices.headerOption
            ).pipe(
            map(result => result));
    }
}

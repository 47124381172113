import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Accesslog, ApplicationUi, LoggedUser } from '../_models/';
import { BaseServices } from './../kuba/kuba.services';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class UiServices {
    applicationUi: ApplicationUi;
    _accesslog: Accesslog;
    _loggedUser = new LoggedUser();

    constructor(private http: HttpClient
        ) {}

    private createAuthorizationHeader(headers: HttpHeaders) {
        headers.append('content-type', 'application/json');
    }

    public getApplicationLanguages() {
        return this.http
            .get(
                environment.BASE_URL + '/master/languages',

            )
            .map(result => result);
    }

    public setApplicationAppearance(appearance: ApplicationUi) {
        this.applicationUi = appearance;
        return true;
    }

    public getApplicationAppearance() {
        return this.applicationUi;
    }

    public SaveAccessLog() {
        this._loggedUser = JSON.parse(sessionStorage.getItem('loggedUser')!);
    }
}

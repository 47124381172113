import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';

import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { InstrumentList } from '../../models/measure-protocol';
import { MeasureProtocolService } from '../../services/measure-protocol.services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'measure-protocol-instrument-list',
    templateUrl: 'measure-protocol-instrument-list.component.html'
})

export class MeasureProtocolInstrumentListComponent implements OnInit {

    //#region variables
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    measureProtocolInstrumentList: FormGroup;
    instruments: InstrumentList[];
    instId: any;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructors
    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private confirmationService: ConfirmationService,
        public measureprotocolservice: MeasureProtocolService,
        private location: Location,
        private translate: TranslateService) {
        this.instId = route.snapshot.parent.params['mid'];
        this.measureProtocolInstrumentList = this.fb.group({
            'InstrumentTypeId': ['', Validators.required],
            'InstrumentModelId': ['', Validators.required],
            'CreatedOn': [''],
        });
    }
    //#endregion

    //#region page-events
    /**
     * page load event
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.instruments = [];
        this.instruments = this.route.snapshot.data['listInst'];
    }
    //#endregion

    //#region methods
    /**
     * get all instrument list
    */
    getAllInstruments() {
        this.subscriptions.push(this.measureprotocolservice
            .getAllInstruments(this.instId)
            .subscribe((result: any) => this.instruments = result));
    }

    /**
     * delete instrument based on id
     * @param  mpIn
    */
    deleteInstrument(instrumentId) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.measureprotocolservice.deleteInstrument(instrumentId).subscribe(result => {
                    this.getAllInstruments();
                    this.toasterComponent.callToastDlt();
                }));
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}


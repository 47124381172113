import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { CommonChecklistEdit, CommonChecklistItem, CommonChecklistList } from 'src/app/kuba/common/models';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CommonChecklistService {
    constructor(private http: HttpClient) {}

    getManualChecklist(
        manualId: number,
        levelId: number,
        levelKey: number,
        parentFolderId: number
    ): Observable<CommonChecklistList[]> {
        return this.http
            .get<any[]>(
                `${
                    environment.BASE_URL
                }/manual/${manualId}/checklist/${levelId}/${levelKey}/${parentFolderId}`,

            )
            .map(result => {
                return result;
            });
    }

    getChecklist(
        manualId: number,
        checklistId: number,
        type: string
    ) {
        if (type.toLocaleUpperCase() === 'MANUAL') {
            let res: any = this.http
                .get<any>(
                    `${
                        environment.BASE_URL
                    }/manual/${manualId}/checklist/${checklistId}`,

                )
                .map(result => {
                    return result;
                });
                return res;
        }
    }

    add(
        commonChecklist: CommonChecklistEdit,
        type: string
    ) {
        if (type.toLocaleUpperCase() === 'MANUAL') {
            let res: any = this.http
                .post<any>(
                    `${environment.BASE_URL}/manual/${
                        commonChecklist.AppManualId
                    }/checklist/add`,
                    commonChecklist,

                )
                .map(result => {
                    return result;
                });
                return res;
        }
    }

    update(
        commonChecklist: CommonChecklistEdit,
        type: string
    ) {
        if (type.toLocaleUpperCase() === 'MANUAL') {
            let res:any = this.http
                .put<any>(
                    `${environment.BASE_URL}/manual/${
                        commonChecklist.AppManualId
                    }/checklist/update`,
                    commonChecklist,

                )
                .map(result => {
                    return result;
                });
                return res;
        }
    }

    delete(Id: number, appManualId: number) {
        return this.http
            .delete(
                `${
                    environment.BASE_URL
                }/manual/${appManualId}/checklist/delete/${Id}`,

            )
            .map(result => {
                return result;
            });
    }

    deleteAllItems(
        Id: number,
        appManualId: number,
        commonChecklistItem: CommonChecklistItem[]
    ) {
        return this.http
            .put(
                `${
                    environment.BASE_URL
                }/manual/${appManualId}/checklist/item/deleteAll/${Id}`,
                commonChecklistItem,

            )
            .map(result => {
                return result;
            });
    }

    updateCheckListItem(
        Id: number,
        appManualId: number,
        commonChecklistItem: CommonChecklistItem[],
        manualRecords: string
    ) {
        return this.http
            .put(
                `${
                    environment.BASE_URL
                }/manual/${appManualId}/checklist/item/updateAll/${Id}`,
                manualRecords,

            )
            .map(result => {
                return result;
            });
    }

    getCheckListforTemplate(
        appManualId: Number,
        appId: number,
        portalId: number,
        businessId: number,
        roleId: number
    ) {
        return this.http
            .get(
                `${
                    environment.BASE_URL
                }/manual/${appManualId}/checklist/checkListForTemplate/${appId}/${portalId}/${businessId}/${roleId}/`,

            )
            .map((result: any) => {
                return result;
            });
    }

    copyCheckList(commonChecklist): Observable<any> {
        return this.http
            .put(
                `${environment.BASE_URL}/manual/${
                    commonChecklist.AppManualId
                }/checklist/CopyCheckList`,
                commonChecklist,

            )
            .map(result => {
                return result;
            });
    }

    deleteCheckList(appManualId: number, checklistId: number) {
        return this.http
            .put(
                `${
                    environment.BASE_URL
                }/manual/${appManualId}/checklist/deleteCheckList/${checklistId}`,
              {}
            )
            .map(result => {
                return result;
            });
    }
}

import { ToasterComponent } from './../../../_directives/toaster.component';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Toast } from 'angular2-toaster';
import { BaseServices } from './../../kuba.services';
import { ManualService } from './../../manuals/services/manual.services';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, ViewChild, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { TreeView } from './../models/index';
import { CommonService } from './../services/common.services';
import { TreeComponent, TreeModel, TREE_ACTIONS, KEYS, IActionMapping } from '@circlon/angular-tree-component';
import { MenuItem, ConfirmationService, TreeNode } from 'primeng/api';
import { TreeData } from './../models/index';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ContextMenu } from 'primeng/contextmenu';
import { Subscription } from 'rxjs';

@Component({
  selector: 'common-tree',
  templateUrl: 'common-tree.component.html',
  providers: [ConfirmationService]
})
export class CommonTreeComponent implements OnInit, AfterViewInit {

  // Call tree Component method from angular-tree-component
  @ViewChild('tree',{static:false}) tree: TreeComponent;
  @ViewChild(ToasterComponent,{static:false}) toasterComponent: ToasterComponent;
  // Call context menu method from primeng
  @ViewChild('displayContext',{static:false}) contextMenu: ContextMenu;

  // Declarations
  @Input('treeData')
  set treeData(data: TreeView) {
    this._treeData = data;
    if (this._treeData.data) {
      this._treeData.data = this.customizeNode(this._treeData.data);
    }
  }

  get treeData(): TreeView {
    return this._treeData;
  }

  @Output() onTreeNodeClick = new EventEmitter<any>();
  @Output() onNodeDrag = new EventEmitter<any>();

  // Tree Configuration
  optionsTreeNode: any;
  actionMapping: IActionMapping = {
    mouse: {
      contextMenu: (tree, node, $event) => {
        if (!this.treeData.isCommonMode && this.treeData.isEditMode) {
          this.contextMenu.model = this.contextMenuItem(node);
          this.contextMenu.position($event);
          this.contextMenu.show();
          $event.preventDefault();
        }

      },
      dblClick: (tree, node, $event) => {
        if (node.hasChildren) { TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event) };
      },
      drop: (tree: TreeModel, node: TreeNode, $event: any, { from, to }) => {
        // use from to get the dragged node.
        // use to.parent and to.index to get the drop location
        // use TREE_ACTIONS.MOVE_NODE to invoke the original action
      },
    }
  };

  // Tree View option
  optionsView = {
    actionMapping: this.actionMapping,
    animateExpand: true,
    animateSpeed: 30,
    animateAcceleration: 1.2,
    allowDrag: true,
    allowDrop: false
  }

  // Tree Edit option
  optionsEdit = {
    actionMapping: this.actionMapping,
    animateExpand: true,
    animateSpeed: 30,
    animateAcceleration: 1.2,
    allowDrag: (node) => {
      return (this.treeData.isEditMode && !node.data.isDefault) ? true : false;
    },
    allowDrop: true

  }

  renameForm: FormGroup;
  private _treeData: TreeView;
  treeSearch = false;
  treeNode: TreeNode;
  levelId = 0;
  levelKey = 0;
  toast: Toast;
  showDialog: boolean = false;
  isValidTitle = true;
  isNameExists = false;
  existsAlerttext: string;
  headerActiontext: string;
  actionName: string;
  typeContext: string;;
  private checkbox: boolean;
  private subscriptions: Subscription[] = [];

  // Constructor parameter declaration
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private commonServices: CommonService,
    private confirmationService: ConfirmationService,
    private manualServices: ManualService,
    private translate: TranslateService
  ) { }

  // Pageload
  ngOnInit() {
    let value = this.treeData;
    if (this.treeData.type.toLocaleUpperCase() === 'MANUAL' && this.treeData.isCommonMode) {
      this.optionsTreeNode = this.optionsView;
    } else {
      this.optionsTreeNode = this.optionsEdit;
    }

    switch (BaseServices.UserRole) {
      case 'Admin':
        this.levelId = 1;
        this.levelKey = BaseServices.ApplicationId;
        break;
      case 'Portal':
        this.levelId = 2;
        this.levelKey = BaseServices.UserId;
        break;
      case 'Editor':
        this.levelId = 3;
        this.levelKey = BaseServices.BusinessId;
        break;
    }

    this.renameForm = this.formBuilder.group({
      'title': ['', Validators.required]
    });

    this.subscriptions.push(this.renameForm.get('title').valueChanges
      .subscribe((newTitle: string) => {
        this.checkTitleValidate(newTitle);
      }));
  }

  ngAfterViewInit() {
    if (this.treeData.type.toLocaleUpperCase() === 'MANUAL' || this.treeData.isEditMode)
      this.filterTreeData(this.treeData.isEditMode);
  }

  customizeNode(treeData: TreeData[]) {
    return <TreeData[]>treeData.map(x => {
      //Change Article folder to manual name
      if (x.isDefault && x.title === 'Articles') {
        x.title = this._treeData.name;
      }
      // Check Parent partial check status
      let partialChildUnCheck = 0;
      x.children.forEach(c => {
        if (c.checked == false) {
          partialChildUnCheck++;
        }
      });
      x.checked = partialChildUnCheck > 1 ? null : x.checked;
      return x;
    });
  }

  // Event trigger when tree node selected
  nodeSelect(node) {
    if (!this.treeData.isCommonMode) {
      let data = node.data;
      if (data) {
        this.onTreeNodeClick.emit(data);
      }
    }
  }

  // Event trigger when tree drop & drop done
  onNodeDrop(event) {
    this.onNodeDrag.emit(event);
  }


  public check(node, $event) {
    let checkedValue = ($event.value == null) ? true : $event.value;
    let nodeData = new TreeData();
    nodeData = node.data;
    nodeData.checked = checkedValue;
    nodeData.modifiedBy = BaseServices.UserId;
    this.subscriptions.push(this.commonServices.updateCheckNode(nodeData, this.treeData).subscribe(result => result));

    this.updateChildNodesCheckBox(node, checkedValue);
    this.updateParentNodesCheckBox(node.parent);
  }
  public updateChildNodesCheckBox(node, checked) {
    node.data.checked = checked;
    if (node.children) {
      node.children.forEach((child) => this.updateChildNodesCheckBox(child, checked));
    }
  }
  public updateParentNodesCheckBox(node) {
    if (node && node.level > 0 && node.children) {
      let allChildChecked = true;
      let noChildChecked = true;

      for (let child of node.children) {
        if (!child.data.checked) {
          allChildChecked = false;
        } else if (child.data.checked) {
          noChildChecked = false;
        }
      }

      if (allChildChecked) {
        node.data.checked = true;
        node.data.indeterminate = false;
      } else if (noChildChecked) {
        node.data.checked = false;
        node.data.indeterminate = false;
      } else {
        node.data.indeterminate = true;
        node.data.checked = null;
      }
      this.updateParentNodesCheckBox(node.parent);
    }
  }

  // Title search for Tree view
  onKey(text) {
    if (text) {
      this.tree.treeModel.filterNodes((node) => {
        return ((node.data.isAccess && (node.data.checked === true || node.data.checked === null)) || this.treeData.isEditMode)
          && (node.data.title.toUpperCase().indexOf(text.toUpperCase()) !== -1
            || node.data.title.toUpperCase().startsWith(text.toUpperCase()));
      });
    } else {
      this.filterTreeData(this.treeData.isEditMode);
    }
  }
  // Tree node search method
  filterSearch() {
    this.treeSearch = !this.treeSearch;
    if (!this.treeSearch) {
      this.filterTreeData(this.treeData.isEditMode);
      this.tree.treeModel.expandedNodeIds = {};
    }
  }

  // Context menu data initialization
  contextMenuItem(node: any): MenuItem[] {
    let item = [];
    let addItem = { label: 'Add new folder', icon: 'pi-folder-open', command: (event) => this.actionNode(node, 'add') };
    let sub_addItem = { label: 'Add sub folder', icon: 'pi-folder', command: (event) => this.actionNode(node, 'add-sub') };
    let renameItem = { label: 'Rename', icon: 'pi-pencil', command: (event) => this.actionNode(node, 'rename') };
    let deleteItem = { label: 'Delete', icon: 'pi-trash', command: (event) => this.actionNode(node, 'delete') };

    if (!node.data.isFileType) {
      item.push(addItem);
      item.push(sub_addItem);
    }
    if (!node.data.isDefault) {
      // Business Level
      if ((this.levelId == node.data.levelId && !node.data.isCommon)) {
        item.push(renameItem);
        item.push(deleteItem);
      }
      // Admin & Portal Level
      if ((this.levelId == 1 || this.levelId == 2) && node.data.createdUserType == BaseServices.roleId) {
        item.push(renameItem);
        item.push(deleteItem);
      }
    }
    return item;
  }

  // Context menu action handle method
  actionNode(node: TreeNode, type: string) {
    this.renameForm.get('title').patchValue('');
    let fileType = !node.data.isFileType ? 'folder' : 'file';
    this.treeNode = node;
    this.typeContext = type;
    switch (this.typeContext) {
      case 'add':
        this.confirmationService.confirm({
          message: `Are you sure you want to create new folder?`,
          icon: 'pi-folder',
          accept: () => {
            this.headerActiontext = `Add new ${fileType}`;
            this.showDialog = true;
            this.actionName = 'Create Folder';
          }
        });
        break;
      case 'add-sub':
        this.confirmationService.confirm({
          message: `Are you sure you want to create sub folder under ${node.data.title} ${fileType}?`,
          icon: 'pi-folder-open',
          accept: () => {
            this.headerActiontext = `Add sub ${fileType}`;
            this.showDialog = true;
            this.actionName = 'Create Sub Folder';
          }
        });
        break;
      case 'rename':
        this.headerActiontext = `Rename ${fileType}`;
        this.renameForm.get('title').patchValue(node.data.title);
        this.showDialog = true;
        this.actionName = 'Rename';
        break;
      case 'delete':
        this.actionName = 'Delete';
        this.confirmationService.confirm({
          message: `Are you sure you want to delete this ${node.data.title} ${fileType}?`,
          icon: 'pi-trash',
          accept: () => {
            this.saveContextAction();
          }
        });
        break;
      default:
        break;
    }
  }

  checkTitleValidate(newTitle) {
    this.isNameExists = false;
    if (newTitle) {
      if (this.treeNode.data.title == newTitle) {
        this.isValidTitle = false;
      } else {
        this.isValidTitle = true;
        switch (this.typeContext) {
          case 'add-sub':
            this.isNameExists = this.treeNode.children.findIndex(x => x.data.title === newTitle && x.data.isFileType === this.treeNode.data.isFileType) > -1;
            break;
          case 'add':
            this.isNameExists = this.treeNode.parent.children.findIndex(x => x.data.title === newTitle && x.data.isFileType === this.treeNode.data.isFileType) > -1;
            break;
          case 'rename':
            this.isNameExists = this.treeNode.parent.children.findIndex(x => x.data.title === newTitle && x.data.isFileType === this.treeNode.data.isFileType) > -1;
            break;
        }
        if (this.isNameExists) {
          this.isValidTitle = false;
          let fileType = !this.treeNode.data.isFileType ? 'folder' : 'file';
          this.existsAlerttext = `${ newTitle } ${ fileType } name already exists`;
        }
      }
    }
  }

  saveContextAction() {
    let nodeData = new TreeData();
    nodeData = _.clone(this.treeNode.data);
    this.toast = this.popToastMessage(nodeData, this.typeContext);
    switch (this.typeContext) {
      case 'add-sub':
        nodeData.title = this.renameForm.value.title;
        nodeData.parentFolderId = this.treeNode.data.nodeId;
        this.addNode(nodeData);
        break;
      case 'add':
        let parentId = this.treeNode.parent.data.treeParentId ? this.treeNode.parent.data.nodeId : 0;
        nodeData.title = this.renameForm.value.title;
        nodeData.parentFolderId = parentId;
        this.addNode(nodeData);
        break;
      case 'rename':
        this.treeNode.data.title = nodeData.title = this.renameForm.value.title;
        nodeData.modifiedBy = BaseServices.UserId;
        this.subscriptions.push(this.commonServices.renameNode(nodeData, this.treeData).subscribe(result => {
          if (result) {
            this.showDialog = false;
            this.toasterComponent.callToast();
            this.tree.treeModel.update();
          }
        }));

        break;
      case 'delete':
        nodeData.status = 0;
        nodeData.modifiedBy = BaseServices.UserId;
        this.subscriptions.push(this.commonServices.deleteNode(nodeData, this.treeData).subscribe(result => {
          if (result) {
            this.toasterComponent.callToast();
            _.remove(this.treeNode.parent.data.children, this.treeNode.data);
            this.tree.treeModel.update();
          }
        }));
        break;
    }
  }

  addNode(nodeData: TreeData) {
    let mid = this.activatedRoute.snapshot.params['mid'];
    let levelId = this.activatedRoute.snapshot.params['levelId'];
    let levelKey = this.activatedRoute.snapshot.params['levelKey'];
    this.toast = this.popToastMessage(nodeData, this.typeContext);
    nodeData.nodeId = 0;
    nodeData.isDefault = false;
    nodeData.status = 1;
    nodeData.createdBy = BaseServices.UserId;
    nodeData.createdOn = null;
    nodeData.modifiedBy = null;
    nodeData.modifiedOn = null;
    nodeData.isCommon = false;

    // Admin level
    if (this.levelId == 1 && (levelId == 1 || levelId == 2)) {
      nodeData.isCommon = true;
    }

    //Portal level
    if (this.levelId == 2 && this.levelId == levelId) {
      nodeData.isCommon = true;
    }

    this.subscriptions.push(this.commonServices.addNode(nodeData, this.treeData).subscribe(result => {
      if (result) {
        this.showDialog = false;
      }
    }));
  }

  childrenCount(node: TreeNode): string {
    let count = '';
    if (node && node.children && node.children.length > 0) {
      if (!this.treeData.isEditMode) {
        let filterCount = node.children.filter(x => x.data.isAccess && (x.data.checked === true || x.data.checked === null)).length;
        if (filterCount > 0) {
          count = `(${ filterCount })`;
        }
      } else {
        count = `(${ node.children.length })`;
      }
    }
    return count;
  }


  public filterTreeData(isEdit: boolean = false) {
    if (isEdit) {
      this.tree.treeModel.clearFilter();
    } else {
      this.tree.treeModel.filterNodes((node) => {
        return (node.data.isAccess && (node.data.checked === true || node.data.checked === null));
      }, false);
    }
  }

  popToastMessage(node: TreeData, type: string): Toast {
    let fileType = !node.isFileType ? 'folder' : 'file';
    let mode: string;
    if (node) {
      switch (type) {
        case 'rename':
          mode = 'renamed';
          break;
        case 'delete':
          mode = 'deleted';
          break;
        case 'add-sub' || 'add':
          mode = 'created';
          break;
      }
      let body = `${ node.title } ${ fileType } ${ mode } successfully`;
      var toast: Toast = {
        type: 'success',
        title: 'Update Manual Data',
        body: body
      };
      return toast;
    }
    return null;

  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }

}

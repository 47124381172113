import { Feature } from './../../../_models/feature';
import { FeatureService } from './../services/feature.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'feature',
    templateUrl: 'feature.component.html'
})
export class FeatureComponent implements OnInit {
    features: any;
    files: Feature[];
    private subscription: Subscription;

    constructor(private featureService: FeatureService) { }

    ngOnInit() {
        let parents;
        this.subscription = this.featureService.getById(1).subscribe(res => {
            this.features = res;
        })
    }

    onTabOpen(index, currentState) {
        let data = this.features[index];
        this.features[index].IsChecked = currentState;
        if (data.Widgets) {
            this.features[index].Widgets =
                data.Widgets.map(x => {
                    x.IsChecked = currentState;
                    return x;
                })

        } else if (data.AdditionFeatures) {
            this.features[index].AdditionFeatures =
                data.AdditionFeatures.map(x => {
                    x.IsChecked = currentState;
                    return x;
                })
        }
    }

    onTabClose(e) {
        let index = e.index;
        let data = this.features[index];
        if (data.Widgets) {
            this.features[index].Widgets =
                data.Widgets.map(x => {
                    x.IsChecked = false;
                    return x;
                })

        } else if (data.AdditionFeatures) {
            this.features[index].AdditionFeatures =
                data.AdditionFeatures.map(x => {
                    x.IsChecked = false;
                    return x;
                })
        }
    }
    saveFeatures(data) {
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}

import { ValidationService } from 'src/app/kuba/shared/services/validation.service'; /* Not used */
import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ToasterComponent } from '../_directives/toaster.component';
import { AlertService } from '../_services/alert.service';
import { AuthenticationService } from './../_services/authentication.service';

import { UserCredential } from './../kuba/users/models/user';
import { BaseServices } from './../kuba/kuba.services'; 
import { Subscription } from 'rxjs';

@Component({
    selector: 'forget-password',
    templateUrl: 'forget-password.component.html',
    styleUrls: ['forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
    @ViewChild(ToasterComponent, {static: false}) toasterComponent: ToasterComponent;
    userCredentials = new UserCredential();
    ForgotPasswordForm: FormGroup;
    isNotValid = false;
    model: any = {};
    mailSent = false;
    loading = false;
    windowOrigin: string;
    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private _fb: FormBuilder,
        private translate: TranslateService,
        private renderer: Renderer2

    ) { }

    ngOnInit() {
        this.ForgotPasswordForm = this._fb.group({
            checkUser: this._fb.group({
                username: ['', [Validators.required, Validators.nullValidator]],
            })
        });
        this.renderer.addClass(document.body.parentElement, 'loginView');
        this.renderer.addClass(document.body, 'loginView');
    }

    sendMail() {
        this.loading = true;
        let medi3: number | null = null; // Initialize it with null or an initial value
        const substringValue = window.location.pathname.substring(window.location.pathname.indexOf('medi3') + 0, window.location.pathname.indexOf('medi3') + 5);
        if (substringValue === 'medi3') {
            medi3 = 5;
        } else {
           medi3=1
        }       
        this.userCredentials.UserName = this.ForgotPasswordForm.value.checkUser.username;
        this.subscriptions.push(this.authenticationService
            .forgetPassword(this.userCredentials)
            .subscribe(result => {
                if (result === false) {
                    this.loading = false;
                    this.isNotValid = true;
                    setTimeout(() => {
                        this.isNotValid = false;
                    }, 5000);
                } else {
                    this.subscriptions.push(this.authenticationService
                        .sendPasswordLink(this.userCredentials,medi3)
                        .subscribe(result => {
                            if (result) {
                                this.loading = false;
                                this.mailSent = true;
                                setTimeout(() => {
                                    this.mailSent = false;
                                }, 5000);
                            }
                        }));
                }
            }));
        this.clear();
    }
    clear() {
        this.isNotValid = false;
        this.mailSent = false;
        this.ForgotPasswordForm = this._fb.group({
            checkUser: this._fb.group({
                username: '',
                emailId: ''
            })
        });
    }
    ngOnDestroy() {
        this.renderer.removeClass(document.body.parentElement, 'loginView');
        this.renderer.removeClass(document.body, 'loginView');

        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
          });
    }
}

import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SearchResultServices {
    parentKey: any;
    constructor(private http: HttpClient, private route: ActivatedRoute) {
        this.parentKey = this.route.snapshot.parent.data['parent'];
    }

    getDeviationList(id: number, key: string) {
        return this.http
            .get(
                `${environment.BASE_URL}/${BaseServices.apiRoute(
                    this.parentKey
                )}/deviationsSearchlist/${id}/${key}`,
            )
            .map((result: any) => result);
    }

    getActivitiesList(id: number, key: string) {
        return this.http
            .get(`${environment.BASE_URL}/activitiesSearchlist/${id}/${key}`, )
            .map((result: any) => result);
    }

    getArticlesList(id: number, key: string) {
        return this.http
            .get(
                `${
                    environment.BASE_URL
                }/feature/tree/featurearticledata/${id}/${key}`,
            )
            .map((result: any) => result);
    }
}

<div class="tab-content">
  <div class="page-title">
    <span translate>TIME_TABLE</span>
    <span class="page-actions pull-right"> </span>
  </div>
  <div class="table-view">
    <!-- Table starts -->
    <p-table
      #timetableTable
      [value]="timetables"
      [rows]="20"
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="rowsPerPageOptions"
      (onFilter)="employeeFilterChange($event)"
      (onPage)="employeePageChange($event)"
      dataKey="Id"
      [(selection)]="selectedTimetable"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
    >
      <!-- Caption -->
      <ng-template pTemplate="caption">
        <div class="ui-helper-clearfix">
          <div class="filter-controls-wrapper" style="display: none">
            <form [formGroup]="TimetableForm">
              <div class="row">
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="Month" class="col-form-label">
                      <span translate>MONTH</span>
                    </label>
                    <div>
                      <p-dropdown
                        [options]="months"
                        (onChange)="onMonthChanged($event, m)"
                        #m
                        formControlName="Month"
                        [style]="{ width: '160px' }"
                      >
                      </p-dropdown>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="Year" class="col-form-label">
                      <span translate>YEAR</span>
                    </label>
                    <div>
                      <p-dropdown
                        type="text"
                        [options]="years"
                        (onChange)="onYearChanged($event, y)"
                        #y
                        formControlName="Year"
                        [style]="{ width: '160px' }"
                      >
                      </p-dropdown>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="department" class="col-form-label">
                      <span translate>FROM_DATE</span>
                    </label>
                    <div>
                      <my-date-picker
                        name="FromDate"
                        [options]="startDateOptions"
                        formControlName="FromDate"
                        placeholder="{{ 'ENTER_THE_START_DATE' | translate }}"
                        (dateChanged)="onTimeTableStartDateChanged($event)"
                      ></my-date-picker>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="department" class="col-form-label">
                      <span translate>END_DATE</span>
                    </label>
                    <div>
                      <my-date-picker
                        name="enddate"
                        [options]="endDateOptions"
                        (dateChanged)="onTimeTableEndDateChanged($event)"
                        formControlName="EndDate"
                        placeholder="{{ 'ENTER_STOP_DATE' | translate }}"
                        required
                      ></my-date-picker>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3" *ngIf="isProject">
                  <div class="form-group">
                    <label for="Project" class="col-form-label">
                      <span translate>ORIGIN</span>
                    </label>
                    <div>
                      <p-dropdown
                        [options]="project"
                        (onChange)="onProjectChange($event, pro)"
                        #pro
                        [style]="{ width: '100%' }"
                      >
                      </p-dropdown>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12">
                  <button
                    class="btn btn-outline-primary"
                    type="submit"
                    (click)="refreshTable()"
                  >
                    <span class="icon ic-sm icon-reset"></span>
                    <span translate>REFRESH</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="table-options">
            <div class="pull-left"></div>
            <div class="pull-right">
              <div class="action-btns-group">
                <button class="btn p-3 filter-toggle">
                  <span class="icon ic-sm icon-filter"></span>
                  <span translate>FILTER</span>
                </button>
              </div>
              <div class="action-btns-group">
                <kuba-export
                  [reportData]="
                    timetableTable.filteredValue || timetableTable.value
                  "
                  [additionalData]="additionalData"
                ></kuba-export>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 55px" *ngIf="isApproveTimetable">
            <p-tableHeaderCheckbox
              [(ngModel)]="headerCheckboxChecked"
              (click)="onHeaderCheckboxClick()"
            ></p-tableHeaderCheckbox>
          </th>

          <th pSortableColumn="ProjectWorkTypeName">
            {{ "TYPE_OF_WORK" | translate }}
            <p-sortIcon field="ProjectWorkTypeName"></p-sortIcon>
          </th>
          <th pSortableColumn="EmployeeName">
            {{ "EMPLOYEE_NAME" | translate }}
            <p-sortIcon field="EmployeeName"></p-sortIcon>
          </th>
          <th pSortableColumn="ProjectName">
            {{ "ORIGIN" | translate }}
            <p-sortIcon field="ProjectName"></p-sortIcon>
          </th>
          <th pSortableColumn="StartDate" width="94px">
            {{ "START_DATE" | translate }}
            <p-sortIcon field="StartDate"></p-sortIcon>
          </th>
          <th pSortableColumn="EndDate" width="94px">
            {{ "END_DATE" | translate }}
            <p-sortIcon field="EndDate"></p-sortIcon>
          </th>
          <th pSortableColumn="StartTime" width="94px">
            {{ "START_TIME" | translate }}
            <p-sortIcon field="StartTime"></p-sortIcon>
          </th>
          <th pSortableColumn="EndTime" width="94px">
            {{ "END_TIME" | translate }}
            <p-sortIcon field="EndTime"></p-sortIcon>
          </th>
          <th pSortableColumn="BreakTime" width="74px">
            {{ "Break" | translate }}
            <p-sortIcon field="BreakTime"></p-sortIcon>
          </th>
          <th pSortableColumn="HoursSpent" width="84px">
            {{ "TOTAL_HOURS_SPENT" | translate }}
            <p-sortIcon field="HoursSpent"></p-sortIcon>
          </th>
          <th pSortableColumn="ApproverNameList">
            {{ "APPROVAL_PERSON" | translate }}
            <p-sortIcon field="ApproverNameList"></p-sortIcon>
          </th>
          <th width="74px">{{ "STATUS" | translate }}</th>
          <th pSortableColumn="Source">
            {{ "SOURCE" | translate }} <p-sortIcon field="Source"></p-sortIcon>
          </th>
          <th *ngIf="isUser" width="55px"></th>
        </tr>
        <tr>
          <th *ngIf="isApproveTimetable" width="36px"></th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                timetableTable.filter(
                  $any($event.target)?.value,
                  'ProjectWorkTypeName',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                timetableTable.filter(
                  $any($event.target)?.value,
                  'EmployeeName',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                timetableTable.filter(
                  $any($event.target)?.value,
                  'ProjectName',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                timetableTable.filter(
                  $any($event.target)?.value,
                  'ApproverNameList',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                timetableTable.filter(
                  $any($event.target)?.value,
                  'StatusText',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                timetableTable.filter(
                  $any($event.target)?.value,
                  'Source',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th *ngIf="isUser"></th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-employeeTimetable let-object="rowData">
        <tr>
          <td style="width: 55px" *ngIf="isApproveTimetable">
            <p-tableCheckbox id="selectedOrders" [value]="employeeTimetable">
            </p-tableCheckbox>
          </td>
          <td>
            <span>
              <a [routerLink]="['../../edit', employeeTimetable.Id]">{{
                employeeTimetable.ProjectWorkTypeName
              }}</a>
            </span>
          </td>
          <td>
            <div
              pTooltip="{{ employeeTimetable.EmployeeName }}"
              tooltipPosition="top"
            >
              {{ employeeTimetable.EmployeeName }}
            </div>
          </td>
          <td>
            <span
              pTooltip="{{ employeeTimetable.ProjectName }}"
              tooltipPosition="top"
              >{{ employeeTimetable.ProjectName }}</span
            >
          </td>
          <td>
            <span pTooltip="{{ employeeTimetable.StartDate }}">{{
              employeeTimetable.StartDate | date : "dd/MM/yyyy"
            }}</span>
          </td>
          <td>
            <span pTooltip="{{ employeeTimetable.EndDate }}">{{
              employeeTimetable.EndDate | date : "dd/MM/yyyy"
            }}</span>
          </td>
          <td>
            <span>{{ employeeTimetable.StartTime }}</span>
          </td>
          <td>
            <span>{{ employeeTimetable.EndTime }}</span>
          </td>
          <td>
            <span>{{ employeeTimetable.BreakTime }}</span>
          </td>
          <td>
            <span>{{ employeeTimetable.HoursSpent }}</span>
          </td>
          <td>
            <span>{{ employeeTimetable.ApproverNameList }}</span>
          </td>
          <td>
            <span>{{ employeeTimetable.StatusText | translate }}</span>
          </td>
          <td>
            <span>{{ employeeTimetable.Source }}</span>
          </td>
          <td class="col-button" *ngIf="isUser">
            <button
              type="button"
              class="btn btn-icon"
              title="{{ 'DELETE' | translate }}"
              (click)="deleteTimetable(employeeTimetable.Id)"
            >
              <span class="icon ic-sm icon-trash"></span>
            </button>
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="13">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
      <!-- footerColumnGroup -->
      <ng-template pTemplate="groupheader" let-customer>
        <tr pRowGroupHeader>
          <td
            *ngIf="!isProject"
            colspan="7"
            [style]="{ 'text-align': 'right', 'font-weight': 'bold' }"
          >
            {{ "TOTAL_HOURS_SPENT" | translate }}:
          </td>
          <td
            *ngIf="isProject"
            colspan="8"
            [style]="{ 'text-align': 'right', 'font-weight': 'bold' }"
          >
            {{ "TOTAL_HOURS_SPENT" | translate }}:
          </td>
          <td [style]="{ 'text-align': 'left', 'font-weight': 'bold' }">
            {{ totalHoursSpent }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table ends -->
    <div class="action-btns-wrapper">
      <button
        class="btn btn-primary"
        type="submit"
        (click)="approveMultiple(selectedTimetable)"
      >
        <span translate>APPROVE_SELECTED</span>
      </button>
    </div>
  </div>
  <p-confirmDialog
    header="{{ 'CONFIRMATION' | translate }}"
    icon="pi pi-question-circle"
    width="425"
    acceptLabel="{{ 'SWITCH_YES' | translate }}"
    rejectLabel="{{ 'SWITCH_NO' | translate }}"
  ></p-confirmDialog>
</div>

<p-dialog
  [(visible)]="isTimeTableSelected"
  [style]="{ width: '500px' }"
  [resizable]="false"
  [draggable]="false"
>
  <span translate>SELECT_ONE_TIMETABLE</span>
</p-dialog>

<toaster-component></toaster-component>

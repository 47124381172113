<div class="table-view">
  <!-- Table starts -->
  <p-table
    #contactsTable
    [value]="contactView"
    dataKey="Id"
    [(selection)]="selectedContacts"
    [rows]="10"
    [paginator]="true"
    [pageLinks]="3"
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="onRowUnselect($event)"
    [rowsPerPageOptions]="[5, 10, 20]"
    exportFilename="Contact List"
    styleClass="p-datatable-striped"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
  >
    <!-- Caption -->
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix">
        <div class="filter-controls-wrapper" *ngIf="viewFilter">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>
                  <span translate>TYPE_OF_CONTACT</span>
                </label>
                <div>
                  <p-dropdown
                    [options]="typeOfContacts"
                    [style]="{ width: '150px' }"
                    #typeOfContactsDropdown
                    (onChange)="onTypeOfContactChanged($event)"
                  >
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>
                  <span translate>CONTACT_MEDIUM</span>
                </label>
                <div>
                  <p-dropdown
                    [options]="contactsMedium"
                    [style]="{ width: '150px' }"
                    #contactsMediumDropdown
                    (onChange)="onContactMediumChanged($event)"
                  >
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>
                  <span translate>COMPANY</span>
                </label>
                <div>
                  <input
                    type="text"
                    pInputText
                    #cf
                    (keyup)="onCompanyChanged(cf)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-options">
          <div class="pull-right">
            <a [routerLink]="['./../../new']" class="btn btn-success mr-2">
              <span class="icon ic-sm icon-add"></span>
              <span translate>NEW_CONTACT</span>
            </a>
            <div class="action-btns-group">
              <button
                class="btn p-3 filter-toggle"
                (click)="viewFilter = !viewFilter"
              >
                <span class="icon ic-sm icon-filter"></span>
                <span translate>FILTER</span>
              </button>
            </div>
            <kuba-export
              [reportData]="contactsTable.filteredValue || contactsTable.value"
              [additionalData]="additionalData"
            ></kuba-export>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- Header -->
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 55px">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th>{{ "NAME" | translate }}</th>
        <th>{{ "CELLULAR_PHONE" | translate }}</th>
        <th>{{ "TELEPHONE" | translate }}</th>
        <th>{{ "EMAIL" | translate }}</th>
        <th pSortableColumn="ContactFunctionalAreaName">
          {{ "FUNCTION" | translate }}
        </th>
      </tr>
      <tr>
        <th></th>
        <th>
          <input
            pInputText
            type="text"
            (input)="
              contactsTable.filter(
                $any($event.target)?.value,
                'Name',
                'contains'
              )
            "
            placeholder=""
            class="p-column-filter"
          />
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th>
          <p-dropdown
            [options]="functions"
            (onChange)="onFunctionChanged($event, functionDropdown)"
            #functionDropdown
            styleClass="p-column-filter"
            placeholder="Select"
            [showClear]="true"
          >
            <ng-template let-status pTemplate="item">
              {{ status.label | translate }}
            </ng-template>
          </p-dropdown>
        </th>
      </tr>
    </ng-template>
    <!-- Body Content -->
    <ng-template pTemplate="body" let-contact>
      <tr>
        <td style="width: 55px">
          <p-tableCheckbox id="selectedOrders" [value]="contact">
          </p-tableCheckbox>
        </td>
        <td>
          <span
            ><a [routerLink]="['./../../edit', contact.Id]">{{
              contact.Name
            }}</a></span
          >
        </td>
        <td>
          <span>{{ contact.MobileCode }}</span>
        </td>
        <td>
          <span>{{ contact.Telephone }}</span>
        </td>
        <td>
          <span>{{ contact.Emailid }}</span>
        </td>
        <td>
          <span>{{ contact.ContactFunctionalAreaName }}</span>
        </td>
      </tr>
    </ng-template>
    <!-- Empty message -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td colspan="6">
          {{ "NO_RECORDS_FOUND" | translate }}
        </td>
      </tr>
    </ng-template>
    <!-- Footer -->
    <ng-template pTemplate="summary">
      <div class="ui-helper-clearfix">
        <div class="footer-data">
          <div class="text-left">
            <span
              pTooltip="{{
                'SELECT_ATLEAST_ONE_RECORD_DELETE_CONTACT' | translate
              }}"
            >
              <button
                class="btn btn-danger"
                [disabled]="!isSelectedValue"
                (click)="multiContactsdeletion()"
              >
                <i class="icon ic-sm icon-trash"></i>
                <span translate>DELETE_CHECKED</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </ng-template>
  </p-table>
  <!-- Table ends -->
</div>
<toaster-component></toaster-component>

<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>

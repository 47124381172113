<div class="main-content">
  <div class="main-heading">
    <strong> {{ checklistData.Title }} </strong>
  </div>
  <div class="card">
    <div class="card-body">
      <tabset #staticTabs>
        <tab heading="{{ 'STEP1_GENERAL' | translate }}">
          <div class="col-sm-8 tab-content">
            <div class="form-group">
              <label class="col-form-label">
                <span translate>CONTROL_AREA</span>
                <span class="required">*</span>
              </label>
              <p-dropdown
                required
                [style]="{ width: '100%' }"
                [options]="buildingDropdown"
                (onChange)="bindDistribution()"
                [(ngModel)]="checklistData.BuildingId"
              ></p-dropdown>
            </div>
            <div class="form-group" *ngIf="isNew && taskId">
              <div class="checkbox">
                <p-checkbox
                  label="{{ 'FILL_CHECKLIST' | translate }}"
                  binary="true"
                  [ngModel]="fillType == 'CHECKLIST'"
                  (onChange)="onReportFormChange('CHECKLIST')"
                >
                </p-checkbox>
                <p-checkbox
                  label="{{ 'FILL_FORM' | translate }}"
                  binary="true"
                  [ngModel]="fillType == 'FORM'"
                  (onChange)="onReportFormChange('FORM')"
                ></p-checkbox>
              </div>
            </div>
            <div class="form-group">
              <label class="col-form-label">
                <span *ngIf="fillType == 'CHECKLIST'" translate
                  >SELECT_CHECKLIST</span
                >
                <span *ngIf="fillType == 'FORM'" translate>SELECT_FORM</span>
                <span class="required">*</span>
              </label>
              <p-dropdown
                #eve
                required
                [style]="{ width: '100%' }"
                [options]="enabledChecklists"
                (onChange)="onReportFormtypeChange(eve)"
                [disabled]="!isNew"
                [(ngModel)]="checklistData.ChecklistId"
              >
              </p-dropdown>
              <div class="error-msg" *ngIf="addDistFromTemplate">
                <span translate>NO_DISTRIBUTIONS_IN_BUILDING</span>
              </div>
            </div>
            <div class="form-group" *ngIf="isNew && taskId">
              <button
                *ngIf="addDistFromTemplate"
                class="btn btn-primary"
                (click)="addTemplateDistribution()"
              >
                <span
                  pTooltip="{{
                    'CLICK_ADD_DISTRIBUTION_FROM_TEMPLATE' | translate
                  }}"
                  translate
                  >ADD_DISTRIBUTION_FROM_TEMPLATE</span
                >
              </button>
            </div>
            <div class="form-group" *ngIf="isMp">
              <label class="col-form-label">
                <span translate>DISTRIBUTION</span>
                <span class="required">*</span>
              </label>
              <p-dropdown
                #dist
                required
                [style]="{ width: '100%' }"
                [options]="distributions"
                (onChange)="mpDistributionChange(dist)"
                [(ngModel)]="distributionId"
              ></p-dropdown>
            </div>
            <div
              class="form-group"
              [ngClass]="{ hidden: fillType != 'CHECKLIST' }"
            >
              <label class="col-form-label">
                <span translate>PREDEFINED_DEADLINE</span>
                <span class="required">*</span>
              </label>
              <p-dropdown
                #pd
                required
                [style]="{ width: '100%' }"
                [options]="yesOrNo"
                [(ngModel)]="predefine"
              >
              </p-dropdown>
            </div>
            <div
              class="form-group"
              *ngIf="pd.value === 1"
              [ngClass]="{ hidden: fillType == 'FORM' }"
            >
              <label class="col-form-label">
                <span translate>RESPONSIBLE_EXECUTION</span>
                <span class="required">*</span>
              </label>
              <p-dropdown
                required
                [style]="{ width: '100%' }"
                [options]="execution"
                [(ngModel)]="checklistData.ExecutionUserId"
              >
              </p-dropdown>
            </div>
            <div
              class="form-group"
              *ngIf="pd.value === 1"
              [ngClass]="{ hidden: fillType == 'FORM' }"
            >
              <label class="col-form-label">
                <span translate>RESPONSIBLE_FOR_FOLLOW_UP</span>
                <span class="required">*</span>
              </label>
              <p-dropdown
                required
                [style]="{ width: '100%' }"
                [options]="followup"
                [(ngModel)]="checklistData.FollowupUserId"
              >
              </p-dropdown>
            </div>
            <div
              class="form-group"
              *ngIf="pd.value === 1"
              [ngClass]="{ hidden: fillType == 'FORM' }"
            >
              <label class="col-form-label">
                <span translate>DEADLINE</span>
                <span class="required">*</span>
              </label>
              <my-date-picker
                required
                [options]="dateOptions"
                placeholder="{{ 'SELECT_DEADLINE_DATE' | translate }}"
                [(ngModel)]="deadline"
                [style]="{ width: '100%' }"
              ></my-date-picker>
            </div>
            <div class="action-btns-wrapper">
              <button (click)="Back()" class="btn btn-outline-secondary">
                <span translate>BACK</span>
              </button>
              <button
                (click)="SaveFirstStepCompleteCheckList()"
                class="btn btn-primary"
                [disabled]="
                  !(
                    checklistData.BuildingId &&
                    checklistData.ChecklistId &&
                    pd.value !== null &&
                    (pd.value === 0 ||
                      (checklistData.ExecutionUserId &&
                        checklistData.FollowupUserId &&
                        deadline)) &&
                    (!isMp || distributionId) &&
                    !addDistFromTemplate
                  )
                "
              >
                <span translate>SAVE_AND_NEXT </span>
              </button>
            </div>
          </div>
        </tab>
        <tab [heading]="getstep2Header()">
          <div class="tab-content">
            <div
              *ngIf="CompleteChecklistEdit.Section && fillType == 'CHECKLIST'"
            >
              <ng-template
                ngFor
                let-section
                let-headPoint="index"
                [ngForOf]="CompleteChecklistEdit.Section"
              >
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ headPoint + 1 }} {{ section.Title }}</th>
                      <th style="width: 70px">
                        <span *ngIf="headPoint === 0" translate>OK</span>
                      </th>
                      <th style="width: 70px">
                        <span *ngIf="headPoint === 0" translate>N/A</span>
                      </th>
                      <th style="width: 70px">
                        <span *ngIf="headPoint === 0" translate>DEVIATION</span>
                      </th>
                      <th style="width: 250px">
                        <span *ngIf="headPoint === 0" translate>COMMENTS</span>
                      </th>
                      <th style="width: 130px">
                        <span *ngIf="headPoint === 0" translate
                          >ATTACHMENTS</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <ng-template
                    ngFor
                    let-sectionitem
                    let-i="index"
                    [ngForOf]="section.Items"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <label
                            >{{ headPoint + 1 }}. {{ i + 1 }}
                            {{ sectionitem.Title }}</label
                          >
                        </td>
                        <ng-template
                          ngFor
                          let-status
                          [ngForOf]="sectionitem.Status"
                        >
                          <td *ngIf="status.Label != 'Deviation'">
                            <div class="custom-radio-check">
                              <input
                                id="statusOptions{{ headPoint }}{{ i }}{{
                                  sectionitem.Status.indexOf(status)
                                }}"
                                [disabled]="sectionitem.DeviationList.length"
                                name="options[{{ headPoint }}{{ i }}]"
                                type="radio"
                                [value]="status.value"
                                (change)="
                                  setStatus(
                                    status,
                                    sectionitem.Status,
                                    sectionitem
                                  )
                                "
                                [checked]="status.value"
                                class="custom-radio-input"
                              />
                              <label
                                class="custom-radio-check-label text-primary"
                                for="statusOptions{{ headPoint }}{{ i }}{{
                                  sectionitem.Status.indexOf(status)
                                }}"
                              ></label>
                            </div>
                          </td>
                        </ng-template>
                        <td>
                          <a
                            class="btn btn-danger"
                            (click)="addEditDeviation(0, headPoint, i)"
                          >
                            <span class="icon ic-sm icon-add"></span
                          ></a>
                        </td>
                        <td>
                          <textarea
                            [(ngModel)]="sectionitem.Comments"
                            id="statusComments"
                            class="form-control single-line"
                          ></textarea>
                        </td>
                        <td class="col-button">
                          <button
                            class="btn btn-outline-success"
                            (click)="addFile(headPoint, i)"
                          >
                            <span translate>ADD_FILES</span>
                          </button>
                          <button
                            type="button"
                            class="btn btn-icon"
                            *ngIf="sectionitem.Attachments.length"
                            (click)="previewAttachment(headPoint, i)"
                            title="{{ 'EDIT' | translate }}"
                          >
                            <i class="icon ic-sm icon-attach"></i>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="6" class="text-right" style="border: 0">
                          <strong>{{ sectionitem.Signature }}</strong>
                        </td>
                      </tr>
                      <tr *ngIf="sectionitem.DeviationList.length">
                        <td colspan="6" style="border: 0">
                          <table class="table table-hover">
                            <tr>
                              <th style="width: 80px">
                                <span translate>DEVIATION_NUMBER</span>
                              </th>
                              <th style="width: 100px">
                                <span translate>LOCATION</span>
                              </th>
                              <th><span translate>DESCRIPTION</span></th>
                              <th style="width: 80px">
                                <span translate>STATUS</span>
                              </th>
                              <th style="width: 100px">
                                <span translate>DEADLINE</span>
                              </th>
                              <th style="width: 160px">
                                <span translate>RESPONSIBLE_EXECUTION</span>
                              </th>
                              <th style="width: 80px"></th>
                            </tr>
                            <tr *ngFor="let dev of sectionitem.DeviationList">
                              <td>{{ dev.Id }}</td>
                              <td>
                                <span translate>{{ dev.Location }}</span>
                              </td>
                              <td>
                                <span translate>{{ dev.Description }}</span>
                              </td>
                              <td>
                                <span translate>{{ dev.StatusText }}</span>
                              </td>
                              <td>
                                <span translate>{{
                                  dev.Deadline | date : "dd.MM.yyyy"
                                }}</span>
                              </td>
                              <td>
                                <span translate>{{
                                  dev.ResponsiblePersonName
                                }}</span>
                              </td>
                              <td class="col-button">
                                <button
                                  type="button"
                                  class="btn btn-icon"
                                  (click)="
                                    addEditDeviation(dev.Id, headPoint, i)
                                  "
                                  title="{{ 'EDIT' | translate }}"
                                >
                                  <i class="icon ic-sm icon-pencil-tip"></i>
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-icon"
                                  (click)="
                                    deleteDeviation(dev['Id'], headPoint, i)
                                  "
                                  title="{{ 'DELETE' | translate }}"
                                >
                                  <i class="icon ic-sm icon-trash"></i>
                                </button>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </ng-template>
                </table>
              </ng-template>
            </div>
            <course-overview
              #course
              *ngIf="enableForm == 'course-overview'"
              [(courseOverview)]="formData"
              (distributionChange)="onCourseDistSelect($event)"
            ></course-overview>
            <distribution-overview
              #distribution
              *ngIf="enableForm == 'distribution-overview'"
              [(distributionOverview)]="formData"
            ></distribution-overview>
            <document-assessment
              #documentAssessment
              *ngIf="enableForm == 'document-assessment'"
              [(DocumentAssessment)]="formData"
            ></document-assessment>
            <emergency-lighting
              #emergencyLighting
              *ngIf="enableForm == 'emergency-lighting'"
              [(lighting)]="formData"
              (saveForm)="saveEmerForm($event)"
              (deleteEquip)="deleteEquipStatus($event)"
            ></emergency-lighting>
            <fire-alarm
              #filreAlarm
              *ngIf="enableForm == 'fire-alarm'"
              (saveForm)="saveFireForm($event)"
              [(firealarmSystem)]="formData"
            >
            </fire-alarm>
            <risk-assessment
              #riskAssessment
              *ngIf="enableForm == 'risk-assessment'"
              [(riskAssessment)]="formData"
            >
            </risk-assessment>
            <div class="table-view" *ngIf="enableForm == 'measure'">
              <!-- Table starts -->
              <p-table
                #dt
                [value]="mpInstruments"
                dataKey="Id"
                [(selection)]="selectedmpInstruments"
                scrollable="true"
                scrollHeight="200px"
                frozenWidth="400px"
              >
                <!-- Header -->
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 55px">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th>{{ "TYPE" | translate }}</th>
                    <th>{{ "MODEL" | translate }}</th>
                    <th>{{ "CALIBRATED_DATE" | translate }}</th>
                  </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-electro>
                  <tr>
                    <td style="width: 38px">
                      <p-tableCheckbox id="selectedOrders" [value]="electro">
                      </p-tableCheckbox>
                    </td>
                    <td>
                      <span>{{ electro.InstrumentTypeName }}</span>
                    </td>
                    <td>
                      <span>{{ electro.InstrumentModelName }}</span>
                    </td>
                    <td>
                      <span>{{
                        electro.CalibratedDate | date : "dd/MM/yyyy"
                      }}</span>
                    </td>
                  </tr>
                </ng-template>
                <!-- Empty message -->
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr>
                    <td colspan="4">
                      {{ "NO_RECORDS_FOUND" | translate }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              <!-- Table Ends -->
            </div>
            <div class="action-btns-wrapper">
              <button
                (click)="EnableFirstTab()"
                class="btn btn-outline-secondary"
              >
                <span translate>PREVIOUS_STEP</span>
              </button>
              <button
                (click)="Saveforlater()"
                class="btn btn-primary"
                [disabled]="saveDisable()"
              >
                <span translate>SAVE_FOR_LATER</span>
              </button>
              <button
                (click)="SaveSecondStepCheckList()"
                class="btn btn-primary"
                [disabled]="checkValid()"
              >
                <span translate>SAVE_AND_NEXT</span>
              </button>
            </div>
          </div>
        </tab>
        <tab [heading]="getstep3Header()">
          <div
            *ngIf="
              enableForm != 'emergency-lighting' &&
              enableForm != 'fire-alarm' &&
              enableForm != 'measure'
            "
            class="form-group"
          >
            <label class="col-form-label">
              <span translate>CONTROL_AREA</span>
              <span class="required">*</span>
            </label>
            <p-dropdown
              required
              [style]="{ width: '100%' }"
              [options]="buildingDropdown"
              [(ngModel)]="checklistData.BuildingId"
            ></p-dropdown>
          </div>
          <div
            *ngIf="
              enableForm != 'emergency-lighting' &&
              enableForm != 'fire-alarm' &&
              enableForm != 'measure'
            "
            class="form-group"
          >
            <label class="col-form-label">
              <span translate>TITLE</span>
              <span class="required">*</span>
            </label>
            <input type="text" pInputText [(ngModel)]="checklistData.Title" />
          </div>
          <div
            *ngIf="
              enableForm != 'emergency-lighting' &&
              enableForm != 'fire-alarm' &&
              enableForm != 'measure'
            "
            class="form-group"
          >
            <label class="col-form-label">
              <span translate>ENTER_YOUR_SIGN</span>
            </label>
            <canvas #canvas></canvas>
          </div>
          <button
            *ngIf="
              enableForm != 'emergency-lighting' &&
              enableForm != 'fire-alarm' &&
              enableForm != 'measure'
            "
            (click)="clearSign()"
            class="btn btn-outline-secondary"
          >
            <span translate>CLEAR_SIGN</span>
          </button>
          <div
            *ngIf="
              enableForm != 'emergency-lighting' &&
              enableForm != 'fire-alarm' &&
              enableForm != 'measure'
            "
            class="action-btns-wrapper"
          >
            <button
              (click)="EnableSecondTab()"
              class="btn btn-outline-secondary"
            >
              <span translate>PREVIOUS_STEP</span>
            </button>
            <button
              (click)="CompleteCheckList()"
              class="btn btn-primary"
              [disabled]="checkValid()"
            >
              <span translate>COMPLETE</span>
            </button>
          </div>
          <div class="tab-content" *ngIf="enableForm == 'emergency-lighting'">
            <table class="table">
              <thead>
                <tr>
                  <th><span translate>NUMBER_NO</span></th>
                  <th><span translate>FLOOR</span></th>
                  <th><span translate>LOCATION</span></th>
                  <th><span translate>TYPE_WATT</span></th>
                  <th><span translate>ATTACHED_DISTRIBUTION_COURSE</span></th>
                  <th><span translate>SOURCE_LYSKILDE</span></th>
                  <th colspan="2" style="width: 90px">
                    <span translate>BATTERY_CHANGE_TYPE</span>
                  </th>
                  <th colspan="2" style="width: 90px">
                    <span translate>BATTERY_TEST</span>
                  </th>
                  <th colspan="2" style="width: 90px">
                    <span translate>CONDITION</span>
                  </th>
                  <th style="width: 200px"><span translate>COMMENTS</span></th>
                  <th style="width: 100px">
                    <span translate>ATTACHMENTS</span>
                  </th>
                </tr>
              </thead>
              <ng-template
                ngFor
                let-equipitem
                let-i="index"
                [ngForOf]="emergencyLightEquipStatus"
              >
                <tbody>
                  <tr>
                    <td>
                      <label>{{ equipitem.no }}</label>
                    </td>
                    <td>
                      <label>{{ equipitem.floor }}</label>
                    </td>
                    <td>
                      <label>{{ equipitem.location }}</label>
                    </td>
                    <td>
                      <label>{{ equipitem.type }}</label>
                    </td>
                    <td>
                      <label>{{ equipitem.distributionCourse }}</label>
                    </td>
                    <td>
                      <label>{{ equipitem.source }}</label>
                    </td>
                    <ng-template ngFor let-status [ngForOf]="equipitem.Status">
                      <td *ngIf="status.Label != 'Deviation'">
                        <div
                          class="custom-radio-check"
                          *ngIf="status.Label.indexOf('BatteryChange') > -1"
                        >
                          <input
                            id="statusOptions1{{ i }}{{
                              equipitem.Status.indexOf(status)
                            }}"
                            name="options[1{{ i }}]"
                            type="radio"
                            [value]="status.value"
                            (change)="
                              setStatus(status, equipitem.Status, equipitem)
                            "
                            [checked]="status.value"
                            class="custom-radio-input"
                          />
                          <label
                            *ngIf="status.Label.indexOf('Ok') > -1"
                            class="custom-radio-check-label text-primary"
                            for="statusOptions1{{ i }}{{
                              equipitem.Status.indexOf(status)
                            }}"
                            >Ok</label
                          >
                          <label
                            *ngIf="status.Label.indexOf('Def') > -1"
                            class="custom-radio-check-label text-primary"
                            for="statusOptions1{{ i }}{{
                              equipitem.Status.indexOf(status)
                            }}"
                            >Def</label
                          >
                        </div>
                        <div
                          class="custom-radio-check"
                          *ngIf="status.Label.indexOf('BatteryTest') > -1"
                        >
                          <input
                            id="statusOptions2{{ i }}{{
                              equipitem.Status.indexOf(status)
                            }}"
                            name="options[2{{ i }}]"
                            type="radio"
                            [value]="status.value"
                            (change)="
                              setStatus(status, equipitem.Status, equipitem)
                            "
                            [checked]="status.value"
                            class="custom-radio-input"
                          />
                          <label
                            *ngIf="status.Label.indexOf('Ok') > -1"
                            class="custom-radio-check-label text-primary"
                            for="statusOptions2{{ i }}{{
                              equipitem.Status.indexOf(status)
                            }}"
                            >Ok</label
                          >
                          <label
                            *ngIf="status.Label.indexOf('Def') > -1"
                            class="custom-radio-check-label text-primary"
                            for="statusOptions2{{ i }}{{
                              equipitem.Status.indexOf(status)
                            }}"
                            >Def</label
                          >
                        </div>
                        <div
                          class="custom-radio-check"
                          *ngIf="status.Label.indexOf('Condition') > -1"
                        >
                          <input
                            id="statusOptions3{{ i }}{{
                              equipitem.Status.indexOf(status)
                            }}"
                            [disabled]="
                              equipitem.DeviationList
                                ? equipitem.DeviationList.length
                                : false
                            "
                            name="options[3{{ i }}]"
                            type="radio"
                            [value]="status.value"
                            (change)="
                              setStatus(status, equipitem.Status, equipitem)
                            "
                            [checked]="status.value"
                            class="custom-radio-input"
                          />
                          <label
                            *ngIf="status.Label.indexOf('Ok') > -1"
                            class="custom-radio-check-label text-primary"
                            for="statusOptions3{{ i }}{{
                              equipitem.Status.indexOf(status)
                            }}"
                            >Ok</label
                          >
                          <label
                            *ngIf="status.Label.indexOf('Def') > -1"
                            class="custom-radio-check-label text-primary"
                            for="statusOptions3{{ i }}{{
                              equipitem.Status.indexOf(status)
                            }}"
                            >Def</label
                          >
                        </div>
                      </td>
                    </ng-template>
                    <td>
                      <a
                        class="btn btn-danger"
                        (click)="addEditEmerDeviation(0, i)"
                      >
                        <span class="icon ic-sm icon-add"></span
                      ></a>
                    </td>
                    <td>
                      <textarea
                        [(ngModel)]="equipitem.Comments"
                        id="statusComments"
                        class="form-control single-line"
                      ></textarea>
                    </td>
                    <td class="col-button">
                      <button
                        class="btn btn-outline-success"
                        (click)="addFile(0, i)"
                      >
                        <span translate>ADD_FILES</span>
                      </button>
                      <button
                        type="button"
                        class="btn btn-icon"
                        *ngIf="equipitem.Attachments.length"
                        (click)="previewAttachment(0, i)"
                        title="{{ 'EDIT' | translate }}"
                      >
                        <i class="icon ic-sm icon-attach"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="13" class="text-right" style="border: 0">
                      <strong>{{ equipitem.Signature }}</strong>
                    </td>
                  </tr>
                  <tr
                    *ngIf="
                      equipitem.DeviationList
                        ? equipitem.DeviationList.length
                        : false
                    "
                  >
                    <td colspan="13" style="border: 0">
                      <table class="table table-hover">
                        <tr>
                          <th style="width: 80px">
                            <span translate>DEVIATION_NUMBER</span>
                          </th>
                          <th style="width: 100px">
                            <span translate>LOCATION</span>
                          </th>
                          <th><span translate>DESCRIPTION</span></th>
                          <th style="width: 80px">
                            <span translate>STATUS</span>
                          </th>
                          <th style="width: 100px">
                            <span translate>DEADLINE</span>
                          </th>
                          <th style="width: 160px">
                            <span translate>RESPONSIBLE_EXECUTION</span>
                          </th>
                          <th style="width: 80px"></th>
                        </tr>
                        <tr *ngFor="let dev of equipitem.DeviationList">
                          <td>{{ dev.Id }}</td>
                          <td>
                            <span translate>{{ dev.Location }}</span>
                          </td>
                          <td>
                            <span translate>{{ dev.Description }}</span>
                          </td>
                          <td>
                            <span translate>{{ dev.StatusText }}</span>
                          </td>
                          <td>
                            <span translate>{{
                              dev.Deadline | date : "dd.MM.yyyy"
                            }}</span>
                          </td>
                          <td>
                            <span translate>{{
                              dev.ResponsiblePersonName
                            }}</span>
                          </td>
                          <td class="col-button">
                            <button
                              type="button"
                              class="btn btn-icon"
                              (click)="addEditEmerDeviation(dev.Id, i)"
                              title="{{ 'EDIT' | translate }}"
                            >
                              <i class="icon ic-sm icon-pencil-tip"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-icon"
                              (click)="deleteEmerDeviation(dev['Id'], i)"
                              title="{{ 'DELETE' | translate }}"
                            >
                              <i class="icon ic-sm icon-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </ng-template>
            </table>
            <div class="action-btns-wrapper">
              <button
                (click)="EnableSecondTab()"
                class="btn btn-outline-secondary"
              >
                <span translate>PREVIOUS_STEP</span>
              </button>
              <button (click)="Saveforlater()" class="btn btn-primary">
                <span translate>SAVE_FOR_LATER</span>
              </button>
              <button
                (click)="SaveSecondStepCheckList()"
                class="btn btn-primary"
                [disabled]="checkEmerCklValid()"
              >
                <span translate>SAVE_AND_NEXT</span>
              </button>
            </div>
          </div>
          <div class="tab-content" *ngIf="enableForm == 'fire-alarm'">
            <table class="table">
              <thead>
                <tr>
                  <th><span translate>AMOUNT</span></th>
                  <th><span translate>TYPE</span></th>
                  <th><span translate>DESCRIPTION</span></th>
                  <th><span translate>LOCATION</span></th>
                  <th><span translate>LABELLING</span></th>
                  <th style="width: 50px">
                    <span translate>OK</span>
                  </th>
                  <th style="width: 70px">
                    <span translate>N/A</span>
                  </th>
                  <th style="width: 70px">
                    <span translate>DEVIATION</span>
                  </th>
                  <th style="width: 250px"><span translate>COMMENTS</span></th>
                  <th style="width: 130px">
                    <span translate>ATTACHMENTS</span>
                  </th>
                </tr>
              </thead>
              <ng-template
                ngFor
                let-equipitem
                let-i="index"
                [ngForOf]="fireAlarmEquipStatus"
              >
                <tbody>
                  <tr>
                    <td>
                      <label>{{ equipitem.amount }}</label>
                    </td>
                    <td>
                      <label>{{ equipitem.type }}</label>
                    </td>
                    <td>
                      <label>{{ equipitem.description }}</label>
                    </td>
                    <td>
                      <label>{{ equipitem.location }}</label>
                    </td>
                    <td>
                      <label>{{ equipitem.merking }}</label>
                    </td>
                    <ng-template ngFor let-status [ngForOf]="equipitem.Status">
                      <td *ngIf="status.Label != 'Deviation'">
                        <div class="custom-radio-check">
                          <input
                            id="statusOptions{{ i }}{{
                              equipitem.Status.indexOf(status)
                            }}"
                            [disabled]="
                              equipitem.DeviationList
                                ? equipitem.DeviationList.length
                                : false
                            "
                            name="options[{{ i }}]"
                            type="radio"
                            [value]="status.value"
                            (change)="
                              setStatus(status, equipitem.Status, equipitem)
                            "
                            [checked]="status.value"
                            class="custom-radio-input"
                          />
                          <label
                            class="custom-radio-check-label text-primary"
                            for="statusOptions{{ i }}{{
                              equipitem.Status.indexOf(status)
                            }}"
                          ></label>
                        </div>
                      </td>
                    </ng-template>
                    <td>
                      <a
                        class="btn btn-danger"
                        (click)="addEditFireDeviation(0, i)"
                      >
                        <span class="icon ic-sm icon-add"></span
                      ></a>
                    </td>
                    <td>
                      <textarea
                        [(ngModel)]="equipitem.Comments"
                        id="statusComments"
                        class="form-control single-line"
                      ></textarea>
                    </td>
                    <td class="col-button">
                      <button
                        class="btn btn-outline-success"
                        (click)="addFile(0, i)"
                      >
                        <span translate>ADD_FILES</span>
                      </button>
                      <button
                        type="button"
                        class="btn btn-icon"
                        *ngIf="equipitem.Attachments.length"
                        (click)="previewAttachment(0, i)"
                        title="{{ 'EDIT' | translate }}"
                      >
                        <i class="icon ic-sm icon-attach"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="11" class="text-right" style="border: 0">
                      <strong>{{ equipitem.Signature }}</strong>
                    </td>
                  </tr>
                  <tr
                    *ngIf="
                      equipitem.DeviationList
                        ? equipitem.DeviationList.length
                        : false
                    "
                  >
                    <td colspan="11" style="border: 0">
                      <table class="table table-hover">
                        <tr>
                          <th style="width: 80px">
                            <span translate>DEVIATION_NUMBER</span>
                          </th>
                          <th style="width: 100px">
                            <span translate>LOCATION</span>
                          </th>
                          <th><span translate>DESCRIPTION</span></th>
                          <th style="width: 80px">
                            <span translate>STATUS</span>
                          </th>
                          <th style="width: 100px">
                            <span translate>DEADLINE</span>
                          </th>
                          <th style="width: 160px">
                            <span translate>RESPONSIBLE_EXECUTION</span>
                          </th>
                          <th style="width: 80px"></th>
                        </tr>
                        <tr *ngFor="let dev of equipitem.DeviationList">
                          <td>{{ dev.Id }}</td>
                          <td>
                            <span translate>{{ dev.Location }}</span>
                          </td>
                          <td>
                            <span translate>{{ dev.Description }}</span>
                          </td>
                          <td>
                            <span translate>{{ dev.StatusText }}</span>
                          </td>
                          <td>
                            <span translate>{{
                              dev.Deadline | date : "dd.MM.yyyy"
                            }}</span>
                          </td>
                          <td>
                            <span translate>{{
                              dev.ResponsiblePersonName
                            }}</span>
                          </td>
                          <td class="col-button">
                            <button
                              type="button"
                              class="btn btn-icon"
                              (click)="addEditFireDeviation(dev.Id, i)"
                              title="{{ 'EDIT' | translate }}"
                            >
                              <i class="icon ic-sm icon-pencil-tip"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-icon"
                              (click)="deleteEmerDeviation(dev['Id'], i)"
                              title="{{ 'DELETE' | translate }}"
                            >
                              <i class="icon ic-sm icon-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </ng-template>
            </table>
            <div class="action-btns-wrapper">
              <button
                (click)="EnableSecondTab()"
                class="btn btn-outline-secondary"
              >
                <span translate>PREVIOUS_STEP</span>
              </button>
              <button (click)="Saveforlater()" class="btn btn-primary">
                <span translate>SAVE_FOR_LATER</span>
              </button>
              <button
                (click)="SaveSecondStepCheckList()"
                class="btn btn-primary"
                [disabled]="checkFireCklValid()"
              >
                <span translate>SAVE_AND_NEXT</span>
              </button>
            </div>
          </div>
          <measure-protocol-visual-contol
            #vsControl
            *ngIf="enableForm == 'measure'"
          >
          </measure-protocol-visual-contol>
          <div *ngIf="enableForm == 'measure'" class="action-btns-wrapper">
            <button
              (click)="EnableSecondTab()"
              class="btn btn-outline-secondary"
            >
              <span translate>PREVIOUS_STEP</span>
            </button>
            <button (click)="SaveVS('back')" class="btn btn-primary">
              <span translate>SAVE_FOR_LATER</span>
            </button>
            <button (click)="SaveVS('next')" class="btn btn-primary">
              <span translate>SAVE_AND_NEXT</span>
            </button>
          </div>
        </tab>
        <tab
          *ngIf="
            enableForm == 'emergency-lighting' ||
            enableForm == 'fire-alarm' ||
            enableForm == 'measure'
          "
          [heading]="getstep4Header()"
        >
          <div *ngIf="enableForm != 'measure'" class="form-group">
            <label class="col-form-label">
              <span translate>CONTROL_AREA</span>
              <span class="required">*</span>
            </label>
            <p-dropdown
              required
              [style]="{ width: '100%' }"
              [options]="buildingDropdown"
              [(ngModel)]="checklistData.BuildingId"
            ></p-dropdown>
          </div>
          <div *ngIf="enableForm != 'measure'" class="form-group">
            <label class="col-form-label">
              <span translate>TITLE</span>
              <span class="required">*</span>
            </label>
            <input type="text" pInputText [(ngModel)]="checklistData.Title" />
          </div>
          <div *ngIf="enableForm != 'measure'" class="form-group">
            <label class="col-form-label">
              <span translate>ENTER_YOUR_SIGN</span>
            </label>
            <canvas #canvas></canvas>
          </div>
          <button
            *ngIf="enableForm != 'measure'"
            (click)="clearSign()"
            class="btn btn-outline-secondary"
          >
            <span translate>CLEAR_SIGN</span>
          </button>
          <div *ngIf="enableForm != 'measure'" class="action-btns-wrapper">
            <button
              (click)="EnableSecondTab()"
              class="btn btn-outline-secondary"
            >
              <span translate>PREVIOUS_STEP</span>
            </button>
            <button
              (click)="CompleteCheckList()"
              class="btn btn-primary"
              [disabled]="checkEmerCklValid()"
            >
              <span translate>COMPLETE</span>
            </button>
          </div>
          <measure-protocol-earth-leakage
            #earthLeak
            *ngIf="enableForm == 'measure'"
          >
          </measure-protocol-earth-leakage>
          <div *ngIf="enableForm == 'measure'" class="action-btns-wrapper">
            <button (click)="EnableTab(2)" class="btn btn-outline-secondary">
              <span translate>PREVIOUS_STEP</span>
            </button>
            <button (click)="EnableTab(4)" class="btn btn-primary">
              <span translate>NEXT</span>
            </button>
          </div>
        </tab>
        <tab
          *ngIf="enableForm == 'measure'"
          heading="{{ 'STEP5_POWER' | translate }}"
        >
          <measure-protocol-power-leakage
            #pwrLeak
          ></measure-protocol-power-leakage>
          <div class="action-btns-wrapper">
            <button (click)="EnableTab(3)" class="btn btn-outline-secondary">
              <span translate>PREVIOUS_STEP</span>
            </button>
            <button (click)="EnableTab(5)" class="btn btn-primary">
              <span translate>NEXT</span>
            </button>
          </div>
        </tab>
        <tab
          *ngIf="enableForm == 'measure'"
          heading="{{ 'STEP6_TEMPERATURE' | translate }}"
        >
          <measure-protocol-temperature
            #temperature
          ></measure-protocol-temperature>
          <div class="action-btns-wrapper">
            <button (click)="EnableTab(4)" class="btn btn-outline-secondary">
              <span translate>PREVIOUS_STEP</span>
            </button>
            <button (click)="EnableTab(6)" class="btn btn-primary">
              <span translate>NEXT</span>
            </button>
          </div>
        </tab>
        <tab
          *ngIf="enableForm == 'measure'"
          heading="{{ 'STEP7_FINISH' | translate }}"
        >
          <div class="form-group">
            <label class="col-form-label">
              <span translate>ENTER_YOUR_SIGN</span>
            </label>
            <canvas #canvas></canvas>
          </div>
          <button (click)="clearSign()" class="btn btn-outline-secondary">
            <span translate>CLEAR_SIGN</span>
          </button>
          <div class="action-btns-wrapper">
            <button (click)="EnableTab(5)" class="btn btn-outline-secondary">
              <span translate>PREVIOUS_STEP</span>
            </button>
            <button (click)="CompleteSignMP()" class="btn btn-primary">
              <span translate>COMPLETE</span>
            </button>
          </div>
        </tab>
      </tabset>
    </div>
  </div>
</div>
<toaster-component></toaster-component>
<p-dialog
  header="{{ 'ADD_FILES' | translate }}"
  *ngIf="isAddFileClicked"
  [modal]="true"
  [(visible)]="isAddFileClicked"
  [style]="{ width: '400px' }"
  [resizable]="false"
  [draggable]="false"
  [contentStyle]="{ overflow: 'visible' }"
>
  <ecpl-document-explorer
    [style]="{ width: '160px', height: '100px' }"
    [uploaderConfig]="upConfig"
    [uploadedFiles]="
      CompleteChecklistEdit.Section[addFileHeadpoint].Items[addFileCheckpoint]
        .Attachments
    "
    (saveChanges)="
      uploaderEvents(
        $event,
        CompleteChecklistEdit.Section[addFileHeadpoint].Items[addFileCheckpoint]
      )
    "
  >
  </ecpl-document-explorer>
</p-dialog>
<p-dialog
  header="{{ 'ADD_FILES' | translate }}"
  *ngIf="isAddFileEmerClicked"
  [modal]="true"
  [(visible)]="isAddFileEmerClicked"
  [style]="{ width: '400px' }"
  [resizable]="false"
  [draggable]="false"
  [contentStyle]="{ overflow: 'visible' }"
>
  <ecpl-document-explorer
    [style]="{ width: '160px', height: '100px' }"
    [uploaderConfig]="upConfig"
    [uploadedFiles]="emergencyLightEquipStatus[emerEqipIndex].Attachments"
    (saveChanges)="
      emerUploaderEvents($event, emergencyLightEquipStatus[emerEqipIndex])
    "
  >
  </ecpl-document-explorer>
</p-dialog>
<p-dialog
  header="{{ 'ADD_FILES' | translate }}"
  *ngIf="isAddFileFireAlarmClicked"
  [modal]="true"
  [(visible)]="isAddFileFireAlarmClicked"
  [style]="{ width: '400px' }"
  [resizable]="false"
  [draggable]="false"
  [contentStyle]="{ overflow: 'visible' }"
>
  <ecpl-document-explorer
    [style]="{ width: '160px', height: '100px' }"
    [uploaderConfig]="upConfig"
    [uploadedFiles]="fireAlarmEquipStatus[fireAlarmEqipIndex].Attachments"
    (saveChanges)="
      emerUploaderEvents($event, fireAlarmEquipStatus[fireAlarmEqipIndex])
    "
  >
  </ecpl-document-explorer>
</p-dialog>
<p-dialog
  header="{{ 'ADD_DEVIATION' | translate }}"
  *ngIf="isAddDeviationClicked"
  [modal]="true"
  [(visible)]="isAddDeviationClicked"
  [style]="{ width: '600px' }"
  [resizable]="false"
  [draggable]="false"
  [contentStyle]="{ overflow: 'auto' }"
>
  <form [formGroup]="deviationForm">
    <div class="form-group row">
      <div class="col-sm-3">
        <label>
          <span translate>INSPECTION_NO</span>
        </label>
      </div>
      <div class="col-sm-9">
        <input
          type="text"
          pInputText
          readonly
          formControlName="InspectionNumber"
        />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <label>
          <span translate>TITLE</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="col-sm-9">
        <input type="text" pInputText formControlName="Title" />
        <control-messages
          [control]="deviationForm.controls['Title']"
        ></control-messages>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <label>
          <span translate>TIME_OF_EVENT</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="col-sm-9">
        <div
          style="
            display: flex;
            justify-content: space-between;
            max-width: 380px;
          "
        >
          <span style="width: 140px">
            <my-date-picker
              name="EventDate"
              [options]="dateOptions"
              formControlName="EventDate"
              placeholder="{{ 'SELECT_EVENT_DATE' | translate }}"
            >
            </my-date-picker>
            <my-date-picker
              name="EventDate"
              [options]="dateOptions"
              formControlName="EventDate"
              (dateChanged)="enableClear()"
              placeholder="{{ 'SELECT_EVENT_DATE' | translate }}"
            >
            </my-date-picker>
          </span>
          <span style="width: 100px">
            <p-calendar
              [timeOnly]="true"
              readonlyInput="true"
              [style]="{ width: '100%' }"
              formControlName="EventTime"
              styleClass="dateTimeField"
            >
            </p-calendar>
            <p-calendar
              [timeOnly]="true"
              readonlyInput="true"
              [style]="{ width: '100%' }"
              (onSelect)="enableClear()"
              formControlName="EventTime"
              styleClass="dateTimeField"
            >
            </p-calendar>
          </span>
          <span>
            <button
              type="button"
              class="btn btn-primary"
              (click)="bindEventDate()"
            >
              <span translate>NOW</span>
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              [disabled]="!IsClear"
              (click)="clearEventDate()"
            >
              <span translate>CLEAR</span>
            </button>
          </span>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <label>
          <span translate>CONTROL_AREA</span>
        </label>
      </div>
      <div class="col-sm-9">
        <p-dropdown
          formControlName="Building"
          [options]="buildingDropdown"
          placeholder="{{ 'SELECT' | translate }}"
          [style]="{ width: '100%' }"
        ></p-dropdown>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <label>
          <span translate>LOCATION</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="col-sm-9">
        <input type="text" pInputText formControlName="Location" />
        <control-messages
          [control]="deviationForm.controls['Location']"
        ></control-messages>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <label>
          <span translate>DESCRIPTION</span>
        </label>
      </div>
      <div class="col-sm-9">
        <textarea
          pInputTextarea
          class="form-control single-line"
          autoResize="autoResize"
          formControlName="Description"
        ></textarea>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <label>
          <span translate>STATUS</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="col-sm-9">
        <p-dropdown
          formControlName="Status"
          [options]="status"
          placeholder="{{ 'SELECT' | translate }}"
          [style]="{ width: '100%' }"
        ></p-dropdown>
        <control-messages
          [control]="deviationForm.controls['Status']"
        ></control-messages>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <label>
          <span translate>IMPORTANCE</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="col-sm-9">
        <p-dropdown
          formControlName="Importance"
          [options]="importance"
          placeholder="{{ 'SELECT' | translate }}"
          [style]="{ width: '100%' }"
        ></p-dropdown>
        <control-messages
          [control]="deviationForm.controls['Importance']"
        ></control-messages>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <label>
          <span translate>DEADLINE</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="col-sm-9">
        <my-date-picker
          [options]="dateOptions1"
          placeholder="{{ 'SELECT_DEADLINE_DATE' | translate }}"
          [style]="{ width: '100%' }"
          formControlName="Deadline"
        ></my-date-picker>
        <control-messages
          [control]="deviationForm.controls['Deadline']"
        ></control-messages>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <label>
          <span translate>RESPONSIBLE_EXECUTION</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="col-sm-9">
        <p-dropdown
          formControlName="ExecutionUserId"
          [options]="execution"
          [style]="{ width: '100%' }"
        >
        </p-dropdown>
        <control-messages
          [control]="deviationForm.controls['ExecutionUserId']"
        ></control-messages>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <label>
          <span translate>RESPONSIBLE_FOR_FOLLOWUP</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="col-sm-9">
        <p-dropdown
          formControlName="FollowupUserId"
          [options]="execution"
          [style]="{ width: '100%' }"
        >
        </p-dropdown>
        <control-messages
          [control]="deviationForm.controls['FollowupUserId']"
        ></control-messages>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <label>
          <span translate>CORRECTIVE_ACTION</span>
        </label>
      </div>
      <div class="col-sm-9">
        <textarea
          pInputTextarea
          class="form-control single-line"
          autoResize="autoResize"
          formControlName="CorrectiveAction"
        ></textarea>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <label>
          <span translate>REFER_VIOLATED_PARA</span>
        </label>
      </div>
      <div class="col-sm-9">
        <p-multiSelect
          formControlName="ParaViolated"
          [options]="violatedParagraphs"
          [filter]="true"
          [style]="{ width: '380px' }"
          defaultLabel="{{ 'CHOOSE' | translate }}"
        >
        </p-multiSelect>
      </div>
    </div>
    <div class="form-group row" *ngIf="isOffer">
      <div class="col-sm-3">
        <label>
          <span translate>OFFER_INCLUDE</span>
        </label>
      </div>
      <div class="col-sm-9">
        <textarea
          pInputTextarea
          class="form-control single-line"
          autoResize="autoResize"
          formControlName="OfferInclude"
        ></textarea>
      </div>
    </div>
    <div class="form-group row" *ngIf="isOffer">
      <div class="col-sm-3">
        <label>
          <span translate>OFFER_PRICE</span>
        </label>
      </div>
      <div class="col-sm-9">
        <input type="number" formControlName="OfferPrice" pInputText />
      </div>
    </div>
    <div>
      <ecpl-document-explorer
        [uploaderConfig]="upConfig"
        [uploadedFiles]="uploadedFiles"
        (saveChanges)="saveUploaded($event)"
      >
      </ecpl-document-explorer>
    </div>
    <div *ngIf="isGuestHideButton" class="action-btns-wrapper">
      <div class="pull-right">
        <button
          *ngIf="isOffer"
          type="button"
          class="btn btn-outline-secondary"
          (click)="addOffer()"
        >
          <span translate>REMOVE_OFFER</span>
        </button>
        <button
          *ngIf="!isOffer"
          type="button"
          class="btn btn-outline-secondary"
          (click)="addOffer()"
        >
          <span translate>ADD_OFFER</span>
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          (click)="saveDeviation(true)"
          [disabled]="!deviationForm.valid"
        >
          <span translate>SAVE_AND_BACK</span>
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          (click)="saveDeviation()"
          [disabled]="!deviationForm.valid"
        >
          <span translate>SAVE</span>
        </button>
      </div>
    </div>
  </form>
</p-dialog>
<p-confirmDialog
  *ngIf="enableForm != 'measure'"
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<div class="article-modal" *ngIf="previewImages.length">
  <div class="modal-dialog">
    <div class="modal-header">
      <h4>
        <span translate>PREVIEW</span>
      </h4>
      <button (click)="hideModal()" class="btn btn-icon close">
        <span class="icon ic-md icon-multiply"></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="container">
        <div class="text-center" *ngFor="let item of previewImages">
          <div>{{ item.filename }}</div>
          <img
            src="{{ item.path }}"
            alt=""
            style="width: 950px; height: 700px"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<p-dialog
  header="{{ 'ADD_FILES' | translate }}"
  *ngIf="distTemplateEmptyMsg"
  [modal]="true"
  [(visible)]="distTemplateEmptyMsg"
  [style]="{ width: '400px' }"
  [resizable]="false"
  [draggable]="false"
  [contentStyle]="{ overflow: 'visible' }"
>
  <span translate>NO_TEMPLATE_DISTRIBUTION</span>
</p-dialog>
<!--Panel Loader-->
<div class="panel-loader" *ngIf="showLoader">
  <span>
    <span class="loader-icon"></span>
    <span>Processing...</span>
  </span>
</div>
<!--Panel Loader-->

import { DeviationShared } from '../../deviation/models/deviationShared';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import 'rxjs/add/operator/map'
import { Observable } from 'rxjs/Observable';

import { BaseServices } from './../../kuba.services';
import { Pagination, PaginatedResult } from 'src/app/_models/options';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import { SessionFeature } from 'src/app/_models';

@Injectable()
export class DeviationServices {

    _caseType: Observable<any> = null!;
    constructor(private http: HttpClient) {
    }

    clearCachedCaseType() {
        this._caseType = null!;
    }
    getDepartments() {
        return this.http.get(environment.BASE_URL + '/alldepartments', )
            .map((result: any) => result);

    }
    // bind the type of case
    getAllCaseType() {
        return this.http.get(environment.BASE_URL + '/casetypes/' + BaseServices.BusinessId
            + '/' + BaseServices.roleId, )
            .map((result: any) => result);
    }
    getClients(id: number) {
        return this.http.get(environment.BASE_URL + '/client/getall/' + id, )
            .map((result: any) => result);
    }
    getDeviationsByUser(id: number) {
        return this.http.get(environment.BASE_URL + '/deviationbyuser/' + id, )
            .map((result: any) => result);
    }

    addDeviation(data: any) {
        return this.http.post(environment.BASE_URL + '/DeviationHeader', data, )
            .map((response: any) => { return response });
    }

    getAllTopics(Id: number) {
        return this.http.get(environment.BASE_URL + '/DeviationTopic/' + Id, )
            .map((result: any) => result);
    }
    getTopicsForAdmin(riskId: number) {
        return this.http.get(environment.BASE_URL + '/topicsforadmin/' + riskId, )
            .map((result: any) => result);
    }
    getDepartmentsForAdmin(riskId: number) {
        return this.http.get(environment.BASE_URL + '/departmentforadmin/' + riskId, )
            .map((result: any) => result);
    }
    // add the type case
    addTypeCase(data: any) {
        return this.http.post(environment.BASE_URL + '/CaseType', data, )
            .map((response: any) => { return response });
    }
    // add  the department
    addDepartment(data: any) {
        return this.http.post(environment.BASE_URL + '/department', data, )
            .map((response: any) => { return response });
    }
    // update  the department
    updateDepartment(Id: number, data: any) {
        return this.http.put(environment.BASE_URL + '/department/' + Id, data, )
            .map((response: any) => { return response });

    }
    // delete  the department
    deleteDepartment(Id: number) {
        return this.http.delete(environment.BASE_URL + '/departmentdelete/' + Id, )
            .map((response: any) => { return response; });
    }
    // update the typecase
    updateCaseType(Id: number, data: any) {
        return this.http.put(environment.BASE_URL + '/CaseType/' + Id, data, )
            .map((response: any) => { return response });

    }
    // delete the typecase
    deleteTypeCase(Id: number) {
        return this.http.delete(environment.BASE_URL + '/CaseType/' + Id, )
            .map((response: any) => { return response });
    }
    // delete the topics
    deleteTopics(Id: number) {
        return this.http.delete(environment.BASE_URL + '/Delete/' + Id, )
            .map((response: any) => { return response });
    }
    // add the topics
    addTopics(data: any) {
        return this.http.post(environment.BASE_URL + '/Save', data, )
            .map((response: any) => { return response });
    }
    // update the topics
    updateTopics(data: any) {
        return this.http.put(environment.BASE_URL + '/Update', data, )
            .map((response: any) => { return response });
    }
    // list the deviations
    list() {
        return this.http.get(environment.BASE_URL + '/deviations', )
            .map(result => result);
    }
    deleteDeviation(id: any) {
        return this.http.delete(environment.BASE_URL + '/deviation/' + id, )
            .toPromise()
            .then((response: any) => { return response });
    }
    getUser(id: number) {
        return this.http.get(environment.BASE_URL + '/project/ContactLookup/' + id, )
            .map((result: any) => result);
    }
    getDeviationByBusiness(id: number) {
        return this.http.get(environment.BASE_URL + '/deviationslist/' + id, )
            .map((result: any) => result);
    }
    getFeatureByBusiness(id: number): Observable<string> {
        return this.http.get<string>(`${environment.BASE_URL}/featurelist/${id}`, );
    }
    // bind business dropdown
    getBusiness() {
        return this.http.get(environment.BASE_URL + '/businesses', )
            .map((result: any) => result);
    }
    // bind deviation list
    getDeviationListWithDateFilter(startDate: string, endDate: string) {
        return this.http.get(`${environment.BASE_URL}/deviations/${startDate}/${endDate}`, )
            .map(result => result);
    }
    getAllDeviationByDepartment(userId: any) {
        return this.http.get(`${environment.BASE_URL}/deviationbydepartment/${userId}`, )
            .map(result => result);
    }
    saveDocument(document: any) {
        return this.http.post(environment.BASE_URL + '/file/upload/business/followup/deviation-docs', document, )
            .map(result => result);
    }

    // bind portal department
    getPortalDepartment() {
        return this.http.get(environment.BASE_URL + '/getdepartmentforportal', )
            .map((result: any) => result);
    }
    getById(id: number) {
        return this.http
            .get(
                environment.BASE_URL + '/deviation/' + id,

            )
            .map(result => result);
    }
    getResponsibleAndAssignedUsers(businessId: number, featureId: number, featureKey: number) {
        return this.http
            .get(`${environment.BASE_URL}/assignedresponsibleusers/${businessId}/${featureId}/${featureKey}`, )
            .map(result => result);
    }

    getRModeFeature() {
        return this.http.get<HttpHeaderResponse>(environment.BASE_URL + '/rmode-feature/' + BaseServices.BusinessId, )
            .pipe(map((response: HttpHeaderResponse) => {
                return response
            }));
    }

}

import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EmployeePrivateDocumentServices {
    item: Object;
    constructor(private http: HttpClient) {

    }

    list(empId: number, type:any) {
        return this.http.get(environment.BASE_URL + '/employee/' + empId + '/private-docs' + '/' + parseInt(type), )
            .map((result:any) => {
                let data = result || [];
                if (data.length > 0) {
                    data.forEach((d:any) => {
                        d.CreatedOn = new Date(d.CreatedOn);
                        d.ModifiedOn = new Date(d.ModifiedOn);
                    });
                    result.data = data;
                    return result;
                }
            });
    }

    delete(empId:number,id: number) {
        return this.http.delete(environment.BASE_URL + '/employee/' + empId + '/private-doc/' + id, )
            .map(result => result);
    }
    save(document:any, empId:number) {
        return this.http.post(environment.BASE_URL + '/employeedocument', document, )
            .map(result => result);
    }

    saveDocs(documents:any, empId:number) {
        return this.http.post(environment.BASE_URL + '/employee/' + empId + '/private-docs', documents, )
            .map(result => result);
    }

    getbyId(id : number) {
        return this.http.get(environment.BASE_URL + '/employee/' + id + '/private-doc/' + id, )
                .map(result => result);
    }


    update(document: any, isSendApproval:any, empId:any,FeatureKey :number,type:any) {
        return this.http.put(environment.BASE_URL + '/employee/' + empId + '/private-doc/' + empId + '/' + isSendApproval + '/' + FeatureKey  + '/' + BaseServices.userLanguageId + '/' +  type, document, )
            .map(result => result);
    }
    updateDoc(docId:any, empId:any,type:any) {
        return this.http.put(environment.BASE_URL + '/employee/' + empId + '/private-doc/update/' + docId , {})
            .map(result => result);
    }

    getApprovalDocuments(empId,bId,PortalId,type) {
        return this.http.get(environment.BASE_URL + '/employee/' + empId + '/approval-doc/' + parseInt(bId)+ '/' + parseInt(PortalId)  + '/' + parseInt(type), )
        .map((result: any) => result);
    }
}

import { TranslateService } from '@ngx-translate/core';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseServices } from './../../kuba.services';
import { MaterialItemLookup } from '../models/material-item-lookup';
import { MaterialItem } from '../models/material-item';
import { FDVDocumentList } from '../models/fdv-document-list';
import { TreeData } from './../../common/models/tree-data.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FdvDocumentServices {

  featureId: number;
  workingModule: any;
  constructor(private http: HttpClient,
              private translate: TranslateService) {
    this.workingModule = JSON.parse(
      sessionStorage.getItem('workingModule')
    );
    this.featureId = this.workingModule.id;
  }

  uploadEFO(appId: number, businessId: number, featurekey: number,filename: string, efoJson: string) {
    let sData = {
      ApplicationId: +appId,
      BusinessId: +businessId,
      UserId: +BaseServices.UserId,
      ProjectId: +featurekey,
      EfoJsonData: efoJson,
      FileName: filename
    };
    return this.http
      .post(
        `${
          environment.BASE_URL
        }/project/efodocument/materiallookup/save`,
        sData
      )
      .map(result => result)
      .catch(error => BaseServices.handleError(error));

  }

  saveOwnDocumentUploadedFile(itemList: MaterialItemLookup[]) {
    return this.http.post(environment.BASE_URL + '/project/owndocument/materiallookup/save', itemList)
      .map((response: any) => { return response });
  }

  SaveOwnDocumentMaterialItem(itemList: MaterialItem[]) {
    return this.http.post(environment.BASE_URL + '/project/owndocument/materialitem/save', itemList)
      .map((response: any) => { return response });
  }

  GetFDVEFODocument(applicationId: number, businessId: number, projectId: number) {
    return this.http
      .get(
        environment.BASE_URL +
        '/project/efodocumentlist/get/' +
        applicationId +
        '/' +
        businessId +
        '/' +
        projectId
      )
      .map(result => result);
  }

  DeleteFDVFfoDocument(fDVDocumentList: any[]) {
    return this.http.put(environment.BASE_URL + '/project/efodocumentlist/delete',
      fDVDocumentList).map((response: any) => { return response });
  }

  GetForvaltningELDocument(featureId: number, businessId: number, projectId: number) {
    return this.http
      .get(
        environment.BASE_URL +
        '/feature/article/forvaltningELArticle/' +
        projectId +
        '/' +
        businessId
      )
      .map(result => result);
  }

  GetFDVCommonDocument(featureId: number, businessId: number, projectId: number) {

    let lang = BaseServices.userCultureInfo(this.translate.currentLang);
    return this.http
      .get(
        environment.BASE_URL +
        '/project/commondocumentlist/get/' +
        featureId +
        '/' +
        businessId +
        '/' +
        projectId + '/' + lang
      )
      .map(result => result);
  }

  DeleteFDVCommonDocunet(commonList: any, projectId: number) {
    return this.http.put(`${environment.BASE_URL}/project/commondocumentlist/delete/${
        projectId}`,
      commonList,
    )
      .map((response: any) => { return response });
  }

  DeleteFDVCommonDocunetList(commonList: any[], projectId: number) {
    return this.http.put(`${environment.BASE_URL}/project/commondocumentlist/list/delete/${
        projectId}`,
      commonList,
    )
      .map((response: any) => { return response });
  }

  GetFDVOwnDocument(applicationId: number, businessId: number, projectId: number) {
    return this.http
      .get(
        environment.BASE_URL +
        '/project/owndocument/owndocumentlist/get/' +
        applicationId +
        '/' +
        businessId +
        '/' +
        projectId
      )
      .map(result => result);
  }

  getProjectFDVStatus(pId: number) {
    return this.http.get(environment.BASE_URL + '/project/fdvStatus/' + pId)
      .map(result => result);
  }


  SaveFDVEFODocumentList(fDVDocumentList: FDVDocumentList[], businessId: number, projectId: number) {

    return this.http.put(`${environment.BASE_URL}/project/efodocumentlist/save/${
        businessId}/${projectId
      }`,
      fDVDocumentList,
    )
      .map((response: any) => { return response });
  }

  SaveFDVOwnDocumentList(fDVDocumentList: FDVDocumentList[]) {
    return this.http.put(environment.BASE_URL + '/project/owndocument/owndocumentlist/save',
      fDVDocumentList)
      .map((response: any) => { return response });
  }

  DeleteFDVOwnDocument(fDVDocumentList: FDVDocumentList) {
    return this.http.put(environment.BASE_URL + '/project/owndocument/owndocumentlist/delete',
      fDVDocumentList).map((response: any) => { return response });
  }

  SaveProjectPhotoMaterialItem(itemList: MaterialItem[]) {
    return this.http.post(environment.BASE_URL + '/project/photoproject/materialitem/save', itemList)
      .map((response: any) => { return response });
  }

  GetFDVProjectPhoto(applicationId: number, businessId: number, projectId: number) {
    return this.http
      .get(
        environment.BASE_URL +
        '/project/photoproject/photoprojectlist/get/' +
        applicationId +
        '/' +
        businessId +
        '/' +
        projectId
      )
      .map(result => result);
  }

  SaveFDVPhotoList(fDVPhotoProjectList: any[]) {
    return this.http.put(environment.BASE_URL + '/project/photoproject/photodocumentlist/save',
      fDVPhotoProjectList)
      .map((response: any) => { return response });
  }

  SaveForvaltningEL(forvaltningList: any[], projectId: number, businessId: number, userId: number) {
    return this.http.put(`${environment.BASE_URL}/project/forvaltning/forvaltningList/save/${
        projectId}/${businessId}/${userId}`,
      forvaltningList,
    )
      .map((response: any) => { return response });
  }

  DeleteFDVPhotoProject(id: any) {
    return this.http.delete(environment.BASE_URL + '/project/photoproject/photoprojectitem/delete/' +
      id
    ).map((response: any) => { return response });
  }

  ReplaceEFODocument(list: any) {
    return this.http.put(environment.BASE_URL + '/project/commondocumentlist/replaceEFODocument/save',
      list)
      .map((response: any) => { return response });
  }

  ReplaceEFODocumentWithOwnDocument(efoItemId: number, ownDocument: string) {
    return this.http.put(environment.BASE_URL + '/project/commondocumentlist/replaceEFOWithOwnDoc/save/' +
      efoItemId + '/' + ownDocument, null
    )
      .map((response: any) => { return response });
  }



  generateForesSideReport(reportData: any, businessId: number, cultureInfo: string, projectId: number, type: number): Observable<Blob> {
    const dynamicType = 'application/pdf';
    const myData = JSON.stringify({ data: reportData });
    const url = `${environment.BASE_URL}/FDVForeSide/${businessId}/${projectId}/${type}/${cultureInfo}`;

    return this.http.post(url, myData, { responseType: 'blob' });
  }

  generateInnholdsfortegnelse(reportData: any, businessId: number, cultureInfo: string, projectId: number): Observable<Blob> {
    const myData = JSON.stringify({ data: reportData });
    const url = `${environment.BASE_URL}/Innholdsfortegnelse/${businessId}/${projectId}/${cultureInfo}`;

    return this.http.post(url, myData, { responseType: 'blob' });
  }

  generateIndex(reportData: any, businessId: number, cultureInfo: string, projectId: number): Observable<Blob> {

    const myData = JSON.stringify({ data: reportData });
    const url = `${environment.BASE_URL}/Index/${businessId}/${projectId}/${cultureInfo}`;

    return this.http.post(url, myData, { responseType: 'blob' });
  }

  generateProjectDetailReport(reportData: any, businessId: number, cultureInfo: string, projectId: number): Observable<Blob> {
    const myData = JSON.stringify({ data: reportData });
    const url = `${environment.BASE_URL}/export-ProjectDetails/${projectId}/${businessId}/${cultureInfo}`;

    return this.http.post(url, myData, { responseType: 'blob' });
  }

  generateExportMaterialItemFile(reportData: any, businessId: number, cultureInfo: string, projectId: number): Observable<Blob> {
    const myData = JSON.stringify({ data: reportData });
    const url = `${environment.BASE_URL}/exportMaterialItemFile/${businessId}/${projectId}/${cultureInfo}`;

    return this.http.post(url, myData, { responseType: 'blob' });
  }

  generateFDVBulkDownLoad(reportData: any, businessId: number, cultureInfo: string,filename: string, projectId: number, type: number) {
    let myData = JSON.stringify({ data: reportData });
    return this.http.post(environment.BASE_URL + '/fdvBulkDownLoad/'+businessId+'/'+ projectId +'/'+type +'/'+filename+'/'+cultureInfo, myData)
      .map((response: any) => { return response });
  }

  getArticleData(aId: number, fkey: number): Observable<TreeData[]> {
    return this.http
      .get(
        `${environment.BASE_URL}/feature/article/articleTreedata/${aId}/${
          this.featureId
        }/${fkey}`,

      )
      .map((result:any) => result);
  }

  generateArticleFile(reportData: any, aId: number, fkey: number, businessId: number, cultureInfo: string): Observable<Blob> {
    const dynamicType = 'application/pdf';
    const myData = JSON.stringify({ data: reportData });
    const url = `${environment.BASE_URL}/export-ElArticle/${aId}/${this.featureId}/${fkey}/${businessId}/${cultureInfo}`;

    return this.http.post(url, myData, { responseType: 'blob' });
  }

  generateNoEFODocument(reportData: any, itemName: string, type: number, businessId: number): Observable<Blob> {
    const myData = JSON.stringify({ data: reportData });
    const url = `${environment.BASE_URL}/noFDVELDocument/${itemName}/${type}/${businessId}`;

    return this.http.post(url, myData, { responseType: 'blob' });
  }

}

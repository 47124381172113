import {
  HttpClient,
  HttpHeaderResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { ResetCredential } from '../kuba/users/models/user';

import { ApplicationUi, LoggedUser } from '../_models/';
import { IdentityToken } from "../_models/identityToken";
import { UserService } from "./user.service";
import { StorageKeys } from "../_models/storageKeys";

@Injectable()
export class AuthenticationService {
  currentUser = new LoggedUser();
  appUi = new ApplicationUi();
  timeouts = [];
  time: any;
  device: any;
  csrfToken: string | null = null;
  constructor(private http: HttpClient, private router: Router, private userService: UserService) {}

  createAuthorizationHeader(headers: HttpHeaders): HttpHeaders {
    return headers.append('content-type', 'application/json');
  }


  login(username: string, password: string, ipAddress: any): Observable<IdentityToken> {
    let headers = new HttpHeaders();
    headers = this.createAuthorizationHeader(headers);

    return this.http
      .post<IdentityToken>(
        `${environment.BASE_URL}/authentication/login-web`,
        {
          Username: username,
          Password: password,
          ipAddress: ipAddress,
          device: 'Desktop',
        },
        { headers: headers }
      )
      .pipe(
        switchMap((token: IdentityToken) => {
          if (token && token.Role && token.Role !== '') {
            this.userService.setIdentityToken(token);
            return [token];
          } else {
            return throwError(() => new Error('Invalid Role in IdentityToken'));
          }
        }),
        catchError((error) => {
          console.error('Login request failed', error);
          return throwError(() => error);
        })
      );
  }

  forgetPassword(userCredential: any) {
    let headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    return this.http
      .put(
        environment.BASE_URL + '/authentication/forgotpassword',
        userCredential,
        { headers: headers }
      )
      .pipe(map((result) => result));
  }

  public getCsrfToken(): string | null {
      const token = localStorage.getItem(StorageKeys.IDENTITY_TOKEN);
      if (token) {
          return (JSON.parse(token) as IdentityToken).CsrfToken;
      }
      return null;
  }

  getUserIdByToken(credential: ResetCredential) {
    let headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    return this.http
      .post(
        environment.BASE_URL + '/authentication/useridbytoken',
        credential,
        { headers: headers }
      )
      .pipe(map((result) => result));
  }

  resetPassword(userCredential: any) {
    let headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    return this.http
      .put(
        environment.BASE_URL + '/authentication/reset-password',
        userCredential,
        { headers: headers }
      )
      .pipe(map((result) => result));
  }

  sendPasswordLink(userCredential: any, appId: number) {
    let headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    let languageId = sessionStorage.getItem('languageId');
    languageId = languageId == null ? '2' : languageId;
    return this.http
      .post(
        environment.BASE_URL +
        '/authentication/sendpasswordlink/' +
        languageId +
        '/' +
        appId,
        userCredential,
        { headers: headers }
      )
      .pipe(map((result) => result));
  }

  getDecryptedLoginCredentials(encUsername: string, encPassword: string) {
    let headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    let auth = {
      Username: encUsername,
      Password: encPassword,
      RememberMe: false,
    };
    return this.http
      .post<HttpHeaderResponse>(
        environment.BASE_URL + '/authentication/decrypt-logincredentials',
        auth,
        { headers: headers }
      )
      .pipe(
        map((response: HttpHeaderResponse) => {
          let res = response;
          return res;
        })
      );
  }

  /**
   * Refreshes the identity token by sending a request to the server.
   *
   * If the request is successful, the new token is stored in local storage.
   */
  public refreshToken() {
    return this.http.post(`${environment.BASE_URL}/authentication/renew-token-web`, {}, ({withCredentials: true})).pipe(
      tap((response: IdentityToken) => {
        this.userService.setIdentityToken(response);
      })
    );
  }

  public logout() {
    const languageMode = localStorage.getItem('languageMode');
    const languageId = localStorage.getItem('languageId');

    sessionStorage.clear();
    localStorage.clear();

    if (languageMode) {
      localStorage.setItem('languageMode', languageMode);
    }
    if (languageId) {
      localStorage.setItem('languageId', languageId);
    }

    this.http.post(`${environment.BASE_URL}/authentication/logout-web`, {}).subscribe({
      next: (res) => {
        console.log('Logged out and cookies cleared', res);
      },
      error: (err) => {
        console.error('Error clearing cookies:', err);
      }
    });
    this.router.navigate(['login']);
  }

}

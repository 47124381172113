import { BaseServices } from './../../kuba.services';
import { Consumption, ConsumptionType } from '../models/consumptions';
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import 'rxjs/add/operator/map'
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ConsumptionsService {
    constructor(private http: HttpClient) {

    }

    list() {
        let result: Consumption;
        return this.http.get(`${environment.BASE_URL}/consumptions/consumptions/${BaseServices.BusinessId}`, BaseServices.headerOption)
            .map((res:any) => res);
    }

    getById(id: number) {
        return this.http.get(`${environment.BASE_URL}/consumptions/consumption/${id}`,BaseServices.headerOption)
            .map((result: any) => result);
    }
    getConsumptionsTypesByBusiness(businessId: number) {
        let result: ConsumptionType;
        return this.http.get(`${environment.BASE_URL}/consumptions/consumptionBusinessTypes/${businessId}`,BaseServices.headerOption)
            .map((res: any) => res);

    }
    getConsumptionsUnit() {
        return this.http.get(environment.BASE_URL + '/consumptions/consumptionUnits',BaseServices.headerOption)
            .map(result => result);
    }
    add(consumption: Consumption) {
        return this.http.post(environment.BASE_URL + '/consumptions/consumption', consumption, BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    update(id:any, data:any) {
        return this.http.put(environment.BASE_URL + '/consumptions/consumption/' + id, data, BaseServices.headerOption)
            .map((response: any) => { return response });
    }
    delete(id:any) {
        return this.http.delete(environment.BASE_URL + '/consumptions/Consumption/' + id, BaseServices.headerOption)
            .map((response: any) => { return response });

    }

    getConsumptionsUnitByBusinessId(BusinessId: number) {
        return this.http.get(`${environment.BASE_URL}/consumptions/consum/consumptionUnits/${BaseServices.BusinessId}`,BaseServices.headerOption)
            .map((result: any) => result);
    }
}

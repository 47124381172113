import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TimeLogService {
    constructor(private http: HttpClient) {

    }
    getAll() {
        return this.http.get(environment.BASE_URL + '/timelogs')
            .map(result => result);
    }
}

import { BaseServices } from 'src/app/kuba/kuba.services';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class VehicleConsumptionService {

    constructor(private http: HttpClient) {
    }
    getAllVehicleConsumption(vehicleId: number) {
        return this.http.get(`${environment.BASE_URL}/vehicle/consumptions/${vehicleId}`, )
            .map((result: any) => result);
    }
    getAllVehicle(Id: number) {
        return this.http.get(`${environment.BASE_URL}/vehicle/vehicleconsumption/${Id}`, )
            .map((result: any) => result);
    }
    saveVehicleConsumption(vehicle) {
        return this.http.post(`${environment.BASE_URL}/vehicle/consumption`, vehicle, )
            .map(result => result);
    }
    getPreviousNo(Id: number) {
        return this.http.get(`${environment.BASE_URL}/vehicle/vehiclepreviouskm/${Id}`, )
            .map((result: any) => result);
    }
    getVehicleConsumptionById(Id: number) {
        return this.http.get(`${environment.BASE_URL}/vehicle/consumption/${Id}`, )
            .map((result: any) => result);

    }
    // multiple delete
    deleteVehicleConsumption(ids) {
        return this.http.put(`${environment.BASE_URL}/vehicle/deleteconsumptions`, ids, )
            .map(result => result);
    }


}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Electro, ElectroContractContentData, ElectroClientUsers, ElectroContractWorkers } from '../models/electrocontract';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ELService } from 'src/app/kuba/kunde-EL/services/el.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { ElectroService } from '../services/electro.services';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { HelperService } from 'src/app/_services/helper.service';
import { Rights } from 'src/app/_models';
import { CustomValidator } from './shared/custom-validator';
import { IMyDpOptions } from 'mydatepicker';
import { ElectroInstallationBuliding } from '../models/electroinstallation';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
    selector: 'electro-contract-info',
    templateUrl: 'electro-contract-info.component.html'
})

export class ElectroContractInfoComponent implements OnInit {

    @ViewChild('dtIntTask',{static: false}) dtIntTask: Table;
    @ViewChild('dtDevTask',{static: false}) dtDevTask: Table;
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    electro: Electro;
    buildings: ElectroInstallationBuliding;
    inspection: ElectroContractContentData[];
    clientInfoForm: FormGroup;
    installationForm: FormGroup;
    installationContactForm: FormGroup;
    electroForm: FormGroup;
    controlscoprForm: FormGroup;
    isEditMode: boolean;
    clients: any;
    clientContacts: any;
    electroClientUsers: any;
    selectedClientUser = [];
    selectedWorker = [];
    selectedTask = [];
    contractWorkers: any;
    internalTask: any;
    deviationTask: any;
    responsiblePerson: SelectItem[];
    contactFunction: any;
    status: any;
    visibility: any;
    interval: any;
    performedFromYears: any;
    loading: boolean;
    firmName: string;
    addControl = false;
    activeTab: number;
    addInspection = false;
    installationType: any;
    gridSystem: any;
    installationBuildAfter: any;
    contractId: number;
    controlscope: any;
    selectedScope: any;
    inspectionscope: any;
    updateinspection: any;
    installationId: number;
    displayDeleteDailog = false;
    buildingId: number;
    IsInspectionSave = true;
    contractCreatedOn: string;
    contractCreatedBy: number;
    completedTillTab = 0;
    cachedContractNum: string;
    cachedClientNum: string;
    clientNumber: number;
    cachedClientId: number;
    private subscriptions: Subscription[] = [];
    /**
     * Date picker configuration option
     */
    public defaultDateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        inline: false,
        height: '32px',
        selectionTxtFontSize: '14px',
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        },
    };
    /**
     * Date picker configuration option
     */
    public inspectionDateOpitons: IMyDpOptions = this.defaultDateOptions;

    constructor(
        public router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private fb: FormBuilder,
        private electroServices: ElectroService,
        private elServices: ELService,
        private confirmationService: ConfirmationService,
    ) {
        this.contractId = +this.route.snapshot.parent.params['cid'];
        this.electro = this.route.snapshot.data['electro'];
        this.buildings = this.route.snapshot.data['buildings'];
        this.inspection = this.route.snapshot.data['inspection'];
        this.activeTab = 1;
        if (this.electro) {
            this.completedTillTab = 5;
            //bind client contact dropdown and information for previous saved contract.
            this.subscriptions.push(this.elServices.getClientContactUser(this.electro.ClientId).subscribe(result => {
                if (result) {
                    this.electroClientUsers = result;
                    if (this.electro.ClientUsers) {
                        this.selectedClientUser = [];
                        this.electro.ClientUsers.forEach((element: any) => {
                            let selectedUsers = this.electroClientUsers.find(
                                x => x.Id === element.ClientContactId
                            );
                            if (selectedUsers) {
                                this.selectedClientUser.push(selectedUsers);
                            }
                        });
                    }
                }
            }));
            this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                this.clientContacts = [];
                this.clientContacts.push(
                    { label: val.SELECT, value: null });
                    this.subscriptions.push(this.electroServices.getClientContact(this.electro.ClientId).subscribe(result => {
                    if (result) {
                        result.forEach(user => {
                            this.clientContacts.push(
                                { label: user.Name, value: user.Id });
                        });
                        this.clientInfoForm.patchValue({ 'ContactPerson': this.electro.ClientContactId });
                    }
                }));
            }));
        }
        this.bindDropdowns();
        if (this.electro) {
            this.cachedClientId = this.electro.ClientId;
            this.isEditMode = true;
            this.initForm(this.electro);
        }
        else {
            this.initForm();
        }
    }

    ngOnInit() {
        this.subscriptions.push(this.translate.stream('SELECT_INSTALLATION_TYPE').subscribe(val => {
            this.installationType = [];
            this.installationType.push(
                { label: val.SELECT, value: null },
                { label: val.RETIREMENT, value: "RETIREMENT" },
                { label: val.OTHER_COMMERCIAL, value: "OTHER_COMMERCIAL" },
                { label: val.KINDERGARDEN, value: "KINDERGARDEN" },
                { label: val.GAS_STATION, value: "GAS_STATION" },
                { label: val.CAR_REPAIR, value: "CAR_REPAIR" },
                { label: val.APARTMENT, value: "APARTMENT" },
                { label: val.RESIDENTIAL, value: "RESIDENTIAL" },
                { label: val.CAMPING_SITE, value: "CAMPING_SITE" },
                { label: val.WORKSHOPS, value: "WORKSHOPS" },
                { label: val.ASSEMBLY_HOUSING, value: "ASSEMBLY_HOUSING" },
                { label: val.STREETLIGHT_SYSTEM, value: "STREETLIGHT_SYSTEM" },
                { label: val.HEALTHCARE_CENTER, value: "HEALTHCARE_CENTER" },
                { label: val.HOTEL, value: "HOTEL" },
                { label: val.CABIN, value: "CABIN" },
                { label: val.SPORT_CENTER, value: "SPORT_CENTER" },
                { label: val.COATING_FACILITY, value: "COATING_FACILITY" },
                { label: val.TEMPORARY_INSTALLATIONS, value: "TEMPORARY_INSTALLATIONS" },
                { label: val.RESTURANT, value: "RESTURANT" },
                { label: val.HOSPITAL, value: "HOSPITAL" },
                { label: val.BUILDINGS_HISTORICAL, value: "BUILDINGS_HISTORICAL" },
                { label: val.INSTALLATIONS_EXPLOSION, value: "INSTALLATIONS_EXPLOSION" },
                { label: val.Other, value: "Other" }
            );
        }));
        this.subscriptions.push(this.translate.stream('SELECT_GRIDSYSTEM').subscribe(val => {
            this.gridSystem = [];
            this.gridSystem.push(
                { label: val.SELECT, value: null },
                { label: val.TNS, value: "TNS" },
                { label: val.VTT, value: "VTT" },
                { label: val.VIT, value: "VIT" }
            );
        }));
        this.subscriptions.push(this.translate.stream('SELECT_INSTALLATION_BUILD_AFTER').subscribe(val => {
            this.installationBuildAfter = [];
            this.installationBuildAfter.push(
                { label: val.SELECT, value: null },
                { label: val.OLDER_THAN_1963, value: "OLDER_THAN_1963" },
                { label: val.FEA_1963, value: "FEA_1963" },
                { label: val.FEB_1988, value: "FEB_1988" },
                { label: val.FEB_1991, value: "FEB_1991" },
                { label: val.NEK_400_1998, value: "NEK_400_1998" },
                { label: val.NEK_400_2002, value: "NEK_400_2002" },
                { label: val.NEK_400_2006, value: "NEK_400_2006" },
                { label: val.NEK_400_2010, value: "NEK_400_2010" },
                { label: val.NEK_400_2014, value: "NEK_400_2014" },
                { label: val.NEK_400_2018, value: "NEK_400_2018" }
            );
        }));

    }

    /**
     * set active tab
     * @param tab 
     */
    setActiveTab(tab, isSave?: boolean) {
        if (this.contractId && tab !== 6 && isSave) {
            this.SaveElectro(tab);
        }
        this.activeTab = tab;
        this.completedTillTab = this.completedTillTab < (tab - 1) ? (tab - 1) : this.completedTillTab;
    }

    iscontractNumUnique(control: FormControl) {
        const q = new Promise((resolve) => {
            setTimeout(() => {
                if (control.value === this.cachedContractNum) {
                    resolve(null);
                }
                else if ((control.value || '').trim().length === 0) {
                    resolve({ whitespace: true });
                } else {
                    this.subscriptions.push(this.electroServices
                        .checkcontractNum(control.value)
                        .subscribe((x) => {
                            if (!x) {
                                resolve(null);
                            } else {
                                resolve({ 'contractNumberExists': true });
                            }
                        }, () => { resolve({ 'contractNumberExists': true }); }));
                }
            }, 1000);
        });
        return q;
    }
    /**
     * initialize form group and supply values to control on edit.
     * @param data 
     */
    initForm(data?: any) {

        let clientFirm = '';
        let contactPerson = '';
        let installationFirm = '';
        let installationAddress = '';
        let installationZip = '';
        let installationCity = '';
        let houseFolder = '';
        let installationContactId = '';
        let installationFunction = '';
        let contractNumber = '';
        let contractName = '';
        let responsibleUserId = '';
        let visibility = '';
        let status = '';
        let buildingId = '';
        let typeOfInstallation = '';
        let gridSystem = '';
        let yearBuilt = '';
        let builtAfter = '';
        let clientNumber = '';

        if (data) {
            clientFirm = data.ClientId;
            contactPerson = data.ClientContactId;
            installationFirm = data.InstallationFirm;
            installationAddress = data.InstallationAddress;
            installationZip = data.InstallationZip;
            installationCity = data.InstallationCity;
            houseFolder = data.Housefolder;
            installationContactId = data.InstallationContactId;
            installationFunction = data.InstallationContactFunction;
            clientNumber = data.Number;
            contractNumber = data.ContractNumber;
            this.cachedContractNum = data.ContractNumber;
            contractName = data.ContractName;
            responsibleUserId = data.ResponsibleUserId;
            visibility = data.Visibilty;
            status = data.Status;
            this.contractCreatedOn = data.CreatedOn;
            this.contractCreatedBy = data.CreatedBy;
            if (data.ContractWorkers) {
                this.selectedWorker = [];
                data.ContractWorkers.forEach((element: any) => {
                    let selectedWorker = this.contractWorkers.find(
                        x => x.Id === element.UserId
                    );
                    if (selectedWorker) {
                        this.selectedWorker.push(selectedWorker);
                    }
                });
            }
        }
        if (this.buildings) {
            this.controlscope = this.buildings;
            this.completedTillTab = this.controlscope ? this.controlscope.length ? 6 : this.completedTillTab : this.completedTillTab;
        }
        if (this.inspection) {
            this.updateinspection = this.inspection;
            this.inspectionscope = this.inspection.filter((x: any) => x.Status === 1);
            if (this.inspectionscope.length) {
                this.completedTillTab = 7;
            }
            this.selectedTask = [];
            this.inspectionscope.forEach((element: any) => {
                let selectedTask = this.internalTask.find(
                    x => x.Id === element.ContentId
                );
                if (selectedTask) {
                    selectedTask.Interval = element.Interval;
                    selectedTask.Cost = element.Cost;
                    selectedTask.Unit = element.Unit;
                    selectedTask.PerformedForm = element.PerformedForm;
                    this.selectedTask.push(selectedTask);
                }
                element.LastPerformed = HelperService.formatInputDate(element.LastPerformed);
                element.NextInspection = HelperService.formatInputDate(element.NextInspection);
            });
        }
        this.clientInfoForm = this.fb.group({
            'clientFirm': [clientFirm, Validators.required],
            'clientNumber': [''],
            'Address': [''],
            'ZipCode': [''],
            'City': [''],
            'PhoneNumber': [''],
            'Email': [''],
            'ContactPerson': [contactPerson]
        });

        this.installationForm = this.fb.group({
            'InstallationFirm': [installationFirm],
            'InstallationAddress': [installationAddress],
            'InstallationZip': [installationZip],
            'InstallationCity': [installationCity],
            'Housefolder': [houseFolder]
        });
        this.installationContactForm = this.fb.group({
            'Name': [installationContactId],
            'PhoneNumber': [''],
            'Email': [''],
            'Function': [installationFunction]
        });
        this.electroForm = this.fb.group({
            'ContractNumber': [contractNumber, Validators.required, this.isClientAndContractNumUnique.bind(this)],
            'ContractName': [contractName, [Validators.required, CustomValidator.noWhitespaceValidator]],
            'ResponsibleUserId': [responsibleUserId, Validators.required],
            'Visibilty': [visibility, Validators.required],
            'Status': [status, Validators.required]
        });
        this.controlscoprForm = this.fb.group({
            'BuildingId': ['', Validators.required],
            'TypeOfInstallation': ['', Validators.required],
            'GridSystem': [''],
            'YearBuilt': ['', Validators.required],
            'BuiltAfter': ['', Validators.required],
            'YearUpgraded': ['']
        });
        if (this.electro) {
            this.getClientInfoByClientId(data.ClientId);
            this.getContactById(data.InstallationContactId);
        }
        this.activeTab = this.completedTillTab < 7 ? this.completedTillTab + 1 : 1;
    }

    /**
     * @param event 
     * validation for year
     */
    checkNumber(event: any): boolean {
        let k = event.keyCode;
        if ((k < 48) || (k > 57)) {
            return false;
        }
        return true;
    }

    /**
     * bind form dropdown data
     */
    bindDropdowns() {
        this.subscriptions.push(this.translate.stream('SELECT_FUNCTION').subscribe(val => {
            this.contactFunction = [];
            this.contactFunction.push(
                { label: val.SELECT, value: '' },
                { label: val.OWNER, value: 1 },
                { label: val.USER, value: 2 },
                { label: val.OTHER, value: 3 }
            );
        }));
        this.subscriptions.push(this.translate.stream('SELECT_CONTRACT_STATUS').subscribe(val => {
            this.status = [];
            this.status.push(
                { label: val.SELECT, value: '' },
                { label: val.ACTIVE, value: 1 },
                { label: val.TERMINATED, value: 2 }
            );
        }));
        this.subscriptions.push(this.translate.stream('SELECT_VISIBILITY').subscribe(val => {
            this.visibility = [];
            this.visibility.push(
                { label: val.SELECT, value: '' },
                { label: val.VISIBLE, value: 1 },
                { label: val.HIDDEN, value: 2 }
            );
        }));
        this.subscriptions.push(this.translate.stream('SELECT_INTERVAL').subscribe(val => {
            this.interval = [];
            this.interval.push(
                { label: val.SELECT, value: 0 },
                { label: val.NONE, value: 1 },
                { label: val.EVERY_MONTH, value: 2 },
                { label: val.EVERY_3_MONTH, value: 3 },
                { label: val.EVERY_6_MONTH, value: 4 },
                { label: val.ANNUALY, value: 5 },
                { label: val.EVERY_OTHER_YEAR, value: 6 },
                { label: val.EVERY_THIRD_YEAR, value: 7 },
                { label: val.EVERY_FOURTH_YEAR, value: 9 },
                { label: val.EVERY_FIFTH_YEAR, value: 8 }
            );
        }));
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.performedFromYears = [];
            this.performedFromYears.push({ label: val.SELECT, value: null });
        }));
        let curYear = new Date().getFullYear();
        for (let n = 1; n <= 10; n++) {
            this.performedFromYears.push({ label: curYear + n, value: curYear + n });
        }
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            let Clients = this.route.snapshot.data['clientswithClientNum'];
            let Workers = this.route.snapshot.data['contractworkers'];
            let Content = this.route.snapshot.data['contractcontent'];
            this.clients = [];
            this.responsiblePerson = [];
            this.clients.push({ label: val.SELECT, value: null });
            this.responsiblePerson.push({ label: val.SELECT, value: null });
            if (Workers) {
                let contractWorkers = [];
                Workers.forEach((user: any) => {
                    let rights = (user.Rights) ? JSON.parse(user.Rights) : null;
                    //filter persons with electro rights.
                    let LeaderAccess = HelperService.ApprovalPersonRightsCheck(rights, Rights.SERVICE_LEADER_ELECTRO);
                    if (!LeaderAccess) {
                        let InstallerAccess = HelperService.ApprovalPersonRightsCheck(rights, Rights.SERVICE_INSTALLER_ELECTRO);
                        if (InstallerAccess) {
                            contractWorkers.push(user);
                        }
                        if (user.RoleId == 3) {
                            this.responsiblePerson.push({ label: user.Name, value: user.Id });
                            contractWorkers.push(user);
                        }
                    }
                    else {
                        this.responsiblePerson.push({ label: user.Name, value: user.Id });
                        contractWorkers.push(user);
                    }
                });
                this.contractWorkers = contractWorkers;
            }
            if (Clients) {
                Clients.forEach(client => {
                    this.clients.push({ label: client.Name, value: client.Id })
                });
            }
            if (Content) {
                this.internalTask = [];
                this.deviationTask = [];
                this.internalTask = Content.filter(x => !(x.InspectionId));
            }
        }));

    }

    /**
     * client change event
     * @param event 
     * @param clientDropdown 
     */
    onClientChanged(event, clientDropdown) {
        this.clientInfoForm.value.ContactPerson = null;
        this.bindClientUser(event.value);
        this.bindClientContacts(event.value);
        this.getClientInfoByClientId(event.value);
        if (event.value == this.cachedClientId) {
            this.electroForm.patchValue({ ContractNumber: this.cachedContractNum });
        } else {
            this.subscriptions.push(this.electroServices.GetHighestContractNumOnClient(event.value).subscribe(res => {
                if (res) {
                    this.electroForm.patchValue({ ContractNumber: (+res) + 1 });
                }
            }))
        }
    }

    /**
     * contact erson change event.
     * @param event 
     */
    onContactChange(event) {
        this.getContactById(event.value);
    }

    /**
     * bind client users based on client selected.
     * @param clientId 
     */
    bindClientUser(clientId: number) {
        this.subscriptions.push(this.elServices.getClientContactUser(clientId).subscribe(result => {
            if (result) {
                this.electroClientUsers = result;
            }
        }));
    }

    /**
     * bind client contacts based on client selected.
     * @param clientId 
     */
    bindClientContacts(clientId: number) {
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.clientContacts = [];
            this.clientContacts.push(
                { label: val.SELECT, value: null });
                this.subscriptions.push(this.electroServices.getClientContact(clientId).subscribe(result => {
                if (result) {
                    result.forEach(user => {
                        this.clientContacts.push(
                            { label: user.Name, value: user.Id });
                    });
                }
            }));
        }));

    }

    /**
     * get client for client selected.
     * @param clientId 
     */
    getClientInfoByClientId(clientId: number) {
        if (clientId) {

            this.subscriptions.push(this.electroServices.getClientById(clientId).subscribe(result => {
                if (result) {
                    this.clientInfoForm.patchValue({
                        'clientNumber': result.Number,
                        'Address': result.Address,
                        'ZipCode': result.Zip,
                        'City': result.Location,
                        'PhoneNumber': result.Mobile,
                        'Email': result.Email,
                    });
                    this.firmName = result.Name;
                    this.clientNumber = result.Number;
                }
            }));
        }
    }

    /**
     * get contact detail by contactid.
     * @param contactId 
     */
    getContactById(contactId: number) {
        if (contactId) {
            this.subscriptions.push(this.electroServices.getClientInfoById(contactId).subscribe(result => {
                if (result) {
                    this.installationContactForm.patchValue({
                        'PhoneNumber': result.Mobile,
                        'Email': result.Email
                    });
                }
            }));
        }
    }

    /**
     * Same as client installation event
     */
    SameInstallation() {
        this.installationForm.patchValue({
            'InstallationFirm': this.firmName,
            'InstallationAddress': this.clientInfoForm.value.Address,
            'InstallationZip': this.clientInfoForm.value.ZipCode,
            'InstallationCity': this.clientInfoForm.value.City,
        });
    }

    /**
     * Same as client contact event
     */
    SameContact() {
        this.installationContactForm.patchValue({
            'Name': this.clientInfoForm.value.ContactPerson,
        });
        this.getContactById(this.clientInfoForm.value.ContactPerson);
    }

    /**
     * On task interval change
     * @param taskId 
     * @param event 
     */
    onIntervalChange(taskId: number, event) {
        this.selectedTask.forEach(task => {
            if (task.Id == taskId) {
                task.Interval = event.value;
            }
        });
    }

    /**
     * On performed form change.
     * @param taskId 
     * @param event 
     */
    onPerformedFormChange(taskId: number, event) {
        this.selectedTask.forEach(task => {
            if (task.Id == taskId) {
                task.PerformedForm = event.value;
            }
        });
    }

    /**
     * save electro contract info.
     * @param tab 
     */
    SaveElectro(tab) {
        let ElectroInfo = new Electro();
        ElectroInfo.ContractNumber = this.electroForm.value.ContractNumber;
        ElectroInfo.ContractName = this.electroForm.value.ContractName;
        ElectroInfo.ClientId = this.clientInfoForm.value.clientFirm;
        ElectroInfo.ClientContactId = this.clientInfoForm.value.ContactPerson;
        ElectroInfo.InstallationFirm = this.installationForm.value.InstallationFirm;
        ElectroInfo.InstallationAddress = this.installationForm.value.InstallationAddress;
        ElectroInfo.InstallationZip = this.installationForm.value.InstallationZip;
        ElectroInfo.InstallationCity = this.installationForm.value.InstallationCity;
        ElectroInfo.Housefolder = this.installationForm.value.Housefolder;
        ElectroInfo.InstallationContactId = this.installationContactForm.value.Name;
        ElectroInfo.InstallationContactFunction = this.installationContactForm.value.Function;
        ElectroInfo.ResponsibleUserId = this.electroForm.value.ResponsibleUserId;
        ElectroInfo.Status = this.electroForm.value.Status;
        ElectroInfo.Visibilty = this.electroForm.value.Visibilty;
        ElectroInfo.Comments = this.electroForm.value.ContractNumber;
        ElectroInfo.BusinessId = BaseServices.BusinessId;
        ElectroInfo.ApplicationId = BaseServices.ApplicationId;
        ElectroInfo.ContractContentsData = [];
        ElectroInfo.ClientUsers = [];
        ElectroInfo.ContractWorkers = [];
        this.selectedClientUser.forEach(user => {
            let data = new ElectroClientUsers();
            data.ClientContactId = user.Id;
            ElectroInfo.ClientUsers.push(data);
        });
        this.selectedWorker.forEach(worker => {
            let data = new ElectroContractWorkers();
            data.UserId = worker.Id;
            ElectroInfo.ContractWorkers.push(data);
        });
        if (this.contractId) {
            ElectroInfo.Id = this.contractId;
            ElectroInfo.ModifiedBy = BaseServices.UserId;
            ElectroInfo.CreatedOn = this.contractCreatedOn;
            ElectroInfo.CreatedBy = this.contractCreatedBy;
            this.subscriptions.push(this.electroServices.updateElectro(ElectroInfo).subscribe(result => {
                if (result) {
                    if (tab === 6) {
                        this.setActiveTab(tab);
                    }
                    this.contractId = result.Id;
                    this.toasterComponent.callToast();
                }
            }));
        }
        else {
            ElectroInfo.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.electroServices.saveElectro(ElectroInfo).subscribe(result => {
                if (result) {
                    this.contractCreatedOn = result.CreatedOn;
                    this.contractCreatedBy = result.CreatedBy;
                    if (tab === 6) {
                        this.setActiveTab(tab);
                    }
                    this.contractId = result.Id;
                    this.toasterComponent.callToast();
                }
            }));
        }
    }

    /**
     * save installation buildings.
     * @param type 
     */
    SaveInstallation(type) {
        let InstallationBuilding = new ElectroInstallationBuliding();
        InstallationBuilding.ElectroContractId = this.contractId;
        InstallationBuilding.BusinessId = BaseServices.BusinessId;
        InstallationBuilding.BuildingNumber = this.controlscoprForm.value.BuildingId;
        InstallationBuilding.InstallationTypeId = this.controlscoprForm.value.TypeOfInstallation;
        InstallationBuilding.GridSystemId = this.controlscoprForm.value.GridSystem;
        InstallationBuilding.InstallationBuildAfterId = this.controlscoprForm.value.BuiltAfter;
        InstallationBuilding.YearUpgraded = this.controlscoprForm.value.YearUpgraded;
        InstallationBuilding.YearBuilt = this.controlscoprForm.value.YearBuilt;
        InstallationBuilding.Status = 1;
        if (this.installationId) {
            InstallationBuilding.Id = this.installationId;
            this.subscriptions.push(this.electroServices.updateInstallationBuilding(InstallationBuilding).subscribe(result => {
                if (result) {
                    this.completedTillTab = 7;
                    this.installationId = 0;
                    this.controlscoprForm.reset();
                    this.getControlScope();
                    if (type == 'SAVE')
                        this.closeControlScope();
                    this.toasterComponent.callToast();
                }
            }));
        }
        else {
            this.subscriptions.push(this.electroServices.saveInstallationBuilding(InstallationBuilding).subscribe(result => {
                if (result) {
                    this.controlscoprForm.reset();
                    this.getControlScope();
                    if (type == 'SAVE')
                        this.closeControlScope();
                    this.toasterComponent.callToast();
                }
            }));
        }
    }

    /**
     * add control scope
     */
    addControlScope() {
        this.addControl = true;
    }

    /**
     * close control scope dialog
     */
    closeControlScope() {
        this.addControl = false;
    }

    /**
     * get control scope buildings.
     */
    getControlScope() {
        this.subscriptions.push(this.electroServices.getInstallationBuilding(this.contractId).subscribe(result => {
            if (result) {
                this.controlscope = result;
            }
        }));
    }

    /**
     * add inspection scope
     */
    addInspectionScope() {
        this.addInspection = true;
    }

    /**
     * edit control scope
     * @param controlscope 
     */
    editControlScope(controlscope: any) {
        this.installationId = controlscope.Id;
        let objcontrol: any;
        objcontrol = {
            BuildingId: controlscope.BuildingNumber,
            TypeOfInstallation: controlscope.InstallationTypeId,
            GridSystem: controlscope.GridSystemId,
            YearBuilt: controlscope.YearBuilt,
            BuiltAfter: controlscope.InstallationBuildAfterId,
            YearUpgraded: controlscope.YearUpgraded
        };
        (<FormGroup>this.controlscoprForm).setValue(objcontrol, {
            onlySelf: true
        });
        this.addControl = true;
    }

    /**
     * delete control scope
     * @param Id 
     */
    deleteControlScope(Id: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_BUILDINGS'),
            accept: () => {
                this.displayDeleteDailog = true;
                this.buildingId = Id;
            }
        });
    }

    /**
     * delete building
     * @param status 
     */
    deleteBuildingFolder(status) {
        if (this.buildingId) {
            this.subscriptions.push(this.electroServices.deleteInstallationBuilding(this.buildingId, status).subscribe(isDeleted => {
                if (isDeleted) {
                    this.displayDeleteDailog = false;
                    this.getControlScope();
                    this.toasterComponent.callToastDlt();
                }
            }));
        }
    }

    /**
     * close inspection scope dialog
     */
    closeInspectionScope() {
        this.addInspection = false;
    }



    /**
     * save inspection tasks enabled
     * @param type 
     */
    SaveInspection(type) {
        let enabledTasks = new Electro();
        enabledTasks.Id = this.contractId;
        enabledTasks.ContractContentsData = [];
        if (type == 'save' && this.inspectionscope) {
            this.inspectionscope.forEach(e => {
                let t = new ElectroContractContentData();
                t.Id = e.Id;
                t.ContentId = e.ContentId;
                t.ElectroContractId = e.ElectroContractId;
                t.Interval = e.Interval;
                t.Status = e.Status ? e.Status : 1;
                t.LastPerformed = e.LastPerformed ? HelperService.formatDateFilter(e.LastPerformed.formatted) : null;
                t.NextInspection = e.NextInspection ? HelperService.formatDateFilter(e.NextInspection.formatted) : null;
                enabledTasks.ContractContentsData.push(t);
            })

        }
        else {
            this.selectedTask.forEach(e => {
                let t = new ElectroContractContentData();
                t.ContentId = e.Id;
                //collect saved task by matching content and inspection level
                let savedTask;
                if (this.updateinspection) {
                    savedTask = this.updateinspection.find(x =>
                        (x.ContentId == t.ContentId))
                }
                t.Id = savedTask ? savedTask.Id : 0;
                t.Interval = (savedTask && savedTask.Interval) ? savedTask.Interval : null;
                t.LastPerformed = (savedTask && savedTask.LastPerformed) ? HelperService.formatDateFilter(savedTask.LastPerformed.formatted) : null;
                t.NextInspection = (savedTask && savedTask.NextInspection) ? HelperService.formatDateFilter(savedTask.NextInspection.formatted) : null;
                t.ElectroContractId = this.contractId;
                t.Status = (savedTask && savedTask.Status) ? savedTask.Status : 1;
                enabledTasks.ContractContentsData.push(t);
            });
        }
        this.subscriptions.push(this.electroServices.saveInspectionScope(enabledTasks, type).subscribe(result => {
            if (result) {
                this.getInspection();
                this.toasterComponent.callToast();
                this.closeInspectionScope();
                //modifying the cid in url to saved contract id
                if (type == 'save') {
                    this.router.navigate([`../../${this.contractId}/editcontent`],
                        { relativeTo: this.route });
                }
            }
        }));
    }

    /**
     * get inspection scope
     */
    getInspection() {
        this.subscriptions.push(this.electroServices.getInspectionScope(this.contractId).subscribe(result => {
            if (result) {
                this.updateinspection = result;
                this.inspectionscope = result.filter((x: any) => x.Status === 1);
                this.inspectionscope.forEach((element: any) => {
                    element.LastPerformed = HelperService.formatInputDate(element.LastPerformed);
                    element.NextInspection = HelperService.formatInputDate(element.NextInspection);
                });
            }
        }));
    }

    /**
     * delete inspection task
     * @param InspectionId 
     */
    deleteInspection(InspectionId) {
        this.subscriptions.push(this.electroServices.deleteInspectionScope(InspectionId).subscribe(isDeleted => {
            if (isDeleted) {
                this.getInspection();
                this.toasterComponent.callToastDlt();
            }
        }));
    }

    /**
     * event on changes in inspection.
     * @param inspection 
     */
    onInspectionChange(inspection) {
        if (inspection) {
            for (let element of inspection) {
                if (!element.Interval || !element.NextInspection) {
                    this.IsInspectionSave = true;
                    break;
                }
                this.IsInspectionSave = false;
            }
        }
        return this.IsInspectionSave;
    }

    isClientAndContractNumUnique(control: FormControl) {
        const q = new Promise((resolve) => {
            setTimeout(() => {
                if (control.value === this.cachedContractNum) {
                    resolve(null);
                }
                if ((control.value || '').trim().length === 0) {
                    resolve({ whitespace: true });
                } else {
                    this.subscriptions.push(this.electroServices
                        .checkclientNumAndContratcNum(this.clientNumber, control.value)
                        .subscribe((x) => {
                            if (!x) {
                                resolve(null);
                            } else {
                                resolve({ 'contractNumberExists': true });
                            }
                        }, () => { resolve({ 'contractNumberExists': true }); }));
                }
            }, 1000);
        });
        return q;
    }
    /**
    * mobilenumber keypress event restrict number greater than 20 and restrict text
    * @param e {any}
    * @param limitNumber {any}
    */
    onMobileNumber(e: any, limitNumber: any) {
        const limit = limitNumber;
        let charCode = (e.which) ? e.which : e.keyCode
        if (e.target.value.length === limit || charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}
import { ConfirmationService } from 'primeng/api';
import { UserService } from './../../users/services/user.service';
import { UploadStatus, UploaderOptions } from 'ngx-uploader';
import { BaseServices } from './../../kuba.services';
import { UploadInput, UploadFile, UploadOutput } from 'ngx-uploader';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { environment } from '../../../../environments/environment';
import { BusinessSignedService } from '../services/businessSigned.service';

// TODO: to be implemented
@Component({
  templateUrl: './business-sign.component.html',
})
export class BusinessSignComponent implements OnInit {
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  isFileChoosed: boolean;
  SignForm: FormGroup;
  files: UploadFile[] = [];
  signedDocument: any;
  userName: string;
  signedList: any;
  userDetails: any;
  userId: number;
  uploadInput: EventEmitter<UploadInput>;
  dragOver: boolean;
  Users: any;
  businessId: number;
  options: UploaderOptions;
  uploadStart: boolean;
  signName: string;
  private subscriptions: Subscription[] = [];

  public selectedFiles: any;
  loading: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private userServices: UserService,
    private businessSignedService: BusinessSignedService,
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private translate: TranslateService
  ) {
    this.businessId = this.route.snapshot.parent?.params['bid'];
    this.SignForm = this.formBuilder.group({
      UserName: ['', [Validators.required]],
    });
    this.options = {
      concurrency: 1,
      allowedContentTypes: ['image/png', 'image/jpeg', 'image/gif'],
    };
  }

  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    this.uploadInput = new EventEmitter<UploadInput>();
    this.userDetails = this.route.snapshot.data['users'];
    this.bindList();
    this.Users = [];
    let users = this.userDetails;
    this.Users = [];
    this.Users.push({ label: 'Select', value: null });
    if (users) {
      users.forEach((element: any) => {
        this.Users.push({ label: element.Name, value: element.Id });
      });
    }
  }
  /**
   * on upload event
   * @param output
   */
  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') {
      // uncomment this if you want to auto upload files when added
      const event: UploadInput = {
        type: 'uploadAll',
        url: environment.BASE_URL + '/file/upload/business/signed',
        method: 'POST',
        data: {
          ApplicationId: `${BaseServices.ApplicationId}`,
          BusinessId: `${this.businessId}`,
          ParentId: `${this.businessId ? this.businessId : 0}`,
          Status: '1',
          CreatedOn: `${new Date()}`,
          ModifiedBy: `${BaseServices.UserId}`,
          ModifiedOn: `${new Date()}`,
        },
      };
      this.uploadInput.emit(event);
    } else if (
      output.type === 'addedToQueue' &&
      typeof output.file !== 'undefined'
    ) {
      this.isFileChoosed = true;
      this.files.push(output.file);
    } else if (
      output.type === 'uploading' &&
      typeof output.file !== 'undefined'
    ) {
      const index = this.files.findIndex(
        (file) =>
          typeof output.file !== 'undefined' && file.id === output.file.id
      );
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      this.files = this.files.filter(
        (file: UploadFile) => file !== output.file
      );
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'done') {
      this.signedDocument = output.file?.response.path;
    } else if (
      output.type === 'rejected' &&
      typeof output.file !== 'undefined'
    ) {
    }

    this.files = this.files.filter(
      (file) => file.progress.status !== UploadStatus.Done
    );
  }

  filesSelect(selectedFiles: any): void {
    this.uploadStart = true;

    this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(
      (file) => file
    );
    let formData = new FormData();
    formData.append('file', this.selectedFiles[0]);

    this.subscriptions.push(
      this.http
        .post(
          environment.BASE_URL +
            '/file/upload/business/signed/' +
            BaseServices.ApplicationId +
            '/' +
            this.businessId,
          formData
        )
        .map((response: any) => {
          this.uploadStart = false;
          let res = response;
          this.signedDocument = res.path;
          this.signName = this.signedDocument.replace(/^.*[\\\/]/, '');
          this.loading = false;
        })
        .subscribe(
          (result) => {},
          (error) => console.log('Upload article Sign Error : ', error),
          () => console.log('Upload article  Sign Complete')
        )
    );
  }

  saveSigned() {
    this.loading = true;
    if (this.userId) {
      this.subscriptions.push(
        this.userServices
          .updateSignatureImage({
            Id: this.userId,
            SignatureImg: this.signedDocument,
          })
          .subscribe((addResponse: any) => {
            if (addResponse) {
              this.toasterComponent.callToast();
              this.bindList();
              this.loading = false;
            }
          })
      );
    }
  }

  onUserChanged(event: any) {
    this.userId = event.value;
  }
  bindList() {
    this.subscriptions.push(
      this.businessSignedService
        .getAllUsers(this.businessId)
        .subscribe((result: any) => {
          if (result) {
            this.signedList = result.filter(
              (x: any) => x.SignatureImg !== null
            );
          }
        })
    );
  }
  editSignedDetails(data: any) {
    this.userId = data.Id;
    this.signedDocument = data.SignatureImg;
    this.SignForm = this.formBuilder.group({
      UserName: data.Id,
    });
  }
  deleteSign(id: number) {
    this.userId = id;
    if (this.userId) {
      this.confirmationService.confirm({
        message: this.translate.instant('DELETE_THIS_RECORD'),
        accept: () => {
          this.subscriptions.push(
            this.userServices
              .updateSignatureImage({ Id: this.userId, SignatureImg: null })
              .subscribe((addResponse) => {
                if (addResponse) {
                  this.bindList();
                  this.toasterComponent.callToastDlt();
                }
              })
          );
        },
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}

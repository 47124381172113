import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import {
    FeatureChecklistModel,
    FeatureDocumentModel
} from 'src/app/kuba/feature-documents/feature-document.models';
import {
    ArticleDataEntityModel,
    ArticleEntityModel,
    LarDataEntityModel
} from 'src/app/shared/ecpl-article-viewer/interfaces';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/Observable';

import { TreeData } from './../common/models';
import { BaseServices } from './../kuba.services';
import { BusinessServices } from '../businesses/services/business.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FeatureManualService {
    featureId: number;
    workingModule: any;

    constructor(private http: HttpClient) {
        this.workingModule = JSON.parse(
            sessionStorage.getItem('workingModule')
        );
        this.featureId = this.workingModule.id;
    }

    getFeatureManualTree(fkey: number): Observable<TreeData[]> {
        this.setWorkingModule();
        return this.http
            .get(
                `${environment.BASE_URL}/feature/tree/featuretreedata/${
                this.featureId
                }/${fkey}/${BaseServices.BusinessId}`,
                BaseServices.headerOption
            )
            .map((result:any) => result);
    }
    getFeatureManualTreeBytree(featureId: number,fkey: number): Observable<TreeData[]> {
        this.setWorkingModule();
        return this.http
            .get(
                `${environment.BASE_URL}/feature/tree/featuretreedata/${
                    featureId
                }/${fkey}/${BaseServices.BusinessId}`,
                BaseServices.headerOption
            )
            .map((result:any) => result);
    }

    // Document related services
    // AppManual Document
    addFeatureManualDocument(uploadedFile) {
        this.setWorkingModule();
        let appFeatureManualDocument: FeatureDocumentModel = new FeatureDocumentModel();
        appFeatureManualDocument.Title = uploadedFile.filename;
        appFeatureManualDocument.OriginalFileName = uploadedFile.filename;
        appFeatureManualDocument.DocumentTypeId = uploadedFile.fileTypeId;
        appFeatureManualDocument.Path = uploadedFile.path;
        appFeatureManualDocument.MimeType = uploadedFile.mimetype;
        appFeatureManualDocument.Size = uploadedFile.size;
        appFeatureManualDocument.FeatureId = uploadedFile.FeatureId;
        appFeatureManualDocument.FeatureKey = uploadedFile.FeatureKey;
        appFeatureManualDocument.Status = '1';
        if ( appFeatureManualDocument.FeatureId == 60){
            appFeatureManualDocument.CreatedBy = uploadedFile.BusinessId;
            appFeatureManualDocument.UserId=uploadedFile.userId;
        }else {
            appFeatureManualDocument.CreatedBy = uploadedFile.userId;
        }
        appFeatureManualDocument.BusinessId = uploadedFile.BusinessId;
        appFeatureManualDocument.FeatureFolderId = uploadedFile.FeatureFolderId;
        return this.http
            .post(
                `${environment.BASE_URL}/feature/document`,
                appFeatureManualDocument,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    copyFeatureManualDocument(docDetails) {
        this.setWorkingModule();
        let appFeatureManualDocument: FeatureDocumentModel = new FeatureDocumentModel();
        appFeatureManualDocument.Id = docDetails.Id;
        appFeatureManualDocument.Path = docDetails.Path;
        appFeatureManualDocument.CreatedBy = BaseServices.UserId;
        appFeatureManualDocument.ModifiedBy = BaseServices.UserId;
        return this.http
            .post(
                `${environment.BASE_URL}/feature/copy`,
                appFeatureManualDocument,
                BaseServices.headerOption
            )
            .map(result => result);
    }


    CreateEditDocumentVersion(document) {
        let varsionDto = {
            Id: 0,
            FeatureManualDocumentId: document.Id,
            Path: document.Path,
            RevisionNo: 1,
            RevisionComment: 'Saving As',
            Status: '1',
            CreatedBy: BaseServices.UserId,
            ModifiedBy: BaseServices.UserId
        };
        return this.http
            .post(
                `${environment.BASE_URL}/feature/SaveCopyversion`,
                varsionDto,
                BaseServices.headerOption
            )
            .map(result => result);
    }
    // Add manual folder
    createFeatureManualFolder(featureKey, model) {
        this.setWorkingModule();
        let sData = {
            FeatureId: this.featureId,
            FeatureKey: featureKey,
            FolderName: model.newNode.filename,
            BusinessId: +BaseServices.BusinessId ? +BaseServices.BusinessId : 0,
            ParentFolderId: model.parent.nodeId,
            DocumentTypeId: model.parent.documentTypeId,
            Status: 1,
            IsDefault: model.newNode.isDefault,
            CreatedBy: +BaseServices.UserId,
            ModifiedBy: +BaseServices.UserId
        };

        return this.http
            .post(
                `${environment.BASE_URL}/feature/folders/new`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    getAdminProjectFeatureKey(projectTemplateId) {
        return this.http
            .get(
                `${environment.BASE_URL}/project/getAdminProjectKey/${
                projectTemplateId
                }`
                ,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    updateManualCheckboxstatus(data, featureId, featureKey) {
        let sData = {
            Id: +data.nodeId,
            Status: +data.status,
            DocumentType: data.documentType.toLowerCase(),
            FileType: data.type.toLowerCase(),
            IsDefault: data.isDefault,
            FeatureId: +featureId,
            FeatureKey: +featureKey
        };
        return this.http
            .put(
                `${environment.BASE_URL}/feature/tree/manualPermission/${
                BaseServices.UserId
                }`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Rename Manaul Folder
    renameFeatureManualFolder(mid, model) {
        this.setWorkingModule();
        let sData = {
            Id: +model.item.data.nodeId,
            FolderName: model.item.data.filename,
            ModifiedBy: +BaseServices.UserId
        };
        return this.http
            .put(
                `${environment.BASE_URL}/feature/folders/rename/${sData.Id}`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Delete Manual folder
    deleteFeatureManualFolder(mid, did) {
        this.setWorkingModule();
        return this.http
            .post(
                `${environment.BASE_URL}/feature/folders/delete/${did}`,
                '',
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Add Article
    newArticle(mid, model) {
        this.setWorkingModule();
        let sData: ArticleEntityModel = {
            Title: model.item.articleContent.article.articleName,
            DocumentNo: model.item.articleContent.article.documentNumber,
            ReferenceNumber: model.item.articleContent.article.documentNumber,
            Chapter: model.item.articleContent.article.chapter,
            Version: model.item.articleContent.article.version,
            FeatureFolderId: model.activeNodeId,
            FeatureId: this.featureId,
            FeatureKey: mid,
            Status: 1,
            BusinessId: +BaseServices.BusinessId ? +BaseServices.BusinessId : 0,
            CreatedBy: +BaseServices.UserId,
            ArticleDate: new Date(model.item.articleContent.article.articleDate.jsdate).toDateString(),
            RoleStatus: '',
            SignaturePath: model.item.articleContent.article.signaturePath,
        };
        return this.http
            .post(
                `${environment.BASE_URL}/feature/article/new`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // update Article
    saveArticle(mid, model) {
        this.setWorkingModule();
        let sData: ArticleEntityModel = {
            Id: model.item.articleId,
            AppManualId: mid,
            ApplicationId: +BaseServices.ApplicationId
                ? +BaseServices.ApplicationId
                : 0,
            BusinessId: +BaseServices.BusinessId ? +BaseServices.BusinessId : 0,
            Title: model.item.articleContent.article.articleName,
            DocumentNo: model.item.articleContent.article.documentNumber,
            Chapter: model.item.articleContent.article.chapter,
            Version: model.item.articleContent.article.version,
            ArticleDate: new Date(model.item.articleContent.article.articleDate.jsdate).toDateString(),
            SignaturePath: model.item.articleContent.article.signaturePath,
            RoleStatus: '',
            Status: 1,
            ModifiedBy: BaseServices.UserId,
            ArticleFormattedDate: model.item.articleContent.article.formattedDate
        };
        return this.http
            .put(
                `${environment.BASE_URL}/feature/article/${sData.Id}`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // update Article own texts
    newArticleText(mid, aid, model, isNew, version) {
        let sData: ArticleDataEntityModel[] = [];
        model.item.articleContent.subArticles.forEach(element => {
            sData.push({
                Id: element.id ? element.id : 0,
                ApplicationId: +BaseServices.ApplicationId
                    ? +BaseServices.ApplicationId
                    : 0,
                PortalId: +BaseServices.PortalId ? +BaseServices.PortalId : 0,
                BusinessId: +BaseServices.BusinessId
                    ? +BaseServices.BusinessId
                    : 0,
                FeatureManualArticleId: aid,
                TextType: element.name,
                TextContent: element.text,
                Version: version,
                Status: element.status === 'Active' ? '1' : '0',
                CreatedBy: BaseServices.UserId,
                ModifiedBy: BaseServices.UserId,
                ArticleStatus: null
            });
        });

        if (isNew) {
            return this.http
                .post(
                    `${environment.BASE_URL}/feature/article/ArticleAllText`,
                    sData,
                    BaseServices.headerOption
                )
                .map(result => result)
                .catch(error => BaseServices.handleError(error));
        } else {
            return this.http
                .put(
                    `${environment.BASE_URL}/feature/article/ArticleAllText/${aid}/${version}`,
                    sData,
                    BaseServices.headerOption
                )
                .map(result => result)
                .catch(error => BaseServices.handleError(error));
        }
    }

    // Rename Article
    renameArticle(mid, model) {
        this.setWorkingModule();
        let sData = {
            Id: +model.item.data.nodeId,
            Title: model.item.data.filename,
            ModifiedBy: +BaseServices.UserId
        };
        return this.http
            .put(
                `${environment.BASE_URL}/feature/article/rename/${sData.Id}`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Delete Article
    deleteArticle(mid, did) {
        this.setWorkingModule();
        return this.http
            .delete(
                `${environment.BASE_URL}/feature/article/delete/${did}`,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Delete Article
    deleteMultipleArticle(data) {
        this.setWorkingModule();
        return this.http
            .put(
                `${environment.BASE_URL}/feature/article/delete`,
                data,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }
    // Add Checklist
    createChecklist(featureId, featureKey, model) {
        this.setWorkingModule();
        // Add Checklist
        let sData = new FeatureChecklistModel();
        sData.ApplicationId = +BaseServices.ApplicationId
            ? +BaseServices.ApplicationId
            : 0;
        sData.PortalId = +BaseServices.PortalId ? +BaseServices.PortalId : 0;
        sData.BusinessId = +BaseServices.BusinessId
            ? +BaseServices.BusinessId
            : 0;
        sData.FeatureId = featureId;
        sData.FeatureKey = featureKey;
        sData.FeatureFolderId = model.activeNodeId;
        sData.Description = model.Description;
        sData.Title = model.item.checkList.title;
        sData.IsScoreIndex = model.item.checkList.scoreIndex;
        sData.Status = model.item.checkList.status === 1 ? 1 : 0;
        sData.CreatedBy = BaseServices.UserId;

        return this.http
            .post(
                `${environment.BASE_URL}/feature/checklist/add`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    saveLar(model) {
        let sData: LarDataEntityModel = {
            Id: +model.item.larId,
            Title: model.item.larContent.title,
            Url: model.item.larContent.url,
            IsCommon: model.item.larContent.isCommon,
            Status: 1,
            ModifiedBy: +BaseServices.UserId
        };
        return this.http
            .put(
                `${environment.BASE_URL}/feature/law-regulations/update/${sData.Id}`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    CreateLar(featureId, featureKey, model) {
        let sData: LarDataEntityModel = {
            FeatureFolderId: model.activeNodeId,
            BusinessId: +BaseServices.BusinessId,
            Title: model.item.larContent.title,
            Url: model.item.larContent.url,
            IsLaws: model.item.larContent.isLaws,
            IsCommon: model.item.larContent.isCommon === '0' ? false : true,
            Status: 1,
            CreatedBy: +BaseServices.UserId,
            ModifiedBy: +BaseServices.UserId,
            FeatureId: featureId,
            FeatureKey: featureKey
        }
        return this.http
            .post(
                `${environment.BASE_URL}/feature/law-regulations/add`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    deleteLar(did) {
        return this.http
            .delete(
                `${environment.BASE_URL}/feature/law-regulations/delete/${did}`,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // update checklist
    UpdateChecklist(mid, model) {
        this.setWorkingModule();
    }

    // Rename checklist
    renameChecklist(mid, model) {
        this.setWorkingModule();
        let sData = {
            Id: +model.item.data.nodeId,
            Title: model.item.data.filename,
            ModifiedBy: +BaseServices.UserId
        };
        return this.http
            .put(
                `${environment.BASE_URL}/feature/checklist/rename/${sData.Id}`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Delete checklist
    deleteChecklist(mid, did) {
        this.setWorkingModule();
        return this.http
            .delete(
                `${environment.BASE_URL}/feature/checklist/delete/${did}`,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    deleteMultipleChecklist(data) {
        return this.http
            .put(
                `${environment.BASE_URL}/feature/checklist/delete`,
                data,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Add Document
    createDocument(mid, model) {
        this.setWorkingModule();
    }

    // update checklist
    UpdateDocument(fileId, userId) {
        let sData = {};
        this.setWorkingModule();
        return this.http.put(
            `${environment.BASE_URL}/feature/updateDocument/${fileId}/${userId}`,
            sData,BaseServices.headerOption
        ) .map(result => result)
        .catch(error => BaseServices.handleError(error));
    }

    // Rename checklist
    renameDocument(mid, model) {
        this.setWorkingModule();
        let sData = {
            Id: +model.item.data.nodeId,
            Title: model.item.data.filename,
            ModifiedBy: +BaseServices.UserId
        };
        return this.http
            .put(
                `${environment.BASE_URL}/feature/rename/${sData.Id}`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Delete document
    deleteDocument(mid, did) {
        this.setWorkingModule();
        return this.http
            .delete(
                `${environment.BASE_URL}/feature/delete/${did}`,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    deleteMultipleDocument(data) {
        this.setWorkingModule();
        return this.http
            .put(
                `${environment.BASE_URL}/feature/delete`,
                data,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }
    moveNode(featureId, featurekey, model, mId) {
        this.setWorkingModule();
        let sData;
        let data = [];
        switch (model.to.parent.documentType) {
            case 'article':
                data = model.treeData;
                sData = {
                    Source: [
                        {
                            Type: model.node.type,
                            FolderId: model.node.nodeId,
                            mId: mId,
                            appId: BaseServices.ApplicationId
                        }
                    ],
                    Destination: {
                        FolderId: model.to.parent.nodeId,
                        ModifiedBy: BaseServices.UserId
                    },
                    TreeData: data
                };
                let res:any = this.http
                    .post(
                        `${
                        environment.BASE_URL
                        }/feature/article/DragAndDrop/${featureId}/${featurekey}/${BaseServices.UserId}/${BaseServices.BusinessId}`,
                        sData,
                        BaseServices.headerOption
                    )
                    .map(result => result)
                    .catch(error => BaseServices.handleError(error));
                return res;

            case 'checklist':
                data = model.treeData;
                sData = {
                    Source: [
                        {
                            Type: model.node.type,
                            FolderId: model.node.nodeId,
                            mId: mId,
                            appId: BaseServices.ApplicationId
                        }
                    ],
                    Destination: {
                        FolderId: model.to.parent.nodeId,
                        ModifiedBy: BaseServices.UserId
                    },
                    TreeData: data
                };
                return this.http
                    .post(
                        `${
                        environment.BASE_URL
                        }/feature/checklist/DragAndDrop/${featureId}/${featurekey}/${BaseServices.UserId}/${BaseServices.BusinessId}`,
                        sData,
                        BaseServices.headerOption
                    )
                    .map(result => result)
                    .catch(error => BaseServices.handleError(error));

            case 'document':
               data = model.treeData;
                sData = {
                    Source: [
                        {
                            Type: model.node.type,
                            FolderId: model.node.nodeId,
                            mId: mId,
                            appId: BaseServices.ApplicationId
                        }
                    ],
                    Destination: {
                        FolderId: model.to.parent.nodeId,
                        ModifiedBy: BaseServices.UserId
                    }
                    ,
                    TreeData: data
                };
                return this.http
                    .post(
                        `${
                        environment.BASE_URL
                        }/feature/DragAndDrop/${featureId}/${featurekey}/${BaseServices.UserId}/${BaseServices.BusinessId}`,
                        sData,
                        BaseServices.headerOption
                    )
                    .map(result => result)
                    .catch(error => BaseServices.handleError(error));

            default:
                break;
        }
    }

    // private

    setWorkingModule() {
        this.workingModule = JSON.parse(
            sessionStorage.getItem('workingModule')
        );
        this.featureId = this.workingModule.id;
    }

    // update checklist
    saveChecklist(featurecheckListId, model) {
        let sData = `[${JSON.stringify(model)}]`;
        return this.http
            .put(
                `${
                environment.BASE_URL
                }/feature/checklistitem/updateAll/${featurecheckListId}`,
                JSON.stringify(sData),
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }


    moveFeatureNode(model) {
        let sData;
        switch (model.to.parent.documentType) {
            case 'article':
                sData = {
                    Source: [
                        {
                            Type: model.node.type,
                            FolderId: model.node.nodeId
                        }
                    ],
                    Destination: {
                        FolderId: model.to.parent.nodeId,
                        ModifiedBy: BaseServices.UserId
                    }
                };
                let res:any = this.http
                    .put(
                        `${
                        environment.BASE_URL
                        }/feature/article/DragAndDrop/article`,
                        sData,
                        BaseServices.headerOption
                    )
                    .map(result => result)
                    .catch(error => BaseServices.handleError(error));
                return res;

            case 'checklist':
                sData = {
                    Source: [
                        {
                            Type: model.node.type,
                            FolderId: model.node.nodeId
                        }
                    ],
                    Destination: {
                        FolderId: model.to.parent.nodeId,
                        ModifiedBy: BaseServices.UserId
                    }
                };
                return this.http
                    .put(
                        `${
                        environment.BASE_URL
                        }/feature/checklist/DragAndDrop/checklist`,
                        sData,
                        BaseServices.headerOption
                    )
                    .map(result => result)
                    .catch(error => BaseServices.handleError(error));

            case 'document':
                sData = {
                    Source: [
                        {
                            Type: model.node.type,
                            FolderId: model.node.nodeId
                        }
                    ],
                    Destination: {
                        FolderId: model.to.parent.nodeId,
                        ModifiedBy: BaseServices.UserId
                    }
                };
                return this.http
                    .put(
                        `${
                        environment.BASE_URL
                        }/feature/DragAndDrop/document`,
                        sData,
                        BaseServices.headerOption
                    )
                    .map(result => result)
                    .catch(error => BaseServices.handleError(error));

            default:
                break;
        }
    }
}

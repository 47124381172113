import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BusinessLogService } from './../services/businessLog.service';
import { BaseServices } from '../../kuba.services';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'business-log.component.html'
})
export class BusinessLogComponent implements OnInit {

    //#region variables
    @ViewChild(ToasterComponent, {static:false}) toasterComponent: ToasterComponent;
    businesId: number;
    loglist: any;
    logonDate: any;
    isCollapsed: boolean;
    roleId: number;
    businessId: number;
    loginUsername: any;
    private subscriptions: Subscription[] = [];
 
    //#endregion

    //#region constructors
    /**
     * constructor
     * @param formBuilder {FormBuilder}
     * @param route {ActivatedRoute}
     * @param businessLogService {BusinessLogService}
     */
    constructor(
        private _fb: FormBuilder,
        private route: ActivatedRoute,
        private BusinessLogService: BusinessLogService
    ) {
    
    }
    //#endregion

    //#region page-events
    /**
     * page load event
    */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.loglist = this.route.snapshot.data['loglist'];
        this.roleId = +BaseServices.roleId;

    }

     /**
    * set collapse value
    * @param rowIndex
    */
  public setCollapse(row) {
    let isCollapsed = (row === this.logonDate);
    return isCollapsed;
  }

    toggleClick(rowData): any {
        let url = window.location.href
        let urlArray = url.split('/')
        let busId = Number(urlArray[urlArray.length-2])
        
        this.logonDate = rowData;
        this.isCollapsed = this.isCollapsed;
        this.subscriptions.push(this.BusinessLogService.getAllLogsUserName(busId, rowData.LogOn).subscribe((res:any) => {
          this.loginUsername = res;
          
        }));
      }

      toggleClickHide(i): void {
        this.loginUsername = null;
        this.logonDate = null;
      }

   /**
   * on destory component
   */
  ngOnDestroy() {
    document.querySelector("body").classList.add("opened");
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
    //#endregion
}

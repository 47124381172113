import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { AbsenceCode } from './../models/businessAbsence';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BusinessAbsenceService {
    constructor(private http: HttpClient) {}

    addAbsenceCode(absenceCode: AbsenceCode) {
        return this.http
            .post(
                environment.BASE_URL + '/business/absencecodes',
                absenceCode,

            )
            .map(result => result);
    }
    getAbsenceById(businessid: number) {
        return this.http
            .get<any>(environment.BASE_URL + '/business/absencecodes/' + businessid, )
            .map(result => result);
    }
    updateAbsenceCode(absenceCode: AbsenceCode) {
        return this.http
            .put(environment.BASE_URL + '/business/absencecodes', absenceCode, )
            .map(result => result);
    }
}

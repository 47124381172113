import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/_services/authentication.service';
@Injectable()
export class Interceptor implements HttpInterceptor {


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token: any;
        let session = JSON.parse(sessionStorage.getItem('session')!);
        if (session !== null && session.AccessToken) {
            token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
        }

        const isMultipart = req.body instanceof FormData;

        let headers = req.headers.set('Authorization', token ? `Bearer ${token}` : req.headers.get('Authorization') || '');

        if (!isMultipart) {
            headers = headers.set('content-type', 'application/json');
        }

        const httpRequest = req.clone({ headers });

        return next.handle(httpRequest);
    }
}
